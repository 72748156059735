import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterchat'
})
export class FilterchatPipe implements PipeTransform {

  transform(value: any, arg: string, propName: any): any {
    if (arg === '') return value;
    
    const resultPosts = [];

    for (const item of value) {
      if (propName == "id") {
        if (item[propName].toLowerCase().indexOf(arg.toLowerCase()) > -1) {
          resultPosts.push(item)
        }
      } else {
        if (item.asunto[propName].toLowerCase().indexOf(arg.toLowerCase()) > -1) {
          resultPosts.push(item)
        }
      }
    };

    return resultPosts;
  }

}
