<section class="main">
  <div class="fx-title mb-4 ">
    <i class="large arrow left icon" (click)="back()" style="cursor:pointer;"></i>
    <h2 class="title" style="margin: 10px;">Go</h2>
    <div></div>
  </div>

  <div class="main">
    <div class="table-responsive" *ngIf="mostrarLead">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Nombre</th>
            <th scope="col">Fecha</th>
            <th scope="col">Última Actualización</th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">{{ mostrarLead.id }}</th>
            <td>{{ mostrarLead.cliente.nombre | titlecase }} {{ mostrarLead.cliente.apellido | titlecase }}</td>
            <td>{{ mostrarLead.cliente.fecha }}</td>
            <td>{{ mostrarLead.last_update.fecha }}</td>
            <td>
              <button type="button" class="ui button" data-bs-toggle="modal" data-bs-target="#exampleModal"
                data-bs-whatever="@getbootstrap">
                Comentar Lead
              </button>
            </td>
            <td>
              <button class="ui button" (click)="openLG1(contenido1)">
                Ver Comentarios
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Comentar Lead</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label for="message-text" class="col-form-label">Comentario:</label>
            <textarea class="form-control" [(ngModel)]="leadComment" id="message-text"></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="ui button" data-bs-dismiss="modal">
            Cerrar
          </button>
          <button type="button" class="btn btn-primary" (click)="commentLeadPost()" data-bs-dismiss="modal">
            Enviar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="ui card">
      <!-- <div class="image" *ngIf="todo.imagen_principal.url; else noImg">
        <img *ngIf="!auth.userFullAdmin(user)" [src]="todo.imagen_principal.url" alt="" />
      </div>
      <div class="content">
        <a class="header">Kristy</a>
        <div class="meta">
          <span class="date">Joined in 2013</span>
        </div>
        <div class="description">
          Kristy is an art director living in New York.
        </div>
      </div>
      <div class="extra content">
        <a>
          <i class="user icon"></i>
          22 Friends
        </a>
      </div>   -->
    </div>
    <div class="casa" *ngFor="let todo of articulosG; let i = index">
      <div class="casa-cliente">
        <div class="">
          <div class="image" *ngIf="todo.imagen_principal.url; else noImg">
            <img *ngIf="!auth.userFullAdmin(user)" [src]="todo.imagen_principal.url" alt="" />
          </div>
          <ng-template #noImg>
            <div class="no-image">
              Sin fotografías
            </div>
          </ng-template>
        </div>

        <div class="info">
          <div style="display: flex;">
            <p *ngIf="todo.tipo.tipo == 'compraventa'">Venta,</p>
            <p *ngIf="todo.tipo.tipo == 'arrendamiento'">Renta,</p>
            <p> {{ todo.articulo.precio }}, </p>
            <p>{{ todo.articulo.estado }}</p>
          </div>

        </div>

        <button (click)="routerItem(todo)">+</button>
      </div>

      <div class="contenido-descripcion">
        <div class="botones">
          <button (click)="positivo(todo, i)">Positivo</button>
          <button (click)="negativo(todo, i)">Negativo</button>
        </div>

        <div class="textarea">
          <textarea class="form-control" id="i" [(ngModel)]="articleComment" placeholder="comentario">
          </textarea>

          <button (click)="commentArticlePost(todo)">POST!</button>
        </div>

        <div class="otro-boton">
          <button (click)="openLG2(contenido2, todo)">
            Ver Comentarios
          </button>
        </div>
      </div>
    </div>
  </div>
</section>




<ng-template #contenido1 let-modal>
  <!-- (click)="modal.dismiss()" -->
  <div class="modal-heade">
    <div class="modal-titles">
      <div class="data">Comentario</div>
      <div class="data">Hora</div>
      <div class="data">Fecha</div>
    </div>
    <div class="modal-data" *ngFor="let comment of allLeadComents">
      <div class="data">{{ comment.comentario.comentario }}</div>
      <div class="data">{{ comment.comentario.hora }}</div>
      <div class="data">{{ comment.comentario.fecha }}</div>
      <hr />
    </div>
  </div>
</ng-template>

<ng-template #contenido2 let-modal>
  <div class="modal-heade">
    <div class="box mb-3" *ngFor="let comment of allArticleComments">
      <div class="id_user">
        <p>Usuario {{comment.comentario.id_user }}</p>
      </div>
      <div class="comment mt-2">
        {{comment.comentario.comentario}}
      </div>
      <div class="date mt-3">
        Comentado el&nbsp;
        <p>{{comment.comentario.fecha}}</p>&nbsp;
        alas&nbsp;
        <p>{{comment.comentario.hora}}</p>
      </div>
      <hr>
    </div>
    <button class="ui button" (click)="closeModal()">Cerrar</button>
    <!-- <div class="modal-titles">
      <div class="data">Comentario</div>
      <div class="data">Id User</div>
      <div class="data">Hora</div>
      <div class="data">Fecha</div>
    </div>
    <div class="modal-data" *ngFor="let comment of allArticleComments">
      <div class="data">{{ comment.comentario.comentario }}</div>
      <div class="data">{{ comment.comentario.id_user }}</div>
      <div class="data">{{ comment.comentario.hora }}</div>
      <div class="data">{{ comment.comentario.fecha }}</div>
      <hr />
    </div> -->
  </div>
</ng-template>

<ng-template #myModal>
  <div class="main">
    <div class="container-modal">
      <modal-content>
        <h5 class="lead">Este Lead no tiene más articulos asignados.</h5>
      </modal-content>
      <modal-footer class="btn-options">
        <button class="ui button" (click)="gestionaView()" style="margin-right: 5px">
          Ir a gestiona
        </button>
        <button class="ui button" (click)="historyLead()">
          Ir a leads
        </button>
      </modal-footer>
    </div>
  </div>
</ng-template>
