import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/SERVICIOS/auth.service';
import { DataDbService } from 'src/app/SERVICIOS/data-db.service';
import { GenerateIdService } from 'src/app/SERVICIOS/generate-id.service';
import { GetTokensService } from 'src/app/SERVICIOS/get-tokens.service';
import { SocketService } from 'src/app/SERVICIOS/socket/socket.service';
import { User } from '../../MODULOS/user.interface';

@Component({
  selector: 'app-select-article',
  templateUrl: './select-article.component.html',
  styleUrls: ['./select-article.component.scss']
})
export class SelectArticleComponent implements OnInit {
  subs_one: Subscription;
  myLeads = [];
  user: User;
  constructor(public dbData: DataDbService, public afs: AngularFirestore, private generateId: GenerateIdService,
    public auth: AuthService, private tokensUser: GetTokensService, private router: Router, private socket: SocketService,) { }

  ngOnInit(): void {
    this.auth.user$.pipe(take(1)).toPromise().then((user) => {
      this.user = user
      this.subs_one = this.afs.collection("LEAD", ref => ref.where("uid_user", "==", user.uid)).valueChanges().subscribe(leads => {
        this.myLeads = leads


      });
    })
  }

  selectArticles(lead: any) {

    this.router.navigate(['keyaccess/select-article', lead.id_document]);
  }
}
