import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { User } from "../../MODULOS/user.interface";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/SERVICIOS/auth.service";
import { take } from "rxjs/operators";

@Component({
  selector: "app-agent-selection",
  templateUrl: "./agent-selection.component.html",
  styleUrls: ["./agent-selection.component.scss"],
})
export class AgentSelectionComponent implements OnInit, OnDestroy {
  public obtUsuarios = [];
  subs: Subscription;

  p: number = 1;
  idR: string;
  user: User;
  disabledInput: boolean = true;
  valueName: string;
  public filterPost = "";
  constructor(
    private afs: AngularFirestore,
    private router: Router,
    public auth: AuthService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.idR = this.route.snapshot.paramMap.get("id");
    let comp = localStorage.getItem("component");
    if (comp == "users") {
      let valueName = localStorage.getItem("valueName");
      let filterPost = localStorage.getItem("filterPost");
      let disabledInput = localStorage.getItem("disabledInput");
      let page = localStorage.getItem("page");

      if (page !== null && page !== undefined && page !== "") {
        this.p = Number(page);
      } else localStorage.setItem("page", "1");

      if (valueName !== null && valueName !== undefined && valueName !== "") {
        this.valueName = valueName;
      }

      if (
        filterPost !== null && filterPost !== undefined && filterPost !== ""
      ) {
        this.filterPost = filterPost;
      }

      if (
        disabledInput !== null && disabledInput !== undefined &&
        disabledInput !== ""
      ) {
        if (disabledInput == "true") {
          this.disabledInput = true;
        } else if (disabledInput == "false") {
          this.disabledInput = false;
        }
      }
    }

    this.auth.user$.pipe(take(1)).toPromise().then((user) => {
      this.user = user;
      this.subs = this.afs.collection(
        "USERS",
        (ref) => ref.where("uid", "!=", user.uid),
      ).valueChanges().subscribe((values: any) => {
        let users = values.filter((user: any) => user.emailVerified == true);
        this.obtUsuarios = users;
      });
    });
  }

  fullview(userData: any) {
    this.afs.collection("LEAD").doc(this.idR).update({
      uid_user: userData.uid,
      agent_name: userData.displayName,
      estatus: "completado",
      completo: true,
    });
    this.router.navigate(["markting", this.idR, userData.uid, "resume"]);
  }
  getText(e) {
    localStorage.setItem("component", "users");

    localStorage.setItem("filterPost", e);

    this.resetPage();
  }
  changePage(e) {
    this.p = e;
    localStorage.setItem("page", e);
  }
  resetPage() {
    this.p = 1;
    localStorage.setItem("page", "1");
  }
  filterWithSelect(e) {
    localStorage.setItem("component", "users");

    if (e == "1") {
      this.valueName = "id";
      this.disabledInput = false;
      localStorage.setItem("disabledInput", "false");
      localStorage.setItem("valueName", this.valueName);
    } else if (e == "2") {
      this.valueName = "nombre";
      this.disabledInput = false;
      localStorage.setItem("disabledInput", "false");
      localStorage.setItem("valueName", this.valueName);
    }
  }
  ngOnDestroy(): void {
    if (this.subs !== undefined) this.subs.unsubscribe();
  }
}
