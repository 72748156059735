<div class="main">

  <div class="fx-title mb-5 mt-4">
    <i class="large arrow left icon" (click)="back()" style="cursor:pointer;"></i>
    <h2 class="title" style="margin-left: 10px;">Fotografías de la comunidad</h2>
    <div></div>
  </div>
  <div *ngIf="images.length > 0" class="d-flex justify-content-between">
    <div>

    </div>
    <button (click)="uploadPhotos()" class="ui button primary">Subir fotografías</button>
  </div>
  <div class="card-columns mt-4" *ngIf="images.length > 0">

    <div *ngFor="let img of images; let i = index">
      <div class="boxImg">
        <img (click)="fullviewImage(i)" style="cursor: pointer;" class="card-img-top" [src]="img.url"
          alt="Card image cap">
        <div *ngIf="user.role == 'COMMUNITY-MANAGE'" class="dot">
          <i title="Rechazar" *ngIf="img.aprobado && !img.rechazado" class="large window close icon deleteImg"
            (click)="reject(i)"></i>
          <i title="Aprobar" *ngIf="!img.aprobado && img.rechazado" class="large window check square icon deleteImg"
            (click)="approve(i)"></i>

        </div>
        <div class="d-flex" *ngIf="!img.aprobado && !img.rechazado">
          <i title="Aprobar" class="large window check square icon deleteImg" (click)="approve(i)"></i>
          <i title="Rechazar" class="large window close icon deleteImg " style="left: 25px;" (click)="reject(i)"></i>

        </div>
      </div>

    </div>

  </div>

  <div class="row" *ngIf="images.length == 0">
    <div class="header">
      <div>
        <div class="text">
          <h1 class="">No hay fotografías aún</h1>
          <button (click)="uploadPhotos()" class="ui button primary mt-3">Subir fotografías</button>

        </div>
      </div>
    </div>
  </div>

  <div class="ui basic modal one" (click)="closeModal()">
    <div style=" display: flex;
    align-items: center;
    justify-content: center;
    height: 85vh;">
      <div class="text-center" style="padding: 40px;">
        <img style="width: 100%;" [src]="urlImage" alt="">
      </div>
    </div>
  </div>
