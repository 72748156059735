<div class="main">
    <h1 class="text-center">Firma Digital</h1>
    <div class="text-center mt-5" style="display: flex;
   flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    ">

        <section style="width: 400px; height:160px; border: 1px solid #ced4da;">
            <canvas #signDigitalW width="400" height="160" style="touch-action: none;"></canvas>
        </section>
        <div class="mt-5">
            <button class="ui button" (click)="cleanSign()">Reiniciar</button>
            <button class="ui primary button" (click)="guardarPNG()">Continuar</button>
        </div>
    </div>

</div>