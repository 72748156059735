import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router, ActivatedRoute } from '@angular/router';
import { ChartConfiguration, PolarAreaControllerChartOptions } from 'chart.js';
import { AuthService } from 'src/app/SERVICIOS/auth.service';
import { DataDbService } from 'src/app/SERVICIOS/data-db.service';
import { GetTokensService } from 'src/app/SERVICIOS/get-tokens.service';
import { SocketService } from 'src/app/SERVICIOS/socket/socket.service';
import { SaleDetail } from '../../models/saleDetail';
import { codes } from 'iso-country-codes';
import { NumerosALetras } from 'numero-a-letras';


import pdfMake from 'pdfmake/build/pdfmake';
import moment from 'moment';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { NumeroALetrasService } from 'src/app/SERVICIOS/numero-a-letras.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sale-list-fullview',
  templateUrl: './sale-list-fullview.component.html',
  styleUrls: ['./sale-list-fullview.component.scss'],
})
export class SaleListFullviewComponent implements OnInit {
  saleData: any;
  article: any;
  user: any;
  agent: any;
  id: string;
  idDocument: string;
  fromCustomer: string;
  customerDueño: any;
  customer: any;
  isMyResquest: boolean = false;
  editDateMeeting1: boolean = false;
  editDateMeeting2: boolean = false;
  editDateMeeting3: boolean = false;
  hasAval: boolean = false;
  aval: any;
  dateMeeting1: any
  dateMeeting2: any
  dateMeeting3: any
  price_one: any;
  price_two: any;
  public doughnutChartLabels: string[] = [
    'Recolección de datos',
    'Analización de datos',
    'Validación de datos',
    'Placing sale',
    'Sale placed',
  ];
  formatterDolar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  public doughnutChartDatasets: ChartConfiguration<'doughnut'>['data']['datasets'] =
    [];

  public doughnutChartOptions: ChartConfiguration<'doughnut'>['options'] = {
    maintainAspectRatio: false,
    responsive: true,

    //oculatr leyenda
  };

  public startAngle: PolarAreaControllerChartOptions['startAngle'] = 3;

  //startAngle
  private dataSubscriptions: Subscription[] = [];

  constructor(
    public dbData: DataDbService,
    public afs: AngularFirestore,
    public auth: AuthService,
    private tokensUser: GetTokensService,
    private router: Router,
    private socket: SocketService,
    private route: ActivatedRoute,
    private numALetras: NumeroALetrasService
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('idlist');
    this.idDocument = this.route.snapshot.paramMap.get('idDocument');
    this.fromCustomer = this.route.snapshot.paramMap.get('fromCustomer');

    this.auth.user$.pipe(take(1)).toPromise().then((user) => {

      this.user = user;
      this.getDatasale();


    }
    );
  }
  unsubscribeDataSubscriptions() {
    // Cancelar todas las suscripciones anteriores
    this.dataSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.dataSubscriptions = [];
  }

  getDatasale() {

    this.unsubscribeDataSubscriptions();

    this.dataSubscriptions.push(
      this.afs
        .collection(this.fromCustomer == "true" ? "CUSTOMER" : 'LEAD')
        .doc(this.idDocument)
        .valueChanges()
        .pipe(distinctUntilChanged())
        .subscribe((lead: any) => {
          this.customer = lead;
          if (this.customer.escalate) {
            this.afs
              .collection("CUSTOMER")
              .doc(this.idDocument)
              .valueChanges()
              .subscribe((lead: any) => {
                this.customer = lead;
                if (this.customer.aval != undefined && this.customer.aval != null) {
                  this.hasAval = true;
                  this.aval = this.customer.aval;
                }
                if (this.customer.uid_user == this.user.uid) this.isMyResquest = true;
                let getPos: Array<any> = lead.positives;

                let pos = getPos.find((item) => item.id === this.id);
                let lease = {
                  cliente: lead.cliente,
                  id: lead.id,
                  id_document: lead.id_document,
                  id_lead: lead.id,
                  positive: pos,
                  uid_user: lead.uid_user,
                };
                this.saleData = lease;
                this.getDataArticle(this.saleData.positive);
                this.getDataAgent(this.saleData);
                this.setDataProgress(this.saleData.positive);
              });
          }
          if (this.customer.aval != undefined && this.customer.aval != null) {
            this.hasAval = true;
            this.aval = this.customer.aval;
          }
          if (this.customer.uid_user == this.user.uid) this.isMyResquest = true;
          let getPos: Array<any> = lead.positives;
          let pos = getPos.find((item) => item.id == this.id);

          let sale = {
            cliente: lead.cliente,
            id: lead.id,
            id_document: lead.id_document,
            id_lead: lead.id,
            positive: pos,
            uid_user: lead.uid_user,
          };
          this.saleData = sale;



          this.getDataArticle(this.saleData.positive);
          this.getDataAgent(this.saleData);
          this.setDataProgress(this.saleData.positive);
        })
    );
    // this.afs
    //   .collectionGroup('OPERATION')
    //   .valueChanges()
    //   .subscribe((data) => {
    //     data.filter((item) => {
    //       if (item['id_document'] === this.id) {
    //         this.saleData = item;
    //         this.getDataArticle(this.saleData);
    //         this.getDataAgent(this.saleData);
    //         this.setDataProgress(this.saleData);
    //       }
    //     });
    //   });
  }
  getDataAgent(saleData: any) {
    this.afs
      .collection('USERS')
      .doc(saleData.uid_user)
      .valueChanges()
      .subscribe((data) => {
        this.agent = data;
      });
  }
  obtenerNacionalidad(nombrePais: string): string {
    const country = codes.find((c) => c.name === nombrePais);
    if (country) {
      return country.nationality;
    }
    return null;
  }
  getDataArticle(saleData: any) {


    this.afs
      .collection('ARTICLE')
      .doc(saleData.id_document_article)
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((data) => {
        this.article = data;

        this.afs
          .collection('CUSTOMER')
          .doc(this.article.id_document_customer)
          .valueChanges()
          .pipe(take(1)) // Utilizar el operador take(1) para limitar la suscripción a una emisión
          .subscribe((data) => {
            this.customerDueño = data;
          });
      });
  }

  setDataProgress(saleData) {
    if (saleData.progress === 'DATA-COLLECT') {
      this.doughnutChartDatasets = [
        {
          data: [20, 20, 20, 20, 20],
          label: 'Series A',
          backgroundColor: [
            'orange', // primer sector
            'white', // segundo sector
            'white',
            'white',
            'white',
            // tercer sector
          ],
          spacing: 0,
        },
      ];
    } else if (saleData.progress === 'DATA-ANALYZING') {
      this.doughnutChartDatasets = [
        {
          data: [20, 20, 20, 20, 20],
          label: 'Series A',
          backgroundColor: [
            'green', // primer sector
            'orange', // segundo sector
            'white',
            'white',
            'white',
            // tercer sector
          ],
        },
      ];
    } else if (saleData.progress === 'DATA-VALIDATING') {
      this.doughnutChartDatasets = [
        {
          data: [20, 20, 20, 20, 20],
          label: 'Series A',
          backgroundColor: [
            'green', // primer sector
            'green', // segundo sector
            'orange',
            'white',
            'white',
            // tercer sector
          ],
        },
      ];
    } else if (saleData.progress === 'PLACING-SALE') {
      this.doughnutChartDatasets = [
        {
          data: [20, 20, 20, 20, 20],
          label: 'Series A',
          backgroundColor: [
            'green', // primer sector
            'green', // segundo sector
            'green',
            'orange',
            'white',
            // tercer sector
          ],
        },
      ];
    } else if (saleData.progress === 'SALE-PLACED') {
      this.doughnutChartDatasets = [
        {
          data: [20, 20, 20, 20, 20],
          label: 'Series A',
          backgroundColor: [
            'green', // primer sector
            'green', // segundo sector
            'green',
            'green',
            'orange',
            // tercer sector
          ],
        },
      ];
    } else if (saleData.progress === 'SALE-PLACED-FINISH') {
      this.doughnutChartDatasets = [
        {
          data: [20, 20, 20, 20, 20],
          label: 'Series A',
          backgroundColor: [
            'green', // primer sector
            'green', // segundo sector
            'green',
            'green',
            'green',
            // tercer sector
          ],
        },
      ];
    }
  }
  generatePDFContrato() {
    let runSaleDate = this.saleData.positive.runSaleDate
    let saleEndedDate = this.saleData.positive.saleEndedDate
    //obtener meses que hay entre las fechas
    let months = this.getMonths(runSaleDate, saleEndedDate)
    let monthsToLetter: String = this.numALetras.NumerosALetrasSinPrecio(months);
    let articlePriceLetter: String = NumerosALetras(this.article.articulo.precio);
    let price = this.article.articulo.precio;
    this.formatterPrice(price, this.article);
    const documentDefinition = {
      info: {
        title: `Contrato de arrendamiento`,
        author: 'Net99',
        subject: 'Contrato de arrendamiento',
        keywords: 'Contrato, arrendamiento, leasing, renta, renta de equipo, renta de equipo de computo, renta de equipo de computo en mexico, renta',
      },
      pageSize: 'LETTER',
      pageMargins: [35, 20, 35, 0],
      content: [
        {
          bold: true,
          text: this.article.id_articulo + "\n\n",
          alignment: 'center',
        },
        {
          alignment: 'justify',
          columns: [
            {
              fontSize: 10.3,
              text:
                'CONTRATO DE ARRENDAMIENTO QUE EN TÉRMINOS DE LO QUE DISPONE EL CÓDIGO CIVIL VIGENTE PARA EL ESTADO DE ' + this.article.articulo.estado + ', QUE CELEBRAN, POR UNA PARTE ' + this.customerDueño.cliente.nombre + " " + this.customerDueño.cliente.apellido + ', A QUIEN EN LO SUCESIVO SE LE LLAMARÁ “EL ARRENDADOR” Y POR LA OTRA PARTE ' + this.customer.cliente.nombre.toString().toUpperCase() + " " + this.customer.cliente.apellido.toString().toUpperCase() + ', A QUIEN SE LE DENOMINARÁ COMO “EL ARRENDATARIO ” Y T H E L E A S E R GUARANTEE EN SU CARÁCTER DE “EL O B L I G A D O S O L I D A R I O ” , Q U I E N E S CUENTAN CON CAPACIDAD LEGAL PARA CONTRATAR Y OBLIGARSE AL TENOR DE LAS SIGUIENTES DECLARACIONES Y CLÁUSULAS QUE A CONTINUACIÓN SE DETALLAN: \n\n\n'
                + 'DECLARACIONES 1.- DECLARA “EL ARRENDADOR”: \n\n' +
                'a).- Llamarse como ha quedado establecido, ser una persona de nacionalidad Mexicana, que cuenta con las facultades suficientes y necesarias para celebrar el presente contrato de arrendamiento respecto,  del inmueble ubicado en calle ' + this.article.articulo.calle + ', #' + this.article.articulo.no_exterior
                + (this.article.articulo.no_interior != "" ? (', interior ' + this.article.articulo.no_interior) : '') + ', colonia ' + this.article.articulo.colonia + ', ciudad ' + this.article.articulo.ciudad + ', estado ' + this.article.articulo.estado +
                ', país ' + this.article.articulo.pais + ', código postal ' + this.article.articulo.cp
                + '. \n\n' +
                'b).- Que a la firma del presente contrato se identifica con ' + this.customerDueño.cliente.identificacion + '. \n\n' +
                'c).- Que para los efectos del presente contrato señala como domicilio para oír y recibir notificaciones, incluyendo el pago de renta el ubicado en ' +
                + this.customerDueño.cliente.calle + ', #' + this.customerDueño.cliente.no_exterior
                + (this.customerDueño.cliente.no_interior != "" ? (', interior ' + this.customerDueño.cliente.no_interior) : '') + ', colonia ' + this.customerDueño.cliente.colonia + ', ciudad ' + this.customerDueño.cliente.ciudad + ', estado ' + this.customerDueño.cliente.estado +
                ', país ' + this.customerDueño.cliente.pais + ', código postal ' + this.customerDueño.cliente.cp
                + '\n\n' +
                'd).- Que no tiene conocimiento alguno sobre si “EL ARRENDATARIO” se encuentra o ha estado involucrado, directa o indirectamente en la comisión de delitos particularmente aquellos que establece la Ley Nacional de Extinción de Dominio reglamentaria del artículo 22 de la Constitución Política de los Estados Unidos Mexicanos, por lo que hasta donde es de su conocimiento “EL ARRENDATARIO” se dedica exclusivamente a la realización de actividades lícitas. \n\n' +
                'e).- Que al no conocer sobre la realización por parte de “EL ARRENDATARIO” de ninguno de los hechos ilícitos y delitos a los que se refiere la Ley Nacional de Extinción de Dominio reglamentaria del artículo 22 de la Constitución Política de los Estados Unidos Mexicanos, actúa con absoluta buena fe en la celebración de este contrato. \n\n'
            },
            {
              fontSize: 10.3,
              text:
                'f).- Qué es su voluntad celebrar el presente contrato de arrendamiento y que no tiene impedimento legal para obligarse en términos del mismo, por lo que se obliga de conformidad a las cláusulas que más adelante se precisan. \n\n' +
                'g).- Qué es su voluntad celebrar el presente contrato, y desea otorgar en arrendamiento el inmueble indicado en la declaración "a" de este apartado. \n\n\n' +
                '2.- DECLARA “EL ARRENDATARIO”: \n\n' +
                'a).- Llamarse como ha quedado establecido, ser una persona de nacionalidad Mexicana en pleno ejercicio de su capacidad legal, por lo que no tiene impedimento alguno para celebrar el presente contrato de arrendamiento y obligarse al cumplimiento de las cláusulas que contiene el presente acuerdo de voluntades. \n\n' +
                'b).- Que bajo protesta de decir verdad y enterado de las consecuencias legales que esto conlleva, manifiesta que el bien inmueble materia del presente contrato, lo destinará única y exclusivamente para un fin lícito, mismo que se determina en el cuerpo del presente y que cubrirá la renta y pago de servicios oportunamente, con los recursos propios que recibe de su trabajo, derivado de la actividad lícita a la que se dedica. \n\n' +
                'c).- Que a la firma del presente contrato se identifica con ' + this.customer.cliente.identificacion + '. \n\n' +
                'd).- El arrendatario manifiesta que para efectos del presente contrato señala como domicilio para oír y recibir notificaciones el inmueble objeto del presente contrato de arrendamiento, asimismo manifiesta que dichas notificaciones aún las de carácter personal se podrán realizar con cualquier persona que se encuentre en dicho inmueble. \n\n' +
                'e).- Que conoce perfectamente el inmueble descrito en las declaraciones de “EL ARRENDADOR” y es su interés tomar en arrendamiento el inmueble ubicado en calle' + this.article.articulo.calle + ', #' + this.article.articulo.no_exterior
                + (this.article.articulo.no_interior != "" ? (', interior ' + this.article.articulo.no_interior) : '') + ', colonia ' + this.article.articulo.colonia + ', ciudad ' + this.article.articulo.ciudad + ', estado ' + this.article.articulo.estado +
                ', país ' + this.article.articulo.pais + ', código postal ' + this.article.articulo.cp
                + ' materia de este contrato y usarlo exclusivamente para fines lícitos más no corruptivos; así como respetar el uso y tipo de suelo que las autoridades competentes predeterminen o bien autoricen.\n\n' +
                'f).- Que recibió de conformidad el inmueble materia de este instrumento a su entera satisfacción con todas las instalaciones completas y funcionando y que los recursos con los que pagará el arrendamiento serán obtenidos de manera lícita. \n\n\n\n'
            },
          ],
        },
        {
          bold: true,
          text: '1 de 6',
          alignment: 'center',
        },
        '\n',
        {
          bold: true,
          text: this.article.id_articulo + "\n\n",
          alignment: 'center',
        },
        {
          alignment: 'justify',
          columns: [
            {
              fontSize: 10.3,
              text:
                'g).- Que por así convenir a sus intereses es su deseo y está dispuesto a tomar en arrendamiento el inmueble que se describe en la declaración 1 inciso “a” de conformidad con los términos y condiciones establecidos en el presente contrato. Que en sus actividades jamás ha incurrido en la comisión de delito alguno, incluyendo los que establece la Ley Nacional de Extinción de Dominio reglamentaria del artículo 22 de la Constitución Política de los Estados Unidos Mexicanos y los que establece la Constitución Política de los Estados Unidos Mexicanos. \n\n' +
                'h).- Que durante la vigencia de este Contrato y mientras se encuentre en posesión del inmueble, tomará todas las medidas razonables y convenientes para evitar que cualquier persona, incluyendo sus funcionarios, empleados, prestadores de servicios, visitantes, clientes, y en general, cualquier persona a la que permita el ingreso al inmueble, cometa delito alguno, particularmente aquellos a los que se refiere la Ley Nacional de Extinción de Dominio reglamentaria del artículo 22 de la Constitución Política de los Estados Unidos Mexicanos. \n\n' +
                'i).- Qué es su deseo e intención celebrar el presente Contrato de arrendamiento y arrendar el inmueble a “EL ARRENDADOR” en los términos y bajo las condiciones que establece el presente contrato, asegurando que todas las declaraciones, manifestaciones y garantías hechas al presente instrumento son verdaderas, precisas y ciertas. \n\n\n' +
                '3.- DECLARA “EL OBLIGADO SOLIDARIO”: \n\n' +
                'a).- Llamarse como ha quedado establecido, ser una persona de nacionalidad MEXICANA en pleno ejercicio de su capacidad legal, por lo que no tiene impedimento alguno para celebrar el presente contrato de arrendamiento y obligarse al cumplimiento de las cláusulas que contiene el presente acuerdo de voluntades. \n\n' +
                'b).- Que a la firma del presente contrato se identifica con ' + this.customer.identificacion + ' \n\n' +
                'c).- Que señala como domicilio para oír y recibir toda clase de notificaciones el ubicado en OF ADDRESS OF PERSON WHOS IS THE WARANTEE OF LEASER IN ARTICLE. y que dichas notificaciones aún las de carácter personal se podrán realizar con cualquier persona que se encuentre en dicho inmueble. \n\n'
            },
            {
              fontSize: 10.3,
              text:
                '4.- DECLARAN LAS PARTES: \n\n' +
                'a) Que en el presente contrato no existe dolo, error, mala fe o cualquier otro vicio de la voluntad, por lo que expresamente renuncian a invocarlos en cualquier tiempo. \n\n' +
                'b) Que reconocen la personalidad con la que comparecen a la celebración de este contrato y expresamente convienen en someterse a las obligaciones contenidas en las siguientes: \n\n' +

                'CLÁUSULAS \n\n' +
                'PRIMERA.- OBJETO Y USO DEL BIEN INMUEBLE ARRENDADO  \n\n' +
                '“EL ARRENDADOR” concede a “EL ARRENDATARIO”, el uso y goce temporal del inmueble ubicado en ' + this.article.articulo.calle + ', #' + this.article.articulo.no_exterior
                + (this.article.articulo.no_interior != "" ? (', interior ' + this.article.articulo.no_interior) : '') + ', colonia ' + this.article.articulo.colonia + ', ciudad ' + this.article.articulo.ciudad + ', estado ' + this.article.articulo.estado +
                ', país ' + this.article.articulo.pais + ', código postal ' + this.article.articulo.cp
                + ', el cual se encuentra en perfectas condiciones ya que reúne todas las condiciones de higiene y seguridad que exigen las normas legales aplicables, declarando “EL ARRENDATARIO” que recibe el inmueble arrendado, a su más entera satisfacción. \n\n' +
                'Estableciendo desde este momento que “EL ARRENDADOR” no tendrá acceso alguno al inmueble arrendado, desde el inicio de la vigencia del presente contrato, hasta que se haga la legal entrega del mismo. \n\n' +
                '“EL ARRENDATARIO” se compromete desde este momento en que el inmueble objeto del presente contrato tendrá única y exclusivamente el uso que las autoridades competentes predeterminen o bien autoricen y el incumplimiento a lo anterior será motivo de rescisión del presente contrato, en virtud de que “EL ARRENDATARIO” se compromete a no dar un uso distinto al autorizado por las autoridades competentes, ni realizar actividades ilícitas dentro del mismo. \n\n' +
                'SEGUNDA.- VIGENCIA DE CONTRATO \n\n' +
                'El plazo del arrendamiento es de ' + months + ' (' + monthsToLetter.toUpperCase() + ') meses forzosos para “EL ARRENDATARIO”, vigencia que inicia a partir del día ' + moment(runSaleDate).format("DD-MM-YYYY") + ' y vence el día ' + moment(saleEndedDate).format("DD-MM-YYYY") + ' por lo que sí “EL ARRENDATARIO” desea continuar la relación de arrendamiento, deberá celebrar un nuevo contrato  con “EL ARRENDADOR” con un incremento en la renta del 5% ya que se venció el término citado y si “EL ARRENDATARIO” continuara ocupando el inmueble no se considerará prorrogado el presente contrato y el importe de la renta mensual se incrementará en un 30% mensual por cada mes o fracción, hasta que realice la formal entrega de la localidad a satisfacción de “EL ARRENDADOR”,'
            },
          ],
        }, {
          bold: true,
          text: '2 de 6',
          alignment: 'center',
        },
        {
          bold: true,
          text: this.article.id_articulo + "\n\n",
          alignment: 'center',
        },
        {
          alignment: 'justify',
          columns: [
            {
              fontSize: 10.3,
              text:
                'independientemente de que proceda hacer efectiva la pena prevista para este caso . “EL ARRENDATARIO” renuncia expresamente a los beneficios de prórroga de un año y a la tacita reconducción, contenida en el Código Civil de la entidad en vigor de cuyo contenido y alcance queda enterado por lectura y comentario entre las partes. No obstante lo anterior “EL ARRENDADOR” queda facultado para demandar a “EL ARRENDATARIO” extra-judicial o judicialmente el otorgamiento de un nuevo contrato de arrendamiento o la terminación del contrato con la desocupación y entrega de la localidad arrendada, en cuyo caso “EL ARRENDATARIO” se compromete a desocupar en un plazo no mayor a quince días naturales a partir del día en que se le haga el requerimiento y en caso contrario “EL ARRENDATARIO” pagara daños y perjuicio, gastos y costas que generen la desocupación y entrega del inmueble objeto del arrendamiento. \n\n' +
                'TERCERA.- VALOR DE LA RENTA Y FORMA DE PAGO \n\n' +
                'La renta estipulada por la ocupación de dicho inmueble es la cantidad de ' + this.price_one + '(' + articlePriceLetter + '), mensuales adelantados, con mantenimiento incluido, cantidad que deberá pagar “EL ARRENDATARIO” a “EL ARRENDADOR” por anticipado los días RENTAL PAY DAY WITH NUMBER de cada mes, según corresponda, por lo que todo pago de renta pactada que se realice después de los 5 días posteriores a la fecha de pago de cada mes causará un interés moratorio a razón del 5% mensual, independientemente que se actualice la hipótesis de rescisión del presente contrato por falta de pago puntual de renta. \n\n' +
                'EL ARRENDATARIO” se obliga a cubrir en efectivo el importe de la renta señalada dentro del párrafo que antecede, en el domicilio de “EL ARRENDADOR” y a modo de cortesía en la cuenta bancaria ' + this.customerDueño.beneficiario.num_cuenta + ' Clabe ' + this.customerDueño.beneficiario.clabe + ', institución ' + this.customerDueño.beneficiario.institucion + ' a favor de ' + this.customerDueño.beneficiario.nombre + ' ' + this.customerDueño.beneficiario.apellido + '\n\n' +
                'Ambas partes convienen en que a partir de ' + moment(runSaleDate).format("DD-MM-YYYY") + ' todo mes que ocupe “EL ARRENDATARIO” el inmueble arrendado, se considerará forzoso y deberá pagarlo íntegramente, aun cuando ocupe el inmueble un solo día. \n\n' +
                '“EL ARRENDATARIO” no podrá retener la renta en ningún caso, ni bajo ningún título judicial o extrajudicial, sino que la pagarán íntegramente en los términos pactados en este contrato. \n\n'
            },

            {
              fontSize: 10.3,
              text:




                'CUARTA.- MEJORAS O BENEFICIOS AL INMUEBLE \n\n' +
                'Las partes pactan que todas las obras, mejoras o beneficios que se realicen en el inmueble pasarán a formar parte del patrimonio de “ EL ARRENDADOR”, sin que se genere ninguna contraprestación por dichas mejoras, obras o beneficios. \n\n' +
                '“EL ARRENDATARIO” está de acuerdo y conforme en que cualquier mejora o adaptación que realicen al inmueble materia del presente contrato, solo lo podrán hacer con autorización previa y por escrito de “EL ARRENDADOR”, siempre y cuando no se perjudique la estabilidad del inmueble, y aquellas mejoras que efectúen y que tengan el carácter de permanentes quedarán en beneficio gratuito del arrendador, sin que “EL ARRENDATARIO” pueda destruirlas o retirarlas al término del arrendamiento. Todos los gastos que se eroguen por adaptaciones serán por cuenta y riesgo de “EL ARRENDATARIO”.  \n\n' +
                'Asimismo, en caso de que “EL ARRENDATARIO” obtenga el permiso para realizar mejoras o beneficios al inmueble, desde este momento se obliga a tramitar los permisos administrativos y fiscales necesarios que para tal efecto se requieran, mismos que serán de su entera responsabilidad y correrán por su cuenta.  \n\n' +
                'Dado que dichas reparaciones correrán por cuenta de “EL ARRENDATARIO”, las cargas administrativas que por las mismas se pueden generar, serán de su entera responsabilidad, por lo que en este acto “EL ARRENDATARIO” libera de dichas cargas a “EL ARRENDADOR”, limitando la responsabilidad del arrendador a la mera autorización de dichas reparaciones. \n\n' +
                'QUINTA.- PAGO DESERVICIOS DE INMUEBLE ARRENDADO  \n\n' +
                'Queda expresamente convenido que los gastos que se causen por concepto de consumo de energía eléctrica, gas, agua, teléfono, internet, sistemas de cable o servicios similares, se cubrirán por parte de “EL ARRENDATARIO” ya que serán de su entera responsabilidad, así como también tendrá la obligación de entregar los recibos originales debidamente pagados a “EL ARRENDADOR”, lo anterior en virtud de que no se tendrá por pagada la renta en caso de incumplimiento en el pago de los servicios. \n\n'
            },
          ],
        }, {
          bold: true,
          text: '3 de 6',
          alignment: 'center',
        },
        '\n\n\n',
        {
          bold: true,
          text: this.article.id_articulo + "\n\n",
          alignment: 'center',
        },
        {
          alignment: 'justify',
          columns: [
            {
              fontSize: 10.3,
              text:
                'SEXTA.- DEPÓSITO EN GARANTÍA \n\n' +
                '“EL ARRENDATARIO” entrega en depósito a “EL ARRENDADOR” la cantidad de ' + this.price_one + '(' + articlePriceLetter + '), por concepto de depósito que garantiza el cuidado del inmueble arrendado; este depósito deberá ser actualizado cada vez que se firme un nuevo contrato y por ningún motivo se tomará a cuenta de rentas. \n\n' +
                'Dicho depósito será devuelto 30 días después de que finalice la relación de arrendamiento entre las partes, lo anterior a fin de poder acreditar que se entrega el inmueble arrendado libre de todo adeudo de pago de servicios, así como de vicios ocultos. \n\n' +

                'SÉPTIMA.- LEY DE EXTINCIÓN DE DOMINIO \n\n' +
                '“EL ARRENDATARIO” bajo protesta de decir verdad y a sabiendas de las consecuencias legales que eso conlleva, se compromete a dar única y exclusivamente el uso que las autoridades competentes predeterminen o bien autoricen en el inmueble objeto del presente contrato, pagando la renta y los servicios con los recursos lícitos que obtiene de su actividad lícita a la que se dedica así como también se compromete a no realizar conductas sospechosas y/o inadecuadas ante terceros, ni a realizar actos ilícitos dentro del inmueble arrendado, ni incurrir en algún delito del orden común o federal. \n\n' +
                '“EL ARRENDATARIO” también se obliga a no tener o permitir en dicho inmueble el almacén de armas de fuego, armas blancas, pólvora, explosivos, droga de cualquier naturaleza, instrumentos para procesarla y/o cualquier otro de naturaleza análoga que sirva o haya servido para cometer algún ilícito, siendo responsables de estos, en caso de que se pretenda involucrar al inmueble materia de este contrato y/o al arrendador o propietario del mismo, deslindando desde este momento tanto al inmueble como a su propietario de dicha responsabilidad y por lo tanto, no será aplicable la LEY FEDERAL DE EXTINCIÓN DE DOMINIO o LA LEY DE EXTINCIÓN DE DOMINIO DE LA ENTIDAD. \n\n'
            },
            {
              fontSize: 10.3,
              text:
                'Las partes convienen que “EL ARRENDATARIO”, posesionario y/o ocupantes del inmueble materia del presente contrato serán los únicos responsables si se comete cualquier delito de carácter local o federal y/o dentro del inmueble arrendado, así también “EL ARRENDATARIO” cuidara que no existan personas secuestradas y/o vehículos o instrumentos que provengan de algún ilícito, por lo que no, ni el agente del ministerio público ni la autoridad judicial federal o estatal podrán inculpar a “EL ARRENDADOR” de responsabilidad alguna conforme a la LEY FEDERAL DE EXTINCIÓN DE DOMINIO, ni de ninguna otra legislación estatal o federal aplicable. \n\n' +
                'Convienen las partes en que si se llega a dar el caso de que se inicie un proceso de extinción de dominio en contra del inmueble arrendado, “EL ARRENDATARIO” se obliga a pagar los gastos y honorarios de los abogados que se contraten para la debida defensa, así como a pagar los daños y perjuicios que se le causen a “EL ARRENDADOR”. \n\n' +
                'OCTAVA.- CONTRATACIÓN DE SEGUROS. \n\n' +
                'Las partes convienen que “EL ARRENDADOR” no es responsable de la seguridad de los bienes de “EL ARRENDATARIO” introducidos en el inmueble.\n\n' +
                'Por lo que en caso de contratación de seguros, si fuese de responsabilidad civil el beneficiario será para “EL ARRENDADOR”, si fuese para casa habitación por robo, el beneficiario será para “EL ARRENDATARIO”. \n\n' +
                'NOVENA.- PROHIBICIÓN DE SUBARRENDAR\n' +
                '“EL ARRENDATARIO” se compromete a no subarrendar, ni a ceder a terceras personas, parcial o totalmente, el uso del bien inmueble materia de este contrato. \n\n' +
                'DÉCIMA.- “EL OBLIGADO SOLIDARIO”  \n\n' +
                '“EL OBLIGADO SOLIDARIO” bajo protesta de decir verdad y a sabiendas de las consecuencias legales que eso conlleva, manifiesta que "EL ARRENDATARIO" dará el uso que las autoridades competentes predeterminen o bien autoricen en el inmueble objeto del presente contrato y que este pagará la renta y los servicios con los recursos lícitos que obtiene de su actividad lícita a la que se dedica así como también garantizaque dentro del inmueble arrendado no se realizaran conductas sospechosas e/o inadecuadas ante terceros, ni actos ilícitos, ni mucho menos incurrir en algún delito del orden común o federal. \n\n'
            },
          ],
        },
        {
          bold: true,
          text: '4 de 6',
          alignment: 'center',
        },
        '\n\n\n\n\n\n',
        {
          bold: true,
          text: this.article.id_articulo + "\n\n",
          alignment: 'center',
        },
        {
          alignment: 'justify',
          columns: [
            {
              fontSize: 10.3,
              text:
                '“EL OBLIGADO SOLIDARIO”, para garantizar las obligaciones contraídas en el presente contrato y en particular para garantizar el pago de las rentaspactadas durante el término del arrendamiento, se constituye como obligado solidario y mancomunado de “EL ARRENDATARIO” garantizando todas sus obligaciones y renuncia además a los beneficios de orden y excusión que le concede la ley. \n\n' +
                'Asimismo, “EL OBLIGADO SOLIDARIO” y “EL ARRENDATARIO” responderán ante "EL ARRENDADOR" de cualquier acción de la autoridad competente en aplicación de la Ley Nacional de Extinción de Dominio reglamentaria del artículo 22 de la Constitución Política de losEstados Unidos Mexicanos o de cualquier otra de naturaleza similar, por el uso indebido que se hiciere del inmueble arrendado. \n\n' +
                'DÉCIMA PRIMERA.- CAUSAS DE RESCISIÓN DE CONTRATO \n\n' +
                '“EL ARRENDADOR” podrá rescindir el presente contrato, en los siguientes casos: \n\n' +
                'A) Si “EL ARRENDATARIO” deja de pagar más de una renta. \n\n' +
                'B) Si “EL ARRENDATARIO” deja de pagar los servicios de luz, agua, gas, teléfono, televisión por cable, internet, etc.\n' +
                'C) Si “EL ARRENDATARIO” almacena en el inmueble objetos pesados, flamantes o explosivos que puedan causar daños al inmueble dado en arrendamiento. \n\n' +
                'D) Si “EL ARRENDATARIO” no paga en tiempo y forma la renta pactada.\n\n' +
                'E) Si “EL ARRENDATARIO” tiene o realiza conductas sospechosas u/o inadecuadasante terceros que haga suponer tiene alguna actividad ilícita dentro del inmueble arrendado. \n\n' +
                'F) Sí “EL ARRENDATARIO” incurre en algún delito del orden común o federal dentro del inmueble. \n\n' +
                'G) Si “EL ARRENDATARIO” usa el inmueble para un objeto distinto al señalado en el presente contrato. \n\n' +
                'H) Si “EL ARRENDATARIO” genera escándalos y/o actitudes inapropiados para los vecinos. \n\n' +
                'I) Si “EL ARRENDATARIO” subarrienda en todo o en partes el inmueble arrendado. \n\n'
            },
            {
              fontSize: 10.3,
              text:
                'J) SI “ EL ARRENDATARIO NO DA CUMPLIMIENTO A CUALQUIERA DE LAS CLAUSULAS CONTENIDAS EN EL PRESENTE CONTRATO”. \n\n' +
                'DÉCIMA SEGUNDA.- CAUSAS DE TERMINACIÓN ANTICIPADA \n\n' +
                'Las partes acuerdan que será causa de terminación anticipada del presente contrato, las siguientes: \n\n' +
                'A) Por muerte de “EL ARRENDATARIO”. \n\n' +
                'B) Sí “EL ARRENDADOR” basado en las conductas sospechosas o inadecuadas de “EL ARRENDATARIO” lo hacen suponer que se tiene una actividad ilícita dentro del inmueble arrendado. \n\n' +
                'C) Por voluntad de ambas partes. \n\n' +
                'DÉCIMA TERCERA.- DE LA TERMINACIÓN DEL CONTRATO Y LA DESOCUPACIÓN DEL INMUEBLE. \n\n' +
                'Los contratantes acuerdan que al término de la vigencia de este contrato, “EL ARRENDATARIO” sin necesidad de resolución judicial, se compromete a entregar a “EL ARRENDADOR” el inmueble como lo recibió y con el simple deterioro que por el uso normal sufra el mismo. La devolución del inmueble, objeto del presente contrato de arrendamiento, se hará mediante cartaentrega firmando “EL ARRENDADOR” de conformidad.\n\n' +
                '“EL ARRENDATARIO” acepta y se obliga a pagar los gastos y costos de procedimientos legales, daños y perjuicios que se ocasionen a “EL ARRENDADOR”, además de la cantidad que resulte de dos meses de renta, si se da el caso que siguiere ocupando el inmueble arrendado a pesar dehaber terminado la vigencia del arrendamiento y/o sin consentimiento de “EL ARRENDADOR”. \n\n' +
                'DÉCIMACUARTA.- DESOCUPACIÓN ANTICIPADA DEL INMUEBLE.\n\n' +
                'Ambas partes acuerdan que en caso de que “EL ARRENDATARIO” desee desocupar, el inmueble materia del presente contrato, en forma anticipada y antes de la fecha de vencimiento del presente, tendrá que dar aviso por escrito a “EL ARRENDADOR”con treinta días de anticipación y pagará a “EL ARRENDADOR” una penalidad consistente en 2 dos meses de renta. \n\n'
            },
          ],
        },
        {
          bold: true,
          text: '5 de 6',

          alignment: 'center',
        },
        '\n\n\n\n',
        {
          alignment: 'justify',
          columns: [
            {
              width: 260,
              fontSize: 10.3,
              text:
                'DÉCIMA QUINTA.- EXCLUSIÓN LABORAL \n\n' +
                '“EL ARRENDADOR” no participa de ninguna responsabilidad de liquidación laboral, o empresarial, o de cualquier otra índole, que pudiera tener “EL ARRENDATARIO” con sus empleados y/o socios; tampoco será responsable de cualquier relación obrero-patronal que se genere en este domicilio con “EL ARRENDATARIO”, ni cualquier otra persona física o moral, dentro del inmueble arrendado. “EL ARRENDADOR” no será responsable ni total ni parcialmente de los daños que pudieran afecar a “ EL ARRENDATARIO” por causa de robo a los bienes en el interior delinmueble, vandalismo o accidentes que llegará a sufrir “EL ARRENDATARIO”, su familia, personas contratadas por él ó amistades.  \n\n' +
                'DÉCIMASEXTA.- RENUNCIAS DEL ARRENDATARIO.- “EL ARRENDATARIO” \n\n' +
                'renuncia expresamente a: \n\n' +
                '1. Los Derechos de Preferencia establecidos en el Código Civil del Estado de ' + this.article.articulo.estado.toString().toUpperCase() + ' para el caso de que “EL ARRENDADOR”, deseé volver a arrendar o vender él inmueble a un tercero.\n\n' +
                '2. El Derecho a pedir la modificación del Contrato en los supuestos señalados en el Código Civil del Estado de ' + this.article.articulo.estado.toString().toUpperCase() + ' .\n\n' +
                'Las partes se someten expresamente a las disposiciones del Código Civil para el ESTADO DE ' + this.article.articulo.estado.toString().toUpperCase() + ' y para regular todo lo relativo a este instrumento y para su interpretación y cumplimiento se someten a la jurisdicción de los tribunales en LEÓN, ' + this.article.articulo.estado.toString().toUpperCase() + ', razón por la cual ambos contratantes renuncian expresamente al fuero que por razón de su domicilio presente o futuro pudiera corresponderles. \n\n' +
                'Enteradas ambas partes del presente contrato, lo firman de conformidad en ' + this.article.articulo.ciudad.toString().toUpperCase() + ' , ESTADO DE ' + this.article.articulo.estado.toString().toUpperCase() + ' , por triplicado el día ' + moment(this.saleData.positive.signSaleDate).format("DD-MM-YYYY") + ', quienes de igual forma lo suscriben en todas y cada una de sus hojas que lo integran.  \n\n'
            },
            {
              alignment: 'center',
              fontSize: 10.3,
              text: '\n\n\n\n\n\n\n\n\n\n\n\n"EL ARRENDADOR"\n\n\n\n_______________________________________ \n\n' +
                'Firma del propietario del artículo \n\n\n\n\n\n\n\n' +
                '"EL ARRENDATARIO"\n\n\n\n_______________________________________ \n\n' +
                this.customer.cliente.nombre + '\n\n\n\n\n\n\n\n' +
                '"EL OBLIGADO SOLIDARIO"\n\n\n\n_______________________________________ \n\n' +
                'THE LEASER GUARANTEE',
              width: 260,
            },
          ],
        },
        {
          bold: true,
          text: '6 de 6',
          alignment: 'center',
        },
      ],
      defaultStyle: {
        columnGap: 20,
      },
    };
    pdfMake.createPdf(documentDefinition).open();
  }

  generatePDFPagares() {
    const content = [];
    let runSaleDate = this.saleData.positive.runSaleDate
    let saleEndedDate = this.saleData.positive.saleEndedDate
 

    //obtener meses que hay entre las fechas
    let months = this.getMonths(runSaleDate, saleEndedDate)


    for (let i = 0; i < months; i++) {

      //saber si i es par o impar

      content.push(
        {
          text: i % 2 != 0 ? '\n' : ''
        },
        {

          columns: [
            {
              width: '13.3%',
              fontSize: 10.1,
              text: '',
            },

            {
              width: '73.3%',
              fontSize: 10.1,
              text: (i + 1) + ' de ' + months + '\n\n',
              //centrado
              alignment: 'center',

            },
          ]
        },
        {
          //obtener total de ancho de la pagina

          //tres columnas
          columns: [
            {
              width: '13.3%',
              //color de fondo

              fontSize: 10.1,
              text: 'Pagaré',
              //centrado

            },
            {
              width: '73.3%',
              fontSize: 10.1,
              text: 'En ' + this.article.articulo.ciudad + ' ' + this.article.articulo.estado.toUpperCase() + ' a ' + this.article.articulo.hora,
              alignment: 'center',
              //centrado




            }, {
              width: '20.3%',
              text: 'Bueno por \n ' + this.formatterDolar.format(this.article.articulo.precio) + "MXN",
              fontSize: 10.1,
              alignment: 'center',
            }

          ],


        },
        '\n',

        {

          fontSize: 10.1,
          text: 'Debemos y pagaremos incondicionalmente por este Pagaré a la orden de:\n' +
            '•' + this.customerDueño.cliente.nombre.toUpperCase() + this.customerDueño.cliente.apellido.toUpperCase() + ' \n',

        },
        {

          fontSize: 10.1,
          text: 'En leon GUANAJUATO A 04 DE MARZO DEL 2023\n'
          ,
          alignment: 'center',
        },
        {
          fontSize: 10.1,
          margin: [10, 0, 10, 0],
          text: '_____________________________________________________________________________________________________________',
        },
        {
          alignment: 'justify',
          fontSize: 9.6,
          margin: [10, 0, 40, 0],
          text: "Valor recibido a mi entera satisfacción. Este Pagaré forma parte de una serie numerada del 1 al " + months + " y todos están sujetos a la condición de que, al no pagarse cualquiera de ellos a su vencimiento, serán exigibles todos los que sigan en número, además de los ya vencidos, desde la fecha de vencimiento de éste documento hasta el día de su liquidación, causará intereses moratorios al tipo 5% mensual, pagadero en ésta ciudad juntamente con el principal.",
        },
        {
          fontSize: 10.1,
          margin: [10, 0, 10, 0],
          text: '_____________________________________________________________________________________________________________\n\n',
        },
        {
          columns: [
            {
              width: '70%',
              fontSize: 10.1,
              text: !this.hasAval ? 'NOMBRE Y DATOS DEL DEUDOR\n\n' +
                'Nombre: ' + this.customer.cliente.nombre.toUpperCase() + this.customer.cliente.apellido.toUpperCase() + '\n\n' +
                'Direccion: San Francisco # 10.13 \n\n\n\n'
                : 'NOMBRE Y DATOS DEL DEUDOR\n\n' +
                'Nombre: ' + this.customer.cliente.nombre.toUpperCase() + this.customer.cliente.apellido.toUpperCase() + '\n\n' +
                'Direccion: San Francisco # 10.13 \n\n\n\n\n\n',
            },
            {
              width: '30%',
              fontSize: 10.1,
              text: this.hasAval ? 'Acepto1\n\n\n\n' + '________________________________ ' : '',
              alignment: 'center',

            },
          ]
        },
        {

          width: '100%',
          fontSize: 10.1,
          text: !this.hasAval ? '________________________________ \n Acepto2\n\n' : '',
          alignment: 'center',
        },
        {
          columns: [

            {
              //cambiar el color de fondo
              width: '70%',
              fontSize: 10.1,
              text: this.hasAval && this.aval.data.no_interior == "" ? 'NOMBRE Y DATOS DEL AVAL\n\n' +
                'Nombre: ' + this.aval.data.nombre.toUpperCase() + this.aval.data.apellido.toUpperCase() + '\n\n' +
                'Direccion:' + this.aval.data.calle + '/' + this.aval.data.colonia + '/ #' + this.aval.data.no_exterior + ' \n\n' :
                this.hasAval && this.aval.data.no_interior != "" ?
                  'NOMBRE Y DATOS DEL AVAL\n\n' +
                  'Nombre: ' + this.aval.data.nombre.toUpperCase() + this.aval.data.apellido.toUpperCase() + '\n\n' +
                  'Direccion:' + this.aval.data.calle + '/' + this.aval.data.colonia + '/ #' + this.aval.data.no_exterior + '/ int #' + this.aval.data.no_exterior + ' \n\n' :

                  '',
            }, {
              width: '30%',
              fontSize: 10.1,
              text: this.hasAval ? 'Acepto3\n\n\n\n' + '________________________________' : '',
              alignment: 'center',

            },],


        },

        {
          fontSize: 10.1,
          text: i % 2 == 0 ? "^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^" : ""

        },

        //saber cuanto espacio hay en blanco
        {
          text: '',
          pageBreak: ((i % 2) != 0) && i + 1 != months ? 'before' : ''
        }



      );
    }
    const documentDefinition = {
      info: {
        title: `Producto ${String(
          'producto'
        ).toUpperCase()}`,
        author: 'Net99',
        subject: 'Contrato de arrendamiento',
        keywords: 'Contrato, arrendamiento, leasing, renta, renta de equipo, renta de equipo de computo, renta de equipo de computo en mexico, rent',

      },
      pageSize: 'LETTER',
      pageMargins: [35, 20, 35, 0],


      content: content,


    };
    pdfMake.createPdf(documentDefinition).open();

  }
  formatterPrice(price: number, articulo: any) {
    let articleCurrency = articulo.articulo.currency;
    if (articleCurrency == 'EUR') {
      const formatterEuro = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
      });
      let letter = formatterEuro.format(price);
      this.price_one = formatterEuro.format(price) + ' EUR';
      this.price_two = formatterEuro.format(price) + ' EUR';
      return letter.replace('$', '€');
    } else if (articleCurrency == 'USD') {
      const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      // return formatterDolar.format(price)
      this.price_one = formatterDolar.format(price) + ' USD';
      this.price_two = formatterDolar.format(price) + ' USD';
    } else {
      const formatterPeso = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
      });

      this.price_one = formatterPeso.format(price) + ' MXN';
      this.price_two = ' (00/100 MN)';
      // let prices = {
      //   price_one,
      //   price_two
      // }
      // return prices
    }
  }
  getMonths(runLeaseDate: any, saleEndedDate: any) {
    const startDate = moment(runLeaseDate);
    const endDate = moment(saleEndedDate);
    const months = endDate.diff(startDate, 'months');
    return months;
  }
  getDateMeetingChange1(event: any) {
    //Run lease date
    this.dateMeeting1 = moment(event.target.value).format()
    let positives: Array<any> = this.customer.positives;
    let index = positives.findIndex(
      (positive: any) => positive.id == this.id
    );
    this.customer.positives[index].runSaleDate = moment(event.target.value).format();
    this.afs.collection(this.customer.escalate ? 'CUSTOMER' : 'LEAD')
      .doc(this.idDocument).set(this.customer);
    // console.log(this.dateFinish.diff(this.dateMeeting, 'days'))
  }

  getDateMeetingChange2(event: any) {
   


    let positives: Array<any> = this.customer.positives;
    let index = positives.findIndex(
      (positive: any) => positive.id == this.id
    );
    this.customer.positives[index].signSaleDate = moment(event.target.value).format();
    this.afs.collection(this.customer.escalate ? 'CUSTOMER' : 'LEAD')
      .doc(this.idDocument).set(this.customer).then(() => {

      });

  }
  getDateMeetingChange3(event: any) {
    //Lease ended date
    this.dateMeeting3 = moment(event.target.value).format()

    let positives: Array<any> = this.customer.positives;
    let index = positives.findIndex(
      (positive: any) => positive.id == this.id
    );
    this.customer.positives[index].saleEndedDate = moment(event.target.value).format();
    this.afs.collection(this.customer.escalate ? 'CUSTOMER' : 'LEAD')
      .doc(this.idDocument).set(this.customer);
  }
  setDateMeeting1() {

    this.editDateMeeting1 = true

  }
  setDateMeeting2() {
    this.editDateMeeting2 = true

  }
  setDateMeeting3() {
    this.editDateMeeting3 = true

  }
}
