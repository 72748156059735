import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NumeroALetrasService {


  constructor() { }

  unidades(num) {
    switch (num) {
      case 1:
        return 'Un';
      case 2:
        return 'Dos';
      case 3:
        return 'Tres';
      case 4:
        return 'Cuatro';
      case 5:
        return 'Cinco';
      case 6:
        return 'Seis';
      case 7:
        return 'Siete';
      case 8:
        return 'Ocho';
      case 9:
        return 'Nueve';
      default:
        return '';
    }
  }

  decenasY(strSin, numUnidades) {
    if (numUnidades > 0) {
      return strSin + ' y ' + this.unidades(numUnidades);
    }

    return strSin;
  }

  decenas(num) {
    var numDecena = Math.floor(num / 10);
    var numUnidad = num - numDecena * 10;

    switch (numDecena) {
      case 1:
        switch (numUnidad) {
          case 0:
            return 'Diez';
          case 1:
            return 'Once';
          case 2:
            return 'Doce';
          case 3:
            return 'Trece';
          case 4:
            return 'Catorce';
          case 5:
            return 'Quince';
          default:
            return 'Dieci' + this.unidades(numUnidad).toLowerCase();
        }
      case 2:
        switch (numUnidad) {
          case 0:
            return 'Veinte';
          default:
            return 'Veinti' + this.unidades(numUnidad).toLowerCase();
        }
      case 3:
        return this.decenasY('Treinta', numUnidad);
      case 4:
        return this.decenasY('Cuarenta', numUnidad);
      case 5:
        return this.decenasY('Cincuenta', numUnidad);
      case 6:
        return this.decenasY('Sesenta', numUnidad);
      case 7:
        return this.decenasY('Setenta', numUnidad);
      case 8:
        return this.decenasY('Ochenta', numUnidad);
      case 9:
        return this.decenasY('Noventa', numUnidad);
      case 0:
        return this.unidades(numUnidad);
      default:
        return '';
    }
  }

  centenas(num) {
    var numCentenas = Math.floor(num / 100);
    var numDecenas = num - numCentenas * 100;

    switch (numCentenas) {
      case 1:
        if (numDecenas > 0) {
          return 'Ciento ' + this.decenas(numDecenas);
        }
        return 'Cien';
      case 2:
        return 'Doscientos ' + this.decenas(numDecenas);
      case 3:
        return 'Trescientos ' + this.decenas(numDecenas);
      case 4:
        return 'Cuatrocientos ' + this.decenas(numDecenas);
      case 5:
        return 'Quinientos ' + this.decenas(numDecenas);
      case 6:
        return 'Seiscientos ' + this.decenas(numDecenas);
      case 7:
        return 'Setecientos ' + this.decenas(numDecenas);
      case 8:
        return 'Ochocientos ' + this.decenas(numDecenas);
      case 9:
        return 'Novecientos ' + this.decenas(numDecenas);
      default:
        return this.decenas(numDecenas);
    }
  }

  seccion(num, divisor, strSingular, strPlural) {
    var numCientos = Math.floor(num / divisor);
    var numResto = num - numCientos * divisor;

    var letras = '';

    if (numCientos > 0) {
      if (numCientos > 1) {
        letras = this.centenas(numCientos) + ' ' + strPlural;
      } else {
        letras = strSingular;
      }
    }

    if (numResto > 0) {
      letras += '';
    }

    return letras;
  }

 miles(num) {
  var divisor = 1000;
  var numCientos = Math.floor(num / divisor);
  var numResto = num - numCientos * divisor;
  var strMiles = this.seccion(num, divisor, 'Un Mil', 'Mil');
  var strCentenas = this.centenas(numResto);

  if (strMiles === '') {
    return strCentenas;
  }

  return (strMiles + ' ' + strCentenas).trim();
}

 millones(num) {
  var divisor = 1000000;
  var numCientos = Math.floor(num / divisor);
  var numResto = num - numCientos * divisor;
  var strMillones = this.seccion(num, divisor, 'Un Millón de', 'Millones de');
  var strMiles = this.miles(numResto);

  if (strMillones === '') {
    return strMiles;
  }

  return (strMillones + ' ' + strMiles).trim();
}

 NumerosALetrasSinPrecio(num) {
  var data = {
    numero: num,
    enteros: Math.floor(num),
    centavos: Math.round(num * 100) - Math.floor(num) * 100,
    letrasCentavos: '',
  
  };


  if (data.enteros === 0) {
    return ('Cero ').trim();
  }

  if (data.enteros === 1) {
    return (this.millones(data.enteros)).trim();
  }

  return (this.millones(data.enteros)).trim();
}
}
