<div class="main">
  <div class="center">
    <div>
      <div class="title mb-4 text-center">
        <h1>Datos del usuario</h1>
      </div>
      <div class="inputs">
        <form [formGroup]="registerForm" (ngSubmit)="onRegister()">
          <div class="row mb">
            <div class="col">
              <div class="form-outline">
                <label for="name">Nombre</label>
                <input id="name" type="text" class="form-control"
                  [ngClass]="{invalid:name.invalid && name.dirty, valid:!name.invalid }" formControlName="name" />
              </div>
            </div>
          </div>
          <div class="row mb">
            <div class="col">
              <div class="form-outline">
                <label for="lastname">Apellido</label>
                <input id="lastname" type="text" class="form-control"
                  [ngClass]="{invalid:lastname.invalid && lastname.dirty, valid:!lastname.invalid }"
                  formControlName="lastname" />
              </div>
            </div>
          </div>
          <div class="buttons mb-4 text-center">
            <button type="submit" [disabled]="registerForm.invalid"
              class="btn btn-dark">
             Continuar
            </button>
            <!--  -->
          </div>
          <!-- <div class="alert alert-danger" role="alert" *ngIf="invalid">
              {{ message_error }}
            </div> -->
        </form>
      </div>
    </div>
    <div class="alerts mt-2">
      <div class="alert alert-danger" role="alert" *ngIf="codeError">
        <h6 class="mt-2">{{messageError}}</h6>
      </div>
    </div>
    <div class="register">
      <div class="flex">
        <p>¿Ya tienes cuenta?</p>
        <a [routerLink]="['/login']">Iniciar Sesión</a>
      </div>
    </div>
  </div>
  <!-- alertas -->
</div>