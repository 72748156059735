<div class="main">
    <div class="center">
        <div class="token" style="text-align: center;
        display: flex;
        justify-content: center;">

            <div class="card" style="width: 18rem;" *ngIf="seconds > 0 ">
                <div class="card-body" *ngIf="tokenInformation != null && tokenInformation != undefined">
                    <h5 *ngIf="seconds > 0" class="card-title">{{tokenInformation.token}}</h5>
                    <p *ngIf="seconds > 0">El token vencerá en {{seconds}} segundos</p>
                    <p *ngIf="seconds == 0">El token ha vencido, genera uno nuevo</p>

                </div>
                <div class="card-body" *ngIf="tokenInformation == null || tokenInformation == undefined">
                    <h5 class="card-title">Generando token...</h5>


                </div>
            </div>
            <div class="card cardNewToken" *ngIf="seconds == 0 " style="width: 18rem; cursor: pointer;" (click)="generateNewToken()">
                <div class="card-body" *ngIf="tokenInformation != null && tokenInformation != undefined">
                    <p>Generar nuevo token</p>
                    >
                </div>

            </div>
        </div>

    </div>
</div>