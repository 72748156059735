export const environment = {
  production: true,
  // firebaseConfig: {
  //   apiKey: "AIzaSyABKOV-rRVVi1TppEYe7cZwqcX8PeOPQug",
  // authDomain: "set99devv2.firebaseapp.com",
  // projectId: "set99devv2",
  // storageBucket: "set99devv2.appspot.com",
  // messagingSenderId: "429357940845",
  // appId: "1:429357940845:web:d2f29235b21028dafb3f64",
  // measurementId: "G-N4TC36W9V9"
  // },
  firebaseConfig: {
    apiKey: "AIzaSyCb6J0l04dJD1rzbpz5_pyQPd86vlfnYXM",
    authDomain: "set99-12303.firebaseapp.com",
    projectId: "set99-12303",
    storageBucket: "set99-12303.appspot.com",
    messagingSenderId: "34115313896",
    appId: "1:34115313896:web:8539b39e43ec26a8dee95d",
    measurementId: "G-EXFV4362WV"
  },
  // firebaseConfig: {
  //   apiKey: "AIzaSyABKOV-rRVVi1TppEYe7cZwqcX8PeOPQug",
  //   authDomain: "set99devv2.firebaseapp.com",
  //   projectId: "set99devv2",
  //   storageBucket: "set99devv2.appspot.com",
  //   messagingSenderId: "429357940845",
  //   appId: "1:429357940845:web:d2f29235b21028dafb3f64",
  //   measurementId: "G-N4TC36W9V9"
  // },
};
