import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SendEmailComponent } from './auth/send-email/send-email.component';
import {
  AngularFirestore,
  AngularFirestoreModule,
} from '@angular/fire/compat/firestore';
import { AuthService } from './SERVICIOS/auth.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { environment } from 'src/environments/environment.prod';
import { BUCKET } from '@angular/fire/compat/storage';

import { AdminModule } from './ADMIN/admin.module';

import { CommonModule } from '@angular/common';
import { ChatsComponent } from './chats/ChatsComponent';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { ChatBubbleComponent } from './chats/chat-bubble/chat-bubble.component';

import { HttpClientModule } from '@angular/common/http';
import { ProfileComponent } from './profile/profile.component';
import { ClipboardModule } from 'ngx-clipboard';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import { NgxPaginationModule } from 'ngx-pagination';
import { TermsComponent } from './auth/terms/terms.component';
import { PrivacyComponent } from './auth/privacy/privacy.component';
import { ComercialModule } from './COMERCIAL/comercial.module';
import { FilterchatPipe } from './COMERCIAL/pipes/filterchat.pipe';
import { NgChartsModule } from 'ng2-charts';
import { DateAgoPipe } from './COMERCIAL/pipes/date-ago.pipe';
import { AllarticlesComponent } from './allarticles/allarticles.component';
import { PhoneLoginComponent } from './auth/phone-login/phone-login.component';
import { VerificationCodeComponent } from './auth/verification-code/verification-code.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { AddEmailComponent } from './auth/add-email/add-email.component';

@NgModule({
  declarations: [
    ChatBubbleComponent,
    AppComponent,
    FilterchatPipe,
    SendEmailComponent,
    DateAgoPipe,
    ChatsComponent,
    ProfileComponent,
    TermsComponent,
    PrivacyComponent,
    AllarticlesComponent,
    PhoneLoginComponent,
    VerificationCodeComponent,
    AddEmailComponent,
  ],
  imports: [
    BrowserModule,
    ComercialModule,
    CommonModule,
    ClipboardModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    NgChartsModule,
    AdminModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    NgxPaginationModule, NgOtpInputModule
  ],
  providers: [
    AuthService,
    AngularFirestore,
    //{ provide: BUCKET, useValue: 'gs://set99devv2.appspot.com' },
   { provide: BUCKET, useValue: 'gs://set99-12303.appspot.com' }
    // { provide: BUCKET, useValue: 'gs://set99devv2.appspot.com' }

  ],

  bootstrap: [AppComponent],
})
export class AppModule { }
