<div class="main" *ngIf="todo && user">
  <div class="fx-title mb-5 mt-3">
    <i class="large arrow left icon" (click)="back()" style="cursor:pointer;"></i>
    <h3 class="title" style="margin: 0;">Información del cliente</h3>
    <div></div>
  </div>



  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{todo.identificacion}} {{todo.cliente.nombre | titlecase}}
            {{todo.cliente.apellido | titlecase}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body all_images">
          <div class="images" *ngFor="let images of todo.urlImage">
            <img [src]="images.url" alt="" />
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="ui button" data-bs-dismiss="modal">
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-5" style="display: flex;
        flex-direction: row;
        justify-content: space-evenly;">

    <div class="text-center shadow-lg p-3 mb-5 card" *ngIf="!UpdateUserData">
      <div class="mb-4">
        <h4 class="">Datos del cliente</h4>
        <a *ngIf="myCustomer" (click)="UpdateDataUser()">Editar</a>
      </div>

      <div class="" style="display: flex;
            justify-content: center;">

        <div>
          <div class="form-outline mb-3" style="margin: 0 30px;">
            <div class="lead" style="font-weight: bold;">Nombre</div>
            <p>{{ todo.cliente.nombre}}</p>

          </div>
          <div class="form-outline mb-3" style="margin: 0 30px;">
            <div class="lead" style="font-weight: bold;">Teléfono</div>
            <p>{{ todo.cliente.telefono}}</p>

          </div>
          <div class="form-outline mb-3" style="margin: 0 30px;">
            <div class="lead" style="font-weight: bold;">ID</div>

            <p>{{ todo.id}}</p>
          </div>
        </div>
        <div>
          <div class="form-outline mb-3" style="margin: 0 30px;">
            <div class="lead" style="font-weight: bold;">Apellido</div>
            <p>{{ todo.cliente.apellido}}</p>
          </div>
          <div class="form-outline mb-3" style="margin: 0 30px;">
            <div class="lead" style="font-weight: bold;">Correo</div>
            <p *ngIf="todo.cliente.correo == ''">No ingresado</p>

            <p *ngIf="todo.cliente.correo != ''"> {{ todo.cliente.apellido}}</p>
          </div>
          <div class="form-outline " style="margin: 0 30px;">
            <div class="lead" style="font-weight: bold;">Agente</div>
            <p>{{ todo.cliente.apellido}}</p>
          </div>

        </div>

      </div>
      <div *ngIf="!my_customer && auth.userFullAdmin(user)">
        <div class="see-id" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@getbootstrap">
          <p> Ver identificación</p>
        </div>

      </div>
    </div>

    <div class="text-center shadow-lg p-3 mb-5 card" *ngIf="UpdateUserData">
      <div>
        <div class="mb-4">
          <h4 class="">Datos del cliente</h4>

        </div>
        <form [formGroup]="userForm" (ngSubmit)="updateUser()">
          <input type="text" name="nombre" [ngClass]="{invalid:nombre.invalid && nombre.dirty, valid:!nombre.invalid }"
            class="form-control mb-3" formControlName="nombre" [placeholder]="name" />
          <input type="text" name="apellido"
            [ngClass]="{invalid:apellido.invalid && apellido.dirty, valid:!apellido.invalid }" class="form-control mb-3"
            formControlName="apellido" [placeholder]="lastname" />
          <input type="text" name="identificacion"
            [ngClass]="{invalid:identificacion.invalid && identificacion.dirty, valid:!identificacion.invalid }"
            class="form-control mb-3" formControlName="identificacion" [placeholder]="identification" />
          <input placeholder="correo" type="text" name="correo"
            [ngClass]="{invalid:correo.invalid && correo.dirty, valid:!correo.invalid }" class="form-control mb-3"
            formControlName="correo" [placeholder]="email" />

          <div *ngIf="UpdateUserData" class="mt-4 mb-4">
            <button class="ui primary button" [disabled]="userForm.invalid" (click)="updateUser()">
              Actualizar
            </button>
            <span style="cursor: pointer;" class="ui button" (click)="UpdateDataUser()">Cancelar</span>
            <div class="mt-3">
              <i class="exclamation circle icon"></i>
              Se necesita aprobación
            </div>
          </div>
        </form>
      </div>
    </div>



    <div class="text-center shadow-lg p-3 mb-5 card" *ngIf="!UpdateAdressClientData">

      <div class="mb-4">
        <h4 class="">Dirección del cliente</h4>
        <a *ngIf="myCustomer" (click)="UpdateAddressClient()">Editar</a>
      </div>

      <div style="display: flex;">

        <div>
          <div class="form-outline mb-3" style="margin: 0 30px;">
            <div class="lead" style="font-weight: bold;">Calle</div>
            <p>{{ todo.cliente.calle}}</p>

          </div>
          <div class="form-outline mb-3" style="margin: 0 30px;">
            <div class="lead" style="font-weight: bold;">Ciudad</div>
            <p>{{ todo.cliente.ciudad}}</p>
          </div>
          <div class="form-outline " style="margin: 0 30px;">
            <div class="lead" style="font-weight: bold;">País</div>
            <p>{{ todo.cliente.pais}}</p>
          </div>

        </div>

        <div>
          <div class="form-outline mb-3" style="margin: 0 30px;">
            <div class="lead" style="font-weight: bold;">No.exterior</div>
            <p>{{ todo.cliente.no_exterior}}</p>
          </div>

          <div class="form-outline mb-3" style="margin: 0 30px;">
            <div class="lead" style="font-weight: bold;">Estado</div>
            <p>{{ todo.cliente.estado}}</p>
          </div>

        </div>
        <div>
          <div class="form-outline mb-3" style="margin: 0 30px;">
            <div class="lead" style="font-weight: bold;">No.interior</div>
            <p *ngIf="todo.cliente.no_interior != ''">{{ todo.cliente.no_interior}}</p>
            <p *ngIf="todo.cliente.no_interior == ''">No ingresado</p>
          </div>
          <div class="form-outline mb-3" style="margin: 0 30px;">
            <div class="lead" style="font-weight: bold;">Colonia</div>
            <p>{{ todo.cliente.colonia}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center shadow-lg p-3 mb-5card" *ngIf="UpdateAdressClientData">
      <h4 class="mb-4">Dirección del cliente</h4>

      <form [formGroup]="userForm" (ngSubmit)="updateUser()">
        <input type="text" name="postal"
          [ngClass]="{invalid:codigo_postal.invalid && codigo_postal.dirty, valid:!codigo_postal.invalid }"
          class="form-control mb-3 " formControlName="cp" (input)="searchNh($event.target.value)" [placeholder]="pc" />

        <input type="text" name="colonia" class="form-control mb-3" formControlName="colonia"
          [ngClass]="{invalid:col.invalid && col.dirty, valid:!col.invalid }" [placeholder]="colonia" />

        <input type="text" name="calle" [ngClass]="{invalid:calle.invalid && calle.dirty, valid:!calle.invalid }"
          class="form-control mb-3" formControlName="calle" [placeholder]="street" />


        <input type="text" name="no_exterior"
          [ngClass]="{invalid:no_exterior.invalid && no_exterior.dirty, valid:!no_exterior.invalid }"
          class="form-control mb-3" formControlName="no_exterior" [placeholder]="noexterior" />


        <input type="text" name="no_interior"
          [ngClass]="{invalid:no_interior.invalid && no_interior.dirty, valid:!no_interior.invalid }"
          class="form-control mb-3" formControlName="no_interior" [placeholder]="nointerior" />

        <input type="text" name="ciudad" [ngClass]="{invalid:ciudad.invalid && ciudad.dirty, valid:!ciudad.invalid }"
          class="form-control mb-3" formControlName="ciudad" [placeholder]="citys" />


        <input type="text" name="estado" [ngClass]="{invalid:estado.invalid && estado.dirty, valid:!estado.invalid }"
          class="form-control mb-3" formControlName="estado" [placeholder]="state" />

        <input type="text" name="pais" class="form-control mb-3" placeholder="México" disabled />
        <div *ngIf="UpdateAdressClientData" class="mt-4 mb-4">
          <button class="ui primary button" [disabled]="userForm.invalid" (click)="updateUser()">
            Actualizar
          </button>
          <span style="cursor: pointer;" class="ui button" (click)="UpdateAddressClient()">Cancelar</span>
          <div class="mt-3">
            <i class="exclamation circle icon"></i>
            Se necesita aprobación
          </div>
        </div>
      </form>

    </div>

  </div>



  <!-- <div class="fx-title mb-4 mt-3">
          <i class="large arrow left icon" (click)="back()" style="cursor:pointer;"></i>
          <h2 class="title" style="margin: 0;">Información del Ingreso</h2>
          <div></div>
        </div>


        <div class="status" *ngIf="todo.estatus == 'deshabilitado'">
          <div style="display: flex; align-items: center;">
            <h3 style="font-weight: bold;"> Estatus: Inhabilitado</h3>
          </div>
          <div class="arguments" *ngIf="todo.estatus == 'deshabilitado' && todo.argumento">

            <h6 style="font-weight: bold;"> Observación: {{todo.argumento}}</h6>
          </div>
        </div>
        <div class="subtitle mb-3 fx">
          <h5>Información del cliente</h5>
          <div style="display: flex; flex-direction: column;
          align-items: flex-end;" *ngIf="auth.userFullAdmin(user)">
            <button (click)="resendIdentification()" [disabled]="todo.mandarIdentificacion" class="ui button">Pedir reenvio de
              Indentificación</button>
          </div>
          <div style="display: flex; flex-direction: column;
          align-items: flex-end;" *ngIf="myCustomer && todo.mandarIdentificacion == true">
            <button (click)="sendIdentification() " class="ui primary button">Volver a subir la identificación</button>
          </div>
        </div>


        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{todo.identificacion}} {{todo.cliente.nombre | titlecase}}
                  {{todo.cliente.apellido | titlecase}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body all_images">
                <div class="images" *ngFor="let images of todo.urlImage">
                  <img [src]="images.url" alt="" />
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="ui button" data-bs-dismiss="modal">
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>


        <div class="shadow-lg p-3 mb-5card" *ngIf="todo.cliente.nombre && !UpdateUserData">
          <div class="card-income ">
            <div class="info-income">
              <div class="lead" style="font-weight: bold;">Nombre</div>
              <p>{{ todo.cliente.nombre}} {{ todo.cliente.apellido }}</p>
            </div>
            <div class="info-income">
              <div class="lead mt-2" style="font-weight: bold;">ID de identificación</div>
              <p>{{ todo.cliente.identificacion}}</p>
            </div>
            <div class="info-income">
              <div class="lead mt-2" style="font-weight: bold;">Teléfono</div>
              <p>{{ todo.cliente.telefono}}</p>
            </div>

            <div class="info-income">
              <div class="lead mt-2" style="font-weight: bold;">Correo</div>
              <p *ngIf=" todo.cliente.correo !== '' ">{{ todo.cliente.correo }}</p>
              <p *ngIf=" todo.cliente.correo == '' ">Correo no ingresado</p>
            </div>
            <div class="info-income">
              <div class="lead mt-2" style="font-weight: bold;">Última Actualización</div>
              <p>{{ todo.actualizacionCliente.fecha}}</p>
            </div>
            <div class="info-income">
              <div class="lead mt-2" style="font-weight: bold;">Código Postal</div>
              <p>{{ pc}}</p>
            </div>
            <div class="info-income">
              <div class="lead mt-2" style="font-weight: bold;">Colonia</div>
              <p>{{ colonia}}</p>
            </div>
            <div class="info-income">
              <div class="lead mt-2" style="font-weight: bold;">Calle</div>
              <p>{{ street}}</p>
            </div>
            <div class="info-income">
              <div class="lead mt-2" style="font-weight: bold;">Número exterior</div>
              <p>{{todo.cliente.no_exterior}}</p>
            </div>
            <div class="info-income">
              <div class="lead mt-2" style="font-weight: bold;">Número interior</div>
              <p *ngIf="todo.cliente.no_interior != ''">{{todo.cliente.no_interior }}</p>
              <p *ngIf="todo.cliente.no_interior == ''">No ingresado</p>
            </div>
            <div class="info-income">
              <div class="lead mt-2" style="font-weight: bold;">Ciudad</div>
              <p>{{todo.cliente.ciudad}}</p>
            </div>
            <div class="info-income">
              <div class="lead mt-2" style="font-weight: bold;">Estado</div>
              <p>{{ state}}</p>
            </div>
            <div class="info-income">
              <div class="lead mt-2" style="font-weight: bold;">País</div>
              <p>{{ country}}</p>
            </div>
            <div class="info-income" *ngIf="(auth.userFullAdmin(user) || auth.userAdmin(user)) && agent">
              <div class="lead mt-2" style="font-weight: bold;">Agente asignado</div>
              <p>{{agent.displayName}}</p>
            </div>


          </div>


          <div style=" margin: 10px 10px;">
            <button class=" ui button" (click)="UpdateDataUser()" *ngIf="myCustomer && todo.estatus !== 'deshabilitado'">
              Actualizar
            </button>
            <div *ngIf="!my_customer && auth.userFullAdmin(user)">
              <i class="big address card outline icon" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal"
                data-bs-whatever="@getbootstrap"></i>
            </div>
          </div>

        </div>

        <div class="table-responsive" *ngIf="UpdateUserData">
          <div class="userAd">
            <form [formGroup]="userForm" (ngSubmit)="updateUser()">
              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">Apellidos</th>
                    <th scope="col">ID de identificación</th>
                    <th scope="col">Número de Contacto</th>
                    <th scope="col">Correo Electrónico</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>

  <input type="text" name="nombre" [ngClass]="{invalid:nombre.invalid && nombre.dirty, valid:!nombre.invalid }"
    class="form-control" formControlName="nombre" [placeholder]="name" />
  </td>
  <td>
    <input type="text" name="apellido"
      [ngClass]="{invalid:apellido.invalid && apellido.dirty, valid:!apellido.invalid }" class="form-control"
      formControlName="apellido" [placeholder]="lastname" />
  </td>
  <td>
    <input type="text" name="identificacion"
      [ngClass]="{invalid:identificacion.invalid && identificacion.dirty, valid:!identificacion.invalid }"
      class="form-control" formControlName="identificacion" [placeholder]="identification" />
  </td>
  <td>
    <input type="text" name="telefono"
      [ngClass]="{invalid:telefono.invalid && telefono.dirty, valid:!telefono.invalid }" class="form-control"
      formControlName="telefono" [placeholder]="phone" />
  </td>
  <td>
    <input type="text" name="correo" [ngClass]="{invalid:correo.invalid && correo.dirty, valid:!correo.invalid }"
      class="form-control" formControlName="correo" [placeholder]="email" />
  </td>
  </tr>
  </tbody>
  </table>
  <table class="table">
    <thead class="thead-dark">
      <tr>
        <th scope="col">Código Postal</th>
        <th scope="col">Colonia</th>
        <th scope="col">Calle</th>
        <th scope="col">Número Exterior</th>
        <th scope="col">Número Interior</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <input type="text" name="postal"
            [ngClass]="{invalid:codigo_postal.invalid && codigo_postal.dirty, valid:!codigo_postal.invalid }"
            class="form-control" formControlName="cp" (input)="searchNh($event.target.value)" [placeholder]="pc" />
        </td>
        <td>
          <input type="text" name="colonia" class="form-control" formControlName="colonia"
            [ngClass]="{invalid:col.invalid && col.dirty, valid:!col.invalid }" [placeholder]="colonia" />
        </td>
        <td>
          <input type="text" name="calle" [ngClass]="{invalid:calle.invalid && calle.dirty, valid:!calle.invalid }"
            class="form-control" formControlName="calle" [placeholder]="street" />

        </td>
        <td>
          <input type="text" name="no_exterior"
            [ngClass]="{invalid:no_exterior.invalid && no_exterior.dirty, valid:!no_exterior.invalid }"
            class="form-control" formControlName="no_exterior" [placeholder]="noexterior" />

        </td>
        <td>
          <input type="text" name="no_interior"
            [ngClass]="{invalid:no_interior.invalid && no_interior.dirty, valid:!no_interior.invalid }"
            class="form-control" formControlName="no_interior" [placeholder]="nointerior" />

        </td>
      </tr>
    </tbody>
  </table>

  <table class="table">
    <thead class="thead-dark">
      <tr>
        <th scope="col">Ciudad</th>
        <th scope="col">Estado</th>
        <th scope="col">País</th>
      </tr>
    </thead>
    <tbody>
      <tr>

        <td>
          <input type="text" name="ciudad" [ngClass]="{invalid:ciudad.invalid && ciudad.dirty, valid:!ciudad.invalid }"
            class="form-control" formControlName="ciudad" [placeholder]="citys" />

        </td>

        <td>
          <input type="text" name="estado" [ngClass]="{invalid:estado.invalid && estado.dirty, valid:!estado.invalid }"
            class="form-control" formControlName="estado" [placeholder]="state" />
        </td>
        <td>
          <input type="text" name="pais" class="form-control" placeholder="México" disabled />
        </td>
        <td></td>
      </tr>
    </tbody>
  </table>
  </form>
</div>
</div>



<div *ngIf="UpdateUserData" class="mt-4 mb-4">
  <button class="ui primary button" [disabled]="userForm.invalid" (click)="updateUser()">
    Actualizar
  </button>
  <span style="cursor: pointer;" class="ui button" (click)="UpdateDataUser()">Cancelar</span>
  <div class="mt-3">
    <i class="exclamation circle icon"></i>
    Se necesita aprobación
  </div>
</div>




contacto adiconal -->


  <div style="display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;">
    <h4 class="text-center">Contacto adicional</h4>
    <a (click)="UpdateDataUserAdd()" *ngIf="myCustomer && !UpdateUserDataAdd && todo.cliente.adicional_nombre != ''">
      Editar</a>
  </div>
  <div class="" *ngIf="todo.cliente.adicional_nombre && !UpdateUserDataAdd">
    <div class="card-income  shadow-lg p-3 mb-5card">
      <div class="info-income ">
        <div class="lead text-center" style="font-weight: bold;">Nombre(s)</div>
        <p class="text-center">{{ todo.cliente.adicional_nombre}}</p>
      </div>
      <div class="info-income ">
        <div class="lead text-center" style="font-weight: bold;">Apellidos</div>
        <p class="text-center"> {{ todo.cliente.adicional_apellido }}</p>
      </div>
      <div class="info-income">
        <div class="lead text-center mt-2" style="font-weight: bold;">Teléfono</div>
        <p class="text-center">{{ todo.cliente.adicional_telefono}}</p>
      </div>
      <div class="info-income">
        <div class="lead text-center mt-2" style="font-weight: bold;">Correo</div>
        <p class="text-center" *ngIf=" todo.cliente.adicional_correo !== '' ">{{ todo.cliente.adicional_correo }}</p>
        <p class="text-center" *ngIf=" todo.cliente.adicional_correo == '' ">Correo no ingresado</p>
      </div>


      <!-- <p>{{ todo.actualizacionCliente.fecha }}</p> -->
      <!-- <div class="mt-4">
              <button class="ui button" (click)="UpdateDataUserAdd()" *ngIf="myCustomer">
                Actualizar
              </button>
            </div> -->
    </div>
  </div>
  <div class="table-responsive" *ngIf="UpdateUserDataAdd">

    <div>
      <form [formGroup]="userAdForm">
        <table class="table">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Apellidos</th>
              <th scope="col">Número de Contacto</th>
              <th scope="col">Correo Electrónico</th>

            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input type="text" name="nombre"
                  [ngClass]="{invalid:adicional_nombre.invalid && adicional_nombre.dirty, valid:!adicional_nombre.invalid }"
                  class="form-control" formControlName="nombre" [placeholder]="add_name" />
              </td>
              <td>
                <input type="text" name="apellido"
                  [ngClass]="{invalid:adicional_apellido.invalid && adicional_apellido.dirty, valid:!adicional_apellido.invalid }"
                  class="form-control" formControlName="apellido" [placeholder]="add_lastname" />
              </td>
              <td>
                <input type="text" name="telefono"
                  [ngClass]="{invalid:adicional_telefono.invalid && adicional_telefono.dirty, valid:!adicional_telefono.invalid }"
                  class="form-control" formControlName="telefono" [placeholder]="add_phone" />
              </td>
              <td>
                <input type="text" name="correo"
                  [ngClass]="{invalid:adicional_correo.invalid && adicional_correo.dirty, valid:!adicional_correo.invalid }"
                  class="form-control" formControlName="correo" [placeholder]="add_email" />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>





  <div *ngIf="!todo.cliente.adicional_nombre && !UpdateUserDataAdd && myCustomer">
    <div class="text-center mt-2" style="width: 90%; margin: auto; display: flex;flex-direction: column;
         align-items: center;">
      <button class="ui primary button" *ngIf="todo.estatus !== 'deshabilitado'; else noContactAd"
        (click)="addContactAd()">Agregar contacto adicional</button>
      <ng-template #noContactAd>
        <p class="text-center">No se agregó el Contacto Adicional</p>
      </ng-template>
    </div>
  </div>
  <div *ngIf="!todo.cliente.adicional_nombre && !myCustomer">
    <div class="text-center mt-2" style="width: 90%; margin: auto; display: flex;flex-direction: column;
          align-items: center;">
      <p>No existe información</p>
    </div>
  </div>

  <div *ngIf="UpdateUserDataAdd" class="mt-3">
    <button *ngIf="!addContactAdicional" class="ui primary button" [disabled]="userAdForm.invalid"
      (click)="updateUserAd()">
      Actualizar
    </button>
    <button *ngIf="addContactAdicional" class="ui primary button" [disabled]="userAdForm.invalid" (click)="addUserAd()">
      Agregar
    </button>
    <button class="ui button" (click)="cancelUpdateDataUserAdd()" style="cursor: pointer;">
      Cancelar
    </button>
    <div class="mt-3" *ngIf="addContactAdicional">
      <i class="exclamation circle icon"></i>
      No se necesita aprobación
    </div>
    <div class="mt-3" *ngIf="!addContactAdicional">
      <i class="exclamation circle icon"></i>
      Se necesita aprobación
    </div>
  </div>




  <div class="text-center" style="display: flex; justify-content: space-around;">

    <div style="width: 47%;" class="shadow-lg p-3 mb-5card">
      <button (click)="goToOwnArticles()" class="mt-2 ui primary button">ARTÍCULOS DEL
        CLIENTE</button>

      <div class="mt-4" style="width:90%; margin:auto">
        <div style="position: relative; display: flex;
              flex-direction: row;
              justify-content: space-around;">
          <div style="display: flex;flex-direction: column;">
            <div class="lead text-center mt-2" style="font-weight: bold;">Número de artículos</div>
            <p>{{getTotalArticles()}}</p>
          </div>
          <div style="display: flex;flex-direction: column;">
            <div class="lead text-center mt-2" style="font-weight: bold;">Productos activos</div>
            <p>{{getProductsArticles()}}</p>
          </div>

        </div>

      </div>
      <!-- <div style="display: flex; justify-content: flex-end;">
              <button *ngIf="myCustomer && todo.estatus !== 'deshabilitado'" class="ui button addArticle mt-4 mb-1"
                (click)="addArticle()">Añadir Nuevo
                Articulo</button>
            </div> -->
    </div>
    <div style="width: 47%; position: relative;" class="shadow-lg p-3 mb-5card">
      <button (click)="goToOthersArticles()" class="mt-2 ui primary button">OTROS
        ARTÍCULOS</button>

      <div class="mt-4" style="width:95%; margin:auto">
        <div style="display: flex; flex-direction: row; justify-content: space-between;">
          <div style="display: flex;flex-direction: column;">
            <div class="lead text-center mt-2" style="font-weight: bold;">Número de artículos</div>
            <p>{{articlesRelation}}</p>
          </div>
          <div style="display: flex;flex-direction: column;">
            <div class="lead text-center mt-2" style="font-weight: bold;">Artículos positivos</div>
            <p>{{articlesPositives}}</p>
          </div>
          <div style="display: flex;flex-direction: column;">
            <div class="lead text-center mt-2" style="font-weight: bold;">Artículos negativos</div>
            <p>{{articlesNegatives}}</p>
          </div>
        </div>

      </div>

    </div>

  </div>
  <div style="display: flex; flex-direction: column;
    align-items: flex-end;" *ngIf="myCustomer && todo.mandarIdentificacion == true">
    <button (click)="sendIdentification() " class="ui primary button">Volver a subir la identificación</button>
  </div>
  <div style="display: flex; justify-content:end;" *ngIf="!myCustomer" class="mt-5">
    <div class="ui primary button" (click)="createAnObservation()">Hacer una observación</div>
    <div style="display: flex; flex-direction: column;
    align-items: flex-end;" *ngIf="auth.userFullAdmin(user)">
      <button (click)="resendIdentification()" [disabled]="todo.mandarIdentificacion" class="ui button">Pedir reenvio de
        Indentificación</button>
    </div>

  </div>


  <!-- <div class="grid-articles mt-5">
          <div *ngFor="let article of resultados; let i = index" class="card card-article ">
            <h4>{{ productSelected[i] | uppercase }}</h4>
            <h6>{{article.id_articulo }}</h6>


            <div *ngIf="todo.estatus == 'incompleto'">
              <button *ngIf="article.completado == true" class="ui button" (click)="fullview(article)">Mostrar
                articulo</button>
            </div>
            <div *ngIf="todo.estatus !== 'incompleto'">
              <div>
                <button *ngIf="article.completado == false && myCustomer && todo.estatus !== 'deshabilitado'"
                  class="ui button" (click)="completeArticle(article)">
                  Completar
                </button>
                <div *ngIf="article.completado == true" class="ui animated button" tabindex="0"
                  (click)='advanceArticle(article)'>
                  <div class="visible content">Ver más</div>
                  <div class="hidden content">
                    <i class="right arrow icon"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->



  <!-- <div class="table-responsive">
          <div class="artiles" *ngIf="resultados">
            <table class="table shadow-lg p-3 mb-5card">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Estatus del producto</th>
                  <th scope="col">Tipo de producto</th>
                  <th scope="col">Fecha De Inicio</th>
                  <th scope="col">Fecha De Vencimiento</th>
                  <th scope="col">Días restantes</th>
                  <th scope="col">Ver</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let articulo of resultados; let i = index" class="info-articles"
                  [ngClass]="{'orange':articulo.estatus == 'por vencer','red':articulo.estatus == 'deshabilitado' || articulo.estatus == 'vencido'  }">
                  <td *ngIf="todo.estatus == 'deshabilitado'  && !auth.userFullAdmin(user) "
                    title="No Se Puede Descargar El PDF">
                    No disponible
                  </td>
                  <td>{{ articulo.id_articulo }}</td>
                  <td *ngIf=" articulo.estatus == 'en espera' && todo.estatus == 'deshabilitado'; else noDesabled">
                    Inhabilitado</td>
                  <ng-template #noDesabled>
                    <th>{{articulo.commodity.estatus | titlecase}}</th>
                  </ng-template>
                  <td>{{ productSelected[i] | titlecase}}</td>
                  <td>{{ newDate[i] }}</td>
                  <td *ngIf="duedates[i] != 'No Disponible'">{{ duedates[i] | date:'dd-MM-yyyy'}}</td>
                  <td *ngIf="duedates[i] == 'No Disponible'">No Disponible</td>

                  <th>{{ difss[i] }}</th>
                  <td>pendiente...</td> -->
  <!-- <td>
          <button *ngIf="articulo.completado == true" class="ui button" (click)="fullview(articulo)">Mostrar
            articulo</button>
        </td> -->
  <!-- <td>
          <button *ngIf="articulo.completado == false && myCustomer && todo.estatus !== 'deshabilitado'" class="ui button"
            (click)="completeArticle(articulo)">
            Completar
          </button>
          <div *ngIf="articulo.completado == true" class="ui animated button" tabindex="0" (click)='advanceArticle(articulo)'>
            <div class="visible content">Ver más</div>
            <div class="hidden content">
              <i class="right arrow icon"></i>
            </div>
          </div>
        </td> -->

  <!-- </tr>
        </tbody>
        </table> -->
  <!-- </div>
      </div> -->



  <!-- <div *ngIf="resultados.length == 0 && auth.userFullAdmin(admin)">
          <p class="text-center mt-2">No se han agregado articulos a este cliente</p>
        </div> -->

</div>






<!-- <div *ngIf="myCustomer && resultados.length == 0">
        <div class="text-center mt-2" style="width: 90%; margin: auto; display: flex;flex-direction: column;
        align-items: center;">
          <button class="ui primary button" *ngIf="todo.estatus == 'deshabilitado'" disabled>Añadir Nuevo
            Articulo</button>
        </div>
        <div class="text-center mt-2" style="width: 90%; margin: auto; display: flex;flex-direction: column;
        align-items: center;">
          <button class="ui primary button" *ngIf="todo.estatus != 'deshabilitado'" (click)="addArticle()">Añadir Nuevo
            Articulo</button>
        </div>
      </div> -->

<div class="ui basic modal one" style="width: 100%; margin: 25vh auto; overflow: hidden;">
  <div class="ui icon header text-center" *ngIf="!rejected_article">
    <i class="exclamation icon"></i>
    Rechazar Articulo
  </div>
  <div class="content text-center" *ngIf="!rejected_article">
    <p>¿Estás seguro de rechazar el articulo?</p>
  </div>
  <div class="actions" style="display: flex; justify-content: center;" *ngIf="!rejected_article">
    <div class="ui red basic cancel inverted button" (click)="hideModalNegative()">
      <i class="remove icon"></i>
      No
    </div>
    <div class="ui green ok inverted button" (click)="showModalArgument()">
      <i class="checkmark icon"></i>
      Sí
    </div>
  </div>

  <div class="ui icon header text-center" *ngIf="rejected_article">
    <i class="exclamation icon"></i>
    Escribe la observación
  </div>
  <div class="content text-center" *ngIf="rejected_article">
    <form [formGroup]="argumentForm" (ngSubmit)=" disapproveArticle()">
      <div class="row mb">
        <textarea formControlName="argument" [ngClass]="{invalid:argumento.invalid && argumento.dirty}"
          class="form-control" name="argument" rows="4"></textarea>
      </div>
    </form>
  </div>
  <div class="actions" style="display: flex; justify-content: center;" *ngIf="rejected_article">
    <div class="ui red basic cancel inverted button" (click)="hideModalNegative()">
      <i class="remove icon"></i>
      Cancelar
    </div>
    <div class="ui green ok inverted button" (click)=" disapproveArticle()">
      <i class="checkmark icon"></i>
      Finalizar
    </div>
  </div>

</div>

<div class="ui basic modal two" style="width: 100%; margin: 25vh auto; overflow: hidden;">
  <div class="ui icon header text-center" *ngIf="!disabled_article">
    <i class="exclamation icon"></i>
    Deshabilitar Articulo
  </div>
  <div class="content text-center" *ngIf="!disabled_article">
    <p>¿Estás seguro de deshabilitar el articulo?</p>
  </div>
  <div class="actions" style="display: flex; justify-content: center;" *ngIf="!disabled_article">
    <div class="ui red basic cancel inverted button" (click)="hideModalNegative()">
      <i class="remove icon"></i>
      No
    </div>
    <div class="ui green ok inverted button" (click)="showArgument()">
      <i class="checkmark icon"></i>
      Sí
    </div>
  </div>



  <div class="ui icon header text-center" *ngIf="disabled_article">
    <i class="exclamation icon"></i>
    Escribe la observación
  </div>
  <div class="content text-center" *ngIf="disabled_article">
    <form [formGroup]="argumentForm" (ngSubmit)="disableArticles()">
      <div class="row mb">
        <textarea formControlName="argument" [ngClass]="{invalid:argumento.invalid && argumento.dirty}"
          class="form-control" name="argument" rows="4"></textarea>
      </div>
    </form>
  </div>
  <div class="actions" style="display: flex; justify-content: center;" *ngIf="disabled_article">
    <div class="ui red basic cancel inverted button" (click)="hideModalNegative()">
      <i class="remove icon"></i>
      Cancelar
    </div>
    <div class="ui green ok inverted button" (click)="disableArticles()">
      <i class="checkmark icon"></i>
      Finalizar
    </div>
  </div>
</div>
<div class="ui basic modal createObservation" style="width: 100%; margin: 25vh auto; overflow: hidden;">
  <div class="ui icon header text-center">
    <h1> Escribe la observación</h1>
  </div>
  <div class="content text-center">
    <form [formGroup]="observationForm" (ngSubmit)="sendObservation()">
      <div class="row mb">
        <textarea formControlName="observation" [ngClass]="{invalid:observation.invalid && observation.dirty}"
          class="form-control" name="observation" rows="4"></textarea>
      </div>
    </form>
  </div>
  <div class="actions" style="display: flex; justify-content: center;">
    <div class="ui red basic cancel inverted button" (click)="hideModalNegative()">
      <i class="remove icon"></i>
      Cancelar
    </div>
    <div class="ui green ok inverted button" (click)="sendObservation()">
      <i class="checkmark icon"></i>
      Enviar Observación
    </div>
  </div>
</div>

<ng-template #myModal>
  <div class="main">
    <div class="container-modal">
      <modal-content>
        <h5 class="lead" *ngIf="sameNumber">Este número ya está relacionado con otro usuario</h5>
      </modal-content>
      <modal-footer class="btn-options">
        <button class="ui button fs-mini" (click)="closeModal()">
          De Acuerdo
        </button>
      </modal-footer>
    </div>
  </div>
</ng-template>

<ng-template #modalNoArticles>
  <div class="main">
    <div class="container-modal">
      <modal-content>
        <h5 class="lead">Este cliente no tiene articulos</h5>
      </modal-content>
      <modal-footer class="btn-options">

        <div style="display: flex; justify-content: flex-end;">

          <button class="ui button addArticle mt-4 mb-1" (click)="closeModal()"> De Acuerdo</button>
          <button *ngIf="myCustomer && todo.estatus !== 'deshabilitado'" class="ui primary button addArticle mt-4 mb-1"
            (click)="addArticle()">Añadir Nuevo
            Articulo</button>
        </div>
      </modal-footer>
    </div>
  </div>
</ng-template>
<ng-template #modalNoOthersArticles>
  <div class="main">
    <div class="container-modal">
      <modal-content>
        <h5>No hay articulos</h5>
      </modal-content>
      <modal-footer class="btn-options">

        <div style="display: flex; justify-content: flex-end;">

          <button class="ui button addArticle mt-4 mb-1" (click)="closeModal()"> De Acuerdo</button>
          <button *ngIf="myCustomer && todo.estatus !== 'deshabilitado'" class="ui primary button addArticle mt-4 mb-1"
            (click)="AddNewOtherArticle()">Añadir Nuevo

            Articulo</button>
        </div>
      </modal-footer>
    </div>
  </div>
</ng-template>
<ng-template #modal_update>
  <div class="main">
    <div class="container-modal">
      <modal-content>
        <h5 class="lead">Se ha enviado una solicitud de cambio</h5>
      </modal-content>
      <modal-footer class="btn-options">
        <button class="ui button" (click)="closeModalUpdate()" style="margin-right: 5px">
          Cerrar
        </button>
        <button class="ui button" (click)="goToChanges()">
          Ir a cambios
        </button>
      </modal-footer>
    </div>
  </div>
</ng-template>
<ng-template #modal_observation>
  <div class="main">
    <div class="container-modal">
      <modal-content>
        <h5 class="lead text-center">{{txt_observation}}</h5>
      </modal-content>
      <modal-footer class="btn-options mt-4">
        <button class="ui button" (click)="closeModalUpdate()">
          Cerrar
        </button>
      </modal-footer>
    </div>
  </div>
</ng-template>