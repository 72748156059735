import { Component, OnInit } from '@angular/core';
import { SocketService } from 'src/app/SERVICIOS/socket/socket.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  constructor(private socket: SocketService,
  ) { }

  ngOnInit(): void {
  }

  sendEvent() {
    this.socket.io.emit("add-product")

  }


}
