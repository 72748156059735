import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Item } from "../../MODULOS/registra/item";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { DataDbService } from "src/app/SERVICIOS/data-db.service";
import { SocketService } from "src/app/SERVICIOS/socket/socket.service";
import { AuthService } from "src/app/SERVICIOS/auth.service";
import { take } from "rxjs/operators";
import { Subscription } from "rxjs";
// Adds the CPU backend.
import * as tf from "@tensorflow/tfjs";
import * as tmImage from "@teachablemachine/image";
import { HttpClient } from "@angular/common/http";
import { join } from "path";
import { promises as fsPromises } from "fs";

declare let $: any;

type ItemData = (Item & { checked: boolean })[];

const checkify = <T>(items: T[]): (T & { checked: boolean })[] =>
  items.map((item) => ({ ...item, checked: false }));

@Component({
  selector: "app-results",
  templateUrl: "./results.component.html",
  styleUrls: ["./results.component.scss"],
})
export class ResultsComponent implements OnInit {
  p: number = 1;
  idR: string;
  art_selected = [];
  articles = [];
  public getIdArticles = [];
  itemsObtained: boolean = false;
  articlesSelected = [];
  getIdArt = [];
  guardarID = [];
  uid_user: any;
  id: number;
  total_articles: number = undefined;
  subs: Subscription;
  noArticleFound: boolean = false;
  isCuarentena: boolean = false;
  articles_obtained = [];
  tagsToSearch: any;
  id_lead: any;
  URLModel = "https://teachablemachine.withgoogle.com/models/JQeM2O0qq/";
  model: any;
  precioDesde: number;
  precioHasta: number;
  superFicieDesde: number;
  superFicieHasta: number;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authSvc: AuthService,
    private afs: AngularFirestore,
    private sockets: SocketService,
    private http: HttpClient,
  ) { }




  ngOnInit() {
    this.loadModel();
    this.idR = this.route.snapshot.paramMap.get("id");
    this.authSvc.getCurrentUser().then((values) => {
      this.uid_user = values.uid;

    });
    this.afs.collection("LEAD").doc(this.idR)
      .valueChanges().pipe(take(1)).toPromise().then((lead: any) => {
        //saber si ya esta terminado o no

        this.tagsToSearch = lead.tagsToSearch;
        this.id_lead = lead.id;
        this.precioDesde = Number(lead.precioDesde);
        this.precioHasta = Number(lead.precioHasta);
        this.superFicieDesde = Number(lead.superficieDesde);
        this.superFicieHasta = Number(lead.superficieHasta);
        this.getArticles(lead.tagsToSearch);
      });

    this.afs.collection("LEAD").valueChanges().pipe(take(1)).toPromise().then(
      (values) => this.id = values.length,
    );
  }
  async setModel(articles) {
    let articlesTags = []
    for (let index = 0; index < articles.length; index++) {
      const file: File = articles[index].imageFile;
      const article = articles[index].article
      try {
        if (!this.model) {
          this.model = await tf.loadLayersModel(
            this.URLModel + "model.json",
          ); // Asegúrate de que la URL sea válida
        }
        // Crear un bitmap a partir del archivo
        let bitmap = await createImageBitmap(file);

        // Crear un canvas temporal
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");

        // Ajustar el tamaño del canvas al del bitmap
        canvas.width = bitmap.width;
        canvas.height = bitmap.height;

        // Dibujar el bitmap en el canvas
        ctx.drawImage(bitmap, 0, 0);

        // Obtener el ImageData del canvas
        let imageData = ctx.getImageData(0, 0, bitmap.width, bitmap.height);

        let img = tf.browser.fromPixels(imageData);
        // Redimensionar el tensor a la forma esperada por el modelo
        let resized = tf.image.resizeBilinear(img, [224, 224]);

        // Agregar una dimensión extra al tensor
        let tensor = resized.expandDims(0);

        // Obtener una predicción con el modelo
        let predictions = await this.model.predict(tensor).data();
        let AllPredictions = []
        let max = []

        for (let i = 0; i < predictions.length; i++) {
          //mostrar los 3 primeros resultados
          AllPredictions.push(predictions[i]);
        }

        //quitar los que tengan 0.0
        AllPredictions.filter((number) => {
          return number > 0.1
        })

        //acomodar los resultados de mayor a menor
        max = AllPredictions.sort((a, b) => b - a);




        //cargar los labels si estan en un archivo.txt
        let labels = [];
        let urlLabels = "./../../../../assets/labels.txt";
        let response = await fetch(urlLabels);
        let data = await response.text();
        let lines = data.split("\n");
        lines.forEach((element) => {
          labels.push(element);
        });

        //poner los labels en el orden de los resultados
        let labelsOrdered = [];
        for (let i = 0; i < max.length; i++) {


          let index = AllPredictions.indexOf(max[i]);
          //quitar el numero de la etiqueta
          let label = labels[index].split(":")
          labelsOrdered.push(label[1] + ":" + max[i]);


        }



        // Filtrar números que empiezan con 0.1 hasta 0.9
        const articleTags = labelsOrdered.filter(label => {


          const strNumero = label.split(":")[1]

          return strNumero.startsWith('0.1') || strNumero.startsWith('0.2') ||
            strNumero.startsWith('0.3') || strNumero.startsWith('0.4') ||
            strNumero.startsWith('0.5') || strNumero.startsWith('0.6') ||
            strNumero.startsWith('0.7') || strNumero.startsWith('0.8') ||
            strNumero.startsWith('0.9') ||
            strNumero.startsWith('1.0');
        }).map(dato => dato.replace(/:[\d.]+/, ''))














        articlesTags.push({
          id_articulo: article.id_articulo,
          articleTags,
          article
        })



        // console.log(labels[maxIndex]);
        if (index == articles.length - 1) this.compareLabels(articlesTags, article);
      } catch (error) {
        console.log(error);

      }
    }



  }
  compareLabels(articlesTags: any, article: any,) {
    const tagsSeleccionados = this.tagsToSearch
      .filter((tag) => tag.seleccionado)
      .map((tag) => tag.descripcion.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase());



    articlesTags.forEach((data) => {
      let count = 0;
      let tags = data.articleTags
      let tagsCurrent = tags.map((tag) => tag.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase());
      let id_articulo = data.id_articulo

      tagsCurrent.forEach((tag: any) => {
        tagsSeleccionados.forEach((tagSelected: string | any[]) => {
          if (tagSelected.includes(tag)) count++
        });
        if (!this.articles.some((article) => article.id_articulo === id_articulo))
          if (
            (tagsSeleccionados.length === 1 && count === 1) ||
            (tagsSeleccionados.length === 2 && count >= 1 && count <= 2) ||
            (tagsSeleccionados.length === 3 && count >= 2 && count <= 3) ||
            (tagsSeleccionados.length > 3 && count >= tagsSeleccionados.length - 3)
          ) this.articles.push(data.article);
      });

    })



    this.total_articles = this.articles.length;
    if (this.total_articles != 0) {
      for (let i = 0; i <= this.articles.length - 1; i++) {
        let guardado = localStorage.getItem(String(i));
        this.getIdArticles.push(JSON.parse(guardado));
      }
      for (let i = 0; i <= this.getIdArticles.length - 1; i++) {
        if (this.getIdArticles[i] == null) {
          let objeto = {
            id: i,
            selected: false,
          };
          localStorage.setItem(String(i), JSON.stringify(objeto));
          this.art_selected.push(objeto);
        } else this.art_selected.push(this.getIdArticles[i]);
      }

      this.articlesSelected = this.art_selected;
    }
  }
  async loadModel() {
    this.model = await tf.loadLayersModel(
      this.URLModel + "model.json",
    );
  }

  animation() {
    //   $('.autumn.leaf')
    // .transition('fade');
    $(".green.leaf")
      .transition("horizontal flip in")
      .transition("vertical flip in");
  }


  getArticles(tagsSearch) {
    let tagsToSearch: any;
    tagsToSearch = tagsSearch;
    this.subs = this.afs.collection("ARTICLE").valueChanges().subscribe(
      (articles: any) => {
        let article = articles.filter((article: any) =>
          article.completado == true &&
          (article.estatus == "habilitado" ||
            article.estatus == "por vencer") &&
          article.commodity.estatus == "aprobado"
        );
        this.articles = article.filter((inmuebleEncontradoEnDB) => {
          let encontrado = false;
          let contador = 0;
          if (inmuebleEncontradoEnDB.caracteristicas) {
            for (
              const inmCaracteristica of inmuebleEncontradoEnDB.caracteristicas
            ) {
              for (const selectedValueToSearch of tagsToSearch) {
                if (
                  inmCaracteristica.id === selectedValueToSearch.id &&
                  inmCaracteristica.seleccionado ===
                  selectedValueToSearch.seleccionado
                ) {
                  contador++;
                  if (
                    contador >= tagsToSearch.length ||
                    contador == tagsToSearch.length ||
                    contador <= tagsToSearch.length
                  ) {
                    encontrado = true;
                  }
                }
              }
            }
            if (inmuebleEncontradoEnDB["articulo"]['superficie'] != null) {
              if (Number(inmuebleEncontradoEnDB["articulo"]['superficie']) >=
                this.superFicieDesde &&
                Number(inmuebleEncontradoEnDB["articulo"]['superficie']) <=
                this.superFicieHasta) {
                contador++;
              }
            }
            if (inmuebleEncontradoEnDB["articulo"]['precio'] != null) {
              if (Number(inmuebleEncontradoEnDB["articulo"]['precio']) >= this.precioDesde &&
                Number(inmuebleEncontradoEnDB["articulo"]['precio']) <= this.precioHasta) {
                contador++;
              }
            }
            if (contador >= 0) {
              inmuebleEncontradoEnDB.contador = contador;
              inmuebleEncontradoEnDB.caracteristicas = inmuebleEncontradoEnDB
                .caracteristicas.filter((c) => c.seleccionado);
              this.animation();
              return encontrado;
            }
            // inmuebleEncontradoEnDB.contador = contador;
            // inmuebleEncontradoEnDB.caracteristicas = inmuebleEncontradoEnDB
            //   .caracteristicas.filter((c) => c.seleccionado);
            // this.animation();
            // return encontrado;
          }

        });
        this.total_articles = this.articles.length;
        if (this.total_articles == 0) { }
        else {
          for (let i = 0; i <= this.articles.length - 1; i++) {
            let guardado = localStorage.getItem(String(i));
            this.getIdArticles.push(JSON.parse(guardado));
          }
          for (let i = 0; i <= this.getIdArticles.length - 1; i++) {
            if (this.getIdArticles[i] == null) {
              let objeto = {
                id: i,
                selected: false,
              };
              localStorage.setItem(String(i), JSON.stringify(objeto));
              this.art_selected.push(objeto);
            } else {
              this.art_selected.push(this.getIdArticles[i]);
            }
          }

          this.articlesSelected = this.art_selected;
        }
      },
    );
  }
  obtenerImagenComoFile(articles) {
    let allArticles = []
    for (let index = 0; index < articles.length; index++) {
      const articleData = articles[index];

      this.http.get(articleData.url, { responseType: "blob" }).subscribe((blob: Blob) => {
        const imageFile = new File([blob], "nombre_de_archivo.png", {
          type: "image/png",
        });

        let article = {
          imageFile,
          article: articleData.article
        }
        allArticles.push(article)

        if (index == articles.length - 1) {
          this.setModel(allArticles);
        }
      });
    }
  }

  checked(isChecked: boolean, art: any, i: number) {
    this.getIdArt = [];
    let objeto = {
      id: i,
      selected: isChecked,
    };
    localStorage.setItem(String(i), JSON.stringify(objeto));

    for (let i = 0; i <= this.articles.length - 1; i++) {
      let guardado = localStorage.getItem(String(i));
      if (guardado !== null) this.getIdArt.push(JSON.parse(guardado));
    }
    if (this.getIdArt.length > 0) {
      this.articlesSelected = this.getIdArt;
    }
  }
  get selected(): ItemData {
    return this.articles.filter(({ checked }) => checked);
  }

  routerItem(art, i) {
    this.router.navigate([
      "registra",
      this.idR,
      art.id_document,
      "item-view",
      i,
    ]);
  }

  submit() {
    this.getIdArt = [];
    this.articles_obtained = [];
    for (let i = 0; i <= this.articles.length - 1; i++) {
      let guardado = localStorage.getItem(String(i));
      if (guardado !== null) this.getIdArt.push(JSON.parse(guardado));
    }

    if (this.getIdArt.length > 0) {
      for (let i = 0; i <= this.getIdArt.length - 1; i++) {
        if (this.getIdArt[i].selected == true) {
          this.articles_obtained.push(this.articles[i]);
        }
      }
    }

    //saber si eligio algun articulo
    if (this.articles_obtained.length == 0) alert("Elige al menos un articulo");
    else {
      for (let i of this.articles_obtained) {
        let objeto = {
          id: i.id_articulo,
        };
        this.guardarID.push(objeto);
      }

      this.afs.doc("LEAD/" + this.idR).update({
        idArticulos: this.guardarID,

      });
      localStorage.clear();
      // this.afs.collection("LEAD").doc(this.idR).update({});
      //
      this.router.navigate(["markting", this.idR, "agent-selection"]);
    }
  }

  noFoundArticle() {
    this.noArticleFound = true;
  }



  backToEdit() {
    this.router.navigate(["markting", this.idR, "filters"]);
  }

  ngOnDestroy() {
    if (this.subs !== undefined) this.subs.unsubscribe();
  }
}
