<div class="main">

  <div class="fx-title mb-5 mt-4">
    <i class="large arrow left icon" (click)="back()" style="cursor:pointer;"></i>
    <h2 class="title" style="margin-left: 10px;">Información del articulo</h2>
    <div></div>
  </div>

  <div class="images">
    <div class="basic-container">
      <gallery *ngIf="imagesArticle.length > 0 && imageMainArticle" id="basic-test" [items]="items" autoplay="false">
      </gallery>
      <div *ngIf="imagesArticle.length == 0 && !imageMainArticle" class="noImg">
        <h4 class="text-center">Por el momento, no hay fotografías para mostrar</h4>
      </div>
    </div>

    <div class="d-flex justify-content-between mt-2">
      <div></div>
      <div>

        <button (click)="communityPhotos()" class="ui button primary">

          Fotografías de la comunidad
        </button>


      </div>
    </div>
  </div>

  <div class="data" *ngIf="data">
    <div class="info-article">
      <div class="first-info">
        <h3 *ngIf='data.tipo.tipo == "compraventa"'>Véndela por</h3>
        <h3 *ngIf='data.tipo.tipo == "arrendamiento"'>Réntasela por</h3>

        <h3>&nbsp;{{ data.articulo.precio | currency}} {{data.articulo.currency}}</h3>
      </div>
      <!-- <p>{{ data.articulo.precio | currency}} {{data.articulo.currency}}</p> -->
      <p class="text-center">{{data.articulo.calle | titlecase}} #{{data.articulo.no_exterior}},
        <span *ngIf="data.articulo.no_interior !== null && data.articulo.no_interior !== ''  ">int.
          {{data.articulo.no_interior}},</span>
        {{data.articulo.colonia}}, {{ data.articulo.ciudad |titlecase }}, {{ data.articulo.estado | titlecase}},
        {{ data.articulo.pais }},
        {{data.articulo.cp}}
      </p>
      <p>ID del articulo: {{data.id_articulo}}</p>
    </div>

    <!-- <div class="features-article"> -->
    <div class="box-features">
      <div class="Subtitle">
        <h4>Información Básica</h4>
        <hr />
      </div>
      <ul class="ks-cboxtags">
        <li *ngFor="let caract of data.caracteristicas">
          <label *ngIf="caract.seleccionado">
            {{ caract.descripcion }}
          </label>
        </li>
      </ul>
    </div>
    <div class="box-description">
      <div class="Subtitle">
        <h4>Descripción</h4>
        <hr />
      </div>
      <p>{{ data.articulo.descripcion }}</p>
    </div>
  </div>
  <div *ngIf="id">
    <div class="buttons" *ngIf="!isCuarentena">
      <button class="btn btn-primary" (click)="SelectArt()" *ngIf="btnSelectedText == 'Seleccionado'">
        {{ btnSelectedText }}
      </button>
      <button class="ui button" (click)="SelectArt()" *ngIf="btnSelectedText !== 'Seleccionado'">
        {{ btnSelectedText }}
      </button>

      <button class="ui button" (click)="back()">
        Continuar con la Seleccion
      </button>
      <button class="ui button" (click)="finalizeSelection()">
        Finalizar Seleccion
      </button>
    </div>
    <div class="buttons" *ngIf="isCuarentena">
      <button class="ui button" routerLink="/registra">
        Continuar registrando
      </button>
    </div>

  </div>
  <div class="buttons" *ngIf='isArtLis || idArticle_listing'>
    <button *ngIf="!authSvc.userFullAdmin(user) && idArticle_listing" class="ui primary button mb-4"
      (click)="addLead()">Agregar</button>
    <button *ngIf="!authSvc.userFullAdmin(user) && !idArticle_listing" class="ui primary button mb-4"
      (click)="addLead()">Agregar Lead</button>

    <button class="ui button mb-4" (click)="back()">Regresar</button>
  </div>
  <div class="buttons" *ngIf='isCuarentine'>
    <button class="btn btn-primary mb-4" (click)="continueCuarentine()">Ir a gestiona</button>
    <button class="ui button mb-4" (click)="continue()">Ignorar y recibir nuevas notificaciones</button>
    <button class="ui button mb-4" (click)="noNotification()">Ignorar y no recibir nuevas
      notificaciones</button>
  </div>
  <div class="buttons" *ngIf='!isCuarentine && (!isArtLis && !idArticle_listing) && !isCuarentena'>
    <button class="ui button mb-4" (click)="back()">Regresar</button>
  </div>
</div>
