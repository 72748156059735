import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { AuthService } from '../auth.service';
import { DataDbService } from '../data-db.service';
import { GetTokensService } from '../get-tokens.service';
import { SocketService } from './socket.service';


@Injectable({
  providedIn: 'root'
})
export class ChatService {
  chats = []
  new_chats = []
  uid_user: string;
  public getChats: any
  id_document: any
  public isChat_desable: boolean = false;
  msg: any;
  userSupport: boolean = false
  constructor(private socket: SocketService, private tokensUser: GetTokensService, private afs: AngularFirestore, private DataDb: DataDbService, private authsv: AuthService) {
    authsv.getCurrentUser().then(values => {
      if (values) {
        this.uid_user = values.uid
        this.userSocketId(values.uid)
      }
    })
    this.msgReceive();
  }


  userSocketId(uid) {
    this.socket.io.emit("user_id", uid)
  }

  get_id_room(room) {
    this.id_document = room
    return room
  }

  isSupport(isSupport: boolean) {
    if (isSupport == true) {
      this.userSupport = true
    } else {
      this.userSupport = false
    }
  }

  chat_end(obj) {
    this.afs.doc('USERS/' + obj.asunto.id_user + '/SUPPORT_CHATS/' + obj.id_document).update({ resuelto: true, activo_bubble: false, activo: false }).then(res => {
      this.socket.io.emit("chat_ended", obj)
    })
  }

  chat_end_temporary(obj) {
    this.afs.doc('USERS/' + obj.asunto.id_user + '/SUPPORT_CHATS/' + obj.id_document).update({ temporal: true, activo: false, activo_bubble: false }).then(res => {
      this.socket.io.emit("chat_ended_temporary", obj)
    })
  }
  take_up_chat(obj) {


    this.afs.doc('USERS/' + obj.asunto.id_user + '/SUPPORT_CHATS/' + obj.id_document).update({ temporal: false, activo: true, activo_bubble: false }).then(async res => {
      var tokens = await this.tokensUser.getTokens(obj.asunto.id_user);

      let data = {
        tokens,
        activo: obj.activo,
        asunto: obj.asunto,
        id: obj.id,
        id_document: obj.id_document,
        no_leido: obj.no_leido,
        no_leido_support: obj.no_leido_support,
        resuelto: obj.resuelto,
        temporal: obj.temporal,
        tomado: obj.tomado,
        user_support: obj.user_support
      }
      this.socket.io.emit("take_up_chat", data)
    })
  }






  // async message(msg) {
  //   this.chats.push(msg);
  //   this.socket.io.emit("message", msg);
  //   let chats = firebase.firestore.FieldValue.arrayUnion(msg)
  //   const ref = this.afs.collection("USERS")
  //     .doc(this.uid_user)
  //     .collection("SUPPORT_CHATS")
  //     .doc(String(this.id_room))
  //   await ref.update({ chats })
  //   // this.msgReceive(msg)
  // }


  // async msgReceive(msg){
  //   msg.messageType = 2;
  //   let chats = firebase.firestore.FieldValue.arrayUnion(msg)
  //   //   let my_room = msg.room_to;
  //     const reff = this.afs.collection("USERS")
  //     .doc(msg.message_to)
  //     .collection("SUPPORT_CHATS")
  //     .doc(String(msg.room_to))
  //   await reff.update({ chats })
  // }





  async message(msg: any, isSupport: boolean) {

    // let no_leido;
    // let no_leido_support;
    // if (isSupport) {
    //   no_leido = firebase.firestore.FieldValue.increment(1)
    //   no_leido_support = firebase.firestore.FieldValue.increment(0)

    // } else {
    //   no_leido_support = firebase.firestore.FieldValue.increment(1)
    //   no_leido = firebase.firestore.FieldValue.increment(0)
    // }

    this.socket.io.emit("message", msg);
    this.chats.push(msg);
    let chats = firebase.firestore.FieldValue.arrayUnion(msg);
    const ref = this.afs.collection("USERS")
      .doc(msg.user)
      .collection("SUPPORT_CHATS")
      .doc(msg.id_document)
    await ref.update({ chats })
    //  this.saveMsgsend(msg)
    //  this.saveMsgReceive({fecha:msg.fecha, hora:msg.hora, messageType:2,
    //   message_to:msg.message_to, read:msg.read, room_to:msg.room_to,text:msg.text})
  }


  async msgReceive() {
    this.socket.io.on("message_to", (msg) => {
      //condicion para saber si esta en la misma sala o no
      if (msg.id_document == this.id_document) {
        //misma sala
        this.chats.push(msg);
      } else {
        if (this.userSupport == true) {
          let no_leido_support = firebase.firestore.FieldValue.increment(1)
          this.afs.collection('USERS').doc(msg.user).collection('SUPPORT_CHATS').doc(msg.id_document).update({ no_leido_support })
        } else {
          let no_leido = firebase.firestore.FieldValue.increment(1)
          this.afs.collection('USERS').doc(msg.user).collection('SUPPORT_CHATS').doc(msg.id_document).update({ no_leido })
        }
        // this.new_chats.push(msg);
      }
    })
  }


  async saveMsgsend(msg) {
    let chats = firebase.firestore.FieldValue.arrayUnion(msg);
    const ref = this.afs.collection("USERS")
      .doc(this.uid_user)
      .collection("SUPPORT_CHATS")
      .doc(String(this.id_document))
    await ref.update({ chats })
  }

  async saveMsgReceive(msg: {
    fecha, hora, messageType,
    message_to, read, room_to, text
  }) {
    this.DataDb.saveMsg(msg)


  }



}


