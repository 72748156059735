import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserOnlineService {

  constructor() {
    this.userOnline()
  }

  userOnline() {
    return navigator.onLine
  }

}
