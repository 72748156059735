import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanEnterGuard } from 'src/app/SERVICIOS/can/can-enter.guard';
import { AuthGuard } from 'src/app/SERVICIOS/guards/auth.guard';
import { CommunityManageGuard } from 'src/app/SERVICIOS/guards/community-manage.guard';
import { DisabledGuard } from 'src/app/SERVICIOS/guards/disabled.guard';
import { FullAdminGuard } from 'src/app/SERVICIOS/guards/full-admin.guard';
import { SupportGuard } from 'src/app/SERVICIOS/guards/support.guard';

import { ArticuloComponent } from './articulo/articulo.component';
import { IdentificacionComponent } from './identificacion/identificacion.component';
import { IngresaComponent } from './ingresa/ingresa.component';
import { PdfComponent } from './pdf/pdf.component';
import { ProductosComponent } from './productos/productos.component';
import { RegistroClienteComponent } from './registro-cliente/registro-cliente.component';
import { SubirFotoComponent } from './subir-foto/subir-foto.component';
import { TipoComponent } from './tipo/tipo.component';

const routes: Routes = [
  {
    path: 'ingresa',
    component: IngresaComponent,

    children: [
      { path: '', component: RegistroClienteComponent },
      { path: 'escalate/:idLead/:index/:uid_user', component: RegistroClienteComponent },
      {
        path: 'escalate/upload-id/:idClient/:index/:idLead',
        component: SubirFotoComponent,
      },
      // {path:'',component:RegistroClienteComponent},
      // {path:'tipo',component:TipoComponent},
      // {path:'productos',component:ProductosComponent},
      // {path:'registro-cliente',component:RegistroClienteComponent},
      // {path:'identificacion',component:IdentificacionComponent},
      // {path:'subir-foto',component:SubirFotoComponent},
      // {path:'articulo',component:ArticuloComponent},
      // {path:'pdf',component:PdfComponent},

      {
        path: ':idCustomer/identificacion',
        component: IdentificacionComponent,
      },
      { path: ':idCustomer/subir-foto', component: SubirFotoComponent },
      { path: ':idCustomer/subir-foto/:edit', component: SubirFotoComponent },
      { path: ':idCustomer/articulo', component: TipoComponent },
      {
        path: ':idCustomer/articulo/:idArticulo/productos',
        component: ProductosComponent,
      },
      {
        path: ':idCustomer/articulo/:idArticulo/:advance/productos',
        component: ProductosComponent,
      },
      // {path:':idCustomer/registro-cliente',component:RegistroClienteComponent},
      {
        path: ':idCustomer/articulo/:idArticulo/articulos',
        component: ArticuloComponent,
      },
      {
        path: ':idCustomer/articulo/:idArticulo/articulos/:edit',
        component: ArticuloComponent,
      },
      { path: ':idCustomer/articulo/:idArticulo/pdf', component: PdfComponent },
    ],
    canActivate: [
      AuthGuard,
      CanEnterGuard,
      DisabledGuard,
      FullAdminGuard,
      SupportGuard,
      CommunityManageGuard,
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IngresaRoutingModule {}
