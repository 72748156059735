import { Injectable } from '@angular/core';
import { AngularFireAuth, } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { first, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { RoleValidator } from '../auth/helpers/roleValidator';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { GenerateIdService } from './generate-id.service';
import { User } from '../COMERCIAL/MODULOS/user.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends RoleValidator {
  public user$: Observable<User>;
  nameUser: string;
  fechaRegistro: any;
  register_code_error: any;

  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private afs: AngularFirestore,
    private generateId: GenerateIdService
  ) {
    super();

    this.user$ = this.afAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.afs.doc<User>(`USERS/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }

  // _userActionOccured: Subject<void> = new Subject();
  // get userActionOccured(): Observable<void> { return this._userActionOccured.asObservable() };

  // notifyUserAction() {
  //   this._userActionOccured.next();
  // }

  async resetPassword(email: string): Promise<void> {
    try {
      return this.afAuth.sendPasswordResetEmail(email);
    } catch (error) {
      return error;
    }
  }

  async sendVerificationEmail(): Promise<void> {
     (await this.afAuth.currentUser).sendEmailVerification().then((data) => {
      console.log('Email has been sent ', data);
    }).catch((error) => {
      console.log(error);
    }
    );

  }

  loginWithPhoneNumber(phoneNumber: string, appVerifier: any) {
    try {
      return this.afAuth.signInWithPhoneNumber(phoneNumber, appVerifier);
    }
    catch (error) {
      return error;
    }

  }


  //login
  async login(email: string, password: string): Promise<User> {
    const { user } = await this.afAuth.signInWithEmailAndPassword(
      email,
      password
    );
    this.updateUser(user);
    return user;
  }

  async register(
    email: string,
    password: string,
    name: string,
    lastname: string,
    fecha_registro: any
  ): Promise<User> {
    this.fechaRegistro = fecha_registro;
    try {
      const { user } = await this.afAuth.createUserWithEmailAndPassword(
        email,
        password
      );
      let User = firebase.auth().currentUser;
      User.updateProfile({
        displayName: `${name} ${lastname}`,
      }).then((e) => {
        this.updateUserData(user);
        this.sendVerificationEmail();
        this.router.navigate(['/verification-email']);
      });

      return user;
    } catch (error) {
      return error;
    }
  }


  async addUserDataRegisterPhone(
    email: string,
    password: string,
    name: string,
    lastname: string,
    fecha_registro: any
  ): Promise<User> {
    this.fechaRegistro = fecha_registro;
    try {
      const { user } = await this.afAuth.createUserWithEmailAndPassword(
        email,
        password
      );
      let User = firebase.auth().currentUser;
      User.updateProfile({
        displayName: `${name} ${lastname}`,
      }).then((e) => {
        this.updateUserData(user);
        this.router.navigate(['/verification-email']);
      });
      this.sendVerificationEmail();
      return user;
    } catch (error) {
      return error;
    }
  }




















  async logout() {
    try {
      await this.afAuth.signOut();
    } catch (error) { }
  }
  getCurrentUser() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  async getDataUser(uid: string) {
    var data: any = await (
      await this.afs.collection('USERS').doc(uid).get().toPromise()
    ).data();
    return data.id;
  }

  private updateUserData(user: User) {
    let notification = [];
    let uid_user = user.uid;

    const userRef: AngularFirestoreDocument<User> = this.afs.doc(
      `USERS/${user.uid}`
    );
    const data: User = {
      uid: user.uid,
      id: this.generateId.createId(),
      email: user.email,
      emailVerified: user.emailVerified,
      displayName: user.displayName,
      date: this.fechaRegistro,
      status: 'undefined',
      photoURL: user.photoURL,
      role: 'NO-ROLE',
      termsAndConditionsAccepted: false,
      tokens: [],
      registerWithPhone: false,
      phoneNumber: null,
      phoneVerified: false,
    };
    return userRef.set(data, { merge: true });
  }
  private updateUser(user: User) {
    const userRef: AngularFirestoreDocument = this.afs.doc(`USERS/${user.uid}`);

    const data = {
      emailVerified: user.emailVerified,
      displayName: user.displayName,
    };
    return userRef.update(data);
  }
}
