import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterusers'
})
export class FilterusersPipe implements PipeTransform {

  transform(value: any, arg: string, propName: any): any {


    const results = [];
    if (arg === '') return value;
    else {
      for (const item of value) {

        if (propName == "nombre") {
          // let names = item.displayName.split(' ')
          // for (let i = 0; i <= names.length - 1; i++) {
          //   console.log();

          //   if (names[i].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(arg.toLowerCase()) > -1) {
          //     results.push(item)
          //   }
          // }
          if (item.displayName.toLowerCase().includes(arg.toLowerCase())) {
            results.push(item)
          }
        }
        else if (propName == 'id') {
          if (arg) {
            let id = String(item[propName])


            if (id.indexOf(arg) !== -1) {
              results.push(item)
            }

          }

        }
      }
      return results;

    }
  }

}
