<div *ngIf="articles.length > 0">
  <div *ngFor="let article of articles; let i = index">
    <div class="card">
      <h2>{{ article.id_articulo }}</h2>
      <div class="d-flex">
        <input
          type="text"
          class="form-control"
          placeholder="superficie"
          [(ngModel)]="article.superficie"
        />
        <button
          class="ui primary button"
          (click)="addSuper(article.id_document, article.superficie)"
        >
          agregar
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="articles.length == 0">
  <h1>No hay más</h1>
</div>
