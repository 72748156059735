import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "@angular/fire/compat/firestore";
import { Router } from "@angular/router";
import firebase from "firebase/compat/app";
import * as moment from "moment";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { Etiquetas } from "../COMERCIAL/MODULOS/etiquetas";
import { Articulo } from "../COMERCIAL/MODULOS/ingresa/articulo";
import { Cliente } from "../COMERCIAL/MODULOS/ingresa/cliente";
import { MessageI } from "../COMERCIAL/MODULOS/ingresa/message";
import { Productos } from "../COMERCIAL/MODULOS/ingresa/productos";
import { Tipo } from "../COMERCIAL/MODULOS/ingresa/tipo";
import { ClienteReg } from "../COMERCIAL/MODULOS/registra/cliente";

import { AuthService } from "./auth.service";
import { GetTokensService } from "./get-tokens.service";
import { NotificationsService } from "./socket/notifications.service";
import { SocketService } from "./socket/socket.service";
import { RecentActivityInterface } from "../interfaces/activity";
import { GenerateRecentActivityService } from "./recent-activity.service";
import { GenerateIdService } from "./generate-id.service";

type CollentionPredicate<T> = string | AngularFirestoreCollection;
type DocumentPredicate<T> = string | AngularFirestoreDocument;

@Injectable({
  providedIn: "root",
})
export class DataDbService {
  articles = 0; //pendiente de validar
  private etiquetasCollection: AngularFirestoreCollection<Etiquetas>;
  uid_user: string;
  idDocumentCustomer: string;
  idDocumentArticle: string;
  idDocumentRegitra: string;
  cuarentena = [];
  usersEncontrados: any[];
  dataLead: any;
  constructor(
    private gra: GenerateRecentActivityService,
    private afs: AngularFirestore,
    private socket: SocketService,
    public AuthService: AuthService,
    private Notification: NotificationsService,
    private router: Router,
    private tokensUser: GetTokensService,
    private generateId: GenerateIdService,
  ) {
    this.AuthService.getCurrentUser().then((values) => {
      if (values !== null) {
        this.uid_user = values.uid;
      }
    });
    this.etiquetasCollection = afs.collection<Etiquetas>("Etiquetas");
  }

  async getDataFs(collection, uid, subCollection) {
    const data = await this.afs
      .collection(collection)
      .doc(uid)
      .collection(subCollection);
    return data;
  }
  saveClient(cliente: Cliente, id: number, index, leadUid, leadData): void {
    this.afs.collection("LEAD").doc(leadData.id_document).update({
      escalate: true,
      mostrar: false,
    });
    this.afs
      .collection("LEAD")
      .doc(leadData.id_document)
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((leadData: any) => {
        let lead = leadData;
        let positives: Array<any> = lead.positives;
        for (let i = 0; i < positives.length; i++) {
          positives[i].dataClientComplete = true;
        }

        lead.positives = positives;
        this.afs
          .collection("LEAD")
          .doc(lead.id_document)
          .update(lead)
          .then((e) => {
            cliente.fecha = moment().format("DD-MM-YYYY");
            cliente.hora = moment().format("hh:mm:ss a");
            this.afs
              .collection("CUSTOMER")
              .doc(leadData.id_document)
              .set({
                id: id,
                escalate: true,
                cliente: cliente,
                date: moment().format(),
                resendIdentification: false,
                complete: false,
                aprobado: true,
                id_document_lead: lead.id_document,
                id_document: leadData.id_document,
                uid_user: leadUid,
                positives: lead.positives,
                idArticulos: lead.idArticulos,
                positive_or_negative: lead.positive_or_negative,
                hora_fecha: {
                  hora: moment().format("hh:mm:ss a"),
                  fecha: moment().format("DD-MM-YYYY"),
                  date: moment().format(""),
                },
              })
              .then((docRef) => {
                this.Notification.customerIncomplete(
                  false,
                  id,
                  leadUid,
                  lead.id_document,
                );

                positives[index].idDocumentClient = lead.id_document;
                lead.positives = positives;

                // this.afs.collection('LEAD').doc(lead.id_document).update(lead);

                this.router.navigate([
                  "/ingresa/escalate/upload-id",
                  lead.id_document,
                  index,
                  lead.id,
                ]);
              })
              .catch((error) => {
                alert("Error updating document: ");
                console.error(error);
              });
          });
      });
  }
  saveCustomer(cliente: Cliente, hora_fecha: any, id: number): void {
    let actualizacionCliente = hora_fecha;
    let actualizacionAdicional = hora_fecha;
    this.afs
      .collection("CUSTOMER")
      .add({
        id: id,
        cliente,
        hora_fecha,
        actualizacionCliente,
        actualizacionAdicional,
        mandarIdentificacion: false,
        completado: false,
        aprobado: true,
        id_cliente: String(id),
        uid_user: this.uid_user,
        estatus: "incompleto",

        id_document: 0,
      })
      .then((docRef) => {
        this.idDocumentCustomer = docRef.id;
        this.router.navigate(["ingresa", docRef.id, "subir-foto"]);

        this.afs
          .collection("CUSTOMER")
          .doc(docRef.id)
          .update({ id_document: docRef.id })
          .then((e) => {
            this.Notification.customerIncomplete(
              false,
              id,
              this.uid_user,
              docRef.id,
            );
          });
      })
      .catch((error) => {
        alert("Error updating document: ");
        console.error(error);
      });
  }

  updateCustomer(
    newData: any,
    id: string,
    id_update: any,
    uid_user: string,
    needsApprove: boolean,
  ) {
    let idUpdate = id_update;
    let id_cliente = newData.id_cliente;
    let id_document_customer = newData.id_document_customer;
    let fecha = {
      fecha: moment().format("DD-MM-YYYY"),
      hora: moment().format("hh:mm:ss"),
    };

    if (needsApprove) {
      this.afs
        .collection("CUSTOMER")
        .doc(String(id))
        .collection("CUSTOMER-UPDATE")
        .add({
          pendiente: newData,
          actualizacion: true,
          id_cliente,
          id_document_customer,
          fecha,
          id_actualizacion: idUpdate,
          estatus: "pendiente",
          uid_user,
        })
        .then((docRef) => {
          this.afs
            .collection("CUSTOMER")
            .doc(String(id))
            .collection("CUSTOMER-UPDATE")
            .doc(docRef.id)
            .update({ id_document: docRef.id });
        });
    } else {
      this.afs
        .collection("CUSTOMER")
        .doc(String(id))
        .valueChanges()
        .pipe(take(1))
        .toPromise()
        .then((customer: any) => {
          let old_data = {
            nombre: customer.cliente.nombre,
            apellido: customer.cliente.apellido,
            correo: customer.cliente.correo,
            telefono: customer.cliente.telefono,
            cp: customer.cliente.cp,
            identificacion: customer.cliente.identificacion,
            colonia: customer.cliente.colonia,
            calle: customer.cliente.calle,
            ciudad: customer.cliente.ciudad,
            no_exterior: customer.cliente.no_exterior,
            no_interior: customer.cliente.no_interior,
            estado: customer.cliente.estado,
            pais: "México",
            hora: moment().format("hh:mm:ss a"),
            fecha: moment().format("DD-MM-YYYY"),
          };

          let new_data = {
            nombre: newData.nombre,
            apellido: newData.apellido,
            identificacion: newData.identificacion,
            correo: newData.correo,
            telefono: newData.telefono,
            cp: newData.cp,
            colonia: newData.colonia,
            ciudad: newData.ciudad,
            no_exterior: newData.no_exterior,
            no_interior: newData.no_interior,
            calle: newData.calle,
            pais: "México",
            estado: newData.estado,
          };
          let fecha_evaluacion = {
            hora: moment().format("hh:mm:ss a"),
            fecha: moment().format("DD-MM-YYYY"),
            date: moment().format(""),
          };

          this.afs
            .collection("CUSTOMER")
            .doc(String(id))
            .collection("CUSTOMER-UPDATE")
            .add({
              actualizacion: false,
              aprobado: true,
              estatus: "Aprobado",
              fecha_evaluacion,
              pendiente: newData,
              id_cliente,
              id_document_customer,
              fecha,
              id_actualizacion: idUpdate,
              uid_user,
              old_data,
            })
            .then((doc) => {
              this.afs
                .collection("CUSTOMER")
                .doc(String(id))
                .collection("CUSTOMER-UPDATE")
                .doc(doc.id)
                .update({
                  id_document: doc.id,
                });
              this.afs
                .collection("CUSTOMER")
                .doc(String(id))
                .set(
                  { cliente: new_data, actualizacionCliente: fecha_evaluacion },
                  { merge: true },
                )
                .then(async () => {
                  var tokens = await this.tokensUser.getTokens(this.uid_user);
                  let data = {
                    tokens,
                    id_document: id,
                    id_request: idUpdate,
                    uid: uid_user,
                  };

                  this.socket.io.emit("customer-update-approve", data);
                });
            });
        });
    }
  }

  updateCustomerAd(newData, id, id_update, uid_user, needsApprove: boolean) {
    let idUpdate = id_update;
    let id_cliente = newData.id_cliente;
    let id_document_customer = newData.id_document_customer;
    let fecha = {
      fecha: moment().format("DD-MM-YYYY"),
      hora: moment().format("hh:mm:ss"),
      date: moment().format(""),
    };

    if (needsApprove) {
      this.afs
        .collection("CUSTOMER")
        .doc(String(id))
        .collection("CUSTOMER-UPDATE")
        .add({
          pendienteAd: newData,
          actualizacionAd: true,
          uid_user,
          fecha,
          id_cliente,
          id_document_customer,
          id_actualizacion: idUpdate,
          estatus: "pendiente",
        })
        .then((docRef) => {
          this.afs
            .collection("CUSTOMER")
            .doc(String(id))
            .collection("CUSTOMER-UPDATE")
            .doc(docRef.id)
            .update({ id_document: docRef.id });
        });
    } else {
      this.afs
        .collection("CUSTOMER")
        .doc(String(id))
        .valueChanges()
        .pipe(take(1))
        .toPromise()
        .then((customer: any) => {
          let old_data = {
            adicional_nombre: customer.cliente.adicional_nombre,
            adicional_apellido: customer.cliente.adicional_apellido,
            adicional_correo: customer.cliente.adicional_correo,
            adicional_telefono: customer.cliente.adicional_telefono,
          };
          let fecha_evaluacion = {
            hora: moment().format("hh:mm:ss a"),
            fecha: moment().format("DD-MM-YYYY"),
          };
          let new_data = {
            adicional_nombre: newData.nombre,
            adicional_apellido: newData.apellido,
            adicional_correo: newData.correo,
            adicional_telefono: newData.telefono,
          };

          this.afs
            .collection("CUSTOMER")
            .doc(String(id))
            .collection("CUSTOMER-UPDATE")
            .add({
              actualizacionAd: false,
              aprobadoAd: true,
              old_data,
              estatus: "Aprobado",
              fecha_evaluacion,
              pendienteAd: newData,
              fecha,
              uid_user,
              id_cliente,
              id_document_customer,
              id_actualizacion: idUpdate,
            })
            .then((e) => {
              this.afs
                .collection("CUSTOMER")
                .doc(String(id))
                .set(
                  {
                    cliente: new_data,
                    actualizacionAdicional: fecha_evaluacion,
                  },
                  { merge: true },
                )
                .then(async () => {
                  var tokens = await this.tokensUser.getTokens(uid_user);
                  let data = {
                    tokens,
                    id_document: id,
                    id_request: idUpdate,
                    uid: uid_user,
                  };
                  this.socket.io.emit("customerad-update-approve", data);
                });
            });
        });
    }

    // this.afs.collection('CUSTOMER').doc(String(id)).collection('CUSTOMER-UPDATE', ref => ref.where('id_document_customer', '==', id_document_customer)).valueChanges().pipe(take(1)).toPromise().then((values: any[]) => {
    //   if (values.length > 0) {
    //     this.afs.collection('CUSTOMER').doc(String(id)).collection('CUSTOMER-UPDATE').doc(String(values[0].id_document)).update({ pendienteAd: newData, ad_fecha, actualizacionAd: true, estatus: 'pendiente', })
    //   } else {
    //     this.afs.collection('CUSTOMER').doc(String(id)).collection('CUSTOMER-UPDATE').add({ pendienteAd: newData, actualizacionAd: true, uid_user, ad_fecha, id_cliente, id_document_customer, id_actualizacion: idUpdate, estatus: 'pendiente' }).then((docRef) => {
    //       this.afs.collection('CUSTOMER').doc(String(id)).collection('CUSTOMER-UPDATE').doc(docRef.id).update({ id_document: docRef.id })
    //     })
    //   }
    // })
  }

  updateArticle(newData, id, id_update) {
    // let idUpdate = id_update
    // let id_cliente = newData.id_cliente
    // let id_document_customer = newData.id_document_customer
    // let uid_user = this.uid_user
    // this.afs.collection('CUSTOMER').doc(String(id)).collection('CUSTOMER-UPDATE', ref => ref.where('id_document_customer', '==', id_document_customer)).valueChanges().pipe(take(1)).toPromise().then((values: any[]) => {
    //   if (values.length > 0) {
    //     this.afs.collection('CUSTOMER').doc(String(id)).collection('CUSTOMER-UPDATE').doc(String(values[0].id_document)).update({ pendienteAd: newData, actualizacionAd: true, estatus: 'pendiente', })
    //   } else {
    //     this.afs.collection('CUSTOMER').doc(String(id)).collection('CUSTOMER-UPDATE').add({ pendienteAd: newData, actualizacionAd: true, uid_user, id_cliente, id_document_customer, id_actualizacion: idUpdate, estatus: 'pendiente' }).then((docRef) => {
    //       this.afs.collection('CUSTOMER').doc(String(id)).collection('CUSTOMER-UPDATE').doc(docRef.id).update({ id_document: docRef.id })
    //     })
    //   }
    // })
  }

  saveUrl(urlImage, id): void {
    this.afs.doc("CUSTOMER/" + id).update({
      urlImage,
      estatus: "habilitado",
      completado: true,
      mandarIdentificacion: false,
    });
  }
  saveUrlClient(urlImage, idClient, index: number, idL: string): void {
    //string to number
    let idLead: number = Number(idL);
   

    this.afs
      .collection("CUSTOMER")
      .doc(String(idClient))
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((leadData: any) => {
        let lead = leadData;

        let positives: Array<any> = lead.positives;
        for (let i = 0; i < positives.length; i++) {
          positives[i].uploadedClientFiles = true;
        }
        lead.positives = positives;
        this.afs
          .collection("CUSTOMER")
          .doc(lead.id_document)
          .update(lead)
          .then((e) => {
            this.afs
              .doc("CUSTOMER/" + idClient)
              .update({
                urlImage,
                estatus: "habilitado",
                completado: true,
                resendIdentification: false,
              })
              .then((e) => {
                this.router.navigate(["/gestiona"]);
              });
          });
      });
  }

  saveType(tipo: Tipo, idDocument, idc, idArticle, user: any): void {
    this.afs
      .collection("ARTICLE")
      .add({
        tipo,
        argumento: "No hay observaciones",
        id_cliente: idc,
        id_articulo: String(idArticle),
        uid_user: user.uid,
        estatus: "incompleto",
        id_document: 0,
        id_document_customer: idDocument,
        completado: false,
      })
      .then((docRef) => {
        this.idDocumentArticle = docRef.id;
        this.afs
          .collection("ARTICLE")
          .doc(docRef.id)
          .update({ id_document: docRef.id })
          .then((e: any) => {
            this.router.navigate([
              "ingresa",
              idDocument,
              "articulo",
              docRef.id,
              "productos",
            ]);
          });
      });
  }

  saveProducto(commodity: Productos, id, isAdvance: boolean): void {
    let product = commodity.producto;
    let duration = product == "gold99"
      ? 99
      : product == "elite99"
        ? 369
        : product == "diamond99"
          ? 189
          : product == "imperial99"
            ? 449
            : 0;
    let autoRenovation = 0;
    let start = moment().format();
    this.afs.doc("ARTICLE/" + id).set(
      {
        commodity: {
          producto: product,
          requestCancellation: false,
          duration,
          fecha: moment().format("DD-MM-YYYY"),
          hora: moment().format("hh:mm:ss a"),
          autoRenovation,
          observation: "sin observaciones",
          start,
          totalProductPeriod: duration,
          dateCancellation: "No disponible",
          finish: this.getDates(start, product),
          estatus: "en espera",
        },
      },
      { merge: true },
    );
  }

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  getDays(name) {
    if (name == "gold99" || name == "Gold99") {
      return 99;
    } else if (name == "elite99" || name == "Elite99") {
      return 369;
    } else if (name == "diamond99" || name == "Diamond99") {
      return 189;
    } else if (name == "imperial99" || name == "Imperial99") {
      return 449;
    }
  }
  getDates(start, product) {
    let date_product = new Date(start);
    let name_product = product;
    let days = this.getDays(name_product);
    return moment(this.addDays(date_product, days)).format();
  }

  saveArticulo(
    articulo: Articulo,
    id,
    comment,
    idCustomer,
    idDocCustomer,
    firstArticle: boolean,
    route_url: string,
  ): void {
    this.afs
      .doc("ARTICLE/" + id)
      .set(
        {
          imagenes: [],
          keysAvailable: true,
          imagen_principal: "",
          articulo,
          estatus: "habilitado",
          completado: true,
        },
        { merge: true },
      )
      .then(async (doc) => {
        this.afs
          .collection("ARTICLE")
          .doc(id)
          .valueChanges()
          .pipe(take(1))
          .toPromise()
          .then((product: any) => {
            let products = {
              id_cliente: idDocCustomer,
              date: product.commodity.fecha,
              id_document: id,
              uid_user: product.uid_user,
              product: product.commodity.producto,
            };

            this.socket.io.emit("add-product");
            if (firstArticle) {
              this.socket.io.emit("first-income", product.uid_user);
            }

            let setRecentActivity = {
              date: moment().format(),
              homeTitle: "Haz completado tu primer ingreso",
              generalTitle: "Haz completado tu primer ingreso",
              responses: false,
              description: "Haz completado tu primer ingreso",
              id: this.generateId.createId(),
              archived: false,
              id_document_component: idDocCustomer,
              important: false,
              routeObservation: route_url,
              isError: false,
              canUploadData: false,
              view: false,
              uid_user: product.uid_user,
              from: "clientes",
              type: "observation",
              isFullview: true,
              canReply: false,
            };
            this.gra.GenerateRecentActivity(
              setRecentActivity,
              product.uid_user,
            );
          });
        let idUser = await this.AuthService.getDataUser(this.uid_user);
        this.afs.collection("ARTICLE/" + id + "/ARTICLE-COMMENT/").add({
          comentario: {
            commentario: comment.comentario,
            date: moment().format(""),
            fecha: moment().format("DD-MM-YYYY"),
            hora: moment().format("hh:mm:ss a"),
            id_user: idUser,
          },
          date: moment().format("DD-MM-YYYY"),
          id_user: idUser,
        });

      });
    this.Notification.customerIncomplete(
      true,
      idCustomer,
      this.uid_user,
      idDocCustomer,
    );
  }

  saveCharacteres(features: MessageI, idA): void {

    this.afs.doc("ARTICLE/" + idA).update({ caracteristicas: features });
  }

  saveEtiqueta(newHabitacional: Etiquetas): void {
    this.etiquetasCollection.add(newHabitacional);
  }

  //REGISTRA
  saveClienteReg(
    cliente: ClienteReg,
    last_update,
    comment,
    id,
    route_url: string,
  ): void {
    this.afs
      .collection("LEAD")
      .add({
        cliente,
        id: String(id),

        cuarentena: false,

        uid_user: this.uid_user,
        last_update,
        mostrar: true,
        estatus: "incompleto",
        completo: false,
      })
      .then(async (docRef) => {
        this.afs
          .collection(
            "LEAD",
            (ref) => ref.where("uid_user", "==", this.uid_user),
          )
          .get()
          .pipe(take(1))
          .toPromise()
          .then((results) => {
            if (results.docs.length <= 1) {
              this.socket.io.emit("firts-lead", this.uid_user);
            }

            //creamos un registro de actividad
            let setRecentActivity = {
              date: moment().format(),

              homeTitle: "Haz creado tu primer lead",
              generalTitle: "Haz creado tu primer lead",
              responses: false,

              description: "Haz creado tu primer lead",
              id: this.generateId.createId(),
              archived: false,
              important: false,
              id_document_component: docRef.id,
              isError: false,
              canUploadData: false,
              view: false,

              uid_user: this.uid_user,
              from: "leads",
              type: "observation",
              isFullview: true,
              canReply: false,
            };
            this.gra.GenerateRecentActivity(setRecentActivity, this.uid_user);
          });
        let id = await this.AuthService.getDataUser(this.uid_user);

        this.Notification.leadIncomplete(false, id, this.uid_user, docRef.id);
        this.afs
          .collection("LEAD")
          .doc(docRef.id)
          .update({ id_document: docRef.id });
        this.afs
          .collection("LEAD")
          .doc(docRef.id)
          .collection("LEAD-COMMENT")
          .add({
            id_document: docRef.id,
            comentario: comment,
            date: moment().format(),
            fecha: moment().format("DD-MM-YYYY"),
            hora: moment().format("hh:mm:ss a"),
            id_user: id,
          });

        this.router.navigate(["registra", docRef.id, "features"]);
      });
  }

  saveLeadKA(
    cliente: ClienteReg,
    last_update,
    comment,
    id,
    idArticulo,
    idKA,
  ): void {
    this.afs
      .collection("LEAD")
      .add({
        cliente,
        id: String(id),
        commodityRelation: 0,
        commodityPositive: 0,
        commodityNegative: 0,
        uid_user: this.uid_user,
        last_update,
        estatus: "completo",
        completo: true,
        mostrar: false,
        idArticulos: [],
      })
      .then(async (docRef) => {
        this.afs
          .collection(
            "LEAD",
            (ref) => ref.where("uid_user", "==", this.uid_user),
          )
          .get()
          .pipe(take(1))
          .toPromise()
          .then((results) => {
            this.dataLead = results.docs[0].data();
            if (results.docs.length <= 1) {
              this.socket.io.emit("firts-lead", this.uid_user);
            }
          });
        let id = await this.AuthService.getDataUser(this.uid_user);

        this.Notification.leadIncomplete(false, id, this.uid_user, docRef.id);
        this.afs
          .collection("LEAD")
          .doc(docRef.id)
          .update({ id_document: docRef.id });
        this.afs
          .collection("ARTICLE")
          .doc(idArticulo)
          .collection("KEY-ACCESS")
          .doc(idKA)
          .update({
            id_lead: String(id),
            id_document_lead: docRef.id,
            nameVisitor: cliente.nombre + " " + cliente.apellido,
            status: "en proceso",
            complete: true,
          })
          .then(() => {
            this.router.navigate([
              "/keyaccess/keyaccess-fullview",
              idArticulo,
              idKA,
            ]);
          });

        this.afs
          .collection("LEAD")
          .doc(docRef.id)
          .collection("LEAD-COMMENT")
          .add({
            id_document: docRef.id,
            comentario: comment,
            date: moment().format(),
            fecha: moment().format("DD-MM-YYYY"),
            hora: moment().format("hh:mm:ss a"),
            id_user: id,
          });
      });
  }

  saveFeaturesReg(id: string, features: any) {
    return this.afs
      .collection("LEAD")
      .doc(id)
      .update({ tagsToSearch: features });
  }

  saveClienteAL(
    cliente: ClienteReg,
    last_update,
    comment,
    id_article,
    id_lead,
  ): void {
    let idArticulos = [];
    let id = {
      id: id_article,
    };
    idArticulos.push(id);
    this.afs
      .collection("LEAD")
      .add({
        cliente,
        id: id_lead,
        commodityRelation: 0,
        commodityPositive: 0,
        commodityNegative: 0,
        uid_user: this.uid_user,
        last_update,
        estatus: "completo",
        completo: true,
        mostrar: true,
        idArticulos,
      })
      .then((doc) => {
        this.afs
          .collection("LEAD")
          .doc(doc.id)
          .set({ id_document: doc.id }, { merge: true })
          .then((e) => {
            this.router.navigate(["gestiona", doc.id, "go"]);
          });
        this.afs
          .collection("LEAD")
          .doc(doc.id)
          .collection("LEAD-COMMENT")
          .add({ id_document: doc.id, comentario: comment });
      });
  }

  lastUpdate(last_update, id) {
    this.afs.doc("LEAD/" + id).update({ last_update });
  }

  saveId(idArticulo, artListing, id, lead): void {
    this.afs.doc("LEAD/" + idArticulo.id).update({
      idArticulos: idArticulo.ids,
      estatus: "completado",
      completo: true,
    });
    this.socket.io.emit("lead-with-articles", this.uid_user);

    let setRecentActivity = {
      date: moment().format(),
      subtitle: "Has creado un nuevo lead",
      homeTitle: "Has creado un nuevo lead",
      generalTitle:
        "Has creado un nuevo lead, ve a la actividad para más información",
      responses: false,
      description: "Tienes un nuevo lead. El lead de " +
        lead.cliente.nombre + " " + lead.cliente.apellido +
        " ha sido creado",
      id: this.generateId.createId(),
      archived: false,
      id_document_component: lead.id_document,
      important: false,
      isError: false,
      canUploadData: false,
      view: false,
      uid_user: lead.uid_user,
      type: "observation",
      canReply: false,
      isFullview: true,
      from: "leads",
    };
    this.socket.io.emit("recent-activity", {
      recentActivity: setRecentActivity,
      uid: lead.uid_user,
    });

    this.Notification.leadIncomplete(true, id, this.uid_user, idArticulo.id);
    let objeto = {
      complete: false,
      uid: this.uid_user,
      id_lead: id,
      id_document: idArticulo.id,
    };
    this.socket.io.emit("lead-status", objeto);
  }

  saveidAL(idArticulos, id) {
    this.afs
      .collection("LEAD")
      .add({ idArticulos, id, completado: false })
      .then((doc) => {
        this.afs.collection("LEAD").doc(doc.id).update({ id_document: doc.id });
        this.router.navigate(["registra", doc.id]);
      });
  }

  leadComment(id, comment) {
    this.afs
      .collection("LEAD")
      .doc(id)
      .collection("LEAD-COMMENT")
      .add({ comentario: comment });
  }

  saveCommodity(commodity, id): void {
    let positive_or_negative = firebase.firestore.FieldValue.arrayUnion(
      commodity,
    );
    this.afs.doc("LEAD/" + id).update({ positive_or_negative });
  }

  saveCommodityPositive(id, commodityPositive) {
    this.afs.doc("LEAD/" + id).update({ commodityPositive });
    this.socket.io.emit("lead-positive", id);
  }
  saveCommodityNegative(id, commodityNegative) {
    this.afs.doc("LEAD/" + id).update({ commodityNegative });
  }
  articleComment(id, comentario) {
    this.afs
      .collection("ARTICLE/" + id + "/ARTICLE-COMMENT")
      .add({ comentario });
  }

  updateDataUserLead(newData, idR, id, uid_user) {
    this.afs
      .collection("LEAD")
      .doc(String(idR))
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((lead: any) => {
        let idUpdate = id;
        let id_document_register = newData.id_document_register;
        let fecha = {
          fecha: moment().format("DD-MM-YYYY"),
          hora: moment().format("hh:mm:ss"),
        };
        let old_data = {
          nombre: lead.cliente.nombre,
          apellido: lead.cliente.apellido,
          correo: lead.cliente.correo,
          telefono: lead.cliente.telefono,
          hora: moment().format("hh:mm:ss a"),
          fecha: moment().format("DD-MM-YYYY"),
        };

        let new_data = {
          nombre: newData.nombre,
          apellido: newData.apellido,
          correo: newData.correo,
          telefono: newData.telefono,
        };

        let fecha_evaluacion = {
          hora: moment().format("hh:mm:ss a"),
          fecha: moment().format("DD-MM-YYYY"),
        };

        this.afs
          .collection("LEAD")
          .doc(String(idR))
          .collection("LEAD-UPDATE")
          .add({
            actualizacion: false,
            aprobado: true,
            estatus: "Aprobado",
            fecha_evaluacion,
            old_data,
            pendiente: newData,
            id_document_register,
            id_actualizacion: idUpdate,
            uid_user,
            fecha,
          })
          .then((e) => {
            this.afs
              .collection("LEAD")
              .doc(String(idR))
              .collection("LEAD-UPDATE")
              .doc(e.id)
              .update({
                id_document: e.id,
              });

            this.afs
              .collection("LEAD")
              .doc(String(idR))
              .set({ cliente: new_data }, { merge: true })
              .then(async () => {
                var tokens = await this.tokensUser.getTokens(uid_user);
                let data = {
                  tokens,
                  id_document: idR,
                  id_request: idUpdate,
                  uid: uid_user,
                };

                //this.socket.io.emit('lead-update-approve', data)
              });
          });
      });

    // this.afs.collection('LEAD').doc(String(idR)).collection('LEAD-UPDATE').add({ pendiente: newData, actualizacion: true, id_document_register, id_actualizacion: idUpdate, estatus: 'pendiente', uid_user, fecha }).then((docRef) => {
    //   this.afs.collection('LEAD').doc(String(idR)).collection('LEAD-UPDATE').doc(docRef.id).update({ id_document: docRef.id })
    // })
  }
  updateDataUserAdLead(newData, idR, id, uid_user) {
    this.afs
      .collection("LEAD")
      .doc(String(idR))
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((lead: any) => {
        let idUpdate = id;
        let id_document_register = newData.id_document_register;
        let fecha = {
          fecha: moment().format("DD-MM-YYYY"),
          hora: moment().format("hh:mm:ss"),
        };

        let new_data = {
          adicional_nombre: newData.nombre,
          adicional_apellido: newData.apellido,
          adicional_correo: newData.correo,
          adicional_telefono: newData.telefono,
          id_document_register,
        };

        let fecha_evaluacion = {
          hora: moment().format("hh:mm:ss a"),
          fecha: moment().format("DD-MM-YYYY"),
        };
        let old_data = {
          adicional_nombre: lead.cliente.adicional_nombre,
          adicional_apellido: lead.cliente.adicional_apellido,
          adicional_correo: lead.cliente.adicional_correo,
          adicional_telefono: lead.cliente.adicional_telefono,
          hora: moment().format("hh:mm:ss a"),
          fecha: moment().format("DD-MM-YYYY"),
        };

        this.afs
          .collection("LEAD")
          .doc(String(idR))
          .collection("LEAD-UPDATE")
          .add({
            fecha_evaluacion,
            pendienteAd: newData,
            actualizacionAd: false,
            id_document_register,
            id_actualizacion: idUpdate,
            estatus: "Aprobado",
            uid_user,
            fecha,
            old_data,
            aprobadoAd: true,
          })
          .then((doc: any) => {
            this.afs
              .collection("LEAD")
              .doc(String(idR))
              .collection("LEAD-UPDATE")
              .doc(doc.id)
              .update({ id_document: doc.id });
            this.afs
              .collection("LEAD")
              .doc(String(idR))
              .set({ cliente: new_data }, { merge: true })
              .then(async () => {
                var tokens = await this.tokensUser.getTokens(uid_user);
                let data = {
                  tokens,
                  id_document: idR,
                  id_request: idUpdate,
                  uid: uid_user,
                };

                this.socket.io.emit("leadad-update-approve", data);
              });
          });
      });
  }

  updateUserLead(id, cliente) {
    this.afs.collection("LEAD/" + id + "/LEAD-UPDATES").add({ cliente });
  }

  async saveMsg(msg) {
    let chats = firebase.firestore.FieldValue.arrayUnion(msg);
    const ref = this.afs
      .collection("USERS")
      .doc(msg.message_to)
      .collection("SUPPORT_CHATS")
      .doc(String(msg.room_to));
    await ref.update({ chats });
  }

  getCaracteristicas() {
    return [
      { id: 1, descripcion: "habitacional" },
      { id: 2, descripcion: "comercial" },
      { id: 3, descripcion: "industrial" },
      { id: 4, descripcion: "renta" },
      { id: 5, descripcion: "venta" },
      { id: 6, descripcion: "usd" },
      { id: 7, descripcion: "mxn" },
      { id: 8, descripcion: "eur" },
      { id: 9, descripcion: "casa" },
      { id: 10, descripcion: "local" },
      { id: 11, descripcion: "oficina" },
      { id: 12, descripcion: "bodega" },
      { id: 13, descripcion: "nave industrial" },
      { id: 14, descripcion: "terreno" },
      { id: 15, descripcion: "mezzanine" },
      { id: 16, descripcion: "departamento" },
      { id: 17, descripcion: "quiosco" },
      { id: 18, descripcion: "cuarto" },
      { id: 19, descripcion: "loft" },
      { id: 20, descripcion: "pent House" },
      { id: 21, descripcion: "rasca cielos" },
      { id: 22, descripcion: "edificio" },
      { id: 23, descripcion: "5k" },
      { id: 24, descripcion: "15k" },
      { id: 25, descripcion: "35k" },
      { id: 26, descripcion: "70k" },
      { id: 27, descripcion: "150k" },
      { id: 28, descripcion: "500k" },
      { id: 29, descripcion: "1m" },
      { id: 30, descripcion: "3m" },
      { id: 31, descripcion: "5m" },
      { id: 32, descripcion: "9m" },
      { id: 33, descripcion: "25m" },
      { id: 34, descripcion: "60m" },
      { id: 35, descripcion: "150m" },
      { id: 36, descripcion: "500m" },
      { id: 37, descripcion: "900m" },
    ];
  }

  private col<T>(
    ref: CollentionPredicate<T>,
    queryFn?,
  ): AngularFirestoreCollection {
    return typeof ref === "string" ? this.afs.collection(ref, queryFn) : ref;
  }

  private doc<T>(ref: DocumentPredicate<T>): AngularFirestoreDocument {
    return typeof ref === "string" ? this.afs.doc(ref) : ref;
  }

  add<T>(ref: CollentionPredicate<T>, data) {
    return this.col(ref).add({
      ...data,
    });
  }

  col$<T>(ref: CollentionPredicate<T>, queryFn?): Observable<any[]> {
    return this.col(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map((docs) => {
          return docs.map((d) => {
            const data = d.payload.doc.data();
            const id = d.payload.doc.id;
            return { id, ...data };
          });
        }),
      );
  }

  //get time now
}
