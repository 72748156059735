import { ElementRef, Renderer2, Component, OnInit, ViewChild } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Subscription } from "rxjs";
import { filter, take } from "rxjs/operators";
import { AuthService } from "src/app/SERVICIOS/auth.service";
import { DataDbService } from "src/app/SERVICIOS/data-db.service";

import { SocketService } from "src/app/SERVICIOS/socket/socket.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { NotificationService } from "src/app/SERVICIOS/notification.service";
import { NotificationsService } from "src/app/SERVICIOS/socket/notifications.service";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import moment from "moment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import SignaturePad from "signature_pad";
import { DomSanitizer } from "@angular/platform-browser";
import { User } from "../../MODULOS/user.interface";
import { HttpClient } from "@angular/common/http";
import { MapService } from "src/app/SERVICIOS/map.service";

declare let $: any;

@Component({
  selector: "app-keyaccess-history-fullview",
  templateUrl: "./keyaccess-history-fullview.component.html",
  styleUrls: ["./keyaccess-history-fullview.component.scss"],
})
export class KeyaccessHistoryFullviewComponent implements OnInit {
  imageSrc: any;
  subs_one: Subscription;
  dataLead: any;
  dataRequest: any;
  idKA = "";
  idArticulo = "";
  user: User;
  dataAgent: any;
  dataArticle: any;
  seconds = 0;
  tokenInformation: any;
  message: string;
  isMyResquest: boolean = false;
  editDateMeeting: boolean = false;
  invalid: boolean = false;
  gettoken: string;
  tokenError: boolean = false;
  tokenErrorMessage: string = "";
  verifiedToken: boolean = false;
  infoRequest: any;
  infoArticle: any;
  infoLead: any;
  meetingChanged: Date;
  finishChanged: Date;
  dateFinish: any;
  dateMeeting: any;
  isMine: boolean = false;
  showFirstModal: boolean = false;
  @ViewChild("myModal")
  myModal;
  @ViewChild("myModalInformation")
  myModalInformation;
  @ViewChild("modal_update")
  modal_update;

  signDigitalPad: any;
  modalRef;
  isChecked: boolean;
  activateCheckBox: boolean = false;
  showModal: boolean = true;
  dias = [
    "domingo",
    "lunes",
    "martes",
    "miércoles",
    "jueves",
    "viernes",
    "sábado",
  ];
  sign: any;
  isAcceptedRequestKeyAccess: boolean = false;
  previousUrl: string;

  constructor(
    public dbData: DataDbService,
    private sanitizer: DomSanitizer,
    public gonotification: NotificationService,
    public notification: NotificationsService,
    public modal: NgbModal,
    private route: ActivatedRoute,
    public afs: AngularFirestore,
    public auth: AuthService,
    private router: Router,
    private http: HttpClient,
    private socket: SocketService,
    private mapSvc: MapService,
    private el: ElementRef, private renderer: Renderer2
  ) {
    this.renderer.listen(this.el.nativeElement, 'contextmenu', (event) => {
      event.preventDefault();
    });

  }
bloquearClicDerecho() {
    return false;
  }

  bloquearArrastre() {
    return false;
  }
  getRoute() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (
          event.url ==
          "/keyaccess/sign/" + this.dataArticle.id_document + "/" +
          this.dataRequest.id_document
        ) {
          if (this.dataRequest.signed == true) {
            this.router.navigate(["/keyaccess"]);
          }
        }
      });
  }

  changeColor() {
    const red = Math.round(Math.random() * 255);
    const green = Math.round(Math.random() * 255);
    const blue = Math.round(Math.random() * 255);
    const colour = "rgb(" + red + "," + green + "," + blue + ")";
    this.signDigitalPad.penColor = colour;
  }

  cleanSign() {
    this.signDigitalPad.clear();
  }

  SignatureForwarding() {
    this.afs.collection("ARTICLE").doc(this.idArticulo).collection("KEY-ACCESS")
      .doc(this.idKA).update({
        // deliveryDate: moment().format(),
        requestSign: true,
        signed: false,
        sign: null,
      });
  }

  signRequest() {
    this.router.navigate([
      "/keyaccess/sign",
      this.dataArticle.id_document,
      this.dataRequest.id_document,
    ]);
  }

  checkToken = new UntypedFormGroup({
    digitOne: new UntypedFormControl("", [
      Validators.required,
      Validators.maxLength(1),
    ]),
    digitTwo: new UntypedFormControl("", [
      Validators.required,
      Validators.maxLength(1),
    ]),
    digitThree: new UntypedFormControl("", [
      Validators.required,
      Validators.maxLength(1),
    ]),
    digitFour: new UntypedFormControl("", [
      Validators.required,
      Validators.maxLength(1),
    ]),
    digitFive: new UntypedFormControl("", [
      Validators.required,
      Validators.maxLength(1),
    ]),
    digitSix: new UntypedFormControl("", [
      Validators.required,
      Validators.maxLength(1),
    ]),
  });

  getDateMeetingChange(event: any) {
    this.dateMeeting = moment(event.target.value).format();
    let date = new Date(this.dateMeeting);
    const numeroDia = new Date(date).getDay();
    const nombreDia = this.dias[numeroDia];

    if (nombreDia == "viernes") {
      this.dateFinish = this.addDays(date, 3);
      this.afs.collection("ARTICLE").doc(this.idArticulo).collection(
        "KEY-ACCESS",
      ).doc(this.idKA).update({
        dateMeeting: moment(event.target.value).format(),
        dateFinish: moment(this.dateFinish).format(),
      });
    } else if (nombreDia == "sábado") {
      this.dateFinish = this.addDays(date, 2);
      this.afs.collection("ARTICLE").doc(this.idArticulo).collection(
        "KEY-ACCESS",
      ).doc(this.idKA).update({
        dateMeeting: moment(event.target.value).format(),
        dateFinish: moment(this.dateFinish).format(),
      });
    } else {
      this.dateFinish = this.addDays(date, 1);
      this.afs.collection("ARTICLE").doc(this.idArticulo).collection(
        "KEY-ACCESS",
      ).doc(this.idKA).update({
        // deliveryDate: moment().format(),
        dateMeeting: moment(event.target.value).format(),
        dateFinish: moment(this.dateFinish).format(),
      });
    }
    if (this.isMine == true && this.dataRequest.tokenVerified == false) {
      this.generateNewToken();
    }

    // console.log(this.dateFinish.diff(this.dateMeeting, 'days'))
  }

  addDays(date: Date, days) {
    date.setDate(date.getDate() + days);
    return date;
  }
  setDateMeeting() {
    if (
      this.dataArticle.keysAvailable == undefined ||
      this.dataArticle.keysAvailable
    ) this.editDateMeeting = true;
    else if (this.dataArticle.keysAvailable == false) {
      alert("Lo sentimos, este articulo aún no tiene las llaves disponibles");
    }
  }
  getDateFinishChange(event: any) {
    // this.dateFinish = moment(event.target.value)

    // if (this.dateMeeting != null) {
    //   console.log(this.dateFinish.diff(this.dateMeeting, 'days'))
    // }
  }

  get token() {
    return this.checkToken.get("token");
  }
  ngOnInit(): void {
    this.auth.user$.pipe(take(1)).toPromise().then((user) => {
      this.idKA = this.route.snapshot.paramMap.get("keyId");
      this.idArticulo = this.route.snapshot.paramMap.get("idArticle");
      this.user = user;
      this.getRequest(this.idKA, this.idArticulo, user);
    });
  }

  getAcceptedRequestKeyAccess() {
    this.socket.io.on("acceptedRequestKeyAccess", (accepted) => {
      // this.isAcceptedRequestKeyAccess = accepted
    });
  }
  generateNewToken() {
    if (this.isMine) {
      this.auth.getCurrentUser().then((user) => {
        let data = {
          uid: user.uid,
          idKA: this.idKA,
          dis: "angular2",
        };
        this.mapSvc.generateNewToken(this.idKA, user.uid).toPromise().then(
          (res: any) => {
            let tokenData = res.data;
            this.recibirToken(tokenData);
            //token generado
          },
        );
      });
    }
  }
  recibirToken(tokenData) {
    if (this.tokenInformation == null || this.tokenInformation == undefined) {
      this.tokenInformation = tokenData;
      this.count(tokenData.seconds);

      //this.seconds == Math.round(this.tokenInformation.seconds)
    } else if (this.tokenInformation.token == tokenData.token) { }
    else {
      this.count(this.tokenInformation.seconds);
      this.tokenInformation = tokenData;
      //this.seconds == Math.round(this.tokenInformation.seconds)
    }
  }

  count(getSeconds: number) {
    this.seconds = Math.round(getSeconds);
    let interval = setInterval(() => {
      this.seconds--;
      if (this.seconds == 0) {
        this.stopCount(interval);
      }
    }, 1000);
  }
  stopCount(interval) {
    clearInterval(interval);
    this.seconds = 0;
    this.tokenInformation = undefined;
  }
  getRequest(idKA: string, idArt: string, user) {
    this.afs.collection("ARTICLE").doc(idArt).collection("KEY-ACCESS").doc(idKA)
      .valueChanges().subscribe((request: any) => {
        if (request.uid_user == user.uid) this.isMine = true;
        this.dataRequest = request;
        this.getLead(request.id_document_lead);
        this.getAgent(request.uid_user);
        this.getArticle(request.id_article);
        this.getRoute();
        if (user.uid == request.uid_user) this.isMyResquest = true;
        if (request.dateMeeting != null && request.dateFinish != null) {
          this.dateMeeting = request.dateMeeting;
          this.dateFinish = request.dateFinish;
          // if (!this.auth.canAdmin(user) && this.dataRequest.tokenVerified == false) {
          //   this.generateNewToken()
          // }
          if (this.isMine && !request.tokenVerified) {
            this.generateNewToken();
          }
        }
      });
  }
  getArticle(id_article: any) {
    this.afs.collection("ARTICLE").doc(id_article).valueChanges().subscribe(
      (dataArticle) => {
        this.dataArticle = dataArticle;
      },
    );
  }
  getAgent(uid_user: string) {
    this.afs.collection("USERS").doc(uid_user).valueChanges().subscribe(
      (dataAgent) => {
        this.dataAgent = dataAgent;
      },
    );
  }
  getLead(idLead: string) {
    this.afs.collection("LEAD").doc(idLead).valueChanges().subscribe((lead) => {
      this.dataLead = lead;
    });
  }

  goToGenerateToken(lead: any) {
    // this.afs.collection('ARTICLE').doc(this.idLead).collection('KEY-ACCESS').doc(this.idKA).update({
    //   id_lead: lead.id,
    //   id_document_lead: lead.id_document,
    //   nameVisitor: lead.cliente.nombre + ' ' + lead.cliente.apellido,
    //   status: 'en proceso',
    //   complete: true
    // }).then(() => {

    //   this.router.navigate(['keyaccess/generate-token', this.idKA]);
    // })
  }
  onOtpChange(token: string) {
    if (token.length == 6) {

      this.checkTokenUser(token);
    }
  }
  async checkTokenUser(token: any) {

    // let digits = Number(this.checkToken.value.digitOne + this.checkToken.value.digitTwo + this.checkToken.value.digitThree + this.checkToken.value.digitFour + this.checkToken.value.digitFive + this.checkToken.value.digitSix)

    let data = {
      uid: this.user.uid,
      token,
    };

    this.mapSvc
      .getToken(token, this.user.uid)
      .pipe(take(1))
      .toPromise()
      .then((res: any) => {
        if (res) {
          if (res.valid == false) {
            this.tokenError = true;
            this.tokenErrorMessage = res.msg;
          } else {
            this.verifiedToken = true;

            this.afs.collection("ARTICLE").doc(this.idArticulo).collection(
              "KEY-ACCESS",
            ).doc(this.idKA).update({
              // deliveryDate: moment().format(),
              tokenVerified: true,
            });
          }
        }
      });

    // this.socket.io.emit("verificationTokens", data);
    // if (this.checkToken.valid) {
    //   try {
    //     const { token } = this.checkToken.value;
    //     await this.authSvc.resetPassword(token)
    //     if (this.invalid == false && this.checkToken.valid) this.gettoken = token

    //   }

    //   catch (error) {
    //     console.log(error);
    //     // this.message == "No hay ningún registro de usuario que corresponda a este correo electrónico. Es posible que se haya eliminado al usuario"

    //     this.invalid = true
    //     let error_code = error.code
    //     if (error_code == "auth/invalid-token") {
    //       this.message = "Ingresa una dirección token correcta."
    //     } else if (error_code == "auth/user-not-found") {
    //       this.message = "No hay ningún registro de usuario que corresponda a este correo electrónico. Es posible que se haya eliminado al usuario."
    //     }
    //   }
    // } else {

    // }
  }

  confirmDeliveryKeysUser() {
    this.afs.collection("ARTICLE").doc(this.idArticulo).collection("KEY-ACCESS")
      .doc(this.idKA).update({
        // deliveryDate: moment().format(),
        // confirmPickUpKeysUser: true,
        // pickUpDate: moment().format()
        deliveryDate: moment().format(),
        confirmDeliveryKeysUser: true,
      });
    this.hideModalNegative();
  }
  pickUpDeliveryKeysUser() {
    this.afs.collection("ARTICLE").doc(this.idArticulo).collection("KEY-ACCESS")
      .doc(this.idKA).update({
        // deliveryDate: moment().format(),
        confirmPickUpKeysUser: true,
        pickUpDate: moment().format(),
      }).then(() => {
        this.afs.collection("ARTICLE").doc(this.idArticulo).update({
          // deliveryDate: moment().format(),
          keysAvailable: true,
        });
      });
    this.hideModalNegative();
  }

  checkSign() {
    this.afs.collection("ARTICLE").doc(this.idArticulo).collection("KEY-ACCESS")
      .doc(this.idKA).update({
        status: "aprobado",
      }).then(() => {
        this.afs.collection("ARTICLE").doc(this.idArticulo).update({
          // deliveryDate: moment().format(),
          keysAvailable: false,
        });
      });
  }
  confirmDeliveryKeys() {
    this.afs.collection("ARTICLE").doc(this.idArticulo).collection("KEY-ACCESS")
      .doc(this.idKA).update({
        // deliveryDate: moment().format(),
        confirmDeliveryKeys: true,
      });
  }

  showFirstModalConfirm() {
    $(".ui.basic.modal.one").modal("show");
  }
  showSecondModalConfirm() {
    $(".ui.basic.modal.two").modal("show");
  }
  showThreeModalConfirm() {
    $(".ui.basic.modal.three").modal("show");
  }
  hideModalNegative() {
    $(".ui.basic.modal.one").modal("hide");
    $(".ui.basic.modal.two").modal("hide");
    $(".ui.basic.modal.three").modal("hide");
  }
  enableKeyAccess() {
    this.afs.collection("ARTICLE").doc(this.idArticulo).collection("KEY-ACCESS")
      .doc(this.idKA).update({
        // deliveryDate: moment().format(),
        enableKeyAccess: true,
      });

    this.hideModalNegative();
  }
  removeModal() {
    this.modal.dismissAll();
    this.isChecked = false;
  }

  viewDocument() {
    let dataURL = this.dataRequest.sign;
    this.imageSrc = this.sanitizer.bypassSecurityTrustUrl(dataURL);
    // const blob = this.urlToBlob(dataURL)
    // const url = window.URL.createObjectURL(blob)
    // this.imageSrc = url;
    this.openModalInformation();

    //const a = document.createElement('a')
    // a.href = url
    // a.download = 'firma'
    // // this.firma = blob
    // document.body.appendChild(a)
    // a.click()
    // window.URL.revokeObjectURL(url)
  }
  urlToBlob(dataURL: any) {
    const parts = dataURL.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const rawl = raw.length;
    const array = new Uint8Array(rawl);
    for (let i = 0; i < rawl; i++) {
      array[i] = raw.charCodeAt(i);
    }
    let blob = new Blob([array], { type: contentType });
    return blob;
  }

  makeKeyAccess() {
    if (!this.dataRequest.termsAndConditionsAccepted) {
      this.openLG3();
    } else {
      this.router.navigate([
        "/keyaccess/sign",
        this.dataArticle.id_document,
        this.dataRequest.id_document,
      ]);
    }
  }
  onScroll(event: any) {
    // visible height + pixel scrolled >= total height
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight
    ) {
      this.activateCheckBox = true;
    } else {
      this.activateCheckBox = false;
      this.isChecked = false;
    }
  }
  checked(checked: boolean) {
    this.isChecked = checked;
  }
  acceptTerms() {
    this.afs.collection("ARTICLE").doc(this.idArticulo).collection("KEY-ACCESS")
      .doc(this.idKA).update({
        // deliveryDate: moment().format(),
        termsAndConditionsAccepted: true,
      }).then((e) => {
        this.removeModal();

        // this.openSignModal()
        // setTimeout(() => {
        //   this.signDigitalPad = new SignaturePad(this.signDigitalPadElement.nativeElement);
        // }, 10000);
      });
    this.router.navigate([
      "/keyaccess/sign",
      this.dataArticle.id_document,
      this.dataRequest.id_document,
    ]);
  }

  acceptRequestKeyAccess() {
    this.socket.io.emit("acceptRequestKeyAccess", this.infoLead.uid_user);
  }
  reset() {
    this.gettoken = undefined;
  }
  openLG3() {
    this.modalRef = this.modal.open(this.myModal, {
      size: "lg",
      animation: true,
      centered: true,
      backdrop: false,
    });
  }

  openModalInformation() {
    this.modalRef = this.modal.open(this.myModalInformation, {
      size: "lg",
      animation: true,
      centered: true,
      backdrop: false,
    });
  }
  animation(e) {
    let value = e.target.value;
    document.getElementById("i-token").setAttribute("value", value);
  }
}
