import { Component, OnDestroy, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { ActivatedRoute, Router } from "@angular/router";
import { take } from "rxjs/operators";
import { Location } from "@angular/common";
import firebase from "firebase/compat/app";
import { Subscription } from "rxjs";
import { SocketService } from "src/app/SERVICIOS/socket/socket.service";

@Component({
  selector: "app-article-cuarentine",
  templateUrl: "./article-cuarentine.component.html",
  styleUrls: ["./article-cuarentine.component.scss"],
})
export class ArticleCuarentineComponent implements OnInit, OnDestroy {
  idR: string;
  idA: string;
  article: any;
  lead: any;
  positive_or_negative = [];
  getIdArticles: any[];
  subs: Subscription;
  subs_two: Subscription;
  subs_three: Subscription;
  disableArticle: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    private router: Router,
    private socket: SocketService,
    private _location: Location,
  ) {
  }
  goBack() {
    this._location.back();
  }
  goBackAndNotification() {
    let objeto = {
      stop: true,
      id_article: this.article.id,
      uid_user: this.article.uid_user,
      id_registra: this.idR,
    };
    this.socket.io.emit("quarantine", objeto);
    this.goBack();
  }
  goBackAndNoNotification() {
    let objeto = {
      stop: "all",
      id_article: this.article.id,
      uid_user: this.article.uid_user,
      id_registra: this.idR,
    };
    this.afs.collection("LEAD").doc(this.idR).update({ cuarentena: false });
    this.socket.io.emit("quarantine", objeto);
    this.goBack();
  }

  ngOnInit(): void {
    //localStorage.clear()

    this.idR = this.route.snapshot.paramMap.get("idRegistra");
    this.idA = this.route.snapshot.paramMap.get("idArticle");
    this.subs = this.afs.collection("ARTICLE").doc(this.idA).valueChanges()
      .subscribe((article: any) => {
        this.article = article;

        if (
          article.estatus !== "habilitado" && article.estatus !== "por vencer"
        ) this.disableArticle = true;
      });
    this.subs_two = this.afs.collection("LEAD").doc(this.idR).valueChanges()
      .subscribe((res) => {
        this.lead = res;
      });
  }
  fullview() {
    this.router.navigate([
      "registra",
      this.article.id_document,
      "item-view",
      "cuarentine",
      this.idR,
    ]);
  }

  continue() {
    let obj = {
      id: this.article.id_articulo,
    };
    this.positive_or_negative = this.lead.positive_or_negative;
    this.getIdArticles = this.lead.idArticulos;
    if (
      this.positive_or_negative == undefined && this.getIdArticles == undefined
    ) {
      let idArticulos = firebase.firestore.FieldValue.arrayUnion(obj);
      this.afs.collection("LEAD").doc(this.idR).update({
        idArticulos,
        cuarentena: false,
      }).then((doc) => {
        this.router.navigate(["gestiona", this.idR, "go"]);
      });
    } else if (this.positive_or_negative == undefined) {
      let find = this.getIdArticles.find((article) =>
        article.id == this.article.id_articulo
      );
      if (find) {
        alert("Ya agregaste este articulo");
      } else {
        let idArticulos = firebase.firestore.FieldValue.arrayUnion(obj);
        this.afs.collection("LEAD").doc(this.idR).update({
          idArticulos,
          cuarentena: false,
        }).then((doc) => {
          this.router.navigate(["gestiona", this.idR, "go"]);
        });
      }
    } else {
      let check = this.positive_or_negative.find((article) =>
        article.id_articulo == this.article.id_articulo
      );
      let find = this.getIdArticles.find((article) =>
        article.id == this.article.id_articulo
      );
      if (find || check) {
        alert("Ya agregaste este articulo");
      } else {
        let idArticulos = firebase.firestore.FieldValue.arrayUnion(obj);
        this.afs.collection("LEAD").doc(this.idR).update({
          idArticulos,
          cuarentena: false,
        }).then((doc) => {
          this.router.navigate(["gestiona", this.idR, "go"]);
        });
      }
    }
  }

  noNotification() {
    this.afs.collection("LEAD").doc(this.idR).update({ cuarentena: false });
    this.goBackAndNoNotification();
  }

  ngOnDestroy(): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }
    if (this.subs_two) {
      this.subs_two.unsubscribe();
    }
  }
}
