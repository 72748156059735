import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { SocketService } from './socket/socket.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  dif: any;
  differences = []
  due_dates = []
  new_date = []
  product: any;
  productSelected = [];
  constructor(private afs: AngularFirestore, private socket: SocketService) {

  }


  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  getDays(name) {
    if (name == 'gold99' || name == 'Gold99') {
      return 99;
    } else if (name == 'elite99' || name == 'Elite99') {
      return 369;
    } else if (name == 'diamond99' || name == 'Diamond99') {
      return 189;
    } else if (name == 'imperial99' || name == 'Imperial99') {
      return 449;
    }
  }
  async dates(articles: Array<any>) {
    this.differences = []
    this.due_dates = []
    this.new_date = []
    this.productSelected = []
    for (const product of articles) {
      if (product.commodity == undefined || product.commodity == null) {

        this.differences.push("No Disponible")
        this.due_dates.push("No Disponible")
        this.new_date.push("No Disponible")
        this.productSelected.push("Sin producto asignado")
      } else {
        if (product.commodity.producto == 'no asignado' && product.commodity.estatus == 'cancelado') {
          this.differences.push("No Disponible")
          this.due_dates.push("No Disponible")
          this.new_date.push("No Disponible")
          this.productSelected.push("Sin producto asignado")
        } else {
          if ((product.commodity.start == undefined || product.commodity.start == null)) {
            this.productSelected.push(product.commodity.producto)
            this.differences.push("No Disponible")
            this.due_dates.push("No Disponible")
            this.new_date.push("No Disponible")
          } else {
            let dif: any
            let name_product = product.commodity.producto
            let date_product = new Date(product.commodity.start);
            let finish = new Date(product.commodity.finish);
            let new_Date = moment(date_product).format('DD-MM-YYYY');
            let days = this.getDays(name_product)
            let new_date = this.addDays(date_product, days); // de la fecha de producto cuenta los dias y da una fecha
            // let due_date = moment(new_date).format('DD-MM-YYYY'); //fecha de vencimiento
            // let today = moment().format('DD-MM-YYYY');
            let current_date = moment();
            let dateProduct = moment(new_date);
            let finishDate = moment(finish);
            dif = product.commodity.autoRenovation == 0 ? dateProduct.diff(current_date, 'days') : finishDate.diff(current_date, 'days'); // me da lo dias que quedan
            dif <= 0 ? dif = 0 : dif
            // let days;
            // let get_date_product = product.commodity.start;
            // let new_Date = moment(get_date_product).format('DD-MM-YYYY');
            // let date_product = new Date(get_date_product);
            // let name_product = product.commodity.producto
            // if (name_product == 'imperial99') {
            //   days = 449;
            // } else if (name_product == 'gold99') {
            //   days = 99;
            // } else if (name_product == 'elite99') {
            //   days = 369;
            // } else if (name_product == 'diamond99') {
            //   days = 189;
            // }




            // let new_date = this.fech(date_product, days); // de la fecha de producto cuenta los dias y da una fecha
            // let due_date = moment(new_date).format('DD-MM-YYYY'); //fecha de vencimiento

            // let today = moment().format('DD-MM-YYYY');

            // let current_date = moment(today);
            // let dateProduct = moment(new_date);

            // this.dif = dateProduct.diff(current_date, 'days'); // me da lo dias que quedan
            // this.dif <= 0 ? this.dif = 0 : this.dif



            this.differences.push(dif)
            this.due_dates.push(product.commodity.finish)
            this.new_date.push(new_Date)
            this.productSelected.push(product.commodity.producto)
          }
        }



      }


    }
    let data = {
      differences: this.differences,
      due_dates: this.due_dates,
      new_date: this.new_date,
      product: this.productSelected
    }

    return data
  }



  // productEnable(product) {
  //   this.afs.collection('CUSTOMER',ref=>ref.where('id_cliente','==',product.id_cliente)).valueChanges()
  //   .pipe(take(1)).toPromise().then((results:any[])=>{
  //     let verification = results[0].estatus      
  //       if(verification == 'Habilitado'){
  //         this.afs.doc("ARTICLE/" + product.id_document).update({ estatus: "habilitado" })
  //       }else {
  //         this.afs.doc("ARTICLE/" + product.id_document).update({ estatus: "inhabilitado" })
  //       }
  //   })

  // }

  // productExpired(product) {
  //   this.afs.doc("ARTICLE/" + product.id_document).update({ estatus: "inhabilitado" })
  // }

}
