import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { DataDbService } from 'src/app/SERVICIOS/data-db.service';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {
  public contactForm: UntypedFormGroup;
  idAL: any;
  isEdit: boolean = false;
  tagsToSearch: any;
  features: any[]
  sendingDataToFirebase: boolean = false;
  constructor(private dbData: DataDbService, private router: Router,
    private route: ActivatedRoute, private formBuilder: UntypedFormBuilder,
    private afs: AngularFirestore) {
    this.idAL = this.route.snapshot.paramMap.get('idA');
    if (this.route.snapshot.paramMap.get('edit') !== null) this.isEdit = true
    this.afs.collection('LEAD').doc(this.idAL).valueChanges().pipe(take(1)).toPromise().then((lead: any) => {
      if (lead.completo == true) {
        if (lead.mostrar == true) this.router.navigate([`gestiona/${this.idAL}/go`])
        else {

          this.router.navigate([`historys/registra-history/${this.idAL}/fullview`])
        }
      } else {
        if (this.isEdit) {
          this.tagsToSearch = lead.tagsToSearch
          this.features = this.getCaracteristicas();
          this.contactForm = this.createForm();

        } else {
          this.features = this.getCaracteristicas();
          this.contactForm = this.createForm();
        }
      }
    })


  }

  // get caracteristicas() { return this.contactForm.get('caracteristicas') as FormArray; }
  get caracteristicas() { if (this.contactForm !== undefined) return this.contactForm.get('caracteristicas') as UntypedFormArray; }



  getFeatures() {
    return new UntypedFormGroup({
      caracteristicas: this.formBuilder.array(
        this.features.map((caracteristica) => {
          return this.formBuilder.group({
            id: caracteristica.id,
            descripcion: caracteristica.descripcion,
            seleccionado: caracteristica.seleccionado,
          });
        }),
      ),
    });
  }
  SaveForm() {
    this.sendingDataToFirebase = true;
    let selectedValuesToSearch = []
    selectedValuesToSearch = this.features.filter(
      (feature) => feature.seleccionado === true,
    );


    if (selectedValuesToSearch.length == 0) {
      alert('Debe seleccionar al menos una caracteristica')
      this.sendingDataToFirebase = false
    }
    else {
      this.dbData.saveFeaturesReg(this.idAL, selectedValuesToSearch).then(e => {
        this.router.navigate(['registra', this.idAL, 'info'])
      }).catch(e => {
        this.sendingDataToFirebase = false
      })
    }
  }

  ngOnInit(): void {
  }

  selected(i: number) {
    // Invierte el valor de 'seleccionado' en la característica actual
    this.features[i].seleccionado = !this.features[i].seleccionado;

    const desc = this.features[i].descripcion;
    const tagsCaracteristicas = this.features[i].tags.map(tag => this.normalizeTag(tag));

    // Filtra características duplicadas en 'othersCaracteristicas'
    const uniqueOthersCaracteristicas = this.othersCaracteristicas.filter(other => {
      return !this.features.some(feature => feature.descripcion === other.descripcion);
    });

    // Busca coincidencias en las otras características y agrégales si es necesario
    uniqueOthersCaracteristicas.forEach(other => {
      const tagsAllOthersCaracteristicas = other.tags.map(tag => this.normalizeTag(tag));
      let founds = 0;

      tagsAllOthersCaracteristicas.forEach(tagOthersCaracteristicas => {
        tagsCaracteristicas.forEach(tagCaracteristicas => {
          if (tagOthersCaracteristicas.toLowerCase().includes(tagCaracteristicas.toLowerCase())) {
            founds++;
            if (founds === 1) {
              other.agregadoPor = desc;
              this.features.push(other);
            }
          }
        });
      });
    });

    // Si 'seleccionado' es falso, filtra características en 'features'
    if (!this.features[i].seleccionado) {
      //this.features = this.features.filter(feature => feature.agregadoPor !== desc);
    }



  }
  normalizeTag(tag) {
    return tag.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }








  othersCaracteristicas = [

    {
      "id": 8,
      "descripcion": "Pequeño centro comercial",
      "seleccionado": false,
      "tags": [
        "comercial",
        "centro comercial",
        "pequeño",
        "comercio",
        "negocio",
        "espacio comercial",
        "local comercial",
        "pequeña empresa",
        "zona comercial",
        "área de tiendas",
        "punto de venta"
      ],
      "agregadoPor": ""
    }, {
      "id": 9,
      "descripcion": "Centro comercial",
      "seleccionado": false,
      "tags": [
        "centro comercial",
        "mall",
        "centro de compras",
        "centro de tiendas",
        "complejo comercial",
        "zona comercial",
        "centro de ventas",
        "plaza comercial",
        "área de compras",
        "centro de minoristas",
        "complejo de tiendas",
        "zona de tiendas",
        "centro de entretenimiento",
        "zona de restaurantes",
        "centro de ocio",
        "espacio comercial",
        "área comercial",
        "punto de venta",
        "centro de negocios",
        "centro de servicios",
        "complejo de restaurantes",
        "zona de alimentos",
        "centro de eventos",
        "zona de juegos",
        "centro de ocio",
        "plaza de compras",
        "centro de moda",
        "área de recreación",
        "zona de cine",
        "centro de diversión",
        "zona de ocio"
      ],
      "agregadoPor": ""
    }, {
      "id": 10,
      "descripcion": "Inteligente",
      "seleccionado": false,
      "tags": [
        "inteligente",
        "tecnología avanzada",
        "sistema inteligente",
        "automatización",
        "dispositivos conectados",
        "soluciones inteligentes",
        "tecnología innovadora",
        "casa inteligente",
        "innovación tecnológica",
        "tecnología de vanguardia",
        "automatización residencial",
        "dispositivos inteligentes",
        "tecnología inteligente",
        "inteligencia artificial",
        "automatización de hogar",
        "conectividad",
        "tecnología moderna",
        "eficiencia tecnológica",
        "domótica",
        "soluciones avanzadas",
        "dispositivos conectados",
        "sistema de control",
        "tecnología de punta",
        "hogar inteligente",
        "tecnología eficiente",
        "control remoto",
        "tecnología de precisión",
        "tecnología de automatización",
        "hogar automatizado",
        "sistema de gestión"
      ],
      "agregadoPor": ""
    },
    {
      "id": 11,
      "descripcion": "Estacionamiento",
      "seleccionado": false,
      "tags": [
        "estacionamiento",
        "aparcamiento",
        "parqueadero",
        "zona de estacionamiento",
        "área de aparcamiento",
        "garaje",
        "área de estacionamiento",
        "parqueo",
        "lugar para estacionar",
        "área de aparcamiento",
        "estacionamiento público",
        "estacionamiento privado",
        "área de estacionamiento al aire libre",
        "área de aparcamiento cubierto",
        "área de parqueo",
        "zona de aparcamiento",
        "parqueadero subterráneo",
        "estacionamiento de vehículos",
        "área de garaje",
        "área de parqueadero",
        "espacio de aparcamiento",
        "área de vehículos",
        "zona de garaje",
        "zona de parqueo",
        "lugar de estacionamiento",
        "área de vehículos",
        "espacio para coches",
        "espacio de parqueadero",
        "área de garaje subterráneo",
        "zona de vehículos"
      ],
      "agregadoPor": ""
    }, {
      "id": 12,
      "descripcion": "Escaleras de madera",
      "seleccionado": false,
      "tags": [
        "escaleras de madera",
        "escaleras de madera maciza",
        "escaleras de madera natural",
        "escaleras de madera artesanales",
        "escaleras de madera hechas a mano",
        "escaleras de madera noble",
        "escaleras de madera rústicas",
        "escaleras de madera sólida",
        "escaleras de madera elegantes",
        "escaleras de madera personalizadas",
        "escaleras de madera únicas",
        "escaleras de madera talladas",
        "escaleras de madera resistentes",
        "escaleras de madera duraderas",
        "escaleras de madera modernas",
        "escaleras de madera clásicas",
        "escaleras de madera tradicionales",
        "escaleras de madera antiguas",
        "escaleras de madera contemporáneas",
        "escaleras de madera vintage",
        "escaleras de madera con barandilla",
        "escaleras de madera de diseño",
        "escaleras de madera con pasamanos",
        "escaleras de madera con balustres",
        "escaleras de madera con pasos",
        "escaleras de madera hechas a medida",
        "escaleras de madera de alta calidad",
        "escaleras de madera para interiores",
        "escaleras de madera para exteriores",
        "escaleras de madera pintadas",
        "escaleras de madera barnizadas"
      ],
      "agregadoPor": ""
    }
    , {
      "id": 13,
      "descripcion": "Paredes de madera",
      "seleccionado": false,
      "tags": [
        "paredes de madera",
        "revestimiento de madera",
        "madera en las paredes",
        "paneles de madera",
        "decoración de madera",
        "paredes con acabado de madera",
        "madera en interiores",
        "paneles de madera en paredes",
        "revestimiento de madera natural",
        "madera rústica",
        "decoración de madera en interiores",
        "paredes de madera maciza",
        "madera en la decoración",
        "paredes de madera elegantes",
        "paneles de madera de alta calidad",
        "revestimiento de madera duradero",
        "paneles de madera para interiores",
        "decoración de madera única",
        "madera en la arquitectura",
        "paredes de madera artesanales",
        "madera en el diseño de interiores",
        "paneles de madera de diseño",
        "revestimiento de madera resistente",
        "paneles de madera personalizados",
        "decoración de madera exclusiva",
        "madera en la construcción",
        "paredes de madera con textura",
        "madera en la arquitectura de interiores",
        "paneles de madera únicos",
        "revestimiento de madera de alta calidad",
        "decoración de madera moderna",
        "madera en el interior de las viviendas"
      ],
      "agregadoPor": ""
    }
    , {
      "id": 14,
      "descripcion": "Techos de iluminación por plafones",
      "seleccionado": false,
      "tags": [
        "techos de iluminación por plafones",
        "techos luminosos",
        "techos de iluminación integrada",
        "iluminación de techos",
        "iluminación empotrada",
        "iluminación en techos",
        "iluminación interior",
        "iluminación moderna",
        "techos con luces empotradas",
        "iluminación LED en techos",
        "diseño de techos con iluminación",
        "tecnología de iluminación por plafones",
        "techos de diseño luminoso",
        "plafones en techos",
        "iluminación arquitectónica",
        "techo luminoso con LEDs",
        "iluminación residencial",
        "iluminación de vanguardia",
        "techos de iluminación decorativa",
        "techo con luces integradas",
        "diseño de techos modernos",
        "techo con iluminación de acento",
        "plafones LED en techos",
        "iluminación de ambiente",
        "techo de diseño contemporáneo",
        "iluminación de interiores",
        "techos de iluminación personalizada",
        "iluminación de alta calidad",
        "techos con sistema de iluminación",
        "techo con luces de bajo consumo",
        "iluminación sostenible"
      ],
      "agregadoPor": ""
    }
    , {
      "id": 15,
      "descripcion": "Almacén",
      "seleccionado": false,
      "tags": [
        "almacén",
        "depósito",
        "almacenamiento",
        "espacio de almacenaje",
        "instalación de almacenamiento",
        "depósito de mercancías",
        "almacenamiento industrial",
        "espacio de almacenamiento comercial",
        "almacenamiento de productos",
        "almacén logístico",
        "bodega",
        "espacio de bodega",
        "instalación de almacenamiento de mercancías",
        "almacén de materiales",
        "depósito de suministros",
        "almacén de inventario",
        "almacenamiento de carga",
        "instalación de almacenamiento industrial",
        "almacén de productos",
        "depósito de mercaderías",
        "espacio de almacenaje de productos",
        "almacén de suministros",
        "almacenamiento de almacén",
        "instalación de almacenamiento logístico",
        "almacén de carga",
        "depósito de materiales",
        "almacenamiento de inventario",
        "almacén de suministros de construcción",
        "instalación de almacenamiento de productos",
        "almacenamiento de productos industriales",
        "almacenamiento de materiales de construcción"
      ],
      "agregadoPor": ""
    }
    , {
      "id": 16,
      "descripcion": "Hotel",
      "seleccionado": false,
      "tags": [
        "hotel",
        "alojamiento",
        "hospedaje",
        "establecimiento hotelero",
        "alojamiento turístico",
        "posada",
        "residencia de viaje",
        "lugar de estadía",
        "estancia temporal",
        "hospedaje turístico",
        "hospedaje de lujo",
        "alojamiento de viaje",
        "residencia temporal",
        "lugar de descanso",
        "hotel boutique",
        "alojamiento de vacaciones",
        "residencia de viaje de negocios",
        "lugar de estadía turística",
        "hotel de negocios",
        "alojamiento de turismo",
        "alojamiento de lujo",
        "hotel de lujo",
        "residencia temporal de turismo",
        "lugar de descanso turístico",
        "hotel con comodidades",
        "alojamiento con servicios",
        "hotel con restaurante",
        "alojamiento con piscina",
        "hotel con gimnasio",
        "alojamiento con spa",
        "hotel con vistas panorámicas"
      ],
      "agregadoPor": ""
    }
    ,

    {
      "id": 17,
      "descripcion": "Directorios de marcas",
      "seleccionado": false,
      "tags": [
        "directorios de marcas",
        "listado de marcas",
        "directorio de empresas",
        "listado de negocios",
        "guía de marcas",
        "catálogo de empresas",
        "lista de productos",
        "índice de marcas",
        "guía de compras",
        "catálogo de marcas",
        "directorio de productos",
        "listado de servicios",
        "registro de marcas",
        "catálogo de negocios",
        "índice de servicios",
        "guía de productos",
        "catálogo de productos",
        "directorio de tiendas",
        "listado de marcas comerciales",
        "guía de marcas y empresas",
        "listado de productos y servicios",
        "registro de empresas",
        "guía de negocios",
        "catálogo de servicios",
        "directorio de comercios",
        "índice de negocios",
        "guía de servicios",
        "lista de marcas y productos",
        "catálogo de empresas y negocios",
        "directorio de marcas y empresas",
        "listado de empresas y servicios"
      ],
      "agregadoPor": ""
    }
    , {
      "id": 18,
      "descripcion": "Servicio de estacionado",
      "seleccionado": false,
      "tags": [
        "servicio de estacionado",
        "servicio de aparcamiento",
        "estacionamiento asistido",
        "servicio de valet",
        "aparcacoches",
        "estacionamiento con asistencia",
        "aparcamiento con valet",
        "servicio de estacionamiento con valet",
        "servicio de aparcamiento con asistencia",
        "estacionamiento con valet parking",
        "servicio de valet parking",
        "aparcamiento de cortesía",
        "estacionamiento personalizado",
        "servicio de aparcacoches",
        "estacionamiento seguro",
        "aparcamiento con seguridad",
        "servicio de estacionamiento seguro",
        "estacionamiento con vigilancia",
        "servicio de valet seguro",
        "aparcamiento con atención personalizada",
        "servicio de estacionamiento con seguridad",
        "estacionamiento con servicio de valet",
        "servicio de aparcamiento con vigilancia",
        "aparcamiento de lujo",
        "estacionamiento de cortesía de lujo",
        "servicio de estacionamiento de lujo",
        "estacionamiento con valet de lujo",
        "servicio de valet de lujo",
        "aparcamiento de lujo con asistencia",
        "servicio de aparcacoches de lujo",
        "estacionamiento de lujo seguro"
      ],
      "agregadoPor": ""
    }
    , {
      "id": 19,
      "descripcion": "Piso de mármol",
      "seleccionado": false,
      "tags": [
        "piso de mármol",
        "suelo de mármol",
        "revestimiento de mármol",
        "mármol en suelos",
        "pavimento de mármol",
        "suelo de lujo",
        "mármol natural",
        "pavimento de alta calidad",
        "revestimiento de suelos de mármol",
        "mármol en la decoración",
        "suelo elegante de mármol",
        "pavimento de mármol de diseño",
        "mármol en interiores",
        "suelo de mármol macizo",
        "mármol en el diseño de interiores",
        "pavimento de mármol resistente",
        "mármol en la arquitectura de interiores",
        "suelo de mármol pulido",
        "mármol en el suelo de la vivienda",
        "pavimento de mármol de alta gama",
        "mármol en el revestimiento de suelos",
        "suelo de mármol de alta calidad",
        "mármol en el suelo de lujo",
        "pavimento de mármol de alto nivel",
        "mármol en el interior de las viviendas",
        "suelo de mármol exclusivo",
        "pavimento de mármol de diseño único",
        "mármol en la decoración de interiores",
        "suelo de mármol con acabado de alta calidad",
        "pavimento de mármol para espacios elegantes",
        "mármol en el revestimiento de suelos de lujo",
        "suelo de mármol en espacios de alto nivel"
      ],
      "agregadoPor": ""
    },
    {
      "id": 20,
      "descripcion": "Paredes de cristal",
      "seleccionado": false,
      "tags": [
        "paredes de cristal",
        "paredes acristaladas",
        "muros de vidrio",
        "paredes transparentes",
        "paredes de vidrio",
        "paredes con vidrio",
        "paredes de cristal modernas",
        "paredes de vidrio contemporáneas",
        "paredes de vidrio con marco",
        "paredes de vidrio sin marco",
        "paredes de vidrio de diseño",
        "paredes de vidrio elegantes",
        "paredes de vidrio luminosas",
        "paredes de vidrio con vista",
        "paredes de vidrio panorámicas",
        "paredes de vidrio de alta calidad",
        "paredes de vidrio resistentes",
        "paredes de vidrio de seguridad",
        "paredes de vidrio para espacios de lujo",
        "paredes de vidrio para ambientes modernos",
        "paredes de vidrio con eficiencia energética",
        "paredes de vidrio para oficinas",
        "paredes de vidrio para locales comerciales",
        "paredes de vidrio para interiores",
        "paredes de vidrio con aislamiento térmico",
        "paredes de vidrio con aislamiento acústico",
        "paredes de vidrio para casas",
        "paredes de vidrio para espacios de diseño",
        "paredes de vidrio para restaurantes",
        "paredes de vidrio para hoteles",
        "paredes de vidrio para comercios",
        "paredes de vidrio para edificios modernos"
      ],
      "agregadoPor": ""
    }, {
      "id": 21,
      "descripcion": "Techos de iluminación por bordes",
      "seleccionado": false,
      "tags": [
        "techos de iluminación por bordes",
        "techos con iluminación perimetral",
        "iluminación de bordes en techos",
        "iluminación de contorno",
        "iluminación de techos decorativa",
        "iluminación de techos de diseño",
        "techo luminoso con iluminación perimetral",
        "iluminación de techos de alto nivel",
        "iluminación de techos modernos",
        "techo con luces en los bordes",
        "iluminación de techos de lujo",
        "iluminación de techos contemporáneos",
        "techo con iluminación LED",
        "iluminación de techos de vanguardia",
        "techo con iluminación decorativa",
        "iluminación de techos con efectos visuales",
        "iluminación de techos personalizada",
        "techo con efectos de iluminación",
        "iluminación de techos con colores",
        "techo con iluminación regulable",
        "iluminación de techos con control remoto",
        "techo con iluminación de ambiente",
        "iluminación de techos con tecnología LED",
        "techo con iluminación de colores",
        "iluminación de techos de alto rendimiento",
        "techo con efectos de iluminación LED",
        "iluminación de techos versátil",
        "techo con iluminación de efectos especiales",
        "iluminación de techos para eventos",
        "techo con iluminación interactiva",
        "iluminación de techos para espacios de entretenimiento",
        "techo con iluminación personalizable"
      ],
      "agregadoPor": ""
    }, {
      "id": 22,
      "descripcion": "Construcción en obra gris",
      "seleccionado": false,
      "tags": [
        "construcción en obra gris",
        "edificio en construcción",
        "obra en proceso",
        "estructura en construcción",
        "construcción sin acabados",
        "obra de construcción",
        "edificio en fase de construcción",
        "construcción de estructuras",
        "proyecto de construcción",
        "edificación en obra gris",
        "construcción de inmuebles",
        "construcción de viviendas",
        "construcción de locales",
        "obra en desarrollo",
        "edificio en crecimiento",
        "construcción de cimientos",
        "construcción de estructuras de concreto",
        "construcción de muros",
        "construcción de techos",
        "construcción de paredes",
        "construcción de pisos",
        "construcción de fachadas",
        "construcción de columnas",
        "obra en estado de construcción",
        "construcción en progreso",
        "edificio en evolución",
        "construcción de proyectos inmobiliarios",
        "construcción de proyectos comerciales",
        "obra en desarrollo de edificación",
        "construcción de proyectos de viviendas"
      ],
      "agregadoPor": ""
    }
    , {
      "id": 23,
      "descripcion": "Área de oficinas",
      "seleccionado": false,
      "tags": [
        "área de oficinas",
        "espacio de trabajo",
        "zona de trabajo",
        "oficinas corporativas",
        "espacio de oficina",
        "zona de oficina",
        "área de trabajo profesional",
        "ambiente de oficina",
        "espacio de trabajo colaborativo",
        "área de oficina compartida",
        "espacio de trabajo eficiente",
        "oficinas de alto rendimiento",
        "espacio de trabajo moderno",
        "zona de oficinas de diseño",
        "ambiente de trabajo flexible",
        "espacio de oficina funcional",
        "área de trabajo versátil",
        "espacio de trabajo con tecnología",
        "oficinas con servicios",
        "espacio de oficina equipado",
        "zona de trabajo con comodidades",
        "ambiente de oficina inspirador",
        "espacio de trabajo de calidad",
        "área de oficinas de alto nivel",
        "espacio de trabajo productivo",
        "zona de oficina de vanguardia",
        "ambiente de trabajo profesional",
        "espacio de trabajo corporativo",
        "área de oficina eficiente",
        "espacio de oficina flexible",
        "zona de trabajo de lujo"
      ],
      "agregadoPor": ""
    }
    , {
      "id": 24,
      "descripcion": "Lujoso",
      "seleccionado": false,
      "tags": [
        "lujoso",
        "de alta gama",
        "lujo",
        "elegancia",
        "alto nivel",
        "exclusividad",
        "calidad superior",
        "lujo extremo",
        "suntuoso",
        "sofisticado",
        "confort de lujo",
        "estilo de vida lujoso",
        "productos de lujo",
        "servicios de lujo",
        "experiencia de lujo",
        "bienestar de lujo",
        "lujo refinado",
        "estilo de vida exclusivo",
        "acabados de lujo",
        "detalles de lujo",
        "lujo en cada detalle",
        "lujo en la arquitectura",
        "lujo en el diseño de interiores",
        "lujo en la decoración",
        "lujo en la vida cotidiana",
        "experiencia de lujo inigualable",
        "calidad de lujo",
        "elegancia refinada",
        "lujo en el hogar",
        "lujo en el trabajo",
        "lujo en la hospitalidad",
        "lujo en el ocio"
      ],
      "agregadoPor": ""
    }
    , {
      "id": 25,
      "descripcion": "Estacionamiento con elevador",
      "seleccionado": false,
      "tags": [
        "estacionamiento con elevador",
        "aparcamiento con ascensor",
        "garaje con elevador",
        "aparcamiento automatizado",
        "estacionamiento robotizado",
        "aparcamiento con tecnología de elevador",
        "aparcamiento con sistema de elevación",
        "aparcamiento automático",
        "garaje con sistema de elevación",
        "aparcamiento con acceso rápido",
        "garaje automatizado",
        "estacionamiento con elevación de vehículos",
        "aparcamiento con servicio de ascensor",
        "garaje con acceso rápido",
        "aparcamiento con elevador para coches",
        "estacionamiento con elevador de autos",
        "aparcamiento con tecnología de elevación",
        "garaje con aparcamiento automatizado",
        "aparcamiento con elevador de vehículos",
        "garaje con tecnología de ascensor",
        "aparcamiento de vehículos con elevador",
        "estacionamiento con ascensor para automóviles",
        "aparcamiento con elevación rápida",
        "garaje con sistema de elevación de coches",
        "aparcamiento con acceso automatizado",
        "estacionamiento con elevador de coches",
        "aparcamiento con tecnología de elevación de vehículos",
        "garaje con estacionamiento robotizado",
        "aparcamiento con acceso con elevador",
        "estacionamiento con elevador para automóviles",
        "aparcamiento con tecnología de elevación de autos",
        "garaje con elevador de vehículos",
        "aparcamiento con acceso de vehículos automático"
      ],
      "agregadoPor": ""
    }
    ,
    {
      "id": 26,
      "descripcion": "Piso de madera",
      "seleccionado": false,
      "tags": [
        "piso de madera",
        "suelo de madera",
        "revestimiento de madera",
        "madera en suelos",
        "pavimento de madera",
        "suelo de madera maciza",
        "madera en el diseño de interiores",
        "pavimento de madera de diseño",
        "revestimiento de madera duradero",
        "suelo de madera con acabado de alta calidad",
        "madera en el revestimiento de suelos",
        "pavimento de madera de alta calidad",
        "madera en el suelo de la vivienda",
        "suelo de madera elegante",
        "pavimento de madera resistente",
        "madera en la decoración de interiores",
        "suelo de madera pulido",
        "pavimento de madera de alto nivel",
        "madera en el interior de las viviendas",
        "suelo de madera de lujo",
        "pavimento de madera de diseño único",
        "madera en la decoración de suelos",
        "suelo de madera con acabado de alta gama",
        "pavimento de madera con estilo",
        "madera en el diseño de suelos",
        "suelo de madera con diseño elegante",
        "pavimento de madera de lujo",
        "madera en el suelo de alta calidad",
        "suelo de madera de alta gama",
        "pavimento de madera elegante",
        "madera en suelos de alta calidad",
        "suelo de madera de diseño exclusivo"
      ],
      "agregadoPor": ""
    }
    , {
      "id": 27,
      "descripcion": "Paredes de cartón",
      "seleccionado": false,
      "tags": [
        "paredes de cartón",
        "muros de cartón",
        "paredes ligeras",
        "muros ligeros",
        "revestimiento de cartón",
        "paredes económicas",
        "muros económicos",
        "paredes temporales",
        "muros temporales",
        "construcción con cartón",
        "paredes flexibles",
        "muros flexibles",
        "paredes reciclables",
        "muros reciclables",
        "paredes sostenibles",
        "muros sostenibles",
        "construcción eficiente",
        "paredes ecológicas",
        "muros ecológicos",
        "paredes versátiles",
        "muros versátiles",
        "revestimiento económico",
        "paredes de cartón corrugado",
        "muros de cartón corrugado",
        "paredes de bajo costo",
        "muros de bajo costo",
        "construcción liviana",
        "paredes desmontables",
        "muros desmontables",
        "paredes de fácil montaje",
        "muros de fácil montaje",
        "paredes temporales económicas"
      ],
      "agregadoPor": ""
    }
    , {
      "id": 28,
      "descripcion": "Techos de iluminación lateral",
      "seleccionado": false,
      "tags": [
        "techos de iluminación lateral",
        "techos con iluminación en los bordes",
        "iluminación de bordes en techos",
        "iluminación de contorno",
        "iluminación de techos decorativa",
        "iluminación de techos de diseño",
        "techo luminoso con iluminación en los bordes",
        "iluminación de techos de alto nivel",
        "iluminación de techos modernos",
        "techo con luces en los bordes",
        "iluminación de techos de lujo",
        "iluminación de techos contemporáneos",
        "techo con iluminación LED",
        "iluminación de techos de vanguardia",
        "techo con iluminación decorativa",
        "iluminación de techos con efectos visuales",
        "iluminación de techos personalizada",
        "techo con efectos de iluminación",
        "iluminación de techos con colores",
        "techo con iluminación regulable",
        "iluminación de techos con control remoto",
        "techo con iluminación de ambiente",
        "iluminación de techos con tecnología LED",
        "techo con iluminación de colores",
        "iluminación de techos de alto rendimiento",
        "techo con efectos de iluminación LED",
        "iluminación de techos versátil",
        "techo con iluminación de efectos especiales",
        "iluminación de techos para eventos",
        "techo con iluminación interactiva",
        "iluminación de techos para espacios de entretenimiento",
        "techo con iluminación personalizable"
      ],
      "agregadoPor": ""
    },
    {
      "id": 29,
      "descripcion": "Escaleras de cristal",
      "seleccionado": false,
      "tags": [
        "escaleras de cristal",
        "escaleras con pasamanos de vidrio",
        "escaleras transparentes",
        "escaleras de diseño",
        "escaleras contemporáneas",
        "escaleras de vidrio modernas",
        "escaleras de cristal elegantes",
        "escaleras de vidrio con estilo",
        "escaleras de cristal con diseño exclusivo",
        "escaleras de vidrio con seguridad",
        "escaleras de vidrio de alta calidad",
        "escaleras de cristal con tecnología",
        "escaleras de vidrio de vanguardia",
        "escaleras de cristal con iluminación LED",
        "escaleras de vidrio con detalles elegantes",
        "escaleras de cristal luminosas",
        "escaleras de vidrio con vistas panorámicas",
        "escaleras de vidrio con efectos visuales",
        "escaleras de vidrio de alto rendimiento",
        "escaleras de vidrio versátiles",
        "escaleras de vidrio con diseño moderno",
        "escaleras de vidrio con estilo contemporáneo",
        "escaleras de vidrio para interiores",
        "escaleras de vidrio para espacios de lujo",
        "escaleras de vidrio con seguridad de alto nivel",
        "escaleras de vidrio para proyectos de diseño",
        "escaleras de vidrio para espacios comerciales",
        "escaleras de vidrio con detalles personalizados",
        "escaleras de vidrio para casas modernas",
        "escaleras de vidrio para oficinas de alto nivel",
        "escaleras de vidrio con tecnología de iluminación",
        "escaleras de vidrio para hoteles de lujo",
        "escaleras de vidrio para espacios elegantes"
      ],
      "agregadoPor": ""
    }
    , {
      "id": 30,
      "descripcion": "Jardines",
      "seleccionado": false,
      "tags": [
        "jardines",
        "espacios verdes",
        "áreas de jardín",
        "jardines exteriores",
        "zonas de paisaje",
        "áreas de esparcimiento al aire libre",
        "jardines naturales",
        "espacios de relajación al aire libre",
        "entornos naturales",
        "jardines con paisajismo",
        "áreas de jardín bien cuidadas",
        "jardines con vegetación exuberante",
        "espacios verdes con flores",
        "áreas de jardín con diseño paisajístico",
        "jardines con áreas de descanso",
        "espacios al aire libre con encanto",
        "entornos naturales relajantes",
        "jardines con fuentes",
        "áreas de jardín con senderos",
        "jardines con zonas de juegos",
        "espacios verdes con bancos",
        "áreas de jardín con iluminación",
        "jardines con áreas de picnic",
        "entornos naturales con fauna",
        "jardines con espacios para eventos",
        "áreas de jardín para actividades al aire libre",
        "jardines con arte al aire libre",
        "espacios verdes con estanques",
        "áreas de jardín con áreas de meditación",
        "jardines con plantas autóctonas",
        "entornos naturales con áreas de sombra",
        "jardines con áreas de exhibición de esculturas"
      ],
      "agregadoPor": ""
    },







    { id: 36, descripcion: "Biblioteca", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 37, descripcion: "Calefacción", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 38, descripcion: "Comunidad de golf", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 39, descripcion: "Frente al mar", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 40, descripcion: "Cochera para carruajes", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 41, descripcion: "Embarcadero", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 42, descripcion: "Medios baños", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 43, descripcion: "Encimeras de granito", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 44, descripcion: "Desayunador", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 45, descripcion: "Aire acondicionado por zonas", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 46, descripcion: "Casa de huéspedes", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 47, descripcion: "Caseta de seguridad", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 48, descripcion: "Producción de vino", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 49, descripcion: "Baños", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 50, descripcion: "Cocina catering", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 51, descripcion: "Garaje para carro de golf", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 52, descripcion: "Vista panorámica", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 53, descripcion: "Portero", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 54, descripcion: "Club de campo", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 55, descripcion: "Regador subterráneo de jardín", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 56, descripcion: "Capacidad de autos en garaje", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 57, descripcion: "Vista al campo de golf", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 58, descripcion: "Chimeneas", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 59, descripcion: "Cuarto de vapor", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 60, descripcion: "Conserjería", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 61, descripcion: "Ecológico", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 62, descripcion: "Caballerizas", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 63, descripcion: "Bolera", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 64, descripcion: "Elevador privado", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 65, descripcion: "Cuartos de billar", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 66, descripcion: "Centro fitness", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 67, descripcion: "Jardines", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 68, descripcion: "Comunidad privada", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 69, descripcion: "Acceso directo a pista de esquí", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 70, descripcion: "Cancha de básquet", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 71, descripcion: "Sala de televisión", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 72, descripcion: "Entreplanta", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 73, descripcion: "Piscina", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 74, descripcion: "Suit para familiares políticos", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 75, descripcion: "Piscina", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 76, descripcion: "Porche para vehículos", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 77, descripcion: "Piso de mármol", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 78, descripcion: "Viñedo", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 79, descripcion: "Bar con frigo", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 80, descripcion: "Sistema de interfono", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 81, descripcion: "Evaluación agraria", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 82, descripcion: "Sistema de seguridad", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 83, descripcion: "Helipuerto", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 84, descripcion: "Spa / jacuzzi", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 85, descripcion: "Despensa / mayordomo", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 86, descripcion: "Estudio artístico", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 87, descripcion: "Vestidores", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 88, descripcion: "Cocina al aire libre", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 89, descripcion: "Terraza", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 90, descripcion: "Calefacción por losa", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 91, descripcion: "Habitación de cine", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 92, descripcion: "Techos inclinados", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 93, descripcion: "Techos abovedados", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 94, descripcion: "Gimnasio", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 95, descripcion: "Lago privado", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 96, descripcion: "Control de acceso", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 97, descripcion: "Calefacción geotérmica", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 98, descripcion: "Vista a la ciudad / avenidas principales", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 99, descripcion: "Aspiradora central", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 100, descripcion: "Cancha de ráquetbol", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 101, descripcion: "Vista a las montañas", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 102, descripcion: "Aeropuerto privado", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 103, descripcion: "Granero", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 104, descripcion: "Muelle", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 105, descripcion: "Habitaciones para el personal", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 106, descripcion: "Vista a grandes cuerpos de agua", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 107, descripcion: "Vista a la bahía", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 108, descripcion: "Hidratantes para fuego", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 109, descripcion: "Acceso ferroviario", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 110, descripcion: "Oficinas", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 111, descripcion: "Elevadores", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 112, descripcion: "Piso de concreto puro", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 113, descripcion: "Turbo ventiladores", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 114, descripcion: "Estación de suministro de diésel", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 115, descripcion: "Caseta de seguridad", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 116, descripcion: "Grua industrial", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 117, descripcion: "Revestimiento compuesto de resina", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 118, descripcion: "Seguridad y eficiencia en andenes", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 119, descripcion: "Salidas de emergencia", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 120, descripcion: "Pared alta compuesta", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 121, descripcion: "Zona de camiones", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 122, descripcion: "Andenes de carga", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 123, descripcion: "Piso de concreto compuesto", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 124, descripcion: "Cubierta de techo", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 125, descripcion: "Ecológico", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 126, descripcion: "Báscula de puente", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 127, descripcion: "Dormitorio para conductores", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 128, descripcion: "Habitación refrigerante", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 129, descripcion: "Piso de OSB o madera", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 130, descripcion: "Tragaluz / hoja translúcida", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 131, descripcion: "Inteligente", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 132, descripcion: "Área de estacionamiento", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 133, descripcion: "Inteligente", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 134, descripcion: "Habitación de limpieza", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 135, descripcion: "Piso con rejilla de barra soldada", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 136, descripcion: "Aislamientos para control de temperatura", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 137, descripcion: "Amplias avenidas", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 138, descripcion: "Ecológico", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 139, descripcion: "Instalaciones para el personal", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 140, descripcion: "Piso de placa diamante", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 141, descripcion: "Área de mantenimiento para MHE", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 142, descripcion: "Acceso directo al tren", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 143, descripcion: "Entresuelo", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 144, descripcion: "Piso VDF", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 145, descripcion: "Altura del almacén", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 146, descripcion: "Chalet americano de una planta", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 147, descripcion: "Federal", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 148, descripcion: "Victoriano", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 149, descripcion: "Personalizada", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 150, descripcion: "Desnivel", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 151, descripcion: "Cottage", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 152, descripcion: "Europeo", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 153, descripcion: "Art Nouveau", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 154, descripcion: "Artesanal", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 155, descripcion: "Georgiano", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 156, descripcion: "Chalet", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 157, descripcion: "Apartamento", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 158, descripcion: "Toscano", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 159, descripcion: "Residencia", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 160, descripcion: "Loft", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 161, descripcion: "Castillo", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 162, descripcion: "Cabaña", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 163, descripcion: "Departamento", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 164, descripcion: "Histórica", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 165, descripcion: "Art Déco", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 166, descripcion: "Moderno", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 167, descripcion: "Multi nivel", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 168, descripcion: "Colonial", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 169, descripcion: "Mediterráneo", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 170, descripcion: "Bungalow", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 171, descripcion: "Low country", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 172, descripcion: "Cape Cod", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 173, descripcion: "Adosada / unifamiliar", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 174, descripcion: "Saltbox", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 175, descripcion: "Contemporáneo", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 176, descripcion: "Tudor", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 177, descripcion: "Cooperativa", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 178, descripcion: "Poste y viga", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 179, descripcion: "Condominio", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 180, descripcion: "Casa de campo", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 181, descripcion: "Tradicional", seleccionado: false, tags: [], agregadoPor: "" },

    { id: 182, descripcion: "Náutico", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 183, descripcion: "Actividades al aire libre", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 184, descripcion: "Actividades acuáticas", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 185, descripcion: "Zona metropolitana", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 186, descripcion: "Comunidad de retiro para adultos mayores", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 187, descripcion: "Histórica", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 188, descripcion: "Comunidad balnearia", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 189, descripcion: "En la playa", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 190, descripcion: "Ecuestre / polo", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 191, descripcion: "Frente al río", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 192, descripcion: "Casino / apuestas", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 193, descripcion: "Privacidad", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 194, descripcion: "Club de bahía / playa", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 195, descripcion: "Granja / rancho", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 196, descripcion: "Vida campestre", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 197, descripcion: "Centro turístico", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 198, descripcion: "Polo", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 199, descripcion: "Barrio universitario", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 200, descripcion: "Pesca", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 201, descripcion: "Esquí", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 202, descripcion: "Club de campo", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 203, descripcion: "Barrio residencial", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 204, descripcion: "Coto de caza / safari", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 205, descripcion: "Tenis", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 206, descripcion: "A la orilla del lago", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 207, descripcion: "Club de yates", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 208, descripcion: "Vida ecológica", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 209, descripcion: "A la orilla del agua", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 210, descripcion: "Comunidad con pista de aterrizaje", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 211, descripcion: "Vista al mar / lago / río", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 212, descripcion: "Isla", seleccionado: false, tags: [], agregadoPor: "" },
    { id: 213, descripcion: "Zonas de viñedo", seleccionado: false, tags: [], agregadoPor: "" }





  ];





  getCaracteristicas() {
    return [
      {
        "id": 1,
        "descripcion": "Habitacional",
        "seleccionado": false,
        "tags": [
          "casa con piscina",
          "piscina",
          "vivienda",
          "propiedad",
          "residencia",
          "inmueble",
          "hogar",
          "propiedad con piscina",
          "casa con alberca",
          "casa con piscina privada"
        ]
      },
      {
        "id": 2,
        "descripcion": "Comercial",
        "seleccionado": false,
        "tags": [
          "casa con piscina",
          "piscina",
          "vivienda",
          "propiedad",
          "residencia",
          "inmueble",
          "hogar",
          "propiedad con piscina",
          "casa con alberca",
          "casa con piscina privada"
        ]
      },
      {
        "id": 3,
        "descripcion": "Industrial",
        "seleccionado": false,
        "tags": [
          "casa con piscina",
          "piscina",
          "vivienda",
          "propiedad",
          "residencia",
          "inmueble",
          "hogar",
          "propiedad con piscina",
          "casa con alberca",
          "casa con piscina privada"
        ]
      },
      {
        "id": 4,
        "descripcion": "Piscina",
        "seleccionado": false,
        "tags": [
          "casa con piscina",
          "piscina",
          "vivienda",
          "propiedad",
          "residencia",
          "inmueble",
          "hogar",
          "propiedad con piscina",
          "casa con alberca",
          "casa con piscina privada"
        ]
      },
      {
        "id": 5,
        "descripcion": "Cochera",
        "seleccionado": false,
        "tags": [
          "habitacional",
          "casa con cocheras",
          "cocheras",
          "vivienda",
          "propiedad",
          "residencia",
          "inmueble",
          "hogar",
          "propiedad con cocheras",
          "casa con estacionamiento",
          "casa con garaje"

        ]
      }
      ,

      {
        "id": 6,
        "descripcion": "Casa de 3 pisos",
        "seleccionado": false,
        "tags": [
          "3 pisos",
          "casa de tres plantas",
          "escaleras",
          "vivienda",
          "propiedad",
          "residencia",
          "inmueble",
          "hogar",
          "tres pisos",
          "triple planta"
        ]
      }
      ,
      {
        "id": 7,
        "descripcion": "Casa de 2 pisos",
        "seleccionado": false,
        "tags": [
          "2 pisos",
          "casa de dos plantas",
          "escaleras",
          "vivienda",
          "propiedad",
          "residencia",
          "inmueble",
          "hogar",
          "dos pisos",
          "doble planta"

        ]
      }

    ];
  }



  createForm() {
    return new UntypedFormGroup({
      caracteristicas: this.formBuilder.array(
        this.features.map((caracteristica) => {
          return this.formBuilder.group({
            id: caracteristica.id,
            descripcion: caracteristica.descripcion,
            seleccionado: false,
          });
        }),
      ),
    });
  }





































}
