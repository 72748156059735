<div class="main">
  <div class=" mt-4 mb-3">
    <h2 class="title">Sale detail</h2>
  </div>

  <div class="shadow-lg p-3 mb-5card" style="padding: 10px;">
    <div class="card-block mt-1 mb-3" *ngIf="saleData && article && agent">
      <div class="mb-3 mt-4">
        <div class="row mb">
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold;">Agente asignado</p>
            </div>
            <div class="form-outline">
              <p>{{agent.displayName}}</p>
            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold;">Sale ID</p>
            </div>
            <div class="form-outline">
              <p>{{saleData.id}}</p>

            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold;">Starting day</p>
            </div>
            <div class="form-outline">
              <!-- <p>{{saleData.dayStart | date:'dd-MM-yyyy'}}</p> -->
              <p>{{saleData.positive.date | date:'dd-MM-yyyy'}}</p>
            </div>
          </div>

        </div>
      </div>
      <div class="mb-3">
        <div class="row mb">
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold;">Sale mount</p>
            </div>
            <div class="form-outline">
              <p>${{article.articulo.precio}} {{article.articulo.currency}}</p>
              <!-- <p>{{ saleData.saleMount | currency}} {{saleData.currency}}</p> -->
            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold;">Article ID</p>
            </div>
            <div class="form-outline">
              <p>{{article.id_articulo}}</p>
              <!-- <p>{{saleData.articleId}}</p> -->
            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold;">Run sale date</p>
            </div>
            <div class="form-outline">
              <div class="form-outline" *ngIf="!isMyResquest && !saleData.positive.runSaleDate">

                <div *ngIf="!editDateMeeting1">
                  <button class="ui button" (click)="setDateMeeting1()">Definir
                    fecha</button>


                </div>
                <div *ngIf="editDateMeeting1 && !dateMeeting1"
                  style="display: flex;align-content: space-between;flex-direction: row;justify-content: flex-start;align-items: baseline;">
                  <input type="date" (change)="getDateMeetingChange1($event)" />
                </div>
                <p *ngIf="dateMeeting1">{{dateMeeting1 | date:'dd-MM-yyyy'}}
                </p>
              </div>
              <div class="form-outline" *ngIf="!isMyResquest && saleData.positive.runSaleDate">
                <p>{{saleData.positive.runSaleDate | date:'dd-MM-yyyy'}}</p>
              </div>
            </div>
            <div class="form-outline" *ngIf="isMyResquest">
              <p *ngIf="isMyResquest && saleData.positive.runSaleDate">{{saleData.positive.runSaleDate |
                date:'dd-MM-yyyy'}}</p>
              <p *ngIf="isMyResquest && !saleData.positive.runSaleDate">
                No se ha definido fecha
              </p>
            </div>
          </div>

        </div>
      </div>
      <div class="mb-3">
        <div class="row mb">
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold;">Sale location</p>
            </div>
            <div class="form-outline">
              <!-- <p>{{saleData.saleLocation}}</p> -->
              {{article.articulo.calle}}/{{article.articulo.colonia}}/{{article.articulo.ciudad}}/{{article.articulo.estado}}/{{article.articulo.pais}}

            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold;">Sign sale date</p>
            </div>
            <div class="form-outline">
              <div class="form-outline" *ngIf="!isMyResquest && !saleData.positive.signSaleDate">

                <div *ngIf="!editDateMeeting2">
                  <button class="ui button" (click)="setDateMeeting2()">Definir
                    fecha</button>


                </div>
                <div *ngIf="editDateMeeting2 && !dateMeeting2"
                  style="display: flex;align-content: space-between;flex-direction: row;justify-content: flex-start;align-items: baseline;">
                  <input type="date" (change)="getDateMeetingChange2($event)" />
                </div>

              </div>
              <div class="form-outline" *ngIf="!isMyResquest && saleData.positive.signSaleDate">
                <p>{{saleData.positive.signSaleDate | date:'dd-MM-yyyy'}}</p>
              </div>
            </div>
            <div class="form-outline" *ngIf="isMyResquest">
              <p *ngIf="isMyResquest && saleData.positive.signSaleDate">{{saleData.positive.signSaleDate |
                date:'dd-MM-yyyy'}}</p>
              <p *ngIf="isMyResquest && !saleData.positive.signSaleDate">
                No se ha definido fecha
              </p>
            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold;">Sale ended date</p>
            </div>
            <div class="form-outline" *ngIf="!isMyResquest && !saleData.positive.saleEndedDate">

              <div *ngIf="!editDateMeeting3">
                <button class="ui button" (click)="setDateMeeting3()">Definir
                  fecha</button>


              </div>
              <div *ngIf="editDateMeeting3 && !dateMeeting3"
                style="display: flex;align-content: space-between;flex-direction: row;justify-content: flex-start;align-items: baseline;">
                <input type="date" (change)="getDateMeetingChange3($event)" />
              </div>
              <p *ngIf="dateMeeting3">{{dateMeeting3 | date:'dd-MM-yyyy'}}
              </p>
            </div>
            <div class="form-outline" *ngIf="!isMyResquest && saleData.positive.saleEndedDate">
              <p>{{saleData.positive.saleEndedDate | date:'dd-MM-yyyy'}}</p>
            </div>
            <div class="form-outline" *ngIf="isMyResquest">
              <p *ngIf="isMyResquest && saleData.positive.saleEndedDate">{{saleData.positive.saleEndedDate |
                date:'dd-MM-yyyy'}}</p>
              <p *ngIf="isMyResquest && !saleData.positive.saleEndedDate">
                No se ha definido fecha
              </p>
            </div>
          </div>

        </div>
      </div>
      <div *ngIf="(saleData.positive.progress === 'PLACING-SALE' || saleData.positive.progress === 'SALE-PLACED' || saleData.positive.progress === 'SALE-PLACED-FINISH') && saleData.positive.runSaleDate && 
      saleData.positive.signSaleDate && saleData.positive.saleEndedDate">
        <!-- <div class="ui button" (click)="generatePDFContrato()">
            Generar contrato
          </div>
          <div class="ui button" (click)="generatePDFPagares()">
            Generar pagarés
          </div> -->
      </div>
    </div>
  </div>



  <div class="shadow-lg p-3 mb-5card" style="padding: 10px;" *ngIf="saleData">
    <div class="card-block mt-1 mb-3">
      <div class="flexbox" style="display: flex;">
        <div class="box">
          <div style="display: block;">
            <canvas baseChart [labels]="doughnutChartLabels" [datasets]="doughnutChartDatasets"
              [options]="doughnutChartOptions" [type]="'doughnut'">
            </canvas>

          </div>
        </div>

        <div class="box" style="width: 70%;" *ngIf="saleData.positive.progress === 'DATA-COLLECT'">
          <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
            <div style="width: 100%">
              <div style="display: flex;
                            flex-direction: row;
                            justify-content: space-around;">

                <div style="width: 19%;display: flex;
                               flex-direction: column;
                               align-items: center;
                               justify-content: space-between;
                               flex-wrap: nowrap;">

                  <div class="circle-orange"></div>
                  <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>Data </div>
                  <div>collecting</div>
                </div>

                <div style="width: 19%;display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: nowrap;">

                  <div class="circle-black"></div>
                  <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>Data</div>
                  <div>analyzing</div>
                </div>
                <div style="width: 19%;display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: nowrap;">
                  <!-- crear un circulo -->
                  <div class="circle-black"></div>
                  <div class="card" style="   width: 100%; height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>Data</div>
                  <div>validating</div>
                </div>
                <div style="width: 19%;display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: nowrap;">
                  <!-- crear un circulo -->
                  <div class="circle-black"></div>
                  <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>Placing</div>
                  <div>sale</div>
                </div>
                <div style="width: 19%;display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: nowrap;">
                  <!-- crear un circulo -->
                  <div class="circle-black"></div>
                  <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>sale</div>
                  <div>placed</div>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div class="box" style="width: 70%;" *ngIf="saleData.positive.progress === 'DATA-ANALYZING'">
          <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
            <div style="width: 100%">
              <div style="display: flex;
                            flex-direction: row;
                            justify-content: space-around;">

                <div style="width: 19%;display: flex;
                               flex-direction: column;
                               align-items: center;
                               justify-content: space-between;
                               flex-wrap: nowrap;">

                  <div class="circle-green"></div>
                  <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>Data </div>
                  <div>collecting</div>
                </div>

                <div style="width: 19%;display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: nowrap;">

                  <div class="circle-orange"></div>
                  <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>Data</div>
                  <div>analyzing</div>
                </div>
                <div style="width: 19%;display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: nowrap;">
                  <!-- crear un circulo -->
                  <div class="circle-black"></div>
                  <div class="card" style="   width: 100%; height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>Data</div>
                  <div>validating</div>
                </div>
                <div style="width: 19%;display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: nowrap;">
                  <!-- crear un circulo -->
                  <div class="circle-black"></div>
                  <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>Placing</div>
                  <div>sale</div>
                </div>
                <div style="width: 19%;display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: nowrap;">
                  <!-- crear un circulo -->
                  <div class="circle-black"></div>
                  <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>sale</div>
                  <div>placed</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="box" style="width: 70%;" *ngIf="saleData.positive.progress === 'DATA-VALIDATING'">
          <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
            <div style="width: 100%">
              <div style="display: flex;
                            flex-direction: row;
                            justify-content: space-around;">

                <div style="width: 19%;display: flex;
                               flex-direction: column;
                               align-items: center;
                               justify-content: space-between;
                               flex-wrap: nowrap;">

                  <div class="circle-green"></div>
                  <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>Data </div>
                  <div>collecting</div>
                </div>

                <div style="width: 19%;display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: nowrap;">

                  <div class="circle-green"></div>
                  <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>Data</div>
                  <div>analyzing</div>
                </div>
                <div style="width: 19%;display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: nowrap;">
                  <!-- crear un circulo -->
                  <div class="circle-orange"></div>
                  <div class="card" style="   width: 100%; height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>Data</div>
                  <div>validating</div>
                </div>
                <div style="width: 19%;display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: nowrap;">
                  <!-- crear un circulo -->
                  <div class="circle-black"></div>
                  <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>Placing</div>
                  <div>sale</div>
                </div>
                <div style="width: 19%;display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: nowrap;">
                  <!-- crear un circulo -->
                  <div class="circle-black"></div>
                  <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>sale</div>
                  <div>placed</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="box" style="width: 70%;" *ngIf="saleData.positive.progress === 'PLACING-SALE'">
          <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
            <div style="width: 100%">
              <div style="display: flex;
                            flex-direction: row;
                            justify-content: space-around;">

                <div style="width: 19%;display: flex;
                               flex-direction: column;
                               align-items: center;
                               justify-content: space-between;
                               flex-wrap: nowrap;">

                  <div class="circle-green"></div>
                  <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>Data </div>
                  <div>collecting</div>
                </div>

                <div style="width: 19%;display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: nowrap;">

                  <div class="circle-green"></div>
                  <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>Data</div>
                  <div>analyzing</div>
                </div>
                <div style="width: 19%;display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: nowrap;">
                  <!-- crear un circulo -->
                  <div class="circle-green"></div>
                  <div class="card" style="   width: 100%; height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>Data</div>
                  <div>validating</div>
                </div>
                <div style="width: 19%;display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: nowrap;">
                  <!-- crear un circulo -->
                  <div class="circle-orange"></div>
                  <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>Placing</div>
                  <div>sale</div>
                </div>
                <div style="width: 19%;display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: nowrap;">
                  <!-- crear un circulo -->
                  <div class="circle-black"></div>
                  <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>sale</div>
                  <div>placed</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box" style="width: 70%;" *ngIf="saleData.positive.progress === 'SALE-PLACED'">
          <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
            <div style="width: 100%">
              <div style="display: flex;
                            flex-direction: row;
                            justify-content: space-around;">

                <div style="width: 19%;display: flex;
                               flex-direction: column;
                               align-items: center;
                               justify-content: space-between;
                               flex-wrap: nowrap;">

                  <div class="circle-green"></div>
                  <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>Data </div>
                  <div>collecting</div>
                </div>

                <div style="width: 19%;display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: nowrap;">

                  <div class="circle-green"></div>
                  <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>Data</div>
                  <div>analyzing</div>
                </div>
                <div style="width: 19%;display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: nowrap;">
                  <!-- crear un circulo -->
                  <div class="circle-green"></div>
                  <div class="card" style="   width: 100%; height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>Data</div>
                  <div>validating</div>
                </div>
                <div style="width: 19%;display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: nowrap;">
                  <!-- crear un circulo -->
                  <div class="circle-green"></div>
                  <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>Placing</div>
                  <div>sale</div>
                </div>
                <div style="width: 19%;display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: nowrap;">
                  <!-- crear un circulo -->
                  <div class="circle-orange"></div>
                  <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>sale</div>
                  <div>placed</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box" style="width: 70%;" *ngIf="saleData.positive.progress === 'SALE-PLACED-FINISH'">
          <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
            <div style="width: 100%">
              <div style="display: flex;
                            flex-direction: row;
                            justify-content: space-around;">

                <div style="width: 19%;display: flex;
                               flex-direction: column;
                               align-items: center;
                               justify-content: space-between;
                               flex-wrap: nowrap;">

                  <div class="circle-green"></div>
                  <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>Data </div>
                  <div>collecting</div>
                </div>

                <div style="width: 19%;display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: nowrap;">

                  <div class="circle-green"></div>
                  <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>Data</div>
                  <div>analyzing</div>
                </div>
                <div style="width: 19%;display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: nowrap;">
                  <!-- crear un circulo -->
                  <div class="circle-green"></div>
                  <div class="card" style="   width: 100%; height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>Data</div>
                  <div>validating</div>
                </div>
                <div style="width: 19%;display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: nowrap;">
                  <!-- crear un circulo -->
                  <div class="circle-green"></div>
                  <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>Placing</div>
                  <div>sale</div>
                </div>
                <div style="width: 19%;display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: nowrap;">
                  <!-- crear un circulo -->
                  <div class="circle-green"></div>
                  <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                    <div class="card-body">
                    </div>
                  </div>
                  <div>sale</div>
                  <div>placed</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>












  </div>