import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanAdminGuard } from '../SERVICIOS/can/can-admin.guard';
import { CanEnterGuard } from '../SERVICIOS/can/can-enter.guard';
import { AuthGuard } from '../SERVICIOS/guards/auth.guard';
import { DisabledGuard } from '../SERVICIOS/guards/disabled.guard';
import { SupportGuard } from '../SERVICIOS/guards/support.guard';
import { AdminComponent } from './admin/admin.component';
import { PerfilComponent } from './perfil/perfil.component';


const routes: Routes = [
{path:'admin', component:AdminComponent,
children:[
  {path:'',component:PerfilComponent},
  ],canActivate:[AuthGuard, CanEnterGuard,DisabledGuard,CanAdminGuard, SupportGuard]
}


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
