import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { GenerateIdService } from 'src/app/SERVICIOS/generate-id.service';
import firebase from 'firebase/compat/app';
import moment from 'moment';

@Component({
  selector: 'app-others-articles',
  templateUrl: './others-articles.component.html',
  styleUrls: ['./others-articles.component.scss'],
})
export class OthersArticlesComponent implements OnInit {
  idC: string;
  customer: any;
  articles = [];
  positives_or_negatives = [];
  observations = [];
  notAssignedArticles = []
  notAssignedArticlesData = []
  positive_or_negative = []
  @ViewChild('myModal') myModal;
  @ViewChild('myModalNoObservations') myModalNoObservations;
  modalRef;
  count = 0;
  constructor(
    public afs: AngularFirestore,
    private route: ActivatedRoute,
    public modal: NgbModal,
    private router: Router,

    private generateId: GenerateIdService
  ) { }

  ngOnInit(): void {
    this.idC = this.route.snapshot.paramMap.get('idCustomer');

    this.getData();
  }
  getData() {

    this.afs
      .collection('CUSTOMER')
      .doc(this.idC)
      .valueChanges()
      .pipe(take(1))


      .toPromise()
      .then
      ((data: any) => {

        this.positives_or_negatives = [];
        this.notAssignedArticlesData = []
        this.articles = [];
        this.customer = data;
        if (data.hasOwnProperty('idArticulos')) {
          if (data.idArticulos.length > 0) {
            this.notAssignedArticles = data.idArticulos
            //   this.positive_or_negative = data.positive_or_negative
          } else {
            this.notAssignedArticles = []
          }
        }
        this.getArticles(data);


      });
  }
  getArticles(customer: any) {
    this.count++;
    if (this.notAssignedArticles.length > 0) {

      this.getNotAssignedArticles();

    }
    if (customer.hasOwnProperty('positive_or_negative')) {
      this.getPosNeg();
    }

  }
  getNotAssignedArticles() {
    this.afs
      .collection('ARTICLE')

      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((articles: any[]) => {
        this.notAssignedArticlesData = []
        for (let i = 0; i < this.notAssignedArticles.length; i++) {
          for (let j = 0; j < articles.length; j++) {
            if (this.notAssignedArticles[i].id == articles[j].id_articulo) {
              this.notAssignedArticlesData.push(articles[j])
            }

          }

        }
      })
  }
  getPosNeg() {
    this.articles = [];
    this.positives_or_negatives = [];
    this.afs
      .collection('CUSTOMER')
      .doc(this.idC)
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((customer: any) => {
        this.articles = [];
        this.positives_or_negatives = [];


        this.positives_or_negatives = customer.positive_or_negative;
        for (const posneg of this.positives_or_negatives) {
          this.afs
            .collection('ARTICLE', (ref) =>
              ref.where('id_articulo', '==', posneg.id_articulo)
            )
            .valueChanges()
            .pipe(take(1))
            .toPromise()
            .then(async (data: any[]) => {
              let article = data[0];
              article.estatus = posneg.estatus;
              article.positive = null;
              article.agent = await this.getAgentData(article.uid_user);

              if (customer.positives) {
                for (const positive of customer.positives) {



                  if (positive.id_document_article == article.id_document) {
                    article.positive = positive;
                  }
                }
              }
              // //  article.positive = positive;
              article.agent = await this.getAgentData(article.uid_user);
              this.articles.push(article);


            });
        }
      });
  }
  getPosNegWithData(data) {
    this.afs
      .collection('CUSTOMER')
      .doc(this.idC)
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((customer: any) => {
        this.articles = [];
        this.positives_or_negatives = [];
        let positive_or_negative = customer.positive_or_negative;
        let positives = customer.positives;
        this.positives_or_negatives = positive_or_negative;
        this.positive_or_negative.push(data)


        for (const posneg of positive_or_negative) {

          this.afs
            .collection('ARTICLE', (ref) =>
              ref.where('id_articulo', '==', posneg.id_articulo)
            )
            .valueChanges()
            .pipe(take(1))
            .toPromise()
            .then(async (data: any[]) => {
              let article = data[0];



              article.positive = null;
              article.agent = await this.getAgentData(article.uid_user);

              for (const positive of positives) {
                if (positive.id_document_article == article.id_document) {
                  article.positive = positive;
                }
              }
              // article.placed_data = posneg.estatus;
              // if (positives != null && positives != undefined) {
              //   for (const positive of positives) {
              //     if (positive.id_document_article == article.id_document) {
              //       article.positive = positive;
              //       article.agent = await this.getAgentData(article.uid_user);
              //     }
              //   }
              // } else {
              //   article.positive = {};
              // }

              this.articles.push(article);

            });
        }
      });
  }
  progessDetail(operation) {
    let type = operation.positive.type;

    if (type == "lease") {
     


      this.router.navigate([
        "lease/lease-list/" +
        operation.positive.id_document_lead +
        "/" +
        operation.positive.id +
        "/full-view",
        true,
      ]);
    } else if (type == "sale") {
      this.router.navigate([
        "sale/sale-list/" +
        operation.positive.id_document_lead +
        "/" +
        operation.positive.id +
        "/full-view",
        operation.positive.fromCustomer,
      ]);
    }
  }
  advanceArticle(articulo) {
    this.router.navigate([
      'historys',
      'ingresa-history',
      articulo.id_document,
      'advance',
    ]);
    // this.router.navigate([`registra/${articulo.id_document}/item-view`])
  }
  editArticle(articulo) {
    this.router.navigate([
      'ingresa',
      this.idC,
      'articulo',
      articulo.id_document,
      'articulos',
      'edit',
    ]);
  }
  addArticle() {
    this.router.navigate(['article-listing/other-article/id/', this.idC]);
  }
  setPositive(article: any, i: number) {
    //eliminar de la lista de articulos no asignados
    this.notAssignedArticlesData.splice(i, 1);

    this.afs
      .collection('CUSTOMER')
      .doc(this.idC)
      .update({ 'idArticulos': this.notAssignedArticlesData }).then((value) => {
        let positive = {
          id: this.generateId.createId(),
          argumento: 'No hay observaciones',
          id_articulo: article.id_articulo,
          id_document: article.id_document,
          evaluado: false,
          estatus: "positivo",
          cambio: false,
          hora: moment().format('hh:mm:ss a'),
          fecha: moment().format('DD-MM-YYYY'),
          date: new Date().getTime(),
        }
        let data = firebase.firestore.FieldValue.arrayUnion(positive)
        this.afs.collection('CUSTOMER').doc(this.idC).update({
          'positive_or_negative': data

        })
        this.getData()

      });


  }
  setNegative(article: any, i: number) {
    this.notAssignedArticlesData.splice(i, 1);

    this.afs
      .collection('CUSTOMER')
      .doc(this.idC)
      .update({ 'idArticulos': this.notAssignedArticlesData }).then(() => {
        let negative = {
          id: this.generateId.createId(),
          argumento: 'No hay observaciones',
          id_articulo: article.id_articulo,
          id_document: article.id_document,
          evaluado: false,
          estatus: "negativo",
          cambio: false,
          hora: moment().format('hh:mm:ss a'),
          fecha: moment().format('DD-MM-YYYY'),
          date: new Date().getTime(),
        }
        let data = firebase.firestore.FieldValue.arrayUnion(negative)
        this.afs.collection('CUSTOMER').doc(this.idC).update({
          'positive_or_negative': data

        })
        this.getData()
      });

  }
  seeObservations(article: any) {
    let data = article;
    //saber si a\existe observaciones
    this.observations = [];

    if (data.observations != null && data.observations != undefined) {
      this.modalRef = this.modal.open(this.myModal, {
        size: 'md',
        animation: true,
        centered: true,
        backdrop: true,
      });
      this.observations = data.observations;
    } else {
      this.modalRef = this.modal.open(this.myModalNoObservations, {
        size: 'md',
        animation: true,
        centered: true,
        backdrop: true,
      });
    }
  }

  async getAgentData(uid: string) {
    var data: any = await (await this.afs.collection('USERS').doc(uid).get().toPromise()).data();
    return data.displayName;

  }
}
