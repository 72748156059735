import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanEnterGuard } from '../SERVICIOS/can/can-enter.guard';
import { AuthGuard } from '../SERVICIOS/guards/auth.guard';
import { CommunityManageGuard } from '../SERVICIOS/guards/community-manage.guard';
import { DisabledGuard } from '../SERVICIOS/guards/disabled.guard';
import { SupportGuard } from '../SERVICIOS/guards/support.guard';
import { ArticleCuarentineComponent } from './article-cuarentine/article-cuarentine.component';
import { ArticleListingComponent } from './article-listing/article-listing.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },

  //
  {
    path: 'article-cuarentine/:idRegistra/:idArticle',
    component: ArticleCuarentineComponent,
    canActivate: [
      AuthGuard,
      CanEnterGuard,
      DisabledGuard,
      SupportGuard,
      CommunityManageGuard,
    ],
  },
  //
  {
    path: 'article-listing',
    component: ArticleListingComponent,
    canActivate: [AuthGuard, CanEnterGuard, DisabledGuard, SupportGuard],
  },
  {
    path: 'article-listing/other-article/id/:idCustomerOtherArticle',
    component: ArticleListingComponent,
    canActivate: [AuthGuard, CanEnterGuard, DisabledGuard, SupportGuard],
  },
  {
    path: 'article-listing/request/:idkeyaccess',
    component: ArticleListingComponent,
    canActivate: [AuthGuard, CanEnterGuard, DisabledGuard, SupportGuard],
  },
  //
  {
    path: 'article-listing/:idRegistra',
    component: ArticleListingComponent,
    canActivate: [
      AuthGuard,
      CanEnterGuard,
      DisabledGuard,
      SupportGuard,
      CommunityManageGuard,
    ],
  },

  //
  //
  {
    path: 'historys',
    loadChildren: () =>
      import('./historys/historys.module').then((m) => m.HistorysModule),
    canActivate: [
      AuthGuard,
      CanEnterGuard,
      DisabledGuard,
      SupportGuard,
      CommunityManageGuard,
    ],
  },
  {
    path: 'community-manage',
    loadChildren: () =>
      import('./community-manage/community-manage.module').then(
        (m) => m.CommunityManageModule
      ),
  },
  {
    path: 'sale',
    loadChildren: () => import('./sale/sale.module').then((m) => m.SaleModule),
    canActivate: [
      AuthGuard,
      CanEnterGuard,
      DisabledGuard,
      SupportGuard,
      CommunityManageGuard,
    ],
  },
  {
    path: 'lease',
    loadChildren: () =>
      import('./lease/lease.module').then((m) => m.LeaseModule),
    canActivate: [
      AuthGuard,
      CanEnterGuard,
      DisabledGuard,
      SupportGuard,
      CommunityManageGuard,
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class ComercialRoutingModule {}
