<div class="main">
  <div class="mt-4 mb-3">
    <h2 class="title">Lease detail</h2>
  </div>

  <div class="shadow-lg p-3 mb-5card" style="padding: 10px">
    <div class="card-block mt-1 mb-3" *ngIf="leaseData && article && agent">
      <div class="mb-3 mt-4">
        <div class="row mb">
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold">Agente asignado</p>
            </div>
            <div class="form-outline">
              <p>{{ agent.displayName }}</p>
            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold">Lease ID</p>
            </div>
            <div class="form-outline">
              <p>{{ leaseData.positive.id }}</p>
            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold">Fecha de inicio</p>
            </div>
            <div class="form-outline">
              <!-- <p>{{leaseData.dayStart | date:'dd-MM-yyyy'}}</p> -->
              <p>{{ leaseData.positive.date | date : "dd-MM-yyyy" }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="row mb">
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold">Lease mount</p>
            </div>
            <div class="form-outline">
              <p>
                ${{ article.articulo.precio }} {{ article.articulo.currency }}
              </p>
              <!-- <p>{{ leaseData.leaseMount | currency}} {{leaseData.currency}}</p> -->
            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold">Article ID</p>
            </div>
            <div class="form-outline">
              <p>{{ article.id_articulo }}</p>
              <!-- <p>{{leaseData.articleId}}</p> -->
            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold">Run lease date</p>
            </div>
            <div class="form-outline">
              <div
                class="form-outline"
                *ngIf="!isMyResquest && !leaseData.positive.runLeaseDate"
              >
                <div *ngIf="!editDateMeeting1">
                  <button class="ui button" (click)="setDateMeeting1()">
                    Definir fecha
                  </button>
                </div>
                <div
                  *ngIf="editDateMeeting1 && !dateMeeting1"
                  style="
                    display: flex;
                    align-content: space-between;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: baseline;
                  "
                >
                  <input type="date" (change)="getDateMeetingChange1($event)" />
                </div>
                <p *ngIf="dateMeeting1">
                  {{ dateMeeting1 | date : "dd-MM-yyyy" }}
                </p>
              </div>
              <div
                class="form-outline"
                *ngIf="!isMyResquest && leaseData.positive.runLeaseDate"
              >
                <p>
                  {{ leaseData.positive.runLeaseDate | date : "dd-MM-yyyy" }}
                </p>
              </div>
              <div class="form-outline" *ngIf="isMyResquest">
                <p *ngIf="isMyResquest && leaseData.positive.runLeaseDate">
                  {{ leaseData.positive.runLeaseDate | date : "dd-MM-yyyy" }}
                </p>
                <p *ngIf="isMyResquest && !leaseData.positive.runLeaseDate">
                  No se ha definido fecha
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="row mb">
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold">Ubicación</p>
            </div>
            <div class="form-outline">
              <!-- <p>{{leaseData.leaseLocation}}</p> -->
              <p style="margin: 0" *ngIf="article.articulo.no_interior == ''">
                {{ article.articulo.calle }},
                {{ article.articulo.no_exterior }}, colonia
                {{ article.articulo.colonia }}.
              </p>
              <p style="margin: 0" *ngIf="article.articulo.no_interior != ''">
                {{ article.articulo.calle }},
                {{ article.articulo.no_exterior }}, int
                {{ article.articulo.no_interior }}, colonia
                {{ article.articulo.colonia }}.
              </p>
              <p>
                {{ article.articulo.ciudad }}, {{ article.articulo.estado }},
                {{ article.articulo.pais }}, {{ article.articulo.cp }}
              </p>
            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold">Fecha de firma</p>
            </div>
            <div class="form-outline">
              <div
                class="form-outline"
                *ngIf="!isMyResquest && !leaseData.positive.signLeaseDate"
              >
                <div *ngIf="!editDateMeeting2">
                  <button class="ui button" (click)="setDateMeeting2()">
                    Definir fecha
                  </button>
                </div>
                <div
                  *ngIf="editDateMeeting2 && !dateMeeting2"
                  style="
                    display: flex;
                    align-content: space-between;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: baseline;
                  "
                >
                  <input type="date" (change)="getDateMeetingChange2($event)" />
                </div>
                <p *ngIf="dateMeeting2">
                  {{ dateMeeting2 | date : "dd-MM-yyyy" }}
                </p>
              </div>
              <div
                class="form-outline"
                *ngIf="!isMyResquest && leaseData.positive.signLeaseDate"
              >
                <p>
                  {{ leaseData.positive.signLeaseDate | date : "dd-MM-yyyy" }}
                </p>
              </div>
              <div class="form-outline" *ngIf="isMyResquest">
                <p *ngIf="isMyResquest && leaseData.positive.signLeaseDate">
                  {{ leaseData.positive.signLeaseDate | date : "dd-MM-yyyy" }}
                </p>
                <p *ngIf="isMyResquest && !leaseData.positive.signLeaseDate">
                  No se ha definido fecha
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold">Número de meses</p>
            </div>
            <div class="box-select-filter" *ngIf="auth.userFullAdmin(user)">
              <div class="row mb">
                <div class="col">
                  <div class="form-outline" *ngIf="!leaseData.positive.months">
                    <select
                      class="form-select"
                      (change)="addMonths($event.target.value)"
                    >
                      <option
                        value="{{ month }}"
                        *ngFor="let month of numMonths; let i = index"
                      >
                        {{ month }}
                      </option>
                    </select>
                    <button class="ui button" (click)="setMonths()">
                      Establecer
                    </button>
                  </div>
                  <div class="form-outline" *ngIf="leaseData.positive.months">
                    {{ leaseData.positive.months }}
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!auth.userFullAdmin(user)">
              <div class="row mb">
                <div class="col">
                  <div class="form-outline" *ngIf="!leaseData.positive.months">
                    <p>No se ha seleccionado</p>
                  </div>
                  <div class="form-outline" *ngIf="leaseData.positive.months">
                    {{ leaseData.positive.months }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <div class="row mb">
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold">
                Fecha de finalización
              </p>
            </div>
            <div
              class="form-outline"
              *ngIf="!isMyResquest && !leaseData.positive.leaseEndedDate"
            >
              <p>No se ha establecido</p>
            </div>
            <div
              class="form-outline"
              *ngIf="!isMyResquest && leaseData.positive.leaseEndedDate"
            >
              <p>
                {{ leaseData.positive.leaseEndedDate | date : "dd-MM-yyyy" }}
              </p>
            </div>
            <div class="form-outline" *ngIf="isMyResquest">
              <p *ngIf="isMyResquest && leaseData.positive.leaseEndedDate">
                {{ leaseData.positive.leaseEndedDate | date : "dd-MM-yyyy" }}
              </p>
              <p *ngIf="isMyResquest && !leaseData.positive.leaseEndedDate">
                No se ha definido fecha
              </p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div
          *ngIf="
            (leaseData.positive.progress === 'PLACING-LEASE' ||
              leaseData.positive.progress === 'LEASE-PLACED' ||
              leaseData.positive.progress === 'LEASE-PLACED-FINISH') &&
            leaseData.positive.runLeaseDate &&
            leaseData.positive.signLeaseDate &&
            leaseData.positive.leaseEndedDate
          "
        >
          <div class="ui button" (click)="generatePDFContrato()">
            Generar contrato
          </div>
          <div class="ui button" (click)="generatePDFPagares()">
            Generar pagarés
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="shadow-lg p-3 mb-5card"
    style="padding: 10px"
    *ngIf="leaseData"
  >
    <div class="card-block mt-1 mb-3">
      <h3 style="font-weight: bold">Progreso</h3>
      <div
        class="flexbox"
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        "
      >
        <div class="box">
          <div style="display: block">
            <canvas
              width="200vh"
              height="200vh"
              baseChart
              [datasets]="doughnutChartDatasets"
              [options]="doughnutChartOptions"
              [type]="'doughnut'"
            >
            </canvas>
          </div>
        </div>

        <div
          class="box"
          style="width: 70%"
          *ngIf="leaseData.positive.progress === 'DATA-COLLECT'"
        >
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
            "
          >
            <div style="width: 100%">
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-around;
                "
              >
                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <div class="circle-orange"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>Data</div>
                  <div>collecting</div>
                </div>

                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <div class="circle-black"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>Data</div>
                  <div>analyzing</div>
                </div>
                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <!-- crear un circulo -->
                  <div class="circle-black"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>Data</div>
                  <div>validating</div>
                </div>
                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <!-- crear un circulo -->
                  <div class="circle-black"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>Placing</div>
                  <div>lease</div>
                </div>
                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <!-- crear un circulo -->
                  <div class="circle-black"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>lease</div>
                  <div>placed</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="box"
          style="width: 70%"
          *ngIf="leaseData.positive.progress === 'DATA-ANALYZING'"
        >
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
            "
          >
            <div style="width: 100%">
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-around;
                "
              >
                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <div class="circle-green"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>Data</div>
                  <div>collecting</div>
                </div>

                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <div class="circle-orange"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>Data</div>
                  <div>analyzing</div>
                </div>
                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <!-- crear un circulo -->
                  <div class="circle-black"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>Data</div>
                  <div>validating</div>
                </div>
                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <!-- crear un circulo -->
                  <div class="circle-black"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>Placing</div>
                  <div>lease</div>
                </div>
                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <!-- crear un circulo -->
                  <div class="circle-black"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>lease</div>
                  <div>placed</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="box"
          style="width: 70%"
          *ngIf="leaseData.positive.progress === 'DATA-VALIDATING'"
        >
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
            "
          >
            <div style="width: 100%">
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-around;
                "
              >
                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <div class="circle-green"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>Data</div>
                  <div>collecting</div>
                </div>

                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <div class="circle-green"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>Data</div>
                  <div>analyzing</div>
                </div>
                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <!-- crear un circulo -->
                  <div class="circle-orange"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>Data</div>
                  <div>validating</div>
                </div>
                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <!-- crear un circulo -->
                  <div class="circle-black"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>Placing</div>
                  <div>lease</div>
                </div>
                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <!-- crear un circulo -->
                  <div class="circle-black"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>lease</div>
                  <div>placed</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="box"
          style="width: 70%"
          *ngIf="
            leaseData.positive.progress === 'PLACING-LEASE' ||
            leaseData.positive.progress === 'PLACING-SALE'
          "
        >
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
            "
          >
            <div style="width: 100%">
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-around;
                "
              >
                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <div class="circle-green"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>Data</div>
                  <div>collecting</div>
                </div>

                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <div class="circle-green"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>Data</div>
                  <div>analyzing</div>
                </div>
                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <!-- crear un circulo -->
                  <div class="circle-green"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>Data</div>
                  <div>validating</div>
                </div>
                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <!-- crear un circulo -->
                  <div class="circle-orange"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>Placing</div>
                  <div>lease</div>
                </div>
                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <!-- crear un circulo -->
                  <div class="circle-black"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>lease</div>
                  <div>placed</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="box"
          style="width: 70%"
          *ngIf="
            leaseData.positive.progress === 'LEASE-PLACED' ||
            leaseData.positive.progress === 'SALE-PLACED'
          "
        >
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
            "
          >
            <div style="width: 100%">
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-around;
                "
              >
                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <div class="circle-green"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>Data</div>
                  <div>collecting</div>
                </div>

                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <div class="circle-green"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>Data</div>
                  <div>analyzing</div>
                </div>
                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <!-- crear un circulo -->
                  <div class="circle-green"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>Data</div>
                  <div>validating</div>
                </div>
                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <!-- crear un circulo -->
                  <div class="circle-green"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>Placing</div>
                  <div>lease</div>
                </div>
                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <!-- crear un circulo -->
                  <div class="circle-orange"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>lease</div>
                  <div>placed</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="box"
          style="width: 70%"
          *ngIf="
            leaseData.positive.progress === 'LEASE-PLACED-FINISH' ||
            leaseData.positive.progress === 'SALE-PLACED-FINISH'
          "
        >
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
            "
          >
            <div style="width: 100%">
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-around;
                "
              >
                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <div class="circle-green"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>Data</div>
                  <div>collecting</div>
                </div>

                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <div class="circle-green"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>Data</div>
                  <div>analyzing</div>
                </div>
                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <!-- crear un circulo -->
                  <div class="circle-green"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>Data</div>
                  <div>validating</div>
                </div>
                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <!-- crear un circulo -->
                  <div class="circle-green"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>Placing</div>
                  <div>lease</div>
                </div>
                <div
                  style="
                    width: 19%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                  "
                >
                  <!-- crear un circulo -->
                  <div class="circle-green"></div>
                  <div
                    class="card"
                    style="width: 100%; height: 0.5px; background-color: black"
                  >
                    <div class="card-body"></div>
                  </div>
                  <div>lease</div>
                  <div>placed</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
