import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class MapService {
  constructor(private http: HttpClient) {}

  // getapi(cp: number) {
  //   let api = `https://apis.forcsec.com/api/codigos-postales/20211011-d4f74b00fe7469a2/${cp}`
  //   return this.http.get(api);
  // }
  getapi(cp: number) {
    return this.http.get(
      `https://set99-world.onrender.com/postal_code/${cp}`,
    );
  }
  generateNewToken(idKa: any, uid: any) {
    return this.http.get(
      `https://set99-world.onrender.com/generate-token/${uid.toString()}/${idKa.toString()}`,
    );
  }
  getToken(token: any, uid: any) {
    return this.http.get(
      `https://set99-world.onrender.com/send-token/${token.toString()}/${uid.toString()}`,
    );
  }
  // getArticles(id_customer) {
  // return this.http.get(`http://localhost:3000/get_articles/${id_customer}`);
  // }
}
