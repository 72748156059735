import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RegistraRoutingModule } from './registra-routing.module';
import { RegistraClienteComponent } from './registra-cliente/registra-cliente.component';
import { RegistraComponent } from './registra/registra.component';
import { InfoComponent } from './info/info.component';
import { ItemViewComponent } from './item-view/item-view.component';
import { GalleryModule } from 'ng-gallery';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { FeaturesComponent } from './features/features.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ArticleImagesComponent } from './article-images/article-images.component';


@NgModule({
  declarations: [RegistraClienteComponent, RegistraComponent, InfoComponent, ItemViewComponent, FeaturesComponent, ArticleImagesComponent,],
  imports: [
    CommonModule,
    NgxPaginationModule,
    GalleryModule.withConfig({
      imageSize: 'cover',
      thumbPosition: 'bottom'
    }),
    BrowserAnimationsModule,
    NoopAnimationsModule,
    RegistraRoutingModule, FormsModule, ReactiveFormsModule, NgxSpinnerModule, NgbModule
  ]
})
export class RegistraModule { }
