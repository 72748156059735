import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { Location } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class CanAdminGuard implements CanActivate {
  constructor(private auth: AuthService,private _location: Location) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    return this.auth.user$.pipe(
      take(1),
      map(user => (user && this.auth.canAdmin(user) ? true : false) || (user && this.auth.isFullAdmin(user) ? true : false)), // <-- important line
      tap(canView => {
        if (!canView) {
          this._location.back();
        }else {
          return true
        }
      })
    );
  }
  
}
