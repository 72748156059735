import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { User } from 'src/app/COMERCIAL/MODULOS/user.interface';
import { AuthService } from 'src/app/SERVICIOS/auth.service';
import { DataDbService } from 'src/app/SERVICIOS/data-db.service';
import { GetTokensService } from 'src/app/SERVICIOS/get-tokens.service';
import { SocketService } from 'src/app/SERVICIOS/socket/socket.service';

@Component({
  selector: 'app-lease-list',
  templateUrl: './lease-list.component.html',
  styleUrls: ['./lease-list.component.scss'],
})
export class LeaseListComponent implements OnInit {
  user: any;
  p: number = 1;
  public m_p: number = 1;
  customer = [];
  my_customer = [];
  uid_user: string;
  c = 0;
  denied: boolean = false;
  public filterPost = '';
  public filterPostu = '';
  valueName: string;
  valueNameu: string;
  selected: number = 0;
  disabledInput: boolean = true;
  disabledInputu: boolean = true;
  incomeSelected: any;
  modal2: boolean = false;
  modal1: boolean = false;
  argumentForm = new UntypedFormGroup({
    argument: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(1000),
    ]),
  });
  subs_two: Subscription;
  subs_one: Subscription;
  text_input: string;
  text_inputu: string;
  p_long: number;
  totalArticles = [];
  allArticles = [];
  articlesLength = [];
  productsLength = [];
  leases: any = [];
  leasesc: any = [];
  uniqueArray = [];
  isFullAdmin: boolean = false;
  constructor(
    public dbData: DataDbService,
    public afs: AngularFirestore,
    public auth: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.auth.user$
      .pipe(take(1))
      .toPromise()
      .then((user) => {
        this.user = user;
        this.afs.collection('USERS').doc(user.uid).valueChanges().subscribe((user: User) => {
          if (user.role == 'FULL-ADMIN') this.isFullAdmin = true;
          this.getDataLease(user.uid);
        });
      });
  }
  async getDataLease(uid) {
    const customerLeadsPromise = new Promise<void>((resolve) => {
      if (this.isFullAdmin) {
        this.afs.collection('CUSTOMER').valueChanges().subscribe((leads) => {
          let nlead: any = [];
          for (const lead of leads) {
            if (
              (lead['positives'] != undefined && lead['positives'] != null) &&
              lead['positives'].length > 0
            ) {
              nlead.push(lead);
            }
          }
          this.leasesc = [];
          for (let i = 0; i < nlead.length; i++) {
            for (let j = 0; j < nlead[i].positives.length; j++) {
              if (nlead[i].positives[j].type == 'lease') {
                let operation = {
                  cliente: nlead[i].cliente,
                  id_document: nlead[i].id_document,
                  uid_user: nlead[i].uid_user,
                  completo: nlead[i].completado,
                  estatus: nlead[i].estatus,
                  id: nlead[i].positives[j].id,
                  id_lead: nlead[i].positives[j].id_lead,
                  positive: nlead[i].positives[j],
                  fromCustomer: true
                };
                this.leasesc.push(operation);
              }
            }
          }
          resolve();
        });
      } else {
        this.afs.collection('CUSTOMER', ref => ref.where("uid_user", "==", uid)).valueChanges().subscribe((leads) => {
          let nlead: any = [];
          for (const lead of leads) {
            if (
              (lead['positives'] != undefined && lead['positives'] != null) &&
              lead['positives'].length > 0
            ) nlead.push(lead);

          }
          this.leasesc = [];
          for (let i = 0; i < nlead.length; i++) {
            for (let j = 0; j < nlead[i].positives.length; j++) {
              if (nlead[i].positives[j].type == 'lease') {
                let operation = {
                  cliente: nlead[i].cliente,
                  id_document: nlead[i].id_document,
                  uid_user: nlead[i].uid_user,
                  completo: nlead[i].completado,
                  estatus: nlead[i].estatus,
                  id: nlead[i].positives[j].id,
                  id_lead: nlead[i].positives[j].id_lead,
                  positive: nlead[i].positives[j],
                  fromCustomer: true
                };
                this.leasesc.push(operation);
              }
            }
          }
          resolve();
        });
      }

    });

    if (this.isFullAdmin) {
      const leadLeadsPromise = new Promise<void>((resolve) => {
        this.afs.collection('LEAD').valueChanges().subscribe((leads) => {
          let nlead: any = [];
          for (const lead of leads) {
            if (
              (lead['positives'] != undefined && lead['positives'] != null) &&
              lead['positives'].length > 0 &&
              lead['escalate'] != true
            ) {
              nlead.push(lead);
            }
          }
          this.leases = [];
          for (let i = 0; i < nlead.length; i++) {
            for (let j = 0; j < nlead[i].positives.length; j++) {
              if (nlead[i].positives[j].type == 'lease') {
                let operation = {
                  cliente: nlead[i].cliente,
                  id_document: nlead[i].id_document,
                  uid_user: nlead[i].uid_user,
                  completo: nlead[i].completo,
                  estatus: nlead[i].estatus,
                  id: nlead[i].positives[j].id,
                  id_lead: nlead[i].positives[j].id_lead,
                  positive: nlead[i].positives[j],
                  fromCustomer: false
                };
                this.leases.push(operation);
              }
            }
          }
          resolve();
        });
      });
      await Promise.all([customerLeadsPromise, leadLeadsPromise]);

      const combinedArray = [...this.leasesc, ...this.leases];
      this.uniqueArray = Array.from(new Set(combinedArray));
    } else {
      const leadLeadsPromise = new Promise<void>((resolve) => {
        this.afs.collection('LEAD', ref => ref.where("uid_user", "==", uid)).valueChanges().subscribe((leads) => {
          let nlead: any = [];
          for (const lead of leads) {
            if (
              (lead['positives'] != undefined && lead['positives'] != null) &&
              lead['positives'].length > 0 &&
              lead['escalate'] != true
            ) {
              nlead.push(lead);
            }
          }
          this.leases = [];
          for (let i = 0; i < nlead.length; i++) {
            for (let j = 0; j < nlead[i].positives.length; j++) {
              if (nlead[i].positives[j].type == 'lease') {
                let operation = {
                  cliente: nlead[i].cliente,
                  id_document: nlead[i].id_document,
                  uid_user: nlead[i].uid_user,
                  completo: nlead[i].completo,
                  estatus: nlead[i].estatus,
                  id: nlead[i].positives[j].id,
                  id_lead: nlead[i].positives[j].id_lead,
                  positive: nlead[i].positives[j],
                  fromCustomer: false
                };
                this.leases.push(operation);
              }
            }
          }
          resolve();
        });
      });
      await Promise.all([customerLeadsPromise, leadLeadsPromise]);

      const combinedArray = [...this.leasesc, ...this.leases];
      this.uniqueArray = Array.from(new Set(combinedArray));
    }


  }



  fullview(todo) {

    this.router.navigate([
      'lease/lease-list/' +
      todo.id_document +
      '/' +
      todo.positive.id +
      '/full-view',
      todo.fromCustomer
    ]);
  }
  filterWithSelectu(e: String) {
    localStorage.setItem('component', 'ingresa');

    if (e == '1') {
      this.valueNameu = 'id';
      this.disabledInputu = false;
      localStorage.setItem('disabledInputu', 'false');
      localStorage.setItem('valueNameu', this.valueNameu);
    } else if (e == '2') {
      this.valueNameu = 'id_cliente';
      this.disabledInputu = false;
      localStorage.setItem('disabledInputu', 'false');
      localStorage.setItem('valueNameu', this.valueNameu);
    } else if (e == '3') {
      this.valueNameu = 'nombre';
      this.disabledInputu = false;
      localStorage.setItem('disabledInputu', 'false');
      localStorage.setItem('valueNameu', this.valueNameu);
    } else if (e == '4') {
      this.valueNameu = 'uid_user';
      this.disabledInputu = false;
      localStorage.setItem('disabledInputu', 'false');
      localStorage.setItem('valueNameu', this.valueNameu);
    } else if (e == '5') {
      this.valueNameu = 'id_article';
      this.disabledInputu = false;
      localStorage.setItem('disabledInputu', 'false');
      localStorage.setItem('valueNameu', this.valueNameu);
    } else if (e == '6') {
      this.valueNameu = 'habilitado';
      this.disabledInputu = true;
      localStorage.setItem('disabledInputu', 'true');
      localStorage.setItem('valueNameu', this.valueNameu);
    } else if (e == '7') {
      this.valueNameu = 'deshabilitado';
      this.disabledInputu = true;
      localStorage.setItem('disabledInputu', 'true');
      localStorage.setItem('valueNameu', this.valueNameu);
      // localStorage.setItem('valueName', this.valueName);
    }
  }
  getTextu(e) {
    localStorage.setItem('component', 'ingresa');

    this.resetPageu();
    localStorage.setItem('filterPostu', e);
  }
  resetPageu() {
    this.p = 1;
    localStorage.setItem('pageu', '1');
  }
}
