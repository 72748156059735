import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PrivacyComponent } from './auth/privacy/privacy.component';
import { SendEmailComponent } from './auth/send-email/send-email.component';
import { TermsComponent } from './auth/terms/terms.component';
import { ChatsComponent } from "./chats/ChatsComponent";
import { ProfileComponent } from './profile/profile.component';
import { CanEnterGuard } from './SERVICIOS/can/can-enter.guard';
import { AuthGuard } from './SERVICIOS/guards/auth.guard';
import { CommunityManageGuard } from './SERVICIOS/guards/community-manage.guard';
import { DisabledGuard } from './SERVICIOS/guards/disabled.guard';
import { SupportGuard } from './SERVICIOS/guards/support.guard';
import { AllarticlesComponent } from './allarticles/allarticles.component';
import { PhoneLoginComponent } from './auth/phone-login/phone-login.component';
import { VerificationCodeComponent } from './auth/verification-code/verification-code.component';
import { AddEmailComponent } from './auth/add-email/add-email.component';





const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  { path: 'login', loadChildren: () => import('./auth/login/login.module').then(m => m.LoginModule) },
  { path: 'register', loadChildren: () => import('./auth/register/register.module').then(m => m.RegisterModule) },
  { path: 'forgot-password', loadChildren: () => import('./auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
  { path: 'verification-email', component: SendEmailComponent },
  { path: 'terms-and-conditions', component: TermsComponent },
  { path: 'privacy-policy', component: PrivacyComponent },
  {
    path: 'login-phone/:type',
    component: PhoneLoginComponent
  }, {
    path: 'login-phone/:type/:uid',
    component: PhoneLoginComponent
  }, {
    path: 'add-email/:uid',
    component: AddEmailComponent
  },

  {
    path: 'register-phone',
    component: VerificationCodeComponent
  },
  { path: 'allarticles', component: AllarticlesComponent },


  //
  { path: 'welcome', loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomeModule), canActivate: [AuthGuard] },


  //
  { path: 'pagina-principal', loadChildren: () => import('./pagina-principal/pagina-principal.module').then(m => m.PaginaPrincipalModule), canActivate: [AuthGuard, CanEnterGuard, DisabledGuard, SupportGuard, CommunityManageGuard] },
  //
  { path: 'operaciones', loadChildren: () => import('./OPERACIONES/operaciones.module').then(m => m.OPERACIONESModule), canActivate: [AuthGuard, SupportGuard, CommunityManageGuard] },
  { path: 'profile/:id', component: ProfileComponent, canActivate: [AuthGuard, CanEnterGuard, DisabledGuard, CommunityManageGuard, SupportGuard] },
  { path: 'my-profile/:id', component: ProfileComponent, canActivate: [AuthGuard, CanEnterGuard, DisabledGuard, CommunityManageGuard, SupportGuard] },
  { path: 'support', component: ChatsComponent, canActivate: [AuthGuard, DisabledGuard, CommunityManageGuard] },

]

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
