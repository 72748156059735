import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanEnterGuard } from 'src/app/SERVICIOS/can/can-enter.guard';
import { AuthGuard } from 'src/app/SERVICIOS/guards/auth.guard';
import { CommunityManageGuard } from 'src/app/SERVICIOS/guards/community-manage.guard';
import { DisabledGuard } from 'src/app/SERVICIOS/guards/disabled.guard';
import { SupportGuard } from 'src/app/SERVICIOS/guards/support.guard';
import { SaleListFullviewComponent } from './sale-list-fullview/sale-list-fullview.component';
import { SaleListComponent } from './sale-list/sale-list.component';
import { SaleComponent } from './sale.component';

const routes: Routes = [
  {
    // path: 'sale-list', component: SaleComponent, children: [
    //     { path: 'list', component: SaleListComponent },
    //     { path: ':idlist/full-view', component: SaleListFullviewComponent },
    path: 'sale-list',
    component: SaleComponent,
    children: [
      { path: 'list', component: SaleListComponent },
      {
        path: ':idDocument/:idlist/full-view/:fromCustomer',
        component: SaleListFullviewComponent,
      },
    ],
    canActivate: [
      CanEnterGuard,
      AuthGuard,
      DisabledGuard,
      
      SupportGuard,
      CommunityManageGuard,
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SaleRoutingModule {}
