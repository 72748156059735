import { Component, OnDestroy, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { AuthService } from "../SERVICIOS/auth.service";
import { SocketService } from "../SERVICIOS/socket/socket.service";
import { SubirArchivoService } from "../SERVICIOS/subir-archivo.service";
import { Location } from "@angular/common";
import { User } from "../COMERCIAL/MODULOS/user.interface";
import { RecentActivityInterface } from "../interfaces/activity";
import moment from "moment";
import { GenerateIdService } from "../SERVICIOS/generate-id.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit, OnDestroy {
  all_income: number = 0;
  all_active_income: number = 0;
  all_inactive_income: number = 0;
  all_records: number = 0;
  all_commodities: number = 0;
  all_commodityPositive: number = 0;
  all_commodityNegative: number = 0;
  id_user: string;
  uid_user: string;
  user: any;
  user_status: string;
  public rol_selected: string;
  leadPositive = [];
  leadNegative = [];
  totalLeadsPositive = 0;
  totalLeadsNegative = 0;
  totalAllLeads = 0;

  actives = [];
  totalArticlesActives = 0;
  totalArticlesInactives = 0;
  totalAllArticles = 0;
  image: string;
  set_change_img: boolean = false;
  urlImage: any;
  User: User;
  btndisabled: boolean = true;
  event: any;
  changeRole: boolean = false;
  subs_one: Subscription;
  subs_two: Subscription;
  subs_three: Subscription;
  subs_four: Subscription;
  url_image: string | ArrayBuffer;
  isMyUser: boolean = true;
  totalIcome: number = 0;
  activeIncome: number = 0;
  inactiveIncome: number = 0;
  totalLead: number = 0;
  activeLead: number = 0;
  inactiveLead: number = 0;
  sub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    public auth: AuthService,
    private subirArchivo: SubirArchivoService,
    private socket: SocketService,
    private router: Router,
    private _location: Location,
    private generateId: GenerateIdService,
  ) {
  }

  ngOnInit(): void {
    this.auth.user$.pipe(take(1)).toPromise().then((user) => {
      this.User = user;
      this.id_user = this.route.snapshot.paramMap.get("id");
      if (this.id_user == user.uid) this.isMyUser = true;
      else this.isMyUser = false;

      this.sub = this.afs.collection("USERS").doc(this.id_user).valueChanges()
        .subscribe((res) => {
          this.user = res;

          this.urlImage = this.user.photoURL;
        });
      this.afs.collection("USERS").doc(this.id_user).valueChanges().pipe(
        take(1),
      ).toPromise().then((res) => {
        if (this.User.role == "ADMIN" || this.User.role == "FULL-ADMIN") {
          this.allIncome();
          this.allRecords();
          this.getStaticLeads(this.id_user);
          this.getMyIcome(this.id_user);
        } else {


          this.getStaticLeads(this.id_user);
          this.getMyIcome(this.id_user);
        }
      });
    });
  }

  addEmail() {
    this.router.navigate(['/add-email/' + this.id_user]);
  }

  addPhoneNumber() {
    this.router.navigate(['/login-phone/login/' + this.id_user]);
  }

  back() {
    this._location.back();
  }

  getStaticLeads(uidUser) {
    let getLeads = [];
    this.afs.collection("LEAD", (ref) => ref.where("uid_user", "==", uidUser))
      .valueChanges().pipe(take(1)).toPromise().then((data: any) => {
        getLeads = data;
        for (let x = 0; x <= getLeads.length - 1; x++) {
          this.leadPositive.push(getLeads[x].commodityPositive);
          this.leadNegative.push(getLeads[x].commodityNegative);
        }
        this.leadPositive.forEach((suma) => {
          this.totalLeadsPositive += suma;
        });
        this.leadNegative.forEach((suma) => {
          this.totalLeadsNegative += suma;
        });
        this.totalAllLeads = getLeads.length;
      });
  }



  getStaticArticle(uidUser) {
    let getArticles = [];
    this.afs.collection(
      "ARTICLE",
      (ref) => ref.where("uid_user", "==", uidUser),
    ).valueChanges().pipe(take(1)).toPromise()
      .then((data: any) => {
        getArticles = data;
        for (let x = 0; x <= getArticles.length - 1; x++) {
          this.actives.push(getArticles[x].estatus);
        }
        this.actives.forEach((suma) => {
          if (
            suma != "incompleto" &&
            (suma == "en revision" || suma == "habilitado")
          ) {
            this.totalArticlesActives++;
          } else if (suma != "incompleto" && suma == "inhabilitado") {
            this.totalArticlesInactives++;
          }
        });

        this.getAllArticles(
          this.totalArticlesActives,
          this.totalArticlesInactives,
        );
      });
  }
  getAllArticles(active, inactives) {
    this.totalAllArticles = active + inactives;
  }

  allIncome() {
    this.afs.collection("CUSTOMER").valueChanges().pipe(take(1)).toPromise()
      .then((all: any[]) => {
        this.all_income = all.length;
        let active = all.filter((customer) => customer.estatus == "habilitado");
        this.all_active_income = active.length;
        let inactive = all.filter((customer) =>
          customer.estatus == "deshabilitado" || customer.estatus == "denegado"
        );
        this.all_inactive_income = inactive.length;
      });
  }

  allRecords() {
    this.afs.collection("LEAD").valueChanges().pipe(take(1)).toPromise().then(
      (leads: any[]) => {
        let allcommodityRelation = [];
        let allcommodityPositive = [];
        let allcommodityNegative = [];
        for (const i of leads) {
          allcommodityRelation.push(i.commodityRelation);
          allcommodityPositive.push(i.commodityPositive);
          allcommodityNegative.push(i.commodityNegative);
        }
        allcommodityRelation.forEach((c) => {
          this.all_commodities += c;
        });
        allcommodityPositive.forEach((c) => {
          this.all_commodityPositive += c;
        });
        allcommodityNegative.forEach((c) => {
          this.all_commodityNegative += c;
        });
      },
    );
  }

  getMyIcome(uid_user) {
    this.afs.collection(
      "CUSTOMER",
      (ref) => ref.where("uid_user", "==", uid_user),
    ).valueChanges().pipe(take(1)).toPromise().then((income: any[]) => {
      this.totalIcome = income.length;
      this.activeIncome = income.filter((income) =>
        income.estatus == "habilitado"
      ).length;
      this.inactiveIncome =
        income.filter((income) => income.estatus !== "habilitado").length;
    });
  }
  // getMyLead(uid_user) {
  //   this.afs.collection('LEAD', ref => ref.where('uid_user', '==', uid_user)).valueChanges().pipe(take(1)).toPromise().then((income: any[]) => {
  //     this.totalLead= income.length
  //     this.activeLead = income.filter((income) => income.estatus == "habilitado").length
  //     this.inactiveLead = income.filter((income) => income.estatus !== "habilitado").length
  //   })
  // }

  changeRoles() {
    this.changeRole = true;
  }

  cancelChnage() {
    this.changeRole = false;
  }

  onUpload(e) {
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (event) => {
      this.urlImage = (<FileReader>event.target).result;
      this.set_change_img = true;
      this.subirArchivo._extraerArchivos(e.target.files);
    };
  }

  cancel(user) {
    this.set_change_img = false;
    this.subirArchivo.LimpiarArchivos();
    this.urlImage = user.photoURL;
  }
  changeProfileImg() {
    this.subirArchivo.cargarImagenes();
    this.set_change_img = false;
  }

  desable() {
    this.afs.collection("USERS").doc(this.id_user).update({
      status: "desabled",
    }).then((e) => {
      this.socket.io.emit("userDisabled", this.user.uid);
    });
  }
  enable() {
    this.afs.collection("USERS").doc(this.id_user).update({
      status: "enabled",
    });
  }

  acept() {
    this.afs.collection("USERS").doc(this.id_user).update({
      status: "enabled",
      role: this.rol_selected,
    }).then((e) => {
      this.afs.collection("USERS").doc(this.id_user).get().toPromise().then(
        (user: any) => {
          let newUser = user.data();
          let data = {
            uid: newUser.uid,
            tokens: newUser.tokens,
          };
          this.socket.io.emit("newUser", data);

          let setRecentActivity = {
            date: moment().format(),

            homeTitle: "¡Has sido aprobado!",
            generalTitle: "¡Felicidades " + newUser.displayName +
              "! Ahora formas parte de Net99",
            responses: false,
            from: "profile",
            subtitle: "¡Has sido aprobado!",
            description: "¡Felicidades! Ahora formas parte de Set99.",
            id: this.generateId.createId(),
            archived: false,
            important: false,
            id_document_component: newUser.uid,
            routeObservation: "profile/" + newUser.uid,
            isError: false,

            view: false,
            uid_user: newUser.uid,
            type: "activity",
            isFullview: true,

            canUploadData: false,
            canReply: false,
          };
          this.socket.io.emit("recent-activity", {
            recentActivity: setRecentActivity,
            uid: newUser.uid,
          });
          this.changeRole = false;
        },
      );
    });
  }
  changeRol() {
    this.afs.collection("USERS").doc(this.id_user).update({
      status: "enabled",
      role: this.rol_selected,
    });
    this.changeRole = false;
  }
  reject() {
    this.afs.collection("USERS").doc(this.id_user).update({
      status: "rejected",
    });
  }

  selectRole(value) {
    if (value) {
      this.btndisabled = false;
    }
  }

  ngOnDestroy() {
    if (this.sub !== undefined) this.sub.unsubscribe();
  }
}
