import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-bank-data',
  templateUrl: './bank-data.component.html',
  styleUrls: ['./bank-data.component.scss']
})
export class BankDataComponent implements OnInit {
  public bankForm: UntypedFormGroup;
  idC: string;
  constructor(public afs: AngularFirestore, private route: ActivatedRoute, private router: Router,) { }

  ngOnInit() {
    this.bankForm = this.createFormGroup();
    this.idC = this.route.snapshot.paramMap.get('idCustomer');

  }


  createFormGroup() {

    return new UntypedFormGroup({
      nombre: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(2),
      ]),
      apellido: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(2),
      ]),
      institucion: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(2),
      ]),
      num_cuenta: new UntypedFormControl('', [
        Validators.minLength(6),
        Validators.maxLength(10),
      ]),
      clabe: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(10),
      ]),
    });
  }
  get nombre() {
    return this.bankForm.get('nombre');
  }
  get apellido() {
    return this.bankForm.get('apellido');
  }
  get institucion() {
    return this.bankForm.get('institucion');
  }
  get num_cuenta() {
    return this.bankForm.get('num_cuenta');
  }
  get clabe() {
    return this.bankForm.get('clabe');
  }

  SaveForm() {
    this.afs.collection('CUSTOMER').doc(this.idC).update({
      beneficiario: {
        nombre: this.nombre.value,
        apellido: this.apellido.value,
        institucion: this.institucion.value,
        num_cuenta: this.num_cuenta.value,
        clabe: this.clabe.value,
      }
    }).then(() => {
      this.router.navigate(['gestiona']);
      // this.afs.collection('CUSTOMER').doc(this.idC).get().toPromise().then((doc: any) => {
      //   if (doc.exists) {
      //     let positives = doc.data().positives;
      //     for (let i = 0; i < positives.length; i++) {
      //       positives[i].dataBank = true
      //     }
      //     this.afs.collection('CUSTOMER').doc(this.idC).update({
      //       positives: positives
      //     }).then(() => {
      //       this.router.navigate(['gestiona']);

      //     })
      //   } else {
      //     console.log('No such document!');
      //   }
      // });
    });
  }

}
