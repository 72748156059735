<div class="main" *ngIf="user">
  <!-- <div class=" d-flex ">
    <i class="large arrow left icon" (click)="back()" style="cursor:pointer;"></i>
    <h2 style="margin: 0;" *ngIf="!isMyUser">Perfil del usuario</h2>
    <h2 style="margin: 0;" *ngIf="isMyUser">Mi perfil</h2>
    <div></div>
  </div> -->
  <div class=" p-3" style="height: 90vh;">
    <div class="d-flex flex-row justify-content-between">
      <h1 class="display-4 mb-5" *ngIf="!isMyUser">Perfil de usuario</h1>
      <h1 class="display-4 mb-5" *ngIf="isMyUser">Mi perfil</h1>
      <div class="card-new" (click)="addPhoneNumber()" *ngIf="  isMyUser &&  !user.phoneNumber">
        Agregar Número telefónico
      </div>
      <div class="card-new" (click)="addEmail()" *ngIf=" isMyUser &&  !user.email">
        Agregar correo electrónico
      </div>
    </div>
    <div class="d-flex flex-row justify-content-around">
      <div class="card shadow-lg p-3" style="width: 30%;">
        <div class="d-flex flex-column">
          <div class="user-icon mt-2">
            <div class="circle" *ngIf="urlImage == null; else getImg"></div>
            <ng-template #getImg>
              <label for="img-user" class="circle-img" style="padding: 0 20% 0 20%;" *ngIf="user.uid === User.uid">
                <div id="new_img_profile" *ngIf="urlImage">
                  <img [src]="urlImage" alt="" />
                </div>
              </label>
              <div class="circle-img" id="new_img_profile" *ngIf="urlImage && user.uid != User.uid">
                <img [src]="urlImage" alt="" />
              </div>

            </ng-template>
            <div class="text-center mt-2" *ngIf="id_user">
              <input type="file" id="img-user" style="display: none" (change)="onUpload($event)" />


              <div class="btn-options mb-4" *ngIf="set_change_img">
                <button class="ui button" (click)="cancel(user)">Cancelar</button>
                <button class="btn btn-primary" (click)="changeProfileImg()">
                  Actualizar imagen
                </button>
              </div>
            </div>
          </div>
          <div class="user-info justify-content-center  mt-3 " style="padding: 0 20% 0 20%;">
            <h1 class="h5 fw-bold ">{{ user.displayName }}</h1>
            <p class="text-muted">
              {{ user.id }}
            </p>

            <div class="d-flex align-items-center">
              <p class="text-light" *ngIf="user.role == 'NO-ROLE'">
                En espera de aprobación
              </p>
              <p class="fw-light" style="color: rgb(155, 137, 58);" *ngIf="user.role !== 'NO-ROLE'">
                {{ user.role }}
              </p>
              <div class="changeRole" (click)="changeRoles()"
                *ngIf="!(user.status == 'undefined' && user.role == 'NO-ROLE') && (User.uid !== id_user) && !changeRole && auth.userFullAdmin(User) == true">
                &nbsp; &nbsp;<p>Cambiar</p>
              </div>

            </div>
            <div class="box-info-t">
              <div class="buttons-options">
                <div class="mb-2" style="display: flex; align-items: center;"
                  *ngIf="(user.role == 'NO-ROLE' &&(user.status == 'undefined' || user.status == 'rejected') &&auth.canAdmin(User)) || changeRole">

                  <select class="form-select" aria-label="Default select example" [(ngModel)]="rol_selected"
                    (change)="selectRole($event.target.value)">
                    <option value="CLASSIC" selected>Classic</option>
                    <option value="ADMIN">Admin</option>
                    <option value="FULL-ADMIN">Full Admin</option>
                    <option value="SUPPORT">Support</option>
                    <option value="COMMUNITY-MANAGE">Community Manage</option>
                  </select>
                </div>
              </div>
              <div class="text-center " style="display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: flex-start;" *ngIf="changeRole">
                <button class="ui red button btn-p" (click)="cancelChnage()">
                  Cancelar
                </button>
                <button class="ui green button" [disabled]="btndisabled" (click)="changeRol()">
                  Cambiar
                </button>
              </div>

            </div>



            <p class="text-muted" *ngIf="user.email">
              {{ user.email }}
            </p>

            <p class="text-muted" *ngIf="user.phoneNumber && isMyUser">
              {{ user.phoneNumber }}
            </p>
            <p class="text-muted">
              Registrado el {{ user.date.fecha }}
            </p>

            <h1 class="h6">Estatus</h1>
            <p class="text-muted" *ngIf="user.status == 'enabled'">
              Activo
            </p>
            <p class="text-muted" *ngIf="user.status == 'desabled' ||user.status == 'disabled' ">
              Inhabilitado
            </p>
            <p class="text-muted" *ngIf="user.status == 'undefined'">
              En espera
            </p>
            <div class="mt-2" *ngIf="auth.userFullAdmin(User) == true && user.uid !== User.uid">
              <div class="d-flex" *ngIf="user.status !== 'undefined' && user.role !== 'NO-ROLE' && !changeRole
        ">
                <button class="ui red button" (click)="desable()" *ngIf="user.status == 'enabled'">
                  Inhabilitar
                </button>
                <button class="ui green button" (click)="enable()" *ngIf="user.status == 'desabled'">
                  Habilitar
                </button>
              </div>
              <div class="d-flex" *ngIf="user.status == 'undefined' && user.role == 'NO-ROLE'">
                <button class="ui red button btn-p" (click)="reject()">
                  Rechazar
                </button>
                <button class="ui green button" [disabled]="btndisabled" (click)="acept()">
                  Habilitar
                </button>
              </div>
            </div>


            <!-- <p class="h5">{{ user.id }}</p>
            <div class="d-flex align-items-center">
              <p class="h5 mt-2 fw-bold" style="color: rgb(155, 137, 58);">{{ user.role }}</p>
              <div class="changeRole" (click)="changeRoles()"
                *ngIf="!(user.status == 'undefined' && user.role == 'NO-ROLE') && (User.uid !== id_user) && !changeRole && auth.userFullAdmin(User) == true">
                &nbsp; &nbsp;Cambiar
              </div>
            </div> -->

          </div>
        </div>
      </div>
      <div style="width: 67%;">
        <div class="card  shadow-lg p-3">
          <div class="subtitle mb-3">
            <p class="fs-2" *ngIf="auth.canAdmin(User) && isMyUser">Estadísticas de todos los usuarios</p>
            <p class="fs-2" *ngIf="auth.canAdmin(User) && !isMyUser">Estadísticas del usuario</p>
            <p class="fs-2" *ngIf="!auth.canAdmin(User) && isMyUser">Mis Estadísticas</p>
          </div>

          <div class="table-responsive">
            <div>
              <p class="fs-5">Clientes</p>
              <table class="table">
                <thead>
                  <tr>
                    <td>Total</td>
                    <td>Activos</td>
                    <td>Inactivos</td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="isMyUser && (User.role == 'FULL-ADMIN' || User.role == 'ADMIN' )">
                    <td>{{all_income}}</td>
                    <td>{{all_active_income }}</td>
                    <td>{{all_inactive_income}}</td>
                  </tr>
                  <tr *ngIf="isMyUser && User.role == 'CLASSIC' ">
                    <td>{{ totalIcome }}</td>
                    <td>{{ activeIncome }}</td>
                    <td>{{ inactiveIncome }}</td>
                  </tr>
                  <tr *ngIf="isMyUser == false && (User.role == 'FULL-ADMIN' || User.role == 'ADMIN' )">
                    <td>{{ totalIcome }}</td>
                    <td>{{ activeIncome }}</td>
                    <td>{{ inactiveIncome }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="mt-5">
              <p class="fs-5">Leads</p>
              <table class="table">
                <thead>
                  <tr>
                    <td>Total</td>
                    <td>Positivo</td>
                    <td>Negativo</td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="isMyUser && (User.role == 'FULL-ADMIN' || User.role == 'ADMIN' )">
                    <td>{{all_commodities}}</td>
                    <td>{{all_commodityPositive}}</td>
                    <td>{{all_commodityNegative}}</td>
                  </tr>
                  <tr *ngIf="isMyUser && User.role == 'CLASSIC'">
                    <td>{{ totalAllLeads }}</td>
                    <td>{{ totalLeadsPositive }}</td>
                    <td>{{ totalLeadsNegative }}</td>
                  </tr>
                  <tr *ngIf="isMyUser == false && (User.role == 'FULL-ADMIN' || User.role == 'ADMIN' )">
                    <td>{{ totalAllLeads }}</td>
                    <td>{{ totalLeadsPositive }}</td>
                    <td>{{ totalLeadsNegative }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>


    </div>

















    <!-- <div class=" main" *ngIf='user'>
        <div class="text-center fx mb-4 mt-3">
          <i class="large arrow left icon" (click)="back()" style="cursor:pointer;"></i>
          <h2 style="margin: 0;" *ngIf="!isMyUser">Perfil del usuario</h2>
          <h2 style="margin: 0;" *ngIf="isMyUser">Mi perfil</h2>
          <div></div>
        </div>
        <div class="user-profile">
          <div class="user-icon">
            <div class="circle" *ngIf="urlImage == null; else getImg"></div>
            <ng-template #getImg>
              <div class="circle-img" id="new_img_profile" *ngIf="urlImage">
                <img [src]="urlImage" alt="" />
              </div>
            </ng-template>
            <div class="text-center mt-2" *ngIf="id_user">
              <input type="file" id="img-user" style="display: none" (change)="onUpload($event)" />
              <div *ngIf="user.uid === User.uid">
                <label class="change-img" for="img-user" *ngIf="!set_change_img; else setChange">Cambiar imagen</label>
              </div>
              <ng-template #setChange>
                <label class="change-img" for="img-user">Seleccionar otra imagen</label>
              </ng-template>
              <div class="btn-options" *ngIf="set_change_img">
                <button class="ui button" (click)="cancel()">Cancelar</button>
                <button class="btn btn-primary" (click)="changeProfileImg()">
                  Actualizar imagen
                </button>
              </div>
            </div>
          </div>
          <div class="user-info">
            <div class="shadow-lg p-3 mb-5 card" style="padding: 10px;">
              <h4 class="mb-3 ">Representante Comercial</h4>
              <div class="title-info" *ngIf="user">
                <div class="info">
                  <span style="font-weight: bold">ID:&nbsp;</span> {{ user.id }}
                  <img class="clipboard-icon" src="./../../assets/icons/clipboards.png" alt="" ngxClipboard
                    [cbContent]="user.id" />
                </div>
                <div class="info">
                  <span style="font-weight: bold">Nombre:&nbsp;</span> {{ user.displayName }}
                </div>
                <div class="info">
                  <span style="font-weight: bold">Fecha de registro:&nbsp;</span>{{ user.date.fecha }}
                </div>
                <div class="info">
                  <div style="display: flex; justify-content: center; align-items: center;"
                    *ngIf="user.role !== 'NO-ROLE'">
                    <span style="font-weight: bold">Rol:&nbsp;</span> {{ user.role }}
                    <div class="changeRole" (click)="changeRoles()"
                      *ngIf="(User.uid !== id_user) && !changeRole && auth.userFullAdmin(User) == true">
                      &nbsp; &nbsp;Cambiar
                    </div>
                  </div>
                  <div style="display: flex; justify-content: center;" *ngIf="user.role == 'NO-ROLE'">
                    <span style="font-weight: bold">Rol:&nbsp;</span> Sin Rol Asignado
                  </div>
                </div>
                <div class="info" style="display: flex">
                  <span style="font-weight: bold">Estatus: &nbsp;</span>
                  <div *ngIf="user.status == 'enabled'">Activo</div>
                  <div *ngIf="user.status == 'undefined'">En espera</div>
                  <div *ngIf="user.status == 'rejected'">Rechazado</div>
                  <div *ngIf="user.status == 'desabled'">Inhabilitado</div>
                </div>
              </div>
            </div>




            <div class="box-info-t">
              <div class="buttons-options">
                <div style="display: flex; align-items: center; margin-bottom: 10px;" *ngIf="
            (user.role == 'NO-ROLE' &&
            (user.status == 'undefined' || user.status == 'rejected') &&
            auth.canAdmin(User)) || changeRole
              ">

                  <select class="form-select" aria-label="Default select example" [(ngModel)]="rol_selected"
                    (change)="selectRole($event.target.value)">
                    <option value="CLASSIC" selected>Classic</option>
                    <option value="ADMIN">Admin</option>
                    <option value="FULL-ADMIN">Full Admin</option>
                    <option value="SUPPORT">Support</option>
                    <option value="COMMUNITY-MANAGE">Community Manage</option>
                  </select>
                </div>





                <div *ngIf="auth.userFullAdmin(User) == true && user.uid !== User.uid">
                  <div class="text-center" *ngIf="user.status !== 'undefined' && user.role !== 'NO-ROLE' && !changeRole
            ">
                    <button class="ui red button" (click)="desable()" *ngIf="user.status == 'enabled'">
                      Inhabilitar
                    </button>
                    <button class="ui green button" (click)="enable()" *ngIf="user.status == 'desabled'">
                      Habilitar
                    </button>
                  </div>
                  <div class="text-center" *ngIf="user.status == 'undefined' && user.role == 'NO-ROLE'">
                    <button class="ui red button btn-p" (click)="reject()">
                      Rechazar
                    </button>
                    <button class="ui green button" [disabled]="btndisabled" (click)="acept()">
                      Habilitar
                    </button>
                  </div>

                  <div class="text-center " style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;" *ngIf="changeRole">
                    <button class="ui red button btn-p" (click)="cancelChnage()">
                      Cancelar
                    </button>
                    <button class="ui green button" [disabled]="btndisabled" (click)="changeRol()">
                      Cambiar
                    </button>
                  </div>


                  <div class="text-center" *ngIf="user.status == 'rejected' && user.role == 'NO-ROLE'">
                    <button class="ui green button" (click)="acept()">
                      Habilitar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="data mt-4">
          <div class="subtitle mb-3">
            <h5 *ngIf="auth.canAdmin(User) && isMyUser">Estadisticas de todos los usuarios</h5>
            <h5 *ngIf="auth.canAdmin(User) && !isMyUser">Estadisticas del usuario</h5>
            <h5 *ngIf="!auth.canAdmin(User) && isMyUser">Mis Estadisticas</h5>
          </div>

          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">
                    <h3>Clientes</h3>
                  </th>
                  <th scope="col">
                    <h3>Leads</h3>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <table class="table">
                      <thead>
                        <tr>
                          <td>Total</td>
                          <td>Activos</td>
                          <td>Inactivos</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngIf="isMyUser && (User.role == 'FULL-ADMIN' || User.role == 'ADMIN' )">
                          <td>{{all_income}}</td>
                          <td>{{all_active_income }}</td>
                          <td>{{all_inactive_income}}</td>
                        </tr>
                        <tr *ngIf="isMyUser && User.role == 'CLASSIC' ">
                          <td>{{ totalIcome }}</td>
                          <td>{{ activeIncome }}</td>
                          <td>{{ inactiveIncome }}</td>
                        </tr>
                        <tr *ngIf="isMyUser == false && (User.role == 'FULL-ADMIN' || User.role == 'ADMIN' )">
                          <td>{{ totalIcome }}</td>
                          <td>{{ activeIncome }}</td>
                          <td>{{ inactiveIncome }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table class="table">
                      <thead>
                        <tr>
                          <td>Total</td>
                          <td>Positivo</td>
                          <td>Negativo</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngIf="isMyUser && (User.role == 'FULL-ADMIN' || User.role == 'ADMIN' )">
                          <td>{{all_commodities}}</td>
                          <td>{{all_commodityPositive}}</td>
                          <td>{{all_commodityNegative}}</td>
                        </tr>
                        <tr *ngIf="isMyUser && User.role == 'CLASSIC'">
                          <td>{{ totalAllLeads }}</td>
                          <td>{{ totalLeadsPositive }}</td>
                          <td>{{ totalLeadsNegative }}</td>
                        </tr>
                        <tr *ngIf="isMyUser == false && (User.role == 'FULL-ADMIN' || User.role == 'ADMIN' )">
                          <td>{{ totalAllLeads }}</td>
                          <td>{{ totalLeadsPositive }}</td>
                          <td>{{ totalLeadsNegative }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> -->
  </div>

</div>