<div class="main" *ngIf=" customer && articles">
  <div style="display: flex; justify-content: end;">
    <button class="ui primary button mt-4 mb-4" *ngIf="isMine" (click)=" addArticle()">Agregar artículo</button>
  </div>

  <div *ngFor="let article of articles; let i = index">
    <div *ngIf="article.completado" style="display: flex;" class="shadow-lg p-3 mb-5">
      <div style="width: 25%;  ">
        <!-- image -->
        <div *ngIf="article.imagen_principal != ''" style="display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        ">

          <img class=" article-img" [src]="article.imagen_principal.url" alt="" width="100%" height="auto">
          <div class="text-center mt-3 mb-3" style="font-weight: bold;">
            {{article.articulo.calle}} / {{article.articulo.no_exterior}} <div
              *ngIf="article.articulo.no_interior != ''"> / {{article.articulo.no_interior}} </div>/
            {{article.articulo.colonia}} / {{article.articulo.ciudad}} / {{article.articulo.estado}}
            / {{article.articulo.pais}} / {{article.articulo.cp}}
          </div>
          <div class="text-center">
            <button *ngIf="article.isMine" class="ui primary button" style="width: 70%;"
              (click)="editArticle(article)">Editar información del
              artículo</button>
            <button class="ui primary button mt-2" style="width: 70%;" (click)="advanceArticle(article)">Ir a detalles
              del artículo</button>
          </div>
        </div>
        <div *ngIf="article.imagen_principal == ''"
          style="display: flex;align-items: center;justify-content: space-evenly;flex-direction: column;height: 40vh;">
          <h5 class="text-center">Sin fotografías</h5>
          <div class="text-center mt-3 mb-3" style="font-weight: bold;">
            {{article.articulo.calle}} / {{article.articulo.no_exterior}} <div
              *ngIf="article.articulo.no_interior != ''"> / {{article.articulo.no_interior}} </div>/
            {{article.articulo.colonia}} / {{article.articulo.ciudad}} / {{article.articulo.estado}}
            / {{article.articulo.pais}} / {{article.articulo.cp}}
          </div>

          <div class="text-center">
            <button class="ui primary button" style="width: 70%;" (click)="editArticle(article)">Editar información del
              artículo</button>
            <button class="ui primary button mt-2" style="width: 70%;" (click)="advanceArticle(article)">Ir a detalles
              del artículo</button>
          </div>
        </div>

      </div>
      <div style="width: 75%;">
        <div>
          <div class="text-center" style="    display: flex;
        flex-direction: row;
        justify-content: space-around;">
            <div>
              <div class="lead" style="font-weight: bold;">Nombre</div>
              <p>{{customer.cliente.nombre}}</p>
            </div>
            <div>
              <div class="lead" style="font-weight: bold;">Agente</div>
              <p>{{article.agent}}</p>
            </div>
            <div>
              <div class="lead" style="font-weight: bold;">Precio</div>
              <p>{{article.articulo.precio | currency}} {{article.articulo.currency}}
            </div>
            <div>
              <div class="lead" style="font-weight: bold;">ID de artículo</div>
              <p>{{article.id_articulo}}</p>
              <a (click)="fullviewArticle(article)">Ver publicación del artículo</a>
            </div>
          </div>
          <div style=" display: flex;align-items: center;" class="mb-4 mt-3">
            <div style="width: 55%;">
              <div>
                <div class="lead text-center" style="font-weight: bold;">Descripción</div>
                <div #textdescription style="text-align: center;
                  display: -webkit-box;
                  -webkit-line-clamp: 4;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  padding: 0 10px;">{{article.articulo.descripcion}}</div>
                <div #buttondescription style="color: rgb(0, 115, 209); cursor: pointer;
                justify-content: center; display: none;" (click)="showModalDescription(article.articulo.descripcion)">
                  Leer descripción completa</div>
                <div class="mt-4">
                  <div style="display: flex;
                  flex-direction: column;
                  align-items: center;">
                    <div class="lead text-center" style="font-weight: bold;">Tags seleccionados</div>
                    <a *ngIf="!editAllTags" (click)="editTags(article)">Actualizar</a>
                  </div>
                  <ul class="ks-cboxtags1" *ngIf="!editAllTags">
                    <li *ngFor="let caract of article.caracteristicas">
                      <label *ngIf="caract.seleccionado">
                        {{ caract.descripcion }}
                      </label>
                    </li>
                  </ul>


                  <div class="box" *ngIf="featuresForm && editAllTags">
                    <ul class="ks-cboxtags">

                      <li *ngFor="let caracteristica of features; let i = index">
                        <input (input)="selected(i)" id="{{ 'caracteritica-' + caracteristica.id }}"
                          [checked]="caracteristica.seleccionado" type="checkbox" />
                        <label for="{{ 'caracteritica-' + caracteristica.id }}">
                          {{ caracteristica.descripcion }}
                        </label>
                      </li>

                    </ul>
                    <button class="ui primary button mt-5" (click)="cancelUpdateTags()">Cancelar</button>
                    <button class="ui primary button mt-5" (click)="updateTags()">Continuar</button>

                  </div>










                </div>
              </div>
            </div>
            <div style="width: 45%;">
              <div style="display: flex;flex-direction: row;align-items: center;justify-content: center;">
                <div style="text-align: center;">
                  <h5 style="font-weight: bold;" *ngIf="article.tipo.tipo == 'compraventa'"> Article Sale</h5>
                  <h5 style="font-weight: bold;" *ngIf="article.tipo.tipo == 'arrendamiento'"> Article Lease</h5>

                  <h5 style="font-weight: bold;">progress</h5>
                  <div *ngIf="article.positive != null && article.positive != undefined && article.positive != {}">
                    <a *ngIf="article.positive.progress != null" (click)="progessDetail(article)">Ver detalles del
                      progreso</a>
                  </div>
                </div>
                <div>
                  <div *ngIf="article.positive != null && article.positive != undefined && article.positive != {}"
                    style="display: flex; justify-content: center; align-items: center; height: 100%;">
                    <div style="width: 100%">
                      <div style="display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  flex-wrap: nowrap;">

                        <div
                          style="  display: flex;flex-direction: row;align-items: center;justify-content: space-between;flex-wrap: nowrap;">

                          <div *ngIf="article.positive.progress != null"
                            [ngClass]="{circle_orange: article.positive.progress == 'DATA-COLLECT', circle_green:article.positive.progress == 'DATA-ANALYZING'||article.positive.progress =='DATA-VALIDATING' ||(article.positive.progress =='PLACING-SALE' || article.positive.progress =='PLACING-LEASE') ||(article.positive.progress =='SALE-PLACED'||article.positive.progress =='LEASE-PLACED')||(article.positive.progress =='SALE-PLACED-FINISH'||article.positive.progress =='LEASE-PLACED-FINISH') }">
                          </div>
                          <div *ngIf="article.positive.progress == null" class="circle_orange">
                          </div>

                          <div>Recolección de datos</div>

                        </div>

                        <div
                          style="  display: flex;flex-direction: row;align-items: center;justify-content: space-between;flex-wrap: nowrap;">

                          <div *ngIf="article.positive.progress  != null"
                            [ngClass]="{circle_black:article.positive.progress == 'DATA-COLLECT', circle_orange:  article.positive.progress == 'DATA-ANALYZING', circle_green:article. positive.progress =='DATA-VALIDATING' ||(article.positive.progress =='PLACING-SALE' || article.positive.progress =='PLACING-LEASE') ||(article.positive.progress =='SALE-PLACED'||article.positive.progress =='LEASE-PLACED')||(article.positive.progress =='SALE-PLACED-FINISH'||article.positive.progress =='LEASE-PLACED-FINISH') }">
                          </div>
                          <div *ngIf="article.positive.progress  == null" class="circle_black">
                          </div>
                          <!-- <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                        <div class="card-body">
                        </div>
                      </div> -->
                          <div>Análisis de datos</div>

                        </div>
                        <div
                          style="  display: flex;flex-direction: row;align-items: center;justify-content: space-between;flex-wrap: nowrap;">
                          <!-- crear un circulo -->
                          <div *ngIf="article.positive.progress  != null"
                            [ngClass]="{circle_black:article.positive.progress == 'DATA-COLLECT' || article.positive.progress == 'DATA-ANALYZING', circle_orange: article. positive.progress =='DATA-VALIDATING'  , circle_green:(article.positive.progress =='PLACING-SALE' || article.positive.progress =='PLACING-LEASE') ||(article.positive.progress =='SALE-PLACED'||article.positive.progress =='LEASE-PLACED')||(article.positive.progress =='SALE-PLACED-FINISH'||article.positive.progress =='LEASE-PLACED-FINISH') }">
                          </div>
                          <div *ngIf="article.positive.progress  == null" class="circle_black">
                          </div>
                          <!-- <div class="card" style="   width: 100%; height: 0.5px; background-color: black;">
                        <div class="card-body">
                        </div>
                      </div> -->
                          <div>Validación de datos</div>
                        </div>
                        <div
                          style="  display: flex;flex-direction: row;align-items: center;justify-content: space-between;flex-wrap: nowrap;">
                          <!-- crear un circulo -->
                          <div *ngIf="article.positive.progress  != null"
                            [ngClass]="{circle_black:article.positive.progress == 'DATA-COLLECT' || article.positive.progress == 'DATA-ANALYZING' || article. positive.progress =='DATA-VALIDATING', circle_orange:  (article.positive.progress =='PLACING-SALE' || article.positive.progress =='PLACING-LEASE') , circle_green:(article.positive.progress =='SALE-PLACED'||article.positive.progress =='LEASE-PLACED')||(article.positive.progress =='SALE-PLACED-FINISH'||article.positive.progress =='LEASE-PLACED-FINISH') }">
                          </div>
                          <div *ngIf="article.positive.progress  == null" class="circle_black">
                          </div>
                          <!-- <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                        <div class="card-body">
                        </div>
                      </div> -->
                          <div *ngIf="article.tipo.tipo == 'compraventa'">Placing Sale</div>
                          <div *ngIf="article.tipo.tipo == 'arrendamiento'">Placing Lease</div>

                        </div>
                        <div
                          style="  display: flex;flex-direction: row;align-items: center;justify-content: space-between;flex-wrap: nowrap;">
                          <!-- crear un circulo -->
                          <div *ngIf="article.positive.progress  != null"
                            [ngClass]="{circle_orange: (article.positive.progress =='SALE-PLACED'||article.positive.progress =='LEASE-PLACED') ,circle_black:article.positive.progress == 'DATA-COLLECT' || article.positive.progress == 'DATA-ANALYZING' || article. positive.progress =='DATA-VALIDATING' || (article.positive.progress =='PLACING-SALE' || article.positive.progress =='PLACING-LEASE') , circle_green:(article.positive.progress =='SALE-PLACED-FINISH'||article.positive.progress =='LEASE-PLACED-FINISH')}">
                          </div>
                          <div *ngIf="article.positive.progress  == null" class="circle_black">
                          </div>
                          <!-- <div class="card" style="  width: 100%;  height: 0.5px; background-color: black;">
                        <div class="card-body">
                        </div>
                      </div> -->

                          <div *ngIf="article.tipo.tipo == 'compraventa'">Sale Placed</div>
                          <div *ngIf="article.tipo.tipo == 'arrendamiento'">Lease Placed</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="article.positive == null "
                    style="display: flex; justify-content: center; align-items: center; height: 100%;">
                    <div style="width: 100%">
                      <div style="display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                flex-wrap: nowrap;">

                        <div
                          style="  display: flex;flex-direction: row;align-items: center;justify-content: space-between;flex-wrap: nowrap;">

                          <div class="circle_black">

                          </div>


                          <div>Recolección de datos</div>

                        </div>

                        <div
                          style="  display: flex;flex-direction: row;align-items: center;justify-content: space-between;flex-wrap: nowrap;">

                          <div class="circle_black">

                          </div>
                          <div>Análisis de datos</div>

                        </div>
                        <div
                          style="  display: flex;flex-direction: row;align-items: center;justify-content: space-between;flex-wrap: nowrap;">
                          <!-- crear un circulo -->
                          <div class="circle_black">

                          </div>
                          <div>Validación de datos</div>

                        </div>
                        <div
                          style="  display: flex;flex-direction: row;align-items: center;justify-content: space-between;flex-wrap: nowrap;">
                          <div class="circle_black">

                          </div>
                          <div *ngIf="article.tipo.tipo == 'compraventa'">Placing Sale</div>
                          <div *ngIf="article.tipo.tipo == 'arrendamiento'">Placing Lease</div>

                        </div>
                        <div
                          style="  display: flex;flex-direction: row;align-items: center;justify-content: space-between;flex-wrap: nowrap;">
                          <div class="circle_black">

                          </div>


                          <div *ngIf="article.tipo.tipo == 'compraventa'">Sale Placed</div>
                          <div *ngIf="article.tipo.tipo == 'arrendamiento'">Lease Placed</div>
                        </div>
                      </div>
                    </div>
                  </div>























































                </div>
              </div>


            </div>

          </div>
          <div style="display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;">
            <button class="ui button" (click)="showArticleComments(article)">
              <p type="button" data-bs-toggle="modal" data-bs-target="#modalComments" data-bs-whatever="@getbootstrap">
                Ver Comentarios
              </p>
            </button>
          </div>
        </div>

      </div>


    </div>
    <div *ngIf="!article.completado " style="    display: flex;
    flex-direction: column;
    align-items: flex-start;" class="shadow-lg p-3 mb-5card">
      <div style="width: 50%; margin-left: 20px; ">
        <h3 style="font-weight: bold;" *ngIf="article.isMine">Completar artículo</h3>
        <h3 style="font-weight: bold;" *ngIf="!article.isMine">Artículo aún no completado</h3>




        <h5 *ngIf="article.commodity != null && article.commodity != undefined" class="card-title">
          {{article.commodity.producto | uppercase }} </h5>
        <h6>{{article.id_articulo}}</h6>

        <p class="card-text " *ngIf="article.commodity == null || article.commodity == undefined">
          -Falta elegir el producto</p>
        <p class="card-text " *ngIf="article.articulo == null || article.articulo == undefined">
          -Falta agregar la información del articulo (precio, ubicación, características)</p>



        <button *ngIf="article.isMine" style="display: flex; justify-content: end;" class="ui primary button"
          (click)="completeArticle(article)">
          Completar</button>
      </div>

    </div>
  </div>
  <div class="modal fade " id="modalComments" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog ">
      <div class="modal-content containerm">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Comentarios del artículo</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body " style="max-height: 50vh; overflow-y: scroll;">
          <div class=" mb-3" *ngFor="let comment of allArticleComments">
            <div class="id_user">
              <b>Usuario {{comment.comentario.id_user }}</b>
            </div>
            <div class="comment mt-2">
              <p> {{comment.comentario.comentario}}</p>
            </div>
            <div class="date mt-2">

              Comentado el&nbsp;
              <p>{{comment.comentario.fecha}}</p>&nbsp;
              alas&nbsp;
              <p>{{comment.comentario.hora}}</p>

            </div>
            <hr>
          </div>
        </div>
        <div class="fx mb-4 " style="padding: 10px ;">
          <input type="text" [(ngModel)]="articleComment" class="form-control">
          <div class="ui primary button mt-3" (click)="commentArticlePost()">
            comentar
          </div>
        </div>
        <div class="modal-footer ">
          <button type="button" class="ui button" data-bs-dismiss="modal">
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
  <ng-template #modaldescription>
    <div class="main">
      <modal-content>
        <div class="container-modal-data">
          {{articleDescription}}
        </div>
      </modal-content>
      <modal-footer>
        <div class="text-center">
          <button class="ui button mb-3 mt-3" (click)="closeModal()">
            Cerrar
          </button>
        </div>
      </modal-footer>
    </div>

  </ng-template>
  <ng-template #modalNoData>
    <div class="main">
      <div class="container-modal">
        <modal-content>
          <h5 class="lead">Por el momento, no hay datos para mostrar</h5>
        </modal-content>
        <modal-footer class="btn-options">
          <button class="ui button" (click)="closeModal()" style="margin-right: 5px">
            Cerrar
          </button>

        </modal-footer>
      </div>
    </div>
  </ng-template>