<div class="main">
    <div class=" d-flex justify-content-between">
        <h2 class="title mt-4">Key Access</h2>
        <div>
            <div class="card-new-keyaccess" routerLink="/article-listing/request/keyaccess"
                *ngIf="!auth.userFullAdmin(user)">
                Nuevo Key Access
            </div>
        </div>
    </div>
    <div class="subtitle mb-3 d-flex justify-content-between" *ngIf="!auth.userFullAdmin(user)">
        <h5 *ngIf="!auth.userFullAdmin(user)">Mis Solicitudes</h5>
        <div></div>

    </div>
    <div class="box-select-filter" *ngIf="!auth.userFullAdmin(user)">
        <div class="row mb">
            <div class="col">
                <div class="form-outline">
                    <select class="form-select" (change)="filterWithSelectu($event.target.value)">
                        <option [selected]="false">Selecciona una opción</option>
                        <option [selected]="valueNameu == 'nombre'" class="large" value="3">Nombre del visitante
                        </option>
                        <option [selected]="valueNameu == 'id_access' " value="2">Access ID</option>
                        <option [selected]="valueNameu== 'id_lead'" value="5">ID del lead</option>
                        <option [selected]="valueNameu == 'id_article'" value="4">ID del articulo</option>

                    </select>
                </div>
            </div>
            <div class="col">
                <div class="form-outline">
                    <input [disabled]="disabledInputu" (input)="getTextu($event.target.value)" class="form-control"
                        placeholder="ingrese el valor" name="filterPostu" [(ngModel)]="filterPostu"
                        [value]="text_inputu" />
                </div>
            </div>
        </div>
    </div>
    <div class="" *ngIf="!auth.userFullAdmin(user)">
        <div *ngIf="( myRequests | keyaccessfilter: filterPostu:valueNameu) as myKeyAccess" class="grid-incomes mt-3">
            <div *ngFor="let request of myKeyAccess| paginate: { itemsPerPage: 6, currentPage: m_p,  totalItems:myKeyAccess.length  }; let i = index"
                class="card card-income">
                <div *ngIf="request.status != 'incompleto'" (click)='keyAccessFullView(request)'>
                    <h3 class="fw-bold">{{request.nameVisitor | titlecase}}</h3>
                    <p>{{ request.id }}</p>
                    <p class="fw-bold" style="font-size: 1.3rem;">
                        {{request.status | titlecase}}
                    </p>
                    <div class="d-flex flex-row justify-content-around mt-4">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                            <th class="text-center fw-bold">Making Date</th>
                            <p>{{request.date | date:'dd-MM-yyyy hh:mm'}}</p>
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center">
                            <th class="text-center fw-bold">Ending Date</th>
                            <p *ngIf="!request.dateFinish">Por definir</p>
                            <p *ngIf="request.dateFinish">{{request.dateFinish | date:'dd-MM-yyyy hh:mm'}}</p>
                        </div>
                    </div>
                </div>
                <div *ngIf="request.status == 'incompleto'" (click)="completeRequest(request)">
                    <h3 class="fw-bold">{{request.nameVisitor | titlecase}}</h3>
                    <p>{{ request.id }}</p>
                    <p class="fw-bold" style="font-size: 1.3rem;">
                        No completado
                    </p>
                    <div class="d-flex flex-row justify-content-around mt-4">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                            <th class="text-center fw-bold">Making Date</th>
                            <p>{{request.date | date:'dd-MM-yyyy hh:mm'}}</p>
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center">
                            <th class="text-center fw-bold">Ending Date</th>
                            <p *ngIf="!request.dateFinish">Por definir</p>
                            <p *ngIf="request.dateFinish">{{request.dateFinish | date:'dd-MM-yyyy hh:mm'}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
    <div class="text-center mt-4 mb-5" *ngIf="myRequests !== undefined && myRequests.length >= 6">
        <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" (pageChange)="changePage($event)"
            class="float-right"></pagination-controls>
    </div>


    <div *ngIf="auth.canAdmin(user)" class="subtitle mb-3 mt-4 d-flex justify-content-between">
        <h5>Solicitudes de otros usuarios</h5>
        <div></div>

    </div>
    <div class="box-select-filter" *ngIf="auth.canAdmin(user)">
        <div class="row mb">
            <div class="col">
                <div class="form-outline">
                    <select class="form-select" (change)="filterWithSelect($event.target.value)">
                        <option [selected]="false">Selecciona una opción</option>
                        <option [selected]="valueName == 'nombre'" class="large" value="3">Nombre del visitante
                        </option>
                        <option [selected]="valueName == 'id_access' " value="2">Access ID</option>
                        <option [selected]="valueName== 'id_lead'" value="5">ID del lead</option>
                        <option [selected]="valueName == 'id_article'" value="4">ID del articulo</option>

                    </select>
                </div>
            </div>
            <div class="col">
                <div class="form-outline">
                    <input [disabled]="disabledInput" (input)="getText($event.target.value)" class="form-control"
                        placeholder="ingrese el valor" name="filterPost" [(ngModel)]="filterPost"
                        [value]="text_input" />
                </div>
            </div>
        </div>
    </div>

    <div class="" *ngIf="auth.canAdmin(user)">
        <div *ngIf="( requests | keyaccessfilter: filterPost:valueName) as keyAccessRequest" class="grid-incomes mt-3">

            <div *ngFor="let request of keyAccessRequest| paginate: { itemsPerPage: 6, currentPage: m_p,  totalItems:keyAccessRequest.length  }; let i = index"
                class="card card-income">
                <div *ngIf="request.status != 'incompleto'" (click)='keyAccessFullView(request)'>
                    <h3 class="fw-bold">{{request.nameVisitor | titlecase}}</h3>
                    <p>{{ request.id }}</p>
                    <p class="fw-bold" style="font-size: 1.3rem;">
                        {{request.status | titlecase}}
                    </p>
                    <div class="d-flex flex-row justify-content-around mt-4">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                            <th class="text-center fw-bold">Making Date</th>
                            <p>{{request.date | date:'dd-MM-yyyy hh:mm'}}</p>
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center">
                            <th class="text-center fw-bold">Ending Date</th>
                            <p *ngIf="!request.dateFinish">Por definir</p>
                            <p *ngIf="request.dateFinish">{{request.dateFinish | date:'dd-MM-yyyy hh:mm'}}</p>
                        </div>
                    </div>
                </div>
                <div *ngIf="request.status == 'incompleto'">
                    <h3 class="fw-bold">{{request.nameVisitor | titlecase}}</h3>
                    <p>{{ request.id }}</p>
                    <p class="fw-bold" style="font-size: 1.3rem;">
                        No completado
                    </p>
                    <div class="d-flex flex-row justify-content-around mt-4">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                            <th class="text-center fw-bold">Making Date</th>
                            <p>{{request.date | date:'dd-MM-yyyy hh:mm'}}</p>
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center">
                            <th class="text-center fw-bold">Ending Date</th>
                            <p *ngIf="!request.dateFinish">Por definir</p>
                            <p *ngIf="request.dateFinish">{{request.dateFinish | date:'dd-MM-yyyy hh:mm'}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <table class="table">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">Access ID</th>
                    <th scope="col">Estatus</th>
                    <th class="large" scope="col">Visitor</th>
                    <th scope="col">Lead ID</th>
                    <th class="large" scope="col">Making Date</th>
                    <th scope="col">Ending Date</th>
                    <th scope="col"></th>
                </tr>
            </thead>

            <tbody *ngIf="(requests | keyaccessfilter: filterPostu:valueNameu) as allRequest">
                <tr *ngIf="allRequest.length == 0">
                    <td colspan="8" class="text-center lead">No hay resultados</td>
                </tr>
                <tr
                    *ngFor="let request of allRequest | paginate: { itemsPerPage: 5, currentPage: m_p,  totalItems: allRequest.length }">

                    <th>{{ request.id }}</th>
                    <td>{{ request.status | titlecase}}</td>
                    <td class="large">{{request.nameVisitor | titlecase}}</td>
                    <td>{{request.id_lead}}</td>
                    <td class="large">{{request.date | date:'dd-MM-yyyy hh:mm'}}</td>
                    <td *ngIf="!request.dateFinish">Por definir</td>
                    <td class="large" *ngIf="request.dateFinish">{{request.dateFinish | date:'dd-MM-yyyy hh:mm'}}</td>
                    <td>
                        <div *ngIf="request.status == 'incompleto'" class="ui button primary">Incompleto</div>
                        <div *ngIf="request.status != 'incompleto'" class="ui button primary"
                            (click)="keyAccessFullView(request)">Ver</div>
                    </td>

                </tr>
            </tbody>
        </table> -->
    </div>
    <div class="text-center mt-4 mb-5" *ngIf="requests.length > 5 && auth.canAdmin(user)">
        <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" (pageChange)="changePageu($event)"
            class="float-right"></pagination-controls>
    </div>

</div>





<!-- MODALES -->
<div class="ui basic modal disable" style="width: 90%; margin-left: 5.15%; margin-top: 25vh;">
    <div class="ui icon header text-center">
        <i class="exclamation icon" *ngIf="modal1"></i>
        <p *ngIf="modal1">Deshabilitar Ingreso</p>
        <p *ngIf="modal2">¿Por qué deshabilitas el ingreso?</p>
    </div>
    <div class="content text-center">
        <p *ngIf="modal1">¿Estás seguro de deshabilitar este ingreso?</p>
        <form [formGroup]="argumentForm" (ngSubmit)="onArg()" *ngIf="modal2">
            <div class="row mb">
                <textarea *ngIf="modal2" formControlName="argument"
                    [ngClass]="{invalid:argumento.invalid && argumento.dirty}" class="form-control" name="argument"
                    rows="4"></textarea>
            </div>
        </form>
    </div>
    <div class="actions" style="display: flex; justify-content: center;" *ngIf="modal1">
        <div class="ui red basic cancel inverted button" (click)="hideModalDisableIcome()">
            <i class="remove icon"></i>
            No
        </div>
        <div class="ui green ok inverted button" (click)="showModalArgument()">
            <i class="checkmark icon"></i>
            Sí
        </div>
    </div>
    <div class="actions" style="display: flex; justify-content: center;" *ngIf="modal2">
        <div>

        </div>
        <div class="ui green ok inverted button" (click)='onArg()'>
            Enviar
        </div>
    </div>
</div>