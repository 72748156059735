import { Component, OnDestroy, OnInit, } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataDbService } from 'src/app/SERVICIOS/data-db.service';
import * as moment from 'moment';
import { UserOnlineService } from 'src/app/SERVICIOS/user-online.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.scss',],

})
export class ProductosComponent {
  public dealsForm: UntypedFormGroup;
  idC: string;
  idA: string; idAd: string;
  products = [
    {
      nombre: 'gold99',
      dias: 99
    },
    {
      nombre: 'diamond99',
      dias: 189
    },
    {
      nombre: 'elite99',
      dias: 369
    },
    {
      nombre: 'imperial99',
      dias: 449
    },
  ];

  constructor(private dbData: DataDbService, private afs: AngularFirestore, private router: Router, private route: ActivatedRoute, private userOnline: UserOnlineService) {
    this.idC = this.route.snapshot.paramMap.get('idCustomer')
    this.idA = this.route.snapshot.paramMap.get('idArticulo')
    this.idAd = this.route.snapshot.paramMap.get('advance')

    this.dealsForm = this.createFormGroup();
  }

  createFormGroup() {
    let fecha_actual = moment().format('DD-MM-YYYY');
    let hora_actual = moment().format('hh:mm:ss a');
    return new UntypedFormGroup({
      producto: new UntypedFormControl('', Validators.required),
      fecha: new UntypedFormControl(fecha_actual),
      hora: new UntypedFormControl(hora_actual),

    });
  }

  get producto() { return this.dealsForm.get('producto') }

  onSaveForm() {
    if (this.userOnline.userOnline()) {
      if (this.dealsForm.valid) {
        this.getExpirationDate()
        // this.dbData.saveProducto(this.dealsForm.value, this.idA);
        // this.router.navigate(['ingresa', this.idC, 'articulo', this.idA, 'articulos']);
        // this.dealsForm.reset();
      } else {
        alert('Elige una opción para continuar')
      }
    }
    else {
      alert("No tienes conexion a internet")
    }
  }



  fech(date, days) {
    date.setDate(date.getDate() + days);
    return date;
  }

  // async getDays(product) {
  //   let data = await this.afs.collection('PRODUCTOS', ref => ref.where('nombre', '==', product)).valueChanges().pipe(take(1)).toPromise().then((allProducts: any[]) => {
  //     return allProducts[0].dias
  //   })
  //   return data
  // }

  getExpirationDate() {
    let isAdvance = false
    if (this.idAd != null) {
      isAdvance = true
    }

    this.dbData.saveProducto(this.dealsForm.value, this.idA, isAdvance);
    if (isAdvance) {
      this.router.navigate(['historys', 'ingresa-history', this.idA, 'advance']);
    } else {
      this.router.navigate(['ingresa', this.idC, 'articulo', this.idA, 'articulos']);
    }
    this.dealsForm.reset();

  }

}




