<div class="main">
  <div class="mt-4 mb-5">
    <h2 *ngIf="!idLead" class="title">Crear lead</h2>
    <h2 *ngIf="idLead" class="title">Agregar AVAL</h2>
  </div>
  <div class="form-registra" *ngIf="!idLead">
    <form [formGroup]="leadForm" (ngSubmit)="SaveForm(false)">
      <div class="subtitle mb-3">
        <h5>Contacto principal</h5>
      </div>

      <div class="row mb">
        <div class="col">
          <div class="form-outline">
            <input
              type="text"
              class="form-control"
              [ngClass]="{ invalid: nombre.invalid && nombre.dirty }"
              formControlName="nombre"
              name="nombre"
              placeholder="Nombres"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input
              type="text"
              class="form-control"
              [ngClass]="{ invalid: apellido.invalid && apellido.dirty }"
              formControlName="apellido"
              name="apellido"
              placeholder="Apellidos"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input
              type="text"
              class="form-control"
              [ngClass]="{ invalid: telefono.invalid && telefono.dirty }"
              formControlName="telefono"
              name="telefono"
              placeholder="Teléfono"
            />
          </div>
        </div>

        <div class="col">
          <div class="form-outline">
            <input
              type="text"
              [ngClass]="{ invalid: correo.invalid && correo.dirty }"
              class="form-control"
              formControlName="correo"
              name="correo"
              placeholder="Correo Electrónico (opcional)"
            />
          </div>
        </div>
      </div>
      <div class="subtitle mb-3">
        <h5>Contacto adicional (opcional)</h5>
      </div>
      <div class="row mb">
        <div class="col">
          <div class="form-outline">
            <input
              type="text"
              class="form-control"
              [ngClass]="{
                invalid: adicional_nombre.invalid && adicional_nombre.dirty
              }"
              formControlName="adicional_nombre"
              name="adicional_nombre"
              placeholder="Nombres"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input
              type="text"
              class="form-control"
              [ngClass]="{
                invalid: adicional_apellido.invalid && adicional_apellido.dirty
              }"
              formControlName="adicional_apellido"
              name="adicional_apellido"
              placeholder="Apellidos"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input
              type="text"
              class="form-control"
              [ngClass]="{
                invalid: adicional_telefono.invalid && adicional_telefono.dirty
              }"
              formControlName="adicional_telefono"
              name="adicional_telefono"
              placeholder="Teléfono"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input
              type="text"
              class="form-control"
              [ngClass]="{
                invalid: adicional_correo.invalid && adicional_correo.dirty
              }"
              formControlName="adicional_correo"
              name="adicional_correo"
              placeholder="Correo Electrónico (opcional)"
            />
          </div>
        </div>
      </div>
      <div class="subtitle mb-3">
        <h5>Comentario (opcional)</h5>
      </div>
      <div class="row mb">
        <div class="col">
          <div class="form-outline">
            <textarea
              formControlName="comentario"
              name="comentario"
              class="form-control"
              [ngClass]="{ invalid: comentarios.invalid && comentarios.dirty }"
              rows="4"
            >
            </textarea>
            <span
              class="text-center"
              *ngIf="comentarios.invalid"
              style="color: #d10707"
              >*El máximo es de {{ lenghtComment }} carácteres.</span
            >
          </div>
        </div>
      </div>
      <div class="text-center mb-4">
        <button class="ui button" [disabled]="leadForm.invalid">
          Continuar
        </button>
      </div>

      <ng-template #showButton>
        <div class="text-center mb-4 mt-4">
          <button
            (click)="saveDataAL()"
            [disabled]="avalForm.invalid"
            class="ui button"
          >
            Ir a gestiona
          </button>
        </div>
      </ng-template>
    </form>
  </div>

  <div class="form-registra" *ngIf="idLead">
    <form [formGroup]="avalForm" (ngSubmit)="SaveForm(true)">
      <div class="subtitle mb-3">
        <h5>Datos del aval</h5>
      </div>

      <div class="row mb">
        <div class="col">
          <div class="form-outline">
            <input
              type="text"
              class="form-control"
              [ngClass]="{ invalid: nombre.invalid && nombre.dirty }"
              formControlName="nombre"
              name="nombre"
              placeholder="Nombres"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input
              type="text"
              class="form-control"
              [ngClass]="{ invalid: apellido.invalid && apellido.dirty }"
              formControlName="apellido"
              name="apellido"
              placeholder="Apellidos"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input
              type="text"
              class="form-control"
              [ngClass]="{ invalid: telefono.invalid && telefono.dirty }"
              formControlName="telefono"
              name="telefono"
              placeholder="Teléfono"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input
              type="text"
              [ngClass]="{
                invalid: identification.invalid && identification.dirty
              }"
              class="form-control"
              formControlName="identification"
              name="identification"
              placeholder="Identificación"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input
              type="text"
              [ngClass]="{ invalid: correo.invalid && correo.dirty }"
              class="form-control"
              formControlName="correo"
              name="correo"
              placeholder="Correo Electrónico (opcional)"
            />
          </div>
        </div>
      </div>
      <div class="subtitle mb-3">
        <h5>Dirección</h5>
      </div>
      <div class="row mb">
        <div class="col">
          <div class="form-outline">
            <input
              type="text"
              id="postal_code"
              class="form-control"
              [ngClass]="{ invalid: code.invalid && code.dirty }"
              formControlName="cp"
              name="cp"
              (input)="searchNh($event.target.value)"
              placeholder="Código Postal"
            />
          </div>
        </div>
        <div class="col" *ngIf="neighborhood && !showInputNeighborhood">
          <div class="form-outline">
            <select
              (change)="addNewNeighborhood($event.target.value)"
              formControlName="colonia"
              aria-placeholder="Colonia"
              class="form-select"
              name="colonia"
            >
              <option
                value="{{ col }}"
                *ngFor="let col of neighborhood; let i = index"
              >
                {{ col }}
              </option>
              <option value="Ingresar Colonia">Ingresar Colonia</option>
            </select>
          </div>
        </div>
        <div class="col" *ngIf="!neighborhood && !showInputNeighborhood">
          <div class="form-outline">
            <select
              (change)="addNewNeighborhood($event.target.value)"
              class="form-select"
              aria-label="Default select example"
              formControlName="colonia"
              aria-placeholder="Colonia"
              name="colonia"
            >
              <option value="Ingresar Colonia">Ingresar Colonia</option>
            </select>
          </div>
        </div>
        <div class="col" *ngIf="showInputNeighborhood">
          <div class="form-outline">
            <input
              type="text"
              class="form-control"
              [ngClass]="{ invalid: colonia.invalid && colonia.dirty }"
              formControlName="colonia"
              name="colonia"
              placeholder="Colonia"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input
              type="text"
              id="street"
              [ngClass]="{ invalid: calle.invalid && calle.dirty }"
              class="form-control"
              formControlName="calle"
              name="calle"
              placeholder="Calle"
            />
          </div>
        </div>
      </div>
      <div class="row mb">
        <div class="col">
          <div class="form-outline">
            <input
              type="text"
              id="no_exterior"
              [ngClass]="{ invalid: no_exterior.invalid && no_exterior.dirty }"
              class="form-control"
              formControlName="no_exterior"
              name="no_exterior"
              placeholder="Número Exterior"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input
              type="text"
              id="no_inside"
              class="form-control"
              formControlName="no_interior"
              name="no_interior"
              placeholder="Número Interior (opcional)"
            />
          </div>
        </div>

        <div class="col" *ngIf="city">
          <div class="form-outline">
            <input
              type="text"
              class="form-control"
              value="{{ city | titlecase }}"
              name="ciudad"
              disabled
              placeholder="Ciudad"
            />
          </div>
        </div>
        <div class="col" *ngIf="!city">
          <div class="form-outline">
            <input
              type="text"
              class="form-control"
              [(ngModel)]="inputCity"
              [ngModelOptions]="{ standalone: true }"
              name="inputCity"
              placeholder="Ciudad"
            />
          </div>
        </div>
      </div>
      <div class="row mb">
        <div class="col" *ngIf="!state">
          <div class="form-outline">
            <input
              type="text"
              class="form-control"
              [(ngModel)]="inputState"
              [ngModelOptions]="{ standalone: true }"
              name="inputState"
              placeholder="Estado"
            />
          </div>
        </div>
        <div class="col" *ngIf="state">
          <div class="form-outline">
            <input
              type="text"
              class="form-control"
              value="{{ state | titlecase }}"
              name="estado"
              disabled
              placeholder="Estado"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input
              type="text"
              class="form-control"
              value="México"
              name="pais"
              disabled
              placeholder="País"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-outline"></div>
        </div>
      </div>
      <div class="subtitle mb-3">
        <h5>Contacto Adicional (opcional)</h5>
      </div>
      <div class="row mb">
        <div class="col">
          <div class="form-outline">
            <input
              type="text"
              class="form-control"
              [ngClass]="{
                invalid: adicional_nombre.invalid && adicional_nombre.dirty
              }"
              formControlName="adicional_nombre"
              name="adicional_nombre"
              placeholder="Nombres"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input
              type="text"
              class="form-control"
              [ngClass]="{
                invalid: adicional_apellido.invalid && adicional_apellido.dirty
              }"
              formControlName="adicional_apellido"
              name="adicional_apellido"
              placeholder="Apellidos"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input
              type="text"
              class="form-control"
              [ngClass]="{
                invalid: adicional_telefono.invalid && adicional_telefono.dirty
              }"
              formControlName="adicional_telefono"
              name="adicional_telefono"
              placeholder="Teléfono"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input
              type="text"
              class="form-control"
              [ngClass]="{
                invalid: adicional_correo.invalid && adicional_correo.dirty
              }"
              formControlName="adicional_correo"
              name="adicional_correo"
              placeholder="Correo Electrónico (opcional)"
            />
          </div>
        </div>
      </div>
      <div class="subtitle mb-3">
        <h5>Comentario (opcional)</h5>
      </div>
      <div class="row mb">
        <div class="col">
          <div class="form-outline">
            <textarea
              formControlName="comentario"
              name="comentario"
              class="form-control"
              [ngClass]="{ invalid: comentarios.invalid && comentarios.dirty }"
              rows="4"
            >
            </textarea>
            <span
              class="text-center"
              *ngIf="comentarios.invalid"
              style="color: #d10707"
              >*El máximo es de {{ lenghtComment }} carácteres.</span
            >
          </div>
        </div>
      </div>
      <div class="text-center mb-4">
        <button class="ui button" [disabled]="avalForm.invalid">
          Continuar
        </button>
      </div>

      <ng-template #showButton>
        <div class="text-center mb-4 mt-4">
          <button
            (click)="saveDataAL(false)"
            [disabled]="avalForm.invalid"
            class="ui button"
          >
            Ir a gestiona
          </button>
        </div>
      </ng-template>
    </form>
  </div>

  <ng-template #myModal>
    <div class="main">
      <div class="container-modal">
        <modal-content>
          <h2 class="fs-bold" style="margin-bottom: 2rem;">{{ modalMsg }}</h2>
          <p class="lead" *ngIf="msgOption != ''">{{ msgOption }}</p>
        </modal-content>
        <modal-footer class="btn-options mt-4" *ngIf="msgOption == ''">
          <button class="ui button " (click)="closeModal()">
            De Acuerdo
          </button>
        </modal-footer>
        <modal-footer class="btn-options mt-4" *ngIf="msgOption != ''">
          <button class="ui button " (click)="closeModal()">
            Regresar
          </button>
          <button class="ui primary button " (click)="addArticleToLead()">
            Agregar artículo
          </button>
        </modal-footer>
      </div>
    </div>
  </ng-template>
</div>
