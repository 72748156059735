import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/SERVICIOS/auth.service';
import { DataDbService } from 'src/app/SERVICIOS/data-db.service';
import { GetTokensService } from 'src/app/SERVICIOS/get-tokens.service';
import { SocketService } from 'src/app/SERVICIOS/socket/socket.service';
import { User } from '../../MODULOS/user.interface';
declare let $: any;
@Component({
  selector: 'app-ingresa-history',
  templateUrl: './ingresa-history.component.html',
  styleUrls: ['./ingresa-history.component.scss'],
})
export class IngresaHistoryComponent implements OnInit, OnDestroy {
  public ingForm: UntypedFormGroup;
  p: number = 1;

  public m_p: number = 1;
  customer = [];
  my_customer = [];
  public user: User;
  uid_user: string;
  c = 0;
  denied: boolean = false;
  public filterPost = '';
  public filterPostu = '';
  valueName: string;
  valueNameu: string;
  selected: number = 0;
  disabledInput: boolean = true;
  disabledInputu: boolean = true;
  incomeSelected: any;
  modal2: boolean = false;
  modal1: boolean = false;
  argumentForm = new UntypedFormGroup({
    argument: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(1000),
    ]),
  });
  subs_two: Subscription;
  subs_one: Subscription;
  text_input: string;
  text_inputu: string;
  p_long: number;
  totalArticles = [];
  allArticles = [];
  articlesLength = [];
  productsLength = [];
  showFilterCard: boolean = false;
  showFilteruCard: boolean = false;
  get argumento() {
    return this.argumentForm.get('argument');
  }

  constructor(
    public dbData: DataDbService,
    public afs: AngularFirestore,
    public auth: AuthService,
    private tokensUser: GetTokensService,
    private router: Router,
    private socket: SocketService
  ) {
    this.ingForm = this.createFormGroup();
    this.auth.user$
      .pipe(take(1))
      .toPromise()
      .then((user) => {
        this.user = user;
        this.uid_user = user.uid;
        this.afs
          .collection('ARTICLE')
          .valueChanges()
          .subscribe((articles) => {
            this.allArticles = articles;
          });
        this.afs
          .collection('CUSTOMER', (ref) =>
            ref.where('uid_user', '==', this.uid_user)
          )
          .valueChanges()
          .subscribe((income) => {
            this.my_customer = income;
            for (let i = 0; i <= income.length - 1; i++) {
              this.getTotalArticles(income[i]);
              this.getProductsArticles(income[i]);
            }
            // this.getTotalArticles(income)
          });
        if (this.auth.canAdmin(user)) {
          this.subs_two = this.afs
            .collection('CUSTOMER', (ref) =>
              ref.where('uid_user', '!=', this.uid_user)
            )
            .valueChanges()
            .subscribe((incomes) => {
              this.customer = incomes;
              this.p_long = this.customer.length;
              incomes.map((income) => {
                // this.getTotalArticles(income)
              });
              // if (this.customer && this.customer.length > 5) {
              //   let pag = Number(localStorage.getItem('pagination_customer'));
              //   if (pag == null || pag == 0) {
              //     localStorage.setItem('pagination_customer', '1');
              //     this.p = 1
              //   } else {
              //     this.p = pag
              //   }

              // }
            });
        }
      });
  }

  createFormGroup() {
    return new UntypedFormGroup({
      tipo: new UntypedFormControl('', Validators.required),
    });
  }

  showFilter() {
    this.showFilterCard = !this.showFilterCard;
  }
  showFilteru() {
    this.showFilteruCard = !this.showFilteruCard;
  }
  changePage(e) {
    this.p = e;
    localStorage.setItem('page', e);
  }
  changePageu(e) {
    this.m_p = e;
    localStorage.setItem('pageu', e);
  }
  resetPage() {
    this.p = 1;
    localStorage.setItem('page', '1');
  }
  resetPageu() {
    this.p = 1;
    localStorage.setItem('pageu', '1');
  }

  getText(e) {
    this.resetPage();
    localStorage.setItem('filterPost', e);
  }

  getTextu(e) {
    localStorage.setItem('component', 'ingresa');

    this.resetPageu();
    localStorage.setItem('filterPostu', e);
  }

  appliedProducts(todo) {
    return 0;
  }

  getTotalArticles(income) {
    if (this.allArticles.length > 0) {
      let newMap = this.allArticles.filter(
        (article) => article.id_document_customer == income.id_document
      );
      return newMap.length;
    }
    // let articlesLength = await this.afs.collection("ARTICLE", ref => ref.where("id_document_customer", "==", income.id_document)).valueChanges().pipe(take(1)).toPromise().then((articles) => {
    //   return articles.length;
    // })
    // // this.articlesLength.push(articlesLength.length)
    // return articlesLength;

    return 0;
  }

  getProductsArticles(income) {
    if (this.allArticles.length > 0) {
      let newList = this.allArticles.filter(
        (article) => article.completado == true
      );
      let newMap = newList.filter(
        (article) => article.id_document_customer == income.id_document
      );
      let count = 0;
      newMap.forEach((article: any) => {
        if (article.commodity.estatus == 'aprobado') count++;
      });

      return count;
    }

    return 0;
  }

  ngOnInit() {
    let comp = localStorage.getItem('component');
    if (comp == 'ingresa') {
      let valueName = localStorage.getItem('valueName');
      let filterPost = localStorage.getItem('filterPost');
      let disabledInput = localStorage.getItem('disabledInput');
      let page = localStorage.getItem('page');

      let valueNameu = localStorage.getItem('valueNameu');
      let filterPostu = localStorage.getItem('filterPostu');
      let disabledInputu = localStorage.getItem('disabledInputu');
      let pageu = localStorage.getItem('pageu');

      if (page !== null && page !== undefined && page !== '') {
        this.p = Number(page);
      } else {
        localStorage.setItem('page', '1');
      }
      if (pageu !== null && pageu !== undefined && pageu !== '') {
        this.m_p = Number(pageu);
      } else {
        localStorage.setItem('pageu', '1');
      }

      if (valueName !== null && valueName !== undefined && valueName !== '')
        this.valueName = valueName;
      if (valueNameu !== null && valueNameu !== undefined && valueNameu !== '')
        this.valueNameu = valueNameu;

      if (
        filterPost !== null &&
        filterPost !== undefined &&
        filterPost !== ''
      ) {
        this.filterPost = filterPost;
        this.text_input = filterPost;
      }
      if (
        filterPostu !== null &&
        filterPostu !== undefined &&
        filterPostu !== ''
      ) {
        this.filterPostu = filterPostu;
        this.text_inputu = filterPostu;
      }
      if (
        disabledInput !== null &&
        disabledInput !== undefined &&
        disabledInput !== ''
      ) {
        if (disabledInput == 'true') {
          this.disabledInput = true;
        } else if (disabledInput == 'false') {
          this.disabledInput = false;
        }
      }
      if (
        disabledInputu !== null &&
        disabledInputu !== undefined &&
        disabledInputu !== ''
      ) {
        if (disabledInputu == 'true') {
          this.disabledInputu = true;
        } else if (disabledInput == 'false') {
          this.disabledInputu = false;
        }
      }
    }

    // if (value !== null) {
    //   this.valueName = value
    // }
    // if(text !== null && text !== ''){
    //   this.text_input = text
    // }
  }

  fullView(todo) {
    this.router.navigate([
      'historys',
      'ingresa-history',
      todo.id_document,
      'fullview',
    ]);
  }

  completeIngresa(data: any) {
    if (data.identificacion == undefined)
      this.router.navigate(['ingresa', data.id_document, 'subir-foto']);
    else if (data.urlImage == undefined)
      this.router.navigate(['ingresa', data.id_document, 'subir-foto']);
  }

  onArg() {
    this.modal2 = false;
    this.modal1 = false;
    let incoming = this.incomeSelected;
    let id_document = incoming.id_document;
    let argumento = this.argumentForm.value.argument;
    this.afs
      .collection('CUSTOMER')
      .doc(id_document)
      .update({ argumento, aprobado: true, estatus: 'deshabilitado' })
      .then(async (e) => {
       
        var tokens = await this.tokensUser.getTokens(incoming.uid_user);
        let notification = {
          uid_user: incoming.uid_user,
          id_document,
          tokens,
        };
        this.socket.io.emit('in-disable', notification);
        this.argumentForm.reset();
        this.incomeSelected = [];
        this.hideModalDisableIcome();
      });
  }

  disable(incoming: any) {
    this.incomeSelected = incoming;
    this.showModalDisableIcome();

    // let id_document = incoming.id_document
    // this.afs.collection('CUSTOMER').doc(id_document).update({ aprobado: true, estatus: "deshabilitado" }).then(e => {
    //   this.socket.io.emit("in-disable", incoming);
    // })
    // this.disapproveArticles(incoming);
  }

  showModalArgument() {
    // let incoming = this.incomeSelected
    // let id_document = incoming.id_document
    // this.afs.collection('CUSTOMER').doc(id_document).update({ aprobado: true, estatus: "deshabilitado" }).then(e => {
    //   this.socket.io.emit("in-disable", incoming);
    // })
    // this.disapproveArticles(incoming);
    this.modal2 = true;
    this.modal1 = false;
  }

  // getArticles(incoming: any) {
  //   let id_document_customer = incoming.id_document;
  //   let id_cliente = incoming.id_cliente;
  //   this.afs
  //     .collection('ARTICLE', (ref) => ref.where('id_cliente', '==', id_cliente))
  //     .valueChanges()
  //     .pipe(take(1))
  //     .toPromise()
  //     .then((results: any[]) => {
  //       for (let i = 0; i <= results.length - 1; i++) {
  //         let id_document = results[i].id_document;

  //         if (results[i].estatus == 'habilitado') {
  //           let articulosInactivos = firebase.firestore.FieldValue.increment(1);
  //           let articulosActivos = firebase.firestore.FieldValue.increment(-1);
  //           this.afs
  //             .collection('ARTICLE')
  //             .doc(id_document)
  //             .update({ estatus: 'inhabilitado' })
  //             .then(() => {
  //               this.afs
  //                 .collection('CUSTOMER')
  //                 .doc(id_document_customer)
  //                 .update({
  //                   articulosInactivos,
  //                   articulosActivos,
  //                 });
  //             });
  //         }
  //       }
  //     });
  // }

  enable(incoming: any) {
    let id_document = incoming.id_document;
    this.afs
      .collection('CUSTOMER')
      .doc(id_document)
      .update({ aprobado: true, estatus: 'habilitado' })
      .then(async (e) => {
        var tokens = await this.tokensUser.getTokens(incoming.uid_user);

        let notification = {
          id_document,
          uid_user: incoming.uid_user,
          tokens,
        };
        this.socket.io.emit('in-enable', notification);
      });
    this.approveArticles(incoming);
  }

  approveArticles(incoming: any) {
    let id_document_customer = incoming.id_document;
    let id_cliente = incoming.id_cliente;
    this.afs
      .collection('ARTICLE', (ref) => ref.where('id_cliente', '==', id_cliente))
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((results: any[]) => {
        for (let i = 0; i <= results.length - 1; i++) {
          let id_document = results[i].id_document;
          if (results[i].estatus == 'inhabilitado') {
            let articulosActivos = firebase.firestore.FieldValue.increment(1);
            let articulosInactivos =
              firebase.firestore.FieldValue.increment(-1);
            this.afs
              .collection('ARTICLE')
              .doc(id_document)
              .update({ estatus: 'habilitado' })
              .then(() => {
                this.afs
                  .collection('CUSTOMER')
                  .doc(id_document_customer)
                  .update({
                    articulosInactivos,
                    articulosActivos,
                  });
              });
          }
        }
      });
  }

  filterWithSelectu(e: String) {
    localStorage.setItem('component', 'ingresa');

    if (e == '1') {
      this.valueNameu = 'id';
      this.disabledInputu = false;
      localStorage.setItem('disabledInputu', 'false');
      localStorage.setItem('valueNameu', this.valueNameu);
    } else if (e == '2') {
      this.valueNameu = 'id_cliente';
      this.disabledInputu = false;
      localStorage.setItem('disabledInputu', 'false');
      localStorage.setItem('valueNameu', this.valueNameu);
    } else if (e == '3') {
      this.valueNameu = 'nombre';
      this.disabledInputu = false;
      localStorage.setItem('disabledInputu', 'false');
      localStorage.setItem('valueNameu', this.valueNameu);
    } else if (e == '4') {
      this.valueNameu = 'uid_user';
      this.disabledInputu = false;
      localStorage.setItem('disabledInputu', 'false');
      localStorage.setItem('valueNameu', this.valueNameu);
    } else if (e == '5') {
      this.valueNameu = 'id_article';
      this.disabledInputu = false;
      localStorage.setItem('disabledInputu', 'false');
      localStorage.setItem('valueNameu', this.valueNameu);
    } else if (e == '6') {
      this.valueNameu = 'habilitado';
      this.disabledInputu = true;
      localStorage.setItem('disabledInputu', 'true');
      localStorage.setItem('valueNameu', this.valueNameu);
    } else if (e == '7') {
      this.valueNameu = 'deshabilitado';
      this.disabledInputu = true;
      localStorage.setItem('disabledInputu', 'true');
      localStorage.setItem('valueNameu', this.valueNameu);
      // localStorage.setItem('valueName', this.valueName);
    }
  }

  filterWithSelect(e) {
    localStorage.setItem('component', 'ingresa');

    if (e == '1') {
      this.valueName = 'id';

      this.disabledInput = false;
      localStorage.setItem('disabledInput', 'false');
      localStorage.setItem('valueName', this.valueName);
    } else if (e == '2') {
      this.valueName = 'id_cliente';
      this.disabledInput = false;
      localStorage.setItem('disabledInput', 'false');
      localStorage.setItem('valueName', this.valueName);
    } else if (e == '3') {
      this.valueName = 'nombre';
      this.disabledInput = false;
      localStorage.setItem('disabledInput', 'false');
      localStorage.setItem('valueName', this.valueName);
    } else if (e == '4') {
      this.valueName = 'uid_user';
      this.disabledInput = false;
      localStorage.setItem('disabledInput', 'false');
      localStorage.setItem('valueName', this.valueName);
    } else if (e == '5') {
      this.valueName = 'id_article';
      this.disabledInput = false;
      localStorage.setItem('disabledInput', 'false');
      localStorage.setItem('valueName', this.valueName);
    } else if (e == '6') {
      this.valueName = 'habilitado';
      this.disabledInput = true;
      localStorage.setItem('disabledInput', 'true');
      localStorage.setItem('valueName', this.valueName);
    } else if (e == '7') {
      this.valueName = 'deshabilitado';
      this.disabledInput = true;
      localStorage.setItem('disabledInput', 'true');
      localStorage.setItem('valueName', this.valueName);
      // localStorage.setItem('valueName', this.valueName);
    }
  }

  showModalDisableIcome() {
    this.modal1 = true;
    $('.ui.basic.modal.disable').modal('show');
  }

  hideModalDisableIcome() {
    this.modal1 = false;
    this.modal2 = false;
    $('.ui.basic.modal.disable').modal('hide');
  }

  cancel(): boolean {
    return false;
  }
  accept(): boolean {
    return true;
  }

  ngOnDestroy() {
    if (this.subs_one !== undefined) {
      this.subs_one.unsubscribe();
    }
    if (this.subs_two !== undefined) {
      this.subs_two.unsubscribe();
    }
  }
}
