import { Component, ViewChild } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { take } from "rxjs/operators";
import { DataDbService } from "src/app/SERVICIOS/data-db.service";
import { GenerateIdService } from "src/app/SERVICIOS/generate-id.service";
import { Location } from "@angular/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { User } from "../../MODULOS/user.interface";
import { MapService } from "src/app/SERVICIOS/map.service";
import { AuthService } from "src/app/SERVICIOS/auth.service";
import firebase from "firebase/compat/app";

@Component({
  selector: "app-registra-cliente",
  templateUrl: "./registra-cliente.component.html",
  styleUrls: ["./registra-cliente.component.scss"],
})
export class RegistraClienteComponent {
  @ViewChild("myModal")
  myModal;
  modalRef;
  public leadForm: UntypedFormGroup;
  public avalForm: UntypedFormGroup;
  public comentario: string;
  idKA: string;
  public leadCreado: any;
  idART: string;
  fecha_actual: string;
  hora_actual: string;
  art_listing: boolean = false;
  idAL: string;
  id: number;
  lenghtComment = 5000;
  isKeyAccess: boolean = false;
  index: string;
  idLead: string;
  country: string;
  state: string;
  city: string;
  neighborhood: any;
  showInputNeighborhood: boolean = false;
  cp: any;
  user: User;
  inputCity = "";
  inputState = "";
  idClient: string;
  modalMsg = "El lead ya ha sido ingresado";
  msgOption = "";
  leadFound: any;
  constructor(
    private dbData: DataDbService,
    private route: ActivatedRoute,
    private generateId: GenerateIdService,
    private afs: AngularFirestore,
    private _location: Location,
    private mapSvc: MapService,
    public modal: NgbModal,
    public router: Router,
    public auth: AuthService,
  ) {
    this.auth.user$.subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });
    this.idAL = this.route.snapshot.paramMap.get("idA");
    this.idKA = this.route.snapshot.paramMap.get("idKA");
    this.idART = this.route.snapshot.paramMap.get("idArticle");
    this.index = this.route.snapshot.paramMap.get("index");
    this.idLead = this.route.snapshot.paramMap.get("idLead");
    this.idClient = this.route.snapshot.paramMap.get("idClient");

    if (this.idKA) this.isKeyAccess = true;
    if (this.idAL) this.art_listing = true;

    (this.fecha_actual = moment().format("DD-MM-YYYY")),
      (this.hora_actual = moment().format("hh:mm:ss a")),
      (this.leadForm = this.createFormGroup());
    this.avalForm = this.createClientFormGroup();
  }

  get code() {
    return this.avalForm.get("cp");
  }
  get colonia() {
    return this.avalForm.get("colonia");
  }
  get calle() {
    return this.avalForm.get("calle");
  }
  get no_exterior() {
    return this.avalForm.get("no_exterior");
  }

  addNewNeighborhood(e) {
    if (e == "Ingresar Colonia") {
      this.showInputNeighborhood = true;
    } else {
      this.showInputNeighborhood = false;
    }
  }
  showInput() {
    this.showInputNeighborhood = true;
  }
  createFormGroup() {
    let emailPattern: any =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return new UntypedFormGroup({
      nombre: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
      ]),
      apellido: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
      ]),
      telefono: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(10),
      ]),
      correo: new UntypedFormControl("", [Validators.pattern(emailPattern)]),

      adicional_nombre: new UntypedFormControl("", [Validators.minLength(2)]),
      adicional_apellido: new UntypedFormControl("", [Validators.minLength(2)]),
      adicional_telefono: new UntypedFormControl("", [Validators.minLength(6)]),
      adicional_correo: new UntypedFormControl("", [
        Validators.pattern(emailPattern),
      ]),

      comentario: new UntypedFormControl("", [
        Validators.maxLength(this.lenghtComment),
      ]),
      fecha: new UntypedFormControl(this.fecha_actual),
      hora: new UntypedFormControl(this.hora_actual),
    });
  }
  createClientFormGroup() {
    let emailPattern: any =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return new UntypedFormGroup({
      nombre: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
      ]),
      apellido: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
      ]),
      telefono: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(10),
      ]),
      correo: new UntypedFormControl("", [Validators.pattern(emailPattern)]),
      cp: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(5),
      ]),
      colonia: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(3),
      ]),
      calle: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(6),
      ]),
      no_exterior: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(1),
      ]),
      identification: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(10),
      ]),
      no_interior: new UntypedFormControl(""),
      pais: new UntypedFormControl("México"),
      adicional_nombre: new UntypedFormControl("", [Validators.minLength(2)]),
      adicional_apellido: new UntypedFormControl("", [Validators.minLength(2)]),
      adicional_telefono: new UntypedFormControl("", [Validators.minLength(6)]),
      adicional_correo: new UntypedFormControl("", [
        Validators.pattern(emailPattern),
      ]),

      comentario: new UntypedFormControl("", [
        Validators.maxLength(this.lenghtComment),
      ]),
      fecha: new UntypedFormControl(this.fecha_actual),
      hora: new UntypedFormControl(this.hora_actual),
    });
  }

  get nombre() {
    return this.avalForm.get("nombre");
  }
  get apellido() {
    return this.avalForm.get("apellido");
  }
  get correo() {
    return this.avalForm.get("correo");
  }
  get telefono() {
    return this.avalForm.get("telefono");
  }
  get adicional_nombre() {
    return this.avalForm.get("adicional_nombre");
  }
  get adicional_apellido() {
    return this.avalForm.get("adicional_apellido");
  }
  get adicional_telefono() {
    return this.avalForm.get("adicional_telefono");
  }
  get adicional_correo() {
    return this.avalForm.get("adicional_correo");
  }
  get comentarios() {
    return this.avalForm.get("comentario");
  }
  get identification() {
    return this.avalForm.get("identification");
  }

  searchNh(e: string) {
    if (e.length == 5) {
      this.getDataApi(e);
    }
  }

  getDataApi(e) {
    this.city = "";
    this.state = "";
    if (e !== this.cp) {
      this.cp = e;
      const { cp } = this.avalForm.value;
      if (cp) {
        this.showInputNeighborhood = false;

        this.mapSvc
          .getapi(cp)
          .pipe(take(1))
          .toPromise()
          .then((res: any) => {
            if (res) {
              this.neighborhood = res.colonias;

              let newCity = String(res.municipio).split(" ");
              for (let i = 0; i <= newCity.length - 1; i++) {
                if (newCity.length == 1) {
                  this.city = newCity[i].charAt(0) +
                    newCity[i].slice(1).toLowerCase();
                } else if (newCity.length > 1) {
                  this.city += newCity[i].charAt(0) +
                    newCity[i].slice(1).toLowerCase() +
                    " ";
                }
              }
              let newState = String(res.estado).split(" ");
              for (let i = 0; i <= newState.length - 1; i++) {
                if (newState.length == 1) {
                  this.state = newState[i].charAt(0) +
                    newState[i].slice(1).toLowerCase();
                } else if (newState.length > 1) {
                  this.state += newState[i].charAt(0) +
                    newState[i].slice(1).toLowerCase() +
                    " ";
                }
              }

              this.avalForm.addControl(
                "ciudad",
                new UntypedFormControl(this.city),
              );
              this.avalForm.addControl(
                "estado",
                new UntypedFormControl(this.state),
              );
            }
          });
      }
    }
  }

  SaveForm(isAval: boolean) {
    // let selectedValuesToSearch = []
    // selectedValuesToSearch = this.caracteristicas.value.filter(c => c.seleccionado === true);
    if (isAval) {
      const {
        adicional_nombre,
        adicional_apellido,
        adicional_correo,
        adicional_telefono,
      } = this.avalForm.value;
      if (
        adicional_nombre !== "" &&
        adicional_apellido == "" &&
        adicional_correo == "" &&
        adicional_telefono == ""
      ) {
        alert(
          "Debe llenar todos los campos del contacto adicional o dejarlos todos vacios",
        );
      } else if (
        adicional_nombre == "" &&
        adicional_apellido !== "" &&
        adicional_correo == "" &&
        adicional_telefono == ""
      ) {
        alert(
          "Debe llenar todos los campos del contacto adicional o dejarlos todos vacios",
        );
      } else if (
        adicional_nombre == "" &&
        adicional_apellido == "" &&
        adicional_correo !== "" &&
        adicional_telefono == ""
      ) {
        alert(
          "Debe llenar todos los campos del contacto adicional o dejarlos todos vacios",
        );
      } else if (
        adicional_nombre == "" &&
        adicional_apellido == "" &&
        adicional_correo == "" &&
        adicional_telefono !== ""
      ) {
        alert(
          "Debe llenar todos los campos del contacto adicional o dejarlos todos vacios",
        );
      } else {
        // if (selectedValuesToSearch.length == 0) alert('Debe seleccionar al menos una caracteristica')
        this.checkCustomer(isAval);
      }
    } else {
      const {
        adicional_nombre,
        adicional_apellido,
        adicional_correo,
        adicional_telefono,
      } = this.leadForm.value;
      if (
        adicional_nombre !== "" &&
        adicional_apellido == "" &&
        adicional_correo == "" &&
        adicional_telefono == ""
      ) {
        alert(
          "Debe llenar todos los campos del contacto adicional o dejarlos todos vacios",
        );
      } else if (
        adicional_nombre == "" &&
        adicional_apellido !== "" &&
        adicional_correo == "" &&
        adicional_telefono == ""
      ) {
        alert(
          "Debe llenar todos los campos del contacto adicional o dejarlos todos vacios",
        );
      } else if (
        adicional_nombre == "" &&
        adicional_apellido == "" &&
        adicional_correo !== "" &&
        adicional_telefono == ""
      ) {
        alert(
          "Debe llenar todos los campos del contacto adicional o dejarlos todos vacios",
        );
      } else if (
        adicional_nombre == "" &&
        adicional_apellido == "" &&
        adicional_correo == "" &&
        adicional_telefono !== ""
      ) {
        alert(
          "Debe llenar todos los campos del contacto adicional o dejarlos todos vacios",
        );
      } else {
        // if (selectedValuesToSearch.length == 0) alert('Debe seleccionar al menos una caracteristica')
        this.checkCustomer(isAval);
      }
    }
  }
  openModal() {
    this.modalRef = this.modal.open(this.myModal, {
      size: "lg",
      animation: true,
      centered: true,
      backdrop: true,
    });
  }
  closeModal() {
    this.modalRef = this.modal.dismissAll();
  }
  checkCustomer(isAval: boolean) {
    // Buscar si el cliente ya existe por telefono
    if (isAval) {
      let telefono = this.avalForm.value.telefono;
      let newTelefono = telefono.split(/\D/).join("");

      this.afs
        .collection(
          "LEAD",
          (ref) => ref.where("cliente.telefono", "==", newTelefono).limit(1),
        )
        .valueChanges()
        .pipe(take(1))
        .toPromise()
        .then((res: any[]) => {
          if (res.length > 0) {
            this.openModal();
          } else {
            // Buscar si el cliente ya existe por nombre y apellido
            let nombre = String(this.avalForm.value.nombre).toLowerCase();
            let apellido = String(this.avalForm.value.apellido).toLowerCase();
            this.afs
              .collection("LEAD", (ref) =>
                ref
                  .where("cliente.nombre", "==", nombre)
                  .where("cliente.apellido", "==", apellido)
                  .limit(1))
              .valueChanges()
              .pipe(take(1))
              .toPromise()
              .then((res: any[]) => {
                if (res.length > 0) {
                  this.openModal();
                } else {
                }
              });
          }
        });
    } else {
      let telefono = this.leadForm.value.telefono;
      let newTelefono = telefono.split(/\D/).join("");

      this.afs
        .collection(
          "LEAD",
          (ref) => ref.where("cliente.telefono", "==", newTelefono).limit(1),
        )
        .valueChanges()
        .pipe(take(1))
        .toPromise()
        .then((res: any[]) => {
          if (res.length > 0) {
            //se encontro el lead
            //saber si es de el
            this.leadFound = res[0];

            if (
              this.leadFound.uid_user == this.user.uid
            ) {
              this.modalMsg = "Ya agregaste el lead anteriormente";
              if (
                this.idAL &&
                !this.leadFound.hasOwnProperty("escalate")
              ) {
                this.msgOption = "¿Quieres agregar el artículo a este lead?";
              }
            } else {
              this.modalMsg = "El lead ya ha sido ingresado por otro usuario";
            }

            this.openModal();
          } else {
            // Buscar si el cliente ya existe por nombre y apellido
            let nombre = String(this.leadForm.value.nombre).toLowerCase();
            let apellido = String(this.leadForm.value.apellido).toLowerCase();
            this.afs
              .collection("LEAD", (ref) =>
                ref
                  .where("cliente.nombre", "==", nombre)
                  .where("cliente.apellido", "==", apellido)
                  .limit(1))
              .valueChanges()
              .pipe(take(1))
              .toPromise()
              .then((res: any[]) => {
                if (res.length > 0) {
                  this.openModal();
                } else {
                  if (isAval) {
                    this.saveAval(newTelefono, nombre, apellido);
                  } else {
                    if (this.isKeyAccess) {
                      this.saveLeadKA(newTelefono, nombre, apellido);
                    } else if (this.art_listing) {
                      this.saveCustomerAL(newTelefono, nombre, apellido);
                    } else {
                      this.saveCustomer(newTelefono, nombre, apellido);
                    }
                  }
                }
              });
          }
        });
    }
  }
  addArticleToLead() {
    let idR = this.leadFound.id_document;
    if (this.leadFound.completo) {
      if (this.leadFound.hasOwnProperty("idArticulos")) {
        let find = this.leadFound.idArticulos.find(
          (article) => article.id == this.idAL,
        );
        if (find) {
          alert("Ya agregaste este articulo");
        } else {
          if (this.leadFound.hasOwnProperty("positive_or_negative")) {
            let check = this.leadFound.positive_or_negative.find(
              (article) => article.id_articulo == this.idAL,
            );
            if (check) {
              alert("Ya agregaste este articulo");
            } else {
              let obj = {
                id: this.idAL,
              };
              let idArticulos = firebase.firestore.FieldValue.arrayUnion(obj);
              this.afs.collection("LEAD").doc(idR).update({
                idArticulos,
                cuarentena: false,
              }).then(() => {
                this.router.navigate(["gestiona", idR, "go"]);
              });
            }
          } else {
            let obj = {
              id: this.idAL,
            };
            let idArticulos = firebase.firestore.FieldValue.arrayUnion(obj);
            this.afs.collection("LEAD").doc(idR).update({
              idArticulos,
              cuarentena: false,
            }).then(() => {
              this.router.navigate(["gestiona", idR, "go"]);
            });
          }
        }
      } else {
        let obj = {
          id: this.idAL,
        };
        let idArticulos = firebase.firestore.FieldValue.arrayUnion(obj);
        this.afs.collection("LEAD").doc(idR).update({
          idArticulos,
          cuarentena: false,
        }).then(() => {
          this.router.navigate(["gestiona", idR, "go"]);
        });
      }
    } else {
      alert("El lead no esta completo");
    }
    this.closeModal();
  }
  saveDataAL(isAval: boolean) {
    this.afs
      .collection("ARTICLE", (ref) => ref.where("id_articulo", "==", this.idAL))
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((results: any[]) => {
        if (results.length > 0) {
          this.checkCustomer(isAval);
          //
        } else {
          alert("El articulo no existe");
          this._location.back();
        }
      });
  }
  saveAval(telefono: number, nombre: string, apellido: string) {
    const { comentario } = this.avalForm.value;

    this.avalForm.value.telefono = telefono;
    this.avalForm.value.nombre = nombre;
    this.avalForm.value.apellido = apellido;
    this.id = Number(this.generateId.createId());



    this.afs
      .collection("CUSTOMER")
      .doc(this.idLead)
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((lead: any) => {
        let positives: Array<any> = lead.positives;
        for (let i = 0; i < positives.length; i++) {
          positives[i].dataAvalComplete = true;
        }
        lead.positives = positives;
        this.afs
          .collection("CUSTOMER")
          .doc(this.idLead)
          .update(lead)
          .then((e) => {
            this.afs
              .collection("CUSTOMER")
              .doc(this.idLead)
              .update({
                aval: {
                  data: this.avalForm.value,
                  comment: comentario,
                  id: this.generateId.createId(),
                  complete: false,
                  date: moment().format(""),
                },
              }).then(() => {
                this.avalForm.reset();
                this.router.navigate([
                  "gestiona",
                  this.idLead,
                  "upload-files-aval",
                  this.index,
                  this.idLead,
                ]);
              });
          });
      });

    //this.dbData.saveAval(this.avalForm.value, last_update, objeto, this.id);
  }
  saveCustomer(telefono: number, nombre: string, apellido: string) {
    // let selectedValuesToSearch = []
    // selectedValuesToSearch = this.caracteristicas.value.filter(c => c.seleccionado === true);

    const { comentario } = this.leadForm.value;
    let objeto = {
      comentario: comentario,
      fecha: this.fecha_actual,
      hora: this.hora_actual,
    };
    let last_update = {
      fecha: this.fecha_actual,
      hora: this.hora_actual,
    };
    this.leadForm.value.telefono = telefono;
    this.leadForm.value.nombre = nombre;
    this.leadForm.value.apellido = apellido;
    this.id = Number(this.generateId.createId());

    this.dbData.saveClienteReg(
      this.leadForm.value,
      last_update,
      objeto,
      this.id,
      this.router.url,
    );
    this.leadForm.reset();
  }
  saveLeadKA(telefono: number, nombre: string, apellido: string) {
    // let selectedValuesToSearch = []
    // selectedValuesToSearch = this.caracteristicas.value.filter(c => c.seleccionado === true);

    const { comentario } = this.leadForm.value;
    let objeto = {
      comentario: comentario,
      fecha: this.fecha_actual,
      hora: this.hora_actual,
    };
    let last_update = {
      fecha: this.fecha_actual,
      hora: this.hora_actual,
    };
    this.leadForm.value.telefono = telefono;
    this.leadForm.value.nombre = nombre;
    this.leadForm.value.apellido = apellido;
    this.id = Number(this.generateId.createId());

    this.dbData.saveLeadKA(
      this.leadForm.value,
      last_update,
      objeto,
      this.id,
      this.idART,
      this.idKA,
    );
    this.leadForm.reset();
  }

  saveCustomerAL(telefono: number, nombre: string, apellido: string) {
    this.leadForm.value.telefono = telefono;
    this.leadForm.value.nombre = nombre;
    this.leadForm.value.apellido = apellido;
    let id = Number(this.generateId.createId());
    const { comentario } = this.leadForm.value;

    let objeto = {
      comentario: comentario,
      fecha: this.fecha_actual,
      hora: this.hora_actual,
    };
    let last_update = {
      fecha: this.fecha_actual,
      hora: this.hora_actual,
    };
    this.dbData.saveClienteAL(
      this.leadForm.value,
      last_update,
      objeto,
      this.idAL,
      id,
    );
  }
}
