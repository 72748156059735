import { ArticleImagesComponent } from "./article-images/article-images.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CanEnterGuard } from "src/app/SERVICIOS/can/can-enter.guard";
import { AuthGuard } from "src/app/SERVICIOS/guards/auth.guard";
import { DisabledGuard } from "src/app/SERVICIOS/guards/disabled.guard";
import { SupportGuard } from "src/app/SERVICIOS/guards/support.guard";

import { FeaturesComponent } from "./features/features.component";
import { InfoComponent } from "./info/info.component";
import { ItemViewComponent } from "./item-view/item-view.component";
import { RegistraClienteComponent } from "./registra-cliente/registra-cliente.component";
import { RegistraComponent } from "./registra/registra.component";

const routes: Routes = [
  {
    path: "registra",
    component: RegistraComponent,
    children: [
      { path: "", component: RegistraClienteComponent },
      {
        path: "keyaccess/:idKA/:idArticle",
        component: RegistraClienteComponent,
      },
      { path: ":idA", component: RegistraClienteComponent },
      {
        path: ":idLead/add-aval/:index/:idClient",
        component: RegistraClienteComponent,
      },
      { path: ":idA/features", component: FeaturesComponent },
      { path: ":idA/features/:edit", component: FeaturesComponent },
      { path: ":idR/info", component: InfoComponent },
      { path: ":idR/:idA/item-view/:id", component: ItemViewComponent },
      { path: ":idA/item-view", component: ItemViewComponent },
      { path: ":idA/item-view/:al", component: ItemViewComponent },
      { path: ":idA/item-view/:al/:idR", component: ItemViewComponent },
      { path: ":idA/article-listing/item-view", component: ItemViewComponent },
      { path: ":idA/community-photos", component: ArticleImagesComponent },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: ":article-listing/:idR/item-view/:idA",
    component: ItemViewComponent,
    canActivate: [AuthGuard, CanEnterGuard, DisabledGuard, SupportGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RegistraRoutingModule {}
