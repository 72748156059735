import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from "@angular/common";
import firebase from 'firebase/compat/app';

import { AuthService } from 'src/app/SERVICIOS/auth.service';

@Component({
  selector: 'app-verification-code',
  templateUrl: './verification-code.component.html',
  styleUrls: ['./verification-code.component.scss']
})
export class VerificationCodeComponent implements OnInit {
  fecha_registro: any;
  isChecked: boolean;
  invalid: boolean = false;
  message_error: string;
  codeError: any;
  messageError: string;
  user: any;

  registerForm = new UntypedFormGroup({

    name: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
    lastname: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
  })

  constructor(public auth: AuthService,
    private afs: AngularFirestore,
    private router: Router, private _location: Location,
  ) {

  }
  get name() { return this.registerForm.get('name') }
  get lastname() { return this.registerForm.get('lastname') }

  async onRegister() {
    if (this.registerForm.valid) {

      const { name, lastname } = this.registerForm.value;

      try {
        if (this.user) {
          console.log("hay usuario");

          //User.updateProfile
          await this.user.updateProfile({
            displayName: name + ' ' + lastname,
          }).then(() => {
            this.afs.collection('USERS').doc(this.user.uid).update({
              displayName: name + ' ' + lastname,
            }).then(() => {
              this.router.navigate(['/welcome']);
            })
          })



        } else {
          console.log("no hay usuario");
        }

      }
      catch (error) {
        console.log(error);
      }

    }



  }


  ngOnInit(): void {
    let user = firebase.auth().currentUser;
    if (user) {
      this.user = user;
      console.log(user);

    } else {
      this._location.back();
    }
  }

}
