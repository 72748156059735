import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class GetTokensService {

  constructor(private afs: AngularFirestore) { }

  async getTokens(uid: string) {
    var data: any = await (await this.afs.collection('USERS').doc(uid).get().toPromise()).data();
    return data.tokens;

  }

}
