import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { log } from 'console';
import { AuthService } from 'src/app/SERVICIOS/auth.service';
import { NotificationService } from 'src/app/SERVICIOS/notification.service';
import { GenerateRecentActivityService } from 'src/app/SERVICIOS/recent-activity.service';
import { NotificationsService } from 'src/app/SERVICIOS/socket/notifications.service';
import { SocketService } from 'src/app/SERVICIOS/socket/socket.service';

@Component({
  selector: 'app-recent-activity',
  templateUrl: './recent-activity.component.html',
  styleUrls: ['./recent-activity.component.scss']
})
export class RecentActivityComponent implements OnInit {
  archivados = []
  recientes = []
  showArch = false
  recentActivity = []
  isFullAdmin = false
  isAdmin = false
  //crea un array de objetos
  p = 1
  pa = 1
  user: any
  constructor(public authSvc: AuthService, private router: Router,
    public gonotification: NotificationService,
    public notification: NotificationsService,
    private socket: SocketService,
    public afs: AngularFirestore,
    public recentAct: GenerateRecentActivityService) { }

  ngOnInit(): void {

    this.authSvc.getCurrentUser().then(user => {
      this.user = user
      this.afs.collection("USERS").doc(user.uid).valueChanges().subscribe((doc: any) => {
        this.isFullAdmin = doc.role == "FULL-ADMIN" ? true : false
        this.isAdmin = doc.role == "ADMIN" ? true : false
        this.getRecentActivity(user.uid);

      })


    })
  }

  async getRecentActivity(uid: string) {
    // let docs = await this.recentAct.GetRecentActivity(uid)
    // for (let index = 0; index < docs.length; index++) {
    //   this.recentActivity.push(docs[index].data())
    // }
    // this.recientes = this.recentActivity.filter((item) => item.recentActivity.archived == false)
    // this.recientes.sort((a, b) => a.recentActivity.date - b.recentActivity.date)
    if (this.isAdmin || this.isFullAdmin) {
      this.afs.collectionGroup("RECENT-ACTIVITY").valueChanges().subscribe((docs) => {
        this.recentActivity = []
        this.recientes = []
        this.recentActivity = docs
        this.recientes = this.recentActivity
        this.recientes.sort((a, b) => a.recentActivity.date - b.recentActivity.date)
      })
    } else {
      this.afs.collection('USERS').doc(uid).collection("RECENT-ACTIVITY")
        .valueChanges()
        .subscribe((docs) => {
          this.recentActivity = []
          this.recientes = []
          this.recentActivity = docs
          this.recientes = this.recentActivity.filter((item) => item.recentActivity.archived == false)
          this.recientes.sort((a, b) => a.recentActivity.date - b.recentActivity.date)
        }
        )
    }


  }
  changePage(e) {
    this.p = e;
    localStorage.setItem('page', e);
  }


  archivar = (elemento: any) => {
    this.recentActivity = []
    elemento.recentActivity.archived = true
    this.recientes = this.recientes.filter((item) => item.recentActivity.id !== elemento.recentActivity.id)
    // this.archivados.push(elemento)
    // console.log(this.archivados);
    this.afs.collection("USERS").doc(this.user.uid).collection("RECENT-ACTIVITY").doc(elemento.id_document)
      .update({ recentActivity: elemento.recentActivity })
  }
  noArchivar(elemento: any) {
    this.recentActivity = []
    elemento.recentActivity.archived = false
    this.archivados = this.archivados.filter((item) => item.recentActivity.id !== elemento.recentActivity.id)
    this.afs.collection("USERS").doc(this.user.uid).collection("RECENT-ACTIVITY").doc(elemento.id_document)
      .update({ recentActivity: elemento.recentActivity })
  }


  showArchivados = async () => {
    this.showArch = true;
    this.afs.collection("USERS").doc(this.user.uid).collection("RECENT-ACTIVITY")
      .valueChanges()
      .subscribe((docs) => {


        this.archivados = []
        this.recentActivity = docs
        this.archivados = this.recentActivity.filter((item) => item.recentActivity.archived == true)
        this.archivados.sort((a, b) => a.recentActivity.date - b.recentActivity.date)
      })


  }
  showPrincipales() {
    this.showArch = false;
    this.recentActivity = []
    this.recientes = []
    this.getRecentActivity(this.user.uid);
  }

  goToFullView(elemento) {


    elemento.recentActivity.view = true
    let recentAct = elemento
    this.setViewTrue(elemento.id_document, elemento.recentActivity.uid_user, recentAct)

    this.router.navigate(['historys/recent-activity-fullview', elemento.id_document])
  }
  setViewTrue(id_document: any, uid: any, recentActivity: any) {
    this.afs.collection("USERS").doc(uid).collection("RECENT-ACTIVITY").doc(id_document)
      .set(recentActivity)



  }
}
