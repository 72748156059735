import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/SERVICIOS/auth.service';
import { SocketService } from 'src/app/SERVICIOS/socket/socket.service';
import { User } from '../../MODULOS/user.interface';

@Component({
  selector: 'app-manage-requests',
  templateUrl: './manage-requests.component.html',
  styleUrls: ['./manage-requests.component.scss']
})
export class ManageRequestsComponent implements OnInit {

  message: string
  user: User
  invalid: boolean = false
  gettoken: string
  tokenError: boolean = false
  tokenErrorMessage: string = ''
  verifiedToken: boolean = false
  infoRequest: any
  infoArticle: any
  infoLead: any
  constructor(private authSvc: AuthService, public afs: AngularFirestore, private socket: SocketService, private router: Router) {
    this.socket.io.on("errorToken", (message) => {
      this.tokenError = true
      this.tokenErrorMessage = message
    })
    this.socket.io.on("correctToken", (info) => {
      this.verifiedToken = true
      this.getDataRequest(info)
    })
  }
  getDataRequest(info: any) {


    this.afs.collectionGroup('KEY-ACCESS').valueChanges().subscribe((requests: any[]) => {
      requests.forEach(request => {
        if (request.id_document == info.idKA) {

          this.infoRequest = request
          this.getArticle(request.id_article);
          this.getLead(request.id_lead);
        }
      });

    })
  }
  getLead(id_lead: any) {
    this.afs.collection('LEAD', ref => ref.where('id', '==', id_lead)).valueChanges().subscribe((lead: any[]) => {
      this.infoLead = lead[0]

    })
  }
  getArticle(id_article: any) {
    this.afs.collection('ARTICLE').doc(id_article).valueChanges().subscribe((article) => {
      this.infoArticle = article

    })
  }

  ngOnInit(): void {
    this.authSvc.getCurrentUser().then(user => {
      this.user = user

    })
  }


  checkToken = new UntypedFormGroup({
    digitOne: new UntypedFormControl('', [Validators.required, Validators.maxLength(1)]),
    digitTwo: new UntypedFormControl('', [Validators.required, Validators.maxLength(1)]),
    digitThree: new UntypedFormControl('', [Validators.required, Validators.maxLength(1)]),
    digitFour: new UntypedFormControl('', [Validators.required, Validators.maxLength(1)]),
    digitFive: new UntypedFormControl('', [Validators.required, Validators.maxLength(1)]),
    digitSix: new UntypedFormControl('', [Validators.required, Validators.maxLength(1)])
  });

  get token() { return this.checkToken.get('token') }

  async checkTokenUser(token: Number) {


    // let digits = Number(this.checkToken.value.digitOne + this.checkToken.value.digitTwo + this.checkToken.value.digitThree + this.checkToken.value.digitFour + this.checkToken.value.digitFive + this.checkToken.value.digitSix)

    let data = {
      uid: this.user.uid,
      token
    }


    this.socket.io.emit("verificationTokens", data)
    // if (this.checkToken.valid) {
    //   try {
    //     const { token } = this.checkToken.value;
    //     await this.authSvc.resetPassword(token)
    //     if (this.invalid == false && this.checkToken.valid) this.gettoken = token

    //   }

    //   catch (error) {
    //     console.log(error);
    //     // this.message == "No hay ningún registro de usuario que corresponda a este correo electrónico. Es posible que se haya eliminado al usuario"

    //     this.invalid = true
    //     let error_code = error.code
    //     if (error_code == "auth/invalid-token") {
    //       this.message = "Ingresa una dirección token correcta."
    //     } else if (error_code == "auth/user-not-found") {
    //       this.message = "No hay ningún registro de usuario que corresponda a este correo electrónico. Es posible que se haya eliminado al usuario."
    //     }
    //   }
    // } else {

    // }

  }

  onOtpChange(token: string) {
    if (token.length == 6) {

      this.checkTokenUser(Number(token))
    }

  }
  acceptRequestKeyAccess() {
    this.socket.io.emit("acceptRequestKeyAccess", this.infoLead.uid_user)
  }
  reset() {
    this.gettoken = undefined
  }
  animation(e) {
    let value = e.target.value
    document.getElementById('i-token').setAttribute("value", value)
  }
}
