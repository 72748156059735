<div class="main">
  <div class="mt-4 mb-5">
    <h2 class="title">Lista de usuarios</h2>
  </div>
  <div
    class="box-select-filter"
    *ngIf="auth.canAdmin(user) || auth.userFullAdmin(user)"
  >
    <div class="row mb">
      <div class="col">
        <div class="form-outline">
          <select
            class="form-select"
            (change)="filterWithSelect($event.target.value)"
          >
            <option [selected]="false">Selecciona una opción</option>
            <option [selected]="valueName == 'id'" class="large" value="1">
              ID
            </option>
            <option [selected]="valueName == 'nombre'" value="2">
              Nombre de usuario
            </option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="form-outline">
          <input
            [disabled]="disabledInput"
            (input)="getText($event.target.value)"
            class="form-control"
            placeholder="ingrese el valor"
            name="filterPost"
            [(ngModel)]="filterPost"
            [value]="text_input"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Estatus</th>
          <th scope="col" class="long">Nombre</th>
          <th scope="col" class="long">Fecha de registro</th>
          <th scope="col">Rol</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody
        *ngIf="obtUsuarios | filterusers : filterPost : valueName as users"
      >
        <tr
          *ngFor="
            let todo of users | paginate : { itemsPerPage: 8, currentPage: p }
          "
        >
          <td>{{ todo.id }}</td>
          <th *ngIf="todo.status == 'undefined'">En proceso</th>
          <th *ngIf="todo.status == 'enabled'">Aceptado</th>
          <th *ngIf="todo.status == 'rejected'">Rehazado</th>
          <th *ngIf="todo.status == 'desabled'">Deshabilitado</th>
          <th *ngIf="todo.status == 'disabled'">Por eliminar</th>
          <th *ngIf="todo.status == 'eliminated'">Eliminado</th>
          <td class="long">{{ todo.displayName }}</td>
          <td class="long">{{ todo.date.fecha }}</td>
          <td>{{ todo.role }}</td>
          <td>
            <div
              class="ui animated button"
              tabindex="0"
              (click)="fullview(todo)"
            >
              <div class="visible content">Ver</div>
              <div class="hidden content">
                <i class="right arrow icon"></i>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="text-center mt-4">
    <pagination-controls
      previousLabel="Anterior"
      nextLabel="Siguiente"
      (pageChange)="changePage($event)"
      class="float-right"
    ></pagination-controls>
  </div>
</div>
