import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class GenerateRecentActivityService {

  constructor(private afs: AngularFirestore,) { }

    GenerateRecentActivity(recentActivity: any, uid: string) {
      this.afs.collection("USERS").doc(uid).collection("RECENT-ACTIVITY").add({
        recentActivity
      }).then((doc) => {
        this.afs.collection("USERS").doc(uid).collection("RECENT-ACTIVITY").doc(doc.id).update({
          id_document: doc.id
        })
      })
    }


  async GetRecentActivity(uid: string) {
    var docs: any = (await this.afs.collection('USERS').doc(uid).collection("RECENT-ACTIVITY").get().toPromise()).docs
    return docs;

  }


}
