<div class="main">
  <div class="mt-4 mb-5">
    <h2 class="title">Article listing</h2>
  </div>
  <div class="subtitle mb-3">
    <h5>Filtrar</h5>
  </div>
  <div class="box-select-filter">
    <div class="row mb">
      <div class="col">
        <div class="form-outline">
          <select class="form-select" (change)="filterWithSelect($event.target.value)">
            <option [selected]="false">Selecciona una opción</option>
            <option [selected]="getFilterSelect == '1'" value="1">ID de artículo</option>
            <option [selected]="getFilterSelect == '2'" value="2">Calle</option>
            <option [selected]="getFilterSelect == '3'" value="3">Colonia</option>
            <option [selected]="getFilterSelect == '4'" value="4">Ciudad</option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="form-outline">
          <div class="ui icon input " [ngClass]="{loading:search}">
            <input [disabled]="disabledInput" [value]="getValueSelect " type="text" name="filterPost"
              (input)="getFilter($event.target.value)" placeholder="Ingrese el valor">
            <i class="search icon"></i>
          </div>
          <!-- <input [disabled]="disabledInput" class="form-control" [value]="getValueSelect "
                        placeholder="ingrese el valor" name="filterPost" (input)="getFilter($event.target.value)" /> -->
        </div>
      </div>
    </div>
  </div>
  <!-- searchWithTags -->

  <div class="subtitle mb-3 mt-5" style="display: flex; justify-content: space-between;">
    <h5>Filtrar pos tags</h5>
    <button class="ui button" (click)="show_hide()">{{text_button}}</button>
  </div>
  <div *ngIf="show">
    <div *ngIf="contactForm">
      <form [formGroup]="contactForm" (change)="searchWithTags()">
        <ul class="ks-cboxtags" formArrayName="caracteristicas">
          <li *ngFor="let caracteritica of caracteristicas.controls; let i = index" [formGroupName]="i">
            <input id="{{ 'caracteritica-' + caracteritica.controls['id'].value }}" formControlName="seleccionado"
              type="checkbox" [value]="caracteritica.controls['seleccionado'].value" />
            <label for="{{ 'caracteritica-' + caracteritica.controls['id'].value }}">
              {{ caracteritica.value.descripcion }}
            </label>
          </li>
        </ul>
      </form>
    </div>
  </div>

  <div *ngIf="( articles | filter:filterPost:valueName) as result">
    <div class="subtitle mb-3 d-flex justify-content-between">
      <h5>Resultados: {{result.length}}</h5>
    </div>
    <div class="text-center mt-4 mb-5" *ngIf="result.length > 6">
      <pagination-controls responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
        (pageChange)="changePage($event)" class="float-right"></pagination-controls>
    </div>
    <div class="all-articles">
      <div class="article card mb-4" *ngFor="let art of result| paginate: { itemsPerPage: 6, currentPage: p } ">
        <div class="image" *ngIf="art.imagen_principal.url; else noImg">
          <button *ngIf="idR && !isKeyAccess && user && user.role !== 'FULL-ADMIN'" class="btn-image ui primary button"
            (click)="addLead(art)">Agregar</button>
          <div class="btn-image" *ngIf="!idR &&  !isKeyAccess &&user && user.role !== 'FULL-ADMIN'">
            <button title="Agregar Lead" class=" ui primary button" (click)="addLead(art)">
              <i class="user plus icon" style=" margin-right: 0px;"></i>
            </button>
            <button class="ui primary button" title="Key Access" (click)="addArticleToKeyAccess(art)"><i
                class="key icon" style=" margin-right: 0px;"></i></button>
          </div>

          <button *ngIf="isKeyAccess && !idR && user && user.role !== 'FULL-ADMIN'" class="btn-image ui primary button"
            (click)="addArticleToKeyAccess(art)">Seleccionar</button>

          <img style="border-radius: 1.5rem;" [src]="art.imagen_principal.url" alt="" />
        </div>
        <ng-template #noImg>
          <div class="no-image">
            <div *ngIf="idCustomerOtherArticle == '' || idCustomerOtherArticle == null">
              <button *ngIf="idR && user && !isKeyAccess && user.role !== 'FULL-ADMIN'"
                class="btn-image ui primary button" (click)="addLead(art)">Agregar artículo</button>
              <div class="btn-image" *ngIf="!idR &&  !isKeyAccess &&user && user.role !== 'FULL-ADMIN'">
                <button title="Agregar Lead" class=" ui primary button" (click)="addLead(art)">
                  <i class="user plus icon" style=" margin-right: 0px;"></i>
                </button>
                <button class="ui primary button" title="Key Access" (click)="addArticleToKeyAccess(art)"><i
                    class="key icon" style=" margin-right: 0px;"></i></button>
              </div>
              <button *ngIf="isKeyAccess && !idR && user && user.role !== 'FULL-ADMIN'"
                class="btn-image ui primary button" (click)="addArticleToKeyAccess(art)">Seleccionar</button>
            </div>
            <div *ngIf="idCustomerOtherArticle != '' && idCustomerOtherArticle != null">
              <button class="btn-image ui primary button" (click)="addLead(art)">Agregar</button>
            </div>
            Sin fotografías
          </div>
        </ng-template>
        <div class="description text-center mt-2 mb-4">
          <div class="first-info text-center">
            <p *ngIf='art.tipo.tipo == "compraventa"'>Véndela por</p>
            <p *ngIf='art.tipo.tipo == "arrendamiento"'>Réntasela por</p>
          </div>
          <div class="mb-2">
            <p>{{ art.articulo.precio | currency}} {{art.articulo.currency}}</p>
          </div>
          <p class="text-center">{{ art.articulo.ciudad | titlecase}}, {{ art.articulo.estado | titlecase}},
            {{
            art.articulo.pais}}</p>
        </div>
        <div class="btn-article mt-4">
          <button class="ui primary
            button" (click)="goToFullView(art)">Ver artículo</button>
        </div>

      </div>
      <div>
        <h4 class="text-center" *ngIf="result.length == 0">No hay resultados</h4>
      </div>
    </div>
  </div>
</div>