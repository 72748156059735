import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/SERVICIOS/auth.service';
import { DataDbService } from 'src/app/SERVICIOS/data-db.service';
import { GenerateIdService } from 'src/app/SERVICIOS/generate-id.service';
import { GetTokensService } from 'src/app/SERVICIOS/get-tokens.service';
import { SocketService } from 'src/app/SERVICIOS/socket/socket.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../MODULOS/user.interface';
@Component({
  selector: 'app-select-lead',
  templateUrl: './select-lead.component.html',
  styleUrls: ['./select-lead.component.scss']
})
export class SelectLeadComponent implements OnInit {
  subs_one: Subscription;
  myLeads = [];
  idKA = ''
  idArticle = ''
  user: User;
  constructor(public dbData: DataDbService, private route: ActivatedRoute, public afs: AngularFirestore, private generateId: GenerateIdService,
    public auth: AuthService, private tokensUser: GetTokensService, private router: Router, private socket: SocketService,) { }

  ngOnInit(): void {
    this.auth.user$.pipe(take(1)).toPromise().then((user) => {
      this.idArticle = this.route.snapshot.paramMap.get('idArticle');
      this.idKA = this.route.snapshot.paramMap.get('keyId');
      this.user = user
      this.subs_one = this.afs.collection("LEAD", ref => ref.where("uid_user", "==", user.uid)).valueChanges().subscribe(leads => {
        this.myLeads = leads.filter((lead: any) => lead.completo == true && lead.escalate != true)
      });
    })
  }
  createNewLead() {
    this.router.navigate(['/registra/keyaccess/', this.idKA, this.idArticle]);
  }

  goToGenerateToken(lead: any) {
    this.afs.collection('ARTICLE').doc(this.idArticle).collection('KEY-ACCESS').doc(this.idKA).update({
      id_lead: lead.id,
      id_document_lead: lead.id_document,
      nameVisitor: lead.cliente.nombre + ' ' + lead.cliente.apellido,
      status: 'en proceso',
      complete: true
    }).then(() => {
      this.router.navigate(['/keyaccess/keyaccess-fullview', this.idArticle, this.idKA]);

    })
  }

  getPositiveArticles(lead) {
    if (
      lead.positive_or_negative == undefined ||
      lead.positive_or_negative == null
    )
      return 0;
    let leadRelation = lead.positive_or_negative;
    if (leadRelation.length > 0) {
      let count = 0;
      leadRelation.forEach((relation) => {
        if (relation.estatus == 'positivo') count++;
      });

      return count;
    }

    return 0;
  }
  getNegativeArticles(lead) {
    if (
      lead.positive_or_negative == undefined ||
      lead.positive_or_negative == null
    )
      return 0;
    let leadRelation = lead.positive_or_negative;
    if (leadRelation.length > 0) {
      let count = 0;
      leadRelation.forEach((relation) => {
        if (relation.estatus == 'negativo') count++;
      });

      return count;
    }

    return 0;
  }
  getRelation(lead) {
    if (
      lead.positive_or_negative == undefined ||
      lead.positive_or_negative == null
    )
      return 0;
    let leadRelation = lead.positive_or_negative;
    if (leadRelation.length > 0) {
      return leadRelation.length;
    }

    return 0;
  }

}
