import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GenerateIdService {
  private id: string;

  constructor() {
    this.generateId()


  }



  generateId() {
    setInterval(() => {
      let date = new Date();
      let seg;
      let mil;
      let mes;
      let dia;
      let getDia = date.getDate();
      let getMes = date.getMonth() + 1;
      let anio = date.getFullYear();
      let hora = date.getHours()
      let minuto = date.getMinutes();
      let getSeg = date.getSeconds()
      let getMil = date.getMilliseconds();

      if (String(getDia).length == 1) {
        dia = 0 + '' + getDia
      } else {
        dia = getDia
      }
      if (String(getMes).length == 1) {
        mes = 0 + '' + getMes
      } else {
        mes = getMes
      }
      if (String(getSeg).length == 1) {
        seg = 0 + '' + getSeg
      } else {
        seg = getSeg
      }
      if (String(getMil).length == 2) {
        mil = 0 + '' + getMil
      } else if (String(getMil).length == 1) {
        mil = 0 + '' + 0 + '' + getMil
      } else {
        mil = getMil
      }
      this.id = `${anio}${mes}${dia}${hora}${minuto}${seg}${mil}`

    }, 10)
  }



  createId() {
    let id = this.id
    return id
  }
}
