import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MarktingComponent } from "./markting.component";
import { MarktingRoutingModule } from "./makting-routing.module";
import { LeadAndSupportComponent } from "./lead-and-support/lead-and-support.component";
import { LeadDataComponent } from "./lead-data/lead-data.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerModule } from "ngx-spinner";
import { FiltersComponent } from "./filters/filters.component";
import { AgentSelectionComponent } from "./agent-selection/agent-selection.component";
import { NgxPaginationModule } from "ngx-pagination";
import { AgentSelectionFilterPipe } from "../pipes/agent-selection-filter.pipe";
import { ResumeComponent } from './resume/resume.component';
import { ResultsComponent } from "./results/results.component";

@NgModule({
  declarations: [
    MarktingComponent,
    LeadAndSupportComponent,
    LeadDataComponent,
    FiltersComponent,
    AgentSelectionComponent,
    AgentSelectionFilterPipe,
    ResumeComponent,
    ResultsComponent,
  ],
  imports: [
    CommonModule,
    MarktingRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgbModule,
    NgxPaginationModule,
  ],
})
export class MarktingModule {}
