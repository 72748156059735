import { Pipe, PipeTransform } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Pipe({
  name: 'lead'
})
export class LeadPipe implements PipeTransform {

  articles = []
  users = []
  constructor(private afs: AngularFirestore) {
    this.afs.collection('ARTICLE').valueChanges().subscribe(async (articles: any) => {
      this.articles = await articles
    })
    this.afs.collection('USERS').valueChanges().subscribe(async (users: any) => {
      this.users = await users
    })

  }

  transform(value: any, arg: string, propName: any): any {
    const results = [];
    if (propName == undefined || (arg == '' || arg == undefined)) return value;
    else {

      for (const item of value) {
        if (propName == "name") {
          let name = item.cliente.nombre
          let lastname = item.cliente.apellido
          let full_name = name + ' ' + lastname
          if (full_name.toLowerCase().includes(arg.toLowerCase())) {
            results.push(item)
          }
        }

        else if (propName == 'idregister') {
          if (arg) {
            let id = String(item.id)
            if (id.includes(arg.toLowerCase())) {
              results.push(item)
            }
          }

          // if (this.articles !== []) {
          // let res = this.articles.find(article => article.id_articulo.indexOf(arg) > -1)
          // if (res.id_document_customer == item.id_document) results.push(item)
          // }else {
          //   console.log('no hay');

          // }

        } else if (propName == 'iduser') {
          if (arg) {
            for (const user of this.users) {
              let id = String(user.id)

              if (id.includes(arg)) {
                if (user.uid == item.uid_user) { results.push(item) }
              }
            }

            // let id = String(item.pendiente.nombre)


            // if (id.indexOf(arg) !== -1) {
            //   results.push(item)
            // }

          }

        }

      };

    }
    return results;
  }



}
