import { OnInit, Pipe, PipeTransform } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Pipe({
  name: 'keyaccessfilter'
})
export class KeyaccessfilterPipe implements PipeTransform {


  articles = []
  users = []
  keys = []
  leads = []



  constructor(private afs: AngularFirestore) {
    this.afs.collection('ARTICLE').valueChanges().subscribe(async (articles: any) => {
      this.articles = await articles
    })
    this.afs.collection('USERS').valueChanges().subscribe(async (users: any) => {
      this.users = await users
    })
    this.afs.collectionGroup('KEY-ACCESS').valueChanges().subscribe(async (keys: any) => {
      this.keys = await keys
    })
    this.afs.collection('LEAD').valueChanges().subscribe(async (leads: any) => {
      this.leads = await leads
    })

  }

  transform(value: any, arg: string, propName: any): any {
    console.log(value, arg, propName);


    const results = [];
    if (propName == undefined || (arg == '' || arg == undefined)) return value;
    if (this.articles && this.users && this.keys) {

      for (const item of value) {
        if (propName == "nombre") {
          if (item.nameVisitor.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(arg.toLowerCase()) > -1) {
            results.push(item)
          }
        }
        else if (propName == 'uid_user') {
          if (arg) {
            this.afs.collection('USERS').valueChanges().subscribe((users: any) => {
              let res = users.find(user => user.id.indexOf(arg) > -1)
              if (res && res.uid == item.uid_user) results.push(item)
            })
          }

        }
        else if (propName == 'id_article') {
          let res = this.articles.find(article => article.id_articulo.indexOf(arg) > -1)

          if ((item.id_article != null && item.id_article != undefined)) {
            if (res.id_document == item.id_article) results.push(item)
          }
        } else if (propName == 'id_lead') {


          let res = this.leads.find(lead => lead.id.indexOf(arg) > -1)
          if ((item.id_lead != null && item.id_lead != undefined)) {
            if (res.id == item.id_lead) results.push(item)
          }

        } else if (propName == 'id_access') {
          if (item.id.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(arg.toLowerCase()) > -1) {
            results.push(item)
          }


        }
        else if (propName == "habilitado" || propName == "en espera") {
          if (item.estatus == propName) results.push(item);

        }
        else if (propName == "deshabilitado") {
          if (item.estatus == "deshabilitado" || item.estatus == "denegado") results.push(item)
        }

        else if (propName == "id_cliente") {
          if (item[propName].toLowerCase().indexOf(arg.toLowerCase()) > -1) results.push(item)
        }
      };

    }
    return results;
  }

}
