<div class="container">
  <div class="header">
    <div class="buttons">
      <div>
        <div class="title text-center">
          <h2>Identificación</h2>
        </div>
        <div class="ui center aligned basic segment">
          <div class="ui left icon action input">
            <button class="ui button" (click)="goToINE()">
              INE O PASAPORTE
            </button>
          </div>
          <div class="ui horizontal divider">
            O
          </div>
          <div class="ui left icon action input">
            <button class="ui button" (click)="aditional()">
              OTRA OPCIÓN
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>