<div class="main">
  <div class="header">
    <div class="buttons">
      <div class="ui two column stackable center aligned grid">
        <div class="middle aligned row">
          <div class="d-flex justify-content-center" *ngIf="!agentData || !leadData">
            <div class="spinner-border" style="width: 3rem; height: 3rem" role="status"></div>
          </div>
          <div class="column card_data card m-3" *ngIf="agentData">
            <h2 class="fw-bold mb-3 mt-2">Datos del agente</h2>
            <div class="d-flex">
              <p class="text-secondary fw-bold">Nombre:</p>
              <p>{{ agentData.displayName }}</p>
            </div>
            <div class="d-flex">
              <p class="text-secondary fw-bold">Correo:</p>
              <p>{{ agentData.email }}</p>
            </div>
            <div class="d-flex">
              <p class="text-secondary fw-bold">Estatus:</p>
              <p>{{ agentData.status }}</p>
            </div>
          </div>
          <div class="column card_data card " *ngIf="leadData">
            <h2 class="fw-bold mb-3 mt-2">Datos del lead</h2>
            <div class="d-flex">
              <p class="text-secondary fw-bold">Nombre:</p>
              <p>{{ leadData.cliente.nombre }}</p>
            </div>
            <div class="d-flex">
              <p class="text-secondary fw-bold">Apellido:</p>
              <p>{{ leadData.cliente.apellido }}</p>
            </div>
            <div class="d-flex">
              <p class="text-secondary fw-bold">Correo:</p>
              <p *ngIf="leadData.cliente.correo  != ''">{{ leadData.cliente.correo }}</p>
              <p *ngIf="leadData.cliente.correo  == ''">No ingresado</p>

            </div>
            <div class="d-flex">
              <p class="text-secondary fw-bold">Estatus:</p>
              <p>{{ leadData.estatus }}</p>
            </div>
          </div>
          <div>
            <button class="ui button mb-2 mt-4" (click)="finish()">
              Finalizar
            </button>

          </div>

        </div>
      </div>

    </div>
  </div>
</div>