import { Component, OnDestroy, OnInit } from "@angular/core";
import { ChatService } from "../SERVICIOS/socket/chat.service";
import { AuthService } from "../SERVICIOS/auth.service";
import { SocketService } from "../SERVICIOS/socket/socket.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Router } from "@angular/router";
import { NotificationsService } from "../SERVICIOS/socket/notifications.service";
import { DataDbService } from "../SERVICIOS/data-db.service";
import { take } from "rxjs/operators";
import { NotificationService } from "../SERVICIOS/notification.service";
import * as moment from "moment";
import { Subscription } from "rxjs";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { User } from "../COMERCIAL/MODULOS/user.interface";
import { RecentActivityInterface } from "../interfaces/activity";
import { GenerateIdService } from "../SERVICIOS/generate-id.service";

@Component({
  selector: "app-chats",
  templateUrl: "./chats.component.html",
  styleUrls: ["./chats.component.scss"],
})
export class ChatsComponent implements OnInit, OnDestroy {
  public messageForm: UntypedFormGroup;
  public messageText = "";
  allChats = [];
  isSupport: boolean = false;
  id_document: string;
  uid_userRoom: string;

  public asunt: string;
  public description: string;
  chat_disabled: boolean = false;
  public all_chats: any;
  public message_chat_desabled: string;
  public message_chat_main: string = "Elige un chat para empezarrr";
  public uid_user: string;
  public salas: any;
  public selected_room: boolean = false;
  asunto: any;
  messageToUser: string;
  canCreateRoom: Boolean;
  end_chat: any;
  roomToUser: string;
  id_salas: number;
  asuntos = 0;
  click = 0;
  user: User;
  datos_user: any;
  data_chats: any;
  all_messages: any;
  room_selected: any;
  attemps = 0;
  valueName: string;
  filterPost = "";
  user_support: any;
  msg_no_reads = [];
  request_chats = [];
  show = true;
  show_text: string = "Ocultar";
  uid_user_support: string;
  public finalized: boolean = false;
  public temporary: boolean = false;
  subs_one: Subscription;
  subs_two: Subscription;
  subs_three: Subscription;
  subs_four: Subscription;

  constructor(
    public chat: ChatService,
    public auth: AuthService,
    private socket: SocketService,
    private afs: AngularFirestore,
    private router: Router,
    private dataDb: DataDbService,
    private generateId: GenerateIdService,
    public notification: NotificationsService,
    public gonotification: NotificationService,
    private authsv: AuthService,
  ) {
    this.chatFinish();
    localStorage.clear();
  }
  bloque1Normal = "bloque1Normal";
  bloque2Normal = "bloque2Normal";
  Dropoculto = "Dropoculto";
  Notioculto = "Notioculto";
  mostrarBloques = false;
  showImg1 = true;
  showImg2 = false;
  showCard = false;
  form1 = true;
  form2 = false;
  form3 = false;

  ngOnInit(): void {
    this.auth.user$
      .pipe(take(1))
      .toPromise()
      .then((user) => {
        this.user = user;
        if (user.role == "SUPPORT") {
          this.socket.io.emit("join-support");
          this.uid_user_support = user.uid;
          this.isSupport = true;
          this.chat.isSupport(this.isSupport);
          this.subs_one = this.afs
            .collectionGroup("SUPPORT_CHATS")
            .valueChanges()
            .subscribe((chats: any) => {
              chats.sort(function (a, b) {
                return b.id - a.id;
              });

              this.allChats = chats.filter(
                (chats) => chats.user_support == user.uid,
              );
            });
          //aqui obtendra todas las solicitudes de chats
          this.subs_two = this.afs
            .collectionGroup("SUPPORT_CHATS")
            .valueChanges()
            .subscribe((chats: any) => {
              this.request_chats = chats.filter(
                (chats) =>
                  (chats.tomado == false && chats.resuelto == false) ||
                  (chats.temporal == true && chats.activo == false),
              );
            });
        } else {
          this.subs_four = this.subs_three = this.afs
            .collection("USERS/" + user.uid + "/SUPPORT_CHATS")
            .valueChanges()
            .subscribe((chats: any) => {
              this.allChats = chats.filter(
                (chats) => chats.activo_bubble == false,
              );
            });
        }
      });
  }

  createForm() {
    return new UntypedFormGroup({
      message: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(1),
      ]),
    });
  }

  obt_chat(data) {

    this.chat_disabled = false;
    this.message_chat_desabled = "";
    if (data.resuelto) {
      this.chat_disabled = true;
      this.finalized = true;
      this.message_chat_desabled = "El asunto ha sido finalizado";
    } else if (data.temporal) {
      this.chat_disabled = true;
      this.temporary = true;
      this.message_chat_desabled = "El asunto ha sido finalizado temporalmente";
    }
    if (!data.resuelto) {
      this.finalized = false;
    } else if (!data.temporal) {
      this.temporary = false;
    } else {
      this.chat_disabled = false;
      this.message_chat_desabled = "";
    }

    this.data_chats = data;
    this.room_selected = data.asunto.id_document;
    this.chat.chats = data.chats;
    this.id_document = data.asunto.id_document;
    this.uid_userRoom = data.asunto.id_user;
    this.user_support = data.user_support;
    this.chat.chats = data.chats;
    this.chat.get_id_room(this.id_document);
    this.selected_room = true;

    if (this.isSupport) {
      this.afs
        .collection("USERS")
        .doc(data.asunto.id_user)
        .collection("SUPPORT_CHATS")
        .doc(this.id_document)
        .update({
          no_leido_support: 0,
        });
    } else {
      this.afs
        .collection("USERS")
        .doc(data.asunto.id_user)
        .collection("SUPPORT_CHATS")
        .doc(this.id_document)
        .update({
          no_leido: 0,
        });
    }
  }

  finish_chat() {
    this.chat.chat_end(this.data_chats);
    this.chat_disabled = true;
    this.message_chat_desabled = "El asunto ha sido finalizado";
    this.temporary = false;
    this.finalized = true;
  }

  finish_chat_temporary() {
    this.chat.chat_end_temporary(this.data_chats);
    this.chat_disabled = true;
    this.message_chat_desabled = "El asunto se finalizó temporalmente";
    this.temporary = true;
    this.finalized = false;
  }

  take_up_case() {
    this.chat.take_up_chat(this.data_chats);
    this.chat_disabled = false;
    this.message_chat_desabled = "";
    this.temporary = false;
  }

  takeup_case(chat) {
    this.chat.take_up_chat(chat);
    this.chat_disabled = false;
    this.message_chat_desabled = "";
    this.temporary = false;
  }

  chatFinish() {
    this.socket.io.on("finishSupport", (data) => {
      if (data.asunto.id_document == this.room_selected) {
        this.chat_disabled = true;
        this.message_chat_desabled = "El asunto ha sido finalizado";
      } else {
        this.chat_disabled = false;
      }
    });
  }

  changeForm1() {
    let asunto = {
      asunt: this.asunt,
      description: this.description,
      hora: moment().format("hh:mm:ss a"),
      fecha: moment().format("DD-MM-YYYY"),
      resuelto: false,
      id_user: this.uid_user,
      id_room: this.id_salas,
    };

    if (this.canCreateRoom) {
      this.createRoom(asunto);
    } else {
      alert("no puedes crear asuntos en este momento");
    }

    this.form1 = !this.form1;
    this.form2 = !this.form2;
  }

  createRoom(asunto) {
    this.chat.chats = [];

    this.dataDb
      .getDataFs("USERS", this.uid_user, "SUPPORT_CHATS")
      .then((data) =>
        data
          .doc(String(this.id_salas))
          .set({ asunto, chats: [], resuelto: false })
      );
    this.socket.io.emit("support_request", asunto);

    // this.afs.collection("USERS").doc(String(this.uid_user)).collection("SUPPORT_CHATS").
    //   doc(String(this.id_salas)).set({ asunto, chats: [], resuelto: false })
    // this.socket.io.emit('support_request', asunto);
  }

  sendMessage() {
    

    if (this.isSupport) {
      let sendMessage = {
        text: this.messageText,
        readSupport: true,
        read: false,
        messageType: 2,
        id_document: this.id_document,
        uid_userRoom: this.uid_userRoom,
        user: this.uid_userRoom,
        hora: moment().format("hh:mm:ss a"),
        fecha: moment().format("DD-MM-YYYY"),
      };

      this.chat.message(sendMessage, true);
      this.messageText = "";
      let setRecentActivity = {
        date: moment().format(),

        homeTitle: "Tienes un nuevo mensaje de soporte",
        generalTitle:
          "Tienes un nuevo mensaje de soporte, ve a la actividad para más información",
        responses: false,
        description:
          "Tienes un nuevo mensaje de soporte, ve a soporte para ver tu nuevo mensaje",
        from: "soporte",
        subtitle: "Tienes un nuevo mensaje de soporte",
        id: this.generateId.createId(),
        archived: false,
        important: false,
        id_document_component: this.id_document,

        isError: false,
        canUploadData: false,
        view: false,
        uid_user: this.uid_userRoom,
        type: "observation",
        canReply: false,
        isFullview: true,
      };

      this.socket.io.emit("recent-activity", {
        recentActivity: setRecentActivity,
        uid: this.uid_userRoom,
      });
    } else {
      let sendMessage = {
        text: this.messageText,
        messageType: 1,
        readSupport: false,
        read: true,
        id_document: this.id_document,
        uid_userRoom: this.user_support,
        user: this.uid_userRoom,
        hora: moment().format("hh:mm:ss a"),
        fecha: moment().format("DD-MM-YYYY"),
      };
      this.chat.message(sendMessage, false);
      this.messageText = "";
      let setRecentActivity = {
        date: moment().format(),

        homeTitle: "Tienes un nuevo mensaje de soporte",
        generalTitle:
          "Tienes un nuevo mensaje de soporte, ve a la actividad para más información",
        responses: false,
        description:
          "Tienes un nuevo mensaje de soporte, ve a soporte para ver tu nuevo mensaje",
        from: "soporte",
        subtitle: "Tienes un nuevo mensaje de soporte",
        id: this.generateId.createId(),
        archived: false,
        important: false,
        id_document_component: this.id_document,

        isError: false,
        canUploadData: false,
        view: false,
        uid_user: this.user_support,
        type: "observation",
        canReply: false,
        isFullview: true,
      };
      this.socket.io.emit("recent-activity", {
        recentActivity: setRecentActivity,
        uid: this.user_support,
      });
    }
  }

  filterWithSelect(e) {
    if (e == "1") {
      this.valueName = "id";
    } else if (e == "2") {
      this.valueName = "id_user";
    }
  }

  changeIcon() {
    if (this.showImg1) {
      this.showImg1 = false;
      this.showImg2 = true;
      this.showCard = true;
    } else if (this.showImg2 == true) {
      this.showImg1 = true;
      this.showImg2 = false;
      this.showCard = false;
    }
  }

  changeForm2() {
    if (this.form2 === true) {
      this.form2 = false;
      this.form1 = false;
      this.form3 = true;
    }
  }

  goNotification(notification: any) {
    this.gonotification.goNotification(notification);
  }

  async onLogout() {
    try {
      await this.authsv.logout();
      this.router.navigate(["/login"]);
    } catch (error) {
      console.log(error);
    }
  }

  show_hide() {
    this.show = !this.show;
    if (this.show) {
      this.show_text = "Ocultar";
    } else {
      this.show_text = "Mostrar";
    }
  }
  take_asunt(asunt: any) {
    let uid_user = asunt.asunto.id_user;
    let id_document = asunt.asunto.id_document;
    this.afs
      .doc("USERS/" + uid_user + "/SUPPORT_CHATS/" + id_document)
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((values: any) => {
        if (values.tomado == true) alert("el asunto ya se ha tomado");
        else {
          this.afs
            .doc("USERS/" + uid_user + "/SUPPORT_CHATS/" + id_document)
            .update({
              tomado: true,
              user_support: this.uid_user_support,
              activo_bubble: true,
              activo: false,
            })
            .then((e) => {
              this.socket.io.emit("acceptSupport", uid_user);
            });
        }
      });
  }

  ngOnDestroy() {
    if (this.subs_one !== undefined) this.subs_one.unsubscribe();
    if (this.subs_two !== undefined) this.subs_two.unsubscribe();
    if (this.subs_three !== undefined) this.subs_three.unsubscribe();
    if (this.subs_four !== undefined) this.subs_four.unsubscribe();
  }
}
