<div class="main">
  <div class="mt-4 mb-5">
    <h2 class="title">Leads positivos</h2>
  </div>

  <div class="mb-3 mt-3" style="display: flex; justify-content: end">
    <button class="ui button" routerLink="/registra" *ngIf="!isFullAdmin">
      Crear lead
    </button>
  </div>
  <div class="grid-incomes mt-5" *ngIf="!isFullAdmin">
    <div
      *ngFor="
        let lead of allOperations
          | paginate : { itemsPerPage: 6, currentPage: p }
      "
      class="mt-3 card card-income"
    >
      <div *ngIf="!lead.completo">
        <div class="" style="padding: 10px">
          <div class="card-block">
            <h4 class="card-title">Incompleto</h4>
            <h5 class="card-title">{{ lead.id }}</h5>
            <p class="card-title">
              {{ lead.cliente.nombre }} {{ lead.cliente.apellido }}
            </p>
            <h6 class="card-subtitle text-muted">
              {{ lead.last_update.fecha }}
            </h6>
            <p></p>

            <p
              class="card-text p-y-1"
              *ngIf="
                lead.tagsToSearch == null || lead.tagsToSearch == undefined
              "
            >
              -Falta elegir los tags para buscar articulos
            </p>
            <p
              class="card-text p-y-1"
              *ngIf="lead.idArticulos == null || lead.idArticulos == undefined"
            >
              -Falta elegir los articulos
            </p>
            <div class="fx">
              <div></div>
              <div
                (click)="completeLead(lead)"
                style="cursor: pointer"
                class="ui button"
              >
                Completar
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="lead.completo">
        <h3>
          {{ lead.cliente.nombre | titlecase }}
          {{ lead.cliente.apellido | titlecase }}
        </h3>
        <p>{{ lead.id }}</p>
        <div>
          <div class="lead" style="font-weight: bold">Fecha de creación</div>
          <p>{{ lead.cliente.fecha }}</p>
        </div>
        <div>
          <div class="lead" style="font-weight: bold">Última actualización</div>
          <p>{{ lead.last_update.fecha }}</p>
        </div>

        <div class="ui button" (click)="go(lead)">GO!</div>
      </div>
    </div>
    <div class="text-center mt-4 mb-5" *ngIf="allOperations.length > 6">
      <pagination-controls
        responsive="true"
        previousLabel="Anterior"
        nextLabel="Siguiente"
        (pageChange)="p = $event"
        class="float-right"
      ></pagination-controls>
    </div>
    <div *ngIf="allOperations.length == 0">
      <p class="text-center">No hay datos por el momento.</p>
    </div>
  </div>

  <div class="table-responsive-sm" *ngIf="isFullAdmin">
    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th scope="col">ID</th>
          <th class="large" scope="col">Nombre</th>
          <th class="big" scope="col">Fecha de creación</th>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody *ngIf="todo.length > 0">
        <tr
          *ngFor="
            let lead of todo | paginate : { itemsPerPage: 8, currentPage: p };
            let i = index
          "
        >
          <th scope="row">{{ lead.id }}</th>
          <td class="large">
            {{ lead.cliente.nombre | titlecase }}
            {{ lead.cliente.apellido | titlecase }}
          </td>
          <td class="big">{{ lead.cliente.fecha }}</td>
          <!-- <td>{{ lead.last_update.fecha }}</td> -->
          <td>
            <button
              class="ui primary button mt-2"
              (click)="payConfirmation(lead, index)"
            >
              Confirmar pago inicial
            </button>
            <button class="ui primary button mt-2" (click)="seeRegistry(lead)">
              Ver registro
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-center mt-4 mb-5" *ngIf="todo.length > 8">
      <pagination-controls
        responsive="true"
        previousLabel="Anterior"
        nextLabel="Siguiente"
        (pageChange)="p = $event"
        class="float-right"
      ></pagination-controls>
    </div>
    <div *ngIf="todo.length == 0">
      <p class="text-center">No hay datos por el momento.</p>
    </div>
    <table class="table mt-5">
      <thead class="thead-dark">
        <tr>
          <th scope="col">ID</th>
          <th class="large" scope="col">Nombre</th>
          <th class="big" scope="col">Fecha de creación</th>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody *ngIf="operations && operations.length > 0">
        <tr
          *ngFor="
            let operation of operations
              | paginate : { itemsPerPage: 8, currentPage: p_u }
          "
        >
          <th scope="row">{{ operation.id }}</th>
          <td class="large">
            {{ operation.cliente.nombre | titlecase }}
            {{ operation.cliente.apellido | titlecase }}
          </td>
          <td class="big">{{ operation.cliente.fecha }}</td>
          <td>
            <div>
              <button
                *ngIf="operation.positive.progress == 'DATA-COLLECT'"
                class="ui primary button mt-2"
                (click)="dataCollectConfirmation(operation)"
                style="width: 50%"
              >
                Confirmar recolección de datos
              </button>
              <button
                *ngIf="operation.positive.progress == 'DATA-ANALYZING'"
                class="ui primary button mt-2"
                style="width: 50%"
                (click)="dataAnalyzing(operation)"
              >
                Confirmar análisis de datos
              </button>
              <button
                *ngIf="operation.positive.progress == 'DATA-VALIDATING'"
                class="ui primary button mt-2"
                style="width: 50%"
                (click)="dataValidating(operation)"
              >
                Confirmar validación de datos
              </button>
              <div
                *ngIf="
                  operation.positive.dataClientComplete &&
                  operation.positive.uploadedClientFiles
                "
              >
                <button
                  *ngIf="operation.positive.progress == 'PLACING-LEASE'"
                  class="ui primary button mt-2"
                  style="width: 50%"
                  (click)="placing(operation)"
                >
                  PLACING-LEASE
                </button>
                <button
                  *ngIf="operation.positive.progress == 'PLACING-SALE'"
                  class="ui primary button mt-2"
                  style="width: 50%"
                  (click)="placing(operation)"
                >
                  PLACING-SALE
                </button>
              </div>

              <div
                *ngIf="
                  operation.positive.progress == 'SALE-PLACED' ||
                  operation.positive.progress == 'LEASE-PLACED'
                "
              >
                <button
                  *ngIf="operation.positive.progress == 'LEASE-PLACED'"
                  style="width: 50%"
                  class="ui primary button mt-2"
                  (click)="finish(operation)"
                >
                  LEASE-PLACED
                </button>
                <button
                  *ngIf="operation.positive.progress == 'SALE-PLACED'"
                  style="width: 50%"
                  class="ui primary button mt-2"
                  (click)="finish(operation)"
                >
                  SALE-PLACED
                </button>
              </div>
            </div>
            <button
              class="ui button"
              style="width: 50%"
              (click)="seeRegistry(operation)"
            >
              Ver registro
            </button>
            <div
              *ngIf="
                operation.positive.progress == 'PLACING-SALE' ||
                operation.positive.progress == 'PLACING-LEASE'
              "
            >
              <button
                *ngIf="
                  operation.positive.dataClientComplete &&
                  !operation.positive.uploadedClientFiles
                "
                style="width: 50%"
                class="ui primary button mt-2"
                (click)="uploadDocumentationClient(operation)"
              >
                Subir documentación del cliente
              </button>
              <button
                *ngIf="
                  !operation.positive.dataClientComplete &&
                  !operation.positive.uploadedClientFiles
                "
                style="width: 50%"
                class="ui primary button mt-2"
                (click)="addClient(operation)"
              >
                Agregar cliente
              </button>
              <button
                *ngIf="
                  operation.positive.dataClientComplete &&
                  operation.positive.uploadedClientFiles &&
                  !operation.positive.dataAvalComplete &&
                  !operation.positive.documentationAvalComplete
                "
                style="width: 50%"
                class="ui primary button mt-2"
                (click)="addAval(operation)"
              >
                Agregar AVAL
              </button>

              <button
                *ngIf="
                  operation.positive.dataClientComplete &&
                  operation.positive.uploadedClientFiles &&
                  operation.positive.dataAvalComplete &&
                  !operation.positive.documentationAvalComplete
                "
                style="width: 50%"
                class="ui primary button mt-2"
                (click)="uploadDocumentationAval(operation)"
              >
                Subir documentación del aval
              </button>
            </div>

            <ng-template #noEnabledButton>
              <button class="ui button" disabled>Agregar cliente</button>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-center mt-4 mb-5" *ngIf="operations.length > 8">
      <pagination-controls
        responsive="true"
        previousLabel="Anterior"
        nextLabel="Siguiente"
        (pageChange)="p_u = $event"
        class="float-right"
      ></pagination-controls>
    </div>
  </div>
</div>
<ng-template #modalRef>
  <div class="main">
    <div class="container-modal">
      <modal-content>
        <h5 class="lead">No se ha agregado el beneficiario</h5>
      </modal-content>
      <modal-footer class="btn-options">
        <button
          class="ui button"
          (click)="closeModalUpdate()"
          style="margin-right: 5px"
        >
          Cerrar
        </button>
        <button class="ui button" (click)="addDataBank()">Agregar</button>
      </modal-footer>
    </div>
  </div>
</ng-template>
