import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IngresaRoutingModule } from './ingresa-routing.module';
import { TipoComponent } from './tipo/tipo.component';
import { IngresaComponent } from './ingresa/ingresa.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductosComponent } from './productos/productos.component';
import { RegistroClienteComponent } from './registro-cliente/registro-cliente.component';
import { IdentificacionComponent } from './identificacion/identificacion.component';
import { SubirFotoComponent } from './subir-foto/subir-foto.component';

import { ArticuloComponent } from './articulo/articulo.component';
import { PdfComponent } from './pdf/pdf.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { DataDbService } from 'src/app/SERVICIOS/data-db.service';
import { FilesDirective } from 'src/app/SERVICIOS/DIRECTIVES/files.directive';


@NgModule({
  declarations: [TipoComponent, FilesDirective, IngresaComponent, ProductosComponent,
    RegistroClienteComponent, IdentificacionComponent, SubirFotoComponent,
    ArticuloComponent, PdfComponent,],
  imports: [
    CommonModule,
    IngresaRoutingModule, BrowserAnimationsModule, ReactiveFormsModule, FormsModule
  ],
  providers: [DataDbService]
})
export class IngresaModule { }
