<div class="main">
    <div class=" mt-4 mb-5">
        <h2 class="title">Filtros</h2>
    </div>
    <div class="container">
        <div class="">
            <label for="rg_precio" class="form-label">Precio</label>
            <input type="range" (input)="getPrecio($event)" class="form-range" id="rg_precio" min="0" max="54"
                value="0">
            <label for="rg_precio" class="form-label">{{rango_precio}}</label>
        </div>
        <div class="">
            <label for="rg_superficie" class="form-label">Superficie</label>
            <input type="range" (change)="getSuperficie($event)" class="form-range" id="rg_superficie" min="0" max="54"
                value="0">
            <label for="rg_superficie" class="form-label">{{rango_superficie}}</label>
        </div>

        <label class="form-label mt-4">Características</label>
        <ul class="ks-cboxtags">

            <li *ngFor="let caracteristica of features; let i = index">
                <input (input)="selected(i)" id="{{ 'caracteritica-' + caracteristica.id }}"
                    [checked]="caracteristica.seleccionado" type="checkbox" />
                <label for="{{ 'caracteritica-' + caracteristica.id }}">
                    {{ caracteristica.descripcion }}
                </label>
            </li>

        </ul>




        <button class="ui primary button mt-5" (click)="onSubmit()">Continuar</button>
    </div>

</div>