<div class="main">
  <div class="image">
    <div class="mt-4 mb-5">
      <h2 class="title">Ingresa foto de la identificación</h2>
    </div>
    <div class="mb-5">
      <div class="subtitle mb-3">
        <h4>Selecciona una opción</h4>
      </div>
      <div class="btn-group table-responsive" role="group" aria-label="Basic radio toggle button group">
        <!-- <form [formGroup]="identificationForm" (ngSubmit)="onSaveForm()"> -->
        <input type="radio" class="btn-check" value="INE" [(ngModel)]="tipo" name="tipo" id="btnradio1"
          autocomplete="off" [checked]="identification == 'INE'">
        <label class="btn btn-outline-primary" for="btnradio1">INE</label>

        <input type="radio" class="btn-check" name="tipo" value="IFE" [(ngModel)]="tipo" id="btnradio2"
          autocomplete="off" [checked]="identification == 'IFE'">
        <label class="btn btn-outline-primary" for="btnradio2">IFE</label>

        <input type="radio" class="btn-check" name="tipo" value="Pasaporte" [(ngModel)]="tipo" id="btnradio3"
          autocomplete="off" [checked]="identification == 'Pasaporte'">
        <label class="btn btn-outline-primary" for="btnradio3">Pasaporte</label>

        <input type="radio" class="btn-check" name="tipo" value="Cartilla militar" [(ngModel)]="tipo" id="btnradio4"
          autocomplete="off" [checked]="identification == 'Cartilla militar'">
        <label class="btn btn-outline-primary" for="btnradio4">Cartilla militar</label>

        <input type="radio" class="btn-check" name="tipo" value="Cédula profesional" [(ngModel)]="tipo" id="btnradio5"
          autocomplete="off" [checked]="identification == 'Cédula profesional'">
        <label class="btn btn-outline-primary" for="btnradio5">Cédula profesional</label>

        <input type="radio" class="btn-check" name="tipo" value="INM" [(ngModel)]="tipo" id="btnradio6"
          autocomplete="off" [checked]="identification == 'INM'">
        <label class="btn btn-outline-primary" for="btnradio6">INM</label>
        <!-- </form> -->
      </div>
      <div class="tags-info mb-3 mt-3">
        <div *ngIf="tipo == 'INE'">
          <i class="exclamation circle icon"></i>
          Es necesario subir una foto frontal y una trasera del INE
        </div>
        <div *ngIf="tipo == 'IFE'">
          <i class="exclamation circle icon"></i>
          Es necesario subir una foto frontal y una trasera del IFE
        </div>
      </div>
    </div>
    <label for="input-image" style="width: 100%; cursor: pointer;">
      <div class="well drop-zone file-over archivos" appFiles [imagenes]="archivos"
        (mouseSobre)="estaSobreElemento = $event " [ngClass]="{ 'file-over': estaSobreElemento }">
        <i class="huge upload icon"></i>
        <p>Arrasta la imagen o da click aquí para elegir</p>
      </div>
    </label>
    <div class="text-center mt-2">
      <input style="display: none;" type="file" id="input-image" appFiles (change)="onUpload($event)"
        accept=".jpg, .png, .jpeg, .jfif">

      <label for="input-image">
        <div class="ui button">
          Elegir imagen
        </div>
      </label>

    </div>
  </div>

  <div class="images-list" *ngIf="archivos.length > 0">
    <h3 class="text-center">Lista de Imagenes</h3>
    <table class="table">
      <thead class="thead=dark">
        <tr>
          <th class="text-center">Vista previa</th>
          <th class="text-center">Progreso</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let archivo of archivos; let i = index">
          <td>
            <img class="img-view" [src]="archivo.url" alt="">
            <p>{{archivo.imagen.nombreArchivo}}</p>
          </td>
          <td>
            <div class="progress">
              <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': archivo.imagen.progreso + '%' }"></div>
            </div>
          </td>
          <!-- <td class="img-ine">
            <button class="btn-delete" (click)="deleteImg(i)" style="position: absolute;">x</button>
            <img class="img-ine-view" [src]="archivo.url" alt="">
          </td>
          <td class="mini text-center">
            <p>{{archivo.imagen.nombreArchivo}}</p>
          </td>
          <td>
            <div class="progress centerText">
              <div class="progress-bar" role="progressbar" [ngStyle]="{'width': archivo.imagen.progreso + '%' }"></div>
            </div>
          </td> -->
        </tr>
      </tbody>
    </table>
    <div class="buttons mb-5">
      <button (click)="LimpiarArchivos()" [disabled]="archivos.length === 0" class="ui button">
        <i class="remove icon"></i>
        Limpiar</button>
      <button (click)="cargarImagenes()" [disabled]="archivos.length === 0" class=" ui green button">Enviar
        {{archivos.length}} foto(s)</button>
    </div>
  </div>
</div>
