import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/SERVICIOS/auth.service';
import { NotificationService } from 'src/app/SERVICIOS/notification.service';
import { NotificationsService } from 'src/app/SERVICIOS/socket/notifications.service';
import { SocketService } from 'src/app/SERVICIOS/socket/socket.service';

@Component({
  selector: 'app-generate-token',
  templateUrl: './generate-token.component.html',
  styleUrls: ['./generate-token.component.scss'],
})
export class GenerateTokenComponent implements OnInit {
  tokenInformation: any;
  idKA = '';
  seconds = 0;
  isAcceptedRequestKeyAccess: boolean = false;
  constructor(
    public authSvc: AuthService,
    private router: Router,
    public gonotification: NotificationService,
    public notification: NotificationsService,
    private socket: SocketService,
    public afs: AngularFirestore,
    private route: ActivatedRoute
  ) {

    this.recibirToken();
  }

  ngOnInit(): void {
    this.idKA = this.route.snapshot.paramMap.get('keyId');
    this.generateNewToken();
  }

  recibirToken() {
    this.socket.io.on('receive-tokens', (tokenData) => {
      this.tokenInformation = tokenData;
      this.count(this.tokenInformation.seconds);
    });
  }

  getAcceptedRequestKeyAccess() {
    this.socket.io.on('acceptedRequestKeyAccess', (accepted) => {
      // this.isAcceptedRequestKeyAccess = accepted
    });
  }

  count(getSeconds: number) {
    this.seconds = Math.round(getSeconds);
    let interval = setInterval(() => {
      this.seconds--;
      if (this.seconds == 0) {
        this.stopCount(interval);
      }
    }, 1000);
  }
  generateNewToken() {
    this.authSvc.getCurrentUser().then((user) => {
      let data = {
        uid: user.uid,
        idKA: this.idKA,
      };
      this.socket.io.emit('generate-token', data);
    });
  }

  stopCount(interval) {
    clearInterval(interval);
  }
}
