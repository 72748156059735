<div class="main">
    <div class="mt-4 mb-5">
        <h2 class="title">Crear lead</h2>

    </div>
    <div class="form-registra">
        <form [formGroup]="leadForm" (ngSubmit)="SaveForm()">
            <div class="subtitle mb-3">
                <h5>Contacto principal</h5>
            </div>

            <div class="row mb">
                <div class="col">
                    <div class="form-outline">
                        <input type="text" class="form-control" [ngClass]="{invalid: nombre.invalid && nombre.dirty  }"
                            formControlName="nombre" name="nombre" placeholder="Nombres" />
                    </div>
                </div>
                <div class="col">
                    <div class="form-outline">
                        <input type="text" class="form-control"
                            [ngClass]="{invalid: apellido.invalid && apellido.dirty}" formControlName="apellido"
                            name="apellido" placeholder="Apellidos" />
                    </div>
                </div>
                <div class="col">
                    <div class="form-outline">
                        <input type="text" class="form-control"
                            [ngClass]="{invalid: telefono.invalid && telefono.dirty}" formControlName="telefono"
                            name="telefono" placeholder="Teléfono" />
                    </div>
                </div>

                <div class="col">
                    <div class="form-outline">
                        <input type="text" [ngClass]="{invalid: correo.invalid && correo.dirty}" class="form-control"
                            formControlName="correo" name="correo" placeholder="Correo Electrónico (opcional)" />
                    </div>
                </div>
            </div>
            <div class="subtitle mb-3">
                <h5>Contacto adicional (opcional)</h5>
            </div>
            <div class="row mb">
                <div class="col">
                    <div class="form-outline">
                        <input type="text" class="form-control"
                            [ngClass]="{invalid: adicional_nombre.invalid && adicional_nombre.dirty}"
                            formControlName="adicional_nombre" name="adicional_nombre" placeholder="Nombres" />
                    </div>
                </div>
                <div class="col">
                    <div class="form-outline">
                        <input type="text" class="form-control"
                            [ngClass]="{invalid: adicional_apellido.invalid && adicional_apellido.dirty}"
                            formControlName="adicional_apellido" name="adicional_apellido" placeholder="Apellidos" />
                    </div>
                </div>
                <div class="col">
                    <div class="form-outline">
                        <input type="text" class="form-control"
                            [ngClass]="{invalid: adicional_telefono.invalid && adicional_telefono.dirty}"
                            formControlName="adicional_telefono" name="adicional_telefono" placeholder="Teléfono" />
                    </div>
                </div>
                <div class="col">
                    <div class="form-outline">
                        <input type="text" class="form-control"
                            [ngClass]="{invalid: adicional_correo.invalid && adicional_correo.dirty}"
                            formControlName="adicional_correo" name="adicional_correo"
                            placeholder="Correo Electrónico (opcional)" />
                    </div>
                </div>
            </div>
            <div class="subtitle mb-3">
                <h5>Comentario (opcional)</h5>
            </div>
            <div class="row mb">
                <div class="col">
                    <div class="form-outline">
                        <textarea formControlName="comentario" name="comentario" class="form-control"
                            [ngClass]="{invalid: comentarios.invalid && comentarios.dirty}" rows="4">
              </textarea>
                        <span class="text-center" *ngIf="comentarios.invalid" style="color: #d10707">*El máximo es de
                            {{lenghtComment}} carácteres.</span>
                    </div>
                </div>
            </div>
            <div class="text-center mb-4">
                <button class="ui button" [disabled]="leadForm.invalid">
                    Continuar
                </button>
            </div>



            <ng-template #showButton>
                <div class="text-center mb-4 mt-4">
                    <button (click)="saveDataAL()" [disabled]="avalForm.invalid" class="ui button">
                        Ir a gestiona
                    </button>
                </div>
            </ng-template>
        </form>
    </div>








    <ng-template #myModal>
        <div class="main">
            <div class="container-modal">
                <modal-content>
                    <h5 class="lead">El cliente ya ha sido ingresado</h5>
                </modal-content>
                <modal-footer class="btn-options">
                    <button class="ui button fs-mini" (click)="closeModal()">
                        De Acuerdo
                    </button>
                </modal-footer>
            </div>
        </div>
    </ng-template>
</div>