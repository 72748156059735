<div class="main">
    <div class="center">
        <div>
            <div class="title mb-4 text-center">
                <h1>Agregar Correo y contraseña</h1>
                <p class="lead">
                    Ingresa tu correo y contraseña para agregarlo a tu cuenta y poder iniciar sesión.
                </p>
            </div>
            <div class="inputs">
                <form [formGroup]="registerForm" (ngSubmit)="onRegister()">


                    <div class="row mb">
                        <div class="col">
                            <div class="form-outline">
                                <label for="inputEmail">Correo electrónico</label>
                                <input type="email"
                                    [ngClass]="{invalid:email.invalid && email.dirty, valid:!email.invalid}"
                                    id="inputEmail" class="form-control" formControlName="email" />
                            </div>
                        </div>
                    </div>
                    <div class="row mb">
                        <div class="col">
                            <div class="form-outline">
                                <label for="inputPassword">Contraseña</label>
                                <input type="password" class="form-control"
                                    [ngClass]="{invalid: same_password, valid:!same_password && (!password.invalid && !repeat_password.invalid)}"
                                    formControlName="password" />

                                <!-- [ngClass]="{'form-control':!same_password, 'input_invalid':same_password,
                  'input_valid':!same_password}" -->


                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <div class="form-outline">
                                <label for="inputPassword">Verificar contraseña</label>
                                <input type="password" class="form-control"
                                    [ngClass]="{invalid: same_password, valid:!same_password && (!password.invalid && !repeat_password.invalid)}"
                                    formControlName="repeat_password" />
                                <label for="inputPassword" *ngIf="same_password" style="color: red;">*Las contraseñas
                                    deben
                                    coincidir.</label>
                                <!-- [ngClass]="{'form-control':!same_password, 'input_invalid':same_password,
                  'input_valid':!same_password}" -->
                            </div>
                        </div>
                    </div>

                    <div class="buttons mb-4 text-center">
                        <button type="submit"
                            [disabled]="registerForm.invalid && (!password.invalid && !repeat_password.invalid)"
                            class="btn btn-dark">
                            Agregar correo y contraseña
                        </button>
                        <!--  -->
                    </div>
                    <!-- <div class="alert alert-danger" role="alert" *ngIf="invalid">
              {{ message_error }}
            </div> -->
                </form>
            </div>
        </div>
        <div class="alerts mt-2">
            <div class="alert alert-danger" role="alert" *ngIf="codeError">
                <h6 class="mt-2">{{messageError}}</h6>
            </div>
        </div>

    </div>
    <!-- alertas -->
</div>