import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/SERVICIOS/auth.service";
import { User } from "../../MODULOS/user.interface";
import moment from "moment";
import { GenerateIdService } from "src/app/SERVICIOS/generate-id.service";
import { SocketService } from "src/app/SERVICIOS/socket/socket.service";
import { Location } from '@angular/common';

@Component({
  selector: "app-resume",
  templateUrl: "./resume.component.html",
  styleUrls: ["./resume.component.scss"],
})
export class ResumeComponent implements OnInit {
  idR: string;
  user: User;
  idAgent: string;
  leadData: any;
  agentData: any;

  constructor(
    private afs: AngularFirestore,
    private router: Router,
    public auth: AuthService, private _location: Location,
    private route: ActivatedRoute, private generateId: GenerateIdService, private socket: SocketService,

  ) { }

  ngOnInit(): void {
    this.idR = this.route.snapshot.paramMap.get("idLead");

    this.idAgent = this.route.snapshot.paramMap.get("idAgent");

    this.afs.collection("LEAD").doc(this.idR).valueChanges().subscribe(
      (lead: any) => {
        this.leadData = lead;
      },
    );
    this.afs.collection("USERS").doc(this.idAgent).valueChanges().subscribe(
      (agent: any) => {
        this.agentData = agent;
      },
    );
  }
  finish() {
    this.sendRecentActivity();
    this.router.navigate(["/markting"]);
  }

  back() {
    this._location.back();
  }

  sendRecentActivity() {
    let setRecentActivity = {
      date: moment().format(),

      homeTitle: "Se te ha asignado un lead",
      generalTitle:
        "Se te ha asignado un lead con nombre " + this.leadData.cliente.nombre + " " + this.leadData.cliente.apellido,
      responses: true,
      description:
        "El lead con nombre " + this.leadData.cliente.nombre + " " + this.leadData.cliente.apellido + " se te ha asignado, puedes verlo en la sección de leads",
      from: "leads",
      subtitle: "Se te ha asignado un lead",
      id: this.generateId.createId(),
      archived: false,
      important: false,
      id_document_component: this.leadData.id_document,
      isError: false,
      canUploadData: false,
      view: false,
      uid_user: this.agentData.uid,
      type: "observation",
      canReply: false,
      isFullview: true,
    };
    this.socket.io.emit("recent-activity", {
      recentActivity: setRecentActivity,
      uid: this.agentData.uid,
    });
  }
}
