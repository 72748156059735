import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanEnterGuard } from 'src/app/SERVICIOS/can/can-enter.guard';
import { AuthGuard } from 'src/app/SERVICIOS/guards/auth.guard';
import { CommunityManageGuard } from 'src/app/SERVICIOS/guards/community-manage.guard';
import { DisabledGuard } from 'src/app/SERVICIOS/guards/disabled.guard';
import { SupportGuard } from 'src/app/SERVICIOS/guards/support.guard';
import { LeaseListFullviewComponent } from './lease-list-fullview/lease-list-fullview.component';
import { LeaseListComponent } from './lease-list/lease-list.component';
import { LeaseComponent } from './lease.component';


const routes: Routes = [
    {
        path: 'lease-list', component: LeaseComponent, children: [
            { path: 'list', component: LeaseListComponent },
            { path: ':idDocument/:idlist/full-view/:fromCustomer', component: LeaseListFullviewComponent },



        ], canActivate: [CanEnterGuard, AuthGuard, DisabledGuard, SupportGuard, CommunityManageGuard]
    }


];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LeaseRoutingModule { }
