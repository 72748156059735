import { Component, OnInit, ViewChild } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";
import { take } from "rxjs/operators";
import { GenerateIdService } from "src/app/SERVICIOS/generate-id.service";
import { ClienteReg } from "../../MODULOS/registra/cliente";
import { AuthService } from "./../../../SERVICIOS/auth.service";
import { RecentActivityInterface } from "src/app/interfaces/activity";

@Component({
  selector: "app-lead-data",
  templateUrl: "./lead-data.component.html",
  styleUrls: ["./lead-data.component.scss"],
})
export class LeadDataComponent implements OnInit {
  public leadForm: UntypedFormGroup;
  public type: any;
  public id: any;
  uid_user: string;

  @ViewChild("myModal")
  myModal;
  modalRef;
  constructor(
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    public modal: NgbModal,
    private generateId: GenerateIdService,
    private router: Router,
    public AuthService: AuthService,
  ) {
    this.AuthService.getCurrentUser().then((values) => {
      if (values !== null) {
        this.uid_user = values.uid;
      }
    });
    this.leadForm = this.createFormGroup();
    this.type = this.route.snapshot.paramMap.get("isSale");
  }
  createFormGroup() {
    let emailPattern: any =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return new UntypedFormGroup({
      nombre: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
      ]),
      apellido: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
      ]),
      telefono: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(10),
      ]),
      correo: new UntypedFormControl("", [Validators.pattern(emailPattern)]),

      adicional_nombre: new UntypedFormControl("", [Validators.minLength(2)]),
      adicional_apellido: new UntypedFormControl("", [Validators.minLength(2)]),
      adicional_telefono: new UntypedFormControl("", [Validators.minLength(6)]),
      adicional_correo: new UntypedFormControl("", [
        Validators.pattern(emailPattern),
      ]),

      comentario: new UntypedFormControl("", [
        Validators.maxLength(5000),
      ]),
    });
  }
  lenghtComment(lenghtComment: any): import("@angular/forms").ValidatorFn {
    throw new Error("Method not implemented.");
  }
  ngOnInit(): void {
  }

  SaveForm() {
    const {
      adicional_nombre,
      adicional_apellido,
      adicional_correo,
      adicional_telefono,
    } = this.leadForm.value;
    if (
      adicional_nombre !== "" &&
      adicional_apellido == "" &&
      adicional_correo == "" &&
      adicional_telefono == ""
    ) {
      alert(
        "Debe llenar todos los campos del contacto adicional o dejarlos todos vacios",
      );
    } else if (
      adicional_nombre == "" &&
      adicional_apellido !== "" &&
      adicional_correo == "" &&
      adicional_telefono == ""
    ) {
      alert(
        "Debe llenar todos los campos del contacto adicional o dejarlos todos vacios",
      );
    } else if (
      adicional_nombre == "" &&
      adicional_apellido == "" &&
      adicional_correo !== "" &&
      adicional_telefono == ""
    ) {
      alert(
        "Debe llenar todos los campos del contacto adicional o dejarlos todos vacios",
      );
    } else if (
      adicional_nombre == "" &&
      adicional_apellido == "" &&
      adicional_correo == "" &&
      adicional_telefono !== ""
    ) {
      alert(
        "Debe llenar todos los campos del contacto adicional o dejarlos todos vacios",
      );
    } else {
      // if (selectedValuesToSearch.length == 0) alert('Debe seleccionar al menos una caracteristica')
      this.checkCustomer();
    }
  }
  checkCustomer() {
    let telefono = this.leadForm.value.telefono;
    let newTelefono = telefono.split(/\D/).join("");

    this.afs
      .collection(
        "LEAD",
        (ref) => ref.where("cliente.telefono", "==", newTelefono).limit(1),
      )
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((res: any[]) => {
        if (res.length > 0) {
          this.openModal();
        } else {
          // Buscar si el cliente ya existe por nombre y apellido
          let nombre = String(this.leadForm.value.nombre).toLowerCase();
          let apellido = String(this.leadForm.value.apellido).toLowerCase();
          this.afs
            .collection("LEAD", (ref) =>
              ref
                .where("cliente.nombre", "==", nombre)
                .where("cliente.apellido", "==", apellido)
                .limit(1))
            .valueChanges()
            .pipe(take(1))
            .toPromise()
            .then((res: any[]) => {
              if (res.length > 0) {
                this.openModal();
              } else {
                this.saveCustomer(newTelefono, nombre, apellido);
              }
            });
        }
      });
  }
  saveCustomer(telefono: number, nombre: string, apellido: string) {
    // let selectedValuesToSearch = []
    // selectedValuesToSearch = this.caracteristicas.value.filter(c => c.seleccionado === true);

    const { comentario } = this.leadForm.value;
    let objeto = {
      comentario: comentario,
      date: moment().format("YYYY-MM-DD"),
      hora: moment().format("HH:mm:ss"),
      fecha: moment().format("YYYY-MM-DD "),
    };
    let last_update = {
      date: moment().format("YYYY-MM-DD"),
      hora: moment().format("HH:mm:ss"),
      fecha: moment().format("YYYY-MM-DD "),
    };
    this.leadForm.value.telefono = telefono;
    this.leadForm.value.nombre = nombre;
    this.leadForm.value.apellido = apellido;
    this.id = Number(this.generateId.createId());

    this.saveClienteReg(
      this.leadForm.value,
      last_update,
      objeto,
      this.id,
      this.router.url,
    );
    this.leadForm.reset();
  }
  saveClienteReg(
    cliente: ClienteReg,
    last_update,
    comment,
    id,
    route_url: string,
  ): void {
    this.afs
      .collection("LEAD")
      .add({
        cliente,
        id: String(id),
        type: this.type == "0" ? "sale" : "rent",
        cuarentena: false,
        uid_user: this.uid_user,
        last_update,
        mostrar: true,
        estatus: "incompleto",
        completo: false,
        createdByAdmin: true,
        uid_admin: this.uid_user,
      })
      .then(async (docRef) => {
        this.afs
          .collection(
            "LEAD",
            (ref) => ref.where("uid_user", "==", this.uid_user),
          )
          .get()
          .pipe(take(1))
          .toPromise()
          .then((results) => {
            if (results.docs.length <= 1) {
              //this.socket.io.emit("firts-lead", this.uid_user);
            }

            //creamos un registro de actividad
            let setRecentActivity = {
              date: moment().format(),

              homeTitle: "Haz creado tu primer lead",
              generalTitle: "Haz creado tu primer lead",
              responses: false,

              description: "Haz creado tu primer lead",
              id: this.generateId.createId(),
              archived: false,
              important: false,
              id_document_component: docRef.id,
              routeObservation: route_url,
              isError: false,
              canUploadData: false,
              view: false,
              component: "leads",
              uid_user: this.uid_user,
              type: "activity",
              isFullview: true,
              canReply: false,
            };
            //this.gra.GenerateRecentActivity(setRecentActivity, this.uid_user);
          });
        let id = await this.AuthService.getDataUser(this.uid_user);

        //  this.Notification.leadIncomplete(false, id, this.uid_user, docRef.id);
        this.afs
          .collection("LEAD")
          .doc(docRef.id)
          .update({ id_document: docRef.id });
        this.afs
          .collection("LEAD")
          .doc(docRef.id)
          .collection("LEAD-COMMENT")
          .add({
            id_document: docRef.id,
            comentario: comment,
            date: moment().format(),
            fecha: moment().format("DD-MM-YYYY"),
            hora: moment().format("hh:mm:ss a"),
            id_user: id,
          });

        if (this.type === "0") {
        } else if (this.type === "1") {
          //ir a filtros
          this.router.navigate(["markting", docRef.id, "filters"]);
        }
      });
  }

  openModal() {
    this.modalRef = this.modal.open(this.myModal, {
      size: "sm",
      animation: true,
      centered: true,
      backdrop: true,
    });
  }
  closeModal() {
    this.modalRef = this.modal.dismissAll();
  }

  get nombre() {
    return this.leadForm.get("nombre");
  }
  get apellido() {
    return this.leadForm.get("apellido");
  }
  get correo() {
    return this.leadForm.get("correo");
  }
  get telefono() {
    return this.leadForm.get("telefono");
  }
  get adicional_nombre() {
    return this.leadForm.get("adicional_nombre");
  }
  get adicional_apellido() {
    return this.leadForm.get("adicional_apellido");
  }
  get adicional_telefono() {
    return this.leadForm.get("adicional_telefono");
  }
  get adicional_correo() {
    return this.leadForm.get("adicional_correo");
  }
  get comentarios() {
    return this.leadForm.get("comentario");
  }
}
