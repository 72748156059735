import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { DataDbService } from './data-db.service';
import { SocketService } from './socket/socket.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  uid_user: string;

  constructor(
    private authsv: AuthService,
    private afs: AngularFirestore,
    private router: Router,
    private dbdata: DataDbService,
    private Socket: SocketService
  ) {
    this.authsv.getCurrentUser().then((value) => {
      if (value) this.uid_user = value.uid;
    });
  }
  goNotification(notification: any) {
    this.setRead(notification);

    if (notification.type == "recent-activity" || notification.type == "observation") {
      //this.router.navigate([notification.type.description]);

      this.router.navigate(["historys/recent-activity-fullview/", notification.id_recent_activity]);

    } else
      if (notification.type == "normal") {
        this.router.navigate([notification.route]);
      }
    if (notification.module == "recent-activity") {
      this.router.navigate(["historys/recent-activity-fullview/", notification.id_document]);
    }
    if (notification.type == 'new-article') {
      this.router.navigate([
        `registra/${notification.description}/item-view/article-listing`,
      ]);
    } else if (notification.type == 'history') {
      if (notification.module == 'ingresa') {
        let data = notification.description;
        this.router.navigate(['historys', 'ingresa-history', data, 'fullview']);
      }
    } else if (notification.type == 'product') {
      let id = notification.description;
      this.afs
        .collection('CUSTOMER', (ref) => ref.where('id_cliente', '==', id))
        .valueChanges()
        .pipe(take(1))
        .toPromise()
        .then((data: any[]) => {
          this.router.navigate([
            'historys',
            'ingresa-history',
            data[0].id_document,
            'fullview',
          ]);
        });
    } else if (notification.type == 'support') {
      this.afs
        .doc(
          'USERS/' +
          notification.description.id_user +
          '/SUPPORT_CHATS/' +
          notification.description.id_document
        )
        .valueChanges()
        .pipe(take(1))
        .toPromise()
        .then((values: any) => {
          if (values.tomado == true) alert('el asunto ya se ha tomado');
          else {
            let uid_user = notification.description.id_user;
            let id_document = notification.description.id_document;
            this.afs
              .doc('USERS/' + uid_user + '/SUPPORT_CHATS/' + id_document)
              .update({
                tomado: true,
                user_support: this.uid_user,
                activo_bubble: true,
                activo: false,
              })
              .then((e) => {
                this.Socket.io.emit(
                  'acceptSupport',
                  notification.description.id_user
                );
              });
          }
        });
    } else if (notification.type == 'take_up_case') {
      this.router.navigate(['support']);
    } else if (notification.type == 'advance-information-article') {
      this.router.navigate([
        'historys/ingresa-history/' + notification.description + '/advance',
      ]);
    } else if (notification.type == 'incomplete') {
      if (notification.module == 'ingresa') {
        let id = notification.description.id_document;
        this.dbdata.col$('CUSTOMER', (ref) => this.querysCustomer(ref, id));
      } else if (notification.module == 'registra') {
        let id = notification.description.id_document;
        this.dbdata.col$('LEAD', (ref) => this.querysLead(ref, id));
      }
    } else if (notification.type == 'cuarentine') {
      this.router.navigate([
        'article-cuarentine',
        notification.description.id_registra,
        notification.description.id_article,
      ]);
    } else if (notification.type == 'product_expired') {
      this.router.navigate([
        'historys',
        'ingresa-history',
        notification.description.id_document,
        'fullview',
      ]);
    } else if (notification.type == 'article-approve') {
      this.router.navigate([
        'historys',
        'ingresa-history',
        notification.description,
        'fullview',
      ]);
    } else if (notification.type == 'lead-positive') {
      this.router.navigate([
        '/operaciones/aprobaciones-gestiona/aprobaciones/',
        notification.description,
        'full-view',
      ]);
    } else if (notification.type == 'lead-status') {
      this.router.navigate(['gestiona/', notification.description, 'go']);
    } else if (notification.type == 'customer-update') {
      this.router.navigate([
        `operaciones/aprobaciones-ingresa/aprobaciones/${notification.description.id_document}/full-view/${notification.description.id_request}`,
      ]);
    } else if (notification.type == 'lead-update') {
      this.router.navigate([
        `operaciones/aprobaciones-registra/aprobaciones/${notification.description.id_document}/full-view/${notification.description.id_request}`,
      ]);
    } else if (notification.type == 'lead-negative') {
      this.router.navigate([
        'historys',
        'registra-history',
        notification.description,
        'fullview',
      ]);
    } else if (notification.type == 'send-notificacion') {
      this.router.navigate([
        'ingresa',
        notification.description,
        'subir-foto',
        'edit',
      ]);
    }
  }
  setRead(notification: any) {
    if (notification.read == false) {
      notification.read = true;


      // this.afs
      //   .doc(
      //     'NOTIFICATION/' + this.uid_user + '/NOTIFICATION/' + notification.id
      //   )
      //   .update({ notification });
    }
  }

  async querysCustomer(ref, id) {
    let sp = await ref.where('id_document', '==', String(id)).get();
    sp.forEach((doc) => {
      let data = doc.data();
      if (data.identificacion == undefined) {
        this.router.navigate(['ingresa', id, 'subir-foto']);
      } else if (data.urlImage == undefined) {
        this.router.navigate(['ingresa', id, 'subir-foto']);
      } else {
        // this.dbdata.col$('ARTICLE', ref => this.queryArticle(ref, id));
      }
    });
  }

  // async queryArticle(ref, id) {
  //   let sp = await ref.where('id_cliente', "==", String(id)).get();
  //   sp.forEach(doc => {
  //     let data = doc.data();

  //     if (data.commodity == undefined) {
  //       this.dbdata.id = id;
  //       this.router.navigate(['/ingresa/productos'])
  //     } else if (data.articulo == undefined) {
  //       this.dbdata.id = id;
  //       this.router.navigate(['/ingresa/articulo'])
  //     }
  //   });
  // }

  async querysLead(ref, id) {
    let sp = await ref.where('id_document', '==', String(id)).get();
    sp.forEach((doc) => {
      let data = doc.data();
      if (data.idArticulos == undefined) {
        this.router.navigate(['registra', id, 'info']);
      }
    });
  }
}
