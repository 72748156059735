import { Pipe, PipeTransform } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Pipe({
  name: 'income'
})
export class IncomePipe implements PipeTransform {

  articles = []
  users = []
  constructor(private afs: AngularFirestore) {
    this.afs.collection('ARTICLE').valueChanges().subscribe(async (articles: any) => {
      this.articles = await articles
    })
    this.afs.collection('USERS').valueChanges().subscribe(async (users: any) => {
      this.users = await users
    })

  }

  transform(value: any, arg: string, propName: any): any {
    const results = [];
    if (propName == undefined || (arg == '' || arg == undefined)) return value;
    else if (this.articles && this.users) {

      for (const item of value) {
        if (propName == "nombre") {
          if (item.cliente[propName].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(arg.toLowerCase()) > -1 ||
            item.cliente.apellido.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(arg.toLowerCase()) > -1) {
            results.push(item)
          }
        }
        else if (propName == 'uid_user') {
          if (arg) {
            this.afs.collection('USERS').valueChanges().subscribe((users: any) => {
              let res = users.find(user => user.id.indexOf(arg) > -1)
              if (res && res.uid == item.uid_user) results.push(item)
            })
          }

        }
        else if (propName == 'id_article') {
          if (arg) {
            this.afs.collection('ARTICLE').valueChanges().subscribe((articles: any) => {
              let res = articles.find(article => article.id_articulo.indexOf(arg) > -1)
              if (res.id_document_customer == item.id_document) results.push(item)
            })
          }

          // if (this.articles !== []) {
          // let res = this.articles.find(article => article.id_articulo.indexOf(arg) > -1)
          // if (res.id_document_customer == item.id_document) results.push(item)
          // }else {
          //   console.log('no hay');

          // }

        }
        else if (propName == "habilitado" || propName == "en espera") {
          if (item.estatus == propName) results.push(item);

        }
        else if (propName == "deshabilitado") {
          if (item.estatus == "deshabilitado" || item.estatus == "denegado") results.push(item)
        }

        else if (propName == "id_cliente") {
          if (item[propName].toLowerCase().indexOf(arg.toLowerCase()) > -1) results.push(item)
        }
      };

    }
    return results;
  }




}
