import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/SERVICIOS/auth.service';
import { User } from '../../MODULOS/user.interface';

@Component({
  selector: 'app-registra-history',
  templateUrl: './registra-history.component.html',
  styleUrls: ['./registra-history.component.scss'],
})
export class RegistraHistoryComponent implements OnInit {
  public my_lead = [];
  m_p: number = 0;
  p: number = 0;
  public all_lead = [];
  leadTodo: any;
  text_input: string;
  disabledInput: boolean = true;
  text_inputu: string;
  disabledInputu: boolean = true;
  user: User;
  public filterPost = '';
  public filterPostu = '';
  valueName: string;
  valueNameu: string;
  ShowDataLead: boolean = false;
  constructor(
    public afs: AngularFirestore,
    private router: Router,
    public auth: AuthService
  ) {
    this.auth.user$
      .pipe(take(1))
      .toPromise()
      .then((user: User) => {
        this.user = user;
        this.afs
          .collection('LEAD'
          )
          .valueChanges()
          .pipe(take(1))
          .toPromise()
          .then((values: any) => {

            if (user.role == 'FULL-ADMIN' || user.role == 'ADMIN') {
              this.all_lead = values.filter(
                (lead) => lead.completo == true && lead.uid_user !== user.uid && lead.escalate != true
              );
            }
            if (user.role != 'FULL-ADMIN') {
              this.my_lead = values.filter(
                (lead) => lead.uid_user == user.uid && lead.escalate != true
              );
            }


            // if (!auth.userFullAdmin(user)) {
            //   this.my_lead = values.filter(
            //     (lead) => lead.uid_user == user.uid && lead.escalate != true
            //   );
            // } else {
            //   this.my_lead = values.filter(
            //     (lead) => lead.uid_admin == user.uid && lead.escalate != true && lead.createdByAdmin
            //   );
            // }



          });
        // if (user.role == 'FULL-ADMIN' || user.role == 'ADMIN') {
        //   this.afs
        //     .collection('LEAD')
        //     .valueChanges()
        //     .pipe(take(1))
        //     .toPromise()
        //     .then((values: any) => {
        // this.all_lead = values.filter(
        //   (lead) => lead.completo == true && lead.uid_user !== user.uid && lead.escalate != true
        // );
        // console.log(this.all_lead);

        //     });
        // }
      });
  }

  ngOnInit(): void {
    let comp = localStorage.getItem('component');
    if (comp == 'registra') {
      let valueName = localStorage.getItem('valueName');
      let filterPost = localStorage.getItem('filterPost');
      let disabledInput = localStorage.getItem('disabledInput');
      let page = localStorage.getItem('page');

      let valueNameu = localStorage.getItem('valueNameu');
      let filterPostu = localStorage.getItem('filterPostu');
      let disabledInputu = localStorage.getItem('disabledInputu');
      let pageu = localStorage.getItem('pageu');

      if (page !== null && page !== undefined && page !== '') {
        this.p = Number(page);
      } else {
        localStorage.setItem('page', '1');
      }
      if (pageu !== null && pageu !== undefined && pageu !== '') {
        this.m_p = Number(pageu);
      } else {
        localStorage.setItem('pageu', '1');
      }

      if (valueName !== null && valueName !== undefined && valueName !== '')
        this.valueName = valueName;
      if (valueNameu !== null && valueNameu !== undefined && valueNameu !== '')
        this.valueNameu = valueNameu;

      if (
        filterPost !== null &&
        filterPost !== undefined &&
        filterPost !== ''
      ) {
        this.filterPost = filterPost;
        this.text_input = filterPost;
      }
      if (
        filterPostu !== null &&
        filterPostu !== undefined &&
        filterPostu !== ''
      ) {
        this.filterPostu = filterPostu;
        this.text_inputu = filterPostu;
      }
      if (
        disabledInput !== null &&
        disabledInput !== undefined &&
        disabledInput !== ''
      ) {
        if (disabledInput == 'true') {
          this.disabledInput = true;
        } else if (disabledInput == 'false') {
          this.disabledInput = false;
        }
      }
      if (
        disabledInputu !== null &&
        disabledInputu !== undefined &&
        disabledInputu !== ''
      ) {
        if (disabledInputu == 'true') {
          this.disabledInputu = true;
        } else if (disabledInput == 'false') {
          this.disabledInputu = false;
        }
      }
    }
  }

  fullView(todo: { id_document: any }) {
    this.leadTodo = todo;
    this.ShowDataLead = true;

    this.router.navigate([
      'historys',
      'registra-history',
      todo.id_document,
      'fullview',
    ]);
  }
  filterWithSelectu(e) {
    localStorage.setItem('component', 'registra');

    if (e == '1') {
      this.valueNameu = 'idregister';
      this.disabledInputu = false;
      localStorage.setItem('disabledInputu', 'false');
      localStorage.setItem('valueNameu', this.valueNameu);
    } else if (e == '2') {
      this.valueNameu = 'name';
      this.disabledInputu = false;
      localStorage.setItem('disabledInputu', 'false');
      localStorage.setItem('valueNameu', this.valueNameu);
    } else if (e == '3') {
      this.valueNameu = 'uid_user';
      this.disabledInput = false;
      localStorage.setItem('disabledInputu', 'false');
      localStorage.setItem('valueNameu', this.valueNameu);
    }
  }

  changePage(e) {
    this.p = e;
    localStorage.setItem('page', e);
  }
  resetPage() {
    this.p = 1;
    localStorage.setItem('page', '1');
  }

  getText(e) {
    this.resetPage();
    localStorage.setItem('filterPost', e);
  }
  changePageu(e) {
    this.m_p = e;
    localStorage.setItem('pageu', e);
  }
  resetPageu() {
    this.p = 1;
    localStorage.setItem('pageu', '1');
  }

  getTextu(e) {
    localStorage.setItem('component', 'registra');

    this.resetPageu();
    localStorage.setItem('filterPostu', e);
  }

  filterWithSelect(e) {
    localStorage.setItem('component', 'registra');

    if (e == '1') {
      this.valueName = 'idregister';
      this.disabledInput = false;
      localStorage.setItem('disabledInput', 'false');
      localStorage.setItem('valueName', this.valueName);
    } else if (e == '2') {
      this.valueName = 'name';
      this.disabledInput = false;
      localStorage.setItem('disabledInput', 'false');
      localStorage.setItem('valueName', this.valueName);
    } else if (e == '3') {
      this.valueName = 'iduser';
      this.disabledInput = false;
      localStorage.setItem('disabledInput', 'false');
      localStorage.setItem('valueName', this.valueName);
    }
  }

  getPositiveArticles(lead) {
    if (
      lead.positive_or_negative == undefined ||
      lead.positive_or_negative == null
    )
      return 0;
    let leadRelation = lead.positive_or_negative;
    if (leadRelation.length > 0) {
      let count = 0;
      leadRelation.forEach((relation) => {
        if (relation.estatus == 'positivo') count++;
      });

      return count;
    }

    return 0;
  }
  getNegativeArticles(lead) {
    if (
      lead.positive_or_negative == undefined ||
      lead.positive_or_negative == null
    )
      return 0;
    let leadRelation = lead.positive_or_negative;
    if (leadRelation.length > 0) {
      let count = 0;
      leadRelation.forEach((relation) => {
        if (relation.estatus == 'negativo') count++;
      });

      return count;
    }

    return 0;
  }
  getRelation(lead) {
    if (
      lead.positive_or_negative == undefined ||
      lead.positive_or_negative == null
    )
      return 0;
    let leadRelation = lead.positive_or_negative;
    if (leadRelation.length > 0) {
      return leadRelation.length;
    }

    return 0;
  }
  // incompleteLead(todo){
  //   if(todo.idArticulos  == undefined){
  //     this.router.navigate(['registra',todo.id_document ,'info']);
  //   }
  // }

  complete(lead) {
    if (!this.auth.userFullAdmin(this.user)) {
      if (lead.tagsToSearch == undefined) {
        this.router.navigate(['registra', lead.id_document, 'features']);
      } else if (lead.idArticulos == undefined) {
        this.router.navigate(['registra', lead.id_document, 'info']);
      }
    } else {
      if (lead.tagsToSearch == undefined) {
        this.router.navigate(['markting', lead.id_document, 'filters']);
      } else if (lead.idArticulos == undefined) {
        this.router.navigate(['markting', lead.id_document, 'results']);
      } else if (lead.uid_user == undefined) {
        this.router.navigate(['markting', lead.id_document, 'agent-selection']);
      }
    }
  }
}
