<div class="main">
    <div class=" mt-5 mb-3">
        <h2 class="title">Caracteriza el inmueble</h2>
    </div>

    <div class="subtitle mt-5" style="display: flex;">
        <h5>Características del inmueble</h5>
        <div class="ui" data-tooltip="Elige las etiquetas que describan lo que quieres buscar"
            data-position="top center" data-variation="mini">
            <i class="ui question circle outline icon"
                style="margin-bottom: 17.5px; margin-left: 10px; cursor: pointer;"></i>
        </div>
    </div>
    <div class="box" *ngIf="contactForm">
        <!-- <form [formGroup]="contactForm" (ngSubmit)="SaveForm()">
            <ul class="ks-cboxtags" formArrayName="caracteristicas">
                <li *ngFor="
                      let caracteritica of caracteristicas.controls;
                      let i = index
                    " [formGroupName]="i">
                    <input id="{{
                        'caracteritica-' + caracteritica.controls['id'].value
                      }}" formControlName="seleccionado" type="checkbox"
                        [value]="caracteritica.controls['seleccionado'].value" />
                    <label for="{{
                        'caracteritica-' + caracteritica.controls['id'].value
                      }}">
                        {{ caracteritica.value.descripcion }}
                    </label>
                </li>
            </ul>
            <div class="text-center mb-4">
                <button *ngIf="!sendingDataToFirebase" class="ui button" [disabled]="contactForm.invalid">
                    Continuar
                </button>

            </div>
        </form> -->
        <ul class="ks-cboxtags">

            <li *ngFor="let caracteristica of features; let i = index">
                <input (input)="selected(i)" id="{{ 'caracteritica-' + caracteristica.id }}"
                    [checked]="caracteristica.seleccionado" type="checkbox" />
                <label for="{{ 'caracteritica-' + caracteristica.id }}">
                    {{ caracteristica.descripcion }}
                </label>
            </li>

        </ul>

        <div class="text-center mb-4">
            <button *ngIf="sendingDataToFirebase" class="ui loading button">
                Continuar
            </button>
            <button *ngIf="!sendingDataToFirebase" class="ui button" (click)="SaveForm()">
                Continuar
            </button>


        </div>
    </div>
</div>