<div class="main">
  <div class="header">
    <div class="buttons">
      <div class="card p-4">
        <div class="segment">
          <div class="ui two column stackable center aligned grid">
            <div class="ui vertical divider">O</div>
            <div class="middle aligned row">
              <div class="column">
                <div class="ui icon header">
                  <i class="pdf file outline icon"></i>
                  Ahora ya puedes descargar el documento.
                </div>
                <button style="position: static" class="ui positive button" (click)="search()">
                  Generar PDF
                </button>

              </div>
              <div class="column">
                <a routerLink="/ingresa" class="ui  button mb-3">Seguir ingresando</a>

                <a (click)="agregarArticulos()" class="ui  button mb-3">Agregar más artículos</a>
                <a routerLink="/pagina-principal" class="ui  button">Ir al menu principal</a>
              </div>
            </div>
          </div>
        </div>


        <!-- <button style="position: static" class="ui primary button" (click)="search()">
          Generar PDF
        </button> -->


      </div>
    </div>
  </div>
</div>