<div class="container">
  <div class="mt-4 mb-5">
    <h2 class="title ">Productos</h2>
  </div>
  <p>
    Selecciona el producto
  </p>

  <form [formGroup]="dealsForm" (ngSubmit)="onSaveForm()">
    <div class="cards">
      <div *ngFor="let product of products; let i = index;">
        <input type="radio" class="btn-check" [value]="product.nombre" formControlName="producto" name="producto"
          id="option{{i}}" autocomplete="off" />
        <label class="cardn card-label" for="option{{i}}">
          <div class="card-body">
            <div class="title">
              <h3>{{product.nombre | titlecase}}</h3>
              <hr />
            </div>
            <div class="text text-center">
              <p>{{product.descripcion}}</p>
              <p>Días de Exclusividad: {{product.dias}} días</p>
            </div>
          </div>


        </label>
      </div>






      <!-- <div class="card" *ngFor="let product of products; let i = index;">
        <div class="card-body">
          <div class="title">
            <h3>{{product.nombre | titlecase}}</h3>
            <hr />
          </div>
          <div class="text text-center">
           <p>{{product.descripcion}}</p>
           <p>Días de Exclusividad: {{product.dias}} días</p>
          </div>
          <input
            type="radio"
            class="btn-check"
            [value]="product.nombre"
            formControlName="producto"
            name="producto"
            id="option{{i}}"
            autocomplete="off"
          />
          <label class="btn btn-outline-secondary" for="option{{i}}">Seleccionar</label>
        </div>
      </div> -->
    </div>
    <div class="send mb-5 mt-3">
      <button (click)="onSaveForm()" [disabled]="producto.invalid" class="ui button">
        Continuar
      </button>
    </div>
  </form>
</div>