import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { User } from 'src/app/COMERCIAL/MODULOS/user.interface';
import { AuthService } from 'src/app/SERVICIOS/auth.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit, OnDestroy {
  public obtUsuarios = []
  subs: Subscription;
  user: User;
  p: number = 1;
  disabledInput: boolean = true;
  valueName: string;
  public filterPost = ''
  constructor(private afs: AngularFirestore, private router: Router, public auth: AuthService) {


  }

  ngOnInit(): void {
    let comp = localStorage.getItem('component')
    if (comp == 'users') {
      let valueName = localStorage.getItem('valueName')
      let filterPost = localStorage.getItem('filterPost')
      let disabledInput = localStorage.getItem('disabledInput')
      let page = localStorage.getItem('page')

      if (page !== null && page !== undefined && page !== '') { this.p = Number(page) } else { localStorage.setItem('page', '1') }

      if (valueName !== null && valueName !== undefined && valueName !== '') this.valueName = valueName

      if (filterPost !== null && filterPost !== undefined && filterPost !== '') {
        this.filterPost = filterPost
      }

      if (disabledInput !== null && disabledInput !== undefined && disabledInput !== '') {
        if (disabledInput == 'true') {
          this.disabledInput = true
        } else if (disabledInput == 'false') {
          this.disabledInput = false


        }
      }
    }


    this.auth.user$.pipe(take(1)).toPromise().then((user) => {
      this.user = user
      this.subs = this.afs.collection('USERS', ref => ref.where('uid', '!=', user.uid)).valueChanges().subscribe((values: any) => {
        let users = values.filter((user: any) => user.emailVerified == true || user.phoneVerified == true);
        this.obtUsuarios = users;
      });
    })
  }

  fullview(userData: any) {
    this.router.navigate(["profile", userData.uid]);
  }
  getText(e) {
    localStorage.setItem('component', 'users')

    localStorage.setItem('filterPost', e)

    this.resetPage()
  }
  changePage(e) {
    this.p = e
    localStorage.setItem('page', e)
  }
  resetPage() {
    this.p = 1;
    localStorage.setItem('page', '1')
  }
  filterWithSelect(e) {
    localStorage.setItem('component', 'users')

    if (e == '1') {
      this.valueName = "id";
      this.disabledInput = false
      localStorage.setItem('disabledInput', 'false');
      localStorage.setItem('valueName', this.valueName);

    } else if (e == '2') {
      this.valueName = "nombre";
      this.disabledInput = false
      localStorage.setItem('disabledInput', 'false');
      localStorage.setItem('valueName', this.valueName);
    }


  }
  ngOnDestroy(): void {
    if (this.subs !== undefined) this.subs.unsubscribe();

  }

}
