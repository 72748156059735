<div class="main ">
    <div class="mt-4 mb-5">
      <h2 class="title">
        Datos bancarios
      </h2>
    </div>
  
    <div class="form-ingresa">
      <form [formGroup]="bankForm" (ngSubmit)="SaveForm()">
  
  
        <div class="row mb">
          <div class="col">
            <div class="form-outline">
              <input type="text" id="name" [ngClass]="{invalid: nombre.invalid && nombre.dirty}" class="form-control"
                formControlName="nombre" name="nombre" placeholder="Nombre(s)" />
            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <input type="text" id="last_name" class="form-control"
                [ngClass]="{invalid: apellido.invalid && apellido.dirty}" formControlName="apellido" name="apellido"
                placeholder="Apellidos" />
            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <input type="text" id="institucion" class="form-control"
                [ngClass]="{invalid: institucion.invalid && institucion.dirty}" name="institucion"
                formControlName="institucion" placeholder="Institución bancaria" />
            </div>
          </div>
        </div>
  
        <div class="row mt-4">
          <div class="col">
            <div class="form-outline">
              <input type="text" id="num_cuenta" class="form-control"
                [ngClass]="{invalid: num_cuenta.invalid && num_cuenta.dirty}" formControlName="num_cuenta"
                name="num_cuenta" placeholder="Número de cuenta" />
            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <input type="text" id="clabe" class="form-control" [ngClass]="{invalid: clabe.invalid && clabe.dirty}"
                formControlName="clabe" name="clabe" placeholder="CLABE interbancaria" />
            </div>
          </div>
          <div class="col"></div>
          <div class="col"></div>
  
        </div>
        <div class="text-center mt-3 mb-3">
          <button class="ui button" [disabled]="bankForm.invalid">
            Continuar
          </button>
        </div>
      </form>
    </div>
  
  
  
    <ng-template #myModal>
      <div class="main">
        <div class="container-modal">
          <modal-content>
            <h5 class="lead text-center mt-3 mb-3">Este cliente ya está relacionado con otro usuario</h5>
          </modal-content>
          <modal-footer class="btn-options">
            <button class="ui button fs-mini" (click)="closeModal()">
              De Acuerdo
            </button>
          </modal-footer>
        </div>
      </div>
    </ng-template>
  </div>