import { Component, OnInit } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import { PdfMakeWrapper } from 'pdfmake-wrapper';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-leasing-contract',
  templateUrl: './leasing-contract.component.html',
  styleUrls: ['./leasing-contract.component.scss']
})
export class LeasingContractComponent implements OnInit {
  public bankForm: UntypedFormGroup;
  constructor() { }

  ngOnInit() {
    this.bankForm = this.createFormGroup();
  }


  createFormGroup() {

    return new UntypedFormGroup({
      nombre: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(2),
      ]),
      apellido: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(2),
      ]),
      institucion: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(2),
      ]),
      num_cuenta: new UntypedFormControl('', [
        Validators.minLength(6),
        Validators.maxLength(10),
      ]),
      clabe: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(10),
      ]),
    });
  }
  get nombre() {
    return this.bankForm.get('nombre');
  }
  get apellido() {
    return this.bankForm.get('apellido');
  }
  get institucion() {
    return this.bankForm.get('institucion');
  }
  get num_cuenta() {
    return this.bankForm.get('num_cuenta');
  }
  get clabe() {
    return this.bankForm.get('clabe');
  }

  SaveForm() { }
}
