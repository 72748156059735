import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { DataDbService } from "src/app/SERVICIOS/data-db.service";
import { ActivatedRoute, Router } from "@angular/router";
import { filter, take } from "rxjs/operators";
import { ProductsService } from "src/app/SERVICIOS/products.service";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { PdfService } from "src/app/SERVICIOS/pdf.service";
import { MapService } from "src/app/SERVICIOS/map.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/SERVICIOS/auth.service";
import firebase from "firebase/compat/app";
import { GetDataService } from "src/app/SERVICIOS/get-data.service";
import { GenerateIdService } from "src/app/SERVICIOS/generate-id.service";
import { SocketService } from "src/app/SERVICIOS/socket/socket.service";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { Location } from "@angular/common";
import { GetTokensService } from "src/app/SERVICIOS/get-tokens.service";
import { User } from "../../MODULOS/user.interface";
import { log } from "util";
import { RecentActivityInterface } from "src/app/interfaces/activity";

declare let $: any;

@Component({
  selector: "app-ingresa-history-fullview",
  templateUrl: "./ingresa-history-fullview.component.html",
  styleUrls: ["./ingresa-history-fullview.component.scss"],
})
export class IngresaHistoryFullviewComponent implements OnInit, OnDestroy {
  @ViewChild("myModal")
  myModal;
  @ViewChild("modal_update")
  modal_update;
  @ViewChild("modal_observation")
  modal_observation;
  @ViewChild("modalNoArticles")
  modalNoArticles;
  @ViewChild("modalNoOthersArticles")
  modalNoOthersArticles;
  @ViewChild("createObservation")
  createObservation;

  p: number = 1;
  modalRef;
  modalUpdateRef;
  modalObservation;
  public todo: any;
  public dif = 0;
  public product: any;
  public fecha_vencimiento: any;
  public difss = [];
  public duedates = [];
  public resultados = [];
  sameNumber: boolean = false;
  sameNames: boolean = false;
  allArticleComments = [];
  name: string;
  lastname: string;
  email: string;
  phone: string;
  pc: string;
  street: string;
  identification: string;
  noexterior: string;
  nointerior: string;
  colonia: string;
  state: string;
  country: string;
  add_name: string;
  add_lastname: string;
  add_email: string;
  add_phone: string;
  userForm: UntypedFormGroup;
  userAdForm: UntypedFormGroup;
  UpdateUserData: boolean = false;
  UpdateAdressClientData: boolean = false;
  user: User;
  ShowMoreCustomer: boolean = false;
  ShowMoreCustomerText: string = "Ver más";
  UpdateUserDataAdd: boolean = false;
  newDate = [];
  idC: string;
  cliente: any;
  citys: string;
  cp: any;
  showInputNeighborhood: boolean;
  neighborhood: any;
  city: any;
  myUid: string;
  myCustomer: boolean = false;
  estatus_customer: any;
  productSelected = [];
  hasIdArticulos: boolean = false;
  private emailPattern: any =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  id_documentSelected: any;
  count: number = 0;
  disabled_article: boolean = false;
  rejected_article: boolean = false;
  articlesPositives: number = 0;
  articlesNegatives: number = 0;
  articlesRelation: number = 0;
  argumentForm = new UntypedFormGroup({
    argument: new UntypedFormControl("", [
      Validators.required,
      Validators.maxLength(1000),
    ]),
  });
  observationForm = new UntypedFormGroup({
    observation: new UntypedFormControl("", [
      Validators.required,
      Validators.maxLength(1000),
    ]),
  });
  addContactAdicional: boolean = false;
  cuarentena = [];
  usersEncontrados: any[];
  subs: Subscription;
  subs_two: Subscription;
  txt_observation: string;
  agent: any;

  get argumento() {
    return this.argumentForm.get("argument");
  }
  get observation() {
    return this.observationForm.get("observation");
  }
  sendObservation() {
    let observation = this.observationForm.value.observation;
    let setRecentActivity = {
      date: moment().format(),

      homeTitle: "Tienes una nueva observación desde clientes",
      generalTitle: "Tienes una nueva observación desde clientes",
      responses: true,
      description: observation,
      id_document_component: this.idC,
      id: this.generateId.createId(),
      archived: false,
      important: false,
      routeObservation: "historys/ingresa-history/" + this.idC + "/fullview",
      isError: false,
      canUploadData: true,
      canReply: true,
      view: false,
      uid_user: this.todo.uid_user,
      type: "observation",
      from: "clientes",
      isFullview: true,
      uid_fullAdmin: this.user.uid,
    };
    this.socket.io.emit("recent-activity", {
      recentActivity: setRecentActivity,
      idFullAdmin: this.user.uid,
      uid: this.todo.uid_user,
    });

    $(".ui.basic.modal.createObservation").modal("hide");

    this.afs.collection("CUSTOMER").doc(this.idC).set({
      observation: true,
    }, { merge: true });
  }
  constructor(
    public afs: AngularFirestore,
    private router: Router,
    public dbData: DataDbService,
    private route: ActivatedRoute,
    private products: ProductsService,
    private generatePdf: PdfService,
    public modal: NgbModal,
    private mapSvc: MapService,
    public auth: AuthService,
    private get_data: GetDataService,
    private generate_id: GenerateIdService,
    private socket: SocketService,
    private generateId: GenerateIdService,
    private tokensUser: GetTokensService,
  ) {}

  ngOnInit() {
    this.idC = this.route.snapshot.paramMap.get("idCustomer");
    this.getArticle(this.idC);
    this.getAgent(this.idC);
    this.auth.user$
      .pipe(take(1))
      .toPromise()
      .then((user: User) => {
        this.user = user;
        this.myUid = user.uid;
        this.get_data.getCustomers(user.uid).then((results: any[]) => {
          let found = ([] = results.filter(
            (customer) => customer.id_document == this.idC,
          ));
          if (found.length == 0) {
            this.myCustomer = false;
          } else if (found.length > 0) {
            this.myCustomer = true;
            this.getdatacustomer();
          }
          if (this.auth.isAdmin(user) || this.auth.isFullAdmin(user)) {
            this.getdatacustomer();
          }
        });
      });
  }

  getArticle(idc) {
    this.subs_two = this.afs
      .collection(
        "ARTICLE",
        (ref) => ref.where("id_document_customer", "==", idc),
      )
      .valueChanges()
      .subscribe(async (articles) => {

        this.resultados = articles;
        let data = await this.products.dates(articles);
        this.difss = data.differences;
        this.duedates = data.due_dates;

        this.newDate = data.new_date;
        this.productSelected = data.product;
      });
  }
  goToOwnArticles() {
    if (this.getTotalArticles() == 0) {
      this.modalRef = this.modal.open(this.modalNoArticles, {
        size: "md",
        animation: true,
        centered: true,
        backdrop: true,
        scrollable: true,
      });
    } else {
      this.router.navigate([
        "historys",
        "ingresa-history",
        this.idC,
        "own-articles",
      ]);
    }
  }

  getTotalArticles() {
    return this.resultados.length;
  }
  getProductsArticles() {
    let articlesWithProducts = this.resultados.filter(
      (article) =>
        article.completado == true &&
        article.commodity.producto != "Sin producto asignado" &&
        article.commodity.estatus == "aprobado",
    );
    return articlesWithProducts.length;
  }
  getPositivesArticles() {
    let articlesWithProducts = this.resultados.filter(
      (article) =>
        article.completado == true &&
        article.commodity.producto != "Sin producto asignado" &&
        article.commodity.estatus == "aprobado",
    );
    return articlesWithProducts.length;
  }

  createAnObservation() {
    $(".ui.basic.modal.createObservation").modal("show");
  }
  goToOthersArticles() {
    if (this.articlesRelation == 0 && this.hasIdArticulos == false) {
      this.modalRef = this.modal.open(this.modalNoOthersArticles, {
        size: "md",
        animation: true,
        centered: true,
        backdrop: true,
        scrollable: true,
      });
    } else {
      this.router.navigate([
        "historys",
        "ingresa-history",
        this.idC,
        "others-articles",
      ]);
    }
  }

  getOthersArticles(idArticles: Array<any>, positive_or_negative: Array<any>) {

    if (idArticles.length > 0) {
      this.hasIdArticulos = true;
    }
    if (positive_or_negative.length > 0) {
      this.articlesRelation = idArticles.length + positive_or_negative.length;
      this.articlesPositives = positive_or_negative.filter(
        (article) => article.estatus == "positivo",
      ).length;
      this.articlesNegatives = positive_or_negative.filter(
        (article) => article.estatus == "negativo",
      ).length;
    } else {
      this.articlesRelation = 0;
      this.articlesPositives = 0;
      this.articlesNegatives = 0;
    }
  }

  getdatacustomer() {
    this.subs = this.afs
      .collection("CUSTOMER")
      .doc(String(this.idC))
      .valueChanges()
      .subscribe((values: any) => {
        this.estatus_customer = values.estatus;
        this.todo = values;
        if (values.hasOwnProperty("idArticulos")) {
          this.getOthersArticles(
            values.idArticulos,
            values.positive_or_negative,
          );
        }
        this.cliente = values;
        this.name = this.todo.cliente.nombre;
        this.lastname = this.todo.cliente.apellido;
        this.email = this.todo.cliente.correo;
        this.phone = this.todo.cliente.telefono;
        this.add_name = this.todo.cliente.adicional_nombre;
        this.add_lastname = this.todo.cliente.adicional_apellido;
        this.add_email = this.todo.cliente.adicional_correo;
        this.add_phone = this.todo.cliente.adicional_telefono;
        this.identification = this.todo.cliente.identificacion;
        this.pc = this.todo.cliente.cp;
        this.noexterior = this.todo.cliente.no_exterior;
        this.nointerior = this.todo.cliente.no_interior;
        this.street = this.todo.cliente.calle;
        this.colonia = this.todo.cliente.colonia;
        this.state = this.todo.cliente.estado;
        this.citys = this.todo.cliente.ciudad;
        this.country = this.todo.cliente.pais;
        this.getAgent(values.uid_user);
      });

    this.userForm = this.createFormGroup();
    this.userAdForm = this.createNewFormGroup();
  }

  openModal() {
    this.modalRef = this.modal.open(this.myModal, {
      size: "md",
      animation: true,
      centered: true,
      backdrop: true,
      scrollable: true,
    });
  }
  closeModal() {
    this.modal.dismissAll();
  }

  searchNh(e: string) {
    if (e.length == 5) {
      this.getDataApi(e);
    }
  }

  openModalUpdate() {
    this.modalUpdateRef = this.modal.open(this.modal_update, {
      size: "md",
      animation: true,
      centered: true,
      backdrop: true,
    });
  }

  closeModalUpdate() {
    this.modal.dismissAll();
  }

  back() {
    this.router.navigate(["/historys/ingresa-history"]);
  }
  goToChanges() {
    this.closeModalUpdate();
    this.router.navigate([
      "/operaciones/aprobaciones-ingresa/aprobaciones/solicitudes",
    ]);
  }
  getDataApi(e) {
    if (e !== this.cp) {
      this.cp = e;
      const { cp } = this.userForm.value;
      if (cp) {
        this.showInputNeighborhood = false;

        this.mapSvc.getapi(cp).subscribe((value: any) => {
          this.neighborhood = value.data.asentamientos;
          this.city = value.data.municipio;
          this.state = value.data.estado;
          this.userForm.addControl("ciudad", new UntypedFormControl(this.city));
          this.userForm.addControl(
            "estado",
            new UntypedFormControl(this.state),
          );
        });
      }
    }
  }

  createFormGroup() {
    return new UntypedFormGroup({
      nombre: new UntypedFormControl("", [Validators.minLength(2)]),
      apellido: new UntypedFormControl("", [Validators.minLength(2)]),
      correo: new UntypedFormControl("", [
        Validators.pattern(this.emailPattern),
      ]),
      telefono: new UntypedFormControl("", [Validators.minLength(10)]),
      identificacion: new UntypedFormControl("", Validators.minLength(2)),
      cp: new UntypedFormControl("", [Validators.minLength(5)]),
      calle: new UntypedFormControl("", [Validators.minLength(2)]),
      colonia: new UntypedFormControl("", [Validators.minLength(2)]),
      ciudad: new UntypedFormControl("", [Validators.minLength(2)]),
      no_interior: new UntypedFormControl("", [Validators.minLength(1)]),
      no_exterior: new UntypedFormControl("", [Validators.minLength(1)]),
      estado: new UntypedFormControl("", [Validators.minLength(2)]),
      pais: new UntypedFormControl("", [Validators.minLength(2)]),
    });
  }
  createNewFormGroup() {
    return new UntypedFormGroup({
      nombre: new UntypedFormControl("", Validators.minLength(2)),
      apellido: new UntypedFormControl("", Validators.minLength(2)),
      correo: new UntypedFormControl("", Validators.pattern(this.emailPattern)),
      telefono: new UntypedFormControl("", Validators.minLength(10)),
    });
  }

  get adicional_nombre() {
    return this.userAdForm.get("nombre");
  }
  get adicional_apellido() {
    return this.userAdForm.get("apellido");
  }
  get adicional_correo() {
    return this.userAdForm.get("correo");
  }
  get adicional_telefono() {
    return this.userAdForm.get("telefono");
  }

  get nombre() {
    return this.userForm.get("nombre");
  }
  get apellido() {
    return this.userForm.get("apellido");
  }
  get identificacion() {
    return this.userForm.get("identificacion");
  }
  get correo() {
    return this.userForm.get("correo");
  }
  get telefono() {
    return this.userForm.get("telefono");
  }
  get codigo_postal() {
    return this.userForm.get("cp");
  }
  get calle() {
    return this.userForm.get("calle");
  }
  get col() {
    return this.userForm.get("colonia");
  }
  get no_exterior() {
    return this.userForm.get("no_exterior");
  }
  get no_interior() {
    return this.userForm.get("no_interior");
  }
  get estado() {
    return this.userForm.get("estado");
  }
  get ciudad() {
    return this.userForm.get("ciudad");
  }

  UpdateDataUser() {
    this.UpdateUserData = !this.UpdateUserData;
  }
  UpdateAddressClient() {
    this.UpdateAdressClientData = !this.UpdateAdressClientData;
  }

  UpdateDataUserAdd() {
    this.UpdateUserDataAdd = true;
  }
  addContactAd() {
    this.UpdateUserDataAdd = true;
    this.addContactAdicional = true;
  }

  addUserAd() {
    const { nombre, apellido, telefono, correo } = this.userAdForm.value;
    let cliente = {
      adicional_nombre: nombre,
      adicional_apellido: apellido,
      adicional_telefono: telefono,
      adicional_correo: correo,
    };
    let actualizacionAdicional = {
      fecha: moment().format("DD-MM-YYYY"),
      hora: moment().format("HH:mm:ss a"),
    };
    this.afs
      .collection("CUSTOMER")
      .doc(String(this.idC))
      .set({ cliente, actualizacionAdicional }, { merge: true })
      .then(() => this.cancelUpdateDataUserAdd());
  }
  cancelUpdateDataUserAdd() {
    this.UpdateUserDataAdd = false;
    this.UpdateAdressClientData = false;
    this.addContactAdicional = false;
    this.userAdForm.reset();
  }

  ShowMore() {
    this.ShowMoreCustomer = !this.ShowMoreCustomer;
    if (this.ShowMoreCustomer == true) this.ShowMoreCustomerText = "Ver menos";
    else this.ShowMoreCustomerText = "Ver más";
  }

  async updateUser() {
    const {
      nombre,
      apellido,
      identificacion,
      telefono,
      correo,
      cp,
      calle,
      colonia,
      estado,
      no_exterior,
      no_interior,
      ciudad,
    } = this.userForm.value;
    if (
      nombre == "" &&
      apellido == "" &&
      identificacion == "" &&
      telefono == "" &&
      correo == "" &&
      cp == "" &&
      calle == "" &&
      colonia == "" &&
      estado == "" &&
      ciudad == "" &&
      no_exterior == "" &&
      no_interior == ""
    ) {
      alert("No hay datos para actualizar.");
    } else {
      if (telefono !== "") {
        let telefonoUser: String = telefono;
        let newTelefono = telefonoUser.split(/\D/).join("");
        this.afs
          .collection(
            "CUSTOMER",
            (ref) => ref.where("cliente.telefono", "==", newTelefono).limit(1),
          )
          .valueChanges()
          .pipe(take(1))
          .toPromise()
          .then((res: any[]) => {
            if (res.length > 0) {
              this.sameNumber = true;
              this.openModal();
            } else {
              this.continueUpdateUser(this.userForm.value);
            }
          });
      } else {
        this.continueUpdateUser(this.userForm.value);
      }
    }
  }

  continueUpdateUser({
    nombre,
    apellido,
    identificacion,
    telefono,
    correo,
    cp,
    calle,
    colonia,
    estado,
    ciudad,
    no_exterior,
    no_interior,
  }) {
    if (nombre == null || nombre == undefined || nombre == "") {
      this.name = this.todo.cliente.nombre;
    } else this.name = nombre;
    if (apellido == null || apellido == undefined || apellido == "") {
      this.lastname = this.todo.cliente.apellido;
    } else this.lastname = apellido;
    if (
      identificacion == null ||
      identificacion == undefined ||
      identificacion == ""
    ) {
      this.identification = this.todo.cliente.identificacion;
    } else this.identification = identificacion;
    if (telefono == null || telefono == undefined || telefono == "") {
      this.phone = this.todo.cliente.telefono;
    } else this.phone = telefono;
    if (correo == null || correo == undefined || correo == "") {
      this.email = this.todo.cliente.correo;
    } else this.email = correo;
    if (cp == null || cp == undefined || cp == "") {
      this.pc = this.todo.cliente.cp;
    } else this.pc = cp;
    if (calle == null || calle == undefined || calle == "") {
      this.street = this.todo.cliente.calle;
    } else this.street = calle;
    if (colonia == null || colonia == undefined || colonia == "") {
      this.colonia = this.todo.cliente.colonia;
    } else this.colonia = colonia;
    if (estado == null || estado == undefined || estado == "") {
      this.state = this.todo.cliente.estado;
    } else this.state = estado;
    if (ciudad == null || ciudad == undefined || ciudad == "") {
      this.citys = this.todo.cliente.ciudad;
    } else this.citys = ciudad;
    if (no_exterior == null || no_exterior == undefined || no_exterior == "") {
      this.noexterior = this.todo.cliente.no_exterior;
    } else this.noexterior = no_exterior;
    if (no_interior == null || no_interior == undefined || no_interior == "") {
      this.nointerior = this.todo.cliente.no_interior;
    } else this.nointerior = no_interior;

    let newDataCustomer = {
      nombre: this.name,
      apellido: this.lastname,
      telefono: this.phone,
      correo: this.email,
      cp: this.pc,
      identificacion: this.identification,
      calle: this.street,
      ciudad: this.citys,
      colonia: this.colonia,
      estado: this.state,
      no_exterior: this.noexterior,
      no_interior: this.nointerior,
      tipo: "Actualización de Información del cliente",
      id_cliente: this.todo.id_cliente,
      id_document_customer: this.idC,
    };
    let id = this.generate_id.createId();
    let id_document_client = this.todo.id_document;
    if (this.resultados.length == 0) {
      this.dbData.updateCustomer(
        newDataCustomer,
        id_document_client,
        id,
        this.todo.uid_user,
        false,
      );
    } else {
      let count = 0;
      for (const article of this.resultados) {
        if (
          article.commodity.estatus == "aprobado" ||
          article.commodity.estatus == "por vencer"
        ) {
          count++;
        }
      }

      if (count > 0) {
        this.openModalUpdate();
        this.dbData.updateCustomer(
          newDataCustomer,
          id_document_client,
          id,
          this.todo.uid_user,
          true,
        );
      } else {
        this.dbData.updateCustomer(
          newDataCustomer,
          id_document_client,
          id,
          this.todo.uid_user,
          false,
        );
      }
    }

    this.UpdateUserData = false;
    this.userForm.reset();
  }

  async updateUserAd() {
    const { nombre, apellido, telefono, correo } = this.userAdForm.value;
    if (nombre == "" && apellido == "" && telefono == "" && correo == "") {
      alert("No hay datos para actualizar.");
    } else {
      if (nombre == null || nombre == undefined || nombre == "") {
        this.add_name = this.todo.cliente.adicional_nombre;
      } else this.add_name = nombre;
      if (apellido == null || apellido == undefined || apellido == "") {
        this.add_lastname = this.todo.cliente.adicional_apellido;
      } else this.add_lastname = apellido;
      if (telefono == null || telefono == undefined || telefono == "") {
        this.add_phone = this.todo.cliente.adicional_telefono;
      } else this.add_phone = telefono;
      if (correo == null || correo == undefined || correo == "") {
        this.add_email = this.todo.cliente.adicional_correo;
      } else this.add_email = correo;
    }

    let id_document_customer = this.todo.id_document;

    let newDataCustomerAd = {
      nombre: this.add_name,
      apellido: this.add_lastname,
      telefono: this.add_phone,
      correo: this.add_email,
      tipo: "Actualización de información del contato adicional",
      id_cliente: this.todo.id_cliente,
      id_document_customer: this.idC,
    };

    let id = this.generate_id.createId();

    if (this.resultados.length == 0) {
      this.dbData.updateCustomerAd(
        newDataCustomerAd,
        id_document_customer,
        id,
        this.todo.uid_user,
        false,
      );
    } else {
      let count = 0;
      for (const article of this.resultados) {
        if (
          article.commodity.estatus == "aprobado" ||
          article.commodity.estatus == "por vencer"
        ) {
          count++;
        }
      }

      if (count > 0) {
        this.openModalUpdate();
        this.dbData.updateCustomerAd(
          newDataCustomerAd,
          id_document_customer,
          id,
          this.todo.uid_user,
          true,
        );
      } else {
        this.dbData.updateCustomerAd(
          newDataCustomerAd,
          id_document_customer,
          id,
          this.todo.uid_user,
          false,
        );
      }
    }

    this.openModalUpdate();
    this.userAdForm.reset();
    this.UpdateUserDataAdd = false;
  }

  editArticle(articulo: { id_document: any }) {
    this.router.navigate([
      "ingresa",
      this.idC,
      "articulo",
      articulo.id_document,
      "articulos",
      "edit",
    ]);
  }

  addArticle() {
    this.router.navigate(["ingresa", this.idC, "articulo"]);
  }

  viewPDF(articulo: any) {
    this.generatePdf.generatePdf(this.cliente, articulo);
  }

  async approve(article: any) {
    if (article.estatus == "denegado") {
      this.afs
        .collection("ARTICLE")
        .doc(article.id_document)
        .update({ estatus: "habilitado", argumento: "No hay observaciones" });
    } else if (article.estatus == "en espera") {
      let articulosPorAprobar = firebase.firestore.FieldValue.increment(-1);
      let articulosActivos = firebase.firestore.FieldValue.increment(1);
      this.afs
        .collection("CUSTOMER")
        .doc(this.idC)
        .update({ articulosActivos, articulosPorAprobar });
      this.afs.collection("ARTICLE").doc(article.id_document).update({
        estatus: "habilitado",
        argumento: "No hay observaciones",
      });
    } else {
      let articulosActivos = firebase.firestore.FieldValue.increment(1);
      this.afs.collection("ARTICLE").doc(article.id_document).update({
        estatus: "habilitado",
        argumento: "No hay observaciones",
      });
      this.afs
        .collection("CUSTOMER")
        .doc(this.idC)
        .update({ articulosActivos });
    }
    var tokens = await this.tokensUser.getTokens(this.todo.uid_user);

    let obj = {
      uid: this.todo.uid_user,
      id_cliente: this.idC,
      id_articulo: article.id_document,
      tokens: tokens,
    };
    this.socket.io.emit("article-approve", obj);

    let features = article.caracteristicas;
    let id_articulo = article.id_document;
    this.articleCuarentine(features, id_articulo);
  }

  setDates(article: any) {
    let product = article.commodity.producto;

    let duration = product == "gold99"
      ? 99
      : product == "elite99"
      ? 369
      : product == "diamond99"
      ? 189
      : product == "imperial99"
      ? 449
      : 0;
    let autoRenovation = 0;
    let start = moment().format();

    let data = {
      duration,
      autoRenovation,
      start,
    };

    this.afs.collection("ARTICLE").doc(article.id_document).set(
      {
        estatus: "habilitado",
        argumento: "No hay observaciones",
        commodity: data,
      },
      { merge: true },
    );

    // let dif: any;
    // let due_date: any;
    // let get_date_product = article.commodity.fecha;
    // let name_product = article.commodity.producto
    // let date_product = new Date(get_date_product);
    // let new_Date = moment(get_date_product).format('DD-MM-YYYY');
    // let days = product == 'gold99' ? 99 : product == 'elite99' ? 369 : 'diamond99' ? 189 : 'imperial99' ? 449 : 0
    // let new_date = this.fech(date_product, days); // de la fecha de producto cuenta los dias y da una fecha
    // due_date = moment(new_date).format('DD-MM-YYYY'); //fecha de vencimiento

    // let today = moment().format('DD-MM-YYYY');

    // let current_date = moment(today);
    // let dateProduct = moment(new_date);

    // dif = dateProduct.diff(current_date, 'days'); // me da lo dias que quedan
    // dif <= 0 ? dif = 0 : dif

    // let obj = {
    //   totalPeriod: dif,
    //   autoRenovation: 0,
    //   cancelation: '',
    //   duration: product == 'gold99' ? 99 : product == 'elite99' ? 369 : 'diamond99' ? 189 : 'imperial99' ? 449 : 0
    // }

    // let producto = firebase.firestore.FieldValue.arrayUnion(obj)
    // this.afs.collection('ARTICLE').doc(article.id_document).set({
    //   estatus: 'habilitado', argumento: 'No hay observaciones', commodity: {

    //     products
    //   start: moment().format(),
    //   }
    // }, { merge: true });
  }
  fech(date, days) {
    date.setDate(date.getDate() + days);
    return date;
  }
  articleCuarentine(feature, id_article) {
    let features = {
      caracteristicas: feature,
    };
    this.afs
      .collection("LEAD", (ref) => ref.where("cuarentena", "==", true))
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then(async (doc: any) => {
        this.cuarentena = doc;

        this.usersEncontrados = this.cuarentena.filter((userEncontrado) => {
          let encontrado = false;
          let contador = 0;
          if (userEncontrado.tagsToSearch) {
            for (const i of userEncontrado.tagsToSearch) {
              for (const selectedValueToSearch of features.caracteristicas) {
                if (
                  i.id == selectedValueToSearch.id &&
                  i.seleccionado === selectedValueToSearch.seleccionado
                ) {
                  contador++;
                  if (contador >= 1) {
                    encontrado = true;
                  }
                }
              }
            }
            userEncontrado.contador = contador;
            userEncontrado.tagsToSearch = userEncontrado.tagsToSearch.filter(
              (c) => c.seleccionado,
            );
            return encontrado;
          }
        });

        for (const i of this.usersEncontrados) {
          var tokens = await this.tokensUser.getTokens(i.uid_user);

          let objeto = {
            stop: false,
            id_article: id_article,
            uid_user: i.uid_user,
            id_registra: i.id_document,
            tokens,
          };
          this.socket.io.emit("cuarentineFound", objeto);
          this.socket.io.emit("quarantine", objeto);
        }
      });
  }

  disapprove({ id_document }) {
    this.id_documentSelected = id_document;
    this.showModalDisableArticle();
  }
  async disapproveArticle() {
    if (this.argumentForm.value.argument !== "") {
      let id_document = this.id_documentSelected;
      let observations = firebase.firestore.FieldValue.arrayUnion(
        this.argumentForm.value.argument,
      );
      this.afs.collection("ARTICLE").doc(id_document).update({
        estatus: "denegado",
        argumento: this.argumentForm.value.argument,
        observations,
      });
      var tokens = await this.tokensUser.getTokens(this.todo.uid_user);

      let obj = {
        uid: this.todo.uid_user,
        id_cliente: this.idC,
        tokens: tokens,
      };
      this.socket.io.emit("article-disapprove", obj);

      this.hideModalNegative();
      this.argumentForm.reset();
    }
  }

  showArgument() {
    this.disabled_article = true;
  }
  showModalArgument() {
    this.rejected_article = true;
  }
  async disableArticles() {
    if (this.argumentForm.value.argument !== "") {
      let id_document = this.id_documentSelected;
      let observations = firebase.firestore.FieldValue.arrayUnion(
        this.argumentForm.value.argument,
      );
      this.afs.collection("ARTICLE").doc(id_document).update({
        estatus: "inhabilitado",
        argumento: this.argumentForm.value.argument,
        observations,
      });

      var tokens = await this.tokensUser.getTokens(this.todo.uid_user);
      let obj = {
        uid: this.todo.uid_user,
        id_cliente: this.idC,
        tokens,
      };
      this.socket.io.emit("article-disable", obj);

      this.hideModalNegative();
      this.argumentForm.reset();
    }
  }

  showModalDisableArticle() {
    $(".ui.basic.modal.one").modal("show");
  }
  showModalinableArticle() {
    $(".ui.basic.modal.two").modal("show");
  }

  hideModalNegative() {
    $(".ui.basic.modal.one").modal("hide");
    $(".ui.basic.modal.two").modal("hide");
    this.disabled_article = false;
    this.rejected_article = false;
  }

  disable({ id_document }) {
    this.id_documentSelected = id_document;
    this.showModalinableArticle();
  }
  disableArticle() {
    let id_document = this.id_documentSelected;
    this.afs
      .collection("ARTICLE")
      .doc(id_document)
      .update({ estatus: "inhabilitado" });
  }
  async enable({ id_document }) {
    this.afs
      .collection("ARTICLE")
      .doc(id_document)
      .update({ estatus: "habilitado", argumento: "No hay observaciones" });

    var tokens = await this.tokensUser.getTokens(this.todo.uid_user);

    let obj = {
      uid: this.todo.uid_user,
      id_cliente: this.idC,
      tokens,
    };
    this.socket.io.emit("article-enable", obj);
  }

  completeArticle(articulo: any) {
    if (articulo.commodity == null) {
      this.router.navigate([
        "ingresa",
        this.idC,
        "articulo",
        articulo.id_document,
        "productos",
      ]);
    } else if (articulo.articulo == null) {
      this.router.navigate([
        "ingresa",
        this.idC,
        "articulo",
        articulo.id_document,
        "articulos",
      ]);
    }
  }

  fullview(articulo) {
    this.router.navigate([`registra/${articulo.id_document}/item-view`]);
  }
  advanceArticle(articulo) {
    this.router.navigate([
      "historys",
      "ingresa-history",
      articulo.id_document,
      "advance",
    ]);
    // this.router.navigate([`registra/${articulo.id_document}/item-view`])
  }

  async resendIdentification() {
    let newName = "";
    let fullName = String(
      this.todo.cliente.nombre + " " + this.todo.cliente.apellido,
    ).split(" ");
    for (let i = 0; i <= fullName.length - 1; i++) {
      newName += fullName[i].charAt(0).toUpperCase() + fullName[i].slice(1) +
        " ";
    }

    this.afs
      .collection("CUSTOMER")
      .doc(this.idC)
      .update({ mandarIdentificacion: true });
    var tokens = await this.tokensUser.getTokens(this.todo.uid_user);

    let objeto = {
      uid: this.todo.uid_user,
      id_document: this.idC,
      name: newName,
      tokens,
    };
    this.socket.io.emit("resendIdentification", objeto);
  }
  sendIdentification() {
    this.router.navigate([
      "ingresa",
      this.todo.id_document,
      "subir-foto",
      "edit",
    ]);
  }

  viewObservation(argumento: string) {
    this.txt_observation = argumento;
    this.openModalObservation();
  }
  openModalObservation() {
    this.modalObservation = this.modal.open(this.modal_observation, {
      size: "md",
      animation: true,
      centered: true,
      backdrop: true,
    });
  }
  // closeModalUpdate() {
  //   this.modal.dismissAll();
  // }

  getAgent(idc: string) {
    this.afs
      .collection("USERS")
      .doc(idc)
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((agent) => {
        this.agent = agent;
      });
  }

  ngOnDestroy() {
    if (this.subs !== undefined) {
      this.subs.unsubscribe();
    }
    if (this.subs_two !== undefined) {
      this.subs_two.unsubscribe();
    }
  }
  AddNewOtherArticle() {
    this.closeModal();
    this.router.navigate(["article-listing/other-article/id/", this.idC]);
  }

  addArticleToOthers(income) {
    // this.afs.collection('LEAD').add({
    //   id_document_client: this.idC,
    //   cliente: this.todo.cliente,
    //   completo:false,
    //   cuarentena: false,
    //   estatus: 'pendiente',
    //   mostrar: false,
    //   id: this.generateId.createId(),
    //   uid_user: this.todo.uid_user,
    // });
    this.router.navigate(["article-listing", income.id_document]);
  }
}
