import { Component, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataDbService } from 'src/app/SERVICIOS/data-db.service';
import * as moment from 'moment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { take } from 'rxjs/operators';
import { UserOnlineService } from 'src/app/SERVICIOS/user-online.service';
import { GenerateIdService } from 'src/app/SERVICIOS/generate-id.service';
import { AuthService } from 'src/app/SERVICIOS/auth.service';


@Component({
  selector: 'app-tipo',
  templateUrl: './tipo.component.html',
  styleUrls: ['./tipo.component.scss',],

})
export class TipoComponent implements OnDestroy {
  public ingForm: UntypedFormGroup;
  public valorSeleccionado: string;
  idDocumentCustomer: string;
  idCustomer: string
  idArticle: number
  user: any;
  constructor(
    private dbData: DataDbService,
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    private auth: AuthService,
    private userOnline: UserOnlineService,
    private generateId: GenerateIdService
  ) {
    this.idDocumentCustomer = this.route.snapshot.paramMap.get('idCustomer')
    this.getIdCustomer()
    this.ingForm = this.createFormGroup();
  }
  getIdCustomer() {
    this.auth.user$.pipe(take(1)).toPromise().then((user: any) => {
      this.user = user;



    })
    this.afs.collection("CUSTOMER").doc(String(this.idDocumentCustomer)).valueChanges().pipe(take(1)).toPromise().then((values: any) => {
      this.idCustomer = values.id
    })
  }

  onSaveForm() {
    if (this.userOnline.userOnline()) {
      if (this.ingForm.valid) {
        if (this.idCustomer !== undefined) {
          this.idArticle = Number(this.generateId.createId());
          this.dbData.saveType(this.ingForm.value, this.idDocumentCustomer, this.idCustomer, this.idArticle, this.user);
          this.ingForm.reset();
        } else {
          this.getIdCustomer()
        }
      } else {
        alert('Elige una opcion para continuar')
      }
    }
    else {
      alert('No tienes conexion a internet')
    }
  }



  createFormGroup() {
    let fecha_actual = moment().format('DD-MM-YYYY');
    let hora_actual = moment().format('hh:mm:ss a');
    return new UntypedFormGroup({
      tipo: new UntypedFormControl('', Validators.required),
      hora: new UntypedFormControl(hora_actual),
      fecha: new UntypedFormControl(fecha_actual),
    });
  }
  get tipo() { return this.ingForm.get('tipo') }

  ngOnDestroy() {

  }


}
