<div class="main" *ngIf=" customer && articles">
  <div style="display: flex;justify-content: flex-end;">
    <button class="ui primary button" (click)="addArticle()">Agregar artículo</button>
  </div>
  <div *ngFor="let article of articles; let i = index">
    <div style="display: flex;" class="shadow-lg p-3 mb-5card">
      <div style="width: 35%;  position: relative;">
        <!-- image -->
        <div *ngIf="article.imagen_principal != ''" style="display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        height: 40vh;">
          <img [src]="article.imagen_principal" alt="" width="100%" height="auto">
          <div class="text-center mt-3" style="font-weight: bold;">
            {{article.articulo.calle}} / {{article.articulo.no_exterior}} <div
              *ngIf="article.articulo.no_interior != ''"> / {{article.articulo.no_interior}} </div>/
            {{article.articulo.colonia}} / {{article.articulo.ciudad}} / {{article.articulo.estado}}
            / {{article.articulo.pais}} / {{article.articulo.cp}}
          </div>

        </div>
        <div *ngIf="article.imagen_principal == ''" style=" display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        height: 40vh;">
          <h5 class="text-center">Sin fotografías</h5>
          <div class="text-center mt-3" style="font-weight: bold;">
            {{article.articulo.calle}} / {{article.articulo.no_exterior}} <div
              *ngIf="article.articulo.no_interior != ''"> / {{article.articulo.no_interior}} </div>/
            {{article.articulo.colonia}} / {{article.articulo.ciudad}} / {{article.articulo.estado}}
            / {{article.articulo.pais}} / {{article.articulo.cp}}
          </div>


        </div>

      </div>
      <div style="width: 65%; margin: 0 30px;">
        <div>
          <div class="text-center" style="display: flex;
        flex-direction: row;
        justify-content: space-around;">
            <div class="mb-5">
              <div class="lead" style="font-weight: bold;">Nombre</div>
              <p>{{customer.cliente.nombre}}</p>
            </div>
            <div>
              <div class="lead" style="font-weight: bold;">Agente</div>
              <p>Wick</p>
            </div>
            <div>
              <div class="lead" style="font-weight: bold;">Precio</div>
              <p>{{article.articulo.precio | currency}} {{article.articulo.currency}}
            </div>
            <div>
              <div class="lead" style="font-weight: bold;">ID de artículo</div>
              <p>{{article.id_articulo}}</p>
            </div>
          </div>
          <div class="text-center mt-5" style="    display: flex;
          flex-direction: row;
          justify-content: space-around;">
            <div class="mb-3 ">
              <div class="lead" style="font-weight: bold;">Fecha de creación</div>
              <p>{{article.articulo.fecha}}</p>
            </div>
            <div>
              <div class="lead" style="font-weight: bold;">Estatus</div>
              <p >{{article.estatus.toString().toUpperCase()}}</p>

            </div>
            <div>
              <div class="lead" style="font-weight: bold;">Progreso</div>

              <div *ngIf="article.positive != null ">
                <div *ngIf="article.positive.progress  == null">
                  <p>Esperando confirmación de pago</p>
                </div>
                <div *ngIf="article.positive.progress != null
                    &&
                    article.positive.progress == 'DATA-COLLECT'
                    ">
                  <p>Recolección de datos</p>
                </div>


                <div *ngIf="article.positive.progress  != null && article.positive.progress == 'DATA-VALIDATING'">
                  <div>Análisis de datos</div>
                </div>


                <div
                  *ngIf="article.positive.progress  != null && (article.positive.progress =='PLACING-LEASE' ||article.positive.progress =='PLACING-SALE')">
                  <div>Validación de datos</div>
                </div>

                <div
                  *ngIf="article.positive.progress  != null && (article.positive.progress =='SALE-PLACED'||article.positive.progress =='LEASE-PLACED')">
                  <div *ngIf="article.tipo.tipo == 'compraventa'">Placing Sale</div>
                  <div *ngIf="article.tipo.tipo == 'arrendamiento'">Placing Lease</div>
                </div>

                <div
                  *ngIf="article.positive.progress  != null && (article.positive.progress =='SALE-PLACED-FINISH'||article.positive.progress =='LEASE-PLACED-FINISH')">
                  <div *ngIf="article.tipo.tipo == 'compraventa'">Sale Placed</div>
                  <div *ngIf="article.tipo.tipo == 'arrendamiento'">Lease Placed</div>
                </div>



                <a  (click)="progessDetail(article)">Ver detalles del progreso</a>

              </div>
              <div *ngIf="article.positive == null">
                <p>Esperando confirmación de pago </p>
              </div>

            </div>


          </div>

          <div class="text-center mt-5" style="    display: flex;
          flex-direction: row;
          justify-content: end">
            <div class="mb-3 ">
              <button class="ui button" (click)="seeObservations(article)">Ver registro de observaciones</button>
            </div>

            <div>

            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
  <div *ngIf="notAssignedArticlesData">
    <div *ngFor="let article of notAssignedArticlesData; let i = index">
      <div style="display: flex;" class="shadow-lg p-3 mb-5card">
        <div style="width: 35%;  position: relative;">
          <!-- image -->
          <div *ngIf="article.imagen_principal != ''" style="display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        height: 40vh;">
            <img [src]="article.imagen_principal" alt="" width="100%" height="auto">
            <div class="text-center mt-3" style="font-weight: bold;">
              {{article.articulo.calle}} / {{article.articulo.no_exterior}} <div
                *ngIf="article.articulo.no_interior != ''"> / {{article.articulo.no_interior}} </div>/
              {{article.articulo.colonia}} / {{article.articulo.ciudad}} / {{article.articulo.estado}}
              / {{article.articulo.pais}} / {{article.articulo.cp}}
            </div>

          </div>
          <div *ngIf="article.imagen_principal == ''" style=" display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        height: 40vh;">
            <h5 class="text-center">Sin fotografías</h5>
            <div class="text-center mt-3" style="font-weight: bold;">
              {{article.articulo.calle}} / {{article.articulo.no_exterior}} <div
                *ngIf="article.articulo.no_interior != ''"> / {{article.articulo.no_interior}} </div>/
              {{article.articulo.colonia}} / {{article.articulo.ciudad}} / {{article.articulo.estado}}
              / {{article.articulo.pais}} / {{article.articulo.cp}}
            </div>


          </div>

        </div>
        <div style="width: 65%; margin: 0 30px;">
          <div>
            <div class="text-center" style="    display: flex;
        flex-direction: row;
        justify-content: space-around;">
              <div class="mb-5">
                <div class="lead" style="font-weight: bold;">Nombre</div>
                <p>{{customer.cliente.nombre}}</p>
              </div>
              <div>
                <div class="lead" style="font-weight: bold;">Agente</div>
                <p>Wick</p>
              </div>
              <div>
                <div class="lead" style="font-weight: bold;">Precio</div>
                <p>{{article.articulo.precio | currency}} {{article.articulo.currency}}
              </div>
              <div>
                <div class="lead" style="font-weight: bold;">ID de artículo</div>
                <p>{{article.id_articulo}}</p>
              </div>
            </div>
            <div class="text-center mt-5" style="    display: flex;
          flex-direction: row;
          justify-content: space-around;">
              <div class="mb-3 ">
                <div class="lead" style="font-weight: bold;">Fecha de creación</div>
                <p>08/03/2023</p>
              </div>
              <div>
                <div class="lead" style="font-weight: bold;">Estatus</div>
                <p>Por definir</p>
              </div>
              <div>
                <div class="lead" style="font-weight: bold;">Progreso</div>
                <p>Sin progreso</p>
              </div>

            </div>

            <div class="text-center mt-5" style="    display: flex;
          flex-direction: row;
          justify-content: end">
              <div class="mb-3 ">
                <button class="ui primary button" (click)="setPositive(article, i)">Positivo</button>
                <button class="ui button" (click)="setNegative(article, i)">Negativo</button>
                <button class="ui button" (click)="seeObservations(article)">Ver registro de observaciones</button>
              </div>

              <div>

              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- sin relacionar
  <div class="subtitle fx mt-4">
    <h5>Artículos sin relacionar</h5>
  </div>
  <div class="table-responsive">
    <div>
      <table class="table">
        <thead class="thead-dark">
          <tr>
            <th scope="col">ID de artículo</th>
            <th scope="col">Estatus</th>
            <th scope="col">Observaciones</th>
            <th scope="col">Fecha de asignación</th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody *ngIf="notAssigned">
          <tr *ngFor="let todo of notAssigned">
            <td>{{ todo.id }}</td>
            <td><b>Por definir</b></td>
            <td>No hay observaciones</td>

            <td>Sin asignar</td>

            <td>
              <button class="ui button" (click)="routerItemNotAssigned(todo)">Ver Articulo</button>
            </td>
            <td>
              <button class="ui button primary" (click)="routerGestiona()">Ir a Gestiona</button>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div> -->



































</div>
<ng-template #myModal>
  <div class="main">
    <div class="container-modal-data">

      <h3>

        <span>Observaciones</span>
      </h3>
      <div style="height: 30vh; width: 100%;overflow-y: scroll;">
        <div *ngFor="let observation of observations">
          <div>
            <div style="display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;">
              <div style="width: 70%;" class="mt-3">
                {{observation}}
              </div>
              <button class="ui primary button ">Ver más</button>
            </div>
          </div>
        </div>
      </div>


      <modal-footer class="btn-options">
        <button class="ui button" (click)="back()" style="margin-right: 5px">
          Regresar
        </button>

      </modal-footer>
    </div>
  </div>
</ng-template>

<ng-template #myModalNoObservations>
  <div class="main">
    <div class="container-modal">
      <modal-content>
        <h3>
          <span>No hay observaciones</span>
        </h3>

      </modal-content>

      <modal-footer class="btn-options">
        <button class="ui button" (click)="gestionaView()" style="margin-right: 5px">
          Ir a gestiona
        </button>
        <button class="ui button" (click)="historyLead()">
          Ir a leads
        </button>
      </modal-footer>
    </div>
  </div>
</ng-template>