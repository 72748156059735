<div class="main">
  <div class="mt-3 mb-4">
    <h2 class="title">Agregar articulo</h2>
  </div>
  <div class="subtitle mb-1 mt-4">
    <h4>Información del Lead</h4>
  </div>
  <div class="table-responsive">
    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th scope="col">Nombre</th>
          <th scope="col">Apellidos</th>
          <th scope="col">Número de Contacto</th>
          <th scope="col">Correo Electrónico</th>
          <th scope="col">Fecha de registro</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="lead">
          <td>{{lead.cliente.nombre | titlecase }}</td>
          <td>{{lead.cliente.apellido | titlecase}}</td>
          <td>{{ lead.cliente.telefono }}</td>
          <td *ngIf="lead.cliente.correo !== ''">{{ lead.cliente.correo }}</td>
          <td *ngIf="lead.cliente.correo == ''">No agregado</td>
          <td>{{ lead.cliente.fecha }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="box">
    <div class="article card" *ngIf="article">
      <div class="image" *ngIf="article.imagen_principal.url; else noImg">
        <img *ngIf="!auth.userFullAdmin(user)" [src]="article.imagen_principal.url" alt="" />
      </div>
      <ng-template #noImg>
        <div class="no-image" (click)="fullview()">
          Sin fotografías
        </div>
      </ng-template>
      <div class="description text-center" *ngIf="article">
        <p>{{ article.articulo.ciudad }}, {{ article.articulo.estado }}</p>
        <p>{{ article.articulo.pais }}, {{ article.articulo.descripcion }}</p>
        <p>{{ article.contador }}</p>
      </div>
      <div class="btn-fullView">
        <button class="ui button btn-fullView" [disabled]="disableArticle" (click)="fullview()">
          +
        </button>
      </div>
      <div class="btnOptions">
        <button class="ui primary button" *ngIf="!disableArticle" (click)="continue()">
          Ir a gestiona
        </button>
        <button class="ui primary button" *ngIf="disableArticle" disabled>
          Artículo no disponible
        </button>
        <button class="ui button" (click)="goBackAndNotification()">
          Ignorar y recibir nuevas notificaciones
        </button>
        <button class="ui button " (click)="noNotification()">
          Ignorar y no recibir notificaciones
        </button>
      </div>
    </div>
  </div>
</div>
