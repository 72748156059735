<div class="main">
  <h1 class="title">Información del artículo</h1>

  <form [formGroup]="articleForm" (ngSubmit)="enviar()">
    <h5 class="lead mb-3 mt-5">Precio y ubicación</h5>

    <div class="row mb">
      <div class="col">
        <div class="form-outline">
          <div class="current_information" *ngIf="valueArticle">
            Precio actual:
            {{
            valueArticle.articulo.precio
            | currency : valueArticle.articulo.currency
            }}
          </div>

          <!-- <input type="text" class="form-control" [ngClass]="{invalid:precio.invalid && precio.dirty}"
           [(ngModel)]="price"  [ngModelOptions]="{standalone: true}" (blur)="toCurrency($event)" name="precio" placeholder="Precio" /> -->
          <input type="text" class="form-control" [ngClass]="{ invalid: precio.invalid && precio.dirty }"
            (blur)="toCurrency($event)" (click)="getPrice()" [value]="price" name="precio" placeholder="Precio" />

          <div class="currency">
            <input type="radio" class="btn-check" id="mxn" (click)="getCurrency('USD')" value="MXN"
              formControlName="currency" name="currency" autocomplete="off" />
            <label class="btn btn-outline-secondary" for="mxn">MXN</label>
            <input type="radio" class="btn-check" id="usd" (click)="getCurrency('USD')" value="USD"
              formControlName="currency" name="currency" autocomplete="off" />
            <label class="btn btn-outline-secondary" for="usd">USD</label>
            <input type="radio" class="btn-check" id="eur" value="EUR" (click)="getCurrency('EUR')"
              formControlName="currency" name="currency" autocomplete="off" />
            <label class="btn btn-outline-secondary" for="eur">EUR</label>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-outline">
          <div class="current_information" *ngIf="valueArticle">
            Superficie: {{ valueArticle.articulo.superficie }}
          </div>
          <input type="text" class="form-control" [ngClass]="{ invalid: code.invalid && code.dirty }"
            formControlName="superficie" name="superficie" placeholder="Superficie" />
        </div>
      </div>
      <div class="col">
        <div class="form-outline">
          <div class="current_information" *ngIf="valueArticle">
            Código postal actual: {{ valueArticle.articulo.cp }}
          </div>
          <input type="text" class="form-control" [ngClass]="{ invalid: code.invalid && code.dirty }"
            (input)="searchNh($event.target.value)" formControlName="cp" name="cp" placeholder="Código Postal" />
        </div>
      </div>
      <div class="col">
        <div class="form-outline">
          <div class="current_information" *ngIf="valueArticle">
            Colonia Actual: {{ valueArticle.articulo.colonia }}
          </div>
          <div class="col" *ngIf="neighborhood && !showInputNeighborhood">
            <div class="form-outline">
              <select class="form-select" (change)="addNewNeighborhood($event.target.value)" formControlName="colonia"
                aria-placeholder="Colonia" name="colonia">
                <option value="{{ col }}" *ngFor="let col of neighborhood; let i = index">
                  {{ col }}
                </option>
                <option value="Ingresar Colonia">Ingresar Colonia</option>
              </select>
            </div>
          </div>
          <div class="col" *ngIf="!neighborhood && !showInputNeighborhood">
            <div class="form-outline">
              <select class="form-select" (change)="addNewNeighborhood($event.target.value)"
                aria-label="Default select example" formControlName="colonia" aria-placeholder="Colonia" name="colonia">
                <option value="Ingresar Colonia">Ingresar Colonia</option>
              </select>
            </div>
          </div>
          <div class="col" *ngIf="showInputNeighborhood">
            <div class="form-outline">
              <input type="text" [ngClass]="{ invalid: colonia.invalid && colonia.dirty }" id="neighborhood"
                class="form-control" formControlName="colonia" name="colonia" placeholder="Colonia" />
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="row mb-5">
      <div class="col">
        <div class="form-outline">
          <div class="current_information" *ngIf="valueArticle">
            Calle actual: {{ valueArticle.articulo.calle }}
          </div>
          <input type="text" class="form-control" [ngClass]="{ invalid: calle.invalid && calle.dirty }"
            formControlName="calle" name="calle" placeholder="Calle" />
        </div>
      </div>
      <div class="col">
        <div class="form-outline">
          <div class="current_information" *ngIf="valueArticle">
            Entre calle: {{ valueArticle.articulo.entre_calle1 }}
          </div>
          <input type="text" class="form-control" [ngClass]="{ invalid: entre_calle1.invalid && entre_calle1.dirty }"
            formControlName="entre_calle1" name="entre_calle1" placeholder="Entre calle" />
        </div>
      </div>
      <div class="col">
        <div class="form-outline">
          <div class="current_information" *ngIf="valueArticle">
            Entre calle 2: {{ valueArticle.articulo.entre_calle2 }}
          </div>
          <input type="text" class="form-control" [ngClass]="{ invalid: entre_calle2.invalid && entre_calle2.dirty }"
            formControlName="entre_calle2" name="entre_calle2" placeholder="Entre calle 2" />
        </div>
      </div>
      <div class="col">
        <div class="form-outline">
          <div class="current_information" *ngIf="valueArticle">
            Número exterior actual: {{ valueArticle.articulo.no_exterior }}
          </div>
          <input type="text" class="form-control" [ngClass]="{ invalid: no_exterior.invalid && no_exterior.dirty }"
            formControlName="no_exterior" name="no_exterior" placeholder="Número Exterior" />
        </div>
      </div>


      <!--  -->
    </div>

    <div class="row mb">
      <div class="col">
        <div class="form-outline">
          <div class="current_information" *ngIf="valueArticle">
            Número interior actual: {{ valueArticle.articulo.no_interior }}
          </div>
          <input type="text" class="form-control" formControlName="no_interior" name="no_interior"
            placeholder="Número Interior (opcional)" />
        </div>
      </div>
      <div class="col" *ngIf="!city">
        <div class="form-outline">
          <div class="current_information" *ngIf="valueArticle">
            Ciudad Actual: {{ valueArticle.articulo.ciudad }}
          </div>
          <input type="text" class="form-control" [(ngModel)]="inputCity" [ngModelOptions]="{ standalone: true }"
            name="ciudad" placeholder="Ciudad" />
        </div>
      </div>
      <div class="col" *ngIf="city">
        <div class="form-outline">
          <div class="current_information" *ngIf="valueArticle">
            Ciudad Actual: {{ valueArticle.articulo.ciudad }}
          </div>
          <input type="text" class="form-control" value="{{ city | titlecase }}" disabled name="ciudad"
            placeholder="Ciudad" />
        </div>
      </div>

      <div class="col" *ngIf="!state">
        <div class="form-outline">
          <div class="current_information" *ngIf="valueArticle">
            Estado Actual: {{ valueArticle.articulo.estado }}
          </div>
          <input type="text" class="form-control" [(ngModel)]="inputState" [ngModelOptions]="{ standalone: true }"
            name="estado" placeholder="Estado" />
        </div>
      </div>
      <div class="col" *ngIf="state">
        <div class="form-outline">
          <div class="current_information" *ngIf="valueArticle">
            Estado Actual: {{ valueArticle.articulo.estado }}
          </div>
          <input type="text" class="form-control" value="{{ state | titlecase }}" disabled name="estado"
            placeholder="Estado" />
        </div>
      </div>
      <div class="col">
        <div class="form-outline">
          <div class="current_information" *ngIf="valueArticle">
            País Actual: {{ valueArticle.articulo.pais }}
          </div>
          <input type="text" class="form-control" formControlName="pais" name="pais" disabled placeholder="País" />
        </div>
      </div>

    </div>

    <h5 class="lead mb-3 mt-5">Descripción del artículo y comentarios</h5>

    <div class="row mb">
      <div class="col">
        <div class="form-outline">
          <textarea formControlName="descripcion" [ngClass]="{ invalid: descripcion.invalid && descripcion.dirty }"
            placeholder="Descripción" name="descripcion" rows="4" class="form-control">
            Write something here
          </textarea>
        </div>
      </div>
      <div class="col">
        <div class="form-outline">
          <textarea formControlName="comentario" name="comentario"
            [ngClass]="{ invalid: comentario.invalid && comentario.dirty }" placeholder="Comentario" rows="4"
            class="form-control">
          </textarea>
        </div>
      </div>
    </div>
  </form>
  <div class="mb-3 mt-5" style="display: flex">
    <h5 class="lead ">Caracteriza el inmueble</h5>

    <div class="ui" data-tooltip="Selecciona las etiquetas que describan mejor tu artículo" data-position="top center"
      data-variation="mini">
      <i class="ui question circle outline icon" style="margin-bottom: 17.5px; margin-left: 10px; cursor: pointer"></i>
    </div>
  </div>

  <div>
    <ul class="ks-cboxtags">

      <li *ngFor="let caracteristica of features; let i = index">
        <input (input)="selected(i)" id="{{ 'caracteritica-' + caracteristica.id }}"
          [checked]="caracteristica.seleccionado" type="checkbox" />
        <label for="{{ 'caracteritica-' + caracteristica.id }}">
          {{ caracteristica.descripcion }}
        </label>
      </li>

    </ul>
  </div>
  <div class="text-center mb-4" *ngIf="edit !== null">
    <button class="ui button" [disabled]="articleForm.invalid && !edit" (click)="enviar()">
      Enviar Actualización
    </button>
  </div>
  <div class="text-center mb-4" *ngIf="edit == null">
    <button class="ui button" [disabled]="articleForm.invalid && !edit" (click)="enviar()">
      Continuar
    </button>
  </div>
  <ng-template #myModal>
    <div class="main">
      <div class="container-modal">
        <modal-content>
          <h5 class="lead">Este articulo ya ha sido agregado</h5>
        </modal-content>
        <modal-footer class="btn-options">
          <button class="ui button fs-mini" (click)="closeModal()">
            De Acuerdo
          </button>
        </modal-footer>
      </div>
    </div>
  </ng-template>
  <ng-template #modal_update>
    <div class="main">
      <div class="container-modal">
        <modal-content>
          <h5 class="lead">Se ha enviado una solicitud de cambio</h5>
        </modal-content>
        <modal-footer class="btn-options">
          <button class="ui button" (click)="closeModalUpdate()" style="margin-right: 5px">
            Cerrar
          </button>
          <button class="ui button" (click)="goToChanges()">
            Ir a cambios
          </button>
        </modal-footer>
      </div>
    </div>
  </ng-template>
</div>