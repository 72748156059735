<div class="main">

    <div class="header">
        <div class="buttons">
            <div class=" p-3 d-flex flex-row justify-content-evenly">
                <div class="card m-2" routerLink=" lead-data/0">
                    <div class="m-5 d-flex flex-column" style="height: 15vh;justify-content: space-between;
        align-items: center;">
                        <h1 class="fw-bold">Comprar</h1>
                    </div>
                </div>
                <div class="card m-2" routerLink="lead-data/1">
                    <div class="m-5 d-flex flex-column" style="height: 15vh;justify-content: space-between;
        align-items: center;">
                        <h1 class="fw-bold">Rentar</h1>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>