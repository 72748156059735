import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DataDbService } from 'src/app/SERVICIOS/data-db.service';
import { AuthService } from 'src/app/SERVICIOS/auth.service';
import * as moment from 'moment';
import { Location } from '@angular/common';
import { take } from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import { Subscription } from 'rxjs';
import { GenerateIdService } from 'src/app/SERVICIOS/generate-id.service';
import { SocketService } from 'src/app/SERVICIOS/socket/socket.service';
import { User } from '../../MODULOS/user.interface';

@Component({
  selector: 'app-go',
  templateUrl: './go.component.html',
  styleUrls: ['./go.component.scss'],
})
export class GoComponent implements OnInit, OnDestroy {
  @ViewChild('myModal') myModal;
  modalRef;
  public mostrarLead: any;
  public posiciones = [];
  articulosG = [];
  id_lead: string;
  leadComment: string;
  articleComment: string;
  allLeadComents: any;
  allArticleComments: any;
  id_user: any;
  idR: string;
  getDataattemps = 0;
  getCommentsattemps = 0;
  subLead: Subscription;
  user: User;
  constructor(
    public afs: AngularFirestore,
    public auth: AuthService,
    public router: Router,
    private dbData: DataDbService,
    private route: ActivatedRoute,
    public modal: NgbModal,
    private generateId: GenerateIdService,
    private _location: Location,
    private socket: SocketService
  ) { }

  ngOnInit() {
    this.idR = this.route.snapshot.paramMap.get('idR');
    this.auth.user$
      .pipe(take(1))
      .toPromise()
      .then((value) => {
        this.user = value;
      });

    this.afs
      .collection('LEAD')
      .doc(String(this.idR))
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((values: any) => {
        this.mostrarLead = values;
        this.id_lead = values.id;
        this.posiciones = values.idArticulos;

        if (this.posiciones.length == 0 || this.posiciones == undefined) {
          this.openModal();
        } else {
          this.dbData.col$('ARTICLE', (ref) => this.querys(ref));
        }
      });
  }

  async querys(ref) {
    for (let x = 0; x <= this.posiciones.length - 1; x++) {
      let snapshot = await ref
        .where('id_articulo', '==', this.mostrarLead.idArticulos[x].id)
        .get();
      snapshot.forEach((doc) => {
        this.articulosG.push(doc.data());
      });
    }

    if (this.articulosG.length == 0) {
      this._location.back();
      this.afs.collection('LEAD').doc(this.idR).update({ mostrar: false });
    } else {
      this.afs.collection('LEAD').doc(this.idR).update({ mostrar: true });
    }
  }

  routerItem(todo) {
    this.router.navigate(['registra', todo.id_document, 'item-view']);
  }

  async positivo(todo: any, index: number) {
    let last_update = this.last_update();
    this.articulosG.splice(index, 1);
    let i = this.posiciones
      .map((e) => {
        return e.id;
      })
      .indexOf(todo.id_articulo);
    this.posiciones.splice(i, 1);
    this.afs
      .collection('LEAD')
      .doc(this.idR)
      .update({ idArticulos: this.posiciones });

    let objeto = {
      id: this.generateId.createId(),
      argumento: 'No hay observaciones',
      id_articulo: todo.id_articulo,
      id_document: todo.id_document,
      evaluado: false,
      estatus: 'positivo',
      cambio: false,
      fecha: moment().format('DD-MM-YYYY'),
      hora: moment().format('hh:mm:ss a'),
    };
    this.dbData.saveCommodity(objeto, this.idR);
    // this.dbData.saveCommodityPositive(this.idR, commodityPositive);
    this.dbData.lastUpdate(last_update, this.idR);
    if (this.articulosG.length == 0) {
      this.afs.collection('LEAD').doc(this.idR).update({ mostrar: false });
      this.openModal();
      let not = {
        complete: true,
        id_document: this.idR,
        id_lead: this.id_lead,
        uid: this.mostrarLead.uid,
      };
      this.socket.io.emit('lead-status', not);
    }

    let notification = {
      id_document: this.idR,
    };
    this.socket.io.emit('lead-to-positive', notification);
  }
  negativo(todo: any, index: number) {
    this.articulosG.splice(index, 1);
    let i = this.posiciones
      .map((e) => {
        return e.id;
      })
      .indexOf(todo.id_articulo);
    this.posiciones.splice(i, 1);

    this.afs
      .collection('LEAD')
      .doc(this.idR)
      .update({ idArticulos: this.posiciones });
    let objeto = {
      id: this.generateId.createId(),
      argumento: 'No hay observaciones',
      id_articulo: todo.id_articulo,
      id_document: todo.id_document,
      evaluado: false,
      estatus: 'negativo',
      cambio: false,
      fecha: moment().format('DD-MM-YYYY'),
      hora: moment().format('hh:mm:ss a'),
    };


    this.dbData.saveCommodity(objeto, this.idR);
    // this.dbData.saveCommodityNegative(this.idR, commodityNegative);
    let last_update = this.last_update();
    this.dbData.lastUpdate(last_update, this.idR);
    if (this.articulosG.length == 0) {
      this.afs.collection('LEAD').doc(this.idR).update({ mostrar: false });
      let not = {
        complete: true,
        id_document: this.idR,
        id_lead: this.id_lead,
        uid: this.mostrarLead.uid,
      };
      this.socket.io.emit('lead-status', not);
      this.openModal();
    }
  }

  historyLead() {

    this.router.navigate([
      'historys/registra-history',
      this.mostrarLead.id_document,
      'fullview',
    ]);
    this.modalRef.close();
  }

  gestionaView() {
    this.router.navigate(['gestiona']);
    this.modalRef.close();
  }

  openModal() {
    this.modalRef = this.modal.open(this.myModal, {
      size: 'md',
      animation: true,
      centered: true,
      backdrop: false,
    });
  }
  back() {
    this._location.back();
  }

  commentArticlePost({ id_document }) {
    let comentarios = {
      comentario: this.articleComment,
      date: moment().format(),
      id_user: this.user.id,
      fecha: moment().format('DD-MM-YYYY'),
      hora: moment().format('hh:mm:ss a'),
    };
    this.dbData.articleComment(id_document, comentarios);
    let last_update = this.last_update();
    this.dbData.lastUpdate(this.idR, last_update);
    this.articleComment = '';
  }

  commentLeadPost() {
    let comentarios = {
      comentario: this.leadComment,
      date: moment().format(),
      id_user: this.user.id,
      fecha: moment().format('DD-MM-YYYY'),
      hora: moment().format('hh:mm:ss a'),
    };
    this.dbData.leadComment(this.idR, comentarios);
    let last_update = this.last_update();
    this.dbData.lastUpdate(this.idR, last_update);
    this.leadComment = '';
    this.lastUpdate();
  }

  lastUpdate() {
    let last_update = {
      fecha: moment().format('DD-MM-YYYY'),
      hora: moment().format('hh:mm:ss a'),
    };
    this.dbData.lastUpdate(last_update, this.idR);
  }

  openLG1(contenido1) {
    this.afs
      .collection('LEAD')
      .doc(String(this.idR))
      .collection('LEAD-COMMENT')
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((comments) => {
        this.allLeadComents = comments;
      });
    this.modal.open(contenido1, { size: 'lg' });
  }

  openLG2(contenido2, { id_document }) {
    this.afs
      .collection(`ARTICLE/${id_document}/ARTICLE-COMMENT`)
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((comments) => (this.allArticleComments = comments));

    this.modal.open(contenido2, { size: 'lg' });
  }

  closeModal() {
    this.modal.dismissAll();
  }

  openLG3(contenido3) {
    this.modal.open(contenido3, { size: 'lg' });
  }

  last_update() {
    let last_update = {
      fecha: moment().format('DD-MM-YYYY'),
      hora: moment().format('hh:mm:ss a'),
    };
    return last_update;
  }

  ngOnDestroy() { }
}
