import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import * as moment from 'moment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Fileitem } from '../COMERCIAL/MODULOS/ingresa/file';


@Injectable({
  providedIn: 'root'
})
export class SubirArchivoService {
  archivos = [];
  private CARPETA_IMAGENES = 'profiles';
  url: string;


  constructor(private afs:AngularFirestore) { }


  _extraerArchivos(archivosLista: FileList) {
    for (const propiedad in Object.getOwnPropertyNames(archivosLista)) {
      const archivoTemporal = archivosLista[propiedad];
      if (this._archivoPuedeSerCargado(archivoTemporal)) {
        const nuevoArchivo = new Fileitem(archivoTemporal);
        this.archivos.pop()
        this.archivos.push(nuevoArchivo);
      }
    }


  }

  private _archivoPuedeSerCargado(archivo: File): boolean {

    if (!this._archivoYaFueDroppeado(archivo.name) && this._esImagen(archivo.type)) {
      return true;
    } else {
      return false;
    }

  }


  private _archivoYaFueDroppeado(nombreArchivo: string): boolean {

    for (const archivo of this.archivos) {

      if (archivo.nombreArchivo === nombreArchivo) {
        return true;
      }

    }

    return false;
  }

  private _esImagen(tipoArchivo: string): boolean {
    return (tipoArchivo === '' || tipoArchivo === undefined) ? false : tipoArchivo.startsWith('image');
  }


  cargarImagenes() {    
    this.cargaImagenesFirebase(this.archivos);
  }

  LimpiarArchivos() {
    this.archivos = [];
  }






  //servicio



  
  cargaImagenesFirebase(imagenes: Fileitem[]) {
    let newurl:string
    const storageRef = firebase.storage().ref();

    for (const item of imagenes) {

      item.estaSubiendo = true;
      if (item.progreso >= 100) {
        continue;
      }

      const uploadTask: firebase.storage.UploadTask =
        storageRef.child(`${this.CARPETA_IMAGENES}/${item.nombreArchivo}`)
          .put(item.archivo);
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot: firebase.storage.UploadTaskSnapshot) => item.progreso = (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        (error) => console.error('Error al subir', error),
        () => {
          uploadTask.snapshot.ref.getDownloadURL()
            .then((url) => {
              newurl = url
              item.url = url;
              item.estaSubiendo = false;
              this.update_img(url );
            });
        }
      );
    }
    return newurl
  }

  getUrl(url){
    this.url = url
  }

  private update_img(url:string) {
    const user = firebase.auth().currentUser;
    let uid = user.uid
    user.updateProfile({
      photoURL:url
    }).then(res=>{
      this.afs.collection('USERS').doc(uid).update({photoURL:url})
    })
  }












}
