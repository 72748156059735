import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DataDbService } from 'src/app/SERVICIOS/data-db.service';
import * as moment from 'moment';
import { MapService } from 'src/app/SERVICIOS/map.service';
import { UserOnlineService } from 'src/app/SERVICIOS/user-online.service';
import { GenerateIdService } from 'src/app/SERVICIOS/generate-id.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { take } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscriber, Subscription } from 'rxjs';
import { User } from '../../MODULOS/user.interface';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-registro-cliente',
  templateUrl: './registro-cliente.component.html',
  styleUrls: ['./registro-cliente.component.scss'],
})
export class RegistroClienteComponent implements OnInit, OnDestroy {
  @ViewChild('myModal') myModal;
  modalRef;
  private emailPattern: any =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  mostrar: boolean = false;
  mostrar2: boolean = false;
  texto: boolean;
  text = '+';
  texto2: boolean;
  text2 = '+';
  attemps = 0;
  public clienteForm: UntypedFormGroup;
  id: any;
  country: string;
  state: string;
  city: string;
  neighborhood: any;
  showInputNeighborhood: boolean = false;
  cp: any;
  user: User;
  lenghtComment = 5000;
  inputCity = '';
  inputState = '';
  idL: string;
  lead: any;
  nombre_lead: string;
  apellido_lead: string;
  correo_lead: string;
  telefono_lead: string;
  nombre_adicional_lead: string;
  apellido_adicional_lead: string;
  correo_adicional_lead: string;
  telefono_adicional_lead: string = '';
  cp_lead: string;
  colonia_lead: string;
  calle_lead: string;
  no_exterior_lead: string;
  no_interior_lead: string;
  ciudad_lead: string;
  estado_lead: string;
  identificacion_lead: string;
  comentario_lead: string;
  customerLead: any;
  index: string;
  leadUid: string;
  constructor(
    private route: ActivatedRoute,
    private dbData: DataDbService,
    private afs: AngularFirestore,
    private mapSvc: MapService,
    public userOnline: UserOnlineService,
    private generate_id: GenerateIdService,
    public modal: NgbModal
  ) {
    this.index = this.route.snapshot.paramMap.get('index');
    this.leadUid = this.route.snapshot.paramMap.get('uid_user');
    if (this.route.snapshot.paramMap.get('idLead') !== null) {
      this.afs
        .collection('LEAD')
        .doc(this.route.snapshot.paramMap.get('idLead'))
        .get()
        .pipe(take(1))
        .toPromise()
        .then((res: any) => {
          this.lead = res.data();
          this.nombre_lead = this.lead.cliente.nombre;
          this.apellido_lead = this.lead.cliente.apellido;
          this.correo_lead = this.lead.cliente.correo;
          this.telefono_lead = this.lead.cliente.telefono;
          this.nombre_adicional_lead = this.lead.cliente.adicional_nombre;
          this.apellido_adicional_lead = this.lead.cliente.adicional_apellido;
          this.correo_adicional_lead = this.lead.cliente.adicional_correo;
          this.telefono_adicional_lead = this.lead.cliente.adicional_telefono;
          this.idL = this.route.snapshot.paramMap.get('idLead');
        });
    }

    // this.generateId()
  }
  ngOnInit() {
    this.clienteForm = this.createFormGroup();
  }

  searchNh(e: string) {
    if (e.length == 5) {
      this.getDataApi(e);
    }
  }

  getDataApi(e) {
    this.city = '';
    this.state = '';
    if (e !== this.cp) {
      if (!this.idL) {
        this.cp = e;
        const { cp } = this.clienteForm.value;
        if (cp) {
          this.showInputNeighborhood = false;

          this.mapSvc
            .getapi(cp)
            .pipe(take(1))
            .toPromise()
            .then((res: any) => {
              if (res) {
                this.neighborhood = res.colonias;

                let newCity = String(res.municipio).split(' ');
                for (let i = 0; i <= newCity.length - 1; i++) {
                  if (newCity.length == 1) {
                    this.city =
                      newCity[i].charAt(0) + newCity[i].slice(1).toLowerCase();
                  } else if (newCity.length > 1) {
                    this.city +=
                      newCity[i].charAt(0) +
                      newCity[i].slice(1).toLowerCase() +
                      ' ';
                  }
                }
                let newState = String(res.estado).split(' ');
                for (let i = 0; i <= newState.length - 1; i++) {
                  if (newState.length == 1) {
                    this.state =
                      newState[i].charAt(0) +
                      newState[i].slice(1).toLowerCase();
                  } else if (newState.length > 1) {
                    this.state +=
                      newState[i].charAt(0) +
                      newState[i].slice(1).toLowerCase() +
                      ' ';
                  }
                }

                this.clienteForm.addControl(
                  'ciudad',
                  new UntypedFormControl(this.city)
                );
                this.clienteForm.addControl(
                  'estado',
                  new UntypedFormControl(this.state)
                );
              }
            });
        }
      } else {
        this.showInputNeighborhood = false;

        this.mapSvc
          .getapi(e)
          .pipe(take(1))
          .toPromise()
          .then((res: any) => {
            if (res) {
              this.neighborhood = res.colonias;
              this.colonia_lead;
              let newCity = String(res.municipio).split(' ');
              for (let i = 0; i <= newCity.length - 1; i++) {
                if (newCity.length == 1) {
                  this.city =
                    newCity[i].charAt(0) + newCity[i].slice(1).toLowerCase();
                } else if (newCity.length > 1) {
                  this.city +=
                    newCity[i].charAt(0) +
                    newCity[i].slice(1).toLowerCase() +
                    ' ';
                }
              }
              let newState = String(res.estado).split(' ');
              for (let i = 0; i <= newState.length - 1; i++) {
                if (newState.length == 1) {
                  this.state =
                    newState[i].charAt(0) + newState[i].slice(1).toLowerCase();
                } else if (newState.length > 1) {
                  this.state +=
                    newState[i].charAt(0) +
                    newState[i].slice(1).toLowerCase() +
                    ' ';
                }
              }
              this.estado_lead = this.state;
              this.ciudad_lead = this.city;
              this.clienteForm.addControl(
                'ciudad',
                new UntypedFormControl(this.city)
              );
              this.clienteForm.addControl(
                'estado',
                new UntypedFormControl(this.state)
              );
            }
          });
      }
    }
  }

  createFormGroup() {
    let fecha_actual = moment().format('DD-MM-YYYY');
    let hora_actual = moment().format('hh:mm:ss a');
    return new UntypedFormGroup({
      nombre: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      apellido: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      identificacion: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(5),
      ]),
      correo: new UntypedFormControl('', [
        Validators.minLength(6),
        Validators.pattern(this.emailPattern),
      ]),
      telefono: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(10),
      ]),
      seg_correo: new UntypedFormControl(''),
      seg_telefono: new UntypedFormControl(''),
      cp: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(5),
      ]),
      colonia: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      calle: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
      no_exterior: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(1),
      ]),
      no_interior: new UntypedFormControl(''),
      pais: new UntypedFormControl('México'),
      descripcion: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(this.lenghtComment),
      ]),
      adicional_nombre: new UntypedFormControl('', [Validators.minLength(3)]),
      adicional_apellido: new UntypedFormControl('', [Validators.minLength(3)]),
      adicional_telefono: new UntypedFormControl('', [Validators.minLength(6)]),
      adicional_correo: new UntypedFormControl('', [
        Validators.minLength(6),
        Validators.pattern(this.emailPattern),
      ]),
      adicional_seg_correo: new UntypedFormControl(''),
      adicional_seg_telefono: new UntypedFormControl(''),
      fecha: new UntypedFormControl(fecha_actual),
      hora: new UntypedFormControl(hora_actual),
    });
  }

  get nombre() {
    return this.clienteForm.get('nombre');
  }
  get apellido() {
    return this.clienteForm.get('apellido');
  }
  get identificacion() {
    return this.clienteForm.get('identificacion');
  }
  get correo() {
    return this.clienteForm.get('correo');
  }
  get telefono() {
    return this.clienteForm.get('telefono');
  }
  get code() {
    return this.clienteForm.get('cp');
  }
  get colonia() {
    return this.clienteForm.get('colonia');
  }
  get calle() {
    return this.clienteForm.get('calle');
  }
  get no_exterior() {
    return this.clienteForm.get('no_exterior');
  }
  get adicional_nombre() {
    return this.clienteForm.get('adicional_nombre');
  }
  get adicional_apellido() {
    return this.clienteForm.get('adicional_apellido');
  }
  get adicional_telefono() {
    return this.clienteForm.get('adicional_telefono');
  }
  get adicional_correo() {
    return this.clienteForm.get('adicional_correo');
  }
  get descripcion() {
    return this.clienteForm.get('descripcion');
  }

  addNewNeighborhood(e) {
    if (e != 'Ingresar Colonia' && e != '' && e != undefined && e != null) {
      this.colonia_lead = e;
      this.showInputNeighborhood = false;
    }
    if (e == 'Ingresar Colonia') {
      this.showInputNeighborhood = true;
    } else {
      this.showInputNeighborhood = false;
    }
  }
  showInput() {
    this.showInputNeighborhood = true;
  }

  SaveForm() {
    if (this.userOnline.userOnline()) {
      if (this.inputCity !== '' && this.inputState !== '') {
        let city;
        let state;
        let newCity = String(this.inputCity).split(' ');
        for (let i = 0; i <= newCity.length - 1; i++) {
          if (newCity.length == 1) {
            city = newCity[i].charAt(0) + newCity[i].slice(1).toLowerCase();
          } else if (newCity.length > 1) {
            city +=
              newCity[i].charAt(0) + newCity[i].slice(1).toLowerCase() + ' ';
          }
        }
        let newState = String(this.inputState).split(' ');
        for (let i = 0; i <= newState.length - 1; i++) {
          if (newState.length == 1) {
            state = newState[i].charAt(0) + newState[i].slice(1).toLowerCase();
          } else if (newState.length > 1) {
            state +=
              newState[i].charAt(0) + newState[i].slice(1).toLowerCase() + ' ';
          }
        }
        this.clienteForm.addControl('ciudad', new UntypedFormControl(city));
        this.clienteForm.addControl('estado', new UntypedFormControl(state));
        this.ciudad_lead = city;
        this.estado_lead = state;
      }
      if (this.idL) {
        //escalar lead
        const customer = {
          nombre: this.nombre_lead,
          apellido: this.apellido_lead,
          identificacion: this.identificacion_lead,
          correo: this.correo_lead,
          telefono: this.telefono_lead,
          cp: this.cp_lead,
          colonia: this.colonia_lead,
          calle: this.calle_lead,
          no_exterior: this.no_exterior_lead,
          no_interior:
            this.no_interior_lead != undefined ? this.no_interior_lead : '',
          pais: 'México',
          ciudad: this.ciudad_lead,
          estado: this.estado_lead,
          comentario: this.comentario_lead,
          adicional_nombre:
            this.nombre_adicional_lead != undefined
              ? this.nombre_adicional_lead
              : '',
          adicional_apellido:
            this.apellido_adicional_lead != undefined
              ? this.apellido_adicional_lead
              : '',
          adicional_telefono:
            this.telefono_adicional_lead != undefined
              ? this.telefono_adicional_lead
              : '',
          adicional_correo:
            this.correo_adicional_lead != undefined
              ? this.correo_adicional_lead
              : '',
        };
        this.customerLead = customer;

        if (
          this.nombre_lead !== undefined &&
          this.apellido_lead !== undefined &&
          this.identificacion_lead !== undefined &&
          this.correo_lead !== undefined &&
          this.telefono_lead !== undefined &&
          this.cp_lead !== undefined &&
          this.colonia_lead !== undefined &&
          this.calle_lead !== undefined &&
          this.no_exterior_lead !== undefined &&
          this.ciudad_lead !== undefined &&
          this.estado_lead !== undefined &&
          this.comentario_lead !== undefined
        ) {
          this.checkCustomer(customer);
        } else {
          alert('Debe llenar todos los campos');
        }
      } else {
        const {
          adicional_nombre,
          adicional_apellido,
          adicional_telefono,
          adicional_correo,
        } = this.clienteForm.value;
        if (
          adicional_nombre !== '' &&
          adicional_apellido == '' &&
          adicional_correo == '' &&
          adicional_telefono == ''
        ) {
          alert(
            'Debe llenar todos los campos del contacto adicional o dejarlos todos vacios'
          );
        } else if (
          adicional_nombre == '' &&
          adicional_apellido !== '' &&
          adicional_correo == '' &&
          adicional_telefono == ''
        ) {
          alert(
            'Debe llenar todos los campos del contacto adicional o dejarlos todos vacios'
          );
        } else if (
          adicional_nombre == '' &&
          adicional_apellido == '' &&
          adicional_correo !== '' &&
          adicional_telefono == ''
        ) {
          alert(
            'Debe llenar todos los campos del contacto adicional o dejarlos todos vacios'
          );
        } else {
          this.checkCustomer(null);
        }
      }
    } else {
      alert('No tienes conexion a internet');
    }
  }

  checkCustomer(customer) {
    if (customer == null) {
      // Buscar si el cliente ya existe por telefono
      let telefono = this.clienteForm.value.telefono;
      let newTelefono = telefono.split(/\D/).join('');

      this.afs
        .collection('CUSTOMER', (ref) =>
          ref.where('cliente.telefono', '==', newTelefono).limit(1)
        )
        .valueChanges()
        .pipe(take(1))
        .toPromise()
        .then((res: any[]) => {
          if (res.length > 0) {
            this.openModal();
          } else {
            // Buscar si el cliente ya existe por identificacion
            let identificacion = this.clienteForm.value.identificacion;
            this.afs
              .collection('CUSTOMER', (ref) =>
                ref
                  .where('cliente.identificacion', '==', identificacion)
                  .limit(1)
              )
              .valueChanges()
              .pipe(take(1))
              .toPromise()
              .then((res: any[]) => {
                if (res.length > 0) {
                  this.openModal();
                } else {
                  // Buscar si el cliente ya existe por nombre y apellido
                  let nombre = String(
                    this.clienteForm.value.nombre
                  ).toLowerCase();
                  let apellido = String(
                    this.clienteForm.value.apellido
                  ).toLowerCase();
                  this.afs
                    .collection('CUSTOMER', (ref) =>
                      ref
                        .where('cliente.nombre', '==', nombre)
                        .where('cliente.apellido', '==', apellido)
                        .limit(1)
                    )
                    .valueChanges()
                    .pipe(take(1))
                    .toPromise()
                    .then((res: any[]) => {
                      if (res.length > 0) {
                        this.openModal();
                      } else {
                        this.saveCustomer(newTelefono, nombre, apellido);
                      }
                    });
                }
              });
          }
        });
    } else {

      // Buscar si el cliente ya existe por telefono
      let telefono = customer.telefono;
      let newTelefono = telefono.split(/\D/).join('');
      let nombre = String(customer.nombre).toLowerCase();
      let apellido = String(customer.apellido).toLowerCase();
      this.saveCustomerLead(newTelefono, nombre, apellido);
      // this.afs
      //   .collection('CUSTOMER', (ref) =>
      //     ref.where('cliente.telefono', '==', newTelefono).limit(1)
      //   )
      //   .valueChanges()
      //   .pipe(take(1))
      //   .toPromise()
      //   .then((res: any[]) => {
      //     if (res.length > 0) {
      //       this.openModal();
      //     } else {
      //       // Buscar si el cliente ya existe por identificacion
      //       let identificacion = customer.identificacion;
      //       this.afs
      //         .collection('CUSTOMER', (ref) =>
      //           ref
      //             .where('cliente.identificacion', '==', identificacion)
      //             .limit(1)
      //         )
      //         .valueChanges()
      //         .pipe(take(1))
      //         .toPromise()
      //         .then((res: any[]) => {
      //           if (res.length > 0) {
      //             this.openModal();
      //           } else {
      //             // Buscar si el cliente ya existe por nombre y apellido
      //             let nombre = String(customer.nombre).toLowerCase();
      //             let apellido = String(customer.apellido).toLowerCase();
      //             this.afs
      //               .collection('CUSTOMER', (ref) =>
      //                 ref
      //                   .where('cliente.nombre', '==', nombre)
      //                   .where('cliente.apellido', '==', apellido)
      //                   .limit(1)
      //               )
      //               .valueChanges()
      //               .pipe(take(1))
      //               .toPromise()
      //               .then((res: any[]) => {
      //                 if (res.length > 0) {
      //                   this.openModal();
      //                 } else {
      //                   this.saveCustomerLead(newTelefono, nombre, apellido);
      //                 }
      //               });
      //           }
      //         });
      //     }
      //   });
    }
  }

  saveCustomer(telefono: number, nombre: string, apellido: string) {
    let newName: string = '';
    let newLastName: string = '';
    let fullName = String(nombre).split(' ');
    for (let i = 0; i <= fullName.length - 1; i++) {
      newName +=
        fullName[i].charAt(0).toUpperCase() + fullName[i].slice(1) + ' ';
    }

    let fullLastName = String(apellido).split(' ');
    for (let i = 0; i <= fullLastName.length - 1; i++) {
      newLastName +=
        fullLastName[i].charAt(0).toUpperCase() +
        fullLastName[i].slice(1) +
        ' ';
    }

    this.clienteForm.value.telefono = telefono;
    this.clienteForm.value.nombre = newName;
    this.clienteForm.value.apellido = newLastName;
    if (this.clienteForm.valid) {
      this.id = this.generate_id.createId();
      let dateTime = {
        hora: moment().format('hh:mm:ss a'),
        fecha: moment().format('DD-MM-YYYY'),
        date: moment().format(''),
      };
      this.dbData.saveCustomer(this.clienteForm.value, dateTime, this.id);
      this.clienteForm.reset();
    } else {
      alert('Revisa que todos los campos esten correctos');
    }
  }

  saveCustomerLead(telefono: number, nombre: string, apellido: string) {
    let newName: string = '';
    let newLastName: string = '';
    let fullName = String(nombre).split(' ');
    for (let i = 0; i <= fullName.length - 1; i++) {
      newName +=
        fullName[i].charAt(0).toUpperCase() + fullName[i].slice(1) + ' ';
    }

    let fullLastName = String(apellido).split(' ');
    for (let i = 0; i <= fullLastName.length - 1; i++) {
      newLastName +=
        fullLastName[i].charAt(0).toUpperCase() +
        fullLastName[i].slice(1) +
        ' ';
    }

    this.customerLead.telefono = telefono;
    this.customerLead.nombre = newName;
    this.customerLead.apellido = newLastName;
    this.id = this.generate_id.createId();

    this.dbData.saveClient(this.customerLead, this.lead.id, this.index, this.leadUid, this.lead);
    // this.afs.collection('LEAD').doc(this.lead.id_document).set(
    //   {
    //     escalated: true,
    //   },
    //   { merge: true }
    // );

    this.resetForm();
  }

  resetForm() {
    this.nombre_lead = '';
    this.apellido_lead = '';
    this.telefono_lead = '';
    this.correo_lead = '';
    this.identificacion_lead = '';
    this.cp_lead = '';
    this.calle_lead = '';
    this.no_exterior_lead = '';
    this.no_interior_lead = '';
    this.colonia_lead = '';
    this.estado_lead = '';
    this.ciudad_lead = '';
    this.comentario_lead = '';
    this.nombre_adicional_lead = '';
    this.apellido_adicional_lead = '';
    this.telefono_adicional_lead = '';
    this.correo_adicional_lead = '';
  }

  openModal() {
    this.modalRef = this.modal.open(this.myModal, {
      size: 'sm',
      animation: true,
      centered: true,
      backdrop: true,
    });
  }
  closeModal() {
    this.modalRef = this.modal.dismissAll();
  }
  aparecer() {
    this.mostrar = !this.mostrar;
    this.texto = !this.texto;

    if (this.texto) {
      return (this.text = '-');
    }
    return (this.text = '+');
  }
  aparecer2() {
    this.mostrar2 = !this.mostrar2;
    this.texto2 = !this.texto2;

    if (this.texto2) {
      return (this.text2 = '-');
    }
    return (this.text2 = '+');
  }

  ngOnDestroy() { }
}
