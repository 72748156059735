import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/SERVICIOS/auth.service';
import { NotificationService } from 'src/app/SERVICIOS/notification.service';
import { NotificationsService } from 'src/app/SERVICIOS/socket/notifications.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],

})
export class AdminComponent implements OnInit {
  bloque1Normal = 'bloque1Normal';
  bloque2Normal = 'bloque2Normal';

  Dropoculto = 'Dropoculto';

  Notioculto = 'Notioculto';

  constructor(public notification: NotificationsService, public router: Router, public gonotification: NotificationService, private authsv: AuthService) {
  
  }

  goNotification(notification: any) {
    this.gonotification.goNotification(notification)
  }

  async onLogout() {
    try {
      await this.authsv.logout();
      this.router.navigate(['/login']);
    } catch (error) {
      console.log(error);
    }
  }
  ngOnInit(): void {
  }



}
