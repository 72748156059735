import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxImageCompressService } from "ngx-image-compress";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { AuthService } from "src/app/SERVICIOS/auth.service";
import { CargaService } from "src/app/SERVICIOS/carga.service";
import { GenerateIdService } from "src/app/SERVICIOS/generate-id.service";
import { Fileitem } from "../../MODULOS/ingresa/file";
import { User } from "../../MODULOS/user.interface";
import { Location } from "@angular/common";
import firebase from "firebase/compat/app";
import moment from "moment";

@Component({
  selector: "app-upload-files",
  templateUrl: "./upload-files.component.html",
  styleUrls: ["./upload-files.component.scss"],
})
export class UploadFilesComponent implements OnInit {
  idA: any;
  archivos = [];
  otros_archivos = [];
  estaSobreElemento = false;
  uploadPercent: Observable<number>;
  urlImage: Observable<string>;
  id: string;
  url: string | ArrayBuffer;
  urls = [];
  getImageMain: string = "";
  getUrlOthersImages = [];
  get_otros_archivos = [];
  communityPhotos = [];
  box_red: boolean = false;
  getoldimages = [];
  loading: boolean = false;
  user: User;
  urlsDoc = [];
  imgResultBeforeCompression: string = "";
  imgResultAfterCompression: string = "";
  idClient: any;
  index: any;
  idLead: any;
  identification: string;
  tipo: string = "";
  datacustomer: any;
  constructor(
    public _cargaImagenes: CargaService,
    private _location: Location,
    private generateId: GenerateIdService,
    public fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private afs: AngularFirestore,
    public auth: AuthService,
    private imageCompress: NgxImageCompressService,
  ) {}

  ngOnInit() {
    this.idA = this.route.snapshot.paramMap.get("idArticle");
    this.idClient = this.route.snapshot.paramMap.get("idClient");
    this.index = this.route.snapshot.paramMap.get("index");
    this.idLead = this.route.snapshot.paramMap.get("idLead");
    this.auth.user$
      .pipe(take(1))
      .toPromise()
      .then((user) => {
        this.afs
          .collection("USERS")
          .doc(user.uid)
          .valueChanges()
          .pipe(take(1))
          .toPromise()
          .then((data: any) => {
            this.user = data;
          });
        if (user.role == "COMMUNITY-MANAGE") {
          this.afs
            .collection("ARTICLE")
            .doc(this.idA)
            .valueChanges()
            .pipe(take(1))
            .toPromise()
            .then((data: any) => {
              this.getImageMain = data.imagen_principal.url;
              this.get_otros_archivos = data.imagenes;
              for (const imagen of data.imagenes) {
                this.otros_archivos.push(imagen);
                this.getoldimages.push(imagen);
                this.urls.push(imagen.url);
              }
            });
        }
        this.afs
          .collection("CUSTOMER")
          .doc(this.idClient)
          .valueChanges()
          .pipe(take(1))
          .toPromise()
          .then((data: any) => {
            this.datacustomer = data;
          });
      });
  }

  compressFile() {
    this.imageCompress.uploadFile().then(({ image, orientation }) => {
      this.imgResultBeforeCompression = image;

      this.imageCompress
        .compressFile(image, orientation, 50, 50)
        .then(async (compressedImage) => {
          //base64 to blob
          var byteString = atob(compressedImage.split(",")[1]);
          var mimeString = compressedImage.split(",")[0];
          mimeString = mimeString.split(":")[1].split(";")[0];
          var ab = new ArrayBuffer(byteString.length);
          var ia = new Uint8Array(ab);
          for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          var blob = new Blob([ab], { type: mimeString });

          var file = new File(
            [blob],
            `SET99_COMMUNITY_PHOTO${this.generateId.createId()}.jpg`,
            {
              type: "image/jpeg",
              lastModified: Date.now(),
            },
          );

          //CREAR UN FILELIST PARA PODER PASARLO A LA FUNCION
          var dt = new DataTransfer();
          dt.items.add(file);
          var fileList = dt.files;

          this._extraerArchivos_community(fileList, null);
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (event) => {
            let url = (<FileReader> event.target).result;
            this.urls.push(url);
          };
        });
    });
  }
  fileChange(e) {
    let fileList: FileList = e.target.files;
    if (fileList.length > 0) {
      //saber si es un pdf
      if (fileList[0].type == "application/pdf") {
        this._extraerArchivos_community(fileList, e);
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (event) => {
          let url = (<FileReader> event.target).result;
          this.urls.push(url);
        };
      } else {
        this._extraerArchivos_community(fileList, e);
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (event) => {
          let url = (<FileReader> event.target).result;
          this.urls.push(url);
        };
      }
    }
  }

  _extraerArchivos_otros(archivosLista: FileList) {
    for (const propiedad in Object.getOwnPropertyNames(archivosLista)) {
      const archivoTemporal = archivosLista[propiedad];
      if (this._archivoPuedeSerCargado_otros(archivoTemporal)) {
        const nuevoArchivo = new Fileitem(archivoTemporal);
        this.otros_archivos.push(nuevoArchivo);
        this.getUrlOthersImages.push(nuevoArchivo);
        if (this.user) {
          if (this.user.role != "COMMUNITY-MANAGE") {
            this.communityPhotos.push(nuevoArchivo);
          }
        }
      }
    }
  }
  _extraerArchivos_community(archivosLista: FileList, e) {
    for (const propiedad in Object.getOwnPropertyNames(archivosLista)) {
      const archivoTemporal = archivosLista[propiedad];
      const nuevoArchivo = new Fileitem(archivoTemporal);
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event) => {
        //agregar url a la imagen
        let url = (<FileReader> event.target).result;
        //nuevoArchivo.urlFile = url;
      };
      this.otros_archivos.push(nuevoArchivo);
    }
  }

  openPDF(file) {
    //data:application/pdf to pdf file
    var byteString = atob(file.split(",")[1]);
    var mimeString = file.split(",")[0];
    mimeString = mimeString.split(":")[1].split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    var filePdf = new File([blob], "SET99_COMMUNITY_PHOTO.pdf", {
      type: "application/pdf",
      lastModified: Date.now(),
    });
    var fileURL = URL.createObjectURL(filePdf);
    window.open(fileURL);

    //open pdf file
  }

  private _archivoPuedeSerCargado(archivo: File): boolean {
    if (
      !this._archivoYaFueDroppeado(archivo.name) &&
      this._esImagen(archivo.type)
    ) {
      return true;
    } else {
      return false;
    }
  }
  private _archivoPuedeSerCargado_otros(archivo: File): boolean {
    if (
      !this._archivoYaFueDroppeado_otros(archivo.name) &&
      this._esImagen(archivo.type)
    ) {
      return true;
    } else {
      return false;
    }
  }

  private _archivoYaFueDroppeado(nombreArchivo: string): boolean {
    for (const archivo of this.archivos) {
      if (archivo.imagen.nombreArchivo === nombreArchivo) {
        return true;
      }
    }
    return false;
  }
  private _archivoYaFueDroppeado_otros(nombreArchivo: string): boolean {
    for (const archivo of this.otros_archivos) {
      if (archivo.nombreArchivo === nombreArchivo) {
        return true;
      }
    }
    return false;
  }

  private _esImagen(tipoArchivo: string): boolean {
    return tipoArchivo === "" || tipoArchivo === undefined
      ? false
      : tipoArchivo.startsWith("image");
  }

  cargarImagenes() {
    if (this.otros_archivos.length > 0) {
      this.loading = true;
      //subir archivos a firebase
      for (let i = 0; i < this.otros_archivos.length; i++) {
        this._cargarImagenesFirebase(this.otros_archivos[i]);
      }
    }
  }
  _cargarImagenesFirebase(item) {
    const storageRef = firebase.storage().ref();
    const uploadTask: firebase.storage.UploadTask = storageRef
      .child(`aval_documentation/SET99_${item.nombreArchivo}`)
      .put(item.archivo);
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (
        snapshot: firebase.storage.UploadTaskSnapshot,
      ) => (item.progreso = (snapshot.bytesTransferred / snapshot.totalBytes) *
        100),
      (error) => console.error("Error al subir", error),
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          item.url = url;
          item.estaSubiendo = false;

          this.saveDocumentation({
            nombre: item.nombreArchivo,
            url: item.url,
            fecha: moment().format("DD-MM-YYYY"),
            hora: moment().format("hh:mm:ss a"),
            date: moment().format(),
            type: item.archivo.type,
          });
        });
      },
    );
  }
  saveDocumentation(documentation) {
    this.urlsDoc.push(documentation);
    if (this.urlsDoc.length == this.otros_archivos.length) {
      this.saveUrlDocumentation(this.urlsDoc);
    }
  }

  saveUrlDocumentation(urlsDoc) {
    this.afs
      .collection("CUSTOMER")
      .doc(this.idLead)
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((lead: any) => {
        let positives: Array<any> = lead.positives;

        for (let i = 0; i < positives.length; i++) {
          positives[i].documentationAvalComplete = true;
        }
        lead.positives = positives;

        this.afs.collection("CUSTOMER").doc(this.idLead).update(lead);

        this.datacustomer.aval.documentationAval = urlsDoc;
        this.datacustomer.aval.identification = this.tipo;
        this.afs
          .collection("CUSTOMER")
          .doc(this.idClient)
          .update({
            aval: this.datacustomer.aval,
            estatus: "habilitado",
            completado: true,
          })
          .then(() => {
            this.router.navigate(["/gestiona"]);
          });
      });
  }
  deleteImgOther(i) {
    this.urls.splice(i, 1);
    this.otros_archivos.splice(i, 1);
    if (i <= this.get_otros_archivos.length) {
      this.get_otros_archivos.splice(i, 1);
    }
  }
  deleteImg(i) {
    this.url;
    this.otros_archivos.splice(i, 1);
  }

  back() {
    this._location.back();
  }
}
