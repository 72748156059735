import { RecentActivityComponent } from './recent-activity/recent-activity.component';
import { LeasingContractComponent } from './leasing-contract/leasing-contract.component';
import { OthersArticlesComponent } from './others-articles/others-articles.component';
import { OwnArticlesComponent } from './own-articles/own-articles.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanEnterGuard } from 'src/app/SERVICIOS/can/can-enter.guard';
import { AuthGuard } from 'src/app/SERVICIOS/guards/auth.guard';
import { CommunityManageGuard } from 'src/app/SERVICIOS/guards/community-manage.guard';
import { DisabledGuard } from 'src/app/SERVICIOS/guards/disabled.guard';
import { SupportGuard } from 'src/app/SERVICIOS/guards/support.guard';

import { AdvanceArticleInformationComponent } from './advance-article-information/advance-article-information.component';
import { HistorysComponent } from './historys/historys.component';
import { IngresaHistoryFullviewComponent } from './ingresa-history-fullview/ingresa-history-fullview.component';
import { IngresaHistoryComponent } from './ingresa-history/ingresa-history.component';
import { RegistraHistoryFullviewComponent } from './registra-history-fullview/registra-history-fullview.component';
import { RegistraHistoryComponent } from './registra-history/registra-history.component';
import { RecentActivityFullviewComponent } from './recent-activity-fullview/recent-activity-fullview.component';
import { BankDataComponent } from './bank-data/bank-data.component';

const routes: Routes = [
  {
    path: 'historys',
    component: HistorysComponent,
    children: [
      { path: 'ingresa-history', component: IngresaHistoryComponent },
      {
        path: 'ingresa-history/:idCustomer/fullview',
        component: IngresaHistoryFullviewComponent,
      },
      { path: 'registra-history', component: RegistraHistoryComponent },
      {
        path: 'registra-history/:idLead/fullview',
        component: RegistraHistoryFullviewComponent,
      },
      {
        path: 'ingresa-history/:idArticle/advance',
        component: AdvanceArticleInformationComponent,
      },
      {
        path: 'ingresa-history/:idCustomer/own-articles',
        component: OwnArticlesComponent,
      },
      {
        path: 'ingresa-history/:idCustomer/others-articles',
        component: OthersArticlesComponent,
      }, {
        path: 'data-bank/:idCustomer',
        component: BankDataComponent,
      }, {
        path: 'recent-activity',
        component: RecentActivityComponent,
      }, {
        path: 'recent-activity-fullview/:id',
        component: RecentActivityFullviewComponent,
      },

    ],
    canActivate: [
      CanEnterGuard,
      AuthGuard,
      DisabledGuard,
      SupportGuard,
      CommunityManageGuard,
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HistorysRoutingModule { }
