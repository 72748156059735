import { filter, take } from "rxjs/operators";
import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Fileitem } from "../../MODULOS/ingresa/file";

import { DataDbService } from "src/app/SERVICIOS/data-db.service";
import { AuthService } from "src/app/SERVICIOS/auth.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { GenerateIdService } from "src/app/SERVICIOS/generate-id.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import moment from "moment";
import { RecentActivityInterface } from "src/app/interfaces/activity";
import firebase from "firebase/compat/app";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CorsService } from "src/app/SERVICIOS/cors.service";
import { SocketService } from "src/app/SERVICIOS/socket/socket.service";
declare let $: any;

@Component({
  selector: "app-recent-activity-fullview",
  templateUrl: "./recent-activity-fullview.component.html",
  styleUrls: ["./recent-activity-fullview.component.scss"],
})
export class RecentActivityFullviewComponent implements OnInit {
  responseText: string;
  contenido2 = "";
  archivo: any;
  archivos = [];
  otros_archivos = [];
  idRa: string;
  important: boolean = false;
  isUpload: boolean = false;
  isPdf: boolean = false;
  isImage: boolean = false;
  recentActivity: any;
  urlFile: string;
  user: any;
  id_user: string;
  isFullAdmin: boolean = false;
  isAdmin: boolean = false;
  idDocUser: string;
  userActivity: any;
  fileType: string;
  responses = [];
  get argumento() {
    return this.argumentForm.get("argument");
  }

  argumentForm = new UntypedFormGroup({
    argument: new UntypedFormControl("", [
      Validators.required,
      Validators.maxLength(1000),
    ]),
  });
  constructor(
    private dbData: DataDbService,
    private router: Router,
    private route: ActivatedRoute,
    private authSvc: AuthService,
    private afs: AngularFirestore,
    private generateId: GenerateIdService,
    public modal: NgbModal,
    private corsService: CorsService,
    private socket: SocketService,
    private _location: Location,
  ) {
  }

  ngOnInit(): void {
    this.authSvc.getCurrentUser().then((user) => {
      this.user = user;
      this.idRa = this.route.snapshot.paramMap.get("id");
      this.afs.collection("USERS").doc(user.uid).valueChanges().subscribe(
        (data: any) => {
          this.id_user = data.id;
          this.isFullAdmin = data.role == "FULL-ADMIN" ? true : false;

          this.isAdmin = data.role == "ADMIN" ? true : false;
          if (this.isFullAdmin || this.isAdmin) {
            this.afs.collectionGroup("RECENT-ACTIVITY").valueChanges()
              .subscribe((recent: any) => {
                recent.forEach((element: any) => {
                  if (element.id_document == this.idRa) {
                    this.idDocUser = element.recentActivity.uid_user;
                    this.afs.collection("USERS").doc(
                      element.recentActivity.uid_user,
                    ).collection("RECENT-ACTIVITY").doc(this.idRa)
                      .valueChanges().subscribe((recent) => {
                        this.recentActivity = recent;
                      });
                  }
                });
              });
          } else {
            this.afs.collection("USERS").doc(user.uid).collection(
              "RECENT-ACTIVITY",
            ).doc(this.idRa).valueChanges().subscribe((recent) => {
              this.recentActivity = recent;
            });
          }
        },
      );
    });
  }
  showModal() {
    $(".ui.basic.modal.one").modal("hide");
    $(".ui.basic.modal.one").modal("show");
  }
  closeModal() {
    $(".ui.basic.modal.one").modal("hide");
    $(".ui.basic.modal.two").modal("hide");
    this.modal.dismissAll();
  }
  reply() {
    console.log("reply");
  }

  likeImportant() {
    this.important = !this.important;
    this.recentActivity.recentActivity.important = true;
    this.afs.collection("USERS").doc(this.user.uid).collection(
      "RECENT-ACTIVITY",
    ).doc(this.idRa).update({
      recentActivity: this.recentActivity.recentActivity,
    });
  }
  likenoImportant() {
    this.important = !this.important;
    this.recentActivity.recentActivity.important = false;
    this.afs.collection("USERS").doc(this.user.uid).collection(
      "RECENT-ACTIVITY",
    ).doc(this.idRa).update({
      recentActivity: this.recentActivity.recentActivity,
    });
  }

  noArchivar() {
    this.recentActivity.recentActivity.archived = false;
    this.afs.collection("USERS").doc(this.user.uid).collection(
      "RECENT-ACTIVITY",
    ).doc(this.idRa).update({
      recentActivity: this.recentActivity.recentActivity,
    });
  }

  archivar() {
    this.recentActivity.recentActivity.archived = true;

    this.afs.collection("USERS").doc(this.user.uid).collection(
      "RECENT-ACTIVITY",
    ).doc(this.idRa).update({
      recentActivity: this.recentActivity.recentActivity,
    });
  }

  thisIsAnError() {
    this.recentActivity.recentActivity.isError = true;

    this.afs.collection("USERS").doc(
      (this.isFullAdmin) ? this.idDocUser : this.user.uid,
    ).collection("RECENT-ACTIVITY").doc(this.idRa).update({
      recentActivity: this.recentActivity.recentActivity,
    });
  }
  fileChange(e) {
    let file = e.target.files[0];
    this.archivo = file;
    let fileList: FileList = e.target.files;
    if (fileList.length > 0) {
      //saber si es un pdf
      if (fileList[0].type == "application/pdf") {
        this._extraerArchivos_community(fileList, e);
        // let reader = new FileReader();
        // reader.readAsDataURL(e.target.files[0]);
        // reader.onload = (event) => {
        //   let url = (<FileReader>event.target).result;
        //   this.urls.push(url);
        // };
      } else {
        this._extraerArchivos_community(fileList, e);
        // let reader = new FileReader();
        // reader.readAsDataURL(e.target.files[0]);
        // reader.onload = (event) => {
        //   let url = (<FileReader>event.target).result;
        //   this.urls.push(url);
        // };
      }
    }
  }

  _extraerArchivos_community(archivosLista: FileList, e) {
    for (const propiedad in Object.getOwnPropertyNames(archivosLista)) {
      const archivoTemporal = archivosLista[propiedad];
      const nuevoArchivo = new Fileitem(archivoTemporal);
      this.otros_archivos[0] = nuevoArchivo;
    }
  }
  cargarImagenes() {
    this.isUpload = true;
    this._cargarImagenesFirebase(this.otros_archivos[0]);
  }
  _cargarImagenesFirebase(item) {
    const storageRef = firebase.storage().ref();
    const uploadTask: firebase.storage.UploadTask = storageRef
      .child(`documentation/${item.nombreArchivo}`)
      .put(item.archivo);
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (
        snapshot: firebase.storage.UploadTaskSnapshot,
      ) => (item.progreso = (snapshot.bytesTransferred / snapshot.totalBytes) *
        100),
      (error) => console.error("Error al subir", error),
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          item.url = url;
          item.estaSubiendo = false;

          this.saveFile({
            nombre: item.nombreArchivo,
            url: item.url,
            date: moment().format(),
            type: item.archivo.type,
          });
        });
      },
    );
  }
  saveFile(dataArchive) {
    let objectFile = {
      nombre: dataArchive.nombre,
      url: dataArchive.url,

      date: dataArchive.date,
      type: dataArchive.type,
      uid: this.user.uid,
    };

    let dataFile = firebase.firestore.FieldValue.arrayUnion(objectFile);
    this.afs.collection("USERS").doc(
      (this.isFullAdmin) ? this.idDocUser : this.user.uid,
    ).collection("RECENT-ACTIVITY").doc(this.idRa).set({
      files: dataFile,
    }, { merge: true }).then((e) => {
      console.log("Imagenes cargadas");
      this.isUpload = false;
      this.otros_archivos = [];
      this.modal.dismissAll();
    }).catch((e) => {
      console.log(e);
    });
  }
  sendResponse() {
    if (this.responseText.length > 0) {
      this.sendResponseToFirebase();
    }
  }
  sendResponseToFirebase() {
    //poner en el doc el is del full admin
    if (this.isFullAdmin) {
      this.afs.collection("USERS").doc(this.idDocUser).collection(
        "RECENT-ACTIVITY",
      ).doc(this.idRa)
        .update({
          idFullAdmin: this.user.uid,
        });
    }

    let objectResponse = {
      response: this.responseText,
      date: moment().format(),
      dataArchive: "no hay datos",

      from: this.isFullAdmin ? "FULL-ADMIN" : "USER",
      uid: this.user.uid,
      id: this.id_user,
    };
    let objResponse = firebase.firestore.FieldValue.arrayUnion(objectResponse);

    this.afs.collection("USERS").doc(
      (this.isFullAdmin) ? this.idDocUser : this.user.uid,
    ).collection("RECENT-ACTIVITY").doc(this.idRa).set({
      responses: objResponse,
    }, { merge: true }).then((e) => {
      this.afs.collection("USERS").doc(
        (this.isFullAdmin) ? this.idDocUser : this.user.uid,
      ).collection("RECENT-ACTIVITY").doc(this.idRa)
        .get().pipe(take(1)).toPromise().then((recentActivity: any) => {
          let dataRecentActivity = recentActivity.data();
          if (this.isFullAdmin) {
            let not = {
              "admin": true,
              "uid": this.idDocUser,
              "uidAdmin": this.user.uid,
              id_document: dataRecentActivity.id_document,
            };
            this.socket.io.emit("response-activity", not);
            //mandar notificacion al usuario
          } else {
            if (dataRecentActivity.idFullAdmin != undefined) {
              let not = {
                "admin": false,
                "uid": this.user.uid,
                "uidAdmin": dataRecentActivity.idFullAdmin,
                "id_document": dataRecentActivity.id_document,
              };
              this.socket.io.emit("response-activity", not);
            }

            //mandar notificacion al full admin
            //
          }
        });
    }).catch((e) => {
      console.log(e);
    });
  }
  goToTheObservationSite(recentActivity) {
    // this.router.navigate([recentActivity.routeObservation])
    let idDoc: String = recentActivity["id_document_component"];
    let from: String = recentActivity["from"];
    let isFullview: boolean = recentActivity["isFullview"];
    if (from == "clientes") {
      if (isFullview) {
        this.router.navigate([
          "historys/ingresa-history/" + idDoc + "/fullview",
        ]);
      }
    }
    if (from == "leads" || from == "lead") {
      if (isFullview) {
        this.router.navigate([
          "historys/registra-history/" + idDoc + "/fullview",
        ]);
      }
    }
    if (from == "productos" || from == "articulos") {
      if (isFullview) {
        this.router.navigate([
          "historys/ingresa-history/" + idDoc + "/own-articles",
        ]);
      }
    }
    if (from == "support" || from == "soporte") {
      if (isFullview) {
        this.router.navigate([
          "/support",
        ]);
      }
    }
  }

  openLG1(contenido1) {
    this.afs.collection("USERS").doc(
      (this.isFullAdmin) ? this.idDocUser : this.user.uid,
    ).collection("RECENT-ACTIVITY").doc(this.idRa)
      .valueChanges().subscribe((recentActivity: any) => {
        this.responses = recentActivity.responses;
      });
    this.modal.open(contenido1, { size: "lg", centered: true });
  }

  openLG2(contenido2) {
    this.contenido2 = contenido2;
    this.afs.collection("USERS").doc(
      (this.isFullAdmin) ? this.idDocUser : this.user.uid,
    ).collection("RECENT-ACTIVITY").doc(this.idRa)
      .valueChanges().subscribe((recentActivity: any) => {
        if (recentActivity.files.length > 0) {
          this.responses = recentActivity.files;
        }
      });
    this.modal.open(contenido2, { size: "lg", centered: true });
  }

  openLG3(contenido2) {
    this.afs.collection("USERS").doc(
      (this.isFullAdmin) ? this.idDocUser : this.user.uid,
    ).collection("RECENT-ACTIVITY").doc(this.idRa)
      .valueChanges().subscribe((recentActivity: any) => {
        if (recentActivity.responses.length > 0) {
          this.responses = recentActivity.responses.filter((r) =>
            r.dataArchive != "no hay datos"
          );
        }
      });
    this.modal.open(contenido2, { size: "lg", centered: true });
  }

  seeArchive(dataFile) {

    this.modal.dismissAll();

    if (dataFile.type == "application/pdf") {
      //abrir el pdf en otra pestaña
      this.isPdf = true;
      this.urlFile = dataFile.url;
      this.fileType = dataFile.type;
      window.open(this.urlFile, "_blank");
    } else {
      this.isPdf = false;
      this.urlFile = dataFile.url;
      this.fileType = dataFile.type;
      $(".ui.basic.modal.two").modal("show");
    }
  }

  showModalUploadFile(contenido3) {
    this.modal.open(contenido3, { size: "sm", centered: true });
  }
  closeModalViewer() {
    $(".ui.basic.modal.two").modal("hide");
    this.modal.open(this.contenido2, { size: "lg", centered: true });
  }
}
