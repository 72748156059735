import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/SERVICIOS/auth.service';
import { GenerateIdService } from 'src/app/SERVICIOS/generate-id.service';
import firebase from 'firebase/compat/app';
import { User } from 'src/app/COMERCIAL/MODULOS/user.interface';

@Component({
  selector: 'app-add-email',
  templateUrl: './add-email.component.html',
  styleUrls: ['./add-email.component.scss']
})
export class AddEmailComponent implements OnInit {

  uid: string;
  private emailPattern: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  isChecked: boolean;
  invalid: boolean = false;
  message_error: string;
  codeError: any;
  messageError: string;
  registerForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.minLength(5), Validators.pattern(this.emailPattern)]),
    password: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
    repeat_password: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
  })


  get email() { return this.registerForm.get('email') }
  get password() { return this.registerForm.get('password') }
  get repeat_password() { return this.registerForm.get('repeat_password') }
  get same_password() { return this.registerForm.get('password').value === this.registerForm.get('repeat_password').value ? null : { 'mismatch': true }; }


  constructor(private authSvc: AuthService, public afAuth: AngularFireAuth, private route: ActivatedRoute, private generateId: GenerateIdService, private router: Router, private afs: AngularFirestore,) { }


  ngOnInit(): void {
    this.uid = this.route.snapshot.paramMap.get("uid");


  }

  async onRegister() {
    if (this.registerForm.valid) {


      const { email, password } = this.registerForm.value;
      try {

        //saber si el usuario ya existe

        this.afs.collection('USERS', ref => ref.where('email', '==', email)).valueChanges().subscribe((user: any) => {
          if (user.length > 0) {
            this.codeError = true
            this.messageError = "Este correo electrónico ya está registrado."
            console.log('invalido');

          } else {

            //agregar provedor de correo al usuario existente
            let user = firebase.auth().currentUser;
            user.linkWithCredential(firebase.auth.EmailAuthProvider.credential(email, password)).then((confirmationResult) => {
              this.afs.collection('USERS').doc(this.uid).update({
                email: email,
                emailVerified: false,

              }).then(() => { 
                this.router.navigate(['/verification-email']);
              })
            }).catch((error) => {
              console.log('error ==>', error);
              
              this.getError(error);
            }
            );
            // //agregar provedor de correo al usuario existente
            // let user = firebase.auth().currentUser;
            // user.linkWithCredential(firebase.auth.EmailAuthProvider.credential(email, password)).then((confirmationResult) => {
            //   this.router.navigate(['/pagina-principal']);
            //   // ...
            // }).catch((error) => {
            //   // Error; SMS not sent
            //   // ...
            //   console.log('error ==>', error);
            // }
            // );
          }
        })


        // const user = await this.authSvc.register(email, password, name, lastname, this.fecha_registro);
        // let getUser: any = user;
        // if (getUser.code !== undefined && getUser.code !== undefined) {
        //   this.getError(getUser);
        // }
      }
      catch (error) {
      }

    }



  }
  isValidField(field: string): boolean {
    return (this.registerForm.get(field).touched || this.registerForm.get(field).dirty)
      && this.registerForm.get(field).valid
  }


  getError(error) {
    this.codeError = error.code;
    if (this.codeError === 'auth/user-not-found') {
      this.messageError = 'No hay ningún registro de usuario que corresponda a esta información. Es posible que se haya eliminado al usuario.';
    } else if (this.codeError === 'auth/wrong-password') {
      this.messageError = 'La contraseña no es correcta.';
    } else if (this.codeError === 'auth/invalid-email') {
      this.messageError = 'El correo electrónico no es válido.';
    } else if (this.codeError === "auth/email-already-in-use") {
      this.messageError = 'El correo electrónico ya está registrado.';
    }
    else {
      this.messageError = 'No se pudo registrar al usuario';
    }

    // if (this.authSvc.register_code_error == "auth/email-already-in-use") {
    //   this.invalid = true;
    //   this.message_error = "El correo electrónico ya está en uso"
    // }
  }
  checked(checked: boolean) {
    this.isChecked = checked;
  }

}
