<div class="main">
  <div class="fx-title  mb-5 mt-4">
    <i class="large arrow left icon" (click)="back()" style="cursor:pointer;"></i>
    <h2 class="title" style="margin-left: 10px;">Información del registro</h2>
    <div></div>
  </div>

  <div class="subtitle mb-3" style="    display: flex;
  flex-direction: row;
  justify-content: space-between;">
    <h5>Información de cliente</h5>
    <!-- <button class="ui primary button" (click)="escalateToCustomer()">Escalar a cliente</button> -->
  </div>


  <div *ngIf="todo">

    <div class="shadow-lg p-3 mb-5card" *ngIf="todo.cliente.nombre != '' && !updateUserData">
      <div class="card-income ">
        <div class="info-income">
          <div class="lead" style="font-weight: bold;">Nombre</div>
          <p>{{ todo.cliente.nombre}} {{ todo.cliente.apellido }}</p>
        </div>

        <div class="info-income">
          <div class="lead mt-2" style="font-weight: bold;">Teléfono</div>
          <p>{{ todo.cliente.telefono}}</p>
        </div>

        <div class="info-income">
          <div class="lead mt-2" style="font-weight: bold;">Correo</div>
          <p *ngIf=" todo.cliente.correo !== '' ">{{ todo.cliente.correo }}</p>
          <p *ngIf=" todo.cliente.correo == '' ">Correo no ingresado</p>
        </div>
        <div class="info-income">
          <div class="lead mt-2" style="font-weight: bold;">Fecha de registro</div>
          <p>{{ todo.cliente.fecha}}</p>
        </div>




      </div>


      <div style=" margin: 10px 10px;">
        <div *ngIf='my_lead'>
          <button class="ui button" (click)="updateDataUser(todo)">
            Actualizar
          </button>
        </div>


      </div>

    </div>
  </div>





  <div class="table-responsive" *ngIf="updateUserData">

    <div>
      <form [formGroup]="userForm">
        <table class="table">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Apellidos</th>
              <th scope="col">Correo Electrónico</th>
              <th scope="col"> Número de Contacto</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input type="text" [ngClass]="{invalid:nombre.invalid && nombre.dirty}" name="nombre"
                  class="form-control" formControlName="nombre" [placeholder]="todo.cliente.nombre" />
              </td>
              <td>
                <input type="text" [ngClass]="{invalid:apellido.invalid && apellido.dirty}" name="apellido"
                  class="form-control" formControlName="apellido" [placeholder]="todo.cliente.apellido" />
              </td>
              <td>
                <input type="text" [ngClass]="{invalid:correo.invalid && correo.dirty}" class="form-control"
                  name="correo" formControlName="correo" [placeholder]="todo.cliente.correo" />
              </td>
              <td>
                <input type="text" class="form-control" name="telefono" formControlName="telefono"
                  [placeholder]="todo.cliente.telefono" />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>



  <div class="buttons mt-1" *ngIf="updateUserData">
    <button class="ui primary button" [disabled]="userForm.invalid" (click)="update()">
      Actualizar
    </button>
    <button class="ui button" (click)="cancelUpdate()">
      Cancelar
    </button>
    <div class="mt-3">
      <i class="exclamation circle icon"></i>
      No necesita aprobación
    </div>
  </div>


  <div class="subtitle mb-3 mt-4">
    <h5>Contacto Adicional</h5>
  </div>


  <div *ngIf="todo">
    <div class="shadow-lg p-3 mb-5card" *ngIf="todo.cliente.adicional_nombre != '' && !updateUserAdData">
      <div class="card-income ">
        <div class="info-income">
          <div class="lead" style="font-weight: bold;">Nombre</div>
          <p>{{ todo.cliente.adicional_nombre}} {{ todo.cliente.adicional_apellido }}</p>
        </div>

        <div class="info-income">
          <div class="lead mt-2" style="font-weight: bold;">Teléfono</div>
          <p>{{ todo.cliente.adicional_telefono}}</p>
        </div>

        <div class="info-income">
          <div class="lead mt-2" style="font-weight: bold;">Correo</div>
          <p *ngIf=" todo.cliente.adicional_correo !== '' ">{{ todo.cliente.adicional_correo }}</p>
          <p *ngIf=" todo.cliente.adicional_correo == '' ">Correo no ingresado</p>
        </div>
        <div class="info-income">
          <div class="lead mt-2" style="font-weight: bold;">Fecha de registro</div>
          <p>{{ todo.cliente.fecha}}</p>
        </div>




      </div>


      <div style=" margin: 10px 10px;">
        <div *ngIf='my_lead'>
          <button class="ui button" (click)="updateDataAdUser()">
            Actualizar
          </button>
        </div>


      </div>

    </div>
  </div>






  <div class="table-responsive" *ngIf="updateUserAdData">


    <div>
      <form [formGroup]="userAdForm">
        <table class="table">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Apellidos</th>
              <th scope="col">Número de Contacto</th>
              <th scope="col">Correo Electrónico</th>

            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input type="text" [ngClass]="{invalid:adnombre.invalid && adnombre.dirty}" name="nombre"
                  class="form-control" formControlName="adicional_nombre"
                  [placeholder]="todo.cliente.adicional_nombre" />
              </td>
              <td>
                <input type="text" [ngClass]="{invalid:adapellido.invalid && adapellido.dirty}" name="apellido"
                  class="form-control" formControlName="adicional_apellido"
                  [placeholder]="todo.cliente.adicional_apellido" />
              </td>
              <td>
                <input type="text" [ngClass]="{invalid:adtelefono.invalid && adtelefono.dirty}" class="form-control"
                  name="telefono" formControlName="adicional_telefono"
                  [placeholder]="todo.cliente.adicional_telefono" />
              </td>
              <td>
                <input type="text" [ngClass]="{invalid:adcorreo.invalid && adcorreo.dirty}" class="form-control"
                  name="correo" formControlName="adicional_correo" [placeholder]="todo.cliente.adicional_correo" />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>

  <div class="buttons mt-1" *ngIf='updateUserAdData'>
    <button class="ui primary button" [disable]="userAdForm.invalid" (click)="updateUserAd()"
      *ngIf="!addContactAdicional">
      Actualizar
    </button>
    <button class="ui button" (click)="addContactAd()" *ngIf="addContactAdicional">
      Agregar
    </button>
    <button class="ui button" (click)="cancelUpdateAd()">
      Cancelar
    </button>
    <div class="mt-3" *ngIf="!addContactAdicional">
      <i class="exclamation circle icon"></i>
      No necesita aprobación
    </div>
    <div class="mt-3" *ngIf="addContactAdicional">
      <i class="exclamation circle icon"></i>
      No necesita aprobación
    </div>
  </div>




  <div *ngIf="ad_name == '' && !updateUserAdData" style="width: 90%; margin: auto; display: flex;flex-direction: column;
  align-items: center;">
    <button class="ui primary button" *ngIf="my_lead" (click)="updateDataAdUser()">Agregar Contacto Adicional</button>
    <p *ngIf='!my_lead'> No hay Información disponlible</p>
  </div>


  <ng-template #myModal>
    <div class="main">
      <div *ngIf="history; else noData">
        <div class="historys ad_update" *ngFor="let history of history">
          <!-- <p>{{history.history.nombre}}</p> -->
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Nombre</th>
                <th scope="col">Apellidos</th>
                <th scope="col">Número de Contacto</th>
                <th scope="col">Correo Electrónico</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{history.history.nombre}}
                </td>
                <td>
                  {{history.history.apellido}}
                </td>
                <td>
                  {{history.history.telefono}}
                </td>
                <td>
                  {{history.history.correo}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <ng-template #noData>
        <p class="text-center">No hay datos</p>
      </ng-template>
    </div>
  </ng-template>



  <ng-template #myModal_t>
    <div class="main">
      <div *ngIf="history_ad !== []; else noData">
        <div class="historys ad_update" *ngFor="let history of history_ad">
          <!-- <p>{{history.history.nombre}}</p> -->
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Nombre</th>
                <th scope="col">Apellidos</th>
                <th scope="col">Número de Contacto</th>
                <th scope="col">Correo Electrónico</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{history.history.adicional_nombre}}
                </td>
                <td>
                  {{history.history.adicional_apellido}}
                </td>
                <td>
                  {{history.history.adicional_telefono}}
                </td>
                <td>
                  {{history.history.adicional_correo}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <ng-template #noData>
        <p class="text-center">No hay datos</p>
      </ng-template>
    </div>
  </ng-template>


  <ng-template #myAlertModal>
    <div class="main">
      <div class="container-modal">
        <modal-content>
          <h5 class="lead">Este número ya está relacionado con otro usuario</h5>
        </modal-content>
        <modal-footer class="btn-options mt-4">
          <button class="ui button fs-mini" (click)="closeModal()">
            De Acuerdo
          </button>
        </modal-footer>
      </div>
    </div>
  </ng-template>








  <div class="subtitle fx mt-4">
    <h5>Relación de artículos</h5>
    <button (click)="addNewCommodity()" *ngIf='my_lead' class="ui primary button">
      Añadir nuevo artículo
    </button>
  </div>
  <div class="table-responsive">
    <div>
      <table class="table">
        <thead class="thead-dark">
          <tr>
            <th scope="col">ID de artículo</th>
            <th scope="col">Estatus</th>
            <th scope="col">Observaciones</th>
            <th scope="col">Fecha de asignación</th>
            <th scope="col" *ngIf='my_lead'></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody *ngIf="pos_neg">
          <tr *ngFor="let todo of pos_neg">
            <td>{{ todo.id_articulo }}</td>
            <td>
              <div *ngIf="todo.positive != null">
                <div class="d-flex flex-column  justify-content-center">
                  <b *ngIf="todo.positive.progress != null && todo.positive.progress!= undefined ">{{
                    todo.positive.progress
                    |
                    titlecase}}</b>
                  <a (click)="progessDetail(todo)">Ver detalles del progeso</a>
                </div>
                <b
                  *ngIf="todo.positive.progress == null || todo.positive.progress == undefined || todo.positive == null || todo.positive == undefined ">
                  Esperando
                  confirmación de pago</b>
              </div>
              <div *ngIf="todo.positive == null">
                <b>
                  Esperando
                  confirmación de pago
                </b>
              </div>


            </td>
            <!-- <td>{{ todo.argumento | titlecase}}</td> -->
            <td *ngIf="todo.argumento !== 'No hay observaciones'"><button class="ui button"
                (click)="viewObservation(todo.argumento)">Ver Observaciones</button></td>
            <td *ngIf="todo.argumento == 'No hay observaciones'">No hay observaciones</td>

            <td>{{ todo.fecha }}</td>
            <td *ngIf='my_lead'>
              <button class="ui button" style="display: inline-block" (click)="modalPositive(todo)"
                *ngIf="todo.estatus == 'negativo' && !todo.cambio">Retomar como positivo</button>
              <!-- <button class="ui button" style="display: inline-block" (click)="modalNegative(todo)"
                *ngIf="todo.estatus == 'positivo' && !todo.cambio">Pasar a negativo</button> -->
            </td>
            <td>
              <button class="ui button" (click)="routerItem(todo)">Ver Articulo</button>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="!pos_neg">
        <p class="text-center mb-3">No hay datos para mostrar por el momento.</p>
      </div>
    </div>
  </div>
  <div class="subtitle fx mt-4">
    <h5>Artículos sin relacionar</h5>
  </div>
  <div class="table-responsive">
    <div>
      <table class="table">
        <thead class="thead-dark">
          <tr>
            <th scope="col">ID de artículo</th>
            <th scope="col">Estatus</th>
            <th scope="col">Observaciones</th>
            <th scope="col">Fecha de asignación</th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody *ngIf="notAssigned">
          <tr *ngFor="let todo of notAssigned">
            <td>{{ todo.id }}</td>
            <td><b>Por definir</b></td>
            <td>No hay observaciones</td>

            <td>Sin asignar</td>

            <td>
              <button class="ui button" (click)="routerItemNotAssigned(todo)">Ver Articulo</button>
            </td>
            <td>
              <button class="ui button primary" (click)="routerGestiona()">Ir a Gestiona</button>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
  <div class="subtitle fx mt-5">
    <h5>Tags Seleccionados</h5>
    <button class="ui button" *ngIf=" my_lead && (!editAllTags && tags)" (click)="editTags()">Editar</button>
    <button class="ui primary button" *ngIf=" my_lead && (!editAllTags && !tags)" (click)="editTags()">Agregar</button>
  </div>
  <ul class="ks-cboxtags1" *ngIf="!editAllTags && tags">
    <li *ngFor="let caract of tags">
      <label *ngIf="caract.seleccionado">
        {{ caract.descripcion }}
      </label>
    </li>
  </ul>
  <p class="text-center mb-5" *ngIf="!editAllTags && !tags">No hay tags Seleccionados</p>

  <div class="box" *ngIf="contactForm && editAllTags">
    <!-- <form [formGroup]="contactForm" (ngSubmit)="updateTags()">
      <ul class="kss-cboxtags" style="margin-bottom: 0;" formArrayName="caracteristicas">
        <li *ngFor="let caracteritica of caracteristicas.controls;
                  let i = index
                " [formGroupName]="i">
          <input id="{{
                    'caracteritica-' + caracteritica.controls['id'].value
                  }}" formControlName="seleccionado" type="checkbox"
            [value]="caracteritica.controls['seleccionado'].value" />
          <label for="{{
                    'caracteritica-' + caracteritica.controls['id'].value
                  }}">
            {{ caracteritica.value.descripcion }}
          </label>
        </li>
      </ul>
      <div class="tags-info mb-4">
        <i class="exclamation circle icon"></i>
        No necesita aprobación
      </div>
      <div class="text-center mb-4">
        <div class="ui button" (click)="cancelUpdateTags()">Cancelar</div>
        <button class="ui primary button" [disabled]="contactForm.invalid">
          Actualizar
        </button>
      </div>
    </form> -->
    <ul class="ks-cboxtags">
      <li *ngFor="let caracteristica of features; let i = index">
        <input (input)="selected(i)" id="{{ 'caracteritica-' + caracteristica.id }}"
          [checked]="caracteristica.seleccionado" type="checkbox" />
        <label for="{{ 'caracteritica-' + caracteristica.id }}">
          {{ caracteristica.descripcion }}
        </label>
      </li>
    </ul>
  </div>



  <div style="display: flex; justify-content:end;" class="mt-5" *ngIf="auth.userFullAdmin(user)">
    <div class="ui primary button" (click)="createAnObservation()">Hacer una observación</div>
  </div>






</div>


<div class="ui basic modal one" style="width: 100%; margin: 25vh auto; overflow: hidden;">
  <div class="ui icon header text-center">
    <i class="exclamation icon"></i>
    ¿Estás seguro de marcar este articulo como Positivo?
  </div>
  <div class="content text-center">
    <p>Esta acción sólo se prodrá realizar una vez</p>
  </div>
  <div class="actions" style="display: flex; justify-content: center;">
    <div class="ui red basic cancel inverted button" (click)="hideModalPositive()">
      <i class="remove icon"></i>
      No
    </div>
    <div class="ui green ok inverted button" (click)="articlePositive()">
      <i class="checkmark icon"></i>
      Sí
    </div>
  </div>
</div>


<div class="ui basic modal two" style="width: 100%; margin: 25vh auto; overflow: hidden;">
  <div class="ui icon header text-center">
    <i class="exclamation icon"></i>
    ¿Estás seguro de marcar este articulo como Negativo?
  </div>
  <div class="content text-center">
    <p>Esta acción sólo se prodrá realizar una vez</p>
  </div>
  <div class="actions" style="display: flex; justify-content: center;">
    <div class="ui red basic cancel inverted button" (click)="hideModalNegative()">
      <i class="remove icon"></i>
      No
    </div>
    <div class="ui green ok inverted button" (click)="articleNegative()">
      <i class="checkmark icon"></i>
      Sí
    </div>
  </div>
</div>
<div class="ui basic modal createObservation" style="width: 100%; margin: 25vh auto; overflow: hidden;">
  <div class="ui icon header text-center">
    <h1> Escribe la observación</h1>
  </div>
  <div class="content text-center">
    <form [formGroup]="observationForm" (ngSubmit)="sendObservation()">
      <div class="row mb">
        <textarea formControlName="observation" [ngClass]="{invalid:observation.invalid && observation.dirty}"
          class="form-control" name="observation" rows="4"></textarea>
      </div>
    </form>
  </div>
  <div class="actions" style="display: flex; justify-content: center;">
    <div class="ui red basic cancel inverted button" (click)="hideModalNegative()">
      <i class="remove icon"></i>
      Cancelar
    </div>
    <div class="ui green ok inverted button" (click)="sendObservation()">
      <i class="checkmark icon"></i>
      Enviar Observación
    </div>
  </div>
</div>

<ng-template #modal_update>
  <div class="main">
    <div class="container-modal">
      <modal-content>
        <h5 class="lead">Se ha enviado una solicitud de cambio</h5>
      </modal-content>
      <modal-footer class="btn-options">
        <button class="ui button" (click)="closeModalUpdate()" style="margin-right: 5px">
          Cerrar
        </button>
        <button class="ui button" (click)="goToChanges()">
          Ir a cambios
        </button>
      </modal-footer>
    </div>
  </div>
</ng-template>

<ng-template #modal_observation>
  <div class="main">
    <div class="container-modal">
      <modal-content>
        <h5 class="lead text-center">{{txt_observation}}</h5>
      </modal-content>
      <modal-footer class="btn-options">
        <button class="ui button" (click)="closeModalUpdate()">
          Cerrar
        </button>
      </modal-footer>
    </div>
  </div>
</ng-template>