import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { DataDbService } from 'src/app/SERVICIOS/data-db.service';
import { AuthService } from 'src/app/SERVICIOS/auth.service';
import { SocketService } from 'src/app/SERVICIOS/socket/socket.service';
import firebase from 'firebase/compat/app';
import { Gallery, GalleryItem, ImageItem } from 'ng-gallery';
import { Subscription } from 'rxjs';
import { User } from '../../MODULOS/user.interface';
import { DownloadImageService } from 'src/app/SERVICIOS/download-image.service';

@Component({
  selector: 'app-item-view',
  templateUrl: './item-view.component.html',
  styleUrls: ['./item-view.component.scss'],
})
export class ItemViewComponent implements OnInit, OnDestroy {
  items: GalleryItem[];
  public data: any;
  isArtLis: boolean = false;
  idR: string;
  idA: string;
  artSelected: boolean;
  id: string;
  infoId: any;
  btnSelectedText: string;
  uid_user: string;
  isCuarentine: boolean = false;
  articles = [];
  getIdArt: any[];
  articlesSelected: any[];
  guardarID = [];
  idArticle_listing: string;
  getIdArticles: any[];
  positive_or_negative: any;
  imageData: any;
  imageMainArticle: string;
  imagesArticle = [];
  user: User;
  isCuarentena: boolean = false;
  subs: Subscription;
  articles_obtained = [];
  lead: any;

  constructor(
    public router: Router,
    private gallery: Gallery,
    public route: ActivatedRoute,
    private _location: Location,
    public modal: NgbModal,
    private afs: AngularFirestore,
    private dbdata: DataDbService,
    private socket: SocketService,
    public authSvc: AuthService,
    private imageService: DownloadImageService
  ) { }

  communityPhotos() {
    this.router.navigate(['registra', this.idA, 'community-photos']);
  }

  downloadPhotos() {
    for (var i = 0; i < this.imageData.length; i++) {
      this.imageService.downloadImage(this.imageData[i].srcUrl, i);
      // this.imageService.getData(this.imageData[i].srcUrl).subscribe((data) => {
      //   this.imageService.download(data, i);
      // });
    }
  }

  ngOnInit(): void {
    this.authSvc.user$
      .pipe(take(1))
      .toPromise()
      .then((user) => {
        this.user = user;
      });
    this.idR = this.route.snapshot.paramMap.get('idR');
    this.idArticle_listing =
      this.route.snapshot.paramMap.get('article-listing');
    this.idA = this.route.snapshot.paramMap.get('idA');
    this.id = this.route.snapshot.paramMap.get('id');
    let al = this.route.snapshot.paramMap.get('al');

    if (al == 'article-listing') {
      this.isArtLis = true;
    } else if (al == 'cuarentine') {
      this.isCuarentine = true;
    }

    if (this.idArticle_listing) {
    }

    if (this.id) {
      let getid = localStorage.getItem(String(this.id));
      this.infoId = JSON.parse(getid);
      if (this.infoId.selected) {
        this.artSelected = true;
        this.btnSelectedText = 'Seleccionado';
      } else {
        this.artSelected = false;
        this.btnSelectedText = 'Seleccionar Articulo';
      }
      this.authSvc.getCurrentUser().then((values) => {
        this.uid_user = values.uid;
      });

      this.afs
        .collection('LEAD')
        .doc(this.idR)
        .valueChanges()
        .pipe(take(1))
        .toPromise()
        .then((lead: any) => {
          this.lead = lead;
          if (lead.cuarentena == true) this.isCuarentena = true;
        });
    }

    this.afs
      .collection('ARTICLE')
      .doc(String(this.idA))
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((values: any) => {
        let get_images = [];
        this.data = values;
        this.imageMainArticle = values.imagen_principal.url;
        let img = {
          srcUrl: values.imagen_principal.url,
          previewUrl: values.imagen_principal.url,
        };
        get_images.push(img);
        this.imagesArticle = values.imagenes;
        for (const images of values.imagenes) {
          let image = {
            srcUrl: images.url,
            previewUrl: images.url,
          };
          get_images.push(image);
        }
        this.imageData = get_images;
        this.items = this.imageData.map(
          (item: { srcUrl: any; previewUrl: any }) =>
            new ImageItem({ src: item.srcUrl, thumb: item.previewUrl })
        );
      });
  }

  finalizeSelection() {
    this.afs
      .collection('LEAD')
      .doc(this.idR)
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((lead: any) => {
        if (lead.cuarentena == true) this.isCuarentena = true;
        this.getArticles(lead.tagsToSearch);
      });
  }

  getArticles(tagsSearch: any) {
    let tagsToSearch: any;
    tagsToSearch = tagsSearch;
    this.subs = this.afs
      .collection('ARTICLE')
      .valueChanges()
      .subscribe((articles: any) => {
        let article = articles.filter(
          (article: { completado: boolean }) => article.completado == true
        );
        this.articles = article.filter(
          (inmuebleEncontradoEnDB: {
            caracteristicas: any[];
            contador: number;
          }) => {
            let encontrado = false;
            let contador = 0;
            if (inmuebleEncontradoEnDB.caracteristicas) {
              for (const inmCaracteristica of inmuebleEncontradoEnDB.caracteristicas) {
                for (const selectedValueToSearch of tagsToSearch) {
                  if (
                    inmCaracteristica.id === selectedValueToSearch.id &&
                    inmCaracteristica.seleccionado ===
                    selectedValueToSearch.seleccionado
                  ) {
                    contador++;
                    if (
                      contador >= tagsToSearch.length ||
                      contador == tagsToSearch.length ||
                      contador <= tagsToSearch.length
                    ) {
                      encontrado = true;
                    }
                  }
                }
              }
              inmuebleEncontradoEnDB.contador = contador;
              inmuebleEncontradoEnDB.caracteristicas =
                inmuebleEncontradoEnDB.caracteristicas.filter(
                  (c: { seleccionado: any }) => c.seleccionado
                );
              return encontrado;
            }
          }
        );
        this.submit(this.articles);
      });
  }

  submit(articles: any[]) {
    this.getIdArt = [];
    this.articles_obtained = [];
    for (let i = 0; i <= articles.length - 1; i++) {
      let guardado = localStorage.getItem(String(i));
      if (guardado !== null) this.getIdArt.push(JSON.parse(guardado));
    }

    if (this.getIdArt.length > 0) {
      for (let i = 0; i <= this.getIdArt.length - 1; i++) {
        if (this.getIdArt[i].selected == true)
          this.articles_obtained.push(this.articles[i]);
      }
    }

    //saber si eligio algun articulo
    if (this.articles_obtained.length == 0) alert('Elige al menos un articulo');
    else {
      for (let i of this.articles_obtained) {
        let objeto = {
          id: i.id_articulo,
        };
        this.guardarID.push(objeto);
      }
      let obj = {
        id: this.idR,
        ids: this.guardarID,
      };
      this.dbdata.saveId(obj, false, this.id, this.lead);
      localStorage.clear();
      this.router.navigate(['gestiona', this.idR, 'go']);
    }

    // this.getIdArt = []
    // this.articlesSelected = [];

    // for (let i = 0; i <= articles.length - 1; i++) {
    //   let guardado = localStorage.getItem(String(i));
    //   this.getIdArt.push(JSON.parse(guardado));
    // }
    // for (let i = 0; i <= this.getIdArt.length - 1; i++) {
    //   if (this.getIdArt[i] !== null && this.getIdArt[i].selected !== null) {
    //     this.articlesSelected.push(articles[i]);
    //   }
    // }
    // if (this.articlesSelected.length > 0) {
    //   for (let i of this.articlesSelected) {
    //     let objeto = {
    //       id: i.id_articulo
    //     }
    //     this.guardarID.push(objeto);
    //   }
    //   let obj = {
    //     id: this.idR,
    //     ids: this.guardarID
    //   }
    //   this.dbdata.saveId(obj, false, this.id);
    //   localStorage.clear()
    //   this.router.navigate(['gestiona', this.idR, 'go']);

    // } else {
    //   this.socket.io.emit("lead-without-articles", this.uid_user);
    //   let idArticulos = []
    //   this.afs.collection('LEAD').doc(this.idR).update({ cuarentena: true, mostrar: false, idArticulos, estatus: "completado", completo: true })
    //   this.router.navigate(['registra']);

    // }
  }

  searchArtSeleceted(selected: { selected: boolean }) {
    return selected.selected == true;
  }

  SelectArt() {
    this.artSelected = !this.artSelected;

    if (this.artSelected) {
      let objeto = {
        id: this.id,
        selected: true,
      };
      this.btnSelectedText = 'Seleccionado';
      localStorage.setItem(String(this.id), JSON.stringify(objeto));
    } else {
      let objeto = {
        id: this.id,
        selected: false,
      };
      this.btnSelectedText = 'Seleccionar Articulo';
      localStorage.setItem(String(this.id), JSON.stringify(objeto));
    }
  }

  back() {
    this._location.back();
  }

  addLead() {
    if (this.idArticle_listing) {
      this.verificationLead();
    } else this.router.navigate(['registra', this.data.id_articulo]);
  }

  verificationLead() {
    let obj = {
      id: this.data.id_articulo,
    };
    this.afs
      .collection('LEAD')
      .doc(this.idR)
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((values: any) => {
        this.positive_or_negative = values.positive_or_negative;
        this.getIdArticles = values.idArticulos;

        if (
          this.positive_or_negative == undefined &&
          this.getIdArticles == undefined
        ) {
          let idArticulos = firebase.firestore.FieldValue.arrayUnion(obj);
          this.afs
            .collection('LEAD')
            .doc(this.idR)
            .update({ idArticulos, cuarentena: false })
            .then((doc) => {
              this.router.navigate(['gestiona', this.idR, 'go']);
            });
        } else if (this.positive_or_negative == undefined) {
          // let check = this.positive_or_negative.find(article => article.id_articulo == art.id_articulo);
          let find = this.getIdArticles.find(
            (article) => article.id == this.data.id_articulo
          );
          if (find) {
            alert('Ya agregaste este articulo');
          } else {
            let idArticulos = firebase.firestore.FieldValue.arrayUnion(obj);
            this.afs
              .collection('LEAD')
              .doc(this.idR)
              .update({ idArticulos, cuarentena: false })
              .then((doc) => {
                this.router.navigate(['gestiona', this.idR, 'go']);
              });
          }
        } else {
          let check = this.positive_or_negative.find(
            (article: { id_articulo: any }) =>
              article.id_articulo == this.data.id_articulo
          );
          let find = this.getIdArticles.find(
            (article) => article.id == this.data.id_articulo
          );
          if (find || check) {
            alert('Ya agregaste este articulo');
          } else {
            let idArticulos = firebase.firestore.FieldValue.arrayUnion(obj);
            this.afs
              .collection('LEAD')
              .doc(this.idR)
              .update({ idArticulos, cuarentena: false })
              .then((doc) => {
                this.router.navigate(['gestiona', this.idR, 'go']);
              });
          }
        }
      });
  }

  continueCuarentine() {
    if (this.idR) {
      let obj = {
        id: this.data.id_articulo,
      };
      this.afs
        .collection('LEAD')
        .doc(this.idR)
        .valueChanges()
        .pipe(take(1))
        .toPromise()
        .then((values: any) => {
          this.positive_or_negative = values.positive_or_negative;
          this.getIdArticles = values.idArticulos;

          if (
            this.positive_or_negative == undefined &&
            this.getIdArticles.length == 0
          ) {
            let idArticulos = firebase.firestore.FieldValue.arrayUnion(obj);
            this.afs
              .collection('LEAD')
              .doc(this.idR)
              .update({ idArticulos, cuarentena: false })
              .then((doc) => {
                this.router.navigate(['gestiona', this.idR, 'go']);
              });
          } else if (this.positive_or_negative == undefined) {
            // let check = this.positive_or_negative.find(article => article.id_articulo == art.id_articulo);
            let find = this.getIdArticles.find(
              (article) => article.id == this.data.id_articulo
            );
            if (find) {
              alert('Ya agregaste este articulo');
            } else {
              let idArticulos = firebase.firestore.FieldValue.arrayUnion(obj);
              this.afs
                .collection('LEAD')
                .doc(this.idR)
                .update({ idArticulos, cuarentena: false })
                .then((doc) => {
                  this.router.navigate(['gestiona', this.idR, 'go']);
                });
            }
          } else {
            let check = this.positive_or_negative.find(
              (article: { id_articulo: any }) =>
                article.id_articulo == this.data.id_articulo
            );
            let find = this.getIdArticles.find(
              (article) => article.id == this.data.id_articulo
            );
            if (find || check) {
              alert('Ya agregaste este articulo');
            } else {
              let idArticulos = firebase.firestore.FieldValue.arrayUnion(obj);
              this.afs
                .collection('LEAD')
                .doc(this.idR)
                .update({ idArticulos, cuarentena: false })
                .then((doc) => {
                  this.router.navigate(['gestiona', this.idR, 'go']);
                });
            }
          }
        });
    }
  }

  noNotification() {
    this.afs.collection('LEAD').doc(this.idR).update({ cuarentena: false });
    this.back();
  }

  openLG(contenido1: any) {
    this.modal.open(contenido1, { size: 'lg' });
  }

  openXL(contenido2: any) {
    this.modal.open(contenido2, { size: 'xl' });
  }

  openXL2(contenido3: any) {
    this.modal.open(contenido3, { size: 'xl' });
  }
  openXL3(contenido4: any) {
    this.modal.open(contenido4, { size: 'xl' });
  }
  openXL4(contenido5: any) {
    this.modal.open(contenido5, { size: 'xl' });
  }
  openXL5(contenido6: any) {
    this.modal.open(contenido6, { size: 'xl' });
  }
  openSM7(contenido7: any) {
    this.modal.open(contenido7, { size: 'widht:55%; height:150px' });
  }

  ngOnDestroy() {
    if (this.subs !== undefined) this.subs.unsubscribe();
  }
}
