import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { DataDbService } from './data-db.service';
import * as moment from 'moment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Location } from '@angular/common';
import { Fileitem } from '../COMERCIAL/MODULOS/ingresa/file';
import { AuthService } from './auth.service';
import { User } from '../COMERCIAL/MODULOS/user.interface';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CargaService {
  private CARPETA_IMAGENES = 'images';
  images = [];
  others_images = [];
  imagenes = [];
  count_images = 0;
  user: User;
  constructor(
    private router: Router,
    private dbData: DataDbService,
    private afs: AngularFirestore,
    private _location: Location,
    public auth: AuthService
  ) {
    this.auth.user$
      .pipe(take(1))
      .toPromise()
      .then((user) => {
        this.user = user;
      });
  }

  cargaImagenesFirebase(imagenes: any, id, isEdit: boolean) {
    this.imagenes = imagenes;
    const storageRef = firebase.storage().ref();
    for (const item of imagenes) {
      item.imagen.estaSubiendo = true;
      if (item.imagen.progreso >= 100) {
        continue;
      }

      const uploadTask: firebase.storage.UploadTask = storageRef
        .child(`${this.CARPETA_IMAGENES}/${item.imagen.nombreArchivo}`)
        .put(item.imagen.archivo);
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot: firebase.storage.UploadTaskSnapshot) =>
        (item.imagen.progreso =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100),
        (error) => console.error('Error al subir', error),
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((url) => {
            this.count_images++;
            item.imagen.url = url;
            item.imagen.estaSubiendo = false;
            this.guardarImagen(
              {
                nombre: item.imagen.nombreArchivo,
                url: item.imagen.url,
                fecha: moment().format('DD-MM-YYYY'),
                hora: moment().format('hh:mm:ss a'),
              },
              id,
              isEdit
            );
          });
        }
      );
    }
  }
  cargaImagenesFirebaseClient(imagenes: any, idClient, index, idLead) {
    this.imagenes = imagenes;
    const storageRef = firebase.storage().ref();
    for (const item of imagenes) {
      item.imagen.estaSubiendo = true;
      if (item.imagen.progreso >= 100) {
        continue;
      }

      const uploadTask: firebase.storage.UploadTask = storageRef
        .child(`${this.CARPETA_IMAGENES}/${item.imagen.nombreArchivo}`)
        .put(item.imagen.archivo);
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot: firebase.storage.UploadTaskSnapshot) =>
        (item.imagen.progreso =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100),
        (error) => console.error('Error al subir', error),
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((url) => {
            this.count_images++;
            item.imagen.url = url;
            item.imagen.estaSubiendo = false;
            this.guardarImagenClient(
              index,
              idClient,
              {
                nombre: item.imagen.nombreArchivo,
                url: item.imagen.url,
                fecha: moment().format('DD-MM-YYYY'),
                hora: moment().format('hh:mm:ss a'),
              },
              idClient
            );
          });
        }
      );
    }
  }

  private guardarImagenClient(
    index,
    idClient,
    imagen: { nombre: string; url: string; fecha; hora },
    id
  ) {
    if (this.userOnline()) {
      this.images.push(imagen);
      this.dbData.saveUrlClient(this.images, id, index, idClient);
      if (this.count_images == this.imagenes.length) {
        this.router.navigate(['gestiona']);
      }
    } else {
      alert('No tienes conexion a internet');
    }
  }

  private guardarImagen(
    imagen: { nombre: string; url: string; fecha; hora },
    id,
    isEdit: boolean
  ) {
    if (this.userOnline()) {
      this.images.push(imagen);
      this.dbData.saveUrl(this.images, id);
      if (isEdit == true) this._location.back();
      else {
        if (this.count_images == this.imagenes.length)
          this.router.navigate(['ingresa', id, 'articulo']);
      }
    } else {
      alert('No tienes conexion a internet');
    }
  }

  userOnline() {
    return navigator.onLine;
  }
  imagesMain(imagenes: any, id) {
    const storageRef = firebase.storage().ref();
    for (const item of imagenes) {
      item.imagen.estaSubiendo = true;
      if (item.imagen.progreso >= 100) {
        continue;
      }

      const uploadTask: firebase.storage.UploadTask = storageRef
        .child(`${this.CARPETA_IMAGENES}/${item.imagen.nombreArchivo}`)
        .put(item.imagen.archivo);
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot: firebase.storage.UploadTaskSnapshot) =>
        (item.progreso =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100),
        (error) => console.error('Error al subir', error),
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((url) => {
            item.imagen.url = url;
            item.imagen.estaSubiendo = false;
            this.principalImagen(
              {
                nombre: item.imagen.nombreArchivo,
                url: item.imagen.url,
                fecha: moment().format('DD-MM-YYYY'),
                hora: moment().format('hh:mm:ss a'),
              },
              id
            );
          });
        }
      );
    }
  }
  imagesOthers(nuevas_imagenes: Fileitem[], edit_imagenes, id) {

    if (nuevas_imagenes.length > 0) {
      this.others_images = [];

      const storageRef = firebase.storage().ref();
      for (const [index, item] of nuevas_imagenes.entries()) {
        item.estaSubiendo = true;
        if (item.progreso >= 100) {
          continue;
        }

        const uploadTask: firebase.storage.UploadTask = storageRef
          .child(`ARTICLES/${item.nombreArchivo}`)
          .put(item.archivo);
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          (snapshot: firebase.storage.UploadTaskSnapshot) =>
          (item.progreso =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100),
          (error) => console.error('Error al subir', error),
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((url) => {
              item.url = url;
              item.estaSubiendo = false;
              this.otraImagen(
                {
                  nombre: item.nombreArchivo,
                  url: item.url,
                  fecha: moment().format('DD-MM-YYYY'),
                  hora: moment().format('hh:mm:ss a'),
                  date: moment().format(),
                },
                id,
                edit_imagenes,
                nuevas_imagenes.length
              );
            });
          }
        );
      }
    } else {
      this.editOldImages(edit_imagenes, id);
    }
    // this.otraImagen(this.imagenes,id,edit_imagenes)
  }

  imagesCommunity(nuevas_imagenes: Fileitem[], id) {
    const storageRef = firebase.storage().ref();
    for (const [index, item] of nuevas_imagenes.entries()) {
      item.estaSubiendo = true;
      if (item.progreso >= 100) {
        continue;
      }

      const uploadTask: firebase.storage.UploadTask = storageRef
        .child(`COMMUNITY-PHOTOS/${item.nombreArchivo}`)
        .put(item.archivo);
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot: firebase.storage.UploadTaskSnapshot) =>
        (item.progreso =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100),
        (error) => console.error('Error al subir', error),
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((url) => {
            item.url = url;
            item.estaSubiendo = false;
            this.otraImagenCommunity(
              {
                nombre: item.nombreArchivo,
                url: item.url,
                uid_user: this.user.uid,
                fecha: moment().format('DD-MM-YYYY'),
                hora: moment().format('hh:mm:ss a'),
                date: moment().format(),
                aprobado: false,
              },
              id
            );
          });
        }
      );
    }
    // this.otraImagen(this.imagenes,id,edit_imagenes)
  }

  editOldImages(edit_imagenes: any[], idA) {
    let array = edit_imagenes;
    this.afs
      .collection('ARTICLE')
      .doc(idA)
      .update({ imagenes: array })
      .then((e) => {
        this.router.navigate([
          `community-manage/uploads/fotografias/articulos/`,
        ]);
      });
  }
  otraImagen(imagen, idA, edit_imagenes: any[], length) {
    if (this.userOnline()) {
      this.others_images.push(imagen);

      if (this.others_images.length == length) {
        let array = edit_imagenes.concat(this.others_images);

        this.afs
          .collection('ARTICLE')
          .doc(idA)
          .update({ imagenes: array })
          .then((e) => { });
        this.router.navigate([
          `community-manage/uploads/fotografias/articulos/`,
        ]);
      }
    } else {
      alert('No tienes conexion a internet');
    }
  }

  otraImagenCommunity(imagen, idA) {
    if (this.userOnline()) {
      let communityPhotos = firebase.firestore.FieldValue.arrayUnion(imagen);
      this.afs
        .collection('ARTICLE')
        .doc(idA)
        .update({ communityPhotos })
        .then((e) => { });
    } else {
      alert('No tienes conexion a internet');
    }
    this._location.back();
  }
  principalImagen(imagen: { nombre; url; fecha; hora }, idA) {
    if (this.userOnline()) {
      this.afs
        .collection('ARTICLE')
        .doc(idA)
        .update({ imagen_principal: imagen });
    } else {
      alert('No tienes conexion a internet');
    }
  }
}
