import { ThisReceiver } from '@angular/compiler';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-sign',
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.scss']
})
export class SignComponent implements OnInit, AfterViewInit {
  @ViewChild('signDigitalW', { static: true }) signDigitalPadElement: any;
  signDigitalPad: any
  firma: any
  idKA
  idArticle
  constructor(private route: ActivatedRoute, public afs: AngularFirestore, private router: Router,) {
    this.idKA = this.route.snapshot.paramMap.get('keyId');
    this.idArticle = this.route.snapshot.paramMap.get('idArticle');
  }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    this.signDigitalPad = new SignaturePad(this.signDigitalPadElement.nativeElement);

  }

  changeColor() {
    const red = Math.round(Math.random() * 255)
    const green = Math.round(Math.random() * 255)
    const blue = Math.round(Math.random() * 255)
    const colour = 'rgb(' + red + ',' + green + ',' + blue + ')'
    this.signDigitalPad.penColor = colour

  }


  cleanSign() {
    this.signDigitalPad.clear()
  }

  download(dataURL: any, nombre: string) {
    if (navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') === -1) {
      window.open(dataURL)
    } else {
      const blob = this.urlToBlob(dataURL)
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = nombre
      this.firma = blob
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    }

  }
  urlToBlob(dataURL: any) {
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawl = raw.length;
    const array = new Uint8Array(rawl);
    for (let i = 0; i < rawl; i++) {
      array[i] = raw.charCodeAt(i);
    }
    let blob = new Blob([array], { type: contentType })

    return blob
  }

  guardarPNG() {
    if (this.signDigitalPad.isEmpty()) {
      alert('es necesaria la firma para continuar')

    } else {
      const u = this.signDigitalPad.toDataURL()
      this.afs.collection('ARTICLE').doc(this.idArticle).collection('KEY-ACCESS').doc(this.idKA).update({
        sign: u,
        signed: true,
        makeKeyAccess: true,
        requestSign: false,
        //status: 'aprobado',
        // deliveryDate: moment().format(),
      }).then((e) => {
        this.router.navigate(['/keyaccess/keyaccess-fullview', this.idArticle, this.idKA]);

      })

      // console.log(u);

      // this.download(u, 'firma.png')
      // this.firma = u
    }
  }





}
