<!-- <h1>hola</h1> -->

<button class="btn-flotante" *ngIf="!showCard" (click)="createChatSupport()">
  <span class="indicator" *ngIf="msgNoReads && msgNoReads > 0">{{msgNoReads}}</span>
  <img src="https://image.flaticon.com/icons/png/512/126/126501.png" />
</button>

<div class="tarjeta" *ngIf="showCard">
  <div class="closeChat" (click)="hideCard()">
    <span class="closeIcon">X</span>
  </div>

  <div class="form1" *ngIf="form1">
    <form [formGroup]="affairForm" (ngSubmit)="waiting()">
      <header>
        <h6>Chat de Soporte BETA</h6>
      </header>
      <input type="text" [ngClass]="{invalid: affair.invalid && affair.dirty}" formControlName="affair"
        placeholder="Asunto..." class="form-control border-0" />
      <textarea placeholder="Descripción..." [ngClass]="{invalid: description.invalid && description.dirty}" rows="7"
        formControlName="description" class="form-control border-0">
        </textarea>
      <button class="btn" [disabled]="affairForm.invalid">Enviar</button>
    </form>
  </div>

  <!-- form2 -->
  <div class="form2" *ngIf="form2">
    <form [formGroup]="messageForm" (ngSubmit)="sendMessage()">
      <div class="box-msg">
        <div [ngClass]="{
              local: message.messageType === 1,
              remoto: message.messageType === 2
            }" *ngFor="let message of chat.b_chats">
          <p>{{ message.text }}</p>
        </div>
      </div>
      <div class="input-msg">
        <input type="text" class="form-control" formControlName="message" />
        <button class="btn" [disabled]="message.invalid">
          Enviar
        </button>
      </div>
    </form>
  </div>

  <!-- form3 -->
  <div class="form3" *ngIf="form3">
    En un momento lo atenderán
    <br>
    <div class="text-center">
      {{ minutesDisplay }}:{{ secondsDisplay && secondsDisplay <= 59 ? secondsDisplay : "00" }} </div>
    </div>

    <!-- form4 -->
    <div class="form4" *ngIf="form4">
      Por el momento, ningun usuario de soporte está disponible, le notificaremos
      cuando se tome el asunto.
      <button class="ui button mt-3" (click)="backChat()">Regresar</button>
    </div>

    <!-- form5 -->
    <div class="form5" *ngIf="form5">
      {{textFnChat}}
      <button class="ui button mt-3" (click)="backChat()">Regresar</button>
    </div>
  </div>
