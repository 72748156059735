import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeaseComponent } from './lease.component';
import { LeaseRoutingModule } from './lease-routing.module';
import { LeaseListFullviewComponent } from './lease-list-fullview/lease-list-fullview.component';
import { LeaseListComponent } from './lease-list/lease-list.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [LeaseComponent, LeaseListFullviewComponent, LeaseListComponent],
  imports: [
    CommonModule,NgxPaginationModule,NgChartsModule,
    LeaseRoutingModule,FormsModule, ReactiveFormsModule 
  ]
})
export class LeaseModule { }
