<div class="main">

  <div class=" d-flex justify-content-between">
    <h2 class="title mt-4 mb-5">Leads</h2>
    <div>
      <div class="card-new-lead" routerLink="/registra" *ngIf="!auth.userFullAdmin(user)">
        Agregar nuevo
        lead
      </div>
    </div>
  </div>

  <div>
    <div class="subtitle mb-3 d-flex justify-content-between">
      <h5 *ngIf="auth.userAdmin(user)">Mis leads</h5>
      <h5 *ngIf="auth.userFullAdmin(user)">Leads desde Lead and support</h5>
    </div>
    <div class="box-select-filter">
      <div class="row mb">
        <div class="col">
          <div class="form-outline">
            <select class="form-select" (change)="filterWithSelectu($event.target.value)">
              <option [selected]="false">Selecciona una opción</option>
              <option [selected]="valueNameu == 'idregister'" class="large" value="1">ID del registro</option>
              <option [selected]="valueNameu == 'name' " value="2">Nombre del cliente</option>

            </select>
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input [disabled]="disabledInputu" (input)="getTextu($event.target.value)" class="form-control"
              placeholder="ingrese el valor" name="filterPostu" [(ngModel)]="filterPostu" [value]="text_inputu" />
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="(my_lead | lead: filterPostu:valueNameu) as myleads" class="grid-incomes mt-5">
      <div *ngFor="let lead of myleads | paginate: { itemsPerPage: 6, currentPage: m_p }"
        class="mt-3 card card-income ">
        <div *ngIf="lead.estatus !== 'incompleto'" (click)='fullView(lead)'>
          <h3 class=" fw-bold">{{ lead.cliente.nombre | titlecase }} {{ lead.cliente.apellido | titlecase }}</h3>
          <p>{{ lead.id }}</p>

          <div style="display: flex;">
            <div style="display: flex;flex-direction: column;
          align-items: center;">
              <p class="text-center" style="font-weight: bold;">Relación de artículos</p>
              <p>{{ getRelation(lead)}}</p>
            </div>
            <div style="display: flex;flex-direction: column;
          align-items: center;">
              <p class="text-center" style="font-weight: bold;">Artículos positivos</p>

              <p>{{ getPositiveArticles(lead) }}</p>
            </div>
            <div style="display: flex;flex-direction: column;
          align-items: center;">
              <p class="text-center" style="font-weight: bold;">Artículos negativos</p>

              <p>{{ getNegativeArticles(lead)}}</p>
            </div>

          </div>


        </div>

        <div *ngIf="lead.estatus == 'incompleto'" (click)="complete(lead)">
          <h3 class=" fw-bold">{{ lead.cliente.nombre | titlecase }} {{ lead.cliente.apellido | titlecase }}</h3>
          <p>{{ lead.id }}</p>

          <div style="display: flex;">
            <div style="display: flex;flex-direction: column;
          align-items: center;">
              <th class="text-center" style="font-weight: bold;">Relación de artículos</th>
              <p>{{ getRelation(lead)}}</p>
            </div>
            <div style="display: flex;flex-direction: column;
          align-items: center;">
              <p class="text-center" style="font-weight: bold;">Artículos positivos</p>

              <p>{{ getPositiveArticles(lead) }}</p>
            </div>
            <div style="display: flex;flex-direction: column;
          align-items: center;">
              <p class="text-center" style="font-weight: bold;">Artículos negativos</p>
              <p>{{ getNegativeArticles(lead)}}</p>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="text-center mt-4 mb-5" *ngIf="my_lead.length > 5">
      <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" (pageChange)="changePageu($event)"
        class="float-right"></pagination-controls>
    </div>
    <div *ngIf="my_lead.length== 0">
      <h1 class="text-center">
        No hay datos por el momento.
      </h1>
    </div>
  </div>


  <!-- <div class="table-responsive" *ngIf="auth.userFullAdmin(user) == false">
    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th scope="col">ID</th>
          <th class="large" scope="col">Nombre</th>
          <th scope="col">COMMODITY RELATION</th>
          <th scope="col">COMMODITY POSITIVE</th>
          <th scope="col">COMMODITY NEGATIVE</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody *ngIf="(my_lead | lead: filterPostu:valueNameu) as myleads">
        <tr *ngFor="let lead of myleads | paginate: { itemsPerPage: 5, currentPage: m_p }">
          <th scope="row">{{ lead.id }}</th>
          <td class="large">{{ lead.cliente.nombre | titlecase }} {{ lead.cliente.apellido | titlecase}}</td>
          <td>{{ lead.commodityRelation }}</td>
          <td>{{ lead.commodityPositive }}</td>
          <td>{{ lead.commodityNegative }}</td>
          <td *ngIf="lead.estatus !== 'incompleto'; else noComplete">
            <div class="ui animated button" tabindex="0" (click)='fullView(lead)'>
              <div class="visible content">Ver</div>
              <div class="hidden content">
                <i class="right arrow icon"></i>
              </div>
            </div>
          </td>
          <ng-template #noComplete>
            <td> <button class="ui button" (click)="complete(lead)">Completar</button></td>
          </ng-template>
        </tr>
      </tbody>
    </table>

  </div> -->




  <div *ngIf="auth.userAdmin(user) || auth.userFullAdmin(user)">
    <div class="subtitle mb-3 mt-5 d-flex justify-content-between"
      *ngIf="auth.userAdmin(user) || auth.userFullAdmin(user)">
      <h5>Leads de otros usuarios</h5>
      <div></div>
    </div>
    <div class="box-select-filter" *ngIf="auth.canAdmin(user)">
      <div class="row mb">
        <div class="col">
          <div class="form-outline">
            <select class="form-select" (change)="filterWithSelect($event.target.value)">
              <option [selected]="false">Selecciona una opción</option>
              <option [selected]="valueName == 'idregister'" class="large" value="1">ID del registro</option>
              <option [selected]="valueName == 'name' " value="2">Nombre del cliente</option>
              <option [selected]="valueNameu== 'iduser' " value="3">iduser</option>

            </select>
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input [disabled]="disabledInput" (input)="getText($event.target.value)" class="form-control"
              placeholder="ingrese el valor" name="filterPost" [(ngModel)]="filterPost" [value]="text_input" />
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="(auth.userAdmin(user) || auth.userFullAdmin(user)) && all_lead.length>0">
      <div *ngIf="(all_lead | lead: filterPost:valueName) as allleads" class="grid-incomes mt-5">
        <div *ngFor="let lead of allleads | paginate: { itemsPerPage: 6, currentPage: p }"
          class="mt-3 card card-income ">
          <h3>{{ lead.cliente.nombre | titlecase }} {{ lead.cliente.apellido | titlecase }}</h3>
          <p>{{ lead.id }}</p>


          <div style="display: flex;">
            <div style="display: flex;flex-direction: column;
            align-items: center;">
              <th class="text-center" style="font-weight: bold;">relación de artículos</th>
              <p>{{getRelation(lead) }}</p>
            </div>
            <div style="display: flex;flex-direction: column;
            align-items: center;">
              <p class="text-center" style="font-weight: bold;">artículos positivos</p>

              <p>{{ getPositiveArticles(lead) }}</p>
            </div>
            <div style="display: flex;flex-direction: column;
            align-items: center;">
              <p class="text-center" style="font-weight: bold;">artículos negativos</p>

              <p>{{ getNegativeArticles(lead) }}</p>
            </div>

          </div>
          <td *ngIf="lead.estatus !== 'incompleto'; else noComplete">
            <div class="ui animated button" tabindex="0" (click)='fullView(lead)'>
              <div class="visible content">Ver</div>
              <div class="hidden content">
                <i class="right arrow icon"></i>
              </div>
            </div>
          </td>
          <ng-template #noComplete>
            <td> <button class="ui button" (click)="complete(lead)">Completar</button></td>
          </ng-template>

        </div>
      </div>
    </div>
    <div *ngIf="all_lead.length== 0">
      <p class="text-center">No hay datos por el momento.</p>
    </div>
  </div>



  <!--

  <div class="table-responsive" *ngIf="auth.userAdmin(user) || auth.userFullAdmin(user)">
    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th scope="col">ID</th>
          <th class="large" scope="col">Nombre</th>
          <th scope="col">COMMODITY RELATION</th>
          <th scope="col">COMMODITY POSITIVE</th>
          <th scope="col">COMMODITY NEGATIVE</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody *ngIf="(all_lead | lead: filterPost:valueName) as allleads">

        <tr *ngFor="let lead of allleads | paginate: { itemsPerPage: 5, currentPage: p }">
          <th scope="row">{{ lead.id }}</th>
          <td class="large">{{ lead.cliente.nombre | titlecase }} {{ lead.cliente.apellido | titlecase}}</td>
          <td>{{ lead.commodityRelation }}</td>
          <td>{{ lead.commodityPositive }}</td>
          <td>{{ lead.commodityNegative }}</td>
          <td *ngIf="lead.estatus !== 'incompleto'; else noComplete">
            <div class="ui animated button" tabindex="0" (click)='fullView(lead)'>
              <div class="visible content">Ver</div>
              <div class="hidden content">
                <i class="right arrow icon"></i>
              </div>
            </div>
          </td>
          <ng-template #noComplete>
            <td> <button class="ui button" (click)="complete(lead)">Completar</button></td>
          </ng-template>
        </tr>
      </tbody>
    </table>
    <div *ngIf="all_lead.length== 0">
      <p class="text-center">No hay datos por el momento.</p>
    </div>
  </div> -->
  <div class="text-center mt-4 mb-5" *ngIf="all_lead.length > 5">
    <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" (pageChange)="changePage($event)"
      class="float-right"></pagination-controls>
  </div>
</div>