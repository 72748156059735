import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GestionaModule } from "./GESTIONA/gestiona.module";
import { HistorysModule } from "./historys/historys.module";
import { IngresaModule } from "./INGRESA/ingresa.module";
import { KeyaccessModule } from "./keyaccess/keyaccess.module";
import { RegistraModule } from "./REGISTRA/registra.module";
import { ArticleCuarentineComponent } from "./article-cuarentine/article-cuarentine.component";
import { ArticleListingComponent } from "./article-listing/article-listing.component";
import { InactividadComponent } from "./componentes/inactividad/inactividad.component";
import { KeyaccessHistoryComponent } from "./keyaccess/keyaccess-history/keyaccess-history.component";
import { FilterPipe } from "./pipes/filter.pipe";
import { ComercialRoutingModule } from "./comercial-routing.module";
import { NgxPaginationModule } from "ngx-pagination";
import { LeaseModule } from "./lease/lease.module";
import { SaleModule } from "./sale/sale.module";
import { MarktingModule } from "./markting/markting.module";
import { KeyaccessfilterPipe } from "./pipes/keyaccessfilter.pipe";

@NgModule({
  declarations: [
    InactividadComponent,
    ArticleListingComponent,
    FilterPipe,
    ArticleCuarentineComponent,
    KeyaccessfilterPipe,
    KeyaccessHistoryComponent,
  ],
  imports: [
    ComercialRoutingModule,
    CommonModule,
    KeyaccessModule,
    NgxPaginationModule,
    IngresaModule,
    LeaseModule,
    SaleModule,
    GestionaModule,
    RegistraModule,
    HistorysModule,
    MarktingModule,
  ],
})
export class ComercialModule {}
