import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'src/app/SERVICIOS/socket/notifications.service';
import { AuthService } from 'src/app/SERVICIOS/auth.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/SERVICIOS/notification.service';

@Component({
  selector: 'app-ingresa',
  templateUrl: './ingresa.component.html',
  styleUrls: ['./ingresa.component.scss',],
  

})
export class IngresaComponent implements OnInit {

  public notifications = []
  mostrarBloques = false;
  notificacion;
  email: string;
  uid: string;
  id: string;

  constructor(public notification: NotificationsService, 
    public gonotification:NotificationService,
    private authsv: AuthService, private router: Router) {
    // this.notification.recibirNotification()
    // this.authsv.getCurrentUser().then(data => {
    //   this.uid = data.uid
    //   this.email = data.email
    // })

  }

  goNotification(notification:any){
    this.gonotification.goNotification(notification)
  }

  async onLogout() {
    try {
      await this.authsv.logout();
      this.router.navigate(['/login']);
    } catch (error) {
      console.log(error);
    }
  }
  ngOnInit(): void {
    localStorage.clear()
  }











}
