import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanEnterGuard } from 'src/app/SERVICIOS/can/can-enter.guard';
import { AuthGuard } from 'src/app/SERVICIOS/guards/auth.guard';
import { DisabledGuard } from 'src/app/SERVICIOS/guards/disabled.guard';
import { SupportGuard } from 'src/app/SERVICIOS/guards/support.guard';

import { GenerateTokenComponent } from './generate-token/generate-token.component';
import { KeyaccessHistoryFullviewComponent } from './keyaccess-history-fullview/keyaccess-history-fullview.component';
import { KeyaccessHistoryComponent } from './keyaccess-history/keyaccess-history.component';
import { KeyaccessComponent } from './keyaccess/keyaccess.component';
import { ManageRequestsComponent } from './manage-requests/manage-requests.component';
import { SelectArticleComponent } from './select-article/select-article.component';
import { SelectLeadComponent } from './select-lead/select-lead.component';
import { SignComponent } from './sign/sign.component';





const routes: Routes = [
    {
        path: 'keyaccess', component: KeyaccessComponent, children: [
            { path: '', component: KeyaccessHistoryComponent },
            { path: 'select-lead/:idArticle/:keyId', component: SelectLeadComponent },
            { path: 'generate-token/:keyId', component: GenerateTokenComponent },
            { path: 'manage-requests', component: ManageRequestsComponent },
            { path: 'keyaccess-fullview/:idArticle/:keyId', component: KeyaccessHistoryFullviewComponent },
            { path: 'sign/:idArticle/:keyId', component: SignComponent },



        ], canActivate: [CanEnterGuard, AuthGuard, DisabledGuard, SupportGuard]
    }


];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class KeyAccessRoutingModule { }
