<div class="main">
  <div class="container">
    <div
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
      "
      class="mb-2"
    >
      <div
        *ngIf="recentActivity && !isFullAdmin"
        style="display: flex; flex-direction: row; justify-content: flex-end"
      >
        <button
          class="ui primary button"
          *ngIf="!recentActivity.recentActivity.important"
          (click)="likeImportant()"
        >
          Marcar como importante
        </button>
        <button
          class="ui primary button"
          *ngIf="recentActivity.recentActivity.important"
          (click)="likenoImportant()"
        >
          Marcar como no importante
        </button>

        <div>
          <button
            class="ui primary button"
            *ngIf="!recentActivity.recentActivity.archived"
            (click)="archivar()"
          >
            Archivar
          </button>
          <button
            class="ui button"
            *ngIf="recentActivity.recentActivity.archived"
            (click)="noArchivar()"
          >
            Archivado
          </button>
        </div>
      </div>

      <div
        *ngIf="recentActivity"
        style="
          display: flex;
          justify-content: space-between;
          justify-content: space-around;
        "
        class="mb-3"
      >
        <div class="shadow-lg p-3 card" style="width: 78%">
          <div>
            <div style="display: flex">
              <div style="font-size: 2.6vh; font-weight: bold">
                Actividad desde
                {{ recentActivity.recentActivity.from }},
                {{ recentActivity.recentActivity.date | ago }}
              </div>
              <!-- <h5 style="font-weight: bold;" *ngIf="important"> (importante)</h5> -->
              <i
                class="star icon"
                title="Importante"
                *ngIf="recentActivity.recentActivity.important"
                style="color: rgb(182, 182, 0)"
              ></i>
            </div>
            <h5 class="mb-3">
              Tienes una nueva observación desde
              {{ recentActivity.recentActivity.from }} ¡puedes verla ahora!
            </h5>
            <hr />

            <div *ngIf="recentActivity.recentActivity.type == 'observation'">
              <p class="lead mt-4">
                Hola {{ user.displayName }}. Para ver toda la información de
                esta observación desde {{ recentActivity.recentActivity.from }},
                puedes ir al lugar de la actividad.
              </p>
            </div>

            <div class="cajaTexto mt-5" *ngIf="recentActivity">
              <h3 style="font-weight: bold">
                {{ recentActivity.recentActivity.description }}
              </h3>
            </div>
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
              "
            >
              <div class="text-center mt-5">
                <button
                  *ngIf="recentActivity.recentActivity.canUploadData"
                  class="ui button"
                  (click)="showModalUploadFile(contenido3)"
                >
                  Subir archivo
                </button>
                <!-- <input style="display: none;" type="file" id="input-image" appFiles (change)="fileChange($event)">

                <label for="input-image">
                  <div class="ui button" *ngIf="!archivo">
                    Subir archivo
                  </div>
                  <div class="ui button" *ngIf="archivo">
                    Subir otro archivo
                  </div>
                </label> -->
              </div>

              <div *ngIf="recentActivity.recentActivity.canReply">
                <button
                  *ngIf="recentActivity.recentActivity.canUploadData"
                  class="mt-5 ui primary button"
                  (click)="openLG2(contenido2)"
                >
                  Ver datos enviados
                </button>
                <button
                  class="mt-5 ui primary button"
                  (click)="openLG1(contenido1)"
                >
                  Ver respuestas
                </button>
                <button class="mt-5 ui primary button" (click)="showModal()">
                  Responder observación
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="recentActivity.recentActivity.type == 'observation'"
          style="
            width: 20%;
            display: flex;
            flex-direction: column;
            justify-content: center;
          "
        >
          <div
            (click)="goToTheObservationSite(recentActivity.recentActivity)"
            class="mb-3 shadow-lgcard"
            style="height: 6.3vh"
          >
            <div
              style="
                cursor: pointer;
                height: 6.3vh;
                display: flex;
                flex-direction: column;
                justify-content: center;
              "
            >
              <div
                style="
                  font-size: 1.1rem;
                  text-align: center;
                  font-weight: bold;
                  cursor: pointer;
                "
              >
                Ir al lugar de la observación
              </div>
            </div>
          </div>
          <div
            *ngIf="!recentActivity.recentActivity.isError"
            class="shadow-lgcard"
            (click)="thisIsAnError()"
          >
            <div
              style="
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;
              "
            >
              <div
                style="font-size: 1.1rem; text-align: center; font-weight: bold"
              >
                <div style="padding: 10px">
                  Creo que esta observación fue creada por error
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="recentActivity.recentActivity.isError"
            class="shadow-lgcard"
          >
            <div
              style="font-size: 1.1rem; text-align: center; font-weight: bold"
            >
              <button class="ui button" style="padding: 10px" disabled>
                Esta observación se revisará
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="ui basic modal one"
    style="width: 100%; margin: 25vh auto; overflow: hidden"
  >
    <div class="ui icon header text-center">Responder a la observación</div>
    <div class="content text-center">
      <div class="row mb">
        <textarea
          [(ngModel)]="responseText"
          [ngClass]="{ invalid: argumento.invalid && argumento.dirty }"
          class="form-control"
          name="argument"
          rows="4"
        ></textarea>
      </div>
      <!-- <p *ngIf="archivo">{{archivos.name}}</p> -->
    </div>
    <div class="actions" style="display: flex; justify-content: center">
      <div class="ui red basic cancel inverted button">
        <i class="remove icon"></i>
        Cancelar
      </div>
      <div class="ui green ok inverted button" (click)="sendResponse()">
        <i class="checkmark icon"></i>
        Enviar respuesta
      </div>
    </div>
  </div>

  <ng-template #contenido1 let-modal>
    <!-- (click)="modal.dismiss()" -->

    <div class="modal-heade" style="height: 50vh; overflow-y: scroll">
      <div *ngIf="responses.length > 0">
        <div class="box_modal mb-3" *ngFor="let response of responses">
          <div style="display: flex">
            <div class="id_user">
              <p style="font-weight: bold">Usuario {{ response.id }}</p>
            </div>
          </div>
          <div class="comment mt-2">
            {{ response.response }}
          </div>
          <div class="date mt-3">
            <p>{{ response.date | date : "dd-MM-yyyy" }}</p>
            &nbsp; alas&nbsp;
            <p>{{ response.date | date : "hh:mm" }}</p>
          </div>
          <hr />
        </div>
      </div>
      <div *ngIf="responses.length == 0">
        <h4 class="mb-3">No hay datos para mostrar</h4>
      </div>
    </div>
    <button class="ui button" (click)="closeModal()">Cerrar</button>
  </ng-template>

  <ng-template #contenido2 let-modal>
    <div class="modal-heade">
      <div class="box_modal mb-3" *ngFor="let response of responses">
        <div style="display: flex">
          <div class="date mt-3">
            Enviado el&nbsp;
            <p>{{ response.date | date : "dd-MM-yyyy" }}</p>
            &nbsp; alas&nbsp;
            <p>{{ response.date | date : "hh:mm" }}</p>
          </div>
        </div>
        <div
          style="border-color: black"
          class="ui button mt-2"
          (click)="seeArchive(response)"
        >
          <div style="margin: 6px">Ver archivo</div>
        </div>

        <hr />
      </div>
      <button class="ui button" (click)="closeModal()">Cerrar</button>
    </div>
  </ng-template>

  <!-- <ng-template #contenido2 let-modal>




    <div class="modal-heade">
      <div class="box_modal mb-3">
        <div style="display: flex;">
          <div class="date mt-3">
           <p>asdasdsad</p>
          </div>
        </div>
        <div style="border-color: black;" class="ui button mt-2" (click)="seeArchive(response)">
          <div style="margin: 6px;">
            Ver archivo
          </div>
        </div>

        <hr>
      </div>
      <button class="ui button" (click)="closeModal()">Cerrar</button>
    </div>
  </ng-template> -->

  <ng-template #contenido3 let-modal>
    <div class="modal-heade">
      <div class="box_modal mb-3">
        <div style="display: flex; justify-content: center">
          <div class="date mt-3">
            <div class="mb-2">
              <h2 class="title text-center">Subir documento</h2>
            </div>
          </div>
        </div>
        <div class="text-center mt-5">
          <input
            style="display: none"
            type="file"
            id="input-image"
            appFiles
            (change)="fileChange($event)"
          />

          <label for="input-image">
            <div class="ui primary button" *ngIf="!archivo">Elegir archivo</div>
            <div class="ui primary button" *ngIf="archivo">
              Elegir otro archivo
            </div>
          </label>
        </div>
      </div>
      <button
        class="text-center mt-5 ui primary button"
        *ngIf="archivo && !isUpload"
        (click)="cargarImagenes()"
      >
        Enviar archivo
      </button>
      <button
        class="text-center mt-5 ui loading primary button"
        *ngIf="archivo && isUpload"
      >
        Enviar archivo
      </button>
      <button class="ui button" (click)="closeModal()">Cerrar</button>
    </div>
  </ng-template>

  <div class="ui basic modal two" style="margin-left: 180px; overflow: hidden">
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      "
    >
      <div
        *ngIf="
          fileType == 'application/jpg' ||
          fileType == 'application/png' ||
          fileType == 'application/jpeg'
        "
      >
        <img [src]="urlFile" style="width: 100%; height: 90vh" />
      </div>

      <div class="actions" style="display: flex; justify-content: center">
        <div class="ui red basic cancel inverted button mt-3">
          <i class="remove icon" (click)="closeModalViewer()"></i>
          Cerrar
        </div>
      </div>
    </div>
  </div>
</div>
