<div class="main ">
  <div class="mt-4 mb-5">
    <h2 class="title">
      <div *ngIf="!idL">Agregar nuevo cliente</div>
      <div *ngIf="idL">Agregar cliente</div>
    </h2>
  </div>

  <div class="form-ingresa" *ngIf="!idL">
    <form [formGroup]="clienteForm" (ngSubmit)="SaveForm()">
      <div class="subtitle mb-3">
        <h5>Datos de Cliente</h5>
      </div>

      <div class="row mb">
        <div class="col">
          <div class="form-outline">
            <input type="text" id="name" [ngClass]="{invalid: nombre.invalid && nombre.dirty}" class="form-control"
              formControlName="nombre" name="nombre" placeholder="Nombre(s)" />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input type="text" id="last_name" class="form-control"
              [ngClass]="{invalid: apellido.invalid && apellido.dirty}" formControlName="apellido" name="apellido"
              placeholder="Apellidos" />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input type="text" id="id_identity" class="form-control"
              [ngClass]="{invalid: identificacion.invalid && identificacion.dirty}" name="identificacion"
              formControlName="identificacion" placeholder="ID de identificacion" />
          </div>
        </div>
      </div>

      <div class="row mb">
        <div class="col">
          <div class="form-outline">
            <input type="text" id="email" class="form-control" [ngClass]="{invalid: correo.invalid && correo.dirty}"
              formControlName="correo" name="correo" placeholder="Correo Electrónico (opcional)" />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input type="text" id="phone" class="form-control" [ngClass]="{invalid: telefono.invalid && telefono.dirty}"
              formControlName="telefono" name="telefono" placeholder="Teléfono" />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input type="button" title="Agregar datos Adicionales" class="ui button show-more" [value]="text"
              (click)="aparecer()" />
          </div>
        </div>
      </div>
      <div class="row mb" *ngIf="mostrar">
        <div class="col">
          <div class="form-outline">
            <input type="text" class="form-control" id="add_email" formControlName="seg_correo" name="seg_correo"
              placeholder="Correo Electrónico Adicional (opcional)" />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input type="text" class="form-control" id="add_phone" formControlName="seg_telefono" name="seg_telefono"
              placeholder="Teléfono Adicional (opcional)" />
          </div>
        </div>
        <div class="col">
          <div class="form-outline"></div>
        </div>
      </div>

      <div class="subtitle mb-3">
        <h5>Dirección</h5>
      </div>
      <div class="row mb">
        <div class="col">
          <div class="form-outline">
            <input type="text" id="postal_code" class="form-control" [ngClass]="{invalid: code.invalid && code.dirty}"
              formControlName="cp" name="cp" (input)="searchNh($event.target.value)" placeholder="Código Postal" />
          </div>
        </div>
        <div class="col" *ngIf="neighborhood && !showInputNeighborhood">
          <div class="form-outline">
            <select (change)="addNewNeighborhood($event.target.value)" formControlName="colonia"
              aria-placeholder="Colonia" class="form-select" name="colonia">
              <option value="{{ col }}" *ngFor="let col of neighborhood; let i = index">
                {{ col }}
              </option>
              <option value="Ingresar Colonia">Ingresar Colonia</option>
            </select>
          </div>
        </div>
        <div class="col" *ngIf="!neighborhood && !showInputNeighborhood">
          <div class="form-outline">
            <select (change)="addNewNeighborhood($event.target.value)" class="form-select"
              aria-label="Default select example" formControlName="colonia" aria-placeholder="Colonia" name="colonia">
              <option value="Ingresar Colonia">Ingresar Colonia</option>
            </select>

          </div>
        </div>
        <div class="col" *ngIf="showInputNeighborhood">
          <div class="form-outline">
            <input type="text" class="form-control" [ngClass]="{invalid: colonia.invalid && colonia.dirty}"
              formControlName="colonia" name="colonia" placeholder="Colonia" />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input type="text" id="street" [ngClass]="{invalid: calle.invalid && calle.dirty}" class="form-control"
              formControlName="calle" name="calle" placeholder="Calle" />
          </div>
        </div>
      </div>
      <div class="row mb">
        <div class="col">
          <div class="form-outline">
            <input type="text" id="no_exterior" [ngClass]="{invalid: no_exterior.invalid && no_exterior.dirty}"
              class="form-control" formControlName="no_exterior" name="no_exterior" placeholder="Número Exterior" />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input type="text" id="no_inside" class="form-control" formControlName="no_interior" name="no_interior"
              placeholder="Número Interior (opcional)" />
          </div>
        </div>

        <div class="col" *ngIf="city">
          <div class="form-outline">
            <input type="text" class="form-control" value="{{city |titlecase}}" name="ciudad" disabled
              placeholder="Ciudad" />
          </div>
        </div>
        <div class="col" *ngIf="!city">
          <div class="form-outline">
            <input type="text" class="form-control" [(ngModel)]="inputCity" [ngModelOptions]="{standalone: true}"
              name="inputCity" placeholder="Ciudad" />
          </div>
        </div>
      </div>
      <div class="row mb">
        <div class="col" *ngIf="!state">
          <div class="form-outline">
            <input type="text" class="form-control" [(ngModel)]="inputState" [ngModelOptions]="{standalone: true}"
              name="inputState" placeholder="Estado" />
          </div>
        </div>
        <div class="col" *ngIf="state">
          <div class="form-outline">
            <input type="text" class="form-control" value="{{state |titlecase}}" name="estado" disabled
              placeholder="Estado" />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input type="text" class="form-control" value="México" name="pais" disabled placeholder="País" />
          </div>
        </div>
        <div class="col">
          <div class="form-outline"></div>
        </div>
      </div>
      <div class="subtitle mb-3">
        <h5>Contacto Adicional (opcional)</h5>
      </div>
      <div class="row mb">
        <div class="col">
          <div class="form-outline">
            <input type="text" class="form-control"
              [ngClass]="{invalid: adicional_nombre.invalid && adicional_nombre.dirty}"
              formControlName="adicional_nombre" name="adicional_nombre" placeholder="Nombre(s)" />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input type="text" class="form-control"
              [ngClass]="{invalid: adicional_apellido.invalid && adicional_apellido.dirty}"
              formControlName="adicional_apellido" name="adicional_apellido" placeholder="Apellidos" />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input type="text" class="form-control"
              [ngClass]="{invalid: adicional_telefono.invalid && adicional_telefono.dirty}"
              formControlName="adicional_telefono" name="adicional_telefono" placeholder="Teléfono" />
          </div>
        </div>
      </div>
      <div class="row mb">
        <div class="col">
          <div class="form-outline">
            <input type="text" class="form-control"
              [ngClass]="{invalid: adicional_correo.invalid && adicional_correo.dirty}"
              formControlName="adicional_correo" name="adicional_correo" placeholder="Correo Electrónico (opcional)" />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input type="button" class="ui button show-more" [value]="text2" (click)="aparecer2()" />
          </div>
        </div>
        <div class="col">
          <div class="form-outline"></div>
        </div>
      </div>
      <div class="row mb" *ngIf="mostrar2">
        <div class="col">
          <div class="form-outline">
            <input type="text" class="form-control" formControlName="adicional_seg_correo" name="adicional_seg_correo"
              placeholder="Correo Electrónico Adicional (opcional)" />
          </div>
        </div>
        <div class="col">
          <div class="form-outline">
            <input type="text" class="form-control" formControlName="adicional_seg_telefono" name="adicional_telefono"
              placeholder="Teléfono Adicional (opcional)" />
          </div>
        </div>
        <div class="col">
          <div class="form-outline"></div>
        </div>
      </div>

      <div class="subtitle mb-3" style="display: flex; align-items: center;">
        <h5>Comentario</h5>
        <div class="ui" data-tooltip="El comentario es información adicional sobre el cliente"
          data-position="top center" data-variation="mini">
          <i class="ui question circle outline icon"
            style="margin-bottom: 17.5px; margin-left: 10px; cursor: pointer;"></i>
        </div>
      </div>
      <div class="row mb">
        <!-- [ngClass]="{'form-control':!descripcion.invalid, 'input_invalid':descripcion.invalid && (descripcion.dirty)}" -->
        <textarea formControlName="descripcion" [ngClass]="{invalid: descripcion.invalid && descripcion.dirty}"
          name="descripcion" class="form-control" rows="4"></textarea>
        <p class="text-center" *ngIf="descripcion.invalid && descripcion.dirty" style="color: #d10707">*El máximo es de
          {{lenghtComment}} carácteres.</p>
      </div>
      <div class="text-center mt-3 mb-3">
        <button class="ui button" [disabled]="clienteForm.invalid">
          Continuar
        </button>
      </div>
    </form>
  </div>

  <div class="form-ingresa" *ngIf="idL">
    <div class="subtitle mb-3">
      <h5>Datos de Cliente</h5>
    </div>
    <div class="row mb">
      <div class="col">
        <div class="form-outline">
          <input type="text" id="name" [ngClass]="{invalid: nombre.invalid && nombre.dirty}" class="form-control"
            [(ngModel)]="nombre_lead" name="nombre" placeholder="Nombre(s)" />
        </div>
      </div>
      <div class="col">
        <div class="form-outline">
          <input type="text" id="last_name" class="form-control"
            [ngClass]="{invalid: apellido.invalid && apellido.dirty}" [(ngModel)]="apellido_lead" name="apellido"
            placeholder="Apellidos" />
        </div>
      </div>
      <div class="col">
        <div class="form-outline">
          <input type="text" id="id_identity" class="form-control" [(ngModel)]="identificacion_lead"
            [ngClass]="{invalid: identificacion.invalid && identificacion.dirty}" name="identificacion"
            placeholder="ID de identificacion" />
        </div>
      </div>
    </div>

    <div class="row mb">
      <div class="col">
        <div class="form-outline">
          <input type="text" id="email" class="form-control" [ngClass]="{invalid: correo.invalid && correo.dirty}"
            [(ngModel)]="correo_lead" name="correo" placeholder="Correo Electrónico (opcional)" />
        </div>
      </div>
      <div class="col">
        <div class="form-outline">
          <input type="text" id="phone" class="form-control" [ngClass]="{invalid: telefono.invalid && telefono.dirty}"
            [(ngModel)]="telefono_lead" name="telefono" placeholder="Teléfono" />
        </div>
      </div>
      <div class="col">
        <div class="form-outline">
          <input type="button" title="Agregar datos Adicionales" class="ui button show-more" [value]="text"
            (click)="aparecer()" />
        </div>
      </div>
    </div>
    <div class="row mb" *ngIf="mostrar">
      <div class="col">
        <div class="form-outline">
          <input type="text" class="form-control" id="add_email" name="seg_correo"
            placeholder="Correo Electrónico Adicional (opcional)" />
        </div>
      </div>
      <div class="col">
        <div class="form-outline">
          <input type="text" class="form-control" id="add_phone" name="seg_telefono"
            placeholder="Teléfono Adicional (opcional)" />
        </div>
      </div>
      <div class="col">
        <div class="form-outline"></div>
      </div>
    </div>

    <div class="subtitle mb-3">
      <h5>Dirección</h5>
    </div>
    <div class="row mb">
      <div class="col">
        <div class="form-outline">
          <input type="text" id="postal_code" class="form-control" [ngClass]="{invalid: code.invalid && code.dirty}"
            name="cp" [(ngModel)]="cp_lead" (input)="searchNh($event.target.value)" placeholder="Código Postal" />
        </div>
      </div>
      <div class="col" *ngIf="neighborhood && !showInputNeighborhood">
        <div class="form-outline">
          <select (change)="addNewNeighborhood($event.target.value)" aria-placeholder="Colonia" class="form-select"
            name="colonia">
            <option value="{{ col }}" *ngFor="let col of neighborhood; let i = index">
              {{ col }}
            </option>
            <option (click)="showInput()">Ingresar Colonia</option>
          </select>
        </div>
      </div>
      <div class="col" *ngIf="!neighborhood && !showInputNeighborhood">
        <div class="form-outline">
          <select (change)="addNewNeighborhood($event.target.value)" class="form-select"
            aria-label="Default select example" aria-placeholder="Colonia" name="colonia">
            <option>Colonia</option>
            <option (click)="showInput()">Ingresar Colonia</option>

          </select>

        </div>
      </div>
      <div class="col" *ngIf="showInputNeighborhood">
        <div class="form-outline">
          <input [(ngModel)]="colonia_lead" type="text" class="form-control"
            [ngClass]="{invalid: colonia.invalid && colonia.dirty}" name="colonia" placeholder="Colonia" />
        </div>
      </div>
      <div class="col">
        <div class="form-outline">
          <input type="text" id="street" [ngClass]="{invalid: calle.invalid && calle.dirty}" class="form-control"
            name="calle" [(ngModel)]="calle_lead" placeholder="Calle" />
        </div>
      </div>
    </div>
    <div class="row mb">
      <div class="col">
        <div class="form-outline">
          <input type="text" id="no_exterior" [ngClass]="{invalid: no_exterior.invalid && no_exterior.dirty}"
            class="form-control" [(ngModel)]="no_exterior_lead" name="no_exterior" placeholder="Número Exterior" />
        </div>
      </div>
      <div class="col">
        <div class="form-outline">
          <input type="text" [(ngModel)]="no_interior_lead" id="no_inside" class="form-control" name="no_interior"
            placeholder="Número Interior (opcional)" />
        </div>
      </div>

      <div class="col" *ngIf="city">
        <div class="form-outline">
          <input type="text" class="form-control" value="{{city |titlecase}}" name="ciudad" disabled
            placeholder="Ciudad" />
        </div>
      </div>
      <div class="col" *ngIf="!city">
        <div class="form-outline">
          <input type="text" class="form-control" [(ngModel)]="inputCity" [ngModelOptions]="{standalone: true}"
            name="inputCity" placeholder="Ciudad" />
        </div>
      </div>
    </div>
    <div class="row mb">
      <div class="col" *ngIf="!state">
        <div class="form-outline">
          <input type="text" class="form-control" [(ngModel)]="inputState" [ngModelOptions]="{standalone: true}"
            name="inputState" placeholder="Estado" />
        </div>
      </div>
      <div class="col" *ngIf="state">
        <div class="form-outline">
          <input type="text" class="form-control" value="{{state |titlecase}}" name="estado" disabled
            placeholder="Estado" />
        </div>
      </div>
      <div class="col">
        <div class="form-outline">
          <input type="text" class="form-control" value="México" name="pais" disabled placeholder="País" />
        </div>
      </div>
      <div class="col">
        <div class="form-outline"></div>
      </div>
    </div>
    <div class="subtitle mb-3">
      <h5>Contacto Adicional (opcional)</h5>
    </div>
    <div class="row mb">
      <div class="col">
        <div class="form-outline">
          <input type="text" class="form-control"
            [ngClass]="{invalid: adicional_nombre.invalid && adicional_nombre.dirty}"
            [(ngModel)]="nombre_adicional_lead" name="adicional_nombre" placeholder="Nombre(s)" />
        </div>
      </div>
      <div class="col">
        <div class="form-outline">
          <input type="text" class="form-control"
            [ngClass]="{invalid: adicional_apellido.invalid && adicional_apellido.dirty}"
            [(ngModel)]="apellido_adicional_lead" name="adicional_apellido" placeholder="Apellidos" />
        </div>
      </div>
      <div class="col">
        <div class="form-outline">
          <input type="text" class="form-control"
            [ngClass]="{invalid: adicional_telefono.invalid && adicional_telefono.dirty}"
            [(ngModel)]="telefono_adicional_lead" name="adicional_telefono" placeholder="Teléfono" />
        </div>
      </div>
    </div>
    <div class="row mb">
      <div class="col">
        <div class="form-outline">
          <input type="text" class="form-control"
            [ngClass]="{invalid: adicional_correo.invalid && adicional_correo.dirty}"
            [(ngModel)]="correo_adicional_lead" name="adicional_correo" placeholder="Correo Electrónico (opcional)" />
        </div>
      </div>
      <div class="col">
        <div class="form-outline">
          <input type="button" class="ui button show-more" [value]="text2" (click)="aparecer2()" />
        </div>
      </div>
      <div class="col">
        <div class="form-outline"></div>
      </div>
    </div>
    <div class="row mb" *ngIf="mostrar2">
      <div class="col">
        <div class="form-outline">
          <input type="text" class="form-control" [(ngModel)]="adicional_seg_correo" name="adicional_seg_correo"
            placeholder="Correo Electrónico Adicional (opcional)" />
        </div>
      </div>
      <div class="col">
        <div class="form-outline">
          <input type="text" class="form-control" [(ngModel)]="adicional_seg_telefono" name="adicional_telefono"
            placeholder="Teléfono Adicional (opcional)" />
        </div>
      </div>
      <div class="col">
        <div class="form-outline"></div>
      </div>
    </div>

    <div class="subtitle mb-3" style="display: flex; align-items: center;">
      <h5>Comentario</h5>
      <div class="ui" data-tooltip="El comentario es información adicional sobre el cliente" data-position="top center"
        data-variation="mini">
        <i class="ui question circle outline icon"
          style="margin-bottom: 17.5px; margin-left: 10px; cursor: pointer;"></i>
      </div>
    </div>
    <div class="row mb">

      <textarea [(ngModel)]="comentario_lead" [ngClass]="{invalid: descripcion.invalid && descripcion.dirty}"
        name="descripcion" class="form-control" rows="4"></textarea>
      <p class="text-center" *ngIf="descripcion.invalid && descripcion.dirty" style="color: #d10707">*El máximo es de
        {{lenghtComment}} carácteres.</p>
    </div>
    <div class="text-center mt-3 mb-3">


      <button (click)="SaveForm()" *ngIf="idL" class="ui button">
        Continuar
      </button>
    </div>

  </div>


  <ng-template #myModal>
    <div class="main">
      <div class="container-modal">
        <modal-content>
          <h5 class="lead text-center mt-3 mb-3">Este cliente ya está relacionado con otro usuario</h5>
        </modal-content>
        <modal-footer class="btn-options">
          <button class="ui button fs-mini" (click)="closeModal()">
            De Acuerdo
          </button>
        </modal-footer>
      </div>
    </div>
  </ng-template>
</div>
