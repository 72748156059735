<div class="main">
    <div  *ngIf="!verifiedToken">
        <div class="otp-center">
            <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:6}"></ng-otp-input>
        </div>
    </div>
    <div *ngIf="verifiedToken">
        <div class="text-center fx mb-4 mt-3">
            <!-- <i class="large arrow left icon" (click)="back()" style="cursor:pointer;"></i> -->
            <h2 style="margin: 0;">Información del Ingreso</h2>
            <div></div>
        </div>
        <hr>

        <div class="subtitle mb-3 fx">
            <h5>Información del cliente</h5>
        </div>




        <div class="table-responsive" *ngIf="infoLead">
            <div class="userMain">
                <table class="table">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">ID lead</th>
                            <th class="large" scope="col">Estatus</th>
                            <th scope="col">Nombre</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>{{ infoLead.id }}</th>
                            <td>{{ infoLead.estatus | titlecase }}</td>
                            <td class="large">{{ infoLead.cliente.nombre | titlecase }} {{ infoLead.cliente.apellido |
                                titlecase
                                }}</td>

                        </tr>
                    </tbody>
                </table>
            </div>

        </div>

        <div class="subtitle mb-3 fx">
            <h5>Información del cliente</h5>
        </div>
        <div class="table-responsive">
            <div class="userMain">
                <table class="table">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Apellidos</th>
                            <th scope="col">ID de identificación</th>
                            <th scope="col">Número de Contacto</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>asdasdsad</td>
                            <td>sadsadasd</td>
                            <td>sadasdsadsad</td>

                            <td>sadsadsadsad</td>


                        </tr>
                    </tbody>
                </table>


            </div>

        </div>

        <div>
            <div class="text-center" *ngIf="user.status !== 'undefined' && user.role !== 'NO-ROLE' && !changeRole
            ">
                <button class="ui red button" (click)="desable()">
                    Rechazar
                </button>
                <button class="ui green button" (click)="acceptRequestKeyAccess()">
                    Aceptar
                </button>
            </div>
        </div>

    </div>

    <div class="alerts">
        <div class="alert alert-danger" role="alert" *ngIf="tokenError">
            <h6 class="mt-2">{{tokenErrorMessage}}</h6>

        </div>
    </div>

</div>