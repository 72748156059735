import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-allarticles',
  templateUrl: './allarticles.component.html',
  styleUrls: ['./allarticles.component.scss']
})
export class AllarticlesComponent implements OnInit {
  articles: any[] = [];
  constructor(public afs: AngularFirestore,) { }

  ngOnInit(): void {

    this.afs.collection('ARTICLE').valueChanges().subscribe((articles) => {
      this.articles = [];
      articles.filter((article: any) => {
        if (article.articulo['superficie'] == null || article.articulo['superficie'] == undefined || article.articulo['superficie'] == '') {
          this.articles.push(article);
        }
      }
      );
    });
  }
  addSuper(idDoc, superficie) {
   

    this.afs.collection('ARTICLE').doc(idDoc).update({
      'articulo.superficie': superficie
    }).then(() => {
      console.log('update');
    }).catch((error) => {
      console.log(error);
    });
  }
}
