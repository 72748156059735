import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/SERVICIOS/auth.service';
import { DataDbService } from 'src/app/SERVICIOS/data-db.service';
import { GenerateIdService } from 'src/app/SERVICIOS/generate-id.service';
import { User } from '../MODULOS/user.interface';



@Component({
  selector: 'app-article-listing',
  templateUrl: './article-listing.component.html',
  styleUrls: ['./article-listing.component.scss']
})
export class ArticleListingComponent implements OnInit, OnDestroy {
  public contactForm: UntypedFormGroup;
  p: number = 1;
  articles = []
  positive_or_negative = []
  idArticle = []
  inmueblesEncontrados: any;
  data: any;
  valueInput: string;
  valueName: string;
  all_articles: any;
  filterPost = '';
  disabledInput: boolean = true
  disabledSelect: boolean = false
  selectedValuesToSearch = []
  idR: string;
  id: number;
  getIdArticles: any[];
  getFilterSelect: string;
  getValueSelect: string;
  valueTags: any[];
  text_button = 'Ocultar'
  show: boolean = true;
  sub: Subscription;
  user: User;
  search: boolean = false;
  isKeyAccess: boolean = false;
  idKeyAccess = ''
  idCustomerOtherArticle = ''
  constructor(private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private dbData: DataDbService,
    private afs: AngularFirestore,
    public auth: AuthService,
    private generateId: GenerateIdService
  ) {

    this.idR = this.route.snapshot.paramMap.get('idRegistra');
    this.idKeyAccess = this.route.snapshot.paramMap.get('idkeyaccess');
    this.idCustomerOtherArticle = this.route.snapshot.paramMap.get('idCustomerOtherArticle');

    if (this.idKeyAccess != null && this.idKeyAccess != undefined) {
      this.isKeyAccess = true
    }
    this.auth.user$.pipe(take(1)).toPromise().then((user: User) => {
      this.user = user
    })
    this.disabledInput = true
  }


  addArticleToKeyAccess(article: any) {
    this.afs.collection('ARTICLE').doc(article.id_document).collection('KEY-ACCESS').add({
      id: this.generateId.createId(),
      id_article: article.id_document,
      status: 'incompleto',
      tokenVerified: false,
      complete: false,
      approve: false,
      requestSign: false,
      date: moment().format(),
      uid_user: this.user.uid,
      enableKeyAccess: false,
      confirmPickUpKeysUser: false,
      termsAndConditionsAccepted: false,
      signed: false,
      confirmPickUpKeys: false
    }).then((doc) => {
      this.afs.collection('ARTICLE').doc(article.id_document).collection('KEY-ACCESS').doc(doc.id).update({
        id_document: doc.id,
      }).then((docs) => {
        this.router.navigate(['keyaccess/select-lead', article.id_document, doc.id]);
      })
    })
  }


  createForm() {
    return new UntypedFormGroup({
      caracteristicas: this.formBuilder.array(
        this.dbData.getCaracteristicas().map(caracteristica => {
          return this.formBuilder.group({
            id: caracteristica.id,
            descripcion: caracteristica.descripcion,
            seleccionado: false,
          })
        })
      ),

    });
  }

  featureForm() {
    return new UntypedFormGroup({
      caracteristicas: this.formBuilder.array(
        this.valueTags.map(caracteristica => {
          return this.formBuilder.group({
            id: caracteristica.id,
            descripcion: caracteristica.descripcion,
            seleccionado: caracteristica.seleccionado,
          })
        })
      ),

    });
  }

  get caracteristicas() {
    return this.contactForm.get('caracteristicas') as UntypedFormArray;
  }


  ngOnInit(): void {
    this.getArticles();
    let getPage = localStorage.getItem('page_al')
    if (getPage !== null && getPage !== undefined && getPage !== '') {
      this.p = Number(getPage)
    }
  }

  getArticles() {
    this.sub = this.afs.collection("ARTICLE").valueChanges().subscribe((articles: any) => {
      this.articles = articles.filter((article: any) => article.completado == true && (article.estatus == "habilitado" || article.estatus == "por vencer") && article.commodity.estatus == 'aprobado')
      this.all_articles = articles.filter((article: any) => article.completado == true && (article.estatus == "habilitado" || article.estatus == "por vencer") && article.commodity.estatus == 'aprobado')


      let getFilterSelect = localStorage.getItem("filterWithSelect");
      let getValueSelect = localStorage.getItem("filterWithSelectValue");
      let getValueTags = localStorage.getItem("searchWithTags");

      if (getFilterSelect !== null) {
        this.getFilterSelect = getFilterSelect;
        this.filterWithSelect(getFilterSelect);
        this.disabledInput = false;
      }
      if (getValueSelect !== undefined) {
        this.getValueSelect = getValueSelect
        this.disabledInput = false;
        this.filterPost = getValueSelect
      }
      if (getValueTags !== null) {

        this.valueTags = JSON.parse(getValueTags);
        this.contactForm = this.featureForm();
        this.disabledInput = true;
        this.disabledSelect = true;

        this.searchWithTags();

      } else {
        this.contactForm = this.createForm();
      }
    })
  }


  async searchWithTags() {
    this.selectedValuesToSearch = this.caracteristicas.value.filter((c: { seleccionado: boolean; }) => c.seleccionado === true);

    if (this.selectedValuesToSearch.length > 0) {
      localStorage.setItem("searchWithTags", JSON.stringify(this.caracteristicas.value));
      localStorage.removeItem("filterWithSelect");
      localStorage.removeItem("filterWithSelectValue");
      // this.disabledSelect = true
      // this.disabledInput = true
      this.filterPost = '';
    }

    this.inmueblesEncontrados = await this.all_articles.filter((inmuebleEncontradoEnDB: { caracteristicas: any[]; contador: number; }) => {
      let encontrado = false;
      let contador = 0;
      if (inmuebleEncontradoEnDB.caracteristicas) {
        for (const inmCaracteristica of inmuebleEncontradoEnDB.caracteristicas) {
          for (const selectedValueToSearch of this.selectedValuesToSearch) {
            if (inmCaracteristica.id === selectedValueToSearch.id && inmCaracteristica.seleccionado === selectedValueToSearch.seleccionado) {
              contador++;
              if (contador >= this.selectedValuesToSearch.length || contador == this.selectedValuesToSearch.length || contador <= this.selectedValuesToSearch.length) {
                encontrado = true;
              }
            }
          }
        }
        inmuebleEncontradoEnDB.contador = contador;
        inmuebleEncontradoEnDB.caracteristicas = inmuebleEncontradoEnDB.caracteristicas.filter((c: { seleccionado: any; }) => c.seleccionado);
        return encontrado;
      }
    });
    this.articles = this.inmueblesEncontrados;
    if (this.articles.length === 0 && this.selectedValuesToSearch.length === 0) {
      this.articles = this.all_articles
    }
  }



  filterWithSelect(e: string) {

    localStorage.removeItem("searchWithTags");
    if (e == '1') {
      this.valueName = "id_articulo";
      localStorage.setItem("filterWithSelect", '1');
      this.disabledInput = false

    } else if (e == '2') {
      this.valueName = "calle";
      localStorage.setItem("filterWithSelect", '2');

      this.disabledInput = false

    } else if (e == '3') {
      this.valueName = "colonia";
      localStorage.setItem("filterWithSelect", '3');

      this.disabledInput = false

    } else if (e == '4') {
      this.valueName = "ciudad";
      localStorage.setItem("filterWithSelect", '4');

      this.disabledInput = false
    } else {
      this.disabledInput = true
    }
  }

  getFilter(e: string) {
    this.resetPage();
    this.search = true
    this.filterPost = e
    localStorage.setItem("filterWithSelectValue", e);
    setTimeout(() => {
      this.search = false
    }, 200);
  }

  changePage(e) {
    this.p = e
    localStorage.setItem('page_al', e)
  }
  resetPage() {
    this.p = 1;
    localStorage.setItem('page_al', '1')
  }



  addLead(art: { id_articulo: any; }) {
    if (this.idCustomerOtherArticle != "" && this.idCustomerOtherArticle != null) {
      let obj = {
        id: art.id_articulo
      }


      this.afs.collection('CUSTOMER').doc(this.idCustomerOtherArticle).get().toPromise().then((lData: any) => {
        let leadData = lData.data();
        this.positive_or_negative = leadData.positive_or_negative
        this.getIdArticles = leadData.idArticulos;
        if (this.positive_or_negative == undefined && this.getIdArticles == undefined) {
          let idArticulos = firebase.firestore.FieldValue.arrayUnion(obj)
          this.afs.collection('CUSTOMER').doc(this.idCustomerOtherArticle).update({ idArticulos })
            .then((e) => {
              this.router.navigate(['historys/ingresa-history', this.idCustomerOtherArticle, 'fullview'])
            })

        } else if (this.positive_or_negative == undefined) {
          // let check = this.positive_or_negative.find(article => article.id_articulo == art.id_articulo);
          let find = this.getIdArticles.find(article => article.id == art.id_articulo);
          if (find) {
            alert('Ya agregaste este artículo')
          }
          else {
            let idArticulos = firebase.firestore.FieldValue.arrayUnion(obj)
            this.afs.collection('CUSTOMER').doc(this.idCustomerOtherArticle).update({ idArticulos })
              .then((e) => {
                this.router.navigate(['historys/ingresa-history', this.idCustomerOtherArticle, 'fullview'])
              })
          }
        } let check = this.positive_or_negative.find(article => article.id_articulo == art.id_articulo);
        let find = this.getIdArticles.find(article => article.id == art.id_articulo);
        if (find || check) {
          alert('Ya agregaste este artículo')
        }
        else {
          let idArticulos = firebase.firestore.FieldValue.arrayUnion(obj)
          this.afs.collection('CUSTOMER').doc(this.idCustomerOtherArticle).update({ idArticulos })
            .then((e) => {
              this.router.navigate(['historys/ingresa-history', this.idCustomerOtherArticle, 'fullview'])
            })
        }
      });






    } else
      if (this.idR) {
        let obj = {
          id: art.id_articulo
        }
        this.afs.collection('LEAD').doc(this.idR).valueChanges().pipe(take(1)).toPromise().then((values: any) => {
          this.positive_or_negative = values.positive_or_negative
          this.getIdArticles = values.idArticulos;

          if (this.positive_or_negative == undefined && this.getIdArticles == undefined) {
            let idArticulos = firebase.firestore.FieldValue.arrayUnion(obj)
            this.afs.collection('LEAD').doc(this.idR).update({ idArticulos, cuarentena: false }).then(doc => {
              this.router.navigate(['gestiona', this.idR, 'go'])
            })

          } else if (this.positive_or_negative == undefined) {
            // let check = this.positive_or_negative.find(article => article.id_articulo == art.id_articulo);
            let find = this.getIdArticles.find(article => article.id == art.id_articulo);
            if (find) {
              alert('Ya agregaste este artículo')
            }
            else {
              let idArticulos = firebase.firestore.FieldValue.arrayUnion(obj)
              this.afs.collection('LEAD').doc(this.idR).update({ idArticulos, cuarentena: false }).then(doc => {
                this.router.navigate(['gestiona', this.idR, 'go'])
              })
            }
          } else {

            let check = this.positive_or_negative.find(article => article.id_articulo == art.id_articulo);
            let find = this.getIdArticles.find(article => article.id == art.id_articulo);
            if (find || check) {
              alert('Ya agregaste este artículo')
            }
            else {
              let idArticulos = firebase.firestore.FieldValue.arrayUnion(obj)
              this.afs.collection('LEAD').doc(this.idR).update({ idArticulos, cuarentena: false }).then(doc => {
                this.router.navigate(['gestiona', this.idR, 'go'])
              })
            }
          }
        })
      } else {
        this.router.navigate(['registra', art.id_articulo])
      }

  }

  goToFullView(art: { id_document: any; }) {
    if (this.idR) this.router.navigate(['article-listing', this.idR, 'item-view', art.id_document]);
    else this.router.navigate(['registra', art.id_document, 'item-view', 'article-listing']);
  }

  show_hide() {
    this.show = !this.show
    if (this.show) this.text_button = 'Ocultar'
    else this.text_button = 'Mostrar'
  }


  ngOnDestroy() {
    if (this.sub !== undefined) this.sub.unsubscribe();
  }

}
