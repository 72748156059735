import { Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { SocketService } from "./SERVICIOS/socket/socket.service";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { NotificationsService } from "./SERVICIOS/socket/notifications.service";
import { NotificationService } from "./SERVICIOS/notification.service";
import { AuthService } from "./SERVICIOS/auth.service";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Subject, Subscription, timer } from "rxjs";
import { UserOnlineService } from "./SERVICIOS/user-online.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { GenerateIdService } from "./SERVICIOS/generate-id.service";
import { BubbleChatService } from "./SERVICIOS/socket/bubble-chat.service";
import { take } from "rxjs/operators";

import * as moment from "moment";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { User } from "./COMERCIAL/MODULOS/user.interface";
import { RecentActivityInterface } from "./interfaces/activity";
declare let $: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss", "./style/app.css"],
  animations: [
    trigger("animaciones", [
      //bloque 1 estado normal
      state("bloque1Normal", style({})),
      //bloque 1 se oculta
      state(
        "bloque1Ocultar",
        style({
          transform: "translateX(-26px)",
        }),
      ),

      //bloque 2 estado normal
      state("bloque2Normal", style({})),

      //cuando se oculta el bloque 1 se muestra automaticamente el bloque 2
      state(
        "bloque2Mostrar",
        style({
          transform: "translateX(-261px)",
        }),
      ),
      state("Dropoculto", style({})),
      state(
        "Dropmostrado",
        style({
          display: "block",
        }),
      ),

      state("Notioculto", style({})),

      state(
        "Notimostrado",
        style({
          display: "block",
        }),
      ),

      transition("bloque1Normal =>  bloque1Ocultar", animate(".35s ease-in")),
      transition("bloque1Ocultar =>  bloque2Normal", animate(".35s ease-out")),
      transition("bloque2Normal =>  bloque2Mostrar", animate(".35s ease-in")),
      transition("bloque2Mostrar =>  bloque2Normal", animate(".35s ease-in")),
      transition("bloque2Normal =>  bloque1Ocultar", animate(".35s ease-in")),
      transition("bloque1Ocultar =>  bloque1Normal", animate(".35s ease-in")),
    ]),
  ],
})
export class AppComponent implements OnDestroy {
  current_time: any;
  bloque1Normal = "bloque1Normal";
  bloque2Normal = "bloque2Normal";
  Dropoculto = "Dropoculto";
  Notioculto = "Notioculto";
  mostrarBloques = false;
  urlImage: string;
  title = "set99";
  public user: User;
  url: string;
  public messageForm: UntypedFormGroup;
  public affairForm: UntypedFormGroup;
  showImg1 = true;
  showImg2 = false;
  showCard = false;
  form1 = true;
  form2 = false;
  form3 = false;
  form4 = false;
  form5 = false;
  text_asunto: string;
  text_description: string;
  id_salas: string;
  salas: any;
  message_chat_main: string;
  canCreateRoom: boolean;
  user_uid: string;
  roomToUser: any;
  chat_disabled: any;
  selected_room: boolean;
  messageToUser: any;
  message_chat_desabled: string;
  data_chats: any;
  text_message: any;
  disableChat: boolean = false;
  uid_user: string;
  minutesDisplay = 0;
  secondsDisplay = 0;
  endTime = 1;
  hideIcon = false;

  unsubscribe$: Subject<void> = new Subject();
  timerSubscription: Subscription;

  textFnChat: string;
  id_document: any;
  uid_userRoom: any;
  user_support: any;
  chatActive: any;
  subs_one: Subscription;
  subs_two: Subscription;
  msgNoReads: number = 0;

  constructor(
    public router: Router,
    public afAuth: AngularFireAuth,
    public gonotification: NotificationService,
    public auth: AuthService,
    private userOnline: UserOnlineService,
    private generateId: GenerateIdService,
    public chat: BubbleChatService,
    private socket: SocketService,
    public notification: NotificationsService,
    private afs: AngularFirestore,
  ) {
    this.auth.user$.subscribe((user) => {
      if (user) {
        this.user = user;
        this.searchChatActive();
        if (user.role == "ADMIN") this.socket.io.emit("join-admin");
        else if (user.role == "SUPPORT") this.socket.io.emit("join-support");
        else if (user.role == "CLASSIC") this.socket.io.emit("join-classic");
        else if (user.role == "FULL-ADMIN") {
          this.socket.io.emit("join-full-admin", user.uid);
        }
      }
    });
    this.disableUser();
    this.messageForm = this.createForm();
    this.affairForm = this.createAffairForm();
    this.chatAccepted();
    this.chatFinish();
    this.chatFinishTemporary();
  }

  toggle(): void {
    $(".ui.dropdown").dropdown("toggle");
    this.closeChat();
  }
  leadAndSupport(){
    this.router.navigate(["/markting"]);
  }
  createForm() {
    return new UntypedFormGroup({
      message: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(1),
      ]),
    });
  }
  createAffairForm() {
    return new UntypedFormGroup({
      affair: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(5),
      ]),
      description: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(5),
      ]),
    });
  }
  get message() {
    return this.messageForm.get("message");
  }
  get affair() {
    return this.affairForm.get("affair");
  }
  get description() {
    return this.affairForm.get("description");
  }

  searchChatActive() {
    this.subs_one = this.afs
      .collection(
        "USERS/" + this.user.uid + "/SUPPORT_CHATS",
        (ref) => ref.where("activo_bubble", "==", true),
      )
      .valueChanges()
      .subscribe((data: any) => {
        this.chatActive = data[0];
        if (this.chatActive !== undefined) {
          if (this.chatActive.no_leido !== undefined) {
            this.msgNoReads = this.chatActive.no_leido;
          }
          let id_document = this.chatActive.id_document;
          this.getChat(id_document);
          this.form2 = true;
          this.form1 = false;
          this.id_salas = id_document;
        }
      });
  }
  closeChat() {
    this.showCard = false;
  }

  createChatSupport() {
    this.showCard = !this.showCard;
    if (this.form1) {
      this.form1 = true;
      this.form2 = false;
      this.form3 = false;
      this.form4 = false;
      this.form5 = false;
    } else if (this.form2) {
      this.chat.inChat(true);
      this.msgReads();
      this.form2 = true;
      this.form1 = false;
      this.form3 = false;
      this.form4 = false;
      this.form5 = false;
    } else if (this.form3) {
      this.form3 = true;
      this.form2 = false;
      this.form1 = false;
      this.form4 = false;
      this.form5 = false;
    } else if (this.form4) {
      this.form4 = true;
      this.form2 = false;
      this.form3 = false;
      this.form1 = false;
      this.form5 = false;
    } else if (this.form5) {
      this.form5 = true;
      this.form2 = false;
      this.form3 = false;
      this.form4 = false;
      this.form1 = false;
    }
  }
  hideCard() {
    this.showCard = false;
    this.chat.inChat(false);
  }

  msgReads() {
    this.afs
      .doc(
        "USERS/" +
          this.chatActive.asunto.id_user +
          "/SUPPORT_CHATS/" +
          this.chatActive.id_document,
      )
      .update({ no_leido: 0 });
  }

  waiting() {
    this.createRoom();
    this.form1 = false;
    this.form3 = true;
    this.resetTimer();
  }

  interval() {
    this.resetTimer();
  }
  resetTimer(endTime: number = this.endTime) {
    const interval = 1000;
    const duration = endTime * 900;
    this.timerSubscription = timer(0, interval)
      .pipe(take(duration))
      .subscribe(
        (value) => this.render((duration - +value) * interval),
        (err) => {},
        () => {
          this.form3 = false;
          this.form4 = true;
          this.temporaChat();

          this.unsubscribe$.next();
          this.unsubscribe$.complete();
        },
      );
  }
  temporaChat() {
    this.afs
      .collection("USERS/" + this.user.uid + "/SUPPORT_CHATS")
      .doc(this.id_salas)
      .update({
        temporal: true,
      });
  }

  private getSeconds(ticks: number) {
    const seconds = ((ticks % 60000) / 1000).toFixed(0);
    return this.pad(seconds);
  }

  private getMinutes(ticks: number) {
    const minutes = Math.floor(ticks / 60000);
    return this.pad(minutes);
  }

  private pad(digit: any) {
    return digit <= 9 ? "0" + digit : digit;
  }
  createRoom() {
    if (this.userOnline.userOnline()) {
      let id_room = this.generateId.createId();
      let chats = [];

      this.afs
        .collection("USERS/" + this.user.uid + "/SUPPORT_CHATS")
        .add({
          chats,
          resuelto: false,
          tomado: false,
          temporal: false,
          id: id_room,
        })
        .then((res) => {
          this.id_salas = res.id;

          let asunto = {
            asunt: this.affair.value,
            description: this.description.value,
            hora: moment().format("hh:mm:ss a"),
            fecha: moment().format("DD-MM-YYYY"),
            id_user: this.user.uid,
            id_document: res.id,
          };
          this.afs
            .collection("USERS/" + this.user.uid + "/SUPPORT_CHATS")
            .doc(res.id)
            .update({
              id_document: res.id,
              asunto,
              no_leido_support: 0,
              no_leido: 0,
            })
            .then((e) => {
              let setRecentActivity = {
                date: moment().format(),

                homeTitle: "Nuevo chat inicializado",
                generalTitle:
                  "Inicializaste un nuevo chat, ve a la actividad para más información",
                responses: true,
                description:
                  "Tienes un nuevo chat, ve a soporte para ver tu nuevo chat",
                from: "soporte",
                subtitle: "Nuevo chat inicializado",
                id: this.generateId.createId(),
                archived: false,
                important: false,
                id_document_component: res.id,
                isError: false,
                canUploadData: false,
                view: false,
                uid_user: this.user.uid,
                type: "observation",
                canReply: false,
                isFullview: true,
              };
              this.socket.io.emit("recent-activity", {
                recentActivity: setRecentActivity,
                uid: this.user.uid,
              });
              this.socket.io.emit("support_request", asunto);
            });

          this.affairForm.reset();
        });
    } else {
      alert("Revisa tu conexion a internet");
    }
  }

  chatAccepted() {
    this.socket.io.on("startChatBubble", (acept) => {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
      if (this.timerSubscription !== undefined) {
        this.timerSubscription.unsubscribe();
      }
      this.form1 = false;
      this.form2 = true;
      this.form3 = false;
      this.form4 = false;
      this.form5 = false;
      acept ? this.getChat(this.id_salas) : "";
    });
  }
  getChat(id_document) {
    this.subs_two = this.afs
      .collection("USERS")
      .doc(this.user.uid)
      .collection("SUPPORT_CHATS")
      .doc(id_document)
      .valueChanges()
      .subscribe((data) => {
        this.chat.b_chats = data.chats;
        this.id_document = data.asunto.id_document;
        this.uid_userRoom = data.asunto.id_user;
        this.user_support = data.user_support;
        this.chat.get_id_room(data.asunto.id_document);
      });
  }

  backChat() {
    this.form1 = true;
    this.form4 = false;
    this.form2 = false;
    this.form3 = false;
    this.form5 = false;
    this.affairForm.reset();
  }
  chatFinish() {
    this.socket.io.on("finishSupport", (data) => {
      if (data.asunto.id_document == this.id_salas) {
        this.textFnChat =
          " El chat se ha finalizado, esperamos que su asunto se haya resuelto.";
        this.disableChat = true;
        this.form2 = false;
        this.form5 = true;
      } else {
        this.disableChat = false;
      }
    });
  }
  chatFinishTemporary() {
    this.socket.io.on("finishSupportTemporary", (data) => {
      if (data.asunto.id_document == this.id_salas) {
        this.textFnChat =
          " El chat se ha finalizado temporalmente, le notificaremos cuando se retome el caso.";
        this.disableChat = true;
        this.form2 = false;
        this.form5 = true;
      } else {
        this.disableChat = false;
      }
    });
  }

  sendMessage() {
    if (this.messageForm.value.message == "") {
      this.messageForm.reset();
    } else {
      let sendMessage = {
        activo_bubble: true,
        text: this.messageForm.value.message,
        read: true,
        readSupport: false,
        messageType: 1,
        id_document: this.id_document,
        user: this.uid_userRoom,
        uid_userRoom: this.user_support,
        user_support: this.user_support,
        hora: moment().format("hh:mm:ss a"),
        fecha: moment().format("DD-MM-YYYY"),
      };

      this.chat.message(sendMessage, false);
      this.messageForm.reset();
      let setRecentActivity = {
        date: moment().format(),

        homeTitle: "Tienes un nuevo mensaje de soporte",
        generalTitle:
          "Tienes un nuevo mensaje de soporte, ve a la actividad para más información",
        responses: false,
        description:
          "Tienes un nuevo mensaje de soporte, ve a soporte para ver tu nuevo mensaje",
        from: "soporte",
        subtitle: "Tienes un nuevo mensaje de soporte",
        id: this.generateId.createId(),
        archived: false,
        important: false,
        id_document_component: this.id_document,

        isError: false,
        canUploadData: false,
        view: false,
        uid_user: this.user_support,
        type: "observation",
        canReply: false,
        isFullview: true,
      };
      this.socket.io.emit("recent-activity", {
        recentActivity: setRecentActivity,
        uid: this.user_support,
      });
    }
  }

  private render(count) {
    this.secondsDisplay = this.getSeconds(count);
    this.minutesDisplay = this.getMinutes(count);
  }

  goTo(name: string) {
    if (this.user.role == "FULL-ADMIN" && name == "ingresa") {
      this.router.navigate([
        `operaciones/aprobaciones-ingresa/aprobaciones/solicitudes`,
      ]);
    } else if (this.user.role == "FULL-ADMIN" && name == "registra") {
      this.router.navigate([
        `operaciones/aprobaciones-registra/aprobaciones/solicitudes`,
      ]);
    } else if (this.user.role == "FULL-ADMIN" && name == "gestiona") {
      this.router.navigate([
        `operaciones/aprobaciones-gestiona/aprobaciones/solicitudes`,
      ]);
    } else {
      this.router.navigate([`/${name}`]);
    }
  }

  logout() {
    if (this.user.role == "SUPPORT") this.socket.io.emit("leave-support");
    this.auth.logout().then((e) => {
      this.router.navigate(["/login"]);
    });
  }

  support() {
    this.router.navigate(["/support"]);
  }

  article_listing() {
    this.router.navigate(["/article-listing"]);
  }
  keyAccess() {
    this.router.navigate(["/keyaccess"]);
  }

  goNotification(notification: any) {
    this.gonotification.goNotification(notification);
    //
    // this.notification.lenght_notifications =
    //   this.notification.lenght_notifications - 1;
  }

  checkNotification() {
    this.closeChat();
    this.notification.lenght_notifications = 0;
    this.notification.setNotificationAllOpen();
  }

  disableUser() {
    this.socket.io.on("user-disabled", () => {
      this.router.navigate(["welcome"]);
    });
  }

  profile() {
    this.router.navigate(["my-profile", this.user.uid]);
  }

  noShow() {
    this.Dropoculto = "Dropoculto";
    this.Notioculto = "Notioculto";
  }

  deslizarBloque1() {
    this.bloque1Normal = this.bloque1Normal === "bloque1Normal"
      ? "bloque1Ocultar"
      : "bloque1Normal";
    this.bloque2Normal = this.bloque2Normal === "bloque2Normal"
      ? "bloque2Mostrar"
      : "bloque2Normal";
  }

  deslizarBloque2() {
    this.bloque2Normal = this.bloque2Normal === "bloque2Mostrar"
      ? "bloque2Normal"
      : "bloque2Mostrar";
    this.bloque1Normal = this.bloque1Normal === "bloque1Ocultar"
      ? "bloque1Normal"
      : "bloque1Ocultar";
  }

  onClick2() {
    this.Notioculto = this.Notioculto === "Notioculto"
      ? "Notimostrado"
      : "Notioculto";
    this.Dropoculto = this.Dropoculto === "Dropoculto"
      ? "Dropoculto"
      : "Dropoculto";
  }
  sidebarToogle() {
    let sidebar = document.getElementById("sidebar");
    sidebar.classList.toggle("collapsed");
  }

  ngOnDestroy() {
    if (this.subs_one !== undefined) this.subs_one.unsubscribe();
    if (this.subs_two !== undefined) this.subs_two.unsubscribe();
  }
}
