import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CanEnterGuard } from "src/app/SERVICIOS/can/can-enter.guard";
import { AuthGuard } from "src/app/SERVICIOS/guards/auth.guard";
import { CommunityManageGuard } from "src/app/SERVICIOS/guards/community-manage.guard";
import { DisabledGuard } from "src/app/SERVICIOS/guards/disabled.guard";
import { FullAdminGuard } from "src/app/SERVICIOS/guards/full-admin.guard";
import { SupportGuard } from "src/app/SERVICIOS/guards/support.guard";
import { MarktingComponent } from "./markting.component";
import { LeadAndSupportComponent } from "./lead-and-support/lead-and-support.component";
import { RegistraClienteComponent } from "../REGISTRA/registra-cliente/registra-cliente.component";
import { LeadDataComponent } from "./lead-data/lead-data.component";
import { FiltersComponent } from "./filters/filters.component";
import { AgentSelectionComponent } from "./agent-selection/agent-selection.component";
import { ResumeComponent } from "./resume/resume.component";
import { ResultsComponent } from "./results/results.component";

const routes: Routes = [
  {
    path: "markting",
    component: MarktingComponent,

    children: [
      { path: "", component: LeadAndSupportComponent },
      {
        path: "lead-and-support",
        component: LeadAndSupportComponent,
      },
      {
        path: "lead-data/:isSale",
        component: LeadDataComponent,
      },
      {
        path: ":id/filters",
        component: FiltersComponent,
      },
      {
        path: ":id/results",
        component: ResultsComponent,
      },
      {
        path: ":id/agent-selection",
        component: AgentSelectionComponent,
      },
      {
        path: ":idLead/:idAgent/resume",
        component: ResumeComponent,
      },
    ],
    canActivate: [
      AuthGuard,
      CanEnterGuard,
      DisabledGuard,
      FullAdminGuard,
      SupportGuard,
      CommunityManageGuard,
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MarktingRoutingModule {}
