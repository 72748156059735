<div class="main">
  <div class=" d-flex justify-content-between">
    <h2 class="title mt-4">Clientes</h2>
    <div>
      <div class="card-new-customer" routerLink="/ingresa" *ngIf="!auth.userFullAdmin(user)">
        Agregar nuevo
        cliente

      </div>
    </div>
  </div>
  <div class="subtitle d-flex justify-content-between subtitle mb-3 mt-3" *ngIf="auth.userFullAdmin(user) == false">
    <h5>Mis clientes</h5>
    <div></div>
    <button (click)="showFilteru()" class="ui button" style="padding: 0.385714em 1.5em 0.38571429em;">
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-filter"
        viewBox="0 0 16 16">
        <path
          d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
      </svg>Filtrar</button>
    <!---->
  </div>
  <div>
    <div class="card  shadow-lg " style="width: 35%;position: absolute; right: 50px;z-index: 1000;"
      *ngIf="showFilteruCard && auth.userFullAdmin(user) == false">
      <div style="margin: 10px;">
        <h5 class="title mt-4">Filtros</h5>
        <div class="box">

          <ul class="ks-cboxtags">
            <li>
              <input id="1" type="radio" [checked]="valueNameu == 'nombre'" (change)="filterWithSelectu(3)"
                value="nombre" />
              <label for="1">
                Nombre del cliente
              </label>
            </li>
            <li>
              <input id="2" type="radio" [checked]="valueNameu == 'id_cliente'" (change)="filterWithSelectu(2)"
                value="id_cliente" />
              <label for="2">
                ID del cliente
              </label>
            </li>
            <li>
              <input id="3" type="radio" [checked]="valueNameu == 'id_article'" (change)="filterWithSelectu(5)"
                value="id_article" />
              <label for="3">
                ID de artículo
              </label>
            </li>
            <li>
              <input id="4" type="radio" [checked]="valueNameu == 'uid_user'" (change)="filterWithSelectu(4)"
                value="uid_user" />
              <label for="4">
                ID del usuario
              </label>
            </li>

          </ul>

          <div class="form-outline">
            <input [disabled]="disabledInputu" (input)="getTextu($event.target.value)" class="form-control"
              placeholder="ingrese el valor" name="filterPostu" [(ngModel)]="filterPostu" [value]="text_inputu" />
          </div>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="!auth.userFullAdmin(user)">
    <div *ngIf="( my_customer | income: filterPostu:valueNameu) as myincomes" class="grid-incomes mt-3">
      <div
        *ngFor="let todo of myincomes| paginate: { itemsPerPage: 6, currentPage: m_p,  totalItems:myincomes.length  }; let i = index"
        class="card card-income " [ngClass]="{ red_observation:todo.observation }">
        <div *ngIf="todo.estatus != 'incompleto'" (click)='fullView(todo)'>
          <h3 class="fw-bold">{{ todo.cliente.nombre | titlecase }} {{ todo.cliente.apellido | titlecase }}</h3>
          <p>{{ todo.id }}</p>
          <p class="fw-bold" style="font-size: 1.3rem;"
            [ngClass]="{'green ':todo.estatus == 'habilitado','red':todo.estatus == 'deshabilitado' }">{{ todo.estatus
            |
            titlecase }}</p>

          <div class="d-flex flex-row justify-content-around mt-4">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <th class="text-center fw-bold">Artículos</th>
              <p>{{getTotalArticles(todo)}}</p>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <th class="text-center fw-bold">Productos aplicados</th>
              <p>{{getProductsArticles(todo)}}</p>
            </div>
          </div>
        </div>
        <div *ngIf="todo.estatus == 'incompleto'" (click)="completeIngresa(todo)">
          <h3 class="fw-bold">{{ todo.cliente.nombre | titlecase }} {{ todo.cliente.apellido | titlecase }}</h3>
          <p>{{ todo.id }}</p>
          <p class="fw-bold" style="font-size: 1.3rem;">
            No completado
          </p>
          <div class="d-flex flex-row justify-content-around mt-4">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <th class="text-center fw-bold">Artículos</th>
              <p>{{getTotalArticles(todo)}}</p>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <th class="text-center fw-bold">Productos aplicados</th>
              <p>{{getProductsArticles(todo)}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center mt-4 mb-5" *ngIf="my_customer !== undefined && my_customer.length >= 6">
    <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" (pageChange)="changePageu($event)"
      class="float-right"></pagination-controls>
  </div>

  <div *ngIf="my_customer.length == 0 && auth.userFullAdmin(user) == false">
    <p class="text-center mt-2">No hay datos por el momento</p>
  </div>




  <div class="subtitle mb-3 mt-3" *ngIf="auth.canAdmin(user)">
    <div class="d-flex justify-content-between">
      <h5>Clientes de otros usuarios</h5>
      <button (click)="showFilter()" class="ui button" style="padding: 0.385714em 1.5em 0.38571429em;"><svg
          xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-filter"
          viewBox="0 0 16 16">
          <path
            d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
        </svg>Filtrar</button>
    </div>
  </div>
  <div class="card" style="width: 35%;position: absolute; right: 50px;z-index: 1000;"
    *ngIf="showFilterCard && auth.canAdmin(user)">
    <div style="margin: 10px;">
      <h5 class="title mt-4">Filtros</h5>
      <div class="box">

        <ul class="ks-cboxtags">
          <li>
            <input id="1" type="radio" [checked]="valueName == 'nombre'" (change)="filterWithSelect(3)"
              value="nombre" />
            <label for="1">
              Nombre del cliente
            </label>
          </li>
          <li>
            <input id="2" type="radio" [checked]="valueName == 'id_cliente'" (change)="filterWithSelect(2)"
              value="id_cliente" />
            <label for="2">
              ID del cliente
            </label>
          </li>
          <li>
            <input id="3" type="radio" [checked]="valueName == 'id_article'" (change)="filterWithSelect(5)"
              value="id_article" />
            <label for="3">
              ID de artículo
            </label>
          </li>
          <li>
            <input id="4" type="radio" [checked]="valueName == 'uid_user'" (change)="filterWithSelect(4)"
              value="uid_user" />
            <label for="4">
              ID del usuario
            </label>
          </li>

        </ul>

        <div class="form-outline">
          <input [disabled]="disabledInput" (input)="getText($event.target.value)" class="form-control"
            placeholder="ingrese el valor" name="filterPost" [(ngModel)]="filterPost" [value]="text_input" />
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="auth.canAdmin(user)">
    <div *ngIf="( customer | income: filterPost:valueName) as incomes" class="grid-incomes mt-5">
      <div *ngFor="let todo of incomes | paginate: { itemsPerPage: 6, currentPage: p,  totalItems: incomes.length }"
        class="card card-income ">
        <div>
          <h3 class="fw-bold">{{ todo.cliente.nombre | titlecase }} {{ todo.cliente.apellido | titlecase }}</h3>
          <p>{{ todo.id_cliente }}</p>
          <div *ngIf="todo.estatus == 'incompleto'">
            <p class="fw-bold" style="font-size: 1.3rem;">Cliente sin completar</p>
          </div>
          <div *ngIf="todo.estatus != 'incompleto'">
            <p class="fw-bold" style="font-size: 1.3rem;"
              [ngClass]="{'green ':todo.estatus == 'habilitado','red':todo.estatus == 'deshabilitado' }">{{ todo.estatus
              |
              titlecase }}</p>
          </div>

          <div class="d-flex flex-row justify-content-around mt-4">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <th class="text-center fw-bold">Artículos</th>
              <p>{{getTotalArticles(todo)}}</p>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <th class="text-center fw-bold">Productos aplicados</th>
              <p>{{getProductsArticles(todo)}}</p>
            </div>
          </div>
          <div *ngIf="todo.estatus != 'incompleto'" class="mt-4 d-flex flex-row justify-content-between">
            <div *ngIf="auth.canAdmin(user)" class="">
              <div *ngIf="todo.aprobado && todo.estatus == 'habilitado' && auth.userFullAdmin(user)">
                <button class="ui  button" (click)="disable(todo)">
                  Deshabilitar
                </button>
              </div>
              <div *ngIf="todo.aprobado && todo.estatus == 'deshabilitado' && auth.userFullAdmin(user)">
                <button class="ui  button" (click)="enable(todo)">
                  Habilitar
                </button>
              </div>
            </div>
            <div class="ui animated button " tabindex="0" (click)='fullView(todo)'>
              <div class="visible content">Ver</div>
              <div class="hidden content">
                <i class="right arrow icon"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>




  <div class="text-center mt-4 mb-5" *ngIf="customer.length > 5">
    <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" (pageChange)="changePage($event)"
      class="float-right"></pagination-controls>
  </div>
  <div class="text-center mt-4 mb-5" *ngIf="(auth.userAdmin(user)|| auth.userFullAdmin(user)) && customer.length == 0">
    <p>No hay clientes por el momento</p>
  </div>
</div>





<!-- MODALES -->
<div class="ui basic modal disable" style="width: 90%; margin-left: 5.15%; margin-top: 25vh;">
  <div class="ui icon header text-center">
    <i class="exclamation icon" *ngIf="modal1"></i>
    <p *ngIf="modal1">Deshabilitar Ingreso</p>
    <p *ngIf="modal2">¿Por qué deshabilitas el ingreso?</p>
  </div>
  <div class="content text-center">
    <p *ngIf="modal1">¿Estás seguro de deshabilitar este ingreso?</p>
    <form [formGroup]="argumentForm" (ngSubmit)="onArg()" *ngIf="modal2">
      <div class="row mb">
        <textarea *ngIf="modal2" formControlName="argument" [ngClass]="{invalid:argumento.invalid && argumento.dirty}"
          class="form-control" name="argument" rows="4"></textarea>
      </div>
    </form>
  </div>
  <div class="actions" style="display: flex; justify-content: center;" *ngIf="modal1">
    <div class="ui red basic cancel inverted button" (click)="hideModalDisableIcome()">
      <i class="remove icon"></i>
      No
    </div>
    <div class="ui green ok inverted button" (click)="showModalArgument()">
      <i class="checkmark icon"></i>
      Sí
    </div>
  </div>
  <div class="actions" style="display: flex; justify-content: center;" *ngIf="modal2">
    <div>

    </div>
    <div class="ui green ok inverted button" (click)='onArg()'>
      Enviar
    </div>
  </div>
</div>