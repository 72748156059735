import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Subject, Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { User } from 'src/app/COMERCIAL/MODULOS/user.interface';
import { AuthService } from 'src/app/SERVICIOS/auth.service';
import { DataDbService } from 'src/app/SERVICIOS/data-db.service';
import { GenerateIdService } from 'src/app/SERVICIOS/generate-id.service';
import { BubbleChatService } from 'src/app/SERVICIOS/socket/bubble-chat.service';
import { ChatService } from 'src/app/SERVICIOS/socket/chat.service';
import { NotificationsService } from 'src/app/SERVICIOS/socket/notifications.service';
import { SocketService } from 'src/app/SERVICIOS/socket/socket.service';
import { UserOnlineService } from 'src/app/SERVICIOS/user-online.service';

@Component({
  selector: 'app-chat-bubble',
  templateUrl: './chat-bubble.component.html',
  styleUrls: ['./chat-bubble.component.scss']
})
export class ChatBubbleComponent implements OnInit, OnDestroy {
  public messageForm: UntypedFormGroup;
  public affairForm: UntypedFormGroup;
  showImg1 = true;
  showImg2 = false;
  showCard = false;
  form1 = true;
  form2 = false;
  form3 = false;
  form4 = false;
  form5 = false;
  text_asunto: string;
  text_description: string;
  user: User;
  id_salas: string;
  salas: any;
  message_chat_main: string;
  canCreateRoom: boolean;
  user_uid: string;
  roomToUser: any;
  chat_disabled: any;
  selected_room: boolean;
  messageToUser: any;
  message_chat_desabled: string;
  data_chats: any;
  text_message: any;
  disableChat: boolean = false;
  uid_user: string;
  minutesDisplay = 0;
  secondsDisplay = 0;
  endTime = 1;
  hideIcon = false

  unsubscribe$: Subject<void> = new Subject();
  timerSubscription: Subscription;

  textFnChat: string
  id_document: any;
  uid_userRoom: any;
  user_support: any;
  chatActive: any;
  subs_one: Subscription;
  subs_two: Subscription;
  msgNoReads: number = 0;


  constructor(public auth: AuthService, private userOnline: UserOnlineService, private generateId: GenerateIdService, public chat: BubbleChatService,
    private socket: SocketService, public notification: NotificationsService, private afs: AngularFirestore) {
    this.chatAccepted()
    this.chatFinish()
    this.chatFinishTemporary()

  }
  ngOnInit(): void {
    this.auth.getCurrentUser().then((value) => {
      if (value) this.user_uid = value.uid;
      this.searchChatActive()
    })
    this.messageForm = this.createForm();
    this.affairForm = this.createAffairForm();
  }

  createForm() {
    return new UntypedFormGroup({
      message: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
    });
  }
  createAffairForm() {
    return new UntypedFormGroup({
      affair: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
      description: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
    });
  }
  get message() { return this.messageForm.get('message') }
  get affair() { return this.affairForm.get('affair') }
  get description() { return this.affairForm.get('description') }

  searchChatActive() {
    this.subs_one = this.afs.collection("USERS/" + this.user_uid + "/SUPPORT_CHATS", ref => ref.where('activo_bubble', '==', true)).valueChanges().subscribe((data: any) => {

      this.chatActive = data[0]
      if (this.chatActive !== undefined) {
        if (this.chatActive.no_leido !== undefined) this.msgNoReads = this.chatActive.no_leido
        let id_document = this.chatActive.id_document
        this.getChat(id_document);
        this.form2 = true;
        this.form1 = false
        this.id_salas = id_document
      }
    })
  }




  createChatSupport() {
    this.showCard = true;
    if (this.form1) {
      this.form1 = true
      this.form2 = false
      this.form3 = false
      this.form4 = false
      this.form5 = false
    } else if (this.form2) {
      this.chat.inChat(true)
      this.msgReads()
      this.form2 = true
      this.form1 = false
      this.form3 = false
      this.form4 = false
      this.form5 = false
    } else if (this.form3) {
      this.form3 = true
      this.form2 = false
      this.form1 = false
      this.form4 = false
      this.form5 = false
    } else if (this.form4) {
      this.form4 = true
      this.form2 = false
      this.form3 = false
      this.form1 = false
      this.form5 = false
    } else if (this.form5) {
      this.form5 = true
      this.form2 = false
      this.form3 = false
      this.form4 = false
      this.form1 = false
    }


  }
  hideCard() {
    this.showCard = false;
    this.chat.inChat(false)
  }

  msgReads() {
    this.afs.doc('USERS/' + this.chatActive.asunto.id_user + '/SUPPORT_CHATS/' + this.chatActive.id_document).update({ no_leido: 0 })

  }

  waiting() {
    this.createRoom()
    this.form1 = false;
    this.form3 = true
    this.resetTimer();
  }

  interval() {
    this.resetTimer();
  }
  resetTimer(endTime: number = this.endTime) {
    const interval = 1000;
    const duration = endTime * 900;
    this.timerSubscription = timer(0, interval).pipe(
      take(duration)
    ).subscribe(value =>
      this.render((duration - +value) * interval),
      err => { },
      () => {
        this.form3 = false;
        this.form4 = true
        this.temporaChat()

        this.unsubscribe$.next();
        this.unsubscribe$.complete();
      }
    )
  }
  temporaChat() {
    this.afs.collection("USERS/" + this.user_uid + "/SUPPORT_CHATS").doc(this.id_salas).update({
      temporal: true
    })
  }

  private render(count) {
    this.secondsDisplay = this.getSeconds(count);
    this.minutesDisplay = this.getMinutes(count);
  }

  private getSeconds(ticks: number) {
    const seconds = ((ticks % 60000) / 1000).toFixed(0);
    return this.pad(seconds);
  }

  private getMinutes(ticks: number) {
    const minutes = Math.floor(ticks / 60000);
    return this.pad(minutes);
  }

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }



  createRoom() {
    if (this.userOnline.userOnline()) {
      let id_room = this.generateId.createId()
      let chats = []

      this.afs.collection("USERS/" + this.user_uid + "/SUPPORT_CHATS").add({
        chats, resuelto: false, tomado: false, temporal: false, id: id_room
      }).then(res => {
        this.id_salas = res.id

        let asunto = {
          asunt: this.affair.value,
          description: this.description.value,
          hora: moment().format('hh:mm:ss a'),
          fecha: moment().format('DD-MM-YYYY'),
          id_user: this.user_uid,
          id_document: res.id,
        };
        this.afs.collection("USERS/" + this.user_uid + "/SUPPORT_CHATS").doc(res.id).update({ id_document: res.id, asunto, no_leido_support: 0, no_leido: 0 }).then(e => {
          this.socket.io.emit('support_request', asunto)
        })

        this.affairForm.reset()
      })
    } else {
      alert('Revisa tu conexion a internet')
    }
  }

  chatAccepted() {
    this.socket.io.on("startChatBubble", (acept) => {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
      if (this.timerSubscription !== undefined) this.timerSubscription.unsubscribe()
      this.form1 = false
      this.form2 = true
      this.form3 = false
      this.form4 = false
      this.form5 = false
      acept ? this.getChat(this.id_salas) : ''
    })
  }
  getChat(id_document) {
    this.subs_two = this.afs.collection("USERS").doc(this.user_uid).collection("SUPPORT_CHATS").doc(id_document).valueChanges()
      .subscribe(data => {
        this.chat.b_chats = data.chats;
        this.id_document = data.asunto.id_document
        this.uid_userRoom = data.asunto.id_user
        this.user_support = data.user_support
        this.chat.get_id_room(data.asunto.id_document);
      })
  }

  backChat() {
    this.form1 = true
    this.form4 = false
    this.form2 = false
    this.form3 = false
    this.form5 = false;
    this.affairForm.reset()
  }
  chatFinish() {
    this.socket.io.on("finishSupport", (data) => {
      if (data.asunto.id_document == this.id_salas) {
        this.textFnChat = " El chat se ha finalizado, esperamos que su asunto se haya resuelto."
        this.disableChat = true
        this.form2 = false;
        this.form5 = true;
      } else {
        this.disableChat = false
      }
    })
  }
  chatFinishTemporary() {
    this.socket.io.on("finishSupportTemporary", (data) => {
      if (data.asunto.id_document == this.id_salas) {
        this.textFnChat = " El chat se ha finalizado temporalmente, le notificaremos cuando se retome el caso."
        this.disableChat = true
        this.form2 = false;
        this.form5 = true;
      } else {
        this.disableChat = false
      }
    })
  }

  sendMessage() {

    if (this.messageForm.value.message == '') {
      this.messageForm.reset()

    } else {
      let sendMessage = {
        text: this.messageForm.value.message,
        read: true,
        readSupport: false,
        messageType: 1,
        id_document: this.id_document,
        user: this.uid_userRoom,
        uid_userRoom: this.user_support,
        user_support: this.user_support,
        hora: moment().format('hh:mm:ss a'),
        fecha: moment().format('DD-MM-YYYY'),
      };
      this.chat.message(sendMessage, false)
      this.messageForm.reset()

    }
  }






  ngOnDestroy() {
    if (this.subs_one !== undefined) this.subs_one.unsubscribe()
    if (this.subs_two !== undefined) this.subs_two.unsubscribe()

  }




}
