import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GetDataService {

  constructor(private afs: AngularFirestore) { }

  async getCustomers(uid: string) {
    let customers = await this.afs.collection('CUSTOMER', ref => ref.where('uid_user', '==', uid)).valueChanges().pipe(take(1)).toPromise().then(results => {
      return results
    })
    return customers

  }
  async getArticles(uid: string) {
    let articles = await this.afs.collection('ARTICLE', ref => ref.where('uid_user', '==', uid)).valueChanges().pipe(take(1)).toPromise().then(results => {
      return results
    })
    return articles

  }
}


