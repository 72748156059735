<div *ngIf="showMessage" class="ui success  message" style="    z-index: 1000;
position: fixed;
bottom: 0;">

    <i class="close icon" (click)="closeMessage()"></i>
    <div class="header">
        {{titleMessage}} &nbsp; &nbsp;</div>
    <p *ngIf="bodyMessage != ''"> {{bodyMessage}}
    </p>
</div>
<div class="main" *ngIf="article && user && agent && customer">
    <div class="ui primary button" (click)="event()">
        event
    </div>
    <div class="fx-title mb-4 mt-3">
        <i class="large arrow left icon" (click)="back()" style="cursor:pointer;"></i>
        <h2 class="title" style="margin: 0;">Información avanzada del artículo</h2>
        <div></div>
    </div>

    <div class="mb-4"
        *ngIf="article.commodity.observation != 'no hay comentarios' && article.commodity.observation != 'sin observaciones'">
        <h5> Observaciones:</h5>
        <div *ngIf="article.commodity.observation != 'no hay observaciones'" class="ui tertiary segment">

            <p>
                {{article.commodity.observation}}
            </p>
        </div>
        <div *ngIf="article.commodity.observation == 'no hay observaciones'">
            <b>No hay observaciones</b>
        </div>
    </div>
    <div class="fx">
        <div *ngIf="assignedProducts.length > 0" class="ui primary button">
            <p type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@getbootstrap">
                Ver todos los productos asignados
            </p>
        </div>

        <button *ngIf="assignedProducts.length == 0" disabled class="ui button">
            Ver todos los productos asignados
        </button>
        <div class="ui primary button" (click)="getAllComments()">
            <p type="button" data-bs-toggle="modal" data-bs-target="#modalComments" data-bs-whatever="@getbootstrap">
                Ver Comentarios
            </p>
        </div>

    </div>
    <div class="subtitle mb-3 fx">
        <h3 style="font-weight: bold;">Información del artículo</h3>
        <div style="    display: flex;
        justify-content: flex-end;
    ">
            <div *ngIf="myArticle"><button class="ui button" (click)="editArticle(article)">Actualizar artículo</button>
            </div>
            <button class="ui button" (click)="fullview(article)">Ver artículo</button>
        </div>
    </div>

    <div class="shadow-lg p-3 mb-5card">

        <div class="mb-3 mt-4">
            <div class="row mb">
                <div class="col">
                    <div class="form-outline">
                        <p class="lead" style="font-weight: bold;">Nombre</p>
                    </div>
                    <div class="form-outline">
                        <p>{{customer.cliente.nombre | titlecase}}</p>
                    </div>
                </div>
                <div class="col">
                    <div class="form-outline">
                        <p class="lead" style="font-weight: bold;">Apellidos</p>
                    </div>
                    <div class="form-outline">
                        <p>{{customer.cliente.apellido | titlecase}}</p>
                    </div>
                </div>
                <div class="col">
                    <div class="form-outline">
                        <p class="lead" style="font-weight: bold;">ID</p>
                    </div>
                    <div class="form-outline">
                        <p>{{customer.id}}</p>
                    </div>
                </div>
                <div class="col">
                    <div class="form-outline">
                        <p class="lead" style="font-weight: bold;">Agente Asignado</p>
                    </div>
                    <div class="form-outline">
                        <p>{{agent.displayName | titlecase}}</p>
                    </div>
                </div>
            </div>
        </div>




        <!-- <div class="userMain">
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Nombre</th>
                        <th scope="col">Apellidos</th>
                        <th scope="col">ID</th>
                        <th scope="col">Agente Asignado</th>

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{customer.cliente.nombre}}</td>
                        <td>{{customer.cliente.apellido}}</td>
                        <td>{{customer.id}}</td>
                        <td>{{agent.displayName}}</td>
                    </tr>
                </tbody>
            </table>
        </div> -->
    </div>
    <div class="subtitle mb-3 fx">
        <h3 style="font-weight: bold;">Información del producto aplicado</h3>
        <div style="    display: flex;
        justify-content: flex-end;
    "> <button *ngIf="article.commodity.producto !='no asignado'" class="ui button" (click)="viewPDF()">Descargar
                PDF</button>
            <div *ngIf="myArticle && article.commodity.start && article.commodity.estatus=='en espera'">
                <button class="ui primary button" (click)="updateDateStart()">
                    Actualizar fecha de inicio </button>
            </div>
        </div>
    </div>

    <div *ngIf="article.commodity.producto == 'no asignado' && !myArticle" class="mt-4" style="text-align: center;">
        <p>Producto no agregado</p>
    </div>

    <div *ngIf="article.commodity.producto != 'no asignado'" class="table-responsive">
        <div class="shadow-lg p-3 mb-5card">

            <div class="mb-3 mt-4">
                <div class="row mb">
                    <div class="col">
                        <div class="form-outline">
                            <p class="lead" style="font-weight: bold;">Producto</p>
                        </div>
                        <div class="form-outline">
                            <div *ngIf="article.commodity.producto != 'no asignado'">
                                <div>
                                    {{ article.commodity.producto | titlecase }}
                                    <span *ngIf="!showDropDown && article.estatus == 'en espera'"
                                        style="cursor: pointer;" class="ui button "
                                        (click)="changeCommodity(article.commodity.producto)">Cambiar</span>
                                </div>
                                <div *ngIf="showDropDown">
                                    <div style="display: flex; align-items: center; margin-bottom: 10px;">

                                        <select class="form-select" [(ngModel)]="productSelected"
                                            (change)="selectProduct($event.target.value)">
                                            <option selected>Selecciona una opción</option>
                                            <option value="gold99" selected>Gold99</option>
                                            <option value="diamond99">Diamond99</option>
                                            <option value="elite99">Elite99</option>
                                            <option value="imperial99">Imperial99</option>
                                        </select>
                                        <span style="cursor: pointer;" class="ui primary button"
                                            (click)="updateProduct()">Actualizar</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-outline">
                            <p class="lead" style="font-weight: bold;">Beneficios</p>
                        </div>
                        <div class="form-outline">
                            <div *ngIf="article.commodity.producto == 'no asignado'">
                                <div>
                                    {{ article.commodity.producto | titlecase }}
                                    <span *ngIf="!showDropDown" style="cursor: pointer;" class="ui button "
                                        (click)="changeCommodity(article.commodity.producto)">Cambiar</span>
                                </div>
                                <div *ngIf="showDropDown">
                                    <div style="display: flex; align-items: center; margin-bottom: 10px;">

                                        <select class="form-select" [(ngModel)]="productSelected"
                                            (change)="selectProduct($event.target.value)">
                                            <option selected>Selecciona una opción</option>
                                            <option value="gold99" selected>Gold99</option>
                                            <option value="diamond99">Diamond99</option>
                                            <option value="elite99">Elite99</option>
                                            <option value="imperial99">Imperial99</option>
                                        </select>
                                        <span style="cursor: pointer;" class="ui primary button"
                                            (click)="updateProduct()">Actualizar</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="article.commodity.producto == 'no asignado' && myArticle ">
                                <button class="ui primary button" (click)="addProduct()">
                                    Agregar un producto
                                </button>
                            </div>
                            <div *ngIf="article.commodity.producto == 'no asignado'">
                                <p>No asignado</p>
                            </div>
                            <div *ngIf="article.commodity.producto != 'no asignado'">
                                <span style="cursor: pointer;" class="ui button"
                                    (click)="seeBenefits(article.commodity.producto)">Ver
                                    Beneficios</span>
                            </div>


                        </div>
                    </div>
                    <div class="col">
                        <div class="form-outline">
                            <p class="lead" style="font-weight: bold;">Estatus</p>
                        </div>
                        <div class="form-outline">
                            <p>{{ article.commodity.estatus | titlecase }}</p>
                            <div *ngIf="article.commodity.producto == 'no asignado'">
                                No disponible
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-outline">
                            <p class="lead" style="font-weight: bold;">Fecha de asignación</p>
                        </div>
                        <div class="form-outline">
                            <p *ngIf="article.commodity.producto != 'no asignado'">{{ article.commodity.fecha}}</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <div class="form-outline">
                            <p class="lead" style="font-weight: bold;">Fecha de inicio</p>
                        </div>
                        <div class="form-outline">
                            <p *ngIf="article.commodity.producto == 'no asignado'">
                                No disponible
                            </p>
                            <p *ngIf="article.commodity.producto != 'no asignado' && article.commodity.start">
                                {{article.commodity.start | date:'dd-MM-yyyy'}}</p>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-outline">
                            <p class="lead" style="font-weight: bold;">Fecha de finalización</p>
                        </div>
                        <div class="form-outline">
                            <p *ngIf="article.commodity.producto == 'no asignado'">
                                No disponible
                            </p>
                            <p *ngIf="article.commodity.producto != 'no asignado' && article.commodity.start">
                                <!-- {{due_date}} -->
                                {{article.commodity.finish | date:'dd-MM-yyyy'}}
                            </p>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-outline">
                            <p class="lead" style="font-weight: bold;">Fecha de renovación</p>
                        </div>
                        <div class="form-outline">
                            <p *ngIf="article.commodity.producto == 'no asignado'">
                                No disponible
                            </p>

                            <p *ngIf="article.commodity.producto != 'no asignado'">{{ dateRenovation }}</p>

                        </div>
                    </div>
                    <div class="col">
                        <div class="form-outline">
                            <p class="lead" style="font-weight: bold;">Días restantes</p>

                        </div>
                        <div class="form-outline">
                            <p *ngIf="article.commodity.producto == 'no asignado'">
                                No disponible
                            </p>
                            <p *ngIf="article.commodity.producto != 'no asignado'">
                                {{diff}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>




    </div>

    <div *ngIf="myArticle && !article.commodity.requestCancellation && article.commodity.producto != 'no asignado' && article.commodity.estatus != 'cancelado' && article.commodity.estatus != 'en espera' "
        style="text-align: center" class="mt-4 mb-4">
        <button class="ui red button" (click)="requestProductCancellation()">
            Solicitar cancelación
        </button>
    </div>
    <div *ngIf="myArticle && !article.commodity.requestCancellation && article.commodity.estatus == 'cancelado'"
        style="text-align: center" class="mt-4 mb-4">
        <button class="ui primary button" (click)="addProduct()">
            Agregar un producto
        </button>
    </div>
    <div *ngIf="article.commodity.requestCancellation && myArticle" style="text-align: center" class="mt-4 mb-4">
        <p>Cancelación pendiente</p>
    </div>
    <div *ngIf="article.commodity.requestCancellation && !myArticle && dataCancellation.observation != null "
        style="text-align: center" class="mt-4 mb-4">
        <p>{{dataCancellation.observation}}</p>
    </div>
    <div>
        <div *ngIf="!myArticle && article.commodity.requestCancellation && auth.userFullAdmin(user)"
            style="text-align: center" class="mt-4 mb-4">
            <button class="ui primary button" (click)="approveCancellation()">
                Aprobar cancelacion
            </button>
        </div>
        <div *ngIf="!myArticle && article.commodity.requestCancellation && auth.userFullAdmin(user)"
            style="text-align: center" class="mt-4 mb-4">
            <button class="ui red button" (click)="rejectCancellation()">
                No aprobar cancelación
            </button>
        </div>
    </div>


    <div *ngIf="!myArticle && auth.userFullAdmin(user)">
        <div *ngIf="customer.estatus !=='deshabilitado'">
            <div style="display: flex" *ngIf="article.estatus == 'en espera' || article.estatus == 'denegado' ">
                <button class="ui green button" (click)="approve(article)">Aprobar</button>
                <button class="ui red button" [disabled]="article.estatus == 'denegado'"
                    (click)="disapprove(article)">Denegar</button>
            </div>
        </div>
        <div style="display: flex">
            <div
                *ngIf="auth.isFullAdmin(user) && article.commodity.estatus == 'aprobado' && !article.commodity.requestCancellation">
                <button class="ui red button" (click)="cancellProduct(article)">Cancelar Producto</button>
            </div>
            <div
                *ngIf="auth.isFullAdmin(user) && article.commodity.estatus == 'en espera' && !article.commodity.requestCancellation">
                <button class="ui red button" (click)="cleanProduct(article)">Limpiar Producto</button>
            </div>
            <div *ngIf="auth.isFullAdmin(user) && article.commodity.estatus == 'en espera' ">
                <button class="ui green button" (click)="approveProduct(article)">Aprobar Producto</button>
            </div>
        </div>
        <!-- <div *ngIf="article.estatus == 'inhabilitado'">
            <button class="ui green button" (click)="enable(article)">Habilitar</button>
        </div> -->
    </div>


    <div style="display: flex; justify-content:end;" *ngIf="auth.isFullAdmin(user)" class="mt-5">
        <div class="ui primary button" (click)="createAnObservation()">Hacer una observación</div>
    </div>








</div>





<div class="ui basic modal one" style="width: 100%; margin: 25vh auto; overflow: hidden;">
    <div class="ui icon header text-center" *ngIf="!rejected_article">
        <i class="exclamation icon"></i>
        Rechazar articulo
    </div>
    <div class="content text-center" *ngIf="!rejected_article">
        <p>¿Estás seguro de rechazar el articulo?</p>
    </div>
    <div class="actions" style="display: flex; justify-content: center;" *ngIf="!rejected_article">
        <div class="ui red basic cancel inverted button" (click)="hideModalNegative()">
            <i class="remove icon"></i>
            No
        </div>
        <div class="ui green ok inverted button" (click)="showModalArgument()">
            <i class="checkmark icon"></i>
            Sí
        </div>
    </div>

    <div class="ui icon header text-center" *ngIf="rejected_article">
        <i class="exclamation icon"></i>
        Escribe la observación
    </div>
    <div class="content text-center" *ngIf="rejected_article">
        <form [formGroup]="argumentForm" (ngSubmit)=" disapproveArticle()">
            <div class="row mb">
                <textarea formControlName="argument" [ngClass]="{invalid:argumento.invalid && argumento.dirty}"
                    class="form-control" name="argument" rows="4"></textarea>
            </div>
        </form>
    </div>
    <div class="actions" style="display: flex; justify-content: center;" *ngIf="rejected_article">
        <div class="ui red basic cancel inverted button" (click)="hideModalNegative()">
            <i class="remove icon"></i>
            Cancelar
        </div>
        <div class="ui green ok inverted button" (click)=" disapproveArticle()">
            <i class="checkmark icon"></i>
            Finalizar
        </div>
    </div>

</div>

<div class="ui basic modal two" style="width: 100%; margin: 25vh auto; overflow: hidden;">
    <div class="ui icon header text-center" *ngIf="!disabled_article">
        <i class="exclamation icon"></i>
        Cancelar producto
    </div>
    <div class="content text-center" *ngIf="!disabled_article">
        <p>¿Estás seguro de realizar ésta acción?</p>
    </div>
    <div class="actions" style="display: flex; justify-content: center;" *ngIf="!disabled_article">
        <div class="ui red basic cancel inverted button" (click)="hideModalNegative()">
            <i class="remove icon"></i>
            No
        </div>
        <div class="ui green ok inverted button" (click)="showArgument()">
            <i class="checkmark icon"></i>
            Sí
        </div>
    </div>



    <div class="ui icon header text-center" *ngIf="disabled_article">
        <i class="exclamation icon"></i>
        Escribe la observación
    </div>
    <div class="content text-center" *ngIf="disabled_article">
        <form [formGroup]="argumentForm" (ngSubmit)="setCancellProduct()">
            <div class="row mb">
                <textarea formControlName="argument" [ngClass]="{invalid:argumento.invalid && argumento.dirty}"
                    class="form-control" name="argument" rows="4"></textarea>
            </div>
        </form>
    </div>
    <div class="actions" style="display: flex; justify-content: center;" *ngIf="disabled_article">
        <div class="ui red basic cancel inverted button" (click)="hideModalNegative()">
            <i class="remove icon"></i>
            regresar
        </div>
        <div class="ui green ok inverted button" (click)="setCancellProduct()">
            <i class="checkmark icon"></i>
            Finalizar
        </div>
    </div>
</div>

<ng-template #myModal>
    <div class="main">
        <div class="container-modal">
            <modal-content>
                <h5 class="lead" *ngIf="sameNumber">Este número ya está relacionado con otro usuario</h5>
            </modal-content>
            <modal-footer class="btn-options">
                <button class="ui button fs-mini" (click)="closeModal()">
                    De Acuerdo
                </button>
            </modal-footer>
        </div>
    </div>
</ng-template>

<ng-template #modal_update>
    <div class="main">
        <div class="container-modal">
            <modal-content>
                <h5 class="lead">Se ha enviado una solicitud de cambio</h5>
            </modal-content>
            <modal-footer class="btn-options">
                <button class="ui button" (click)="closeModalUpdate()" style="margin-right: 5px">
                    Cerrar
                </button>
                <button class="ui button" (click)="goToChanges()">
                    Ir a cambios
                </button>
            </modal-footer>
        </div>
    </div>
</ng-template>
<ng-template #modal_observation>
    <div class="main">
        <div class="container-modal">
            <modal-content>
                <h5 class="lead text-center">{{txt_observation}}</h5>
            </modal-content>
            <modal-footer class="btn-options mt-4">
                <button class="ui button" (click)="closeModalUpdate()">
                    Cerrar
                </button>
            </modal-footer>
        </div>
    </div>
</ng-template>










<div class="ui basic modal cancel product" style="width: 100%; margin: 25vh auto; overflow: hidden;">
    <div class="ui icon header text-center" *ngIf="!acceptCancelProduct">
        <i class="exclamation icon"></i>
        Solicitar Cancelación
    </div>
    <div class="content text-center" *ngIf="!acceptCancelProduct">
        <p>¿Estás seguro de solicitar una cancelación?</p>
    </div>
    <div class="actions" style="display: flex; justify-content: center;" *ngIf="!acceptCancelProduct">
        <div class="ui red basic cancel inverted button" (click)="hideModalNegative()">
            <i class="remove icon"></i>
            No
        </div>
        <div class="ui green ok inverted button" (click)="showModalArgumentCancell()">
            <i class="checkmark icon"></i>
            Sí
        </div>
    </div>

    <div class="ui icon header text-center" *ngIf="acceptCancelProduct">
        <i class="exclamation icon"></i>
        Escribe la observación
    </div>
    <div class="content text-center" *ngIf="acceptCancelProduct">
        <form [formGroup]="argumentForm" (ngSubmit)=" requestCancell()">
            <div class="row mb">
                <textarea formControlName="argument" [ngClass]="{invalid:argumento.invalid && argumento.dirty}"
                    class="form-control" name="argument" rows="4"></textarea>
            </div>
        </form>
    </div>
    <div class="actions" style="display: flex; justify-content: center;" *ngIf="acceptCancelProduct">
        <div class="ui red basic cancel inverted button" (click)="hideModalNegative()">
            <i class="remove icon"></i>
            Cancelar
        </div>
        <div class="ui green ok inverted button" (click)="requestCancell()">
            <i class="checkmark icon"></i>
            Finalizar
        </div>
    </div>

</div>






<div class="ui basic modal reject cancell product" style="width: 100%; margin: 25vh auto; overflow: hidden;">
    <div class="ui icon header text-center" *ngIf="!isrejectCancellProduct">
        <i class="exclamation icon"></i>
        Rechazar Cancelación
    </div>
    <div class="content text-center" *ngIf="!isrejectCancellProduct">
        <p>¿Estás seguro de rechazar la cancelación?</p>
    </div>
    <div class="actions" style="display: flex; justify-content: center;" *ngIf="!isrejectCancellProduct">
        <div class="ui red basic cancel inverted button" (click)="hideModalNegative()">
            <i class="remove icon"></i>
            No
        </div>
        <div class="ui green ok inverted button" (click)="showModalArgumentRejectCancell()">
            <i class="checkmark icon"></i>
            Sí
        </div>
    </div>

    <div class="ui icon header text-center" *ngIf="isrejectCancellProduct">
        <i class="exclamation icon"></i>
        Escribe la observación
    </div>
    <div class="content text-center" *ngIf="isrejectCancellProduct">
        <form [formGroup]="argumentForm">
            <div class="row mb">
                <textarea formControlName="argument" [ngClass]="{invalid:argumento.invalid && argumento.dirty}"
                    class="form-control" name="argument" rows="4"></textarea>
            </div>
        </form>
    </div>
    <div class="actions" style="display: flex; justify-content: center;" *ngIf="isrejectCancellProduct">
        <div class="ui red basic cancel inverted button" (click)="hideModalNegative()">
            <i class="remove icon"></i>
            Regresar
        </div>
        <div class="ui green ok inverted button" (click)="rejectTheCancell()">
            <i class="checkmark icon"></i>
            Finalizar
        </div>
    </div>

</div>































<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Historial de Productos Asignados</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body all_images">
                <div class="images" *ngFor="let product of assignedProducts">
                    <hr>
                    <div class="table-responsive">
                        <table class="table">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">Duración</th>
                                    <th scope="col">Periodo total</th>
                                    <th scope="col">Autorenovaciones</th>
                                    <th scope="col">Día de cancelación</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {{product.commodity.duration}}

                                    </td>
                                    <td>
                                        {{product.commodity.totalProductPeriod}}

                                    </td>
                                    <td>
                                        {{product.commodity.autoRenovation}}

                                    </td>
                                    <td>
                                        {{product.dateCancellation | date:'dd-MM-yyyy'}}

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">Producto</th>
                                    <th scope="col">Fecha de asignación</th>
                                    <th scope="col">Fecha de inicio</th>
                                    <th scope="col">Fecha de finalización</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {{product.commodity.product}}

                                    </td>
                                    <td>
                                        {{product.commodity.assigned}}

                                    </td>
                                    <td>
                                        {{product.commodity.start | date:'dd-MM-yyyy'}}

                                    </td>
                                    <td>
                                        {{product.commodity.ending | date:'dd-MM-yyyy' }}

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="ui button" data-bs-dismiss="modal">
                    Cerrar
                </button>
            </div>
        </div>
    </div>
</div>

<ng-template #contenido2 let-modal>
    <div class="modal-heade mt-4">
        <div class=" mb-3" *ngFor="let comment of allArticleComments">
            <div class="id_user">
                <p>Usuario {{comment.comentario.id_user }}</p>
            </div>
            <div class="comment mt-2">
                {{comment.comentario.comentario}}
            </div>
            <div class="date mt-3">
                Comentado el&nbsp;
                <p>{{comment.comentario.fecha}}</p>&nbsp;
                alas&nbsp;
                <p>{{comment.comentario.hora}}</p>
            </div>
            <hr>
        </div>
        <div class="fx mb-4">
            <input type="text" [(ngModel)]="articleComment" class="form-control">
            <div class="ui primary button" (click)="commentArticlePost()">
                comentar
            </div>
        </div>
        <button class="ui button mb-4" (click)="closeModal()">Cerrar</button>
    </div>
</ng-template>





<div class="modal fade" id="modalComments" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Comentarios del artículo</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body allComments" style="max-height: 50vh; overflow-y: scroll;">
                <div class=" mb-3" *ngFor="let comment of allArticleComments">
                    <div class="id_user">
                        <b>Usuario {{comment.comentario.id_user }}</b>
                    </div>
                    <div class="comment mt-2">
                        <p> {{comment.comentario.comentario}}</p>
                    </div>
                    <div class="date mt-2">

                        Comentado el&nbsp;
                        <p>{{comment.comentario.fecha}}</p>&nbsp;
                        alas&nbsp;
                        <p>{{comment.comentario.hora}}</p>

                    </div>
                    <hr>
                </div>
            </div>
            <div class="fx mb-4" style="padding: 10px ;">
                <input type="text" placeholder="Escribe tu comentario" [(ngModel)]="articleComment"
                    class="form-control">
                <div class="ui primary button mt-3" (click)="commentArticlePost()">
                    comentar
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="ui button" data-bs-dismiss="modal">
                    Cerrar
                </button>
            </div>
        </div>
    </div>
</div>
<div class="ui basic modal createObservation" style="width: 100%; margin: 25vh auto; overflow: hidden;">
    <div class="ui icon header text-center">
        <h1> Escribe la observación</h1>
    </div>
    <div class="content text-center">
        <form [formGroup]="observationForm" (ngSubmit)="sendObservation()">
            <div class="row mb">
                <textarea formControlName="observation" [ngClass]="{invalid:observation.invalid && observation.dirty}"
                    class="form-control" name="observation" rows="4"></textarea>
            </div>
        </form>
    </div>
    <div class="actions" style="display: flex; justify-content: center;">
        <div class="ui red basic cancel inverted button" (click)="hideModalNegative()">
            <i class="remove icon"></i>
            Cancelar
        </div>
        <div class="ui green ok inverted button" (click)="sendObservation()">
            <i class="checkmark icon"></i>
            Enviar Observación
        </div>
    </div>
</div>