<div class="main">
  <div class="mt-4 mb-5">
    <h2 class="title">Tipo</h2>
  </div>
  <div class="container">
    <div class="header">
      <div class="buttons">
        <div>
          <div class="ui center aligned basic segment">
            <form [formGroup]="ingForm" (ngSubmit)="onSaveForm()">
              <div class="ui left icon action input">
                <input type="radio" id="btn-check-arrendamiento" value="arrendamiento" formControlName="tipo"
                  name="tipo" autocomplete="off" />
                <label class="cardn card-label" for="btn-check-arrendamiento">Arrendamiento</label>

                <!-- <input type="radio" class="btn-check" id="btn-check-outlined" value="arrendamiento"
                  formControlName="tipo" name="tipo" autocomplete="off" />
                <label class="btn btn-outline-primary" for="btn-check-outlined">Arrendamiento</label> -->
              </div>
              <div class="ui horizontal divider">
                O
              </div>
              <div class="ui left icon action input">
                <input type="radio" id="btn-check-compraventa" value="compraventa" formControlName="tipo" name="tipo"
                  autocomplete="off" />
                <label class="cardn card-label" for="btn-check-compraventa">Compraventa</label>
              </div>
              <button type="submit" class="ui button mt-4" [disabled]="tipo.invalid">Continuar</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>