import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { take } from 'rxjs/operators';
import { PdfService } from 'src/app/SERVICIOS/pdf.service';

@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss']
})
export class PdfComponent implements OnInit {


  clientes: any;
  articulos = [];
  idC: any;
  attemps = 0;
  idA: string;



  constructor(
    private router: Router,
    private afs: AngularFirestore,
    private generatePdf: PdfService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.idC = this.route.snapshot.paramMap.get('idCustomer')
    this.idA = this.route.snapshot.paramMap.get('idArticulo')


    this.afs.collection('CUSTOMER').doc(String(this.idC)).valueChanges()
      .pipe(take(1)).toPromise().then(values => { this.clientes = values })


  }


  agregarArticulos() {
    this.router.navigate(['ingresa', this.idC, 'articulo']);
  }

  search() {
    this.attemps++
    if (this.attemps == 1) {
      this.afs.collection('ARTICLE').doc(String(this.idA)).valueChanges()
        .pipe(take(1)).toPromise().then(values => {
          this.articulos.push(values)
          this.pdf()
        })
    }
  }

  pdf() {
    this.generatePdf.generatePdf(this.clientes, this.articulos[0])
  }
}
