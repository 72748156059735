import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaleComponent } from './sale.component';
import { SaleRoutingModule } from './sale-routing.module';
import { SaleListComponent } from './sale-list/sale-list.component';
import { SaleListFullviewComponent } from './sale-list-fullview/sale-list-fullview.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [SaleComponent, SaleListComponent, SaleListFullviewComponent,],
  imports: [
    CommonModule,
    SaleRoutingModule, NgxPaginationModule, NgChartsModule,FormsModule, ReactiveFormsModule 

  ]
})
export class SaleModule { }
