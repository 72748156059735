import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/SERVICIOS/auth.service';
import { NotificationsService } from 'src/app/SERVICIOS/socket/notifications.service';
import { NotificationService } from 'src/app/SERVICIOS/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registra',
  templateUrl: './registra.component.html',
  styleUrls: ['./registra.component.scss'],
  
})
export class RegistraComponent implements OnInit {
  public notifications=[]



  constructor(public notification:NotificationsService,public router:Router, public gonotification:NotificationService,private authsv:AuthService) { }
  goNotification(notification:any){
    this.gonotification.goNotification(notification)
  }
  
  async onLogout() {
    try {
      await this.authsv.logout();
      this.router.navigate(['/login']);
    } catch (error) {
      console.log(error);
    }
  }



  ngOnInit(): void {
  }


  




}