import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanEnterGuard } from 'src/app/SERVICIOS/can/can-enter.guard';
import { AuthGuard } from 'src/app/SERVICIOS/guards/auth.guard';
import { CommunityManageGuard } from 'src/app/SERVICIOS/guards/community-manage.guard';
import { DisabledGuard } from 'src/app/SERVICIOS/guards/disabled.guard';
import { FullAdminGuard } from 'src/app/SERVICIOS/guards/full-admin.guard';
import { SupportGuard } from 'src/app/SERVICIOS/guards/support.guard';

import { GestionaViewComponent } from './gestiona-view/gestiona-view.component';
import { GestionaComponent } from './gestiona/gestiona.component';
import { GoComponent } from './go/go.component';
import { UploadFilesComponent } from './upload-files/upload-files.component';

const routes: Routes = [
  {
    path: 'gestiona',
    component: GestionaComponent,
    children: [
      { path: '', component: GestionaViewComponent },
      { path: ':idR/go', component: GoComponent },
      { path: ':idClient/upload-files-aval/:index/:idLead', component: UploadFilesComponent },
    ],
    canActivate: [
      AuthGuard,
      CanEnterGuard,
      DisabledGuard,
      SupportGuard,
      FullAdminGuard,
      CommunityManageGuard,
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GestionaRoutingModule {}
