import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  
  transform(value: any, arg: string, propName:any): any {
    if (arg === '' || propName == undefined) return value;

    let newArg = arg.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    const resultPosts = [];    
    for (const item of value) {
      if(propName == "id_articulo"){
        if(item[propName].indexOf(arg) > -1){
          resultPosts.push(item)
        }
      }else if(item.articulo[propName].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(newArg.toLowerCase()) > -1){
        resultPosts.push(item)
      }
    };
    return resultPosts;
  }

  

}
