import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/SERVICIOS/auth.service';
import { DataDbService } from 'src/app/SERVICIOS/data-db.service';
import { GetTokensService } from 'src/app/SERVICIOS/get-tokens.service';
import { SocketService } from 'src/app/SERVICIOS/socket/socket.service';
import { User } from '../../MODULOS/user.interface';
declare let $: any
@Component({
  selector: 'app-keyaccess-history',
  templateUrl: './keyaccess-history.component.html',
  styleUrls: ['./keyaccess-history.component.scss']
})
export class KeyaccessHistoryComponent implements OnInit, OnDestroy {
  p: number = 1;

  public m_p: number = 1;
  customer = [];
  my_customer = []
  public user: User;
  uid_user: string;
  denied: boolean = false
  public filterPost = ''
  public filterPostu = ''
  valueName: string;
  valueNameu: string;
  selected: number = 0
  disabledInput: boolean = true;
  disabledInputu: boolean = true;
  incomeSelected: any;
  modal2: boolean = false;
  modal1: boolean = false;
  argumentForm = new UntypedFormGroup({
    argument: new UntypedFormControl('', [Validators.required, Validators.maxLength(1000)]),
  })
  subs_two: Subscription;
  subs_one: Subscription;
  text_input: string;
  text_inputu: string;
  p_long: number;
  requests = [];
  myRequests = [];

  get argumento() { return this.argumentForm.get('argument') }


  constructor(public dbData: DataDbService, public afs: AngularFirestore,
    public auth: AuthService, private tokensUser: GetTokensService, private router: Router, private socket: SocketService,) {

    this.auth.user$.pipe(take(1)).toPromise().then((user) => {
      this.user = user
      this.uid_user = user.uid
      if (!this.auth.canAdmin(user)) {
        this.subs_two = this.afs.collectionGroup("KEY-ACCESS").valueChanges().subscribe((requests: any[]) => {
          this.myRequests = []
          this.requests = []
          requests.forEach((request) => {
            if (request.uid_user != user.uid) {
            } else if (request.uid_user == user.uid) {


              if (request.status == 'incompleto') {
                request.nameVisitor = 'por definir'
                request.id_lead = 'por definir'
                this.myRequests.push(request)
              } else {
                this.myRequests.push(request)
              }

            }
          })

        })

      } else
        if (this.auth.canAdmin(user)) {
          this.subs_two = this.afs.collectionGroup("KEY-ACCESS").valueChanges().subscribe((requests: any[]) => {
            this.myRequests = []
            this.requests = []
            requests.forEach((request) => {
              if (request.uid_user != user.uid) {
                if (request.status == 'incompleto') {
                  request.nameVisitor = 'Por definir'
                  request.id_lead = 'Por definir'
                  this.requests.push(request)
                } else {
                  this.requests.push(request)
                }
              } else if (request.uid_user == user.uid) {

                if (request.status == 'incompleto') {
                  request.nameVisitor = 'Por definir'
                  request.id_lead = 'Por definir'
                  this.myRequests.push(request)
                } else {
                  this.myRequests.push(request)
                }
              }
            })


          })
        }
    })
  }
  keyAccessFullView(request: any) {
    this.router.navigate(['/keyaccess/keyaccess-fullview', request.id_article, request.id_document]);
  }

  newRequestAccessKey() {
    this.router.navigate(['/article-listing/keyaccess']);
  }

  changePage(e) {
    this.p = e
    localStorage.setItem('page', e)
  }
  changePageu(e) {
    this.m_p = e
    localStorage.setItem('pageu', e)
  }
  resetPage() {
    this.p = 1;
    localStorage.setItem('page', '1')
  }
  resetPageu() {
    this.p = 1;
    localStorage.setItem('pageu', '1')
  }

  getText(e) {
    this.resetPage()
    localStorage.setItem('filterPost', e)
    this.filterPost = e
  }

  getTextu(e) {
    this.resetPageu()
    localStorage.setItem('filterPostu', e)

    this.filterPostu = e



  }


  ngOnInit() {

    let comp = localStorage.getItem('component')
    if (comp == null || comp == undefined || comp == '') {
      localStorage.setItem('component', 'keyaccess-history')
      this.text_input = ''
      this.text_inputu = ''
    }
    else
      if (comp == 'keyaccess-history') {
        let valueName = localStorage.getItem('valueName')
        let filterPost = localStorage.getItem('filterPost')
        let disabledInput = localStorage.getItem('disabledInput')
        let page = localStorage.getItem('page')

        let valueNameu = localStorage.getItem('valueNameu')
        let filterPostu = localStorage.getItem('filterPostu')
        let disabledInputu = localStorage.getItem('disabledInputu')

        let pageu = localStorage.getItem('pageu')



        if (page !== null && page !== undefined && page !== '') { this.p = Number(page) } else { localStorage.setItem('page', '1') }
        if (pageu !== null && pageu !== undefined && pageu !== '') { this.m_p = Number(pageu) } else { localStorage.setItem('pageu', '1') }

        if (valueName !== null && valueName !== undefined && valueName !== '') this.valueName = valueName
        if (valueNameu !== null && valueNameu !== undefined && valueNameu !== '') this.valueNameu = valueNameu

        if (filterPost !== null && filterPost !== undefined && filterPost !== '') {
          this.filterPost = filterPost
          this.text_input = filterPost
        }
        if (filterPostu !== null && filterPostu !== undefined && filterPostu !== '') {
          this.filterPostu = filterPostu
          this.text_inputu = filterPostu

        }
        if (disabledInput !== null && disabledInput !== undefined && disabledInput !== '') {
          if (disabledInput == 'true') {
            this.disabledInput = true
          } else if (disabledInput == 'false') {
            this.disabledInput = false
          }
        }
        if (disabledInputu != null && disabledInputu != undefined && disabledInputu != '' &&
          disabledInputu != 'true'
        ) {
          this.disabledInputu = false

        } else {
          this.disabledInputu = true
        }

      }



    // if (value !== null) {
    //   this.valueName = value
    // }
    // if(text !== null && text !== ''){
    //   this.text_input = text
    // }

  }











  filterWithSelectu(e: String) {
    localStorage.setItem('component', 'keyaccess-history')

    if (e == '1') {
      this.valueNameu = "id";
      this.disabledInputu = false
      localStorage.setItem('disabledInputu', 'false');
      localStorage.setItem('valueNameu', this.valueNameu);
    } else if (e == '2') {
      this.valueNameu = "id_access";
      this.disabledInputu = false
      localStorage.setItem('disabledInputu', 'false');
      localStorage.setItem('valueNameu', this.valueNameu);
    } else if (e == '3') {
      this.valueNameu = "nombre";
      this.disabledInputu = false
      localStorage.setItem('disabledInputu', 'false');
      localStorage.setItem('valueNameu', this.valueNameu);
    }
    else if (e == '4') {
      this.valueNameu = "id_article";
      this.disabledInputu = false
      localStorage.setItem('disabledInputu', 'false');
      localStorage.setItem('valueNameu', this.valueNameu);
    }
    else if (e == '5') {
      this.valueNameu = "id_lead";
      this.disabledInputu = false
      localStorage.setItem('disabledInputu', 'false');
      localStorage.setItem('valueNameu', this.valueNameu);
    }
    else if (e == '6') {
      this.valueNameu = "habilitado";
      this.disabledInputu = true
      localStorage.setItem('disabledInputu', 'true');
      localStorage.setItem('valueNameu', this.valueNameu);

    } else if (e == '7') {
      this.valueNameu = "deshabilitado";
      this.disabledInputu = true
      localStorage.setItem('disabledInputu', 'true');
      localStorage.setItem('valueNameu', this.valueNameu);
      // localStorage.setItem('valueName', this.valueName);
    }

  }

  filterWithSelect(e) {
    localStorage.setItem('component', 'keyaccess-history')

    if (e == '1') {
      this.valueName = "id";

      this.disabledInput = false
      localStorage.setItem('disabledInput', 'false');
      localStorage.setItem('valueName', this.valueName);
    } else if (e == '2') {
      this.valueName = "id_access";
      this.disabledInput = false
      localStorage.setItem('disabledInput', 'false');
      localStorage.setItem('valueName', this.valueName);
    } else if (e == '3') {
      this.valueName = "nombre";
      this.disabledInput = false
      localStorage.setItem('disabledInput', 'false');
      localStorage.setItem('valueName', this.valueName);
    }
    else if (e == '4') {
      this.valueName = "id_article";
      this.disabledInput = false
      localStorage.setItem('disabledInput', 'false');
      localStorage.setItem('valueName', this.valueName);
    }
    else if (e == '5') {
      this.valueName = "id_lead";
      this.disabledInput = false
      localStorage.setItem('disabledInput', 'false');
      localStorage.setItem('valueName', this.valueName);
    }
    else if (e == '6') {
      this.valueName = "habilitado";
      this.disabledInput = true
      localStorage.setItem('disabledInput', 'true');
      localStorage.setItem('valueName', this.valueName);

    } else if (e == '7') {
      this.valueName = "deshabilitado";
      this.disabledInput = true
      localStorage.setItem('disabledInput', 'true');
      localStorage.setItem('valueName', this.valueName);
      // localStorage.setItem('valueName', this.valueName);
    }

  }






  ngOnDestroy() {
    if (this.subs_one !== undefined) {
      this.subs_one.unsubscribe();
    }
    if (this.subs_two !== undefined) {
      this.subs_two.unsubscribe()
    }
  }

}
















