import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationsService } from 'src/app/SERVICIOS/socket/notifications.service';
import { AuthService } from 'src/app/SERVICIOS/auth.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/SERVICIOS/notification.service';
import { SocketService } from 'src/app/SERVICIOS/socket/socket.service';

@Component({
  selector: 'app-historys',
  templateUrl: './historys.component.html',
  styleUrls: ['./historys.component.scss'],

})
export class HistorysComponent implements OnInit, OnDestroy {
  uid_user: string;

  constructor(public notification: NotificationsService,
    private socket: SocketService,
    private authSvc: AuthService, private router: Router,
    public gonotification: NotificationService, private authsv: AuthService) {
    this.authSvc.getCurrentUser().then(value => {
      this.uid_user = value.uid
    })
  }

  ngOnInit(): void {
   //localStorage.clear()
  }


  sendEvent() {
    this.socket.io.emit("add-product")

  }


  async onLogout() {
    try {
      await this.authsv.logout();
      this.router.navigate(['/login']);
    } catch (error) {
      console.log(error);
    }
  }

ngOnDestroy(): void {

}


}
