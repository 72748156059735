<div class="main">
    <!-- <div class="mt-4 mb-3 d-flex justify-content-between">
        <h1>Seleccionar lead</h1>

        <div></div>
        <button class="ui primary button" (click)="createNewLead()">Crear lead</button>
    </div> -->
    <div class=" d-flex justify-content-between">
        <h2 class="title mt-4 mb-5">Seleccionar lead</h2>
        <div>
            <div class="card-new-lead" (click)="createNewLead()">
                Crear lead
            </div>
        </div>
    </div>

    <div class=" mt-3" *ngIf="myLeads.length > 0">
        <div *ngFor="let lead of myLeads" class="grid-incomes">
            <div class=" card card-income" (click)='goToGenerateToken(lead)'>
                <h3 class=" fw-bold">{{ lead.cliente.nombre | titlecase }} {{ lead.cliente.apellido | titlecase }}
                </h3>
                <p>{{ lead.id }}</p>

                <div style="display: flex;">
                    <div style="display: flex;flex-direction: column;
                align-items: center;">
                        <th class="text-center" style="font-weight: bold;">Relación de artículos</th>
                        <p>{{ getRelation(lead)}}</p>
                    </div>
                    <div style="display: flex;flex-direction: column;
                align-items: center;">
                        <p class="text-center" style="font-weight: bold;">Artículos positivos</p>

                        <p>{{ getPositiveArticles(lead) }}</p>
                    </div>
                    <div style="display: flex;flex-direction: column;
                align-items: center;">
                        <p class="text-center" style="font-weight: bold;">Artículos negativos</p>

                        <p>{{ getNegativeArticles(lead)}}</p>
                    </div>

                </div>
            </div>
            <!-- <div class="card mt-3">
                <div class="card-body">
                    <h5 class="card-title">{{lead.cliente.nombre}} {{lead.cliente.apellido}}</h5>
                    <p class="card-text">{{lead.cliente.telefono}}</p>
                    <div (click)="goToGenerateToken(lead)" class="ui button primary">Seleccionar Lead</div>
                </div>
            </div> -->
        </div>
    </div>
    <div class="row" *ngIf="myLeads.length == 0">
        <div>
            <h3>No hay leads disponibles</h3>
        </div>
    </div>
</div>