import { User } from "src/app/COMERCIAL/MODULOS/user.interface";

export class RoleValidator {

  isClassic(user: User): boolean {
    return user.role === 'CLASSIC'
  }

  isNoRole(user: User): boolean {
    return user.role === 'NO-ROLE';
  }

  isAdmin(user: User): boolean {
    return user.role === 'ADMIN';
  }


  isFullAdmin(user: User): boolean {
    return user.role === 'FULL-ADMIN';
  }
  isSuperAdmin(user: User): boolean {
    return user.role === 'SUPER-ADMIN';
  }

  isSupport(user: User): boolean {
    return user.role === 'SUPPORT';
  }
 
  isEnabled(user: User): boolean {
    return user.status === 'enabled';
  }

  canSupport(user: User): boolean {
    const allowed = ['SUPPORT']
    return this.checkAuthorization(user, allowed)
  }
  canAdmin(user: User): boolean {
    const allowed = ['ADMIN', 'FULL-ADMIN']
    return this.checkAuthorization(user, allowed)
  }

  userFullAdmin(user: User): boolean {
    const allowed = ['FULL-ADMIN']
    return this.checkAuthorization(user, allowed)
  }
  userAdmin(user: User): boolean {
    const allowed = ['ADMIN']
    return this.checkAuthorization(user, allowed)
  }
  userSupport(user: User): boolean {
    const allowed = ['SUPPORT']
    return this.checkAuthorization(user, allowed)
  }
  isCommunityManage(user: User): boolean {   
    const allowed = ['COMMUNITY-MANAGE'] 
    return this.checkAuthorization(user, allowed)
  }





  private checkAuthorization(user: User, allowedRoles: string[]): boolean {
    if (!user) return false
    for (const role of allowedRoles) {
      if (user.role == role) {
        return true
      }
    }
    return false
  }

}