import { Component, OnChanges, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { CargaService } from "src/app/SERVICIOS/carga.service";
import { ActivatedRoute } from "@angular/router";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { take } from "rxjs/operators";
import { Location } from "@angular/common";
import { FormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Fileitem } from "../../MODULOS/ingresa/file";

@Component({
  selector: "app-subir-foto",
  templateUrl: "./subir-foto.component.html",
  styleUrls: ["./subir-foto.component.scss"],
})
export class SubirFotoComponent implements OnInit {
  public identificationForm: UntypedFormGroup;
  estaSobreElemento = false;
  archivos = [];
  uploadPercent: Observable<number>;
  urlImage: Observable<string>;
  router: any;
  id: string;
  urls = [];
  edit: boolean = false;
  identification: string;
  tipo: string = "";
  idClient: string;
  index: string;
  idLead: string;
  constructor(
    public _cargaImagenes: CargaService,
    private _location: Location,
    private afs: AngularFirestore,
    private route: ActivatedRoute,
  ) {
    this.idClient = this.route.snapshot.paramMap.get("idClient");
    this.index = this.route.snapshot.paramMap.get("index");
    this.idLead = this.route.snapshot.paramMap.get("idLead");
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("idCustomer");
    if (this.route.snapshot.paramMap.get("edit")) {
      this.afs
        .collection("CUSTOMER")
        .doc(this.id)
        .valueChanges()
        .pipe(take(1))
        .toPromise()
        .then((customer: any) => {
          if (customer.mandarIdentificacion) {
            this.edit = true;
            this.identification = customer.identificacion;
            this.tipo = this.identification;
          } else this._location.back();
        });
    }
    // this.identificationForm = this.createFormGroup()
  }

  // createFormGroup() {
  //   return new FormGroup({
  //     tipo: new FormControl('', Validators.required),
  //   });
  // }
  // get tipo() { return this.identificationForm.get('tipo') }

  onUpload(e) {
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (event) => {
      let url = (<FileReader> event.target).result;
      this._extraerArchivos(e.target.files, url);
      e = undefined;
    };
  }
  _extraerArchivos(archivosLista: FileList, url) {
    for (const propiedad in Object.getOwnPropertyNames(archivosLista)) {
      const archivoTemporal = archivosLista[propiedad];
      if (this._archivoPuedeSerCargado(archivoTemporal)) {
        const nuevoArchivo = new Fileitem(archivoTemporal);
        let imagen = {
          imagen: nuevoArchivo,
          url,
        };
        this.archivos.push(imagen);
      }
    }
  }

  private _archivoPuedeSerCargado(archivo: File): boolean {
    if (
      !this._archivoYaFueDroppeado(archivo.name) &&
      this._esImagen(archivo.type)
    ) {
      return true;
    } else {
      return false;
    }
  }

  private _archivoYaFueDroppeado(nombreArchivo: string): boolean {
    for (const archivo of this.archivos) {
      if (archivo.nombreArchivo === nombreArchivo) {
        return true;
      }
    }

    return false;
  }

  private _esImagen(tipoArchivo: string): boolean {
    return tipoArchivo === "" || tipoArchivo === undefined
      ? false
      : tipoArchivo.startsWith("image");
  }

  cargarImagenes() {
    if (this.tipo !== "") {
      if (this.idClient) {
        this.onSaveForm();
        this._cargaImagenes.cargaImagenesFirebaseClient(
          this.archivos,
          this.idClient,
          this.index,
          this.idLead,
        );
      } else {
        this.onSaveForm();
        this._cargaImagenes.cargaImagenesFirebase(
          this.archivos,
          this.id,
          this.edit,
        );
      }
    } else {
      alert("Elige el tipo de identificación");
    }
  }
  onSaveForm() {
    if (this.idClient) {
      this.afs
        .collection("CUSTOMER")
        .doc(this.idClient)
        .set({ identification: this.tipo, identificacion: this.tipo }, {
          merge: true,
        });
    } else {
      this.afs
        .collection("CUSTOMER")
        .doc(this.id)
        .set({ identificacion: this.tipo }, { merge: true });
    }
  }

  LimpiarArchivos() {
    this.archivos = [];
    this.urls = [];
  }

  deleteImg(i: number) {
    this.archivos.splice(i, 1);
  }
}
