<div class="main">
  <div class=" mb-2">
    <h2 class="title">Actividad reciente</h2>
  </div>
  <div class="container">
    <div style="display: flex;
    flex-direction: row;
    justify-content: flex-end;" class="mb-2">
      <div>

      </div>
      <div style="display: flex;">

        <!-- <button class="ui primary button " (click)="showPrincipales()">Principal</button>
        <button class="ui primary button " (click)="showArchivados()">Archivados</button>

        <button class="ui primary button ">menu</button> -->
        <div style="padding-right: 10px;">
          <input (click)="showPrincipales()" type="radio" class="btn-check" name="options" id="option1"
            autocomplete="off">
          <label class="btn btn-secondary" for="option1">Principal ({{recientes.length}})</label>
        </div>
        <input (click)="showArchivados()" type="radio" class="btn-check" name="options" id="option2" autocomplete="off">
        <label class="btn btn-secondary" for="option2">Archivados ({{archivados.length}})</label>
      </div>
    </div>


    <div *ngIf="!showArch">
      <div class="subtitle mb-5">
        <h4>Recientes</h4>
      </div>
      <div style="width: 98%; margin: auto; position: relative;"
        *ngFor="let reciente of recientes | paginate: { itemsPerPage: 5, currentPage: p,  totalItems: recientes.length }">
        <div class="point-view" *ngIf="!reciente.recentActivity.view">

        </div>
        <div style="display: flex;justify-content: space-between;justify-content: space-around;" class="mb-3">
          <div class="shadow-lg p-3card" style="width: 79%;  height: 14vh; ">
            <div
              style="margin-left: 20px;height: 6.3vh;display: flex; flex-direction: column; justify-content: center;">
              <div>
                <div>
                  <h3 style="font-weight: bold;">{{reciente.recentActivity.id}}</h3>
                  <p *ngIf="isFullAdmin || isAdmin"></p>
                  <i class="star icon" title="Importante" *ngIf="reciente.recentActivity.important"
                    style="color: rgb(182, 182, 0);"></i>
                  <h5>{{reciente.recentActivity.generalTitle}}</h5>
                </div>

              </div>
            </div>
          </div>
          <div style="width: 20%; display: flex; flex-direction: column;justify-content: space-around;">
            <div (click)="goToFullView(reciente)" class=" shadow-lg card" style="height: 6.3vh;">
              <div
                style="cursor: pointer;height: 6.3vh;display: flex; flex-direction: column; justify-content: center;">
                <div style="font-size: 1.1rem;text-align: center; font-weight: bold; cursor: pointer;">Ver</div>
              </div>
            </div>
            <div  *ngIf="!isFullAdmin && !isAdmin"class=" shadow-lg card" style="height: 6.3vh;">
              <div
                style="cursor: pointer;height: 6.3vh;display: flex; flex-direction: column; justify-content: center;">
                <div  style="font-size: 1.1rem; text-align: center; font-weight: bold;" (click)="archivar(reciente)">
                  Archivar</div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="recientes.length == 0">
        <h3>No hay nada que mostrar</h3>
      </div>
    </div>
    <div *ngIf="showArch">
      <div class="subtitle mb-5">
        <h4>Archivados</h4>
      </div>
      <div style="width: 98%; margin: auto; position: relative;"
        *ngFor="let reciente of archivados | paginate: { itemsPerPage: 5, currentPage: pa,  totalItems: archivados.length }">
        <div class="point-view" *ngIf="!reciente.recentActivity.view">

        </div>
        <div style="display: flex;justify-content: space-between;justify-content: space-around;" class="mb-3">
          <div class="shadow-lg p-3card" style="width: 79%;  height: 14vh; ">
            <div
              style="margin-left: 20px;height: 6.3vh;display: flex; flex-direction: column; justify-content: center;">
              <div>
                <div>
                  <h3 style="font-weight: bold;">{{reciente.recentActivity.id}}</h3>
                  <i class="star icon" title="Importante" *ngIf="reciente.recentActivity.important"
                    style="color: rgb(182, 182, 0);"></i>

                  <h5>{{reciente.recentActivity.generalTitle}}</h5>
                </div>

              </div>
            </div>
          </div>
          <div style="width: 20%; display: flex; flex-direction: column;justify-content: space-around;">
            <div (click)="goToFullView(reciente)" class=" shadow-lg card" style="height: 6.3vh;">
              <div
                style=" cursor: pointer;height: 6.3vh;display: flex; flex-direction: column; justify-content: center;">
                <div style="font-size: 1.1rem;text-align: center; font-weight: bold; cursor: pointer;">Ver</div>
              </div>
            </div>
            <div *ngIf="!isFullAdmin && !isAdmin" class=" shadow-lg card" style="height: 6.3vh;">
              <div
                style="margin-left: 20px;cursor: pointer;height: 6.3vh;display: flex; flex-direction: column; justify-content: center;">

                <div  style="font-size: 1.1rem; text-align: center; font-weight: bold;" (click)="noArchivar(reciente)">
                  No Archivar</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="archivados.length == 0">
        <h3>No hay nada que mostrar</h3>
      </div>
    </div>

    <div>

    </div>
    <div *ngIf="!showArch">
      <div class="text-center mt-4 mb-5" *ngIf="recientes.length > 5">
        <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" (pageChange)="changePage($event)"
          class="float-right"></pagination-controls>
      </div>
    </div>
    <div *ngIf="showArch">
      <div class="text-center mt-4 mb-5" *ngIf="archivados.length > 5">
        <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" (pageChange)="changePage($event)"
          class="float-right"></pagination-controls>
      </div>
    </div>

  </div>
</div>