import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from '../auth.service';
import { SocketService } from './socket.service';
import firebase from 'firebase/compat/app';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  tokens = [];
  notifications = [];
  others_notifications = [];
  uid_user: string;
  count: number = 0;
  lenght_notifications: number = 0;
  array = [];
  subs: Subscription;
  isNewNotification: boolean = false;

  constructor(
    private socket: SocketService,
    private authsv: AuthService,
    private afs: AngularFirestore
  ) {
    this.authsv.getCurrentUser().then((data) => {
      if (data !== null) {
        this.uid_user = data.uid;

        this.getNotificationsNoReads();
      }
    });
  }

  async getNotificationsNoReads() {

    this.afs
      .collection(`USERS/${this.uid_user}/NOTIFICATION`)
      .valueChanges()
      .subscribe((notifications: any) => {
        //acomodar por fecha
        let newNot = notifications.sort((a: any, b: any) => {
          return (
            new Date(b.notification.date).getTime() -
            new Date(a.notification.date).getTime()
          );
        });

        this.notifications = [];
        this.lenght_notifications = 0;
        if (notifications !== undefined) {
          for (const notification of newNot) {
            let not = notification.notification;
            not.id = notification.id;
            if (!notification.notification.open) this.lenght_notifications++;
            this.notifications.push(not);
            // this.lenght_notifications = this.notifications.length;
          }
          //acomodar por fecha
        }
      });
  }

  userEspecifico(uid) {
    this.socket.io.emit('user-especifico', uid);
  }

  chat(objet) {
    this.socket.io.emit('chat', objet);
  }

  customerIncomplete(complete, id_cliente, id_user, id_document) {
    let object = {
      complete: complete,
      uid: id_user,
      id_client: id_cliente,
      id_document: id_document,
    };
    this.socket.io.emit('customerIncomplete', object);
  }

  leadIncomplete(complete, id_lead, id_user, id) {
    let object = {
      complete: complete,
      uid: id_user,
      id_lead: id_lead,
      id_document: id,
    };

    this.socket.io.emit('leadIncomplete', object);
  }

  leadComplete(complete, id_lead, id_user) {
    let object = {
      complete: complete,
      uid: id_user,
      id_lead: id_lead,
    };
    this.socket.io.emit('leadComplete', object);
  }

  setNotificationAllOpen() {
    this.afs
      .collection(`USERS/${this.uid_user}/NOTIFICATION`)
      .get()
      .pipe(take(1))
      .toPromise()
      .then((data) => {
        data.docs.forEach((doc) => {
          let not: any = doc.data();
          if (!not.notification.open) {
            this.afs.doc(`USERS/${this.uid_user}/NOTIFICATION/${doc.id}`).set(
              {
                notification: {
                  open: true,
                },
              },
              { merge: true }
            );
          }
        });
      });
  }
}
