<div class="main" *ngIf="articles; else loading">
  <div class="mt-4 mb-5">
    <h2 class="title">Artículos</h2>
  </div>
  <div *ngIf='total_articles > 0 && !noArticleFound && !isCuarentena'>
    <div class="options-buttons" (click)="backToEdit()">
      <button class="ui button">
        Regresar a la selección
      </button>
      <button class="ui button" (click)="noFoundArticle()">
        No encontré el mejor artículo para mi selección
      </button>
    </div>

    <div>
      <div>
        <div class="mt-5 mb-5">
          <h5>Resultados: {{articles.length}}</h5>
        </div>
      </div>
      <!-- <div class="text-center mt-4 mb-2" *ngIf="articles.length > 5">
        <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" (pageChange)="p = $event"
          class="float-right"></pagination-controls>
      </div> -->
    </div>
    <div class="all-articles" *ngIf="(articles && articlesSelected) && !noArticleFound">
      <div class="article card mb-4" *ngFor="let art of articles; let i = index ">
        <div class="image" *ngIf="art.imagen_principal.url; else noImg">
          <img [src]="art.imagen_principal.url" alt="" />
        </div>
        <ng-template #noImg>
          <div class="no-image">
            Sin fotografías
          </div>
        </ng-template>
        <div class="description text-center mt-2 mb-4">
          <div class="first-info text-center">
            <p *ngIf='art.tipo.tipo == "compraventa"'>Véndela por</p>
            <p *ngIf='art.tipo.tipo == "arrendamiento"'>Réntasela por</p>
          </div>
          <div class="mb-2">
            <p>{{ art.articulo.precio | currency}} {{art.articulo.currency}}</p>
          </div>
          <p class="text-center">{{ art.articulo.ciudad | titlecase}}, {{ art.articulo.estado | titlecase}}, {{
            art.articulo.pais}}</p>
        </div>
        <div class="options">
          <button class="ui button" title="Vista Completa" (click)="routerItem(art, i)">
            +
          </button>
          <input type="checkbox" class="btn-check" (change)="checked($event.target.checked, art, i)"
            name="options-outlined" [checked]="articlesSelected[i].selected" [id]="i" />
          <label style="width: 100%;" *ngIf="articlesSelected[i].selected" [for]="i"
            class="ui primary button ">Seleccionado</label>
          <label style="width: 100%;" *ngIf="articlesSelected[i].selected == false" [for]="i"
            class="ui button">Seleccionar</label>
        </div>
      </div>
    </div>
  </div>



  <div *ngIf="total_articles > 0 && ( noArticleFound || isCuarentena)">
    <div class="no-found">
      <p class="text-center mb-4">Te notificaremos cuando tengamos mejores articulos para tu selección lo más pronto
        posible
      </p>
      <div>
        <button class="ui button mb-2" routerLink="/pagina-principal">Ir ala página principal</button>
        <button class="ui button" routerLink="/registra">Continuar registrando</button>
      </div>

    </div>
  </div>

  <div *ngIf="total_articles == 0">
    <div class="no-found">
      <p class="text-center mb-4">
        Lo sentimos, no hemos podido encontrar artículos para tu selección pero no te preocupes, te notificaremos cuando
        tengamos nuevos
        y mejores artículos lo mas pronto posible
      </p>
      <div>
        <button class="ui button mb-2" routerLink="/pagina-principal">Ir ala página principal</button>
        <button class="ui button" routerLink="/registra">Continuar registrando</button>
      </div>
    </div>
  </div>
  <div *ngIf="total_articles == undefined">
    <div style="display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
    height: 80vh;">
      <div class="ui segment">
        <div class="ui active inverted dimmer">
          <div class="ui text loader">Buscando...</div>
        </div>
      </div>
    </div>
  </div>
  <div class="next mb-5" *ngIf="total_articles > 0 && !noArticleFound && !isCuarentena">
    <button class="btn btn-primary" (click)="submit()">Ir a gestiona</button>
  </div>
</div>
<ng-template #loading> Cargando... </ng-template>
