import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin/admin.component';
import { PerfilComponent } from './perfil/perfil.component';
import { AdminRoutingModule } from '../ADMIN/admin-routing.module';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { FilterusersPipe } from '../COMERCIAL/pipes/filterusers.pipe';



@NgModule({
  declarations: [AdminComponent, PerfilComponent, FilterusersPipe],
  imports: [
    CommonModule, AdminRoutingModule, FormsModule, NgxPaginationModule

  ]
})
export class AdminModule { }
