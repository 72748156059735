<div class="wrapper" *ngIf=" user &&
router.url !== '/login' &&
router.url !== '/register' &&
router.url !== '/verification-email' &&
router.url !== '/terms-and-conditions' &&
router.url !== '/privacy-policy' &&
router.url !== '/forgot-password' &&
router.url !== '/register-phone' &&
router.url !== '/login-phone/login' &&
router.url !== '/login-phone/register' &&

router.url !== '/welcome'; else noShowBar">

  <!-- <nav id="sidebar" class="sidebar" *ngIf="user && !auth.userSupport(user) && !auth.isCommunityManage(user)">
    <div class="cajaBotones">
      <i (click)="sidebarToogle()" (click)="goTo('ingresa')" class="huge home icon"></i>
      <i style="margin-top : 30px;" (click)="sidebarToogle()" (click)="goTo('registra')" class="huge pencil icon"></i>
      <i style="margin-top : 30px;" (click)="sidebarToogle()" (click)="goTo('gestiona')" class="huge home icon"></i>
    </div>
  </nav> -->


  <!-- <div class="setsidebar">
    <h3 class="mt-3 titlePage" routerLink="/pagina-principal">Set99</h3>
    <div style="padding-left: 30px; padding-right: 30px; display: flex;
    flex-direction: column;
    align-items: center;">
      <a title="Ingresa" class="item mt-4 linkp" (click)="goTo('ingresa')">
        <i class="big plus icon"></i>

      </a>
      <a title="Registra" class="item mt-4 linkp" (click)="goTo('registra')">
        <i class="big user plus icon"></i>

      </a>
      <a title="Gestiona" class="item mt-4 linkp" (click)="goTo('gestiona')">
        <i class="big tasks icon"></i>


      </a>
    </div>
  </div> -->

  <!-- <div class="d-flex flex-column flex-shrink-0 bg-light" style="width: 4.5rem;">
    <a href="/" class="d-block p-3 link-dark text-decoration-none" title="Icon-only" data-bs-toggle="tooltip"
      data-bs-placement="right">
      <svg class="bi pe-none" width="40" height="32">
        <use xlink:href="#bootstrap" />
      </svg>
      <span class="visually-hidden">Icon-only</span>
    </a>
    <ul class="nav nav-pills nav-flush flex-column mb-auto text-center">
      <li class="nav-item">
        <a href="#" class="nav-link active py-3 border-bottom rounded-0" aria-current="page" title="Home"
          data-bs-toggle="tooltip" data-bs-placement="right">
          <i class="big plus icon"></i>
        </a>
      </li>
      <li>
        <a href="#" class="nav-link py-3 border-bottom rounded-0" title="Dashboard" data-bs-toggle="tooltip"
          data-bs-placement="right">
          <i class="big user plus icon"></i>
        </a>
      </li>
      <li>
        <a href="#" class="nav-link py-3 border-bottom rounded-0" title="Orders" data-bs-toggle="tooltip"
          data-bs-placement="right">
          <i class="big tasks icon"></i>
        </a>
      </li>


    </ul>
    <div class="dropdown border-top">
      <a href="#"
        class="d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none dropdown-toggle"
        data-bs-toggle="dropdown" aria-expanded="false">
        <img src="https://github.com/mdo.png" alt="mdo" width="24" height="24" class="rounded-circle">
      </a>
      <ul class="dropdown-menu text-small shadow">
        <li><a class="dropdown-item" href="#">New project...</a></li>
        <li><a class="dropdown-item" href="#">Settings</a></li>
        <li><a class="dropdown-item" href="#">Profile</a></li>
        <li>
          <hr class="dropdown-divider">
        </li>
        <li><a class="dropdown-item" href="#">Sign out</a></li>
      </ul>
    </div>
  </div> -->
  <div class="main">
    <nav class="navbar navbar-expand navbar-light navbar-bg">
      <!-- <a id="icono" class="sidebar-toggle d-flex" (click)="sidebarToogle()"
        *ngIf="user && !auth.userSupport(user) && !auth.isCommunityManage(user)">
        <i class="hamburger align-self-center"></i>
      </a> -->
      <p class="fs-2 fw-bolder" style=" cursor: pointer; color: rgb(155, 137, 58);" routerLink="/pagina-principal">Set99
      </p>




      <div class="navbar-collapse collapse">
        <ul class="navbar-nav navbar-align">
          <li class="nav-item">
            <a><img style="cursor: pointer;" (click)="createChatSupport()" src="./../../../assets/icons/support.png"
                width="24" height="24" title="Iniciar chat de soporte"></a>

          </li>
          <li class="nav-item dropdown icon-notification">
            <div class="btn-group" *ngIf="user && !auth.isCommunityManage(user)">

              <a (click)="checkNotification()" class="dropdown-toggle" role="button" id="dropdownMenuLink"
                data-bs-toggle="dropdown" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-bell align-middle">
                  <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                  <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                </svg>
                <span class="indicator" *ngIf="notification.lenght_notifications > 0">
                  {{ notification.lenght_notifications }}
                </span>
              </a>
              <ul class="dropdown-menu dropdown-notification" aria-labelledby="dropdownMenuLink">
                <div style="margin-left: 5px;">
                  <h4 class="subtitle f-white"> Notificaciones</h4>
                </div>
                <li class="dropdown-item" *ngFor="let not of notification.notifications" (click)="goNotification(not)">
                  <hr>
                  <div style="background-color: #d0dbff;" class="items" *ngIf="!not.read">

                    <div class="box-item">
                      <div class="item-content" style="font-weight: bold;"> {{not.msg }} </div>
                    </div>
                    <div class="dateAgo mt-2">
                      {{ not.date | dateAgo }}
                    </div>
                  </div>
                  <div class="items" *ngIf="not.read">

                    <div class="box-item">
                      <div class="item-content" style="font-weight: bold;"> {{not.msg }} </div>
                    </div>
                    <div class="dateAgo mt-2">
                      {{ not.date | dateAgo }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li></li>

          <li class="nav-item nav-item">
            <svg (click)="toggle()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-three-dots-vertical" viewBox="0 0 16 16" type="button">
              <path
                d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
            </svg>
            <div class="btn-group">
              <div class="ui dropdown">
                <div class="menu">
                  <div class="item" (click)="profile()"
                    *ngIf="!auth.userSupport(user) && !auth.isCommunityManage(user)">Perfil</div>

                  <div class="item" routerLink="/historys/ingresa-history"
                    *ngIf="!auth.userSupport(user) && !auth.isCommunityManage(user)">
                    Clientes
                  </div>
                  <div class="item" routerLink="/historys/registra-history"
                    *ngIf="!auth.userSupport(user)&& !auth.isCommunityManage(user)">

                    Leads
                  </div>
                  <div class="item"
                    *ngIf="auth.canAdmin(user) && !auth.userSupport(user) && !auth.isCommunityManage(user)"
                    routerLink="/gestiona">Leads positivos
                  </div>
                  <div class="item"
                    *ngIf="!auth.canAdmin(user) && !auth.userSupport(user) && !auth.isCommunityManage(user)"
                    routerLink="/gestiona">Gestiona
                  </div>
                  <div class="item"
                    *ngIf="auth.canAdmin(user) && !auth.userSupport(user) && !auth.isCommunityManage(user)"
                    routerLink="/admin">Usuarios
                  </div>
                  <div class="item" (click)="article_listing()"
                    *ngIf="!auth.userSupport(user) && !auth.isCommunityManage(user)">

                    Article Listing
                  </div>
                  <div class="item" (click)="keyAccess()" *ngIf="!auth.userSupport(user)">

                    Key Access
                  </div>
                  <div class="item"
                    *ngIf="!auth.userFullAdmin(user) && !auth.userSupport(user) && !auth.isCommunityManage(user)">
                    <i class="dropdown icon" style="margin-right: 5px;"></i>
                    Cambios
                    <div class="menu">
                      <div class="item" routerLink="/operaciones/aprobaciones-ingresa/aprobaciones/solicitudes">
                        Clientes</div>
                      <div class="item" routerLink="/operaciones/aprobaciones-registra/aprobaciones/solicitudes">
                        Leads</div>
                    </div>
                  </div>
                  <div class="item" (click)="leadAndSupport()" *ngIf="auth.userFullAdmin(user)">

                    Lead and Support
                  </div>
                  <div class="item" *ngIf="!auth.isCommunityManage(user)">
                    <i class="dropdown icon" style="margin-right: 5px;"></i>
                    Sale and Lease
                    <div class="menu">
                      <div class="item" routerLink="sale/sale-list/list">
                        Sale</div>
                      <div class="item" routerLink="lease/lease-list/list">
                        Lease</div>
                    </div>
                  </div>
                  <div class="item" routerLink="/historys/recent-activity"
                    *ngIf="!auth.userSupport(user) && !auth.isCommunityManage(user)">

                    Actividad Reciente
                  </div>
                  <div class="divider" *ngIf="!auth.userSupport(user) && !auth.isCommunityManage(user)"></div>
                  <div class="item" (click)="support()"
                    *ngIf="!auth.userSupport(user) && !auth.isCommunityManage(user)">
                    Soporte
                  </div>
                  <div class="item" (click)="logout()">Cerrar Sesión</div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <router-outlet></router-outlet>

    <!-- <app-chat-bubble *ngIf="user &&
        router.url !== '/login' &&
        router.url !== '/register' &&
        router.url !== '/verification-email' &&
        router.url !== '/forgot-password' &&
        router.url !== '/welcome' &&
        router.url !== '/support'
      && !auth.isCommunityManage(user)">
    </app-chat-bubble> -->

  </div>
  <div class="tarjeta " *ngIf="showCard">
    <!-- <div class="closeChat" (click)="hideCard()">
      <button class="closeIcon circular ui icon button">
        <i class="icon close"></i>
      </button>

    </div> -->

    <div class="form1 " *ngIf="form1">
      <form [formGroup]="affairForm" (ngSubmit)="waiting()">
        <header>
          <h6>Chat de Soporte</h6>
        </header>
        <input type="text" [ngClass]="{invalid: affair.invalid && affair.dirty}" formControlName="affair"
          placeholder="Asunto..." class="form-control border-0" />
        <textarea placeholder="Descripción..." [ngClass]="{invalid: description.invalid && description.dirty}" rows="7"
          formControlName="description" class="form-control border-0">
          </textarea>
        <button class="btn" [disabled]="affairForm.invalid">Enviar</button>
      </form>

    </div>

    <!-- form2 -->
    <div class="form2" *ngIf="form2">
      <form [formGroup]="messageForm" (ngSubmit)="sendMessage()">
        <div class="box-msg">
          <div [ngClass]="{
                local: message.messageType === 1,
                remoto: message.messageType === 2
              }" *ngFor="let message of chat.b_chats">
            <span>{{ message.text }}</span>
          </div>
        </div>
        <div class="input-msg">
          <input type="text" class="form-control" formControlName="message" />
          <button class="btn" [disabled]="message.invalid">
            Enviar
          </button>
        </div>
      </form>
    </div>

    <!-- form3 -->
    <div class="form3" *ngIf="form3">
      En un momento lo atenderán
      <br>
      <div class="text-center">
        {{ minutesDisplay }}:{{ secondsDisplay && secondsDisplay <= 59 ? secondsDisplay : "00" }} </div>
      </div>

      <!-- form4 -->
      <div class="form4" *ngIf="form4">
        Por el momento, ningun usuario de soporte está disponible, le notificaremos
        cuando se tome el asunto.
        <button class="ui button mt-3" (click)="backChat()">Regresar</button>
      </div>

      <!-- form5 -->
      <div class="form5" *ngIf="form5">
        {{textFnChat}}
        <button class="ui button mt-3" (click)="backChat()">Regresar</button>
      </div>
      <a *ngIf="!form2" style="padding: 3px; color: rgb(73, 73, 73);" (click)="closeChat()">Cerrar</a>

    </div>
  </div>

  <ng-template #noShowBar>
    <div>
      <router-outlet></router-outlet>

      <!-- <app-chat-bubble *ngIf="
      router.url !== '/login' &&
      router.url !== '/register' &&
      router.url !== '/verification-email' &&
      router.url !== '/forgot-password' &&
      router.url !== '/terms-and-conditions' &&
      router.url !== '/privacy-policy' &&
      router.url !== '/welcome' &&
      router.url !== '/support'
    ">
      </app-chat-bubble> -->
    </div>
  </ng-template>



  <!-- // "node_modules/bootstrap/dist/css/bootstrap.css",
// "node_modules/jquery/dist/jquery.min.js",
// "node_modules/popper.js/dist/umd/popper.js",
// "node_modules/bootstrap/dist/js/bootstrap.js", -->