import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/SERVICIOS/auth.service';
import { NotificationsService } from 'src/app/SERVICIOS/socket/notifications.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/SERVICIOS/notification.service';

@Component({
  selector: 'app-gestiona',
  templateUrl: './gestiona.component.html',
  styleUrls: ['./gestiona.component.scss'],
  
 
})
export class GestionaComponent implements OnInit {
  public notifications=[]
 

  mostrarBloques = false;





  constructor( public notification:NotificationsService,public router:Router, public gonotification:NotificationService,private authsv:AuthService) {
    //slocalStorage.clear()
   }


   goNotification(notification:any){
    this.gonotification.goNotification(notification)
  }
  
  async onLogout() {
    try {
      await this.authsv.logout();
      this.router.navigate(['/login']);
    } catch (error) {
      console.log(error);
    }
  }
  

  ngOnInit(): void {
  }






  






}
