<!-- 

<div class="main" *ngIf="uniqueArray">
  <h2 class="title mt-4">Sale</h2>

  <div class="subtitle mb-3 d-flex justify-content-between">
    <h5 *ngIf="auth.userAdmin(user)">Mis Sale</h5>
    <div></div>
    <div></div>
  </div>

  <div class="box-select-filter">
    <div class="row mb">
      <div class="col">
        <div class="form-outline">
          <select class="form-select" (change)="filterWithSelectu($event.target.value)">
            <option [selected]="false">Selecciona una opción</option>
            <option [selected]="valueNameu == 'nombre'" class="large" value="3">Nombre del cliente</option>
            <option [selected]="valueNameu == 'id_cliente' " value="2">ID de cliente</option>
            <option [selected]="valueNameu == 'id_article'" value="5">ID de artículo</option>
            <option [selected]="valueNameu == 'uid_user'" value="4">ID de usuario</option>
            <option [selected]="valueNameu == 'habilitado'" value="6">Habilitados</option>
            <option [selected]="valueNameu == 'deshabilitado'" value="7">Deshabilitados </option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="form-outline">
          <input [disabled]="disabledInputu" (input)="getTextu($event.target.value)" class="form-control"
            placeholder="ingrese el valor" name="filterPostu" [(ngModel)]="filterPostu" [value]="text_inputu" />
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="uniqueArray">
    <div class="grid-incomes mt-5">
      <div
        *ngFor="let todo of uniqueArray | paginate: { itemsPerPage: 6, currentPage: m_p,  totalItems:uniqueArray.length  }; let i = index"
        class="card card-income ">
        <h3>{{ todo.cliente.nombre | titlecase }}</h3>
        <p>{{ todo.id}}</p>
        <p style="font-weight: bold;">{{
          todo.positive.payConfirmationDate | date:'dd-MM-yyyy'}}</p>



        <div>
          <div class="ui animated button" tabindex="0" (click)='fullview(todo)'>
            <div class="visible content">Ver</div>
            <div class="hidden content">
              <i class="right arrow icon"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center mt-4 mb-5" *ngIf="uniqueArray !== undefined && uniqueArray.length >= 6">
    <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" (pageChange)="changePageu($event)"
      class="float-right"></pagination-controls>
  </div>

  <div *ngIf="uniqueArray.length == 0">
    <p class="text-center mt-2">No hay datos por el momento</p>
  </div> -->
<div class="main">
  <h2 class="title mt-4">Sale</h2>

  <div class="subtitle mb-3 d-flex justify-content-between">
    <h5 *ngIf="auth.userAdmin(user)">Mis sale</h5>
    <div></div>
    <div></div>
  </div>

  <div class="box-select-filter">
    <div class="row mb">
      <div class="col">
        <div class="form-outline">
          <select class="form-select" (change)="filterWithSelectu($event.target.value)">
            <option [selected]="false">Selecciona una opción</option>
            <option [selected]="valueNameu == 'nombre'" class="large" value="3">Nombre del cliente</option>
            <option [selected]="valueNameu == 'id_cliente' " value="2">ID de cliente</option>
            <option [selected]="valueNameu == 'id_article'" value="5">ID de artículo</option>
            <option [selected]="valueNameu == 'uid_user'" value="4">ID de usuario</option>
            <option [selected]="valueNameu == 'habilitado'" value="6">Habilitados</option>
            <option [selected]="valueNameu == 'deshabilitado'" value="7">Deshabilitados </option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="form-outline">
          <input [disabled]="disabledInputu" (input)="getTextu($event.target.value)" class="form-control"
            placeholder="ingrese el valor" name="filterPostu" [(ngModel)]="filterPostu" [value]="text_inputu" />
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="uniqueArray">
    <div class="grid-incomes mt-5">
      <div
        *ngFor="let todo of uniqueArray | paginate: { itemsPerPage: 6, currentPage: m_p,  totalItems:uniqueArray.length  }; let i = index"
        class="card card-income ">
        <h3>{{ todo.cliente.nombre | titlecase }}</h3>
        <p>{{ todo.id}}</p>
        <p style="font-weight: bold;">{{
          todo.positive.payConfirmationDate | date:'dd-MM-yyyy'}}</p>



        <div>
          <div class="ui animated button" tabindex="0" (click)='fullview(todo)'>
            <div class="visible content">Ver</div>
            <div class="hidden content">
              <i class="right arrow icon"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center mt-4 mb-5" *ngIf="uniqueArray !== undefined && uniqueArray.length >= 6">
    <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" (pageChange)="changePageu($event)"
      class="float-right"></pagination-controls>
  </div>

  <div *ngIf="uniqueArray.length == 0">
    <p class="text-center mt-2">No hay datos por el momento</p>
  </div>




  <!-- <div class="subtitle mb-3 mt-5" *ngIf="auth.canAdmin(user)">
        <h5>Ingresos de otros usuarios</h5>
    </div>
    <div class="box-select-filter" *ngIf="auth.canAdmin(user)">
        <div class="row mb">
            <div class="col">
                <div class="form-outline">
                    <select class="form-select" (change)="filterWithSelect($event.target.value)">
                        <option [selected]="false">Selecciona una opción</option>
                        <option [selected]="valueName == 'nombre'" class="large" value="3">Nombre del cliente</option>
                        <option [selected]="valueName == 'id_cliente' " value="2">ID de cliente</option>
                        <option [selected]="valueName == 'id_article'" value="5">ID de artículo</option>
                        <option [selected]="valueName == 'uid_user'" value="4">ID de usuario</option>
                        <option [selected]="valueName == 'habilitado'" value="6">Habilitados</option>
                        <option [selected]="valueName == 'deshabilitado'" value="7">Deshabilitados </option>
                    </select>
                </div>
            </div>
            <div class="col">
                <div class="form-outline">
                    <input [disabled]="disabledInput" (input)="getText($event.target.value)" class="form-control"
                        placeholder="ingrese el valor" name="filterPost" [(ngModel)]="filterPost"
                        [value]="text_input" />
                </div>
            </div>
        </div>
    </div> -->
  <!--
    <div *ngIf="auth.canAdmin(user)">
        <div *ngIf="( customer | income: filterPost:valueName) as incomes" class="grid-incomes mt-5">
            <div *ngFor="let todo of incomes | paginate: { itemsPerPage: 6, currentPage: p,  totalItems: incomes.length }"
                class="card card-income ">
                <h3>{{ todo.cliente.nombre | titlecase }} {{ todo.cliente.apellido | titlecase }}</h3>
                <p>{{ todo.id_cliente }}</p>
                <p style="font-weight: bold;"
                    [ngClass]="{'green ':todo.estatus == 'habilitado','red':todo.estatus == 'deshabilitado' }">{{
                    todo.estatus |
                    titlecase }}</p>

                <div style="display: flex;justify-content: space-around;">
                    <div style="display: flex;flex-direction: column;
              align-items: center;">
                        <th class="text-center" style="font-weight: bold;">Articulos</th>
                        <p>{{getTotalArticles(todo)}}</p>
                    </div>
                    <div style="display: flex;flex-direction: column;
              align-items: center;">
                        <th class="text-center" style="font-weight: bold;">Productos aplicados</th>
                        <p>{{getProductsArticles(todo)}}</p>
                    </div>
                </div>

                <div class="mt-3" style="display: flex;justify-content: space-between;"
                    *ngIf="todo.estatus !== 'incompleto'">

                    <div *ngIf="auth.canAdmin(user)">
                        <div *ngIf="todo.aprobado && todo.estatus == 'habilitado' && auth.userFullAdmin(user)">
                            <button class="ui red button" (click)="disable(todo)">
                                Deshabilitar
                            </button>
                        </div>
                        <div *ngIf="todo.aprobado && todo.estatus == 'deshabilitado' && auth.userFullAdmin(user)">
                            <button class="ui green button" (click)="enable(todo)">
                                Habilitar
                            </button>
                        </div>
                    </div>
                    <div class="ui animated button" tabindex="0" (click)='fullView(todo)'>
                        <div class="visible content">Ver</div>
                        <div class="hidden content">
                            <i class="right arrow icon"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->




  <!-- <div class="text-center mt-4 mb-5" *ngIf="customer.length > 5">
        <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" (pageChange)="changePage($event)"
            class="float-right"></pagination-controls>
    </div>
    <div class="text-center mt-4 mb-5"
        *ngIf="(auth.userAdmin(user)|| auth.userFullAdmin(user)) && customer.length == 0">
        <p>No hay ingresos por el momento</p>
    </div> -->
</div>





<!-- MODALES -->
<!--
<div class="ui basic modal disable" style="width: 90%; margin-left: 5.15%; margin-top: 25vh;">
    <div class="ui icon header text-center">
        <i class="exclamation icon" *ngIf="modal1"></i>
        <p *ngIf="modal1">Deshabilitar Ingreso</p>
        <p *ngIf="modal2">¿Por qué deshabilitas el ingreso?</p>
    </div>
    <div class="content text-center">
        <p *ngIf="modal1">¿Estás seguro de deshabilitar este ingreso?</p>
        <form [formGroup]="argumentForm" (ngSubmit)="onArg()" *ngIf="modal2">
            <div class="row mb">
                <textarea *ngIf="modal2" formControlName="argument"
                    [ngClass]="{invalid:argumento.invalid && argumento.dirty}" class="form-control" name="argument"
                    rows="4"></textarea>
            </div>
        </form>
    </div>
    <div class="actions" style="display: flex; justify-content: center;" *ngIf="modal1">
        <div class="ui red basic cancel inverted button" (click)="hideModalDisableIcome()">
            <i class="remove icon"></i>
            No
        </div>
        <div class="ui green ok inverted button" (click)="showModalArgument()">
            <i class="checkmark icon"></i>
            Sí
        </div>
    </div>
    <div class="actions" style="display: flex; justify-content: center;" *ngIf="modal2">
        <div>

        </div>
        <div class="ui green ok inverted button" (click)='onArg()'>
            Enviar
        </div>
    </div>
</div> -->