
import { Directive, EventEmitter, ElementRef, HostListener, Output, Input } from '@angular/core';
import { Fileitem } from 'src/app/COMERCIAL/MODULOS/ingresa/file';

@Directive({
  selector: '[appFiles]'
})
export class FilesDirective {


  @Input() imagenes = []
  @Output() mouseSobre: EventEmitter<boolean> = new EventEmitter();
  url: string | ArrayBuffer;


  constructor() { }

  @HostListener('dragover', ['$event'])
  public onDragEnter(event: any) {
    this.mouseSobre.emit(true);
    this._prevenirDetener(event);

  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: any) {
    this.mouseSobre.emit(false);

  }

  @HostListener('drop', ['$event'])
  public onDrop(event: any) {
    this.mouseSobre.emit(false);

    const transferencia = this._getTransferencia(event);


    if (!transferencia) {
      return;
    }

    this.getUrl(transferencia.files)

    this._prevenirDetener(event);
    this.mouseSobre.emit(false);

  }


  getUrl(file: FileList) {
    let reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = (event) => {
      this.url = (<FileReader>event.target).result;
      this._extraerArchivos(file, this.url);

    }
  }
  private _getTransferencia(event: any) {
    return event.dataTransfer ? event.dataTransfer : event.originalEvent.dataTransfer;
  }

  private _extraerArchivos(archivosLista: FileList, url) {

    for (const propiedad in Object.getOwnPropertyNames(archivosLista)) {

      const archivoTemporal = archivosLista[propiedad];

      if (this._archivoPuedeSerCargado(archivoTemporal)) {

        const nuevoArchivo = new Fileitem(archivoTemporal);
        let imagenes = {
          imagen: nuevoArchivo,
          url
        }
        this.imagenes.push(imagenes)
      }
    }


  }

  //Validaciones
  private _archivoPuedeSerCargado(archivo: File): boolean {

    if (!this._archivoYaFueDroppeado(archivo.name) && this._esImagen(archivo.type)) {
      return true;
    } else {
      return false;
    }

  }


  private _prevenirDetener(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  private _archivoYaFueDroppeado(nombreArchivo: string): boolean {

    for (const archivo of this.imagenes) {

      if (archivo.imagen.nombreArchivo === nombreArchivo) {
        return true;
      }

    }

    return false;
  }

  private _esImagen(tipoArchivo: string): boolean {
    return (tipoArchivo === '' || tipoArchivo === undefined) ? false : tipoArchivo.startsWith('image');
  }


}
