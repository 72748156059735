import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lead-and-support',
  templateUrl: './lead-and-support.component.html',
  styleUrls: ['./lead-and-support.component.scss']
})
export class LeadAndSupportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
