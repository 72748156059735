import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AuthService } from '../auth.service';


@Injectable({
  providedIn: 'root'
})
export class CanEnterGuard implements CanActivate {
  constructor(private auth: AuthService, private router:Router) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    return this.auth.user$.pipe(
      take(1),
      map(user => user && ((this.auth.isClassic(user) || this.auth.isAdmin(user) || this.auth.isFullAdmin) && (user.status == "enabled" ))),
      tap(isEnter => {
        if (!isEnter) {
          this.router.navigate(['welcome'])
        }
      })
    );

  }
  
}
