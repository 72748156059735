import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-identificacion',
  templateUrl: './identificacion.component.html',
  styleUrls: ['./identificacion.component.scss']
})
export class IdentificacionComponent {
  public valorSeleccionado: string;
  id: string;
  constructor(private router: Router, private route: ActivatedRoute, private afs: AngularFirestore) {
    this.id = this.route.snapshot.paramMap.get('idCustomer')
  }
  /*opcion_uno(){
    this.router.navigate(['/ingresa/subir-foto']);
  }
  opcion_dos(){}
  */

  goToINE() {
    this.afs.collection('CUSTOMER').doc(this.id).set({ identificacion: 'INE' }, { merge: true });
    this.router.navigate(['ingresa', this.id, 'subir-foto']);

  }

  aditional() {
    this.afs.collection('CUSTOMER').doc(this.id).set({ identificacion: 'Documentos Adicionales' }, { merge: true });
    this.router.navigate(['ingresa', this.id, 'subir-foto']);

  }


}
