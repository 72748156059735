import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/SERVICIOS/auth.service";
import { take } from "rxjs/operators";
import { Subscription } from "rxjs";
import { PdfService } from "src/app/SERVICIOS/pdf.service";
import * as moment from "moment";
import { GetDataService } from "src/app/SERVICIOS/get-data.service";
import firebase from "firebase/compat/app";
import { GetTokensService } from "src/app/SERVICIOS/get-tokens.service";
import { SocketService } from "src/app/SERVICIOS/socket/socket.service";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { GenerateIdService } from "src/app/SERVICIOS/generate-id.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DataDbService } from "src/app/SERVICIOS/data-db.service";
import { User } from "../../MODULOS/user.interface";
import { RecentActivityInterface } from "src/app/interfaces/activity";
import { GenerateRecentActivityService } from "src/app/SERVICIOS/recent-activity.service";
import { Location } from "@angular/common";

declare let $: any;

@Component({
  selector: "app-advance-article-information",
  templateUrl: "./advance-article-information.component.html",
  styleUrls: ["./advance-article-information.component.scss"],
})
export class AdvanceArticleInformationComponent implements OnInit {
  @ViewChild("myModal")
  myModal;
  @ViewChild("modal_update")
  modal_update;
  @ViewChild("modal_observation")
  modal_observation;
  @ViewChild("modalCancelProduct")
  modalCancelProduct;

  user: User;
  public article: any;
  assignedProducts: Array<any> = [];
  public customer: any;
  public agent: any;
  allArticleComments: any;
  disabled_article: boolean = false;
  rejected_article: boolean = false;
  cuarentena = [];
  idA: string;
  subs: Subscription;
  subsC: Subscription;
  subsAg: Subscription;
  days: number = 0;
  diff: number = 0;
  articleComment: string;
  due_date: any;
  dateRenovation: any;
  myArticle: boolean = false;
  idC: string;
  usersEncontrados: any[];
  id_documentSelected: any;
  showDropDown: boolean = false;
  productSelected: string;
  acceptCancelProduct: boolean = false;
  showMessage: boolean = false;
  titleMessage: string;
  bodyMessage: string;
  dataCancellation: any;
  isrejectCancellProduct: boolean = false;
  get argumento() {
    return this.argumentForm.get("argument");
  }

  argumentForm = new UntypedFormGroup({
    argument: new UntypedFormControl("", [
      Validators.required,
      Validators.maxLength(1000),
    ]),
  });
  constructor(
    public afs: AngularFirestore,
    private router: Router,
    private route: ActivatedRoute,
    private generatePdf: PdfService,
    public auth: AuthService,
    private generateId: GenerateIdService,
    private get_data: GetDataService,
    private tokensUser: GetTokensService,
    private socket: SocketService,
    private dbData: DataDbService,
    public modal: NgbModal,
    private gra: GenerateRecentActivityService,
    private _location: Location,
  ) { }
  observationForm = new UntypedFormGroup({
    observation: new UntypedFormControl("", [
      Validators.required,
      Validators.maxLength(1000),
    ]),
  });
  createAnObservation() {
    $(".ui.basic.modal.createObservation").modal("show");
  }
  ngOnInit(): void {
    this.idA = this.route.snapshot.paramMap.get("idArticle");

    this.auth.user$
      .pipe(take(1))
      .toPromise()
      .then((user: User) => {
        this.user = user;
        this.get_data.getArticles(user.uid).then((results: any[]) => {
          let found = ([] = results.filter(
            (article) => article.id_document == this.idA,
          ));
          if (found.length == 0) {
            this.myArticle = false;
          } else if (found.length > 0) {
            this.myArticle = true;
          }
        });
      });
    this.getArticle();
  }

  ShowMessage(title: string, body: string) {
    this.showMessage = true;
    this.titleMessage = title;
    this.bodyMessage = body;
    setTimeout(() => {
      this.closeMessage();
    }, 8000);
  }
  closeMessage() {
    this.showMessage = false;
    this.titleMessage = "";
    this.bodyMessage = "";
  }
  getArticle() {
    this.subs = this.afs
      .collection("ARTICLE")
      .doc(this.idA)
      .valueChanges()
      .subscribe((values: any) => {
        this.article = values;
        if (this.article.commodity.requestCancellation) {
          this.getRequestCancellation(this.article);
        }
        this.getCustomer(values.id_document_customer);
        this.getAssignedProducts();

        if (this.article.commodity.producto == "no asignado") {
        } else {
          this.getDates(this.article);
        }
      });
  }
  getRequestCancellation(article) {
    this.afs
      .collection("ARTICLE")
      .doc(this.idA)
      .collection(
        "COMMODITY-HISTORY",
        (ref) =>
          ref.where("idCancellation", "==", article.commodity.idCancellation),
      )
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((docs: any) => {
        this.dataCancellation = docs[0];
      });
  }

  event(){
    this.socket.io.emit('add-product')
  }
  getAssignedProducts() {
    this.afs
      .collection("ARTICLE")
      .doc(this.idA)
      .collection("COMMODITY-HISTORY")
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((docs) => {
        for (const commodity of docs) {
          if (commodity.accepted) {
            this.assignedProducts.push(commodity);
            //quitar los repetidos
           
          }
        }
      });
  }
  getCustomer(id_doc: string) {
    this.subsC = this.afs
      .collection("CUSTOMER")
      .doc(id_doc)
      .valueChanges()
      .subscribe((values: any) => {
        this.customer = values;
        this.idC = values.id_document;
        this.getAgent(values.uid_user);
      });
  }
  getAgent(uid: string) {
    this.subsAg = this.afs
      .collection("USERS")
      .doc(uid)
      .valueChanges()
      .subscribe((values: any) => {
        this.agent = values;
      });
  }
  viewPDF() {
    this.generatePdf.generatePdf(this.customer, this.article);
  }
  back() {
    //REGRESAR A LA PAGINA ANTERIOR
    this._location.back();
  }
  editArticle(articulo: { id_document: any }) {
    this.router.navigate([
      "ingresa",
      this.customer.id_document,
      "articulo",
      articulo.id_document,
      "articulos",
      "edit",
    ]);
  }
  fullview(articulo) {
    this.router.navigate([`registra/${articulo.id_document}/item-view`]);
  }

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  getDays(name) {
    if (name == "gold99" || name == "Gold99") {
      return 99;
    } else if (name == "elite99" || name == "Elite99") {
      return 369;
    } else if (name == "diamond99" || name == "Diamond99") {
      return 189;
    } else if (name == "imperial99" || name == "Imperial99") {
      return 449;
    }
  }

  updateDates(product) {
    let date_product = new Date(this.article.commodity.start);
    let name_product = product;
    let days = this.getDays(name_product);
    return moment(this.addDays(date_product, days)).format();
  }
  getDates(article) {
    let date_product = new Date(article.commodity.start);
    let finish = new Date(article.commodity.finish);
    let name_product = article.commodity.producto;
    let days = this.getDays(name_product);

    let new_date = this.addDays(date_product, days);


    let setDateRenovation = this.addDays(finish, 39);
    
    this.dateRenovation = moment(setDateRenovation).format("DD-MM-YYYY"); //fecha de vencimiento
    
    
    this.due_date = moment(new_date).format("DD-MM-YYYY"); //fecha de vencimiento

    //hoy es 24 de mayo

    let current_date = moment();
    let dateProduct = moment(new_date);

    this.diff = dateProduct.diff(current_date, "days"); // me da lo dias que quedan
    this.diff <= 0 ? (this.diff = 0) : this.diff;


  }

  changeCommodity(product: string) {
    this.showDropDown = true;
  }

  selectProduct(product: string) { }
  updateProduct() {
    this.afs
      .collection("ARTICLE")
      .doc(this.idA)
      .set(
        {
          commodity: {
            duration: this.productSelected == "gold99"
              ? 99
              : this.productSelected == "elite99"
                ? 369
                : this.productSelected == "diamond99"
                  ? 189
                  : this.productSelected == "imperial99"
                    ? 449
                    : 0,
            producto: this.productSelected,
            totalProductPeriod: 0,
            finish: this.updateDates(this.productSelected),
          },
        },
        { merge: true },
      )
      .then((e) => {
        this.showDropDown = false;
        this.ShowMessage(
          "Se ha actualizado el producto con éxito",
          "El producto se ha cambiado a " + this.productSelected,
        );
      });
    // this.afs.collection('ARTICLE').doc(this.idA).valueChanges().pipe(take(1)).toPromise().then((article: any) => {
    //   let commodity = article.commodity
    //   // let history = {
    //   //   duration: commodity.duration,
    //   //   product
    //   // }
    //   this.afs.collection('ARTICLE').doc(this.idA).update({
    //     'commodity': {
    //       duration: this.productSelected == 'gold99' ? 99 : this.productSelected == 'elite99' ? 369 : this.productSelected == 'diamond99' ? 189 : this.productSelected == 'imperial99' ? 449 : 0,
    //       producto: this.productSelected,
    //     }
    //   })
    // })
  }

  updateDateStart() {
    if (
      this.article.commodity.start == undefined ||
      this.article.commodity.start == null
    ) {
      console.error("no es posible hacer eso ahora");
    } else {
      this.afs
        .collection("ARTICLE")
        .doc(this.idA)
        .set({ commodity: { start: moment().format() } }, { merge: true })
        .then((e) => {
          this.ShowMessage(
            "Se ha actualizado la fecha con éxito",
            "La fecha se a actualizado a la actual",
          );
        });
    }
  }

  disapprove({ id_document }) {
    this.id_documentSelected = id_document;
    this.showModalDisableArticle();
  }
  showModalDisableArticle() {
    $(".ui.basic.modal.one").modal("show");
  }
  async disapproveArticle() {
    if (this.argumentForm.value.argument !== "") {
      let id_document = this.id_documentSelected;
      let observations = firebase.firestore.FieldValue.arrayUnion(
        this.argumentForm.value.argument,
      );
      this.afs.collection("ARTICLE").doc(id_document).update({
        estatus: "denegado",
        argumento: this.argumentForm.value.argument,
        observations,
      });
      var tokens = await this.tokensUser.getTokens(this.article.uid_user);

      let obj = {
        uid: this.customer.uid_user,
        id_cliente: this.idC,
        tokens: tokens,
      };
      this.socket.io.emit("article-disapprove", obj);

      this.hideModalNegative();
      this.argumentForm.reset();
    }
  }

  async approve(article: any) {
    if (article.estatus == "denegado") {
      this.afs
        .collection("ARTICLE")
        .doc(article.id_document)
        .update({ estatus: "habilitado", argumento: "No hay observaciones" });
    } else if (article.estatus == "en espera") {
      let articulosPorAprobar = firebase.firestore.FieldValue.increment(-1);
      let articulosActivos = firebase.firestore.FieldValue.increment(1);
      this.afs
        .collection("CUSTOMER")
        .doc(this.idC)
        .update({ articulosActivos, articulosPorAprobar });
      this.afs.collection("ARTICLE").doc(article.id_document).update({
        estatus: "habilitado",
        argumento: "No hay observaciones",
      });
    } else {
      let articulosActivos = firebase.firestore.FieldValue.increment(1);
      this.afs.collection("ARTICLE").doc(article.id_document).update({
        estatus: "habilitado",
        argumento: "No hay observaciones",
      });
      this.afs
        .collection("CUSTOMER")
        .doc(this.idC)
        .update({ articulosActivos });
    }
    var tokens = await this.tokensUser.getTokens(this.customer.uid_user);

    let obj = {
      uid: this.customer.uid_user,
      id_cliente: this.idC,
      id_articulo: article.id_document,
      tokens: tokens,
    };
    this.socket.io.emit("article-approve", obj);

    let features = article.caracteristicas;
    let id_articulo = article.id_document;
    this.articleCuarentine(features, id_articulo);
  }
  cancellProduct({ id_document }) {
    this.id_documentSelected = id_document;
    this.showModalinableArticle();
  }
  showModalinableArticle() {
    $(".ui.basic.modal.two").modal("show");
  }
  showArgument() {
    this.disabled_article = true;
  }
  showModalArgument() {
    this.rejected_article = true;
  }
  showModalArgumentCancell() {
    this.acceptCancelProduct = true;
  }
  showModalArgumentRejectCancell() {
    this.isrejectCancellProduct = true;
  }

  async rejectTheCancell() {
    if (this.argumentForm.value.argument !== "") {
      let id_document = this.idA;
      var tokens = await this.tokensUser.getTokens(this.customer.uid_user);

      let obj = {
        uid: this.customer.uid_user,
        id_cliente: this.idC,
        tokens: tokens,
        id_document: this.idA,
      };
      this.socket.io.emit("reject-product-cancell", obj);
      let observations = firebase.firestore.FieldValue.arrayUnion(
        this.argumentForm.value.argument,
      );
      this.afs
        .collection("ARTICLE")
        .doc(id_document)
        .set(
          {
            commodity: {
              requestCancellation: false,
              observation: this.argumentForm.value.argument,
              observations,
            },
            observations,
          },
          { merge: true },
        )
        .then(async (e) => {
          this.afs
            .collection("ARTICLE")
            .doc(id_document)
            .collection("COMMODITY-HISTORY")
            .doc(this.dataCancellation.id_document)
            .update({
              argumento: this.argumentForm.value.argument,
              estatus: "rechazado",
              observations,
            });
        })
        .then((e: any) => {
          this.argumentForm.reset();
        });
    }
  }

  async setCancellProduct() {
    if (this.argumentForm.value.argument !== "") {
      let id_document = this.idA;
      var tokens = await this.tokensUser.getTokens(this.customer.uid_user);

      let notification = {
        uid: this.customer.uid_user,
        id_document,

        tokens: tokens,
      };

      this.socket.io.emit("product-cancellation", notification);
      let observations = firebase.firestore.FieldValue.arrayUnion(
        this.argumentForm.value.argument,
      );
      this.afs
        .collection("ARTICLE")
        .doc(id_document)
        .set(
          {
            commodity: {
              estatus: "cancelado",
              observation: this.argumentForm.value.argument,
              observations,
            },
            observations,
          },
          { merge: true },
        )
        .then((e) => {
          let id = Number(this.generateId.createId());
          let commodity = {
            duration: this.article.commodity.duration,
            totalProductPeriod: 0,
            autoRenovation: this.article.commodity.autoRenovation,
            product: this.article.commodity.producto,
            assigned: this.article.commodity.fecha,
            start: this.article.commodity.start,
            ending: this.article.commodity.finish,
          };
          this.afs
            .collection("ARTICLE")
            .doc(id_document)
            .collection("COMMODITY-HISTORY")
            .add({
              idCancellation: id,
              id_document: "",
              dateCancellation: "undefined",
              requestCancellation: true,
              dateRequest: moment().format(),
              accepted: false,
              estatus: "en espera",
              argumento: this.argumentForm.value.argument,
              commodity,
              observations,
            })
            .then((e) => {
              let idD = e.id;
              this.afs
                .collection("ARTICLE")
                .doc(id_document)
                .set(
                  {
                    commodity: {
                      idCancellation: id,
                    },
                  },
                  { merge: true },
                )
                .then((e: any) => {
                  this.afs
                    .collection("ARTICLE")
                    .doc(id_document)
                    .collection("COMMODITY-HISTORY")
                    .doc(idD)
                    .update({ id_document: idD, estatus: "aprobado" });
                })
                .then((e: any) => {
                  this.approveCancellation();
                  this.argumentForm.reset();
                });
            });
        });
      var tokens = await this.tokensUser.getTokens(this.article.uid_user);

      let obj = {
        uid: this.article.uid_user,
        id_cliente: this.idC,
        tokens: tokens,
      };
    }
    // if (this.argumentForm.value.argument !== '') {
    //   let id_document = this.id_documentSelected
    //   this.afs.collection('ARTICLE').doc(id_document).update({ estatus: 'inhabilitado', argumento: this.argumentForm.value.argument });
    //   let articulosInactivos = firebase.firestore.FieldValue.increment(1);
    //   let articulosActivos = firebase.firestore.FieldValue.increment(-1);
    //   this.afs.collection('CUSTOMER').doc(this.idC).update({ articulosInactivos, articulosActivos });
    //   var tokens = await this.tokensUser.getTokens(this.customer.uid_user);
    //   let obj = {
    //     uid: this.customer.uid_user,
    //     id_cliente: this.idC,
    //     tokens
    //   }
    //   this.socket.io.emit('article-disable', obj)

    //   this.hideModalNegative()
    //   this.argumentForm.reset();
    // }
  }
  async approveProduct(article) {
    let id_document = article.id_document;
    this.afs
      .collection("ARTICLE")
      .doc(id_document)
      .set(
        {
          commodity: {
            estatus: "aprobado",
          },
        },
        { merge: true },
      );
    var tokens = await this.tokensUser.getTokens(this.customer.uid_user);
    let notification = {
      uid: this.customer.uid_user,
      id_document: article.id_document,
      tokens: tokens,
    };
    //socket
    this.socket.io.emit("approve-product", notification);
    let setRecentActivity = {
      date: moment().format(),
      homeTitle: "1 producto ha sido aprobado",
      generalTitle:
        "¡Felicidades! tienes 1 producto aprobado, vaya al actividad para más información",
      responses: false,
      description: "El producto aplicado en el artículo de " +
        this.customer.cliente.nombre + " " + this.customer.cliente.apellido +
        " ahora está aprobado, puede ver más información llendo al lugar de actividad",
      id: this.generateId.createId(),
      archived: false,
      from: "clientes",
      subtitle: "Producto aplicado",
      id_document_component: this.customer.id_document,
      important: false,
      routeObservation: this.router.url,
      isError: false,

      view: false,
      uid_user: this.customer.uid_user,
      type: "activity",
      canUploadData: false,
      canReply: false,
      isFullview: true,
    };
    this.gra.GenerateRecentActivity(setRecentActivity, this.customer.uid_user);
  }

  get observation() {
    return this.observationForm.get("observation");
  }
  sendObservation() {
    let observation = this.observationForm.value.observation;
    let setRecentActivity = {
      date: moment().format(),
      homeTitle: "Tienes una nueva observación en productos",
      generalTitle:
        "Tienes una nueva observación en productos, !ve a verla ahora! Para más información",
      responses: true,
      description: observation,
      canReply: true,
      subtitle: "el producto aplicado",
      id: this.generateId.createId(),
      archived: false,
      important: false,
      id_document_component: this.idA,
      //routeObservation: "historys/ingresa-history/" + this.idA + "/advance",
      isError: false,
      canUploadData: true,
      view: false,
      uid_user: this.customer.uid_user,
      type: "observation",
      from: "productos",
      isFullview: true,
      uid_fullAdmin: this.user.uid,
    };

    this.socket.io.emit("recent-activity", {
      recentActivity: setRecentActivity,
      uid: this.customer.uid_user,
      idFullAdmin: this.user.uid,
    });

    // arrayUnion

    $(".ui.basic.modal.createObservation").modal("hide");

    this.afs.collection("ARTICLE").doc(this.idA).set({
      observation: true,
    }, { merge: true });
  }

  cleanProduct() {
    this.afs
      .collection("ARTICLE")
      .doc(this.idA)
      .set(
        {
          commodity: {
            requestCancellation: false,
            duration: 0,
            producto: "no asignado",
            autoRenovation: 0,
            estatus: "cancelado",
            dateCancellation: moment().format(),
            totalProductPeriod: 0,
          },
        },
        { merge: true },
      );
  }

  async enable({ id_document }) {
    this.afs
      .collection("ARTICLE")
      .doc(id_document)
      .update({ estatus: "habilitado", argumento: "No hay observaciones" });

    var tokens = await this.tokensUser.getTokens(this.customer.uid_user);

    let obj = {
      uid: this.customer.uid_user,
      id_document: this.idA,
      tokens,
    };
    this.socket.io.emit("article-enable", obj);
  }
  hideModalNegative() {
    $(".ui.basic.modal.one").modal("hide");
    $(".ui.basic.modal.two").modal("hide");
    $(".ui.basic.modal.cancel.product").modal("hide");
    $(".ui.basic.modal.reject.cancell.product").modal("hide");
    this.disabled_article = false;
    this.rejected_article = false;
    this.acceptCancelProduct = false;
    this.isrejectCancellProduct = false;
  }
  articleCuarentine(feature, id_article) {
    let features = {
      caracteristicas: feature,
    };
    this.afs
      .collection("LEAD", (ref) => ref.where("cuarentena", "==", true))
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then(async (doc: any) => {
        this.cuarentena = doc;

        this.usersEncontrados = this.cuarentena.filter((userEncontrado) => {
          let encontrado = false;
          let contador = 0;
          if (userEncontrado.tagsToSearch) {
            for (const i of userEncontrado.tagsToSearch) {
              for (const selectedValueToSearch of features.caracteristicas) {
                if (
                  i.id == selectedValueToSearch.id &&
                  i.seleccionado === selectedValueToSearch.seleccionado
                ) {
                  contador++;
                  if (contador >= 1) {
                    encontrado = true;
                  }
                }
              }
            }
            userEncontrado.contador = contador;
            userEncontrado.tagsToSearch = userEncontrado.tagsToSearch.filter(
              (c) => c.seleccionado,
            );
            return encontrado;
          }
        });

        for (const i of this.usersEncontrados) {
          var tokens = await this.tokensUser.getTokens(i.uid_user);

          let objeto = {
            stop: false,
            id_article: id_article,
            uid_user: i.uid_user,
            id_registra: i.id_document,
            tokens,
          };
          this.socket.io.emit("cuarentineFound", objeto);
          this.socket.io.emit("quarantine", objeto);
        }
      });
  }

  seeBenefits(name: string) {
    window.open(
      "https://net99.world/products/" + name,
      "_blank", // <- This is what makes it open in a new window.
    );


    // if (name == "gold99" || name == "Gold99") {
    //   window.open(
    //     "https://net99.world/gold99product",
    //     "_blank", // <- This is what makes it open in a new window.
    //   );
    // } else if (name == "elite99" || name == "Elite99") {
    //   window.open(
    //     "https://net99.world/elite99product",
    //     "_blank", // <- This is what makes it open in a new window.
    //   );
    // } else if (name == "diamond99" || name == "Diamond99") {
    //   window.open(
    //     "https://net99.world/diamond99product",
    //     "_blank", // <- This is what makes it open in a new window.
    //   );
    // } else if (name == "imperial99" || name == "Imperial99") {
    //   window.open(
    //     "https://net99.world/imperial99product",
    //     "_blank", // <- This is what makes it open in a new window.
    //   );
    // }
  }

  requestProductCancellation() {
    $(".ui.basic.modal.cancel.product").modal("show");
  }
  rejectCancellation() {
    $(".ui.basic.modal.reject.cancell.product").modal("show");
  }
  acceptProductCancellation() {
    $(".ui.basic.modal.cancel.product").modal("show");
  }
  async requestCancell() {
    if (this.argumentForm.value.argument !== "") {
      let id = Number(this.generateId.createId());
      let commodity = {
        duration: this.article.commodity.duration,
        totalProductPeriod: 0,
        autoRenovation: this.article.commodity.autoRenovation,
        product: this.article.commodity.producto,
        assigned: this.article.commodity.fecha,
        start: this.article.commodity.start,
        ending: this.article.commodity.finish,
      };

      var tokens = await this.tokensUser.getTokens(this.customer.uid_user);

      let obj = {
        uid: this.customer.uid_user,
        id_document: this.idA,
        tokens: tokens,
      };
      this.socket.io.emit("request-cancell", obj);
      let observations = firebase.firestore.FieldValue.arrayUnion(
        this.argumentForm.value.argument,
      );
      this.afs
        .collection("ARTICLE")
        .doc(this.idA)
        .collection("COMMODITY-HISTORY")
        .add({
          idCancellation: id,
          id_document: "",
          dateCancellation: "undefined",
          requestCancellation: true,
          dateRequest: moment().format(),
          accepted: false,
          estatus: "en espera",
          argumento: "no hay comentarios",
          observation: this.argumentForm.value.argument,
          commodity,
          observations,
        })
        .then((e) => {
          let idD = e.id;
          this.afs
            .collection("ARTICLE")
            .doc(this.idA)
            .set(
              {
                commodity: {
                  idCancellation: id,
                  requestCancellation: true,
                },
              },
              { merge: true },
            )
            .then((e: any) => {
              this.afs
                .collection("ARTICLE")
                .doc(this.idA)
                .collection("COMMODITY-HISTORY")
                .doc(idD)
                .update({ id_document: idD, estatus: "aprobado" });
            });
        });

      this.hideModalNegative();
      this.argumentForm.reset();
    }
  }

  async noApproveCancellation() {
    var tokens = await this.tokensUser.getTokens(this.customer.uid_user);

    let obj = {
      uid: this.customer.uid_user,
      id_document: this.idA,
      tokens: tokens,
    };
    this.socket.io.emit("reject-product-cancell", obj);
    this.afs
      .collection("ARTICLE")
      .doc(this.idA)
      .set(
        {
          commodity: { requestCancellation: false },
        },
        { merge: true },
      );
  }

  async approveCancellation() {
    var tokens = await this.tokensUser.getTokens(this.customer.uid_user);

    let obj = {
      uid: this.customer.uid_user,
      id_document: this.idA,
      tokens: tokens,
    };
    this.socket.io.emit("approve-cancell", obj);
    this.afs
      .collection("ARTICLE")
      .doc(this.idA)
      .collection(
        "COMMODITY-HISTORY",
        (ref) =>
          ref.where(
            "idCancellation",
            "==",
            this.article.commodity.idCancellation,
          ),
      )
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((data: any) => {
        this.afs
          .collection("ARTICLE")
          .doc(this.idA)
          .set(
            {
              commodity: {
                requestCancellation: false,
                duration: 0,
                producto: "no asignado",
                autoRenovation: 0,
                estatus: "cancelado",
                dateCancellation: moment().format(),
                totalProductPeriod: 0,
              },
            },
            { merge: true },
          )
          .then();
        this.afs
          .collection("ARTICLE")
          .doc(this.idA)
          .collection("COMMODITY-HISTORY")
          .doc(data[0].id_document)
          .set(
            {
              dateCancellation: moment().format(),
              accepted: true,
              estatus: "aprobado",
              commodity: {
                totalProductPeriod: this.diffDays(
                  this.article.commodity.start,
                  moment().format(),
                ),
              },
            },
            { merge: true },
          );
      });
  }
  diffDays(start, cancellation) {
    let dateStart = moment(start);
    let dateCancellation = moment(cancellation);
    return dateCancellation.diff(dateStart, "days");
  }
  addProduct() {
    this.router.navigate([
      "ingresa",
      this.customer.id_document,
      "articulo",
      this.idA,
      2,
      "productos",
    ]);
  }

  getAllComments() {
    this.afs
      .collection(`ARTICLE/${this.article.id_document}/ARTICLE-COMMENT`)
      .valueChanges()
      .subscribe((comments) => (this.allArticleComments = comments));
  }

  // openLG2(contenido2) {
  // this.afs.collection(`ARTICLE/${this.article.id_document}/ARTICLE-COMMENT`).valueChanges().pipe(take(1)).toPromise().then(
  //   comments => this.allArticleComments = comments
  // )

  //   this.modal.open(contenido2, { size: 'lg' })
  // }

  commentArticlePost() {
    let comentarios = {
      comentario: this.articleComment,
      date: moment().format(),
      id_user: this.user.id,
      fecha: moment().format("DD-MM-YYYY"),
      hora: moment().format("hh:mm:ss a"),
    };
    this.dbData.articleComment(this.article.id_document, comentarios);
    this.articleComment = "";
  }
}
