import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/SERVICIOS/auth.service';
import firebase from 'firebase/compat/app';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { User } from 'src/app/COMERCIAL/MODULOS/user.interface';
import { GenerateIdService } from 'src/app/SERVICIOS/generate-id.service';
import moment from 'moment'; import { Location } from "@angular/common";

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { log } from 'console';

@Component({
  selector: 'app-phone-login',
  templateUrl: './phone-login.component.html',
  styleUrls: ['./phone-login.component.scss']
})
export class PhoneLoginComponent implements OnInit {
  phoneNumber: string;
  message: string = "Ingresa un número de teléfono válido."
  invalid: boolean = false
  showCodeInput: boolean = false
  confirmationResult: any;
  appVerifier: any;
  uid: string;
  type: string;
  isChecked: boolean;
  @ViewChild('myModal') myModal;
  modalRef;
  user: any;
  count: number = 60;

  constructor(private authSvc: AuthService, public modal: NgbModal, private _location: Location, public afAuth: AngularFireAuth, private route: ActivatedRoute, private generateId: GenerateIdService, private router: Router, private afs: AngularFirestore,) { }

  ngOnInit(): void {
    this.uid = this.route.snapshot.paramMap.get("uid");
    this.type = this.route.snapshot.paramMap.get("type");


    // Renderiza previamente el reCAPTCHA en el elemento recaptcha-container
  }
  sendPhoneForm = new UntypedFormGroup({
    phone: new UntypedFormControl('', [Validators.required,])
  });

  get phone() { return this.sendPhoneForm.get('phone') }

  openModalTerms() {
    this.modalRef = this.modal.open(this.myModal, {
      size: "lg", animation: true, centered: true, backdrop: false
    })
  }
  checked(checked: boolean) {
    this.isChecked = checked;
  }
  removeModal() {
    this.modal.dismissAll()
    this.isChecked = false
  }

  acceptTerms() {
    this.afs.collection('USERS').doc(this.user.uid).set({ termsAndConditionsAccepted: true }, { merge: true }).then((e) => {
      if (this.user.role !== 'SUPPORT' && this.user.status) {
        this.router.navigate(['/pagina-principal']);
      } else {
        this.router.navigate(['/support']);
      }
      if (this.user && !this.user.status) {
        this.router.navigate(['/register-phone/']);

      } else if (this.user) {
        this.router.navigate(['/register-phone/']);
        ;
      }

      this.removeModal()
    })
  }
  async onLogin() {
    if (this.phone.value.length < 10 || this.phone.value.length > 10) {
      this.invalid = true
      this.message = "Ingresa un número de teléfono válido."
    } else {

      if (this.uid) {
        //buca si el usuario ya existe
        this.afs.collection('USERS', ref => ref.where('phoneNumber', '==', "+52" + this.phone.value)).valueChanges().subscribe((users: any) => {
          if (users.length > 0) {
            this.invalid = true
            this.message = "Este número de teléfono ya está registrado."
          } else {
            //agregar provedor de telefono al usuario existente
            this.user = firebase.auth().currentUser;
            this.phoneNumber = "+52" + this.phone.value
            this.appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
            this.user.linkWithPhoneNumber(this.phoneNumber, this.appVerifier).then((confirmationResult) => {
              this.showCodeInput = true
              this.confirmationResult = confirmationResult;
              // ...
            }).catch((error) => {
              // Error; SMS not sent
              // ...
              console.log('error ==>', error);
            }
            )
          }
        })
      } else {
        if (this.type == "login") {
          //buca si el usuario existe y si tiene el provedor de telefono
          this.afs.collection('USERS', ref => ref.where('phoneNumber', '==', "+52" + this.phone.value)).valueChanges().subscribe((user: any) => {
            if (user.length > 0) {
              this.invalid = false
              this.phoneNumber = "+52" + this.phone.value
              this.appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
              this.authSvc.loginWithPhoneNumber(this.phoneNumber, this.appVerifier).then(
                (confirmationResult) => {
                  this.showCodeInput = true
                  this.confirmationResult = confirmationResult;
                  this.appVerifier.clear()
                  this.counter();
                }).catch((error) => {
                  // Error; SMS not sent
                  // ...
                  console.log('error ==>', error);
                }
                )

            } else {
              this.invalid = true
              this.message = "Este número de teléfono no está registrado."
            }
          })

        }
        else if (this.type == "register") {
          //buca si el usuario ya existe
          this.afs.collection('USERS', ref => ref.where('phoneNumber', '==', "+52" + this.phone.value)).valueChanges().subscribe((user: any) => {
            if (user.length > 0) {
              this.invalid = true
              this.message = "Este número de teléfono ya está registrado."
            } else {
              this.invalid = false

              //envia el codigo de verificacion por sms por primera vez y sí funciona
              this.phoneNumber = "+52" + this.phone.value
              this.appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
              this.authSvc.loginWithPhoneNumber(this.phoneNumber, this.appVerifier).then(
                (confirmationResult) => {
                  this.showCodeInput = true
                  this.confirmationResult = confirmationResult;
                }).catch((error) => {
                  console.log('error2 ==>', error);
                })
            }
          })
        }
      }
    }
  }

  counter() {
    this.count = 60;
    let interval = setInterval(() => {
      this.count--
      if (this.count == 0) {

        this.stopCount(interval)
      }

    }, 1000)
  }
  stopCount(interval) {
    clearInterval(interval);

  }

  sendNewCode() {
    this.invalid = false;
    this.showCodeInput = false;
    this.appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
    this.authSvc.loginWithPhoneNumber(this.phoneNumber, this.appVerifier).then(
      (confirmationResult) => {
        this.showCodeInput = true
        this.confirmationResult = confirmationResult;
      }).catch((error) => {
        console.log('error2 ==>', error);
      })


    // Reenvía el código de verificación por SMS por segunda vez si no funciona
    this.phoneNumber = "+52" + this.phone.value;





    // Llama a la función para enviar el SMS



    ;
  }





  onOtpChange(otp: string) {
    if (otp.length == 6) {
      this.confirmationResult.confirm(otp).then((result: any) => {
        // User signed in successfully.
        const user = result;
        let date: Date = new Date();
        let mes = date.getMonth() + 1;
        let hora_actual;
        let fecha_actual = date.getDate() + '-' + mes + '-' + date.getFullYear();
        if (date.getMinutes() <= 9) {
          hora_actual = date.getHours() + ':0' + date.getMinutes();
        } else {
          hora_actual = date.getHours() + ':' + date.getMinutes();
        }

        let fecha_registro = {
          fecha: fecha_actual,
          hora: hora_actual,
          date: moment().format(''),
        }
        if (this.uid) {
          //actualiza el numero de telefono
          this.afs.collection('USERS').doc(this.uid)
            .set({
              phoneNumber: this.phoneNumber,
              registerWithPhone: false,
              phoneVerified: true
            }, { merge: true }).then(() => {
              this.router.navigate(['/pagina-principal/']);
            }
            )

        } else {
          if (this.type == "register") {
            const data: User = {
              uid: user.user.uid,
              id: this.generateId.createId(),
              email: null,
              emailVerified: false,
              displayName: null,
              date: fecha_registro,
              status: 'undefined',
              photoURL: null,
              role: 'NO-ROLE',
              termsAndConditionsAccepted: false,
              tokens: [],
              registerWithPhone: true,
              phoneNumber: this.phoneNumber,
              phoneVerified: true
            };

            this.user = data;
            this.afs.collection('USERS').doc(user.user.uid).set(data, { merge: true });
            this.openModalTerms();
          }
          else if (this.type == "login") {


            let userData = user.user;
            this.user = userData;
            if (user.user.displayName) {
              this.router.navigate(['welcome']);
            } else {
              this.router.navigate(['/register-phone/']);
            }


          }
        }
        // ...
      }
      ).catch((error: any) => {
        // User couldn't sign in (bad verification code?)
        // ...
        console.log('error ==>', error);
        this.invalid = true
        this.message = "El código de verificación por SMS no es válido. Vuelva a enviar el sms del código de verificación y asegúrese de utilizar el código de verificación proporcionado por el usuario."
      });


    }
  }
  back() {
    this._location.back();

  }
  newNumber() {
    this.showCodeInput = false
    this.phoneNumber = ""
    this.phone.setValue("")
    this.message = "Ingresa un número de teléfono válido."
    this.invalid = false
    this.showCodeInput = false
    this.confirmationResult = null
  }
}
