import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Item } from "../../MODULOS/registra/item";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { DataDbService } from "src/app/SERVICIOS/data-db.service";
import { SocketService } from "src/app/SERVICIOS/socket/socket.service";
import { AuthService } from "src/app/SERVICIOS/auth.service";
import { take } from "rxjs/operators";
import { Subscription } from "rxjs";
declare let $: any;

type ItemData = (Item & { checked: boolean })[];

const checkify = <T>(items: T[]): (T & { checked: boolean })[] =>
  items.map((item) => ({ ...item, checked: false }));

@Component({
  selector: "app-info",
  templateUrl: "./info.component.html",
  styleUrls: ["./info.component.scss"],
})
export class InfoComponent implements OnInit, OnDestroy {
  p: number = 1;
  idR: string;
  art_selected = [];
  articles = [];
  public getIdArticles = [];
  itemsObtained: boolean = false;
  articlesSelected = [];
  getIdArt = [];
  guardarID = [];
  uid_user: any;
  id: number;
  total_articles: number = undefined;
  subs: Subscription;
  noArticleFound: boolean = false;
  isCuarentena: boolean = false;
  articles_obtained = [];
  id_lead: any;
  lead: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authSvc: AuthService,
    private afs: AngularFirestore,
    private sockets: SocketService,
    private dbData: DataDbService,
  ) {}
  ngOnInit() {
    this.authSvc.getCurrentUser().then((values) => {
      this.uid_user = values.uid;
    });
    this.idR = this.route.snapshot.paramMap.get("idR");
    this.afs.collection("LEAD").doc(this.idR)
      .valueChanges().pipe(take(1)).toPromise().then((lead: any) => {
        this.lead = lead;
        //saber si ya esta terminado o no
        if (lead.completo == true) {
          if (lead.mostrar == true) {
            this.router.navigate([`gestiona/${this.idR}/go`]);
          } else {this.router.navigate([
              `historys/registra-history/${this.idR}/fullview`,
            ]);}
        } else {
          if (lead.cuarentena !== undefined && lead.cuarentena == true) {
            this.isCuarentena = true;
          }
          this.getArticles(lead.tagsToSearch);
          this.id_lead = lead.id;
        }
      });

    this.afs.collection("LEAD").valueChanges().pipe(take(1)).toPromise().then(
      (values) => this.id = values.length
    );
  }

  animation() {
    //   $('.autumn.leaf')
    // .transition('fade');
    $(".green.leaf")
      .transition("horizontal flip in")
      .transition("vertical flip in");
  }

  getArticles(tagsSearch) {
    let tagsToSearch: any;
    tagsToSearch = tagsSearch;
    this.subs = this.afs.collection("ARTICLE").valueChanges().subscribe(
      (articles: any) => {
        let article = articles.filter((article: any) =>
          article.completado == true &&
          (article.estatus == "habilitado" ||
            article.estatus == "por vencer") &&
          article.commodity.estatus == "aprobado"
        );
        this.articles = article.filter((inmuebleEncontradoEnDB) => {
          let encontrado = false;
          let contador = 0;
          if (inmuebleEncontradoEnDB.caracteristicas) {
            for (
              const inmCaracteristica of inmuebleEncontradoEnDB.caracteristicas
            ) {
              for (const selectedValueToSearch of tagsToSearch) {
                if (
                  inmCaracteristica.id === selectedValueToSearch.id &&
                  inmCaracteristica.seleccionado ===
                    selectedValueToSearch.seleccionado
                ) {
                  contador++;
                  if (
                    contador >= tagsToSearch.length ||
                    contador == tagsToSearch.length ||
                    contador <= tagsToSearch.length
                  ) {
                    encontrado = true;
                  }
                }
              }
            }
            inmuebleEncontradoEnDB.contador = contador;
            inmuebleEncontradoEnDB.caracteristicas = inmuebleEncontradoEnDB
              .caracteristicas.filter((c) => c.seleccionado);
            this.animation();
            return encontrado;
          }
        });
        this.total_articles = this.articles.length;
        if (this.total_articles == 0) this.toCuarentena();
        else {
          for (let i = 0; i <= this.articles.length - 1; i++) {
            let guardado = localStorage.getItem(String(i));
            this.getIdArticles.push(JSON.parse(guardado));
          }
          for (let i = 0; i <= this.getIdArticles.length - 1; i++) {
            if (this.getIdArticles[i] == null) {
              let objeto = {
                id: i,
                selected: false,
              };
              localStorage.setItem(String(i), JSON.stringify(objeto));
              this.art_selected.push(objeto);
            } else {
              this.art_selected.push(this.getIdArticles[i]);
            }
          }

          this.articlesSelected = this.art_selected;
        }
      },
    );
  }

  checked(isChecked: boolean, art: any, i: number) {
    this.getIdArt = [];
    let objeto = {
      id: i,
      selected: isChecked,
    };
    localStorage.setItem(String(i), JSON.stringify(objeto));

    for (let i = 0; i <= this.articles.length - 1; i++) {
      let guardado = localStorage.getItem(String(i));
      if (guardado !== null) this.getIdArt.push(JSON.parse(guardado));
    }
    if (this.getIdArt.length > 0) {
      this.articlesSelected = this.getIdArt;
    }
  }
  get selected(): ItemData {
    return this.articles.filter(({ checked }) => checked);
  }

  routerItem(art, i) {
    this.router.navigate([
      "registra",
      this.idR,
      art.id_document,
      "item-view",
      i,
    ]);
  }

  submit() {
    this.getIdArt = [];
    this.articles_obtained = [];
    for (let i = 0; i <= this.articles.length - 1; i++) {
      let guardado = localStorage.getItem(String(i));
      if (guardado !== null) this.getIdArt.push(JSON.parse(guardado));
    }

    if (this.getIdArt.length > 0) {
      for (let i = 0; i <= this.getIdArt.length - 1; i++) {
        if (this.getIdArt[i].selected == true) {
          this.articles_obtained.push(this.articles[i]);
        }
      }
    }

    //saber si eligio algun articulo
    if (this.articles_obtained.length == 0) alert("Elige al menos un articulo");
    else {
      for (let i of this.articles_obtained) {
        let objeto = {
          id: i.id_articulo,
        };
        this.guardarID.push(objeto);
      }
      let obj = {
        id: this.idR,
        ids: this.guardarID,
      };
      this.dbData.saveId(obj, false, this.id_lead, this.lead);
      
      this.router.navigate(["gestiona", this.idR, "go"]);
      localStorage.clear();
    }
  }

  noFoundArticle() {
    this.noArticleFound = true;
    this.toCuarentena();
  }

  toCuarentena() {
    if (this.isCuarentena == false) {
      this.sockets.io.emit("lead-without-articles", this.uid_user);
      let idArticulos = [];
      this.afs.collection("LEAD").doc(this.idR).update({
        cuarentena: true,
        mostrar: false,
        idArticulos,
        estatus: "completado",
        completo: true,
      });
    }
  }

  backToEdit() {
    this.router.navigate(["registra", this.idR, "features", "edit"]);
  }

  ngOnDestroy() {
    if (this.subs !== undefined) this.subs.unsubscribe();
  }
}
