import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GestionaRoutingModule } from './gestiona-routing.module';
import { GestionaComponent } from './gestiona/gestiona.component';
import { GestionaViewComponent } from './gestiona-view/gestiona-view.component';
import { GoComponent } from './go/go.component';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { UploadFileDirective } from 'src/app/SERVICIOS/DIRECTIVES/upload-file.directive';

@NgModule({
  declarations: [
    UploadFileDirective,
    GestionaComponent,
    GestionaViewComponent,
    GoComponent,
    UploadFilesComponent,
  ],
  imports: [
    CommonModule,
    GestionaRoutingModule,
    FormsModule,
    NgxPaginationModule,
  ],
  providers: [NgxImageCompressService],
})
export class GestionaModule {}
