import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeyaccessComponent } from './keyaccess/keyaccess.component';
import { KeyAccessRoutingModule } from './keyaccess-routing.module';
import { SelectLeadComponent } from './select-lead/select-lead.component';
import { SelectArticleComponent } from './select-article/select-article.component';
import { GenerateTokenComponent } from './generate-token/generate-token.component';
import { ManageRequestsComponent } from './manage-requests/manage-requests.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgOtpInputModule } from 'ng-otp-input';
import { KeyaccessHistoryFullviewComponent } from './keyaccess-history-fullview/keyaccess-history-fullview.component';
import { SignComponent } from './sign/sign.component';




@NgModule({
  declarations: [KeyaccessComponent, SelectLeadComponent, SelectArticleComponent, GenerateTokenComponent, ManageRequestsComponent, KeyaccessHistoryFullviewComponent, SignComponent,],
  imports: [
    CommonModule, KeyAccessRoutingModule,
    ReactiveFormsModule, FormsModule, NgOtpInputModule,
  ]
})
export class KeyaccessModule { }
