<div class="main">
  <div class="image">
    <div class="fx-title mb-4">
      <i
        class="large arrow left icon"
        (click)="back()"
        style="cursor: pointer"
      ></i>
      <h2 class="title" style="margin-left: 10px">
        Subir documentación del aval
      </h2>
      <div></div>
    </div>
    <div class="mb-5">
      <div class="subtitle mb-3">
        <h4>Selecciona una opción</h4>
      </div>
      <div
        class="btn-group table-responsive"
        role="group"
        aria-label="Basic radio toggle button group"
      >
        <!-- <form [formGroup]="identificationForm" (ngSubmit)="onSaveForm()"> -->
        <input
          type="radio"
          class="btn-check"
          value="INE"
          [(ngModel)]="tipo"
          name="tipo"
          id="btnradio1"
          autocomplete="off"
          [checked]="identification == 'INE'"
        />
        <label class="btn btn-outline-primary" for="btnradio1">INE</label>

        <input
          type="radio"
          class="btn-check"
          name="tipo"
          value="IFE"
          [(ngModel)]="tipo"
          id="btnradio2"
          autocomplete="off"
          [checked]="identification == 'IFE'"
        />
        <label class="btn btn-outline-primary" for="btnradio2">IFE</label>

        <input
          type="radio"
          class="btn-check"
          name="tipo"
          value="Pasaporte"
          [(ngModel)]="tipo"
          id="btnradio3"
          autocomplete="off"
          [checked]="identification == 'Pasaporte'"
        />
        <label class="btn btn-outline-primary" for="btnradio3">Pasaporte</label>

        <input
          type="radio"
          class="btn-check"
          name="tipo"
          value="Cartilla militar"
          [(ngModel)]="tipo"
          id="btnradio4"
          autocomplete="off"
          [checked]="identification == 'Cartilla militar'"
        />
        <label class="btn btn-outline-primary" for="btnradio4"
          >Cartilla militar</label
        >

        <input
          type="radio"
          class="btn-check"
          name="tipo"
          value="Cédula profesional"
          [(ngModel)]="tipo"
          id="btnradio5"
          autocomplete="off"
          [checked]="identification == 'Cédula profesional'"
        />
        <label class="btn btn-outline-primary" for="btnradio5"
          >Cédula profesional</label
        >

        <input
          type="radio"
          class="btn-check"
          name="tipo"
          value="INM"
          [(ngModel)]="tipo"
          id="btnradio6"
          autocomplete="off"
          [checked]="identification == 'INM'"
        />
        <label class="btn btn-outline-primary" for="btnradio6">INM</label>
        <!-- </form> -->
      </div>
      <div class="tags-info mb-3 mt-3">
        <div *ngIf="tipo == 'INE'">
          <i class="exclamation circle icon"></i>
          Es necesario subir una foto frontal y una trasera del INE
        </div>
        <div *ngIf="tipo == 'IFE'">
          <i class="exclamation circle icon"></i>
          Es necesario subir una foto frontal y una trasera del IFE
        </div>
      </div>
    </div>
    <div class="mt-2 more-images">
      <div *ngIf="user">
        <input
          type="file"
          *ngIf="user.role != 'COMMUNITY-MANAGE'"
          style="display: none"
          id="input-image-others"
          (change)="fileChange($event)"
          accept=".jpg, .png, .jpeg, .jfif, .pdf"
        />
      </div>

      <div *ngFor="let images of otros_archivos; let i = index">
        <div class="box-more-image">
          <i
            class="large window close icon"
            style="
              position: absolute;
              right: 0;
              cursor: pointer;
              background-color: white;
            "
            (click)="deleteImgOther(i)"
          ></i>
          <img
            *ngIf="images.archivo.type != 'application/pdf'"
            [src]="urls[i]"
            class="show_img_other"
            alt=""
          />
          <div
            (click)="openPDF(urls[i])"
            class="box-more-articles text-center show_img_other"
            *ngIf="images.archivo.type == 'application/pdf'"
          >
            <i class="huge file pdf outline icon"></i>
            <p class="lead" *ngIf="otros_archivos.length == 0">Credencial</p>
            <p class="lead" *ngIf="otros_archivos.length == 1">Credencial</p>
            <p class="lead" *ngIf="otros_archivos.length == 2">Credencial</p>
            <p class="lead" *ngIf="otros_archivos.length == 3">Credencial</p>
            <p class="lead" *ngIf="otros_archivos.length == 4">Credencial</p>
          </div>
        </div>
      </div>
      <label
        *ngIf="otros_archivos.length == 0"
        for="input-image-others"
        class="box-more-images"
      >
        <div class="box-more-articles text-center">
          <p>Subir el primer archivo</p>
        </div>
      </label>
      <label
        *ngIf="otros_archivos.length == 1"
        for="input-image-others"
        class="box-more-images"
      >
        <div class="box-more-articles text-center">
          <p>Subir el segundo archivo</p>
        </div>
      </label>
      <label
        *ngIf="otros_archivos.length == 2"
        for="input-image-others"
        class="box-more-images"
      >
        <div class="box-more-articles text-center">
          <p>Subir el tercer archivo</p>
        </div>
      </label>
      <label
        *ngIf="otros_archivos.length == 3"
        for="input-image-others"
        class="box-more-images"
      >
        <div class="box-more-articles text-center">
          <p>Subir el cuarto archivo</p>
        </div>
      </label>
      <label
        *ngIf="otros_archivos.length == 4"
        for="input-image-others"
        class="box-more-images"
      >
        <div class="box-more-articles text-center">
          <p>Subir el quinto archivo</p>
        </div>
      </label>
    </div>
  </div>
  <div class="text-center mb-5" *ngIf="user">
    <button class="ui button" disabled *ngIf="otros_archivos.length == 0">
      Subir documentación
    </button>
    <button
      class="ui button"
      *ngIf="otros_archivos.length > 0 && !loading"
      (click)="cargarImagenes()"
    >
      Subir documentación
    </button>

    <button *ngIf="loading" class="ui loading button">Loading</button>
  </div>
</div>
