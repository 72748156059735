import { Injectable } from "@angular/core";
import { io } from "socket.io-client";

@Injectable({
  providedIn: "root",
})
export class SocketService {
  //   io = io("https://set99-world.onrender.com", {
  //     withCredentials: true,
  //     autoConnect: true,
  //   });
  //   constructor() {}
  // }
  io = io("https://set99-world.onrender.com", {
    withCredentials: true,
    autoConnect: true,
  });
  constructor() { }
}
