<div class="main">
  <h2 class="title mb-5">Key Access Full View</h2>

  <div class="shadow-lg p-3 mb-5 card" style="padding: 10px">
    <div class="card-block mt-1 mb-3" *ngIf="dataLead && dataAgent && dataArticle">
      <h3 style="font-weight: bold">Información del key access</h3>

      <div class="mb-3 mt-4">
        <div class="row mb">
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold">Visitante</p>
            </div>
            <div class="form-outline">
              <p>
                {{ dataLead.cliente.nombre | titlecase }}
                {{ dataLead.cliente.apellido | titlecase }}
              </p>
            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold">Agente</p>
            </div>
            <div class="form-outline">
              <p>{{ dataAgent.displayName | titlecase }}</p>
            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold">Fecha de reunión</p>
            </div>
            <div class="form-outline" *ngIf="isMyResquest">
              <div *ngIf="editDateMeeting">
                <input type="date" (change)="getDateMeetingChange($event)" />
              </div>
              <div *ngIf="!editDateMeeting">
                <button *ngIf="!dateMeeting" class="ui button" (click)="setDateMeeting()">
                  Definir fecha
                </button>
                <div *ngIf="dateMeeting && !dataRequest.tokenVerified" style="
                    display: flex;
                    align-content: space-between;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: baseline;
                  ">
                  <p style="margin-right: 12px">
                    {{ dateMeeting | date : "dd-MM-yyyy" }}
                  </p>
                  <button class="ui button" (click)="setDateMeeting()">
                    Editar
                  </button>
                </div>
                <p *ngIf="dateMeeting && dataRequest.tokenVerified">
                  {{ dateMeeting | date : "dd-MM-yyyy" }}
                </p>
              </div>
            </div>
            <div class="form-outline" *ngIf="!isMyResquest">
              <p *ngIf="!dateMeeting">Por definir</p>
              <p *ngIf="dateMeeting">{{ dateMeeting | date : "dd-MM-yyyy" }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="row mb">
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold">Lead ID</p>
            </div>
            <div class="form-outline">
              <p>{{ dataLead.id }}</p>
            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold">Fecha de creación</p>
            </div>
            <div class="form-outline">
              <p>{{ dataRequest.date | date : "dd-MM-yyyy" }}</p>
            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold">
                Fecha límite de entrega
              </p>
            </div>
            <div class="form-outline">
              <p *ngIf="dateFinish">{{ dateFinish | date : "dd-MM-yyyy" }}</p>
              <p *ngIf="!dateFinish">No disponible</p>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="row mb">
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold">Article ID</p>
            </div>
            <div class="form-outline">
              <p>{{ dataArticle.id_articulo }}</p>
            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold">Fecha de recolección</p>
            </div>
            <div class="form-outline">
              <p *ngIf="!dataRequest.deliveryDate">Pendiente</p>
              <p *ngIf="dataRequest.deliveryDate">
                {{ dataRequest.deliveryDate | date : "dd-MM-yyyy hh:mm" }}
              </p>
            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold">Fecha de entrega</p>
            </div>
            <div class="form-outline">
              <p *ngIf="!dataRequest.pickUpDate">No disponible</p>
              <p *ngIf="dataRequest.pickUpDate">
                {{ dataRequest.pickUpDate | date : "dd-MM-yyyy hh:mm" }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3" *ngIf="dataRequest.sign && !dataRequest.requestSign">
        <div class="row mb">
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold"></p>
            </div>
          </div>
          <div class="col">
            <div class="form-outline"></div>
          </div>
          <div class="col">
            <div class="form-outline"></div>
          </div>
        </div>
        <div class="row mb">
          <div class="col">
            <div class="form-outline">
              <button class="ui primary button" (click)="viewDocument()">
                Ver documento
              </button>
            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <p></p>
            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <p></p>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3" *ngIf="!dataRequest.sign && dataRequest.requestSign && isMine">
        <div class="row mb">
          <div class="col">
            <div class="form-outline">
              <p class="lead" style="font-weight: bold"></p>
            </div>
          </div>
          <div class="col">
            <div class="form-outline"></div>
          </div>
          <div class="col">
            <div class="form-outline"></div>
          </div>
        </div>
        <div class="row mb">
          <div class="col">
            <div class="form-outline">
              <button class="ui primary button" (click)="signRequest()">
                Volver a firmar
              </button>
            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <p></p>
            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-4">
    <div class="token" style="text-align: center; display: flex; justify-content: center"
      *ngIf="dataRequest && !auth.isFullAdmin(user)">
      <div *ngIf="
          dataRequest.tokenVerified &&
          dataRequest.dateMeeting &&
          dataRequest.status != 'aprobado'
        ">
        <button *ngIf="!dataRequest.enableKeyAccess && !dataRequest.makeKeyAccess" class="ui primary button" disabled>
          Make A #KeyAccesss
        </button>
        <button *ngIf="dataRequest.enableKeyAccess && !dataRequest.makeKeyAccess" (click)="makeKeyAccess()"
          class="ui primary button">
          Make A #KeyAccesss
        </button>
      </div>
      <div *ngIf="dataRequest.sign">
        <div *ngIf="
            dataRequest.tokenVerified &&
            dataRequest.dateMeeting &&
            dataRequest.status == 'aprobado'
          ">
          <button *ngIf="!dataRequest.confirmDeliveryKeysUser" (click)="showSecondModalConfirm()"
            class="ui primary button">
            Confirmar recolección de llaves
          </button>
          <div>
            <p *ngIf="
                dataRequest.confirmDeliveryKeysUser &&
                !dataRequest.confirmPickUpKeysUser
              " class="lead">
              Entrega aún no confirmada
            </p>
            <p *ngIf="
                dataRequest.confirmDeliveryKeysUser &&
                dataRequest.confirmPickUpKeysUser
              " class="lead">
              Entrega confirmada
            </p>
          </div>
          <!-- <button disabled *ngIf="dataRequest.confirmDeliveryKeysUser" class="ui primary button">Confirmado
                        de
                        entrega de
                        llaves</button> -->
        </div>
      </div>
      <div *ngIf="dataRequest.dateMeeting && dataRequest.tokenVerified == false" style="width: 18rem">
        <div class="cardToken" *ngIf="tokenInformation; else noTokenInfo">
          <div class="card-body">
            <div *ngIf="seconds > 0; else generateNewTokenNow">
              <h5 class="card-title">{{ tokenInformation.token }}</h5>
              <lead>El token vencerá en {{ seconds }} segundos</lead>
            </div>
            <ng-template #generateNewTokenNow>
              <p>El token ha vencido, genera uno nuevo</p>
              <button (click)="generateNewToken()" class="btn">
                Generar nuevo token
              </button>
            </ng-template>
          </div>
        </div>

        <ng-template #noTokenInfo>
          <div class="cardToken cardNewToken" (click)="generateNewToken()">
            <div class="card-body fw-bold">
              Generar nuevo token
            </div>
          </div>

        </ng-template>











      </div>
    </div>
    <div style="text-align: center; display: flex; justify-content: center"
      *ngIf="dataRequest && auth.isFullAdmin(user)">
      <div *ngIf="dataRequest.dateMeeting && !dataRequest.tokenVerified">
        <div class="otp-center">
          <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{ length: 6 }"></ng-otp-input>

          <!-- mensajes de error -->
          <div *ngIf="tokenError" class="mt-2 alert alert-danger" role="alert">
            <p>{{ tokenErrorMessage }}</p>
          </div>
        </div>
      </div>
      <div *ngIf="
          dataRequest.tokenVerified &&
          !dataRequest.sign &&
          dataRequest.status !== 'aprobado'
        ">
        <button *ngIf="dataRequest.enableKeyAccess" class="ui primary button" disabled>
          Enable #KeyAccess
        </button>
        <button *ngIf="!dataRequest.enableKeyAccess" (click)="showFirstModalConfirm()" class="ui primary button">
          Enable #KeyAccess
        </button>
      </div>
      <div *ngIf="
          dataRequest.tokenVerified &&
          dataRequest.confirmDeliveryKeysUser &&
          dataRequest.status == 'aprobado'
        ">
        <p class="lead">Recolección confirmada</p>
        <button *ngIf="!dataRequest.confirmPickUpKeysUser" (click)="showThreeModalConfirm()" class="ui primary button">
          Confirmar entrega
        </button>
      </div>
      <div *ngIf="
          dataRequest.tokenVerified &&
          !dataRequest.confirmDeliveryKeysUser &&
          dataRequest.status == 'aprobado'
        ">
        <p class="lead">Recolección aún no confirmada</p>
      </div>
      <div *ngIf="
          dataRequest.tokenVerified &&
          dataRequest.sign &&
          dataRequest.dateMeeting &&
          dataRequest.status == 'en proceso'
        ">
        <button (click)="checkSign()" class="ui primary button">
          Continuar
        </button>
        <button (click)="SignatureForwarding()" class="ui button">
          Pedir reenvio de firma
        </button>
        <button disabled *ngIf="dataRequest.confirmDeliveryKeysUser" class="ui primary button">
          Confirmado de entrega de llaves
        </button>
      </div>
    </div>
  </div>

  <footer *ngIf="dataRequest && dataRequest.status !== 'aprobado'" class="mt-5 bg-light text-center text-lg-start">
    <!-- Copyright -->
    <div class="text-center p-3 red">
      <p class="lead">En proceso de aprobación</p>
    </div>
    <!-- Copyright -->
  </footer>

  <footer *ngIf="dataRequest && dataRequest.status == 'aprobado'" class="mt-5 bg-light text-center text-lg-start">
    <!-- Copyright -->
    <div class="text-center p-3 green">
      <p class="lead">Aprobado</p>
    </div>
    <!-- Copyright -->
  </footer>
</div>

<ng-template #myModal *ngIf="showModal">
  <div class="main-modal" style="margin: 20px">
    <div class="container-modal-data">
      <h2 style="font-weight: bold">#KeyAccess</h2>

      <div class="card" (scroll)="onScroll($event)" style="width: 100%; height: 50vh; overflow-y: scroll">
        <div style="margin: 20px">
          <div style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            ">
            <table class="default">
              <tr>
                <th>ARTCILE ID</th>
              </tr>

              <tr>
                <td>{{ dataArticle.id_articulo }}</td>
              </tr>
            </table>
            <table class="default">
              <tr>
                <th>USER ID</th>
              </tr>

              <tr>
                <td>{{ dataAgent.id }}</td>
              </tr>
            </table>
            <table class="default">
              <tr>
                <th>ACCESS ID</th>
              </tr>

              <tr>
                <td>{{ dataRequest.id }}</td>
              </tr>
            </table>
          </div>

          <br />
          <h5>DEFINICIONES.-</h5>
          <p>
            Se le otorga el acceso a llaves a
            {{ dataAgent.displayName | titlecase }} quien en adelante se le
            denomina el solicitante.
          </p>
          <h5>DECLARACIONES.-</h5>
          <p *ngIf="dataArticle.articulo.no_interior != ''">
            Declara el solicitante la recepción de llaves con el único fin de
            poder mostrar a sus prospectos por una sola vez y físicamente el
            interior del inmueble ubicado en {{ dataArticle.articulo.calle }},
            {{ dataArticle.articulo.cp }}, #{{
            dataArticle.articulo.no_exterior
            }}

            int {{ dataArticle.articulo.no_interior }} Entre las calles
            {{ dataArticle.articulo.entre_calle1 }}
            y la calle
            {{ dataArticle.articulo.entre_calle2 }}.
          </p>
          <p *ngIf="dataArticle.articulo.no_interior == ''">
            Declara el solicitante la recepción de llaves con el único fin de
            poder mostrar a sus prospectos por una sola vez y físicamente el
            interior del inmueble ubicado en {{ dataArticle.articulo.calle }},
            {{ dataArticle.articulo.cp }}, #{{
            dataArticle.articulo.no_exterior
            }}

            Entre las calles {{ dataArticle.articulo.entre_calle1 }} y calle
            {{ dataArticle.articulo.entre_calle2 }}.
          </p>
          <P>
            Declara el solicitante solicitar el acceso a llaves del inmueble
            antes descrito, con la única finalidad de mostrar físicamente el
            interior del inmueble para su promoción del mismo y lograr la renta
            y/o venta del inmueble descrito en el presente instrumento.
          </P>

          <p>
            Este acceso a llaves que solicita el solicitante y en conformidad a
            lo dispuesto en el presente instrumento, declara y acepta el
            solicitante que cualquier mal uso o acto indebido que atente contra
            la integridad física o legal del inmueble descrito en este documento
            y en consecuente al propietario del inmueble descrito. El
            solicitante declara firmando y aceptando toda responsabilidad y
            compromiso que le relacione a un acto indebido o bien el atentar en
            contra de la integridad física y legal del inmueble descrito en el
            presente documento denominado #KeyAccess (acceso a llaves).
          </p>

          <h5>TÉRMINOS & CONDICIONES.</h5>

          <p>
            1. El solicitante pagará la cantidad de $3,999.00 (TRES MIL
            NOVECIENTOS NOVENTA Y NUEVE PESOS 00/100 MN) a Net99 que se define
            en el convenio con el ID de artículo:
            {{ dataArticle.id_articulo }} por la perdida de llaves, de las
            cuales el solicitante se le da la posesión a la aceptación de los
            términos, condiciones y firma del presente instrumento por el
            solicitante.
          </p>
          <p>
            2. El solicitante pagará cualquier daño causado a la integridad
            física del inmueble descrito en el presente instrumento, es decir;
            cualquier daño causado por el solicitante al terreno y/o
            construcción del inmueble descrito en el presente instrumento; en
            caso de ser amueblado, el solicitante pagará también y
            adicionalmente cada accesorio, mobiliario y/o electrodoméstico que
            se encuentre dentro dicho inmueble o cualquier bien de valor que se
            encuentre dentro de dicho inmueble, es decir; teléfonos, dinero,
            joyas y cualquier bien o pertenencia de valor del titular de dicho
            inmueble en el convenio con el ID de artículo:
            {{ dataArticle.id_articulo }}, de lo contrario y en caso de
            oposición por el solicitante este será entregado y/o puesto en
            procedencia y aplicabilidad de las autoridades competentes.
          </p>
          <p>
            3. Si por consecuencia del solicitante existiría algún incidente en
            el inmueble mencionado dentro el presente instrumento, es decir;
            cualquier robo y/o daños a dicho inmueble por terceros y esto sea
            ocasionado por descuido o por no tomar las medidas de seguridad
            correspondiente en su visita y/o durante su posesión de llaves de
            dicho inmueble tal cual se define en el convenio con el ID de
            artículo:
            {{ dataArticle.id_articulo }}. El solicitante será responsable de
            estos daños y pagará la cifra correspondiente y calculada por el
            perito público y/o privado por los daños o perdidas que ocasione
            dicho evento al propietario y/o a Net99 tal cual se define en el
            convenio con el ID de artículo: {{ dataArticle.id_articulo }} de lo
            contrario y en caso de oposición por el solicitante este será
            entregado y/o puesto en procedencia y aplicabilidad de las
            autoridades competentes.
          </p>
          <p>
            4. El solicitante se obliga a cubrir los gastos que se originen por
            el perito público o privado dado el caso expuesto en el párrafo 3
            (TRES) de TERMINOS & CONDICIONES presentes en este instrumento.
          </p>
          <p>
            5. Si el solicitante llegará a ser sorprendido dentro del inmueble
            realizando cualquier otro acto y/o actividad desmoralizada o que no
            sea mostrar el inmueble mencionado en el presente instrumento con
            único fin de promocionarlo para lograr la venta y/o renta del mismo,
            este pagará una indemnización de $9,999.00 (NUEVE MIL NOVECIENTOS
            NOVENTA Y NUEVE PESOS 00/100 MN) a Net99 tal cual se define en el
            convenio con el ID de artículo: {{ dataArticle.id_articulo }} y este
            será entregado y/o puesto en procedencia y aplicabilidad de las
            autoridades competentes por faltas y/o daños a la integridad de
            dicho inmueble de dominio ajeno.
          </p>
          <p>
            6. El solicitante bajo protesta de decir verdad y a sabiendas de las
            consecuencias legales que eso conlleva, se compromete a tomar
            posesión de llaves del inmueble tal cual se define en el convenio
            con el ID de artículo:
            {{ dataArticle.id_articulo }}, únicamente para realizar una sola
            visita al inmueble con fines de promover el mismo y no realizar
            conductas sospechosas y/o inadecuadas, ni a realizar actos ilícitos
            dentro del inmueble anteriormente mencionado, ni incurrir en algún
            delito del orden común o federal. El solicitante también se obliga a
            no tener o permitir en dicho inmueble el almacén de armas de fuego,
            armas blancas, pólvora, explosivos, droga de cualquier naturaleza,
            instrumentos para procesarla y/o cualquier otro de naturaleza
            análoga que sirva o haya servido para cometer algún ilícito, siendo
            responsable de estos, en caso de que se pretenda involucrar al
            inmueble materia del presente instrumento, a Net99 y/o al
            propietario del mismo, deslindando desde este momento tanto al
            inmueble, a Net99, así como a su propietario de dicha
            responsabilidad y por lo tanto, no será aplicable la LEY FEDERAL DE
            EXTINCIÓN DE DOMINIO o LA LEY DE EXTINCIÓN DE DOMINIO DE LA ENTIDAD.
            El solicitante conviene que será el único responsable si se comete
            cualquier delito de carácter local o federal y/o dentro del
            inmueble, así también el solicitante cuidara que no existan personas
            secuestradas y/o vehículos o instrumentos que provengan de algún
            ilícito, por lo que ni el agente del ministerio público, ni la
            autoridad judicial federal o estatal podrán inculpar a Net99 o a el
            propietario del presente inmueble de responsabilidad alguna conforme
            a la LEY FEDERAL DE EXTINCIÓN DE DOMINIO, ni de ninguna otra
            legislación estatal o federal aplicable. Convienen el solicitante en
            que si se llega a dar el caso de que se inicie un proceso de
            extinción de dominio en contra del inmueble arrendado, el
            solicitante se obliga a pagar los gastos y honorarios de los
            abogados que se contraten para la debida defensa, así como a pagar
            los daños y perjuicios que se le causen a Net99 y al propietario del
            inmueble.
          </p>
          <p>
            7. El solicitante tendrá un periodo máximo de 24 horas para entregar
            las llaves del inmueble citado en multiples oraciones dentro de los
            párrafos anteriores de lo contrario pagará $399.00 (TRES CIENTOS
            NOVENTA Y NUEVE PESOS 00/100 MN) a Net99 tal cual se define en el
            convenio con el ID de artículo: {{ dataArticle.id_articulo }} a
            excepción de que estas sean solicitadas mediante el presente
            instrumento los días viernes, siendo el caso de que el solicitante
            haga la recepción de las llaves en día viernes, tendrá como máximo
            72 hrs para entregar dichas llaves.
          </p>
          <p>
            8. El solicitante se obliga a realizar la entrega y/o recepción de
            llaves en Alud 833 Int 209 Jardines del Moral, León, Guanajuato,
            México CP 37160 como dirección establecida por Net99 tal cual se
            define en el convenio con el ID de artículo:
            {{ dataArticle.id_articulo }} y está podrá ser modificada sin previo
            aviso, ya que la modificación se mostrara al instante a nivel
            aplicativo al momento de ser efectuada.
          </p>
          <p>
            9. El solicitante se somete al cumplimiento explicito de cada uno de
            los elementos que integran el presenten instrumento, es decir; de
            sus definiciones, declaraciones, términos y condiciones. El
            solicitante se somete al conservar la posesión temporal de las
            llaves del inmueble citado en multiples oraciones dentro de los
            párrafos anteriores e incluso al no conservar la posesión de éstas,
            ya que puede incurrir en malas practicas e indebidas acciones aún
            después de la entrega la entrega de las llaves. Por esto mismo el
            solicitante será único responsable de cualquier acto o suceso
            indebido que le relacione atentando contra la integridad física y
            legal del inmueble aún y cuando no conserve la posesión de dichas
            llaves.
          </p>
        </div>
      </div>
      <div class="ui checkbox mb-3 mt-2">
        <input *ngIf="!activateCheckBox" type="checkbox" id="terms" disabled />
        <input *ngIf="activateCheckBox" type="checkbox" id="terms" (change)="checked($event.target.checked)" />
        <label style="cursor: pointer" for="terms">Estoy de acuerdo y he leído los términos y condiciones.
        </label>
      </div>
    </div>
    <modal-footer class="fx">
      <div></div>
      <div>
        <button class="ui button" (click)="removeModal()">Regresar</button>
        <button *ngIf="!isChecked" class="ui primary button" disabled>
          Aceptar y Continuar
        </button>
        <button *ngIf="isChecked" class="ui primary button" (click)="acceptTerms()">
          Aceptar y Continuar
        </button>
      </div>
    </modal-footer>
  </div>
</ng-template>

<ng-template #myModalInformation>
  <div class="main-modal" style="margin: 20px">
    <div class="container-modal-data">
      <h2 style="font-weight: bold">#KeyAccess</h2>

      <div class="card" style="width: 100%; height: 50vh; overflow-y: scroll">
        <div style="margin: 20px">
          <div style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            ">
            <table class="default">
              <tr>
                <th>ARTCILE ID</th>
              </tr>

              <tr>
                <td>{{ dataArticle.id_articulo }}</td>
              </tr>
            </table>
            <table class="default">
              <tr>
                <th>USER ID</th>
              </tr>

              <tr>
                <td>{{ dataAgent.id }}</td>
              </tr>
            </table>
            <table class="default">
              <tr>
                <th>ACCESS ID</th>
              </tr>

              <tr>
                <td>{{ dataRequest.id }}</td>
              </tr>
            </table>
          </div>

          <br />
          <h5>DEFINICIONES.-</h5>
          <p>
            Se le otorga el acceso a llaves a
            {{ dataAgent.displayName | titlecase }} quien en adelante se le
            denomina el solicitante.
          </p>
          <h5>DECLARACIONES.-</h5>
          <p *ngIf="dataArticle.articulo.no_interior != ''">
            Declara el solicitante la recepción de llaves con el único fin de
            poder mostrar a sus prospectos por una sola vez y físicamente el
            interior del inmueble ubicado en {{ dataArticle.articulo.calle }},
            {{ dataArticle.articulo.cp }}, #{{
            dataArticle.articulo.no_exterior
            }}

            int {{ dataArticle.articulo.no_interior }} Entre las calles
            {{ dataArticle.articulo.entre_calle1 }}
            y la calle
            {{ dataArticle.articulo.entre_calle2 }}.
          </p>
          <p *ngIf="dataArticle.articulo.no_interior == ''">
            Declara el solicitante la recepción de llaves con el único fin de
            poder mostrar a sus prospectos por una sola vez y físicamente el
            interior del inmueble ubicado en {{ dataArticle.articulo.calle }},
            {{ dataArticle.articulo.cp }}, #{{
            dataArticle.articulo.no_exterior
            }}

            Entre las calles {{ dataArticle.articulo.entre_calle1 }} y calle
            {{ dataArticle.articulo.entre_calle2 }}.
          </p>
          <P>
            Declara el solicitante solicitar el acceso a llaves del inmueble
            antes descrito, con la única finalidad de mostrar físicamente el
            interior del inmueble para su promoción del mismo y lograr la renta
            y/o venta del inmueble descrito en el presente instrumento.
          </P>

          <p>
            Este acceso a llaves que solicita el solicitante y en conformidad a
            lo dispuesto en el presente instrumento, declara y acepta el
            solicitante que cualquier mal uso o acto indebido que atente contra
            la integridad física o legal del inmueble descrito en este documento
            y en consecuente al propietario del inmueble descrito. El
            solicitante declara firmando y aceptando toda responsabilidad y
            compromiso que le relacione a un acto indebido o bien el atentar en
            contra de la integridad física y legal del inmueble descrito en el
            presente documento denominado #KeyAccess (acceso a llaves).
          </p>

          <h5>TÉRMINOS & CONDICIONES.</h5>

          <p>
            1. El solicitante pagará la cantidad de $3,999.00 (TRES MIL
            NOVECIENTOS NOVENTA Y NUEVE PESOS 00/100 MN) a Net99 que se define
            en el convenio con el ID de artículo:
            {{ dataArticle.id_articulo }} por la perdida de llaves, de las
            cuales el solicitante se le da la posesión a la aceptación de los
            términos, condiciones y firma del presente instrumento por el
            solicitante.
          </p>
          <p>
            2. El solicitante pagará cualquier daño causado a la integridad
            física del inmueble descrito en el presente instrumento, es decir;
            cualquier daño causado por el solicitante al terreno y/o
            construcción del inmueble descrito en el presente instrumento; en
            caso de ser amueblado, el solicitante pagará también y
            adicionalmente cada accesorio, mobiliario y/o electrodoméstico que
            se encuentre dentro dicho inmueble o cualquier bien de valor que se
            encuentre dentro de dicho inmueble, es decir; teléfonos, dinero,
            joyas y cualquier bien o pertenencia de valor del titular de dicho
            inmueble en el convenio con el ID de artículo:
            {{ dataArticle.id_articulo }}, de lo contrario y en caso de
            oposición por el solicitante este será entregado y/o puesto en
            procedencia y aplicabilidad de las autoridades competentes.
          </p>
          <p>
            3. Si por consecuencia del solicitante existiría algún incidente en
            el inmueble mencionado dentro el presente instrumento, es decir;
            cualquier robo y/o daños a dicho inmueble por terceros y esto sea
            ocasionado por descuido o por no tomar las medidas de seguridad
            correspondiente en su visita y/o durante su posesión de llaves de
            dicho inmueble tal cual se define en el convenio con el ID de
            artículo:
            {{ dataArticle.id_articulo }}. El solicitante será responsable de
            estos daños y pagará la cifra correspondiente y calculada por el
            perito público y/o privado por los daños o perdidas que ocasione
            dicho evento al propietario y/o a Net99 tal cual se define en el
            convenio con el ID de artículo: {{ dataArticle.id_articulo }} de lo
            contrario y en caso de oposición por el solicitante este será
            entregado y/o puesto en procedencia y aplicabilidad de las
            autoridades competentes.
          </p>
          <p>
            4. El solicitante se obliga a cubrir los gastos que se originen por
            el perito público o privado dado el caso expuesto en el párrafo 3
            (TRES) de TERMINOS & CONDICIONES presentes en este instrumento.
          </p>
          <p>
            5. Si el solicitante llegará a ser sorprendido dentro del inmueble
            realizando cualquier otro acto y/o actividad desmoralizada o que no
            sea mostrar el inmueble mencionado en el presente instrumento con
            único fin de promocionarlo para lograr la venta y/o renta del mismo,
            este pagará una indemnización de $9,999.00 (NUEVE MIL NOVECIENTOS
            NOVENTA Y NUEVE PESOS 00/100 MN) a Net99 tal cual se define en el
            convenio con el ID de artículo: {{ dataArticle.id_articulo }} y este
            será entregado y/o puesto en procedencia y aplicabilidad de las
            autoridades competentes por faltas y/o daños a la integridad de
            dicho inmueble de dominio ajeno.
          </p>
          <p>
            6. El solicitante bajo protesta de decir verdad y a sabiendas de las
            consecuencias legales que eso conlleva, se compromete a tomar
            posesión de llaves del inmueble tal cual se define en el convenio
            con el ID de artículo:
            {{ dataArticle.id_articulo }}, únicamente para realizar una sola
            visita al inmueble con fines de promover el mismo y no realizar
            conductas sospechosas y/o inadecuadas, ni a realizar actos ilícitos
            dentro del inmueble anteriormente mencionado, ni incurrir en algún
            delito del orden común o federal. El solicitante también se obliga a
            no tener o permitir en dicho inmueble el almacén de armas de fuego,
            armas blancas, pólvora, explosivos, droga de cualquier naturaleza,
            instrumentos para procesarla y/o cualquier otro de naturaleza
            análoga que sirva o haya servido para cometer algún ilícito, siendo
            responsable de estos, en caso de que se pretenda involucrar al
            inmueble materia del presente instrumento, a Net99 y/o al
            propietario del mismo, deslindando desde este momento tanto al
            inmueble, a Net99, así como a su propietario de dicha
            responsabilidad y por lo tanto, no será aplicable la LEY FEDERAL DE
            EXTINCIÓN DE DOMINIO o LA LEY DE EXTINCIÓN DE DOMINIO DE LA ENTIDAD.
            El solicitante conviene que será el único responsable si se comete
            cualquier delito de carácter local o federal y/o dentro del
            inmueble, así también el solicitante cuidara que no existan personas
            secuestradas y/o vehículos o instrumentos que provengan de algún
            ilícito, por lo que ni el agente del ministerio público, ni la
            autoridad judicial federal o estatal podrán inculpar a Net99 o a el
            propietario del presente inmueble de responsabilidad alguna conforme
            a la LEY FEDERAL DE EXTINCIÓN DE DOMINIO, ni de ninguna otra
            legislación estatal o federal aplicable. Convienen el solicitante en
            que si se llega a dar el caso de que se inicie un proceso de
            extinción de dominio en contra del inmueble arrendado, el
            solicitante se obliga a pagar los gastos y honorarios de los
            abogados que se contraten para la debida defensa, así como a pagar
            los daños y perjuicios que se le causen a Net99 y al propietario del
            inmueble.
          </p>
          <p>
            7. El solicitante tendrá un periodo máximo de 24 horas para entregar
            las llaves del inmueble citado en multiples oraciones dentro de los
            párrafos anteriores de lo contrario pagará $399.00 (TRES CIENTOS
            NOVENTA Y NUEVE PESOS 00/100 MN) a Net99 tal cual se define en el
            convenio con el ID de artículo: {{ dataArticle.id_articulo }} a
            excepción de que estas sean solicitadas mediante el presente
            instrumento los días viernes, siendo el caso de que el solicitante
            haga la recepción de las llaves en día viernes, tendrá como máximo
            72 hrs para entregar dichas llaves.
          </p>
          <p>
            8. El solicitante se obliga a realizar la entrega y/o recepción de
            llaves en Alud 833 Int 209 Jardines del Moral, León, Guanajuato,
            México CP 37160 como dirección establecida por Net99 tal cual se
            define en el convenio con el ID de artículo:
            {{ dataArticle.id_articulo }} y está podrá ser modificada sin previo
            aviso, ya que la modificación se mostrara al instante a nivel
            aplicativo al momento de ser efectuada.
          </p>
          <p>
            9. El solicitante se somete al cumplimiento explicito de cada uno de
            los elementos que integran el presenten instrumento, es decir; de
            sus definiciones, declaraciones, términos y condiciones. El
            solicitante se somete al conservar la posesión temporal de las
            llaves del inmueble citado en multiples oraciones dentro de los
            párrafos anteriores e incluso al no conservar la posesión de éstas,
            ya que puede incurrir en malas practicas e indebidas acciones aún
            después de la entrega la entrega de las llaves. Por esto mismo el
            solicitante será único responsable de cualquier acto o suceso
            indebido que le relacione atentando contra la integridad física y
            legal del inmueble aún y cuando no conserve la posesión de dichas
            llaves.
          </p>
        </div>
        <div class="mb-5" style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-wrap: nowrap;
            align-content: center;
          ">
          <img (contextmenu)="bloquearClicDerecho()" (dragstart)="bloquearArrastre()" class="mt-3" [src]="imageSrc"
            width="" alt="
          firma de
          
          " />
          <h5 class="mt-3" style="font-weight: bold">
            {{ dataAgent.displayName | titlecase }}
          </h5>
        </div>
      </div>
    </div>

    <modal-footer class="fx">
      <div></div>
      <div>
        <button class="ui button mt-4" (click)="removeModal()">Regresar</button>
      </div>
    </modal-footer>
  </div>
</ng-template>

<div class="ui basic modal one" style="width: 100%; margin: 25vh auto; overflow: hidden">
  <div class="ui icon header text-center">
    <i class="exclamation icon"></i>
    Confirmar
  </div>
  <div class="content text-center">
    <p>¿Estás seguro de confirmar el keyaccess?</p>
  </div>
  <div class="actions" style="display: flex; justify-content: center">
    <div class="ui red basic cancel inverted button" (click)="hideModalNegative()">
      <i class="remove icon"></i>
      No
    </div>
    <div class="ui green ok inverted button" (click)="enableKeyAccess()">
      <i class="checkmark icon"></i>
      Sí
    </div>
  </div>
</div>
<div class="ui basic modal two" style="width: 100%; margin: 25vh auto; overflow: hidden">
  <div class="ui icon header text-center">
    <i class="exclamation icon"></i>
    Confirmar
  </div>
  <div class="content text-center">
    <p>¿Estás seguro de confirmar la recolección de llaves?</p>
  </div>
  <div class="actions" style="display: flex; justify-content: center">
    <div class="ui red basic cancel inverted button" (click)="hideModalNegative()">
      <i class="remove icon"></i>
      No
    </div>
    <div class="ui green ok inverted button" (click)="confirmDeliveryKeysUser()">
      <i class="checkmark icon"></i>
      Sí
    </div>
  </div>
</div>
<div class="ui basic modal three" style="width: 100%; margin: 25vh auto; overflow: hidden">
  <div class="ui icon header text-center">
    <i class="exclamation icon"></i>
    Confirmar
  </div>
  <div class="content text-center">
    <p>¿Estás seguro de confirmar la entrega de llaves?</p>
  </div>
  <div class="actions" style="display: flex; justify-content: center">
    <div class="ui red basic cancel inverted button" (click)="hideModalNegative()">
      <i class="remove icon"></i>
      No
    </div>
    <div class="ui green ok inverted button" (click)="pickUpDeliveryKeysUser()">
      <i class="checkmark icon"></i>
      Sí
    </div>
  </div>
</div>