import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from 'src/app/SERVICIOS/auth.service';
import { take } from 'rxjs/operators';
import { User } from '../../MODULOS/user.interface';

declare let $: any;

@Component({
  selector: 'app-article-images',
  templateUrl: './article-images.component.html',
  styleUrls: ['./article-images.component.scss'],
})
export class ArticleImagesComponent implements OnInit {
  idA: string;
  images: any[] = [];
  allImages: any[] = [];
  user: User;
  urlImage: string = '';
  constructor(
    private _location: Location,
    private router: Router,
    public route: ActivatedRoute,
    private afs: AngularFirestore,
    public auth: AuthService
  ) {
  }

  ngOnInit(): void {

    this.idA = this.route.snapshot.paramMap.get('idA');
    this.auth.user$.subscribe((user) => {
      this.user = user;
      this.getData(this.user);
    });
  }
  getData(user: User) {
    this.afs
      .collection('ARTICLE')
      .doc(this.idA)
      .valueChanges()
      .subscribe((article: any) => {
        this.allImages = [];
        this.images = [];

        if (
          article.communityPhotos == undefined ||
          article.communityPhotos == null
        ) {
          this.allImages = [];
          this.images = [];
        } else {
          this.allImages = article.communityPhotos;
          if (
            // user.role === 'ADMIN' ||

            user.role === 'COMMUNITY-MANAGE'
          ) {
            if (article.communityPhotos.length > 0) {
              article.communityPhotos.forEach((image: { rechazado: any }) => {
                if (!image.rechazado) {
                  this.images.push(image);
                }
              });
            }
          } else {
            if (article.communityPhotos.length > 0) {
              article.communityPhotos.forEach(
                (image: { aprobado: any; rechazado: any }) => {
                  if (image.aprobado && !image.rechazado) {
                    this.images.push(image);
                  }
                }
              );
            }
          }
        }
      });
  }
  back() {
    this._location.back();
  }
  uploadPhotos() {
    this.router.navigate([
      '/community-manage/uploads/fotografias/subir-foto/' + this.idA,
    ]);
  }

  approve(i: number) {
    let image = this.images[i];
    image.aprobado = true;
    image.rechazado = false;
    this.images[i] = image;

    this.afs.collection('ARTICLE').doc(this.idA).update({
      communityPhotos: this.images,
    });
  }
  reject(i: number) {
    // let image = this.images[i];
    // image.rechazado = true;
    // image.aprobado = false;
    // this.images[i] = image;

    this.images.splice(i, 1);
    this.afs.collection('ARTICLE').doc(this.idA).update({
      communityPhotos: this.images,
    });
  }
  fullviewImage(i: number) {
    this.urlImage = this.images[i].url;
    $('.ui.basic.modal.one').modal('show');
  }
  closeModal() {
    $('.ui.basic.modal.one').modal('hide');
  }
}
