<div class="main center">
    <div class="title text-center mb-4">
        <div class="title mb-5 text-center">
            <h1>Set99</h1>
        </div>
        <div>
            <div *ngIf="this.type == 'login'&& !this.uid">
                <p class="fs-2" *ngIf="!showCodeInput ">
                    Inicio de sesión con teléfono
                </p>
                <p class="lead" *ngIf="!showCodeInput ">
                    Ingresa tu número de teléfono a 10 dígitos para iniciar sesión.
                </p>
                <p class="fs-2" *ngIf="showCodeInput ">
                    Verificación de código al número {{phoneNumber}}
                </p>
                <p class="lead" *ngIf="showCodeInput ">
                    Ingresa el código de 6 dígitos que se envió al {{phoneNumber}}.
                </p>

            </div>
            <div *ngIf="this.type == 'register'">
                <p class="fs-2" *ngIf="!showCodeInput ">
                    Crear cuenta con teléfono
                </p>
                <p class="lead" *ngIf="!showCodeInput ">
                    Ingresa tu número de teléfono a 10 dígitos para crear tu cuenta.
                </p>
                <p class="fs-2" *ngIf="showCodeInput ">
                    Verificación de código al número {{phoneNumber}}
                </p>
                <p class="lead" *ngIf="showCodeInput ">
                    Ingresa el código de 6 dígitos que se envió al {{phoneNumber}}.
                </p>
            </div>
            <div *ngIf="this.type == 'login' && this.uid">
                <p class="fs-2" *ngIf="!showCodeInput ">
                    Agregar teléfono a la cuenta
                </p>
                <p class="lead" *ngIf="!showCodeInput ">
                    Ingresa tu número de teléfono a 10 dígitos para agregarlo a tu cuenta y poder iniciar sesión sin
                    necesidad de
                    usar
                    tu correo y contraseña.
                </p>
                <p class="fs-2" *ngIf="showCodeInput ">
                    Verificación de código al número {{phoneNumber}}
                </p>
                <p class="lead" *ngIf="showCodeInput ">
                    Ingresa el código de 6 dígitos que se envió al {{phoneNumber}}.
                </p>
            </div>
        </div>
        <!-- <p class="fs-2" *ngIf="!showCodeInput &&  !this.uid">
            Inicio de sesión con teléfono
        </p>
        <p *ngIf=" this.uid && !showCodeInput ">
            Agregar teléfono a la cuenta
        </p>
        <p class="fs-2" *ngIf="showCodeInput ">
            Verificación de codigo al número {{phoneNumber}}
        </p>

        <p class="lead" *ngIf=" !this.uid && !showCodeInput ">
            Ingresa tu número de teléfono para iniciar sesión.
        </p>
        <p class="lead" *ngIf=" this.uid && !showCodeInput ">
            Ingresa tu número de teléfono para agregarlo a tu cuenta y poder iniciar sesión sin necesidad de usar tu
            correo y contraseña.
        </p>
        <p class="lead" *ngIf="showCodeInput ">
            Ingresa el código de 6 dígitos que se envió al {{phoneNumber}}.
        </p> -->
    </div>
    <div class="inputs">
        <div *ngIf="!phoneNumber">
            <form [formGroup]="sendPhoneForm" (ngSubmit)="onLogin()">
                <div class="input-contain mb-4">
                    <input type="number" id="i-phone" name="phone" value="" formControlName="phone" autocomplete="off"
                        aria-labelledby="placeholder-fname">
                    <label class="placeholder-text" for="i-phone" id="placeholder-fname">
                        <div class="text">Teléfono</div>
                    </label>
                </div>


                <button class="btn btn-dark mt-3 mb-5" disabled *ngIf="sendPhoneForm.invalid">
                    Continuar
                </button>
                <button class="btn btn-dark mt-3 mb-5" *ngIf="!sendPhoneForm.invalid" (click)="onLogin()">
                    Continuar
                </button>
            </form>
        </div>
        <div id="recaptcha-container" class="" *ngIf="!showCodeInput "></div>

    </div>
    <div class="alerts">
        <div class="alert " role="alert" *ngIf="showCodeInput ">
            <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{ length: 6 }"></ng-otp-input>
            <a class="d-flex flex-row-reverse" (click)="sendNewCode()" *ngIf="count == 0">Reenviar código de
                verificación</a>
            <p class="d-flex flex-row-reverse text-muted" *ngIf="count != 0">Reenviar código de
                verificación en {{count}}</p>
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="invalid && message">
            <h6 class="mt-2">{{message}}</h6>
            <!-- <button class="btn" routerLink="/login">Volver al Login</button>
          <button class="btn" (click)="reset()">¿No es {{phoneNumber}} tu correo electronico?</button> -->
        </div>
    </div>
    <div class="d-flex flex-column">
        <a *ngIf="showCodeInput " (click)="newNumber()">{{phoneNumber}} no es mi número de teléfono</a>

        <a *ngIf="!this.uid" class="mt-5" [routerLink]="['/login']">Volver al Login</a>
        <a *ngIf="this.uid" class="mt-5" (click)="back()">Cancelar</a>
    </div>
</div>










<ng-template #myModal>
    <div class="main-modal" style="margin: 20px;">
        <div class="container-modal-data">
            <h2>Términos y condiciones</h2>

            <div class="card" style="width: 100%; height:50vh; overflow-y: scroll;">
                <div style="margin: 20px;">
                    <p>
                        Estás en www.set99.world. Estas Condiciones de servicio contienen los términos y
                        condiciones que rigen todo el uso de nuestra Plataforma (como se define a continuación) y
                        Servicios (como se define a continuación) y todo el contenido, servicios y/o productos
                        disponibles en o a través de la Plataforma (en conjunto, los “Los Servicios de Set99").
                    </p>

                    <p> Los Servicios de Set99 se le ofrecen sujetos a su aceptación, sin modificación acordados por
                        las partes de acuerdo con estos Términos de servicio), de todos los términos y condiciones
                        contenidos en el presente documento y todas las demás normas de funcionamiento, políticas
                        (incluyendo, sin limitación, nuestra Política de privacidad en https://www.net99.world/
                        digitalfullproduct#privacypolicy), las Directrices (como se definen a continuación) y cualquier
                        modificación futura de las mismas, y los procedimientos que puedan publicarse de vez en
                        cuando en la Plataforma o ponerse a su disposición en o a través de los Servicios de Set99 (en
                        conjunto, los "Términos"). Cuando son aceptados por usted (como se define mas adelante),
                        estos Términos forman un contrato legalmente vinculante entre usted y el Proveedor (como se
                        define más adelante). Si acepta estos Términos en nombre de una entidad, como su
                        empleador o la empresa para la que trabaja, usted declara que tiene la autoridad legal para
                        obligar a dicha entidad.</p>
                    <p>LEA ATENTAMENTE ESTAS CONDICIONES. AL REGISTRARSE, ACCEDER, NAVEGAR Y/O
                        UTILIZAR LOS SERVICIOS DE SET99, USTED RECONOCE QUE HA LEÍDO, ENTENDIDO Y
                        ACEPTADO ESTAR OBLIGADO POR ESTOS TÉRMINOS. SI NO ESTÁ DE ACUERDO CON
                        ESTOS TÉRMINOS, NO ACCEDA, NAVEGUE O UTILICE LA PLATAFORMA O LOS SERVICIOS
                        SET99.</p>
                    <p> El Proveedor puede, a su sola discreción, elegir suspender o terminar el acceso o el uso de los
                        Servicios de Set99 a cualquiera que viole estos Términos.</p>
                    <p> El idioma original de estos Términos es el inglés. El Proveedor puede poner a disposición
                        traducciones para su comodidad. En caso de conflicto entre la versión original en inglés y
                        cualquier traducción, prevalecerá la versión en inglés.</p>

                    <h5>1. Definiciones </h5>


                    <p>Definiciones</p>
                    <p>Cuenta</p>
                    <p>el medio principal para acceder y utilizar los Servicios de Set99, sujeto a su aprobación por el
                        Proveedor;</p>
                    <p>Actividad</p>
                    <p>citas, tareas, eventos y acciones (como una llamada, una reunión, un almuerzo o un tipo
                        personalizado) en un calendario que puede estar asociado a una Operación, una persona o
                        una Organización;</p>
                    <p> Condiciones adicionales
                    </p>
                    <p>Disposiciones aplicables al uso por parte del Cliente de una Característica particular según lo
                        indicado por el Proveedor en las áreas pertinentes de los Servicios de Set99;</p>


                    <p>Autorización</p>
                    <p> el conjunto de derechos y privilegios en el Sitio Web asignados a un Usuario por un Cliente;
                    </p>
                    <p>Servicio Beta</p>
                    <p>Una característica o funcionalidad de los Servicios de Set99 que está en desarrollo o no ha
                        sido lanzada como producto final que el Proveedor ha puesto a disposición del Cliente para su
                        prueba y evaluación;</p>
                    <p> Cliente</p>
                    <p> una persona física o jurídica que ha aceptado estos Términos con el Proveedor;
                        Datos del Cliente</p>
                    <p>Archivos y cualquier otro dato e información digital, que se somete a los Servicios de Set99 o
                        se inserta de otro modo en el Sistema por parte del Cliente (incluidos los Usuarios, Productos,
                        personas, Organizaciones, Actividades, Pipelines, Etapas y Tratos específicos asociados con
                        el Cliente);</p>
                    <p>Contenido</p>
                    <p>cualquier dato e información disponible a través de los Servicios de Set99 o contenidos en la
                        estructura del Sistema, artículos, documentos, folletos, presentaciones, fotografías, imágenes,
                        obras audiovisuales, otros materiales informativos y cualquier comentario;</p>
                    <p> Acuerdo</p>
                    <p> las ventas en curso, perdidas o ganadas de un Cliente a una Organización o persona;</p>
                    <p> Función
                    </p>
                    <p>Una función o conjunto de funciones que proporcionan una capacidad particular dentro de los
                        Servicios de Set99 según lo determine el Proveedor;</p>
                    <p>Archivos</p>
                    <p>documentos de cualquier tipo (imágenes, hojas de cálculo, archivos de texto, etc.) que son
                        insertados en el Sistema por el Cliente, y que normalmente están asociados a un Acuerdo,
                        Persona u Organización en particular;</p>
                    <p> Directrices
                    </p>
                    <p>directrices o reglas adicionales aplicables a Características, aplicaciones, productos o
                        servicios específicos que pueden publicarse de vez en cuando en la Plataforma o ponerse a
                        disposición de otra manera en o a través de los Servicios de Set99;</p>
                    <p> Organización</p>
                    <p> personas jurídicas (como empresas) y otras entidades con las que el Cliente está haciendo
                        tratos;</p>




                    <p> Set99 LN
                    </p>
                    <p>Set99 LN, una representación física privada establecida bajo las leyes de la República
                        Mexicana, con su sede principal en Av Alud 833 Jardines Del Moral, León, Guanajuato,
                        México, CP 37160 y registrada en el Registro Federal de Contribuyentes con el código
                        MAVJ901210IP7, y una filial Set99 QR ;</p>
                    <p>Set99 Qro</p>
                    <p>Set99 Qro, una empresa con representación física en José Siurob 14 int4 Alameda, Querétaro,
                        Qro;</p>
                    <p>Materiales de Set99</p>
                    <p>las interfaces visuales, los gráficos, el diseño, los sistemas, los métodos, la información,
                        código informático, software, servicios, "look and feel", organización, compilación del
                        contenido, código, datos y todos los demás elementos de los Servicios de Set99;</p>
                    <p> Servicios de Set99</p>
                    <p> el Sitio web, el Sistema, el Contenido, la Plataforma y todo el contenido, servicios y/o
                        productos disponibles en o a través de la Plataforma;</p>
                    <p> Tubería</p>
                    <p>el proceso de realización de una Operación, que consta de varias Etapas;</p>
                    <p> Modalidad
                    </p>
                    <p>varios criterios relacionados con el uso y la funcionalidad de los Servicios de Set99 y en los
                        que se basa la el tipo de usuario;</p>
                    <p>Plataforma</p>
                    <p>la aplicación de gestión de la relación con el cliente de Set99;</p>
                    <p>Productos</p>
                    <p> los bienes o servicios que un Cliente ofrece a personas y Organizaciones;
                    </p>
                    <p>Revendedor</p>
                    <p> entidad de terceros que (i) adquiere los Servicios de Set99 del Proveedor y revende dichos
                        Servicios de Set99 a los Clientes, (ii) factura directamente a dichos Clientes y (iii)
                        proporciona a
                        dichos Clientes un servicio de atención al cliente;</p>
                    <p>Estatus</p>
                    <p> el estado de un acuerdo en un momento determinado;
                    </p>


                    <p>Proveedor</p>
                    <p> según el contexto, Set99 LN, Set99 QR; y en conjunto, los "Proveedores";
                    </p>
                    <p>Sistema</p>
                    <p> la solución de computación en la nube integrada para proporcionar los Servicios de Set99,
                        incluyendo las aplicaciones, el software, el hardware, las bases de datos, las interfaces, los
                        medios asociados, la documentación, las actualizaciones, los nuevos lanzamientos y otros
                        componentes o materiales proporcionados con ellos;</p>
                    <p>Usuario</p>
                    <p> una persona física a la que se le concede la Autorización para utilizar la Cuenta en nombre de
                        un Cliente;</p>
                    <p> Sitio web
                    </p>
                    <p>la compilación de todos los documentos web (incluyendo imágenes, archivos php y html)
                        puestos a disposición a través de Set99 o de sus subdominios o dominios con nombres
                        idénticos bajo otros dominios superiores y propiedad del Proveedor.
                        2. Autoridad para celebrar estos términos con el Proveedor
                        El uso de los Servicios de Set99 está sujeto a la aceptación de estos Términos. Para aceptar
                        estos Términos por sí mismo o en nombre de un Cliente, una persona debe tener la capacidad
                        legal para hacerlo. En el caso de una persona física, la persona debe tener al menos 18 años
                        de edad o tener una autorización válida de su representante legal o custodio. En el caso de
                        una persona jurídica, la entidad debe estar debidamente constituida y en regla.</p>
                    <p> Las Condiciones se aceptan en cuanto se produce una de las siguientes situaciones</p>
                    <p> la persona ha recibido la confirmación de la creación de la Cuenta y las credenciales
                        necesarias del Proveedor para poder iniciar sesión en su Cuenta; o
                        para aquellos Servicios de Set99 y partes del Sitio web cuyo uso no dependa de la creación de
                        una Cuenta, en el momento de obtener acceso a dichos servicios.
                        Usted no podrá, sin el consentimiento previo por escrito del Proveedor, acceder a los Servicios
                        de Set99 (i) con fines de producción, (ii) si es un competidor de Set99, (iii) para supervisar
                        la
                        disponibilidad, el rendimiento o la funcionalidad de los Servicios de Set99 o (iv) para otros
                        fines
                        de evaluación comparativa o competitivos.</p>
                    <p> Una vez aceptadas, estas Condiciones permanecerán en vigor hasta que se rescindan según
                        lo dispuesto en el presente documento.</p>
                    <p> 3. 3. Modificaciones de los Términos</p>
                    <p> El Proveedor se reserva el derecho, a su sola discreción, de cambiar, modificar, añadir o
                        eliminar partes de los Términos en cualquier momento, publicando dichos cambios en o a
                        través de la Plataforma o los Servicios de Set99. Por favor, compruebe estos Términos
                        periódicamente para ver si hay cambios. Su uso continuado de los Servicios de Set99 después
                        de que se hayan publicado dichos cambios según lo dispuesto anteriormente constituye su
                        aceptación vinculante de dichos cambios. Dichos Términos modificados entrarán en vigor
                        automáticamente en el momento en que (i) usted continúe utilizando los Servicios de Set99, o
                        (ii) 30 días después de la publicación de dichos Términos modificados en o a través de la
                        Plataforma, lo que ocurra primero. Sin perjuicio de lo anterior, la resolución de cualquier
                        disputa que surja entre usted y el Proveedor se regirá por los Términos vigentes en el
                        momento en que surgió dicha disputa.
                    </p>
                    <p> 4.1. 4.1. Prestación de los Servicios de Set99</p>
                    <p>
                        El Proveedor (a) pondrá los Servicios de Set99, el Contenido y los Datos del Cliente a
                        disposición de un Cliente de acuerdo con estos Términos, (b) proporcionará el soporte
                        estándar aplicable para los Servicios de Set99 al Cliente, y/o soporte mejorado, (c) hará
                        esfuerzos comercialmente razonables para que los Servicios de Set99 estén disponibles las 24
                        horas del día, 7 días a la semana, excepto por:</p>
                    <p>(i) el tiempo de inactividad planificado (del que el Proveedor dará aviso electrónico previo
                        según lo dispuesto en las Directrices), y (ii) cualquier indisponibilidad causada por
                        circunstancias que escapen al control razonable del Proveedor, incluyendo, por ejemplo, un
                        acto siniestro, un acto de gobierno, una inundación, un incendio, un terremoto, disturbios
                        civiles, un acto de terror, una huelga u otro problema laboral, un fallo o retraso del proveedor
                        de servicios de Internet o un ataque de denegación de servicio.</p>
                    <p>4.2. Protección de los datos del cliente
                        El Proveedor mantendrá salvaguardas administrativas, físicas y técnicas para la protección de
                        la seguridad, confidencialidad e integridad de los Datos del Cliente, tal y como se describe en
                        las Directrices. Dichas salvaguardas incluirán, pero no se limitarán a, medidas para evitar el
                        acceso, uso, modificación o divulgación de los Datos del cliente por parte del personal del
                        Proveedor, excepto (a) para proporcionar los Servicios de Set99 y evitar o solucionar
                        problemas técnicos o de servicio, (b) según lo exija la ley de acuerdo con la Sección 7.4
                        (Divulgación obligatoria) a continuación, o (c) según lo permita expresamente el Cliente o el
                        Usuario por escrito.</p>
                    <p>El Proveedor podrá utilizar proveedores de servicios para realizar los Servicios. El Proveedor
                        hará esfuerzos comercialmente razonables para garantizar que las transferencias de datos a
                        los proveedores de servicios cumplan con los requisitos aplicables al procesamiento de datos
                        de los Clientes y proporcionará información sobre dichas transferencias de datos en estos
                        Términos para la consideración del Cliente.</p>
                    <p>Al aceptar estos Términos, el Cliente autoriza al Proveedor (una autorización general por
                        escrito en el sentido de la LEY FEDERAL DE PROTECCIÓN DE DATOS PERSONALES EN
                        POSESIÓN DE LOS PARTICULARES Nueva Ley DOF 05-07-2010 ) a contratar a los siguientes
                        proveedores de servicios para la realización de los Servicios. El Proveedor deberá avisar con
                        10 días hábiles de antelación antes de contratar a cualquier nuevo proveedor de servicios en
                        virtud de la autorización general del Proveedor.</p>
                    <p>La lista de proveedores de servicios que procesan los Datos del Cliente puede verse aquí:
                        GOOGLE LLC Y SALESFORCE.COM, INC.</p>
                    <p>5. 5. Uso de los Servicios de Set99
                    </p>
                    <p>5.1 Establecimiento de una cuenta</p>
                    <p>Ciertas características, funciones, partes o elementos de los Servicios de Set99 solo pueden
                        ser utilizados o accesibles por los titulares de una Cuenta. La persona que desee crear una
                        Cuenta debe completar el formulario de registro en el Sitio web o el proceso alternativo
                        proporcionado por un Revendedor si el acceso a los Servicios de Set99 se adquiere de un
                        Revendedor; y aceptar estos Términos haciendo clic en "Registrarse" u otro botón similar
                        Cada Cliente sólo puede tener una Cuenta. Si varias personas necesitan utilizar una Cuenta en
                        nombre del Cliente, el Cliente deberá designar a dichas personas como Usuarios. Cada uno de
                        estos Usuarios estará sujeto a las restricciones establecidas en estas Condiciones.</p>

                    <p>Si el Cliente ha designado a los Usuarios y les ha concedido la Autorización, se considerará
                        que dichos Usuarios están autorizados a actuar en nombre del Cliente cuando utilicen la
                        Cuenta. El Proveedor no es responsable de verificar la validez de la Autorización de ningún
                        Usuario y no tendrá ninguna responsabilidad al respecto. Sin embargo, el Proveedor puede, a
                        su discreción, solicitar información adicional o prueba de las credenciales de la persona. Si el
                        Proveedor no está seguro de que a un Usuario se le haya concedido la Autorización, el
                        Proveedor puede, a su sola discreción, impedir que dicho Usuario acceda a los Servicios de
                        Set99.</p>
                    <p>Un Usuario puede estar asociado a múltiples Clientes y Cuentas. La eliminación de un Usuario
                        de una Cuenta no eliminará al Usuario de la Plataforma si está conectado a múltiples Cuentas.
                    </p>
                    <p>El Cliente y cualquier Usuario asociado a una Cuenta deben proporcionar al Proveedor
                        información verdadera, precisa, actual y completa sobre el Cliente, los Usuarios o la Cuenta y
                        mantenerla actualizada.</p>
                    <p>5.2 Acceso a una Cuenta</p>
                    <p>El Proveedor proporcionará al Cliente un nombre de usuario y una contraseña ("Credenciales
                        de inicio de sesión") que se utilizarán para iniciar sesión en su Cuenta, a menos que el Cliente
                        utilice la función de inicio de sesión único u otro servicio para iniciar sesión. Estas
                        credenciales
                        de inicio de sesión no deben ser utilizadas por varias personas. Si el Cliente ha designado a
                        varios usuarios, cada uno de ellos recibirá unas credenciales de inicio de sesión distintas. El
                        Cliente y cada Usuario son responsables de mantener la confidencialidad de todas las
                        credenciales de inicio de sesión asociadas a una Cuenta. El Cliente debe notificar
                        inmediatamente al Proveedor de cualquier divulgación, pérdida o uso no autorizado de
                        cualquier Credencial de Inicio de Sesión; de la salida de un Usuario de la organización del
                        Cliente; de un cambio en la función de un Usuario en la organización del Cliente;
                        de cualquier terminación del derecho de un Usuario por cualquier razón.</p>
                    <p>5.3 Terminación de la cuenta
                    </p>
                    <p>El Cliente podrá rescindir estas Condiciones en cualquier momento según lo dispuesto en la
                        Sección 17.</p>
                    <p>El Proveedor eliminará permanentemente la Cuenta en un plazo de seis meses a partir de la
                        fecha efectiva de la rescisión.</p>
                    <p>7. Datos del cliente</p>
                    <p>7.1 Carga de los datos del cliente en la plataforma
                    </p>
                    <p>Si el Cliente carga los Datos del Cliente en la Plataforma, dichos Datos del Cliente y cualquier
                        procesamiento de dichos Datos del Cliente deben cumplir con estas Condiciones y con la
                        legislación aplicable. Todos los derechos, títulos e intereses sobre los Datos del cliente
                        pertenecen al Cliente o a terceras personas (incluidos los Usuarios, personas y
                        Organizaciones), ya sean publicados y/o cargados por usted o puestos a disposición en o a
                        través de los Servicios de Set99 por el Proveedor. Al cargar los Datos del cliente en la a la
                        Plataforma, el Cliente autoriza al Proveedor a procesar los Datos del Cliente. El Cliente es
                        responsable de garantizar que el Cliente y cualquiera de los Usuarios asociados a la Cuenta no
                        creen, transmitan, muestren o pongan a disposición de otro modo ningún Dato del Cliente que
                        infrinja los términos de estas Condiciones, los derechos del Proveedor, de otros Clientes o
                        Usuarios, de personas u Organizaciones, o que sea perjudicial (por ejemplo, virus, gusanos,
                        malware y otros códigos destructivos), ofensivo, amenazante, abusivo, acosador, tortuoso,
                        difamatorio, vulgar, obsceno, que invada la privacidad de otra persona, que sea odioso o que
                        sea ilegal de otro modo; y el Cliente y todos los Usuarios asociados a la Cuenta tienen los
                        derechos necesarios para utilizar los Datos del Cliente, incluso para insertarlos en la
                        Plataforma y procesarlos mediante la Cuenta.</p>
                    <p>7.2 No se garantiza la exactitud</p>
                    <p>El Proveedor no garantiza ninguna exactitud con respecto a la información contenida en los
                        Datos del cliente, y le recomienda encarecidamente que piense cuidadosamente en lo que
                        transmite, envía o publica a o a través de los Servicios de Set99. Usted entiende que toda la
                        información contenida en los Datos del cliente es responsabilidad exclusiva de la persona que
                        originó dichos Datos del cliente. Esto significa que el Cliente, y no el Proveedor, es
                        totalmente
                        responsable de todos los Datos del Cliente que se carguen, publiquen, transmitan o se pongan
                        a disposición de otro modo a través de los Servicios de Set99, así como de cualquier acción
                        realizada por los Proveedores u otros Clientes o Usuarios como resultado de dichos Datos del
                        Cliente.</p>
                    <p>7.3 Datos del Cliente ilegales</p>
                    <p>El Proveedor no está obligado a preseleccionar, supervisar o filtrar los Datos del cliente o los
                        actos de su procesamiento por parte del Cliente para descubrir cualquier naturaleza ilegal de
                        los mismos. Sin embargo, si se descubren o se ponen en conocimiento del Proveedor tales
                        Datos del cliente ilegales o la acción de su procesamiento ilegal, o si hay razones para creer
                        que ciertos Datos del cliente son ilegales, el Proveedor tiene derecho a notificar al Cliente
                        dichos Datos del Cliente ilegales; negar su publicación en el Sitio Web o su inserción en el
                        Sistema exigir al Cliente que los Datos del Cliente ilegales cumplan con estas Condiciones y
                        con la legislación aplicable retirar temporal o permanentemente los Datos del cliente ilegales
                        del Sitio web o de la Cuenta, restringir el acceso a los mismos o eliminarlos.
                        Si el Proveedor recibe pruebas convincentes de que los Datos del cliente no son ilegales, el
                        Proveedor podrá, a su entera discreción, restablecer dichos Datos del cliente, que fueron
                        eliminados del Sitio web o de la Cuenta o cuyo acceso fue restringido.</p>
                    <p>Además, en el caso de que el Proveedor considere, a su entera discreción, que los Datos del
                        cliente infringen las leyes, normas o reglamentos aplicables o estas Condiciones, el Proveedor
                        podrá (pero no está obligado), eliminar dichos Datos del cliente en cualquier momento con o
                        sin previo aviso.</p>
                    <p>Sin limitar la generalidad de la frase anterior, Set99 LN. cumple con la Ley de Derechos de
                        Autor, y eliminará los Datos del Cliente de la Plataforma al recibir una notificación de
                        retirada
                        conforme.</p>
                    <p>Set99 QR, como procesador de datos, asistirá al Cliente como controlador de datos en el
                        cumplimiento de las obligaciones del Cliente en virtud del Reglamento de la LEY FEDERAL DE
                        PROTECCIÓN DE DATOS PERSONALES EN POSESIÓN DE LOS PARTICULARES Nueva Ley
                        DOF 05-07-2010.</p>
                    <p>7.4 Divulgación obligatoria
                    </p>
                    <p>El Proveedor podrá revelar la información confidencial de un Cliente en la medida en que la ley
                        le obligue a hacerlo. En tal caso, el Proveedor hará esfuerzos comercialmente razonables para
                        proporcionar al Cliente una notificación previa de la divulgación obligada (en la medida en que
                        lo permita la ley) y el Cliente proporcionará asistencia razonable, a su costo, si desea
                        impugnar
                        la divulgación. Si el Proveedor se ve obligado por ley a revelar la información confidencial del
                        Cliente como parte de un procedimiento civil en el que el Proveedor es parte, y el Cliente no
                        impugna la divulgación, el Cliente reembolsará al Proveedor los costes razonables de
                        recopilación y de acceso seguro a dicha información confidencial.</p>
                    <p>8. Servicios</p>
                    <p>8.1 Uso de los Servicios de Set99</p>
                    <p>Sujeto a estos Términos, el Proveedor concede al Cliente y a sus usuarios autorizados una
                        licencia no exclusiva, intransferible y no sublicenciable para utilizar los Servicios de Set99
                        para
                        recopilar, almacenar y organizar los Datos del Cliente, como añadir nuevas Personas y
                        Organizaciones, crear tuberías y añadir Etapas en estas tuberías, generar informes basados en
                        los Datos del Cliente, añadir Ofertas en Etapas y supervisar su evolución a través de las
                        siguientes Etapas, añadir Productos y/o Artículos que se ofrezcan a la venta a Personas y
                        Organizaciones; añadir nuevos Usuarios y concederles Autorizaciones, asignar Actividades a
                        un Usuario concreto; modificar y eliminar los Datos del Cliente; personalizar las
                        Características
                        o funcionalidades estándar de los Servicios de Set99; recibir ayuda y orientación razonables y
                        del Proveedor en relación con el uso de los Servicios de Set99. Si el Proveedor determina que
                        el uso de los Servicios por parte del Cliente está fuera del uso permitido y previsto, tal y
                        como
                        se describe en el presente documento, o que el uso del ancho de banda del Servicio o de
                        cualquier característica o funcionalidad es sea significativamente excesivo en relación con
                        otros Usuarios, el Proveedor se reserva el derecho de suspender la respectiva Cuenta del
                        Cliente, (o parte de ella) hasta que el Cliente garantice al Proveedor que se abstendrá de
                        seguir
                        abusando de los Servicios.</p>
                    <p>8.2 Soporte técnico
                    </p>
                    <p>El Proveedor proporcionará un soporte técnico razonable al Cliente y a su Usuario autorizado a
                        petición razonable del Cliente. El Proveedor responderá a las solicitudes de soporte de un
                        Cliente utilizando los contactos establecidos a continuación tan pronto como sea
                        razonablemente posible. La respuesta a las consultas de los Clientes y Usuarios que hayan
                        aceptado estas Condiciones pero que no tengan una Cuenta puede ser menos rápida, o
                        puede no producirse en absoluto.</p>
                    <p>Los contactos para todas las consultas de asistencia son:</p>
                    <p>la mensajería instantánea, aplicación de notificación incorporada en la Página Web, o
                        correo electrónico: user5@net99.world .
                        Sin perjuicio de lo anterior, si usted adquirió el acceso a los Servicios de Set99 a través de
                        un
                        Revendedor, el soporte técnico de primera línea será proporcionado por el Revendedor y no
                        por el Proveedor.</p>
                    <p>8.3 Modificaciones del Servicio
                    </p>
                    <p>El Proveedor se reserva el derecho de modificar los Servicios de Set99 o cualquier parte o
                        elemento de los mismos de vez en cuando sin previo aviso, incluyendo, sin limitación cambiar
                        el nombre de los Servicios de Set99 a su entera discreción; dejar de proporcionar o interrumpir
                        el desarrollo de cualquier Servicio de Set99 o parte o elemento de la Plataforma de forma
                        temporal o permanente; tomar las medidas necesarias para preservar los derechos del
                        Proveedor ante cualquier uso de los Servicios de Set99 que pueda interpretarse
                        razonablemente como una violación de los derechos de propiedad intelectual del Proveedor,
                        distribución de virus de Internet, gusanos, troyanos, malware y otras actividades destructivas o
                        ilegales. </p>
                    <p>Según corresponda, el Cliente podrá ser notificado de dichas modificaciones al iniciar sesión
                        en la Cuenta. Las modificaciones, incluyendo el cambio de las tarifas aplicables a los Servicios
                        de Set99, entrarán en vigor treinta (30) días antes de la fecha efectiva de dicha modificación.
                    </p>
                    <p>Si el Cliente no acepta la modificación, el Cliente deberá notificar al Proveedor o al
                        Revendedor antes de la fecha efectiva de la modificación, y estos Términos terminarán en la
                        fecha efectiva de la modificación. El uso continuado por parte del Cliente de los Servicios de
                        Set99, o cualquier parte o elemento de los mismos, después de la fecha efectiva de una
                        modificación indicará su consentimiento a las modificaciones. El Proveedor no será
                        responsable ante el Cliente o ante cualquier tercera persona por cualquier modificación,
                        suspensión o interrupción de los Servicios de Set99, o cualquier parte o elemento de los
                        mismos.</p>
                    <p>8.4 Características adicionales
                    </p>
                    <p>El Proveedor podrá ocasionalmente poner a disposición características adicionales a través de
                        los Servicios de Set99, que pueden estar sujetas a Términos adicionales. El uso por parte del
                        Cliente de cualquiera de estas Funciones está sujeto a su aceptación de las Condiciones
                        adicionales presentadas dentro de la Función correspondiente o de estas Condiciones de
                        servicio.</p>
                    <p>8.5 Servicios Beta
                    </p>
                    <p>El Proveedor puede ofrecer ciertas características de funcionalidad con el fin de probarlas y
                        evaluarlas ("Servicio Beta" o "Servicios Beta"). El Proveedor se reserva el derecho a
                        interrumpir
                        total o parcialmente, en cualquier momento y de forma ocasional, temporal o
                        permanentemente, cualquiera de los Servicios Beta con o sin previo aviso al Cliente. El Cliente
                        acepta que el Proveedor no será responsable ante el Cliente ni ante ningún tercero por
                        cualquier daño relacionado con el uso de los Servicios Beta por parte del Cliente, o causado
                        por la modificación, suspensión o interrupción de cualquiera de los Servicios Beta por
                        cualquier motivo.</p>
                    <p>9. Contrato de tratamiento de datos
                    </p>
                    <p>A los efectos de la LEY FEDERAL DE PROTECCIÓN DE DATOS PERSONALES EN POSESIÓN
                        DE LOS PARTICULARES Nueva Ley DOF 05-07-2010, estas Condiciones constituyen el
                        contrato de tratamiento de datos entre el Cliente como responsable del tratamiento y el
                        Proveedor como encargado del mismo. El Cliente encarga al Proveedor el tratamiento de los
                        datos tal y como se describe en estas Condiciones.</p>
                    <p>9.1 Objeto y naturaleza del tratamiento</p>
                    <p>El Proveedor proporciona la Plataforma en la que el Cliente, como responsable del tratamiento
                        de datos, puede recopilar, almacenar y organizar los datos personales de los interesados
                        determinados por el Cliente.</p>
                    <p>La Plataforma ha sido diseñada para funcionar como una herramienta de CRM pero, en la
                        medida en que no esté regulada por estos Términos, el Cliente decide cómo utilizar la
                        Plataforma.</p>
                    <p>9.2 Duración
                    </p>
                    <p>El Proveedor procesará los datos en nombre del Cliente hasta la terminación de los Servicios
                        de Set99 de acuerdo con estos Términos. El Proveedor almacenará los datos del Cliente
                        durante un periodo de seis meses, en caso de que el Cliente desee reabrir la Cuenta para
                        reanudar el uso de los Servicios de Set99 o para exportar los Datos del Cliente, a menos que
                        el Cliente indique lo contrario. El Proveedor elimina o devuelve todos los datos personales al
                        responsable del tratamiento una vez finalizada la prestación de servicios relativos al
                        tratamiento, y elimina las copias existentes, a menos que la legislación de los Estados Unidos
                        Mexicanos exija la conservación de los datos personales.</p>
                    <p>9.3 Derechos y obligaciones de las partes
                    </p>
                    <p>Los derechos y obligaciones del Cliente en relación con los Datos del Cliente se establecen en
                        las secciones 4 a 10 de estas Condiciones. El Proveedor garantiza que las personas
                        autorizadas a procesar los datos personales se han comprometido a la confidencialidad o
                        están bajo una obligación legal apropiada de confidencialidad.</p>

                    <p>El Proveedor adopta todas las medidas requeridas en virtud de la LEY FEDERAL DE
                        PROTECCIÓN DE DATOS PERSONALES EN POSESIÓN DE LOS PARTICULARES Nueva Ley
                        DOF 05-07-2010. El Proveedor se compromete a poner a disposición del responsable del
                        tratamiento toda la información necesaria para demostrar el cumplimiento de sus obligaciones
                        y a permitir y contribuir a las auditorías, incluidas las inspecciones, realizadas o encargadas
                        por el Cliente como responsable del tratamiento</p>
                    <p>10. Restricciones
                    </p>
                    <p>10.1 Actividades prohibidas</p>
                    <p>El Cliente y sus Usuarios autorizados pueden utilizar los Servicios de Set99 y cualquier parte o
                        elemento de los mismos únicamente en el ámbito, con los medios y para los fines identificados
                        en estos Términos y la ley aplicable. A modo de ejemplo, ni el Cliente ni ningún Usuario podrá
                        utilizar los Servicios de Set99 o cualquier parte o elemento de los mismos para cometer un
                        delito, infringir cualquier ley aplicable o incitar o invitar a otros a llevar a cabo dichas
                        acciones
                        ilegales; copiar, duplicar, distribuir, modificar, adaptar, piratear, crear trabajos derivados,
                        realizar
                        ingeniería inversa o descompilar los Servicios de Set99 o cualquier parte o elemento de los
                        mismos, o intentar extraer el código fuente de los mismos, a menos que (i) esté expresamente
                        permitido por la ley aplicable, y (ii) en la medida en que el Proveedor no esté autorizado por
                        dicha ley aplicable a excluir o limitar los derechos anteriores; utilizar los Servicios de Set99
                        o
                        cualquier parte o elemento de los mismos a menos que haya aceptado estos Términos.
                        10.2 Ciertos usos requieren el consentimiento del Proveedor
                        El Cliente o cualquier Usuario no podrá, sin el previo consentimiento expreso por escrito del
                        Proveedor (correo electrónico, fax, Skype, etc.) vender, revender, arrendar, licenciar,
                        sublicenciar, distribuir, proporcionar, revelar, divulgar, explotar o conceder de otro modo el
                        Acceso o poner a disposición los Servicios de Set99 en su totalidad o en parte a cualquier
                        tercera persona, a menos que dicha tercera persona sea otro Usuario autorizado del mismo
                        Cliente; utilizar los Servicios de Set99 o cualquier parte o elemento de los mismos en un
                        ámbito, con medios o con fines distintos a aquellos para los que se creó su funcionalidad;
                        utilizar los Servicios de Set99 o cualquier parte o elemento de los mismos por medio de
                        programas que les envíen consultas o solicitudes automáticas, a menos que dicho programa
                        haya sido puesto a disposición por el Proveedor;</p>
                    <p>10.3 Cumplimiento del control comercial
                    </p>
                    <p>El Cliente, cualquier usuario, revendedor o agente ("Tercero") por la presente declara,
                        garantiza, pacta y acuerda que, con respecto a los Servicios de Set99:</p>
                    <p>El Tercero ha cumplido y cumplirá, y hará que sus directores, funcionarios, empleados y
                        agentes cumplan, con las leyes y reglamentos de sanciones económicas, comerciales y
                        financieras de México, y cualquier otra ley extranjera aplicable.
                        El Tercero no tomará ninguna acción, directa o indirectamente, que haga que Set99 o
                        cualquiera de sus subsidiarias o filiales, o cualquiera de sus respectivos funcionarios,
                        directores, empleados o representantes, violen cualquier Control Comercial.</p>
                    <p>El Tercero notificará inmediatamente a Set99 si el Tercero o cualquier personal empleado por o
                        afiliado al Tercero (i) comete cualquier infracción real o potencial de los Controles
                        Comerciales
                        en relación con los Servicios de Set99, o (ii) se convierte en una Persona Restringida.
                        Set99 tendrá el derecho de terminar inmediatamente el acceso a, o el uso de los Servicios de
                        Set99 sin aviso o responsabilidad para el Tercero, si el Tercero, o cualquier persona empleada
                        por o afiliada con el Tercero toma cualquier acción en violación de las disposiciones descritas
                        aquí o si Set99 determina, a su sola discreción, que el uso continuado de los Servicios de
                        Set99 por parte del Tercero podría violar los Controles Comerciales.</p>
                    <p>11. Privacidad</p>
                    <p>El Proveedor se toma muy en serio la privacidad de sus Clientes y Usuarios. La Política de
                        Privacidad del Proveedor en www.net99.world/#privacypolicy se incorpora a estos Términos
                        por referencia. Lea detenidamente la Política de privacidad, ya que rige la recopilación, el uso
                        y
                        la divulgación de la información personal del Cliente o Usuario por parte del Proveedor.</p>
                    <p>12. Derechos de propiedad intelectual</p>
                    <p>12.1 Derechos de Propiedad Intelectual de Set99 en los Servicios de Set99
                        Los Servicios de Set99, los Materiales de Set99, los nombres comerciales y las marcas
                        registradas de Set99, y cualquier parte o elemento de los mismos son única y exclusivamente
                        propiedad y están operados por el Proveedor y sus terceros proveedores y socios de
                        alojamiento. Los Materiales de Set99 están protegidos por las leyes de derechos de autor,
                        imagen comercial, patentes, secretos comerciales y marcas registradas, convenciones y
                        tratados internacionales y todas las demás leyes pertinentes de propiedad intelectual y
                        derechos de propiedad. El Proveedor, sus filiales y licenciatarios conservan todos los
                        derechos, títulos e intereses sobre dichos Servicios de Set99, Materiales de Set99, nombres
                        comerciales y marcas registradas de Set99, y cualquier parte o elemento. Su uso de los
                        Servicios de Set99 y los Materiales de Set99, y cualquier parte o elemento, no le otorga ningún
                        derecho de propiedad o derechos de propiedad intelectual sobre los mismos. Cualquier
                        distribución comercial o promocional, publicación o explotación de los Materiales de Set99
                        está estrictamente prohibida a menos que haya recibido el permiso expreso previo por escrito
                        del Proveedor o del titular de los derechos aplicables. El Proveedor se reserva todos los
                        derechos de los Servicios de Set99, los Materiales de Set99 y los nombres comerciales y
                        marcas registradas de Set99 que no se concedan expresamente en los Términos.</p>
                    <p>12.2 Contenido propiedad de Set99</p>
                    <p>Sujeto a estos Términos, el Proveedor concede al Cliente y a sus usuarios autorizados una
                        licencia no exclusiva, intransferible y no sublicenciable para descargar una única copia de
                        cualquier parte del Contenido únicamente para su uso personal y no comercial, siempre que
                        conserve todos los avisos de derechos de autor y de propiedad que figuran en dicha parte del
                        Contenido. Usted reconoce expresamente que no adquiere ningún derecho de propiedad al
                        descargar cualquier material con derechos de autor desde o a través de la Plataforma o los
                        Servicios de Set99. Usted no copiará, distribuirá o publicará ningún Contenido o cualquier
                        información obtenida o derivada del mismo, excepto según lo permitido en o a través de los
                        Servicios de Set99 o según lo permitido por la ley aplicable.</p>
                    <p>12.3 Datos del Cliente</p>
                    <p>El Proveedor podrá utilizar los Datos del Cliente en un formato agregado o anónimo para fines
                        de investigación, educativos y otros similares. El Proveedor no podrá utilizar o mostrar los
                        Datos del Cliente sin el consentimiento por escrito del Cliente. El Proveedor respeta su
                        derecho a la propiedad exclusiva de sus Datos de Cliente. A menos que usted lo permita
                        específicamente, su uso de los Servicios de Set99 no concede al Proveedor la licencia para
                        utilizar, reproducir, adaptar, modificar, publicar o distribuir los Datos del cliente creados
                        por
                        usted o almacenados en su Cuenta para fines comerciales, de marketing o similares del
                        Proveedor. El Cliente concede expresamente al Proveedor el derecho a utilizar y analizar los
                        datos agregados de la actividad del sistema asociados con el uso de los Servicios de Set99
                        por parte del Cliente y sus Usuarios con el fin de optimizar, mejorar o perfeccionar el
                        funcionamiento de los Servicios de Set99, así como para crear nuevas Características y
                        funcionalidades en relación con los Servicios de Set99 a la entera discreción del Proveedor.
                        El Cliente es el único responsable de sus propios Datos del Cliente y de las consecuencias de
                        su publicación en o a través del Servicio de Set99. En relación con los Datos del Cliente, el
                        Cliente afirma, representa y garantiza que (i) el Cliente es propietario de sus Datos del
                        cliente o
                        tiene las licencias, derechos, consentimientos y permisos necesarios para utilizar y autorizar a
                        los Proveedores a mostrar o utilizar de otro modo los Datos del cliente en virtud de todas las
                        patentes,
                        marcas comerciales, derechos de autor, secretos comerciales u otros derechos de
                        propiedad en y para sus Datos del cliente de forma coherente con las Características y
                        funcionalidad previstas de los Servicios de Set99 y estos Términos, y para conceder los
                        derechos y la licencia establecidos en la Sección 11. 3(a), y (ii) los Datos del Cliente, el uso
                        del
                        Proveedor o de cualquier Licenciatario de Set99 de dichos Datos del Cliente conforme a estos
                        Términos, y el ejercicio del Proveedor o de cualquier Licenciatario de Set99 de los derechos de
                        licencia establecidos en la Sección 11.3(a), no infringen ni infringirán: (a) ningún derecho de
                        terceros, incluyendo cualquier derecho de autor, marca registrada, patente, secreto comercial,
                        derecho moral, derecho a la privacidad, derecho a la publicidad o cualquier otro derecho de
                        propiedad intelectual o de propiedad; (b) violar cualquier ley o regulación aplicable en
                        cualquier
                        parte del mundo; o (c) requerir la obtención de una licencia o el pago de cualquier cuota y/o
                        regalías por parte del Proveedor a cualquier tercero por la ejecución de cualquier Servicio de
                        Set99 que el Cliente haya elegido que sea ejecutado por el Proveedor o por el ejercicio de
                        cualquier derecho otorgado en estos Términos, a menos que el Cliente y el Proveedor
                        acuerden lo contrario.</p>
                    <p>12.4 Comentarios
                    </p>
                    <p>Si el Cliente o un Usuario proporciona al Proveedor cualquier comentario, informe de errores,
                        retroalimentación o modificaciones para los Servicios de Set99 ("Retroalimentación"), el
                        Proveedor tendrá el derecho de utilizar dicha Retroalimentación a su discreción, incluyendo,
                        pero sin limitarse a la incorporación de dichos cambios sugeridos en los Servicios de Set99.</p>
                    <p>El Cliente o el Usuario (según sea el caso) por la presente otorga al Proveedor una licencia
                        perpetua, irrevocable, no exclusiva y libre de regalías bajo todos los derechos necesarios para
                        incorporar, publicar, reproducir, distribuir, modificar, adaptar, preparar trabajos derivados,
                        mostrar públicamente, ejecutar públicamente, explotar y utilizar su Retroalimentación para
                        cualquier propósito. El Proveedor tendrá derecho a modificar o eliminar cualquier Feedback
                        proporcionado en las áreas públicas del Sitio Web si el Proveedor lo considera, a su
                        discreción, dañino, ofensivo, amenazante, abusivo, acosador, tortuoso, difamatorio, vulgar,
                        obsceno, invasivo de la privacidad de otros, odioso o de cualquier otra forma ilegal.</p>
                    <p>13. Sitios, productos y servicios de terceros
                    </p>
                    <p>Los Servicios de Set99 pueden incluir enlaces a otros sitios web o servicios ("Sitios
                        enlazados") únicamente para comodidad de los Clientes. A menos que se indique específica y
                        explícitamente, el Proveedor no respalda ninguno de dichos Sitios vinculados ni la información,
                        material, productos o servicios contenidos en los Sitios vinculados o accesibles a través de
                        ellos. Además, el Proveedor no ofrece ninguna garantía expresa o implícita con respecto a la
                        información, el material, los productos o los servicios contenidos en los Sitios Enlazados o
                        accesibles a través de ellos. EL ACCESO Y EL USO DE LOS SITIOS ENLAZADOS, INCLUIDA
                        LA INFORMACIÓN, EL MATERIAL, LOS PRODUCTOS Y LOS SERVICIOS DE LOS SITIOS
                        ENLAZADOS O ACCESIBLES A TRAVÉS DE ELLOS, SE REALIZA EXCLUSIVAMENTE POR
                        CUENTA Y RIESGO DEL USUARIO.</p>
                    <p>Cualquier contenido referido como proporcionado por la comunidad es proporcionado por
                        terceros y no desarrollado o mantenido por Set99. Al utilizar cualquier código o biblioteca
                        marcados por la comunidad en su desarrollo de software, usted reconoce y acepta que Set99
                        no es responsable en modo alguno del rendimiento o los daños causados por dicho código o
                        biblioteca proporcionados por la comunidad.</p>
                    <p>14. Renuncias; sin garantía
                    </p>
                    <p>A MENOS QUE EL PROVEEDOR INDIQUE EXPRESAMENTE LO CONTRARIO, LOS
                        SERVICIOS DE Set99, EL MATERIAL DE Set99 Y CUALQUIER CONTENIDO, SERVICIO O
                        CARACTERÍSTICA DISPONIBLE JUNTO CON LOS SERVICIOS DE Set99 O A TRAVÉS DE
                        ELLOS SE PROPORCIONAN "TAL CUAL" Y "SEGÚN DISPONIBILIDAD" SIN GARANTÍAS DE
                        NINGÚN TIPO, YA SEAN EXPRESAS O IMPLÍCITAS. EN LA MEDIDA EN QUE LO PERMITA LA
                        LEY APLICABLE, EL PROVEEDOR Y SUS AFILIADOS RENUNCIAN A TODAS LAS
                        GARANTÍAS, ESTATUTARIAS, EXPRESAS O IMPLÍCITAS, INCLUYENDO, PERO SIN
                        LIMITARSE A, LAS GARANTÍAS IMPLÍCITAS DE COMERCIABILIDAD, IDONEIDAD PARA UN
                        PROPÓSITO PARTICULAR, NO VIOLACIÓN DE LOS DERECHOS DE PROPIEDAD,
                        CORRECCIÓN, PRECISIÓN Y FIABILIDAD.</p>
                    <p>A MENOS QUE EL PROVEEDOR INDIQUE EXPRESAMENTE LO CONTRARIO, EL
                        PROVEEDOR Y SUS AFILIADOS NO GARANTIZAN QUE LOS SERVICIOS DE Set99 Y
                        CUALQUIER CONTENIDO, SERVICIOS DE DATOS DE CLIENTES O CARACTERÍSTICAS
                        DISPONIBLES EN CONJUNTO CON O A TRAVÉS DE LOS SERVICIOS DE Set99 SEAN
                        ININTERRUMPIDOS O ESTÉN LIBRES DE ERRORES, QUE LOS DEFECTOS SEAN
                        CORREGIDOS O QUE LOS SERVICIOS DE Set99 Y CUALQUIER CONTENIDO, DATOS DE
                        CLIENTES, SERVICIOS O CARACTERÍSTICAS DISPONIBLES EN CONJUNTO CON O A
                        TRAVÉS DE LOS SERVICIOS DE Set99 O EL SERVIDOR QUE LOS HACE DISPONIBLES
                        ESTÉN LIBRES DE VIRUS U OTROS COMPONENTES DAÑINOS.</p>
                    <p>A MENOS QUE EL PROVEEDOR INDIQUE EXPRESAMENTE LO CONTRARIO, EL
                        PROVEEDOR Y SUS AFILIADOS NO GARANTIZAN NI HACEN NINGUNA REPRESENTACIÓN
                        CON RESPECTO AL USO O LOS RESULTADOS DEL USO DE LA PLATAFORMA, LOS
                        SERVICIOS DE Set99, EL MATERIAL DE Set99 O CUALQUIER SITIO VINCULADO, EN
                        TÉRMINOS DE CORRECCIÓN, EXACTITUD, CONFIABILIDAD U OTROS.
                    </p>
                    <p>LAS LEYES DE DETERMINADOS PAÍSES Y ESTADOS NO PERMITEN LA LIMITACIÓN DE LAS
                        GARANTÍAS IMPLÍCITAS NI LA EXCLUSIÓN O LIMITACIÓN DE DETERMINADOS DAÑOS. SI
                        ESTAS LEYES SE APLICAN A USTED, ALGUNAS O TODAS LAS RENUNCIAS, EXCLUSIONES
                        O LIMITACIONES ANTERIORES PUEDEN NO APLICARSE A USTED, Y PODRÍA TENER
                        DERECHOS ADICIONALES.</p>
                    <p>15. Indemnización
                    </p>
                    <p>Usted acepta defender, indemnizar y eximir de responsabilidad al Proveedor y a sus afiliados, y
                        a sus respectivos directores, funcionarios, empleados y agentes, de cualquier reclamación,
                        pérdida, daño, responsabilidad, incluyendo los honorarios de los abogados, que surjan de su
                        uso o mal uso de los Servicios de Set99, los Materiales de Set99, las representaciones hechas
                        al Proveedor, sus afiliados y/o terceros, la violación de estos Términos, la violación de los
                        derechos de cualquier otra persona o entidad, o cualquier incumplimiento de las
                        representaciones, garantías y pactos anteriores. El Proveedor se reserva el derecho, a su
                        cargo, de asumir la defensa exclusiva y control de cualquier asunto por el que se le exija
                        indemnizar al Proveedor, y usted acepta cooperar con dicha defensa de estas reclamaciones.
                    </p>
                    <p>16. Limitación de la responsabilidad
                    </p>
                    <p>16.1 Ausencia de responsabilidad
                    </p>
                    <p>El Proveedor no será responsable ante el Cliente o el Usuario de las consecuencias derivadas
                        de cualquier modificación en estos Términos, los Servicios de Set99, el Material de Set99, o
                        cualquier parte o elemento de los mismos (incluyendo pero sin limitarse a la Cuenta),
                        incluyendo cualquier error, interrupción permanente o temporal, descontinuación, suspensión u
                        otro tipo de indisponibilidad de los Servicios de Set99 o del Material de Set99; eliminación,
                        corrupción o fallo en el almacenamiento de los Datos del Cliente; uso de los Datos del Cliente
                        por parte del Cliente o de cualquiera de los Usuarios asociados a la Cuenta; la actualización de
                        la Modalidad actual; cualquier divulgación, pérdida o uso no autorizado de las credenciales de
                        inicio de sesión del Cliente o de cualquier Usuario autorizado debido a que el Cliente no las
                        mantiene confidenciales;</p>
                    <p>el uso por parte del Cliente de la Cuenta o de los Servicios de Set99 por medio de
                        navegadores distintos a los aceptados o soportados por el Proveedor;
                        la aplicación de cualquier recurso contra el Cliente o los Usuarios autorizados por parte del
                        Proveedor, por ejemplo si el Cliente o el Usuario han cometido un delito o han realizado una
                        infracción de la ley aplicable al utilizar los Servicios de Set99 o cualquier parte o elemento
                        de
                        los mismos; las diferencias entre las tecnologías y plataformas utilizadas para el acceso, por
                        ejemplo, si ciertas Características, funciones, partes o elementos de los Servicios de Set99
                        están diseñados para su uso en un ordenador personal o portátil y no funcionan en una
                        plataforma móvil o una tableta; la aplicación por parte del Proveedor de los remedios descritos
                        en estos Términos, incluso si los motivos razonables o la base legal para la aplicación de estos
                        remedios resultan ser infundados o inválidos posteriormente.
                        Además, el Proveedor y sus filiales no serán responsables ante el Cliente de ninguna
                        reclamación de cualquier Usuario, persona, Organización o terceros contra el Cliente que se
                        derive del hecho de que el Cliente no proporcionara al Proveedor información precisa sobre el
                        Cliente, los Usuarios o la Cuenta; notificar al Proveedor las razones por las que un Usuario no
                        tiene derecho a utilizar la Cuenta en nombre del Cliente suministrar cualquier Producto que
                        haya acordado suministrar a dicha persona u Organización (tanto si dicho incumplimiento se
                        produce como resultado de la negligencia del Proveedor, del incumplimiento de estos
                        Términos o de cualquier otro modo) garantizar la legalidad de los Datos del Cliente;
                        obtener los derechos necesarios para utilizar los Datos del Cliente; o cumplir con cualquiera de
                        las restricciones descritas en estas Condiciones.</p>
                    <p>LA LIMITACIÓN ANTERIOR SE APLICARÁ TANTO SI LA ACCIÓN ES CONTRACTUAL COMO
                        EXTRACONTRACTUAL E INDEPENDIENTEMENTE DE LA TEORÍA DE LA RESPONSABILIDAD,
                        PERO NO LIMITARÁ LAS OBLIGACIONES DEL CLIENTE.</p>
                    <p>16.2 Exclusión de daños consecuentes y relacionados
                    </p>
                    <p>EN NINGÚN CASO NINGUNA DE LAS PARTES NI SUS FILIALES TENDRÁN
                        RESPONSABILIDAD ALGUNA DERIVADA DE ESTOS TÉRMINOS O RELACIONADA CON
                        ELLOS POR CUALQUIER PÉRDIDA DE BENEFICIOS, INGRESOS, FONDO DE COMERCIO O
                        DAÑOS INDIRECTOS, ESPECIALES, INCIDENTALES, CONSECUENTES, DE COBERTURA, DE
                        INTERRUPCIÓN DE LA ACTIVIDAD COMERCIAL O PUNITIVOS, TANTO SI LA ACCIÓN ES
                        CONTRACTUAL COMO EXTRACONTRACTUAL E INDEPENDIENTEMENTE DE LA TEORÍA DE
                        LA RESPONSABILIDAD, INCLUSO SI UNA PARTE O SUS FILIALES HAN SIDO ADVERTIDAS
                        DE LA POSIBILIDAD DE TALES DAÑOS O SI EL REMEDIO DE UNA PARTE O DE SUS
                        FILIALES NO CUMPLE SU PROPÓSITO ESENCIAL.</p>
                    <p>LA RENUNCIA ANTERIOR NO SE APLICARÁ EN LA MEDIDA EN QUE LO PROHÍBA LA LEY.
                    </p>
                    <p>17. Terminación de estos términos
                    </p>
                    <p>17.1 Por conveniencia
                    </p>
                    <p>Estos Términos pueden ser terminados por conveniencia en las siguientes situaciones
                        por el Cliente en cualquier momento, haciendo clic en el enlace de cancelación en el Sitio Web,
                        que le guiará a través del proceso de cancelación cuando inicie sesión en la Cuenta;
                        por el Proveedor al decidir finalizar la prestación de los Servicios de Set99 y cerrar la
                        Plataforma;</p>
                    <p>17.2 Por incumplimiento
                    </p>
                    <p>Las presentes Condiciones podrán ser resueltas por incumplimiento mediante notificación por
                        escrito a la otra parte, tal y como se indica en el apartado "Notificación" más adelante:
                        por cualquiera de las partes en caso de incumplimiento de estos Términos por parte de la otra
                        parte, si el incumplimiento no ha sido subsanado en un plazo de 30 días a partir de la
                        recepción de una notificación de la parte no incumplidora; o inmediatamente por cualquiera de
                        las partes si la otra parte incumple sus obligaciones, según corresponda en virtud de las
                        secciones 12 [Derechos de propiedad intelectual], 15 [Indemnización] o 10 [Restricciones] de
                        estas Condiciones.</p>
                    <p>17.3 Efecto de la rescisión
                    </p>
                    <p>A la terminación de estos Términos,
                        El Proveedor desactivará y eliminará permanentemente la Cuenta, en un plazo de seis meses a
                        partir de la fecha efectiva de terminación de estos Términos. Si el Cliente ha solicitado
                        específicamente una eliminación más temprana de la Cuenta, el Proveedor cumplirá con dicha
                        solicitud en el plazo de 1 mes a partir de la recepción de dicha solicitud.
                        El Cliente deberá:</p>
                    <p>dejar de utilizar y evitar el uso posterior de los Servicios de Set99, incluyendo, sin
                        limitación, la
                        Plataforma; y descargar cualquier responsabilidad en la que haya incurrido el Cliente antes en
                        virtud de estos Términos antes de su terminación; y Las siguientes disposiciones sobrevivirán
                        a la terminación de estos Términos: Secciones 1, 7.4, 10, 11, 12, 14, 15, 16, 18 y 19.</p>
                    <p>17.4 Recursos
                    </p>
                    <p>Si el Proveedor rescinde estos Términos como resultado de un incumplimiento no asegurado
                        por parte de un Cliente o Usuario, el Proveedor tiene derecho a utilizar los mismos o similares
                        recursos contra cualquier otra persona que utilice los Servicios de Set99 en conflicto con estos
                        Términos. Sin perjuicio de lo anterior, el Proveedor también podrá aplicar cualquier otro
                        remedio disponible para él bajo la ley aplicable. Tras la aplicación de cualquier remedio, el
                        Cliente o Usuario puede perder el acceso o sufrir una pérdida de ciertas características,
                        funciones, partes o elementos de los Servicios de Set99.</p>
                    <p>Si el Proveedor tiene motivos razonables para creer que el uso de los Servicios de Set99 por
                        parte del Cliente o del Usuario, incluyendo la Cuenta, puede dañar a cualquier tercera persona,
                        el Proveedor tiene derecho a tomar las medidas adecuadas bajo su control para prevenir,
                        detener y eliminar el daño, cuando sea posible, con el fin de proteger a esas terceras
                        personas.</p>
                    <p>El Proveedor tiene derecho a suspender el acceso a la totalidad o a una parte del Servicio,
                        incluida la eliminación del Contenido, en cualquier momento por violación del presente
                        Acuerdo o para proteger la integridad, operatividad y seguridad del Servicio, con efecto
                        inmediato, con o sin previo aviso. A menos que lo prohíba la ley o un proceso legal o para
                        evitar un daño inminente al Servicio o a cualquier tercero, el Proveedor suele avisar en forma
                        de banner o correo electrónico en o antes de dicha suspensión. El Proveedor, a su discreción y
                        utilizando la buena fe, adaptará cualquier suspensión según sea necesario para preservar la
                        integridad, operatividad y seguridad del Servicio.
                    </p>
                    <p>18. Con quién se contrata
                    </p>
                    <p>18.1 Generalidades
                    </p>
                    <p>El Proveedor con el que contrata el Cliente depende del domicilio del Cliente. El Proveedor
                        determina el domicilio del Cliente en función del país indicado en la dirección del Usuario que
                        celebra estas Condiciones en nombre del Cliente. El Proveedor determina el domicilio del
                        Cliente en función de la ubicación indicada en la dirección IP del Usuario que celebra estas
                        Condiciones en nombre del Cliente. Al aceptar estas Condiciones, el Cliente está contratando
                        con el Proveedor que aparece frente a su domicilio en la siguiente tabla:</p>
                    <p>Domicilio del Cliente:
                    </p>
                    <p> Proveedor:</p>
                    <p>Contacto del Proveedor:</p>
                    <p>La Republica Mexicana y el resto del mundo.</p>
                    <p> Set99 LN</p>
                    <p> correo electrónico: user5@net99.world</p>
                    <p> dirección postal:</p>
                    <p> Av Alud 833
                    </p>
                    <p>Jardines del Moral</p>
                    <p> León Guanajuato, México</p>
                    <p> México</p>
                    <p>Set99 QR</p>
                    <p>correo electrónico: user5@net99.world</p>
                    <p> dirección postal:</p>
                    <p>Jose Siurob 14</p>
                    <p> La Alameda</p>
                    <p> Querétaro, Qro</p>
                    <p> México</p>
                    <p>18.2 Ley aplicable y jurisdicción
                    </p>
                    <p>En el caso de una disputa, controversia o reclamación que surja de o en relación con estos
                        Términos, incluyendo pero sin limitarse a la formación, validez, incumplimiento o terminación
                        de los mismos, las partes intentarán resolver el asunto amistosamente en negociaciones
                        mutuas. En el caso de que no se pueda alcanzar una resolución mutuamente aceptable en un
                        plazo razonable, cualquiera de las partes tendrá derecho a buscar todos los recursos
                        disponibles, incluidos los recursos legales sujetos a los términos y condiciones establecidos a
                        continuación. Sin perjuicio de lo anterior y con sujeción a los términos y condiciones que se
                        establecen a continuación, cualquiera de las partes podrá solicitar medidas cautelares con
                        respecto a cualquier asunto en disputa en la medida en que lo permita la legislación aplicable.
                        En caso de que no sea posible un acuerdo amistoso entre las partes, la controversia se
                        resolverá definitivamente en los tribunales o mediante arbitraje, tal como se designa en el
                        presente documento, con sujeción a los términos y condiciones que se establecen a
                        continuación. Cualquier cuestión relacionada con estas Condiciones que no se resuelva
                        expresa o implícitamente por las disposiciones contenidas en las mismas se regirá e
                        interpretará de acuerdo con lo siguiente:</p>
                    <p>Domicilio del cliente:
                    </p>
                    <p>Ley aplicable; Resolución de conflictos:
                    </p>
                    <p>Tribunales con jurisdicción exclusiva:
                    </p>
                    <p>La Republica Mexicana.
                    </p>
                    <p>Las presentes Condiciones (y cualquier otra norma, política o directriz incorporada por
                        referencia a las mismas) se regirán e interpretarán de acuerdo con la legislación de la
                        República Méxicana, sin que se aplique ningún principio de conflicto de leyes.</p>
                    <p>Las presentes Condiciones (y cualquier otra norma, política o directriz incorporada por
                        referencia a las mismas) se regirán e interpretarán de acuerdo con la legislación de la
                        República Méxicana, sin que se aplique ningún principio de conflicto de leyes.</p>
                    <p>Estas Condiciones (y cualquier otra norma, política o directriz incorporada por referencia a las
                        mismas) se regirán e interpretarán de acuerdo con las leyes del Estado de Guanajuato, sin dar
                        efecto a ningún principio de conflicto de leyes. A menos que el Cliente y Set99 LN acuerden lo
                        contrario por escrito, cualquier disputa que surja de o esté relacionada con estos Términos, o
                        el incumplimiento de los mismos, se regirá por los términos establecidos en esta Sección 18.2.
                    </p>
                    <p>Cada uno de nosotros acuerda que presentará cualquier disputa contra el otro en nuestras
                        respectivas capacidades individuales y no como demandante o miembro de una clase en
                        cualquier clase pretendida, procedimiento representativo o como una asociación. Además,
                        cada uno de nosotros acepta que las disputas se arbitrarán únicamente a título individual y no
                        en una acción colectiva, consolidada o representativa. El árbitro no está facultado para
                        modificar estas disposiciones.</p>
                    <p>La solicitud de arbitraje deberá exponer la naturaleza de la(s) demanda(s) y la reparación
                        solicitada. Salvo que se limite específicamente en estas Condiciones, el tribunal arbitral
                        estará
                        facultado para conceder cualquier solución o medida que considere apropiada, ya sea
                        provisional o definitiva, incluidas, entre otras, las medidas cautelares y los requerimientos, y
                        cualquier medida de este tipo ordenada por el tribunal arbitral se considerará, en la medida en
                        que lo permita la legislación aplicable, un laudo definitivo sobre el objeto de las medidas y
                        será
                        ejecutable como tal. Cada una de las partes conserva el derecho de solicitar a cualquier
                        tribunal de la jurisdicción competente la adopción de medidas provisionales y/o cautelares,
                        incluidos los embargos o requerimientos prearbitrales, y cualquier solicitud de este tipo no se
                        considerará incompatible con el acuerdo de arbitraje ni una renuncia al derecho de arbitraje. La
                        existencia y el contenido del procedimiento arbitral, así como las sentencias o laudos, serán
                        confidenciales para las partes y los miembros del tribunal arbitral, excepto (i) en la medida en
                        que su divulgación pueda ser exigida a una parte para cumplir con un deber legal, proteger o
                        perseguir un derecho legal, o ejecutar o impugnar un laudo en un procedimiento legal de
                        buena fe ante un tribunal estatal u otra autoridad judicial, (ii) con el consentimiento de todas
                        las
                        partes, (iii) cuando sea necesario para la preparación o presentación de una demanda o
                        defensa en este arbitraje, (iv) cuando dicha información ya sea de dominio público, excepto
                        como resultado de un incumplimiento de esta cláusula, o (v) por orden del tribunal arbitral a
                        petición de una parte. El tribunal arbitral podrá incluir en su laudo una asignación a
                        cualquiera
                        de las partes de las costas y gastos, incluidos los honorarios de los abogados, que el tribunal
                        arbitral considere razonables. Todo laudo del tribunal arbitral será definitivo y vinculante
                        para
                        las partes. La ejecución de cualquier laudo podrá solicitarse a cualquier tribunal de la
                        jurisdicción competente.</p>
                    <p>Si cualquier parte de esta disposición se considera inaplicable, entonces el resto de esta
                        disposición permanecerá en pleno efecto y se interpretará y aplicará como si la parte
                        declarada inaplicable no estuviera contenida en este documento.</p>
                    <p>El uso de los Servicios de Set99 no está autorizado en ninguna jurisdicción que no dé efecto a
                        todas las disposiciones de estos Términos, incluyendo sin limitación, esta sección.
                    </p>
                    <P>Sin perjuicio de lo anterior, usted y los Proveedores acuerdan que nada de lo aquí expuesto se
                        considerará como una renuncia, impedimento o limitación del derecho de cualquiera de las
                        partes a (i) emprender acciones de cumplimiento a través de las agencias federales, estatales
                        o locales aplicables, cuando dichas acciones estén disponibles, (ii) buscar un desagravio por
                        mandato judicial en un tribunal de justicia, o (iii) presentar una demanda en un tribunal de
                        justicia para tratar las reclamaciones por infracción de la propiedad intelectual.</P>
                    <p>19. Disposiciones generales</p>
                    <p>19.1 Relación de las partes
                    </p>
                    <p>Las partes actuarán únicamente como contratistas independientes. Estos Términos no se
                        interpretarán como la creación de una agencia, sociedad, empresa conjunta, obligación
                        fiduciaria, o cualquier otra forma de asociación legal entre el Cliente y cualquiera de los
                        Proveedores, y el Cliente no representará lo contrario, ya sea expresamente, por implicación,
                        apariencia o de otra manera. Estas Condiciones no benefician a terceros.</p>
                    <p>19.2 Divisibilidad
                    </p>
                    <p>Si algún término, condición o disposición de estas Condiciones se considera inválido,
                        inaplicable o ilegal en su totalidad o en parte por cualquier motivo, dicha disposición se
                        aplicará en la medida máxima permitida para hacer efectiva la intención de las partes. La
                        validez y aplicabilidad de los restantes términos, condiciones o disposiciones, o partes de
                        ellos, no se verán afectados.</p>
                    <p>19.3 Acuerdo completo
                    </p>
                    <p>Estos Términos son el acuerdo completo entre el Cliente y el Proveedor con respecto al uso de
                        los Servicios de Set99 por parte del Cliente y reemplaza todos los acuerdos, propuestas o
                        representaciones anteriores y contemporáneas, escritas u orales, con respecto a su objeto.
                        Salvo que se estipule lo contrario en el presente documento, ninguna modificación, enmienda
                        o renuncia a cualquier disposición de estos Términos será efectiva a menos que se haga por
                        escrito y esté firmada por la parte contra la que se pretende hacer valer la modificación,
                        enmienda o renuncia.</p>
                    <p>19.4 Cesión</p>
                    <p>El Cliente no podrá, directa o indirectamente, en todo o en parte, por efecto de la ley o de otro
                        modo, ceder o transferir estos Términos o delegar cualquiera de sus derechos y/u obligaciones
                        en virtud de estos Términos sin el consentimiento previo por escrito del Proveedor. Cualquier
                        intento de cesión, transferencia o delegación sin dicho consentimiento previo por escrito será
                        nulo e inaplicable. Sin perjuicio de lo anterior, el Cliente, o sus cesionarios o transferencias
                        sucesivas permitidas, podrán ceder o transferir estos Términos o delegar cualquier derecho u
                        obligación en virtud de los mismos sin consentimiento: (1) a cualquier entidad controlada por el
                        Cliente, o bajo control común con el Cliente, o sus cesionarios o transferencias sucesivas
                        permitidas; o (2) en relación con una fusión, reorganización, transferencia, venta de activos o
                        líneas de productos, o cambio de control o propiedad del Cliente, o sus cesionarios o
                        transferencias sucesivas permitidas.</p>
                    <p>19.5 No hay renuncia</p>
                    <p>El hecho de que cualquiera de las Partes no ejerza o haga cumplir alguna disposición o alguno
                        de sus derechos en virtud de estas Condiciones no se considerará una renuncia a la aplicación
                        futura de esa o cualquier otra disposición o derecho.</p>
                    <p>19.6 Avisos</p>
                    <p>Salvo que se especifique lo contrario en estas Condiciones, todas las notificaciones
                        relacionadas con estas Condiciones se harán por escrito y entrarán en vigor a partir de (a) la
                        entrega personal, (b) el segundo día hábil después del envío por correo, o (c), excepto en el
                        caso de las notificaciones de rescisión o de una reclamación no procedente ("Notificaciones
                        legales"), que deberán ser claramente identificables como Notificaciones legales, el día del
                        envío por correo electrónico. Las notificaciones se dirigirán al administrador del sistema de
                        Servicios designado por usted.</p>
                    <p>Última actualización: 29 de Agosto de 2022 </p>
                </div>


            </div>
            <div class="ui checkbox mb-3 mt-2">
                <input type="checkbox" id="terms" (change)="checked($event.target.checked)">
                <label style="cursor: pointer;" for="terms">Estoy de acuerdo y he leído los términos, condiciones y <a
                        href="https://www.set99.world/#/privacy-policy" target="_blank" style="cursor: pointer;"
                        for="">aviso de
                        privacidad</a>

                </label>

            </div>
        </div>
        <modal-footer class="fx">
            <div></div>
            <div>
                <button class="ui button" (click)="removeModal()">
                    Regresar
                </button>
                <button *ngIf="!isChecked" class=" ui primary button" disabled>
                    Aceptar y Continuar
                </button>
                <button *ngIf="isChecked" class=" ui primary button" (click)="acceptTerms()">
                    Aceptar y Continuar
                </button>
            </div>
        </modal-footer>
    </div>
</ng-template>