<div class="col-md-4 mx-auto mt-5">
    <div class="card mt-5" *ngIf="user$ | async as user; else loading">
        <div class="card-body">

            <h3 class="card-title text-center"> ¡Gracias Por Registarte!</h3>

            <div class="form-group mt-2">

                <p class="text-center">

                    Enviamos un mensaje de confirmación a tu correo electrónico <strong>{{ user?.email }}</strong>
                </p>
                <p class="text-center">
                    Por favor, revisa tu bandeja de entrada y verifícalo.
                </p>
            </div>

            <div class="form-group text-center mt-5">
                <p class="text-center">
                    ¿No te llegó el código?
                </p>
                <button class="btn btn-primary" (click)="onSendEmail()" *ngIf="counter == 0"> Reenviar
                    verificación
                </button>
                <button class="btn btn-primary  " disabled *ngIf="counter > 0">
                    Espera {{ counter }} segundos para volver a enviar
                </button>

            </div>
            <a routerLink="/login">Regresar</a>
        </div>

    </div>
    <ng-template #loading>

        Cargando...

    </ng-template>
    <p></p>
</div>