import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HistorysRoutingModule } from './historys-routing.module';
import { HistorysComponent } from './historys/historys.component';
import { IngresaHistoryComponent } from './ingresa-history/ingresa-history.component';
import { IngresaHistoryFullviewComponent } from './ingresa-history-fullview/ingresa-history-fullview.component';
import { RegistraHistoryComponent } from './registra-history/registra-history.component';
import { RegistraHistoryFullviewComponent } from './registra-history-fullview/registra-history-fullview.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IncomePipe } from '../pipes/income.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { AdvanceArticleInformationComponent } from './advance-article-information/advance-article-information.component'; // <-- import the module
import { LeadPipe } from '../pipes/lead.pipe';
import { OwnArticlesComponent } from './own-articles/own-articles.component';
import { OthersArticlesComponent } from './others-articles/others-articles.component';
import { LeasingContractComponent } from './leasing-contract/leasing-contract.component';
import { RecentActivityComponent } from './recent-activity/recent-activity.component';
import { RecentActivityFullviewComponent } from './recent-activity-fullview/recent-activity-fullview.component';
import { AgoPipe } from '../pipes/ago.pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BankDataComponent } from './bank-data/bank-data.component';


@NgModule({
  declarations: [
    AgoPipe,

    HistorysComponent,
    IngresaHistoryComponent,
    IngresaHistoryFullviewComponent,
    RegistraHistoryComponent,
    RegistraHistoryFullviewComponent,
    IncomePipe,
    LeadPipe,
    AdvanceArticleInformationComponent,
    OwnArticlesComponent,
    OthersArticlesComponent,
    LeasingContractComponent,
    RecentActivityComponent,
    RecentActivityFullviewComponent,
    BankDataComponent,
  ],
  imports: [
    CommonModule,
    HistorysRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    NgxPaginationModule,
  ],
})
export class HistorysModule { }
