import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/SERVICIOS/auth.service';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss'],

})
export class SendEmailComponent {

  public user$: Observable<any> = this.authSvc.afAuth.user;
  public counter: number = 60;
  public sendEmail: boolean = true;
  count = 0;
  constructor(private authSvc: AuthService, private router: Router, public afAuth: AngularFireAuth) { }

  ngOnInit(): void {
    //acceder al localStorage para obtener el email
    const isSendEmail = localStorage.getItem('isSendEmail');
    if (isSendEmail == 'true') {
      const dataCounter = localStorage.getItem('counter');
      if (dataCounter !== null && dataCounter !== undefined && dataCounter !== '') {
        this.counter = parseInt(dataCounter);
      }
      this.initCounter();
    } else {
      this.count++;
      if (this.count == 1) {
        this.onSendEmail();
        this.counter = 60;
      }

    }
  }
  initCounter() {

    let interval = setInterval(() => {
      this.counter--;
      this.sendEmail = false;
      localStorage.setItem('counter', this.counter.toString());
      if (this.counter <= 0) {
        this.sendEmail = true;
        this.count = 0;
        localStorage.setItem('isSendEmail', 'false');
        localStorage.setItem('counter', "0");
        clearInterval(interval);
      }
    }, 1000);
  }

  onSendEmail() {
    try {
      this.authSvc.sendVerificationEmail();
      localStorage.setItem('isSendEmail', 'true');
      this.sendEmail = false;
      this.counter = 60;
      this.initCounter();
    }
    catch (error) {
      alert(error);
    }
  }




  //   firebase.auth()
  //     const email = firebase.auth().currentUser.email
  //     // const password = firebase.auth().currentUser.

  //     // try {
  //     //   const user = await this.authSvc.login(email, password);
  //     //   if (user.role !== 'SUPPORT' && user.status) {
  //     //     this.router.navigate(['/pagina-principal']);
  //     //   } else {
  //     //     this.router.navigate(['/support']);
  //     //   }
  //     //   if (user && !user.status) {
  //     //     this.router.navigate(['/welcome']);
  //     //   } else if (user) {
  //     //     this.router.navigate(['/verification-email']);
  //     //   }
  //     //   else {
  //     //     this.router.navigate(['/register']);
  //     //   }
  //     // }
  //     // catch (error) {
  //     // }

  // }
}
