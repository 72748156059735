<div class="main">
  <div class="container">
    <div class="request" *ngIf="auth.userSupport(user)">
      <div class="subtitle mb-2 d-flex justify-content-between" *ngIf="auth.userSupport(user)">
        <h5>Solicitudes de usuarios: {{request_chats.length }}</h5>
        <div></div>
        <button class="ui button fs-mini" (click)="show_hide()">{{show_text}}</button>
      </div>

      <div class="table-responsive mb-4" style="max-height: 22vh; overflow-y: scroll;" *ngIf="show">
        <table class="table">
          <thead class="thead-dark">
            <tr>
              <th scope="col">ID</th>
              <th scope="col" class="large">Asunto</th>

              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let chat of request_chats">
              <th>{{ chat.id }}</th>
              <td class="large">{{ chat.asunto.asunt | titlecase }}</td>
              <td *ngIf="!chat.temporal"><button class="ui button fs-mini" (click)="take_asunt(chat)">Tomar
                  Asunto</button></td>
              <td *ngIf="chat.temporal"><button class="ui button fs-mini" (click)="takeup_case(chat)">Retomar
                  Asunto</button></td>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row clearfix">
      <div class="col-lg-12">
        <div class="cardChat chat-app">
          <div id="plist" class="people-list">
            <div class="input-group">
              <div class="filter" *ngIf="auth.canSupport(user)">
                <select class="form-select" (change)="filterWithSelect($event.target.value)">
                  <option selected>Selecciona una opción</option>
                  <option value="1">ID de asunto</option>
                  <option value="2">ID de usuario</option>
                </select>

                <div class="form-outline">
                  <input class="form-control" placeholder="ingrese el valor" name="filterPost"
                    [(ngModel)]="filterPost" />
                </div>
              </div>
            </div>
            <ul class="list-unstyled chat-list mt-2 mb-0">


              <li class="clearfix" (click)="obt_chat(room)" *ngFor="
              let room of allChats 
              let i = index
            ">
                <div class="about">
                  <div class="name">{{room.asunto.asunt}}</div>
                  <p class=" mt-2 mb-2 fw-bold">{{room.id}}</p>
                  <div class="status">
                    <p *ngIf="room.resuelto" style="font-size: 1.2rem;  color: gray; font-weight :bolder;">Resuelto</p>
                    <p *ngIf="!room.resuelto && room.temporal"
                      style="font-size: 1.2rem;color: rgb(128, 105, 0);font-weight :bolder;">
                      Cerrado
                      Temporalmente</p>
                    <p *ngIf="!room.resuelto && !room.temporal"
                      style="font-size: 1.2rem;color: green;font-weight :bolder;">Activo</p>
                  </div>
                </div>
              </li>

            </ul>
          </div>
          <div class="chat" *ngIf="selected_room == true ">
            <div class="chat-header clearfix">
              <div class="chat-about">
                <h6 class="m-b-0 fw-bold">{{data_chats.asunto.asunt}}</h6>
                <small style="
                color: #6c757d;
                font-size: 12px;
                font-weight: 400;
                max-height:  50px;
                overflow-y: scroll;
                ">{{data_chats.asunto.description}}</small>
                <small style="
                color: #6c757d;
                font-size: 12px;
                max-height:  50px;
                overflow-y: scroll;
                ">{{data_chats.id}}</small>
              </div>
            </div>
            <div class="chatHistorySupport" *ngIf="auth.canSupport(user); else noUserSupport">
              <ul class="m-b-0">
                <li class="clearfix" *ngFor="let message of chat.chats">
                  <div class="message my-message" *ngIf="message.messageType === 2">
                    {{ message.text }}
                  </div>
                  <div class="message other-message float-right" *ngIf="message.messageType === 1">
                    {{ message.text }}
                  </div>
                </li>
              </ul>
            </div>
            <ng-template #noUserSupport>
              <div class="chatHistory">
                <ul class="m-b-0">
                  <li class="clearfix" *ngFor="let message of chat.chats">
                    <div class="message my-message" *ngIf="message.messageType === 1">
                      {{ message.text }}
                    </div>
                    <div class="message other-message float-right" *ngIf="message.messageType === 2">
                      {{ message.text }}
                    </div>
                  </li>
                </ul>
              </div>
            </ng-template>



            <div class="inputs">
              <div class="btn_finish" [disabled]="chat_disabled" (click)="finish_chat()"
                *ngIf="auth.canSupport(user) && (!temporary && !finalized)">
                <img src="./../../assets/icons/check.png" width="20" height="20" alt="" />
                Terminar <br />
                Asunto
              </div>
              <div class="btn_finish" *ngIf="auth.canSupport(user) && (!temporary && !finalized)"
                (click)="finish_chat_temporary()">
                <img src="./../../assets/icons/clock.png" width="20" height="20" alt="" />
                Terminar <br />
                Temporalmente
              </div>

              <div class="btn_finish" *ngIf="auth.canSupport(user) && (temporary && !finalized)"
                (click)="take_up_case()">
                <button class="ui button">Retomar caso</button>
              </div>
              <input [(ngModel)]="messageText" type="text" class="form-control" placeholder="Escribe un mensaje"
                maxlength="1000" [disabled]="chat_disabled" />
              <button class="btn" (click)="sendMessage()" [disabled]="chat_disabled">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                  class="bi bi-telegram" viewBox="0 0 16 16">
                  <path
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z" />
                </svg>
              </button>
            </div>
          </div>
          <div class="no_chat" style="margin-left:200;" *ngIf="selected_room == false ">
            <h1>Elige un chat para empezar</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="main">
  <div class=" mt-4 mb-4">
    <h2 class="title">Soporte</h2>
  </div>
  <div class="request" *ngIf="auth.userSupport(user)">
    <div class="subtitle mb-2 d-flex justify-content-between" *ngIf="auth.userSupport(user)">
      <h5>Solicitudes de usuarios: {{request_chats.length }}</h5>
      <div></div>
      <button class="ui button fs-mini" (click)="show_hide()">{{show_text}}</button>
    </div>

    <div class="table-responsive mb-4" style="max-height: 22vh; overflow-y: scroll;" *ngIf="show">
      <table class="table">
        <thead class="thead-dark">
          <tr>
            <th scope="col">ID</th>
            <th scope="col" class="large">Asunto</th>

            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let chat of request_chats">
            <th>{{ chat.id }}</th>
            <td class="large">{{ chat.asunto.asunt | titlecase }}</td>
            <td *ngIf="!chat.temporal"><button class="ui button fs-mini" (click)="take_asunt(chat)">Tomar
                Asunto</button></td>
            <td *ngIf="chat.temporal"><button class="ui button fs-mini" (click)="takeup_case(chat)">Retomar
                Asunto</button></td>
        </tbody>
      </table>
    </div>
  </div>
  <section class="container">



    <div class="sidebar">
      <div class="filter" *ngIf="auth.canSupport(user)">
        <select class="form-select" (change)="filterWithSelect($event.target.value)">
          <option selected>Selecciona una opción</option>
          <option value="1">ID de asunto</option>
          <option value="2">ID de usuario</option>
        </select>

        <div class="form-outline">
          <input class="form-control" placeholder="ingrese el valor" name="filterPost" [(ngModel)]="filterPost" />
        </div>
      </div>
      <div class="asunto" (click)="obt_chat(room)" *ngFor="
          let room of allChats 
          let i = index
        ">
        <div class="asunto">
          <div class="msg-no-reads" *ngIf="user && auth.canSupport(user) && room.no_leido_support > 0; else no_support">
            {{room.no_leido_support}}
          </div>
          <ng-template #no_support>
            <div class="msg-no-reads" *ngIf="user && !auth.canSupport(user) && room.no_leido > 0;">
              {{room.no_leido}}
            </div>
          </ng-template>
          <p *ngIf="room.resuelto" style="color: gray; font-weight :bolder;">Resuelto</p>
          <p *ngIf="!room.resuelto && room.temporal" style="color: rgb(128, 105, 0);font-weight :bolder;">Cerrado
            Temporalmente</p>
          <p *ngIf="!room.resuelto && !room.temporal" style="color: green;font-weight :bolder;">Activo</p>
          <span><span style="font-weight: bolder;">ID:</span> {{room.id}}</span>
          <span><span style="font-weight: bolder;">Fecha:</span> {{ room.asunto.fecha }}</span>
          <span><span style="font-weight: bolder;">Hora:</span> {{ room.asunto.hora }}</span>
          <span><span style="font-weight: bolder;">Asunto:</span> {{ room.asunto.asunt }}</span>
          <span><span style="font-weight: bolder;">Descripción:</span> {{ room.asunto.description }} </span>
        </div>
      </div>
    </div>
    <div class="box-chat ">
      <div class="chat  shadow-lg p-3 mb-5 cardChat" *ngIf="auth.canSupport(user); else noUserSupport">
        <div *ngIf="selected_room !== true" class="msg_home">
          <h2>{{ message_chat_main }}</h2>
        </div>

        <div [ngClass]="{
            local: message.messageType === 2,
            remoto: message.messageType === 1
          }" *ngFor="let message of chat.chats">
          <p>{{ message.text }}</p>
        </div>
        <div class="fn_message">
          <div class="text_center">
            <p>{{ message_chat_desabled }}</p>
          </div>
        </div>
      </div>
      <ng-template #noUserSupport>
        <div class="chat  shadow-lg p-3 mb-5 cardChat">
          <div *ngIf="selected_room !== true" class="msg_home">
            <p>{{ message_chat_main }}</p>
          </div>

          <div [ngClass]="{
              local: message.messageType === 1,
              remoto: message.messageType === 2
            }" *ngFor="let message of chat.chats">
            {{ message.text }}
          </div>
          <div class="fn_message">
            <div class="text_center">
              <p>{{ message_chat_desabled }}</p>
            </div>
          </div>
        </div>
      </ng-template>

      <div *ngIf="selected_room" class="inputs">
        <div class="btn_finish" [disabled]="chat_disabled" (click)="finish_chat()"
          *ngIf="auth.canSupport(user) && (!temporary && !finalized)">
          <img src="./../../assets/icons/check.png" width="20" height="20" alt="" />
          Terminar <br />
          Asunto
        </div>
        <div class="btn_finish" *ngIf="auth.canSupport(user) && (!temporary && !finalized)"
          (click)="finish_chat_temporary()">
          <img src="./../../assets/icons/clock.png" width="20" height="20" alt="" />
          Terminar <br />
          Temporalmente
        </div>

        <div class="btn_finish" *ngIf="auth.canSupport(user) && (temporary && !finalized)" (click)="take_up_case()">
          <button class="ui button">Retomar caso</button>
        </div>
        <input [(ngModel)]="messageText" type="text" class="form-control" placeholder="Escribe un mensaje"
          maxlength="1000" [disabled]="chat_disabled" />
        <button class="btn" (click)="sendMessage()" [disabled]="chat_disabled">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-telegram"
            viewBox="0 0 16 16">
            <path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z" />
          </svg>
        </button>
      </div>
    </div>
  </section>
</div> -->




<!-- <div class="container-fluid h-50">
  <div class="row justify-content-center h-100">
    <div class="col-md-4 col-xl-3 chat">
      <div class="cardChat mb-sm-3 mb-md-0 contacts_cardChat">
        <div class="cardChat-header">

        </div>
        <div class="cardChat-body contacts_body">
          <ul class="contacts">
            <li class="active" (click)="obt_chat(room)" *ngFor="
            let room of allChats | filterchat: filterPost:valueName;
            let i = index">
              <div class="d-flex bd-highlight">
                <div class="user_info">
                  <span *ngIf="room.resuelto">Resuelto</span>
                  <span *ngIf="!room.resuelto && room.temporal">Cerrado Temporalmente</span>
                  <span *ngIf="!room.resuelto && !room.temporal">Activo</span>
                  <p>ID: {{room.id}}</p>
                  <p>Fecha: {{ room.asunto.fecha }}</p>
                  <p>Hora: {{ room.asunto.hora }}</p>
                  <p>Asunto: {{ room.asunto.asunt }}</p>
                  <p>Descripción: {{ room.asunto.description }} </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="cardChat-footer"></div>
      </div>
    </div>
    <div class="col-md-8 col-xl-6 chat">
      <div class="cardChat">
        <div class="cardChat-header msg_head">
          <div class="d-flex bd-highlight">
            <div class="img_cont">
              <img src="https://therichpost.com/wp-content/uploads/2020/06/avatar2.png" class="rounded-circle user_img">
              <span class="online_icon"></span>
            </div>
            <div class="user_info">
              <span>Chat with jassa</span>
              <p>1767 Messages</p>
            </div>
          </div>

        </div>
        <div class="cardChat-body msg_cardChat_body" *ngIf="auth.canSupport(user); else noUserSupport">


          <div class="d-flex justify-content-start mb-4">
            <div class="img_cont_msg">
              <img src="https://therichpost.com/wp-content/uploads/2020/06/avatar2.png"
                class="rounded-circle user_img_msg">
            </div>
            <div class="msg_cotainer">
              Hi, how are you samim?
              <span class="msg_time">8:40 AM, Today</span>
            </div>
          </div>
          <div class="d-flex justify-content-end mb-4">
            <div class="msg_cotainer_send">
              Hi jassa i am good tnx how about you?
              <span class="msg_time_send">8:55 AM, Today</span>
            </div>
            <div class="img_cont_msg">
              <img src="https://therichpost.com/wp-content/uploads/2020/06/avatar2.png"
                class="rounded-circle user_img_msg">
            </div>
          </div>
          <div class="d-flex justify-content-start mb-4">
            <div class="img_cont_msg">
              <img src="https://therichpost.com/wp-content/uploads/2020/06/avatar2.png"
                class="rounded-circle user_img_msg">
            </div>
            <div class="msg_cotainer">
              I am good too, thank you for your chat template
              <span class="msg_time">9:00 AM, Today</span>
            </div>
          </div>
          <div class="d-flex justify-content-end mb-4">
            <div class="msg_cotainer_send">
              You are welcome
              <span class="msg_time_send">9:05 AM, Today</span>
            </div>
            <div class="img_cont_msg">
              <img src="https://therichpost.com/wp-content/uploads/2020/06/avatar2.png"
                class="rounded-circle user_img_msg">
            </div>
          </div>
          <div class="d-flex justify-content-start mb-4">
            <div class="img_cont_msg">
              <img src="https://therichpost.com/wp-content/uploads/2020/06/avatar2.png"
                class="rounded-circle user_img_msg">
            </div>
            <div class="msg_cotainer">
              I am looking for your next templates
              <span class="msg_time">9:07 AM, Today</span>
            </div>
          </div>
          <div class="d-flex justify-content-end mb-4">
            <div class="msg_cotainer_send">
              Ok, thank you have a good day
              <span class="msg_time_send">9:10 AM, Today</span>
            </div>
            <div class="img_cont_msg">
              <img src="https://therichpost.com/wp-content/uploads/2020/06/avatar2.png"
                class="rounded-circle user_img_msg">
            </div>
          </div>
          <div class="d-flex justify-content-start mb-4">
            <div class="img_cont_msg">
              <img src="https://therichpost.com/wp-content/uploads/2020/06/avatar2.png"
                class="rounded-circle user_img_msg">
            </div>
            <div class="msg_cotainer">
              Bye, see you
              <span class="msg_time">9:12 AM, Today</span>
            </div>
          </div>
        </div>










        <ng-template #noUserSupport>
          <div *ngIf="selected_room !== true" class="msg_home">
            <p>{{ message_chat_main }}</p>
          </div>
          <div *ngIf="selected_room">
            <div *ngFor="let message of chat.chats">

              <div *ngIf="message.messageType === 2" class="d-flex justify-content-start mb-4">
                <div class="msg_cotainer">
                  {{ message.text }}

                </div>
              </div>
              <div *ngIf="message.messageType === 1" class="d-flex justify-content-end mb-4">
                <div class="msg_cotainer_send">
                  {{ message.text }}

                </div>
              </div>
            </div>
          </div>
        </ng-template>






        <div class="cardChat-footer">
          <div class="input-group">
            <div *ngIf="selected_room" class="inputs">
              <div class="btn_finish" [disabled]="chat_disabled" (click)="finish_chat()"
                *ngIf="auth.canSupport(user) && (!temporary && !finalized)">
                <img src="./../../assets/icons/check.png" width="20" height="20" alt="" />
                Terminar <br />
                Asunto
              </div>
              <div class="btn_finish" *ngIf="auth.canSupport(user) && (!temporary && !finalized)"
                (click)="finish_chat_temporary()">
                <img src="./../../assets/icons/clock.png" width="20" height="20" alt="" />
                Terminar <br />
                Temporalmente
              </div>

              <div class="btn_finish" *ngIf="auth.canSupport(user) && (temporary && !finalized)"
                (click)="take_up_case()">
                <button class="ui button">Retomar caso</button>
              </div>
              <input [(ngModel)]="messageText" type="text" class="form-control" placeholder="Escribe un mensaje"
                maxlength="1000" [disabled]="chat_disabled" />
              <button class="btn" (click)="sendMessage()" [disabled]="chat_disabled">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                  class="bi bi-telegram" viewBox="0 0 16 16">
                  <path
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z" />
                </svg>
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->